import { Locale } from '@ecomm/utils'

/**
 * Takes the attributeHash and returns the number of total products.
 * attributeHash is a string of hexadecimal characters
 */
export const getAttributeHashToItemCount = (
  attributeHash: string,
  locale: Locale
): number => {
  const additionalItemsToAdd = locale === 'en-US' ? 1 : 2 // +1 for US (base station) and +2 (base station + wired indoor camera) for UK

  if (!attributeHash) {
    return 0
  } else {
    // converts each pair of hexadecimal characters to numbers
    const numbers =
      attributeHash
        .match(/.{2}/g)
        ?.filter((hex: string) => !isNaN(Number(`0x${hex}`)))
        .map((hex: string) => parseInt(hex, 16)) ?? []
    return (
      numbers.reduce((acc: number, num: number) => acc + num, 0) +
      additionalItemsToAdd
    )
  }
}
