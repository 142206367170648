import { TypeOf, z } from '@simplisafe/ewok'

export const contentfulImageQuerySchema = z.object({
  __typename: z.literal('ContentfulAsset'),
  contentful_id: z.string(),
  title: z.string(),
  description: z.string(),
  file: z.object({
    url: z.string(),
    details: z.object({
      size: z.number(),
      image: z.object({
        width: z.number(),
        height: z.number()
      })
    })
  })
})

export type ContentfulImageQuerySchema = TypeOf<
  typeof contentfulImageQuerySchema
>
