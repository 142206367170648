import { useEffect, useRef } from 'react'

type Options = {
  readonly enabled?: boolean
  readonly target?: GlobalEventHandlers
}
export const isSSR = () => !typeof window

export function useEventListener(
  eventType: keyof GlobalEventHandlersEventMap,
  handler: EventListener,
  { enabled = true, target }: Options = {}
) {
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  })

  useEffect(() => {
    const internalHandler = (e: Event) => {
      return handlerRef.current(e)
    }

    const canItRun = !isSSR() && enabled

    const element = !isSSR() && !target ? window : target

    canItRun && element && element.addEventListener(eventType, internalHandler)

    return () => {
      element && element.removeEventListener(eventType, internalHandler)
    }
  }, [enabled, eventType, target])
}
