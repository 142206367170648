import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

export type ProductCardProps = {
  /** Product image to display, expects a Gatsby image in most cases */
  readonly img: ReactNode
  /** Product description, displayed as the card's text contents */
  readonly description: ReactNode
  /** Whether the selected Product is active or not */
  readonly isActive?: boolean
}

/** @deprecated Do not use ss-react-components*/
const ProductCard: FC<ProductCardProps> = ({
  img,
  description,
  isActive
}: ProductCardProps) => (
  <div
    className={classnames('cursor-pointer', { 'opacity-30': !isActive })}
    data-component="ProductCard"
  >
    <div className="relative mb-4 h-[130px]">{img}</div>
    <div className="prose md:prose-md lg:prose-lg prose-h3:!text-base prose-h3:md:!text-lg prose-h3:!font-bold">
      {description}
    </div>
  </div>
)

export default ProductCard
