//@ts-nocheck
/* eslint-disable max-lines -- legacy code - avoid changes */
import classNames from 'classnames'
import always from 'ramda/src/always'
import defaultTo from 'ramda/src/defaultTo'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
// eslint-disable-next-line no-restricted-imports -- legacy code
import pipe from 'ramda/src/pipe'
import replace from 'ramda/src/replace'
import React, { FC, ReactNode, useCallback, useState } from 'react'

import { Menu } from '..'
import { AccordionLinksProps } from '../AccordionLinks'
import { toFirstCharUpper } from '../common'
import FooterLinksGroup from '../FooterLinksGroup'
import IconDropDown, { IconDropDownProps } from '../IconDropDown'
import { MenuItem, MenuProps } from '../Menu'
import Offers, { OffersProps } from '../Offers'

export type FooterLinkProps = {
  readonly title: string
  readonly titleUrl?: string
  readonly menus?: readonly MenuProps[]
}

type Icon = {
  readonly imgSrc: string
  readonly linkUrl: string
  readonly description: string
}

export type footerData = {
  readonly socialLinks?: readonly SocialLinks[]
  readonly footerLinks?: readonly FooterLinkProps[]
  readonly offers?: OffersProps
  readonly countrySelector?: IconDropDownProps
  readonly copyRightText: string
  readonly staticLinks?: readonly Links[]
  readonly legalLinks: readonly Links[]
  readonly privacy?: Links
  readonly leftBottomLink?: readonly Links[]
  // Only shows on full layout
  readonly a11yPartnerLink?: Icon
}

export type FooterProps = {
  readonly footerData: footerData
  readonly type?: string
  readonly onClickLink?: (menuItem: MenuItem) => void
  readonly dataComponent?: string
}

export type Links = {
  readonly linkText: string
  readonly linkUrl: string
}

export type SocialLinks = {
  readonly id: string
  readonly linkText: string
  readonly linkUrl: string
  readonly image: ReactNode
}

const renderTopMenu = (links: readonly FooterLinkProps[]) => (
  <div className="text-body-size prose-a:hover:no-underline m-0 font-medium text-white md:max-w-none">
    <ul
      className="m-0 flex justify-between p-0 md:mr-[20%]"
      key="slimFooterLinks"
    >
      {links.map((item: FooterLinkProps) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <li
          className="prose-a:text-white prose-a:text-body-size list-none last:hidden"
          key={item.title}
        >
          <Menu
            className="hover:text-white hover:!underline"
            key={item.title}
            url={defaultTo('#')(item.titleUrl)}
          >
            {item.title}
          </Menu>
        </li>
      ))}
    </ul>
  </div>
)

const renderStaticLinks = (links: readonly Links[], newTab = false) => {
  return links.map((item: Links) => (
    <li key={item.linkText}>
      <a
        className="text-xs text-white no-underline hover:underline"
        href={item.linkUrl}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        target={ifElse(equals(true), always('_blank'), always('_self'))(newTab)}
      >
        {item.linkText}
      </a>
    </li>
  ))
}

const getClassName = (name: string) => {
  const myStyle = pipe(toFirstCharUpper, replace(/\s/g, ''))
  return ifElse(isNil, always(''), myStyle)(name)
}

const renderPlainFooter = (footerData: footerData) => {
  const { legalLinks, copyRightText } = footerData

  return (
    <div className="mx-auto my-0 flex w-full flex-[0_1_auto] flex-row flex-wrap max-md:justify-between md:items-end lg:justify-between">
      <div className="max-w-full flex-grow basis-0 p-3 pt-0 text-left md:text-center">
        <span className="text-neutral-black before:bg-neutral-black relative text-sm text-white before:absolute before:-left-[50px] before:top-0.5 before:hidden before:h-[11px] before:w-px before:content-[''] md:before:block">
          {copyRightText}
        </span>
      </div>
      <div className="flex max-w-full flex-grow basis-0 items-center p-3 pt-0 text-sm md:block">
        <ul className="prose-a:text-neutral-black m-0 mb-4 list-none gap-6 last:mb-0 last:flex last:basis-full last:justify-between last:max-md:pl-0 md:gap-8 last:md:ml-0 last:lg:ml-[20%]">
          {renderStaticLinks(legalLinks, true)}
        </ul>
      </div>
    </div>
  )
}

// TODO Refactor to use children and be more flexible from the front-end
/**
 * Get Offer Section on Footer
 */
/** @deprecated Do not use ss-react-components*/
const Footer: FC<FooterProps> = ({
  footerData,
  onClickLink,
  type,
  dataComponent = Footer.name
}: FooterProps) => {
  const footerType = defaultTo('Full')(type)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const theme = getClassName(footerType)
  const isPlainTheme = equals(footerType, 'Plain')

  const [hoverIndex, setHoverIndex] = useState(-1)

  const updateHover = useCallback((index: number) => {
    setHoverIndex(index)
  }, [])

  const renderFullFooter = (
    footerData: footerData,
    theme: string,
    onClickLink: ((menuItem: MenuItem) => void) | undefined
  ) => {
    const isSlimTheme = equals('Slim', theme)
    const {
      footerLinks,
      privacy,
      offers,
      countrySelector,
      legalLinks,
      staticLinks,
      socialLinks,
      copyRightText,
      leftBottomLink,
      a11yPartnerLink
    } = footerData
    const renderSocialMediaLinks = (links: readonly SocialLinks[]) => {
      return links.map((item: SocialLinks, index: number) => (
        <li
          className="ml-2.5 inline-block h-4 w-4 md:ml-0 md:mr-2.5 [&>*_img]:h-[14px] [&>*_img]:w-4"
          key={item.id}
        >
          <Menu
            className={classNames('block no-underline', {
              'opacity-70': hoverIndex === index,
              'opacity-100': hoverIndex !== index
            })}
            key={item.linkUrl}
            onMouseEnter={() => updateHover(index)}
            onMouseLeave={() => updateHover(-1)}
            url={item.linkUrl}
          >
            {item.image}
          </Menu>
        </li>
      ))
    }

    const renderA11yPartnerLink = (icon: Icon, isMobile = false) => (
      <a
        className={classNames('mb-2.5 leading-[0]', {
          'mb-0 pb-1.5 pl-3 md:hidden': isMobile
        })}
        href={icon.linkUrl}
      >
        <img alt={icon.description} className="h-7" src={icon.imgSrc} />
      </a>
    )

    const staticLinksClasses = classNames(
      'list-none m-0 mb-4 last:flex last:mb-0 last:lg:ml-[20%] gap-6 lg:gap-8',
      {
        'md:flex last:lg:ml-0 md:justify-end': theme === 'Full',
        'last:flex-start last:pl-0 last:md:justify-end': theme === 'Slim'
      }
    )

    return (
      <div className="max-w-8xl mx-auto mt-0">
        <div
          className={classNames(
            'my-0 grid w-full grid-cols-12 gap-6 px-3 pb-0 pt-3 md:gap-8 lg:gap-12',
            {
              hidden: isPlainTheme,
              'mb-8': isSlimTheme
            }
          )}
        >
          <div
            className={classNames(
              'order-2 pb-0 md:order-3 lg:order-1',
              { 'col-span-12 lg:col-span-6': !isSlimTheme },
              { 'col-span-8 md:col-span-4 lg:col-span-3': isSlimTheme },
              'prose-a:hover:no-underline text-white md:max-w-none'
            )}
          >
            {!isNil(footerLinks) &&
              ifElse(
                equals(false),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
                // @ts-expect-error
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
                always(
                  <FooterLinksGroup
                    className="prose-a:hover:underline prose-a:hover:text-white"
                    footerLinkList={
                      footerLinks as readonly AccordionLinksProps[]
                    }
                    onClick={onClickLink}
                  />
                ),
                always(renderTopMenu(footerLinks))
              )(isSlimTheme)}
            {!isNil(privacy) && (
              <Menu
                className={classNames('m-0 text-white', {
                  'max-md:hidden': isSlimTheme,
                  'md:hidden': !isSlimTheme
                })}
                name={privacy.linkText}
                url={privacy.linkUrl}
              />
            )}
          </div>
          <div
            className={classNames(
              'order-1 col-span-12 pb-0 md:col-span-8 lg:order-2 lg:col-span-5',
              { 'hidden md:block': isSlimTheme }
            )}
          >
            {!isNil(offers) && <Offers {...offers} />}
          </div>
          {!isNil(a11yPartnerLink) &&
            renderA11yPartnerLink(a11yPartnerLink, true)}
          {!isNil(countrySelector) && (
            <div
              className={classNames(
                'order-3 flex flex-[0_1_auto] flex-wrap text-white md:order-2 [&>div]:py-0',
                'prose-ul:m-0 prose-ul:p-0 prose-li:pt-5 prose-li:pr-2.5 prose-li:pb-0.5 prose-li:pl-0 pt-0 md:items-center md:justify-end lg:items-start',
                {
                  'md:flex-start col-span-4 w-full justify-end md:mr-0 lg:col-span-4':
                    isSlimTheme,
                  'col-span-12 md:col-span-4 lg:col-span-1': !isSlimTheme,
                  'max-md:prose-ul:-left-8': theme === 'Full'
                }
              )}
            >
              <IconDropDown
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
                {...(countrySelector as IconDropDownProps)}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            'flex flex-[0_1_auto] flex-row flex-wrap',
            'max-md:justify-between md:items-end lg:justify-between',
            {
              'mt-0': !staticLinks,
              'w-full flex-row-reverse justify-center lg:w-[58%]': isPlainTheme,
              'mb-4 gap-4 max-md:flex-col': isSlimTheme,
              'lg:flex-flow flex-row-reverse py-6 md:flex-row': theme === 'Full'
            }
          )}
        >
          {!isNil(socialLinks) && (
            <div
              className={classNames(
                'flex max-w-full flex-grow basis-0 items-center px-3 pt-0 max-md:order-1 lg:justify-start',
                {
                  'max-md:prose-li:ml-0 max-md:prose-li:mr-4 justify-start max-md:order-none':
                    isSlimTheme,
                  'justify-end md:justify-start': theme === 'Full'
                }
              )}
            >
              <ul
                className={classNames(
                  'm-0 flex p-0 md:block lg:justify-between'
                )}
              >
                {renderSocialMediaLinks(socialLinks)}
              </ul>
            </div>
          )}
          <div className="order-last max-w-full flex-grow basis-0 px-3 text-left md:order-none md:text-center">
            <span className="text-sm text-white">{copyRightText}</span>
          </div>
          <div
            className={classNames(
              'hidden max-w-full flex-grow basis-0 items-center px-3 pt-0 text-sm md:block',
              'prose-a:text-xs prose-a:text-white',
              {
                'max-md:order-1 max-md:block max-md:max-w-[50%]':
                  theme === 'Slim'
              }
            )}
          >
            {!isNil(a11yPartnerLink) && renderA11yPartnerLink(a11yPartnerLink)}
            {!isNil(staticLinks) && (
              <ul className={staticLinksClasses}>
                {renderStaticLinks(staticLinks)}
              </ul>
            )}
            <ul className={staticLinksClasses}>
              {renderStaticLinks(legalLinks)}
            </ul>
          </div>
        </div>
        {!isNil(leftBottomLink) && (
          <div className="max-w-full flex-grow basis-0 px-3 pb-3 pt-0 text-sm">
            <ul className="prose-li:list-none prose-a:text-xs prose-a:text-white m-0 p-0">
              {renderStaticLinks(leftBottomLink)}
            </ul>
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(
        'prose md:prose-md lg:prose-lg prose-headings:text-white prose-p:text-white prose-a:text-white',
        {
          'bg-neutral-black': !isPlainTheme,
          'px-2 py-4 md:px-5 md:py-6 lg:px-12 lg:py-8': theme === 'Slim',
          'bg-neutral-light-50 px-2 py-4 md:px-5 md:py-6 lg:px-12':
            isPlainTheme,
          'px-2 py-6 md:px-8 md:pb-16 md:pt-8 lg:px-16 lg:py-10':
            theme === 'Full'
        }
      )}
      data-component={dataComponent}
    >
      {ifElse(
        equals(true),
        () => renderPlainFooter(footerData),
        () => renderFullFooter(footerData, footerType, onClickLink)
      )(isPlainTheme)}
    </div>
  )
}

export default Footer
