import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.PeelAndStick,
    text: 'Peel-and-stick install'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 5 yrs'
  }
]

const description = {
  content: `The Temperature Sensor detects extreme temps both high and low. In areas with colder weather, you want to be alerted before pipes can freeze or burst, not after. Place a sensor in the basement for timely notifications.
  \nIn hot weather, a temperature sensor protects pets by alerting you if the interior temperature reaches an upper limit that you set.
  `
}

const specs = {
  content: `1.5 x 2.25 x 0.62 inches
  \nOptional screw kit
  \n1 CR-2032 Lithium 3V battery (included)
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: 'EiMxjozlXfE2AE8ri3hD1'
}

export const temperatureSensorData: ProductData = {
  productId: '2wDbRS9BycPUehz7e4uNnw',
  sensorName: 'Temperature Sensor',
  features,
  description,
  specs,
  faqs
}
