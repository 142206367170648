import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { useFocusTrap, useLockBodyScroll } from '@ecomm/shared-hooks'
import { Cancel, Profile, SimpliSafeLogo } from '@ecomm/shared-icons'
import classNames from 'classnames'
import { none } from 'fp-ts/lib/Option'
import { Link } from 'gatsby'
import { useRef } from 'react'
import React from 'react'
import { useTracking } from 'react-tracking'

import CartLink from './CartLink'
import MobileNavMenu from './MobileNavMenu'
import NavLink from './NavLink'
import {
  GridProps,
  ImageLinkSchemaProps,
  MainMenuProps,
  MobileMenuProps
} from './schema'

type AsideProps = {
  readonly menu: MobileMenuProps
  readonly show: boolean
  readonly cartQuantity: number
  readonly toggle: () => void
}

const isMenu = (
  el: GridProps | ImageLinkSchemaProps | MainMenuProps
): el is MainMenuProps => 'links' in el

const isGrid = (
  el: GridProps | ImageLinkSchemaProps | MainMenuProps
): el is GridProps => 'gridItems' in el

export default function Aside({
  cartQuantity,
  menu,
  show,
  toggle
}: AsideProps) {
  const ref = useRef(null)
  useFocusTrap(ref, show)

  useLockBodyScroll(show)

  const { trackEvent } = useTracking()

  return (
    <>
      <aside
        className={classNames(
          'fixed left-0 top-0 z-20 flex h-full w-full flex-col bg-white transition-all duration-300 md:w-[442px] lg:hidden',
          {
            'invisible -translate-x-full md:-translate-x-[442px]': !show,
            'visible translate-x-0': show
          }
        )}
        ref={ref}
      >
        {/* Aside header */}
        <div className="bg-neutral-black flex h-16 flex-shrink-0 items-center justify-between p-4 md:h-24 md:p-8">
          <Link
            aria-label="Home page"
            className="h-full"
            onClick={() => {
              trackEvent({
                label: 'logo',
                event: 'navigation-link',
                eventAction: 'click',
                eventCategory: 'navigation'
              })
              trackNavigationClick({
                action: 'click',
                navElement: 'logo'
              })
            }}
            to="/"
          >
            <SimpliSafeLogo
              className="h-full"
              titleA11y="Simplisafe homepage"
            />
          </Link>
          <button
            aria-label="close"
            className="inline-flex h-6 w-6 cursor-pointer border-none bg-transparent p-0 text-xl text-white"
            onClick={() => {
              trackEvent({
                label: 'hamburger',
                event: 'navigation-link',
                eventAction: 'close',
                eventCategory: 'navigation'
              })
              trackNavigationClick({
                action: 'close',
                navElement: 'hamburger'
              })
              toggle()
            }}
          >
            <Cancel className="h-6 w-6" titleA11y="Close" />
          </button>
        </div>
        {/* Aside body */}
        <div className="overflow-auto px-6 md:px-8">
          {menu.map((el, i) =>
            isGrid(el) ? (
              <div key="aside-grid">
                <div className="grid grid-cols-4 gap-4 py-6 md:gap-6 md:py-8">
                  {el.gridItems.map((item, index) => (
                    <NavLink
                      className={classNames(
                        'text-neutral-black hover:text-complementary-blue-100 min-h-0 flex-col gap-0 text-center',
                        {
                          'col-span-2 h-36': index <= 1,
                          'col-span-1 h-32': index > 1
                        }
                      )}
                      key={`aside-grid-item-${item.title || index}`}
                      trackingLabel={none}
                      {...item}
                    />
                  ))}
                </div>
                <hr className="m-0" />
              </div>
            ) : isMenu(el) ? (
              <div className="py-6 md:py-8" key={`aside-nav`}>
                <MobileNavMenu key={`aside-nav-${i}`} menu={el} />
              </div>
            ) : null
          )}
        </div>
        {/* Aside footer */}
        <div className="mt-auto flex h-20 items-center justify-between px-6 md:px-8">
          <CartLink
            className="text-neutral-black hover:text-primary-100 no-underline"
            quantity={cartQuantity}
          />
          <a
            className="text-neutral-black hover:text-primary-100 inline-flex items-center gap-2 no-underline"
            href="https://webapp.simplisafe.com/"
            onClick={() => {
              trackEvent({
                label: 'My account',
                event: 'navigation-link',
                eventAction: 'click',
                eventCategory: 'navigation'
              })
              trackNavigationClick({
                action: 'click',
                navElement: 'My account'
              })
            }}
          >
            <Profile className="h-6 w-6" />
            My account
          </a>
        </div>
      </aside>
      <div
        className={classNames(
          'fixed left-0 top-0 z-[9] hidden h-full w-full bg-black opacity-70 transition-opacity duration-300',
          {
            'md:block lg:hidden': show,
            hidden: !show
          }
        )}
        data-component="aside-background"
        onClick={toggle}
      />
    </>
  )
}
