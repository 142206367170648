import { TypeOf, z } from '@simplisafe/ewok'

export const reviewSectionSchema = z.object({
  headline: z.string().nullish(),
  reviews: z
    .object({
      company: z.string(),
      review: z.object({
        raw: z.string()
      }),
      publishDate: z.string().nullish()
    })
    .array(),
  button: z
    .object({
      text: z.string(),
      url: z.string()
    })
    .optional(),
  __typename: z.literal('ContentfulReviewSection')
})

export type ReviewSectionSchema = TypeOf<typeof reviewSectionSchema>
