import { ApolloRichText } from '@ecomm/contentful/components'
import type { smallTextSchema } from '@ecomm/shared-data'
import type { TypeOf } from '@simplisafe/ewok'
import classNames from 'classnames'

type Props = TypeOf<typeof smallTextSchema>

export function SmallText({ text, textAlignment }: Props) {
  const json = text?.json
  const links = text?.links
  return json ? (
    <div
      className={classNames(
        'w-full',
        {
          'text-left': textAlignment === 'Left',
          'text-center': textAlignment === 'Center',
          'text-right': textAlignment === 'Right'
        },
        'prose-h1:mb-4'
      )}
      data-testid="SmallText"
    >
      <ApolloRichText json={json} links={links} />
    </div>
  ) : null
}
