import { getBraze } from '@ecomm/shared-window'

import { brazeLogCustomEvent } from '../brazeLogCustomEvent'
import { EVENTS_LOGGING_DELAY } from '../constants'
import { handleBrazeImmediateFlush } from '../handleBrazeImmediateFlush'

/**
 * Sets the current braze user
 * @param leadSource
 * @param userId
 * @returns
 */
export function setUserId(leadSource: string, userId: string) {
  return getBraze(braze => {
    const properties = { lead_source: leadSource }
    braze.changeUser(userId)
    console.info(`Braze: In setUserId called changeUser with ${userId}`)
    setTimeout(() => {
      brazeLogCustomEvent('lead_created', properties)
      braze.requestImmediateDataFlush(
        handleBrazeImmediateFlush({ caller: 'setUserId', leadSource, userId })
      )
    }, EVENTS_LOGGING_DELAY)
    return userId
  })
}
