import { useMemo } from 'react'

import { useCurrentPromoBannerData } from '../useCurrentPromoBannerData'

export function useSitewidePromoEndDateDisplay(): {
  /** The banner's display end date. This is a string that can be used inside JavaScript Date functions. */
  readonly endDate: string
  /** Determines if the end date should display or not. */
  readonly hasEndDateCallout: boolean
} {
  const {
    displayEndTimeCalloutOverride,
    endTime,
    endTimeCalloutOverride,
    hasEndDateCallout
  } = useCurrentPromoBannerData()

  const endDate =
    displayEndTimeCalloutOverride && endTimeCalloutOverride
      ? endTimeCalloutOverride
      : endTime

  return useMemo(
    () => ({
      endDate,
      hasEndDateCallout
    }),
    [endDate, hasEndDateCallout]
  )
}
