//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import * as css from './ProductAddon.module.scss'

export type ProductAddonProps = {
  /** Out of stock messaging to appear below addon information */
  readonly outOfStockMessage?: ReactNode
  /** A component to display price and any discounts */
  readonly price: ReactNode
  /** Optional info popup */
  readonly productPopup?: ReactNode
  /** Optional text to appear before the quantity changer */
  readonly quantityBefore?: string
  /** Optional to appear after the quantity changer */
  readonly quantityAfter?: string
  /** A quantity changer component */
  readonly quantitySelector: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const ProductAddon: FC<ProductAddonProps> = ({
  dataComponent = ProductAddon.name,
  productPopup,
  price,
  outOfStockMessage,
  quantityAfter,
  quantityBefore,
  quantitySelector
}: ProductAddonProps) => {
  return (
    <div data-component={dataComponent}>
      <div
        className={classNames(
          'textSizeSM',
          'neutralBlackTextColor',
          css.addonParagraph
        )}
      >
        <span className={css.addonQuantity}>
          {quantityBefore ? <span>{quantityBefore}</span> : null}
          <span className={css.addonQuantitySelect}>{quantitySelector}</span>
        </span>
        <span>
          {`${quantityAfter}`} {price}
          {productPopup ? (
            <span className={css.addonPopupLink}>{productPopup}</span>
          ) : null}
        </span>
      </div>
      {outOfStockMessage ? (
        <div
          className={classNames(
            'textSizeXS',
            'fontWeightMedium',
            'cautionTextYellowTextColor',
            'm1_t'
          )}
        >
          {outOfStockMessage}
        </div>
      ) : null}
    </div>
  )
}

export default ProductAddon
