import { OLYMPUS_SKU, SCOUT_SKU, SHIELD_SKU } from '@ecomm/data-constants'
import { useMicroCopy } from '@ecomm/micro-copy'
import { type Locale, useEnv } from '@ecomm/utils'
import { type Option, fold, none, some } from 'fp-ts/lib/Option'
import React, { type ReactNode } from 'react'

import { useCartSkus } from '../hooks'
import type { CartLineItemProps } from '../types'

export type CartBelowContentsProps = {
  readonly content: ReactNode
  readonly itemList: readonly CartLineItemProps[]
}

/*
 * A Content Collection in Contentful stores three GroupSections of BelowCartContent
 * if both Scout and Shield are in the cart *we want to show the first (ALL_ACCESSORIES).
 * If only Shield is in cart, we want to show the second (SHIELD_ACCESSORIES),
 * and if only Scout is on cart we want to show the third (SCOUT_ACCESSORIES).
 * If neither Scout nor Shield are in cart, show no below cart content.
 */
export const getGroupBasedOnCartItems = (
  { content }: CartBelowContentsProps,
  skuIsInCart: (sku: string) => boolean,
  locale: Locale
) => {
  // This is sorted in CTFL
  const elements = {
    ALL_ACCESSORIES: 0,
    SHIELD_ACCESSORIES: 1,
    SCOUT_ACCESSORIES: 2
  }

  const hasOutdoorCamera = skuIsInCart(SHIELD_SKU) || skuIsInCart(OLYMPUS_SKU)

  // uk scout does not have a power adapter, so exclude from showing
  const hasScout = locale === 'en-US' && skuIsInCart(SCOUT_SKU)

  const hasBoth = hasOutdoorCamera && hasScout

  const index: Option<number> = hasBoth
    ? some(elements.ALL_ACCESSORIES)
    : hasOutdoorCamera
      ? some(elements.SHIELD_ACCESSORIES)
      : hasScout
        ? some(elements.SCOUT_ACCESSORIES)
        : none

  return fold(
    () => null,
    (id: number) => content[id]
  )(index)
}

export function CartBelowContents(data: CartBelowContentsProps) {
  const { locale } = useEnv()
  const cartSkus = useCartSkus()
  const skuIsInCart = (sku: string) => cartSkus.has(sku)

  const content = getGroupBasedOnCartItems(data, skuIsInCart, locale)
  const microCopy = useMicroCopy()

  return content ? (
    <div className="md:order-3 lg:col-span-full">
      <div className="my-8 lg:col-span-full lg:mt-16 [&>h3]:pl-4 [&>p]:pl-4">
        <div className="px-4 md:px-0">
          <h1 className="m-0 p-0 text-3xl font-medium md:text-4xl">
            {locale === 'en-US'
              ? microCopy['cart-below-content-title-variation']
              : microCopy['cart-below-content-title']}
          </h1>
          <h3 className="m-0 pb-4 pt-3 text-base font-normal md:text-lg">
            {locale === 'en-US'
              ? microCopy['cart-below-content-subtitle-variation']
              : microCopy['cart-below-content-subtitle']}
          </h3>
        </div>
        <div className="m-0 p-0 md:-ml-4 lg:-ml-11">{content}</div>
      </div>
    </div>
  ) : null
}
