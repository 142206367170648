import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { Locale } from '@ecomm/utils'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'

const isSiteUK = (locale: Locale): boolean => locale === 'en-GB'

export function useUpdatePostalCode(locale: Locale) {
  const { values, setFieldValue } = useFormikContext<CheckoutFormValuesType>()

  useEffect(() => {
    isSiteUK(locale) &&
      values.postalCode &&
      setFieldValue('postalCode', values.postalCode.toUpperCase())
  }, [values.postalCode, setFieldValue])
}
