import { ContentfulImageSchema } from '@ecomm/contentful/components'
import { useContentful } from '@ecomm/shared-apollo'
import * as E from 'fp-ts/lib/Either'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import { useMemo } from 'react'

import { PRODUCT_IMAGE_CAROUSEL_QUERY } from './query'
import {
  ProductImageCarouselSchema,
  productImageCarouselSchema
} from './schema'

export const useContentfulProductImageCarousel = (
  productId: string
): readonly ContentfulImageSchema[] | readonly never[] => {
  const { data: rawApolloData } = useContentful(PRODUCT_IMAGE_CAROUSEL_QUERY, {
    productId
  })

  return useMemo(
    () =>
      pipe(
        E.tryCatch(
          () =>
            pipe(
              rawApolloData,
              (data: { readonly [key: string]: unknown }) =>
                productImageCarouselSchema.parse(data),
              (parsedData: ProductImageCarouselSchema) =>
                parsedData.product.imageCarouselCollection.items
            ),
          identity
        ),
        E.fold(constant([]), identity)
      ),
    [rawApolloData]
  )
}
