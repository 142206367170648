import { differenceInDays, differenceInWeeks, isValid, parse } from 'date-fns'

export const getShippingEstimate = (
  backInStockDate: string
): readonly [string, boolean] => {
  const parsedDate = parse(backInStockDate, 'yyyy-MM-dd', new Date())
  const restockDate = isValid(parsedDate) ? parsedDate : new Date()
  const inDaysDifference = differenceInDays(restockDate, new Date())
  const inWeeksDifference = differenceInWeeks(restockDate, new Date(), {
    roundingMethod: 'round'
  })

  const weeksDifferenceLabel = `${inWeeksDifference} - ${inWeeksDifference + 1}`
  const shippingEstimate =
    inDaysDifference < 8
      ? `${inDaysDifference} days`
      : `${weeksDifferenceLabel} weeks`

  const displayOutOfStockMessage = inDaysDifference > 0
  return [shippingEstimate, displayOutOfStockMessage]
}
