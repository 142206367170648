import {
  ModalLocation,
  trackClickComparisonChartModalEvent
} from '@ecomm/cdp-tracking'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export const useTrackHomepage = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (event: TrackingData['event']) => {
      trackEvent({
        event
      })
    },
    [trackEvent]
  )
}

export const useTrackHomepageComparisonChartModal = (
  modalLocation: ModalLocation
) => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'modal-click-comparison-chart',
      modalLocation
    })
    trackClickComparisonChartModalEvent(modalLocation)
  }, [trackEvent, modalLocation])
}
