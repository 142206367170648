import { Maybe } from '@simplisafe/ewok'
import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'

import { CheckMark } from '../icons'

export type CartUpdatedMessageProps = {
  readonly className?: string
  /** data-component attribute value */
  readonly dataComponent?: string
  /** Whether the message is currently visible */
  readonly isVisible?: boolean
  /** The message to display */
  readonly message?: string
  /** Time duration in milliseconds for displaying the message. Setting zero won't consider the timeout */
  readonly duration?: number
}

/** @deprecated Do not use ss-react-components*/
const CartUpdatedMessage: FC<CartUpdatedMessageProps> = ({
  className,
  dataComponent = CartUpdatedMessage.name,
  message,
  isVisible = true,
  duration = 0
}: CartUpdatedMessageProps) => {
  const [showMessage, setShowMessage] = useState(isVisible)

  useEffect(() => {
    setShowMessage(isVisible)
  }, [isVisible])

  useEffect(() => {
    Maybe.fromFalsy(duration).forEach(() =>
      setTimeout(() => setShowMessage(false), duration)
    )
  }, [showMessage, duration])

  return message ? (
    <div
      className={classNames(
        'text-complementary-blue-100 text-sm transition-all',
        { 'invisible opacity-0': !showMessage },
        { 'visible opacity-100': showMessage },
        className
      )}
      data-component={dataComponent}
    >
      <div className="relative flex flex-nowrap items-center justify-end leading-none md:justify-start">
        <span className="mr-1">{message}</span>
        <CheckMark className="relative w-6" />
      </div>
    </div>
  ) : null
}

export default CartUpdatedMessage
