//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import { Cart } from '../icons'
import * as css from './CartCount.module.scss'

type CartCountProps = {
  /** The quantity of items in the cart to display in an overlay */
  readonly count: number
}

/** @deprecated Do not use ss-react-components*/
const CartCount: FC<CartCountProps> = ({ count }: CartCountProps) => {
  return (
    <div
      className={classNames(
        'relative mr-2 inline-flex items-center',
        css.container
      )}
    >
      {count !== 0 && (
        <span
          className={classNames(
            'absolute -right-4 -top-4 flex h-5 w-5 items-center justify-center rounded-full pt-0.5 text-sm',
            css.count
          )}
        >
          {count}
        </span>
      )}
      <Cart className="relative inline-block" />
    </div>
  )
}

export default CartCount
