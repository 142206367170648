import { ChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import { Link } from '@ecomm/framework'
import { useState } from 'react'
import { useTracking } from 'react-tracking'
import type { Z } from '@simplisafe/ewok'
import type { FooterMenu, trackingEventSchema } from '@ecomm/footer-data'

export default function FooterCollapsible({
  menu
}: {
  readonly menu: FooterMenu
}) {
  const [show, setShow] = useState(false)
  const { trackEvent } = useTracking<{
    readonly event: Z.infer<typeof trackingEventSchema>
  }>()

  return (
    <>
      <button
        className="group inline-flex cursor-pointer items-center gap-2 border-none bg-inherit p-0 text-base font-medium text-white md:cursor-default md:text-lg"
        onClick={() => setShow(prev => !prev)}
      >
        {menu.title}
        <ChevronDown
          className={classNames(
            'mt-1 h-4 w-4 transition-transform duration-200 md:hidden',
            {
              'rotate-180': show,
              'rotate-0': !show
            }
          )}
        />
      </button>
      <ul
        className={classNames('mt-2 list-none p-0 md:block', { hidden: !show })}
      >
        {menu.linksCollection.items.map(link => (
          <li className="py-2" key={`footer_menus_collapsible_${link.text}`}>
            {link.url?.startsWith('/') ? (
              <Link
                className="text-white no-underline hover:underline"
                onClick={() =>
                  link.trackingEvent &&
                  trackEvent({
                    event: link.trackingEvent
                  })
                }
                to={link.url}
              >
                {link.text}
              </Link>
            ) : (
              <a
                className="text-white no-underline hover:underline"
                href={link.url ?? ''}
                onClick={() =>
                  link.trackingEvent &&
                  trackEvent({
                    event: link.trackingEvent
                  })
                }
              >
                {link.text}
              </a>
            )}
          </li>
        ))}
      </ul>
    </>
  )
}
