import { LoadingSpinner } from '@ecomm/checkout-icons'
import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import { useEffect } from 'react'

import useAddToCart from '../../../hooks/useAddToCart'
import useCartQuantity from '../../../hooks/useCartQuantity'
import QuantitySelector from '../../PersonalizePackage/QuantitySelector'

type AddToCartControlsProps = {
  readonly sku: string
  readonly maxQuantity: number
  readonly isOnStock: boolean
  readonly isSellable: boolean
  readonly price: string
}

export default function AddToCartControls({
  sku,
  maxQuantity,
  isOnStock,
  isSellable,
  price
}: AddToCartControlsProps) {
  const microcopy = useMicroCopy()
  const { onDecrease, onIncrease, quantity } = useCartQuantity(sku, maxQuantity)
  const { addToCart, isLoading, isCartUpdateError, isCartUpdateSuccess } =
    useAddToCart(sku, quantity)
  const isButtonDisabled = isCartUpdateError || !isSellable || quantity < 1

  useEffect(() => {
    isCartUpdateSuccess && navigate('/cart')
  }, [isCartUpdateSuccess])

  return (
    <div className="mt-4 flex flex-col" data-component="AddToCartControls">
      {isOnStock ? (
        <>
          <div className="flex items-center gap-4 md:gap-6">
            <span className="font-medium">{price}</span>
            <QuantitySelector
              maxQuantity={!isSellable ? 0 : maxQuantity}
              minQuantity={0}
              onDecrease={onDecrease}
              onIncrease={onIncrease}
              quantity={quantity}
            />
          </div>
          <button
            className={classNames('btn mt-3 h-14 w-44', {
              'bg-neutral-medium-100 border-0 text-white': isButtonDisabled,
              'btn-solid-primary': !isButtonDisabled
            })}
            disabled={isButtonDisabled}
            onClick={addToCart}
          >
            {isLoading ? (
              <LoadingSpinner fillColor="white" size={24} />
            ) : (
              microcopy['add-to-cart']
            )}
          </button>
          {isCartUpdateError ? (
            <small className="text-sale mt-2">
              {microcopy['package-atc-error']}
            </small>
          ) : null}
        </>
      ) : (
        <small className="text-sale w-full">{microcopy['out-of-stock']}</small>
      )}
    </div>
  )
}
