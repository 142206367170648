import { usePromoBannerVisibility } from '../usePromoBannerVisibility'

export const useBannerInteractionState = () => {
  const { isVisible, setVisibility } = usePromoBannerVisibility()

  const onCloseBanner = () => {
    setVisibility(false)
  }

  const onOpenBanner = () => {
    setVisibility(true)
  }

  const toggleBannerVisibility = () => {
    setVisibility(!isVisible)
  }

  return {
    isVisible,
    onCloseBanner,
    onOpenBanner,
    toggleBannerVisibility
  }
}
