import classNames from 'classnames'
import React, { ReactNode } from 'react'

export type CartShippingInfoProps = {
  /** Core components ship estimate */
  readonly estimateText?: ReactNode
  /** Core components ship delay text */
  readonly delayText?: ReactNode
}

function CartShippingInfo({ estimateText, delayText }: CartShippingInfoProps) {
  return (
    <div className={'mb-[20px]'}>
      {estimateText ? (
        <div className={classNames('outOfStockText', 'inline text-[14px]')}>
          {estimateText}
        </div>
      ) : null}
      {delayText ? (
        <div className="text-neutral-medium-100 inline text-[14px]">
          {delayText}
        </div>
      ) : null}
    </div>
  )
}

export default CartShippingInfo
