import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { getContentfulImageFromGatsbyData } from '@ecomm/contentful-image-utils'
import { ContentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { ContentfulRichText } from '@ecomm/shared-components'

type HeroProps = {
  readonly content: ContentfulRichTextSchema
}

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const img = getContentfulImageFromGatsbyData(node.data.target)

      return <ContentfulImage classNameOverride="w-32 md:mt-8" {...img} />
    }
  }
}

export default function Hero({ content }: HeroProps) {
  return (
    <div className="bg-neutral-black prose-headings:text-white rounded-base prose-headings:mb-0 flex flex-col items-center justify-center gap-6 p-8 text-center md:p-12 lg:p-16">
      <ContentfulRichText {...content} optionsCustom={options} />
    </div>
  )
}
