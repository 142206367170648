import {
  FormCheckbox,
  FormField,
  FormSection,
  FormTextInput
} from '@ecomm/checkout/components'
import { useTrackCheckoutLeadOptedIn } from '@ecomm/checkout/shipping-hooks'
import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { isSiteUS } from '@ecomm/checkout/utils'
import { Locale } from '@ecomm/utils'
import { MaybeT } from '@simplisafe/ewok'
import { UserCheckoutData } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { useFormikContext } from 'formik'
import { Dispatch, SetStateAction } from 'react'

import { LoginLink } from './LoginLink'

export type EmailProps = {
  readonly fetchUserCheckoutDataFailure: (e: Error) => void
  readonly fetchUserCheckoutDataSuccess: (
    userCheckoutData: MaybeT<UserCheckoutData>
  ) => void
  readonly locale: Locale
  readonly loginSuccess: boolean
  readonly setIsFormLoading: Dispatch<SetStateAction<boolean>>
}

export function Email(props: EmailProps) {
  const { values } = useFormikContext<CheckoutFormValuesType>()

  useTrackCheckoutLeadOptedIn(values.offerAndTip)

  return (
    <div data-component="CheckoutFormEmail">
      <FormSection title="Email">
        <div className="rounded-base bg-neutral-light-50 w-full p-4 pb-4 md:px-8 md:pb-8">
          <div className="lg:flex lg:flex-row lg:gap-8">
            <LoginLink
              fetchUserCheckoutDataFailure={props.fetchUserCheckoutDataFailure}
              fetchUserCheckoutDataSuccess={props.fetchUserCheckoutDataSuccess}
              loginSuccess={props.loginSuccess}
              setIsFormLoading={props.setIsFormLoading}
            />
            <FormField
              className="w-full md:w-1/2 lg:w-full"
              id="email"
              label="Email address*"
              labelClassName="mb-2 md:mb-1"
            >
              <FormTextInput
                className="w-full"
                id="email"
                name="email"
                placeholder="Email Address"
              />
            </FormField>
          </div>
          <hr
            className="border-neutral-medium-100 my-4 border border-b-0 border-solid"
            data-component="Divider"
          />
          <FormField className="" id="exclusive-offers">
            {isSiteUS(props.locale) && !props.loginSuccess && (
              <FormCheckbox
                className="text-base font-normal"
                label="Send me exclusive offers and safety tips from SimpliSafe"
                name="offerAndTip"
              />
            )}
          </FormField>
        </div>
      </FormSection>
    </div>
  )
}
