//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactElement, useCallback, useContext, useRef } from 'react'

import { useLockBodyScroll } from '../hooks'
import useFocusTrap from '../hooks/useFocusTrap'
import useOnKeyPress from '../hooks/useOnKeyUp'
import { CloseX, Hamburger } from '../icons'
import HeaderContext from './context'
import * as css from './Header.module.scss'

type HeaderNavigationDrawerProps = {
  /** Sets whether the navigation drawer and hamburger toggle are visible */
  readonly display: boolean
  /** The top contents of the navigation drawer */
  readonly top?: ReactElement | readonly ReactElement[]
  /** The top contents of the navigation drawer */
  readonly header?: ReactElement | readonly ReactElement[]
  /** The middle contents of the navigation drawer */
  readonly middle?: ReactElement | readonly ReactElement[]
  /** The top contents of the navigation drawer */
  readonly bottom?: ReactElement | readonly ReactElement[]
  /** Click function for Hamburger Menu */
  readonly onHamburgerClick?: () => void
}

/** @deprecated Do not use ss-react-components*/
const HeaderNavigationDrawer: FC<HeaderNavigationDrawerProps> = ({
  display,
  top,
  header,
  middle,
  bottom,
  onHamburgerClick
}: HeaderNavigationDrawerProps) => {
  const { isOpen, setIsOpen } = useContext(HeaderContext)
  const ref = useRef(null)

  useOnKeyPress('Escape', _ => setIsOpen(false))
  useFocusTrap(ref, isOpen)

  useLockBodyScroll(isOpen)

  const handleOnClick = useCallback(() => {
    onHamburgerClick && onHamburgerClick()
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen, onHamburgerClick])

  return display ? (
    <>
      <button
        aria-controls={'mobile-menu'}
        aria-expanded={isOpen}
        aria-label="Open Main Menu"
        className="mr-3 appearance-none border-0 bg-transparent p-0 text-inherit"
        onClick={handleOnClick}
      >
        <Hamburger />
      </button>
      <aside
        className={classNames(
          'text-neutral-black flex w-full flex-col bg-white shadow-lg transition-all md:max-w-md',
          {
            'invisible -translate-x-full': !isOpen,
            'translate-x-0': isOpen
          },
          css.drawer
        )}
        id="mobile-menu"
        ref={ref}
      >
        {/* Top menu with duplicated header things */}
        <div className="prose bg-neutral-black prose-a:w-full prose-a:h-full flex h-16 w-full shrink-0 justify-between p-4 text-white md:h-24 md:p-8">
          {header}
          <button
            aria-controls={'mobile-menu'}
            aria-expanded={isOpen}
            aria-label="Close Main Menu"
            className="appearance-none border-0 bg-transparent p-0 text-inherit"
            onClick={handleOnClick}
          >
            <CloseX />
          </button>
        </div>
        {top ? (
          <div
            className={classNames(
              'border-neutral-medium-100 grid shrink-0 grid-cols-2 gap-2 border-0 border-b border-solid px-6 py-6 lg:border-none',
              css.top
            )}
          >
            {top}
          </div>
        ) : null}
        {middle ? (
          <div
            className={classNames('p-6 md:px-8 md:pb-4 md:pt-4 ', css.middle)}
          >
            {middle}
          </div>
        ) : null}
        {bottom ? (
          <div
            className={classNames(
              'mt-auto flex w-full justify-between p-6 md:px-8 md:pb-8 md:pt-4',
              css.bottom
            )}
          >
            {bottom}
          </div>
        ) : null}
      </aside>
      {/* Black background */}
      {isOpen ? (
        <div
          className="fixed left-0 top-0 z-[2] h-full w-full bg-black opacity-50 transition-all duration-200"
          onClick={handleOnClick}
        />
      ) : null}
    </>
  ) : null
}

export default HeaderNavigationDrawer
