import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.NightVision,
    text: 'Reflective at night'
  }
]

const description = {
  content: `Let intruders know: this house isn't the one to mess with.
    \nOne free Yard Sign is included with your system.
  `
}

const specs = {
  content: `9 x 9 inches
    \nHeavy duty yard stake (included)
  `
}

export const extraYardSignData: ProductData = {
  productId: '7oyJ2ZgD2xoTZVMeDkjeRy',
  sensorName: 'Extra Yard Sign',
  features,
  description,
  specs
}
