import { trackLeadSubmit } from '@ecomm/cdp-tracking'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export const useTrackingSubmitLead = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'submitLead'
    })
    trackLeadSubmit('checkout')
  }, [trackEvent, trackLeadSubmit])
}
