import { useTrackAddProductToCart } from '@ecomm/cdp-tracking-utils'
import { useProduct } from '@ecomm/data-products'
import { useTrackingProductAddToCart } from '@ecomm/tracking'
import type { Product } from '@simplisafe/ss-ecomm-data/products'

/**
 * Custom hook that abstracts all the tracking logic for the AddToCartButton component.
 */
export function useAddToCartButtonTracking(sku: string) {
  const reduxProduct: Product | undefined = useProduct(sku)
    .toMaybe()
    .orUndefined()

  const trackAddToCart = useTrackingProductAddToCart(reduxProduct)
  const { trackProductCardAddToCartEvent } = useTrackAddProductToCart()

  function trackSuccess(quantity: number) {
    trackProductCardAddToCartEvent(reduxProduct, quantity)
    trackAddToCart(quantity)
  }

  return {
    trackSuccess
  }
}
