import { TypeOf, z } from '@simplisafe/ewok'

const sources = z.union([
  z.object({
    media: z.string(),
    srcSet: z.string(),
    type: z.string().optional(),
    sizes: z.string().optional()
  }),
  z.object({
    media: z.string().optional(),
    srcSet: z.string(),
    type: z.string(),
    sizes: z.string().optional()
  })
])

export const gatsbyImageDataSchema = z.object({
  height: z.number(),
  images: z.object({
    fallback: z
      .object({
        src: z.string(),
        sizes: z.string().optional(),
        srcSet: z.string().optional()
      })
      .optional(),
    sources: z.array(sources).optional()
  }),
  layout: z
    .literal('fixed')
    .or(z.literal('fullWidth'))
    .or(z.literal('constrained')),
  width: z.number()
})

// Reference: https://github.com/gatsbyjs/gatsby/blob/a259ff8e9c08b169cc767ea4f450ce3e509877bc/packages/gatsby-plugin-image/src/components/hooks.ts#L24-L26
export const gatsbyImageSchema = z.object({
  description: z.string().nullish(),
  gatsbyImageData: gatsbyImageDataSchema,
  title: z.string()
})

export const optionalGatsbyImageSchema = z.makeOptional(
  gatsbyImageSchema.nullable().optional()
)

export type GatsbyImageSchema = TypeOf<typeof gatsbyImageSchema>
export type OptionalGatsbyImageSchema = TypeOf<typeof optionalGatsbyImageSchema>

export const promoImageSchema = z.object({
  description: z.string(),
  file: z
    .object({
      url: z.string(),
      details: z.object({
        size: z.number(),
        image: z.object({
          width: z.number(),
          height: z.number()
        })
      }),
      fileName: z.string(),
      contentType: z.string()
    })
    .optional()
})

export type PromoImageSchema = TypeOf<typeof promoImageSchema>
