import React, { FC, ReactNode } from 'react'

import Heading from '../Heading'
import Text from '../Text'

export type BlogArticlePreviewProps = {
  /** Displays the last date of edit as well as the Author of the Article */
  readonly lastPostedText: ReactNode
  /** The full post url for the blog */
  readonly overviewUrl: ReactNode
  /**  PreviewDescription */
  readonly previewDescription: ReactNode
  /** Preview Image for the Blog Post */
  readonly previewImage: ReactNode
  /** Title of the Article */
  readonly title: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const BlogArticlePreview: FC<BlogArticlePreviewProps> = ({
  lastPostedText,
  overviewUrl,
  previewDescription,
  previewImage,
  title,
  dataComponent = BlogArticlePreview.name
}: BlogArticlePreviewProps) => (
  <div
    className="mx-auto mt-8 max-w-4xl overflow-hidden lg:mt-16"
    data-component={dataComponent}
  >
    <Heading
      className="prose-a:hover:text-complementary-blue-100 prose-a:no-underline"
      element="h2"
      headingAspect="h3"
      useTailwind
    >
      {title}
    </Heading>

    <div className="md:float-left md:mr-8 md:block md:w-96">{previewImage}</div>

    <div className="prose md:prose-md prose-a:text-neutral-medium-100 prose-p:text-neutral-medium-100 mb-8">
      {lastPostedText}
    </div>

    <div className="prose md:prose-md md:min-h-[150px]">
      {previewDescription}
    </div>

    <Text className="mb-6 mt-4 md:mb-0 md:mt-8 md:flex md:justify-end md:pr-4">
      {overviewUrl}
    </Text>
  </div>
)

export default BlogArticlePreview
