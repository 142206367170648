import { AFFIRM_MONTHLY_EXPERIMENT_IMPACTED_EVENT } from '@ecomm/data-constants'
import { useCallback } from 'react'

import { useOptimizelyTrack } from './useOptimizelyTrack'

export const useOptimizelyAffirmIconClick = () => {
  const trackEvent = useOptimizelyTrack({
    eventKey: AFFIRM_MONTHLY_EXPERIMENT_IMPACTED_EVENT
  })

  const fn = useCallback(() => {
    trackEvent()
  }, [trackEvent])

  return fn
}
