import { UserAttributes } from '../types'

export const toUserAttributes = (data?: UserAttributes): UserAttributes => {
  return {
    device: data?.device ?? null,
    environment: data?.environment ?? null,
    firstSeenUrl: data?.firstSeenUrl ?? null,
    geoLocation: data?.geoLocation ?? null,
    isLoggedIn: !!data?.isLoggedIn,
    isPartnerCookieSet: !!data?.isPartnerCookieSet,
    platform: data?.platform ?? 'fcp',
    userAgent: data?.userAgent ?? null,
    utm_campaign: data?.utm_campaign ?? null,
    utm_content: data?.utm_content ?? null,
    utm_media: data?.utm_media ?? null,
    utm_source: data?.utm_source ?? null,
    utm_term: data?.utm_term ?? null
  }
}
