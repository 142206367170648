import { TypeOf, z } from '@simplisafe/ewok'

export const buildMySystemBannerSchema = z.object({
  contentfulJson: z.object({
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export type BuildMySystemBannerSchema = TypeOf<typeof buildMySystemBannerSchema>
