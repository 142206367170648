import { useFragmentWithVariations } from '@ecomm/optimizely-hooks'

import { layoutNoHeaderSchema } from '../../assemblies/Layout/layoutSchema'
import { featuredProductPageSchema } from './featuredProductPageSchema'

export const useFeaturedProductPageFragment = <
  T extends { readonly contentfulFeaturedProductPage: U },
  U
>(
  query: T
) => {
  const featuredProductPageFragment = query.contentfulFeaturedProductPage

  const featuredProductPage = useFragmentWithVariations(
    featuredProductPageFragment,
    featuredProductPageSchema
  )
  const layout = featuredProductPage.layout

  return {
    ...featuredProductPageSchema.parse(featuredProductPage),
    layout: layoutNoHeaderSchema.parse(layout)
  }
}
