//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { SiteColor } from '../types/colors'
import { SitePadding } from '../types/padding'
import { SiteRounded } from '../types/rounded'
import { SiteShadow } from '../types/shadows'
import isNotUndefined from '../utils/isNotUndefined'
import * as css from './Column.module.scss'

export type Span = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export type Spans = readonly [Span, Span?, Span?]

// TODO Later need to move to global along with helper class if needed.
export type SiteMargin = 'large' | 'medium' | 'none' | 'small'

export type ColumnProps = {
  /** Determines how a column will align itself (vertically) within any leftover space. Defaults to whatever Row alignItems has defined.  */
  readonly alignSelf?: 'center' | 'end' | 'start' | 'stretch'
  /** Sets the background color class. Expects a valid SiteColor value. */
  readonly backgroundColor?: SiteColor
  /** Content to be placed inside this Column. */
  readonly children?: ReactNode
  /** Determines if children of Column are height: 100%. Useful for grids of components that must be equal in height, such as cards. Defaults to false. */
  readonly equalHeightChildren?: boolean
  /** If columns are split into rows, this column will move to the top. Used for media to show up before text on mobile. */
  readonly firstRow?: boolean
  /** Sets if the column should have a minimum and maximum height. */
  readonly height?: 'any' | 'fixed'
  /** Determines how a column will align itself (horizontally) within any leftover space. Defaults to 'stretch'.  */
  readonly justifySelf?: 'center' | 'end' | 'start' | 'stretch'
  /** Left & Right Margin outside the column. */
  readonly marginLeftRight?: SiteMargin
  /** Padding inside the column. */
  readonly padding?: SitePadding
  /** Border radius */
  readonly rounded?: SiteRounded
  /** Column spans out of 12 for mobile, tablet, and desktop. */
  readonly spans?: Spans
  /** Defines a box-shadow style. Expects a valid SiteShadow value. */
  readonly shadow?: SiteShadow
  /** data-component attribute value */
  readonly dataComponent?: string
  /** Classnames for custom styles */
  readonly className?: string
}

/** @deprecated Do not use ss-react-components*/
const Column: FC<ColumnProps> = ({
  alignSelf,
  backgroundColor,
  children,
  equalHeightChildren,
  marginLeftRight,
  padding,
  spans = [12],
  firstRow = false,
  height,
  justifySelf,
  rounded = 'base',
  shadow,
  className,
  dataComponent = Column.name
}: ColumnProps) => {
  const classes = classNames(
    {
      'rounded-base overflow-hidden': rounded === 'base',
      'rounded-none': rounded === 'none'
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    { [css[`mobile${spans[0]}`]]: isNotUndefined(spans[0]) },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    { [css[`tablet${spans[1]}`]]: isNotUndefined(spans[1]) },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    { [css[`desktop${spans[2]}`]]: isNotUndefined(spans[2]) },
    { [css.equalHeightChildren]: equalHeightChildren },
    { 'self-start': alignSelf === 'start' },
    { 'self-center': alignSelf === 'center' },
    { 'self-stretch': alignSelf === 'stretch' },
    { 'self-end': alignSelf === 'end' },
    { 'justify-self-start': justifySelf === 'start' },
    { 'justify-self-center': justifySelf === 'center' },
    { 'justify-self-stretch': justifySelf === 'stretch' },
    { 'justify-self-end': justifySelf === 'end' },
    { 'lg:mx-lg': marginLeftRight === 'large' },
    { 'p-4': padding === 'small' },
    { 'py-8 px-4 md:py-8': padding === 'medium' },
    { 'py-8 px-4 md:py-8 lg:p-16': padding === 'large' },
    { 'lg:mx-md': marginLeftRight === 'medium' },
    { 'lg:mx-sm': marginLeftRight === 'small' },
    { [css.fixedHeight]: height === 'fixed' },
    { [css.first]: firstRow },
    {
      [`${backgroundColor}BackgroundColor`]:
        isNotUndefined(backgroundColor) && backgroundColor !== 'none'
    },
    { shadowSoft: shadow === 'soft' },
    { shadowSoftBottom: shadow === 'softBottom' },
    { shadowMedium: shadow === 'medium' },
    { shadowStrong: shadow === 'strong' },
    css.column,
    className
  )
  return (
    <div className={classes} data-component={dataComponent}>
      {children}
    </div>
  )
}

export default Column
