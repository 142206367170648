import { graphql, useStaticQuery } from 'gatsby'

import { nhwWrappedSchema } from './schema'

const useNeighbourhoodWatchQuery = () => {
  return nhwWrappedSchema.parse(
    useStaticQuery(graphql`
      #graphql
      query NeighbourhoodWatchQuery {
        quoteWizardAsset: contentfulAsset(
          contentful_id: { eq: "4mI9KaGvxh51fi1GYOSaVr" }
        ) {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
        appFeaturesCarouselAssets: allContentfulAsset(
          filter: {
            contentful_id: {
              in: [
                "6rmX3DElVdPZn4Hw3JNqV9"
                "50wlqz44dQwAzOoxzmPO90"
                "38iheGuzSVg8kPQ1aokAnw"
                "ZYB8WxMtOe454lse5FG2D"
                "6LT6dvk157aAfFdKyeG2Kf"
              ]
            }
          }
        ) {
          nodes {
            contentful_id
            id
            title
            description
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        expertReviews: contentfulJson(
          contentful_id: { eq: "2143Uo0jib8jsfRyWv2DDQ" }
        ) {
          ...json
        }
        expertReviewsAssets: allContentfulAsset(
          filter: {
            contentful_id: {
              in: ["5X5OsfxAW6Fc2Zj3HZDlHC", "7eH4KhA049wM5ZTOOmWyWd"]
            }
          }
        ) {
          nodes {
            contentful_id
            gatsbyImageData(width: 320)
            description
          }
        }
        guaranteeAsset: contentfulAsset(
          contentful_id: { eq: "5FRJNkZAaZL4mHk6vuMNVb" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
        quoteWizard: contentfulQuoteWizard(
          contentful_id: { eq: "6wRdF53o74w2MhOhBIkzip" }
        ) {
          __typename
          id: contentful_id
          contentful_id
          jebbitUrl
          type
        }
        guaranteeCopy: contentfulJson(
          contentful_id: { eq: "5bXaWtcoGF04HVmWb5Kuoe" }
        ) {
          ...json
        }
        expertSectionAsset: contentfulAsset(
          contentful_id: { eq: "4l8DenaxSNBl1LH3mmDLMv" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        comparisonTable: contentfulJson(
          contentful_id: { eq: "4YjQ579QrpjyGYVax6Keoo" }
        ) {
          content {
            internal {
              content
            }
          }
        }
        nhwLogo: contentfulAsset(
          contentful_id: { eq: "6CkYI9FPyTNWu6GvsMao2n" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
        nhwDescription: contentfulJson(
          contentful_id: { eq: "7HH7RWCIsC6K47dixMgmza" }
        ) {
          ...json
        }
        ceoComment: contentfulJson(
          contentful_id: { eq: "5q7zuIWtyzBzYHa1uVXtA9" }
        ) {
          ...json
        }
      }
    `)
  )
}
export { useNeighbourhoodWatchQuery }
