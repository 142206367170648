function Add({ testId }: { readonly testId: string }) {
  return (
    <svg
      data-testid={`btn-add-${testId}`}
      height="44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Add</title>
      <path
        d="M22 0c12.150264 0 22 9.8497355 22 22s-9.849736 22-22 22S0 34.1502645 0 22 9.849736 0 22 0zm0 4C12.058875 4 4 12.0588745 4 22s8.058875 18 18 18 18-8.0588745 18-18S31.941125 4 22 4zm0 8c1.025672 0 1.871014.7720804 1.986545 1.7667577L24 14v6h6c1.104569 0 2 .8954305 2 2 0 1.0256717-.77208 1.8710143-1.766758 1.9865445L30 24h-6v6c0 1.1045695-.895431 2-2 2-1.025672 0-1.871014-.7720804-1.986545-1.7667577L20 30v-6h-6c-1.104569 0-2-.8954305-2-2 0-1.0256717.77208-1.8710143 1.766758-1.9865445L14 20h6v-6c0-1.1045695.895431-2 2-2z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default Add
