import {
  CAMERA_BANNER_ASSET_QUERY,
  cameraBannnerAssetSchema,
  CONFIDENCE_BAR_QUERY,
  confidenceBarSchema,
  GUARANTEE_ASSET_QUERY,
  GUARANTEE_COPY_QUERY,
  guaranteeAssetSchema,
  guaranteeCopySchema,
  VIDEO_BUTTON_ASSET_QUERY,
  videoButtonAssetSchema
} from '@ecomm/scout'
import { useContentful } from '@ecomm/shared-apollo'
import { Map as ImmutableMap } from 'immutable'
import { useMemo } from 'react'

export const useCameraBannerAssetQuery = () => {
  const { data } = useContentful(CAMERA_BANNER_ASSET_QUERY)
  return useMemo(() => cameraBannnerAssetSchema.parse(data), [data])
}

export const useGuaranteeCopyQuery = () => {
  const { data } = useContentful(GUARANTEE_COPY_QUERY)
  const guaranteeCopy = useMemo(() => guaranteeCopySchema.parse(data), [data])
  return ImmutableMap(guaranteeCopy.guaranteeCopy.content)
}

export const useVideoButtonAssetQuery = () => {
  const { data } = useContentful(VIDEO_BUTTON_ASSET_QUERY)
  return useMemo(() => videoButtonAssetSchema.parse(data), [data])
}

export const useConfidenceBarQuery = (id: string) => {
  const { data } = useContentful(CONFIDENCE_BAR_QUERY, { id })
  return useMemo(() => confidenceBarSchema.parse(data)?.confidenceBar, [data])
}

export const useGuaranteeAssetQuery = () => {
  const { data } = useContentful(GUARANTEE_ASSET_QUERY)
  return useMemo(() => guaranteeAssetSchema.parse(data), [data])
}
