import { LoadingSpinner } from '@ecomm/checkout-icons'
import {
  type LeadGenCaptureProps,
  type LeadGenResponseProps,
  leadGenCapture
} from '@ecomm/data-leads'
import { useLeadCaptureEmailSubmitted } from '@ecomm/promotions-hooks'
import {
  ContentfulRichText,
  GatsbyImage,
  Modal
} from '@ecomm/shared-components'
import {
  COOKIE_LEAD_DATA,
  getLeadData,
  useCookieChange
} from '@ecomm/shared-cookies'
import {
  ReferrerUrlContext,
  setBrazeAttributeGoogleClientId,
  useLeadGenCaptureV2,
  useGoogleAnalytics,
  useOptimizelyTrackSiteEvents
} from '@ecomm/tracking'
import { trackLeadCapture } from '@ecomm/tracking'
import { IOSaveMySystem } from '@simplisafe/ss-ecomm-data/cart'
import { selectCurrentPromoCode } from '@simplisafe/ss-ecomm-data/redux/select'
import type { FormikHelpers } from 'formik'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as R from 'fp-ts/lib/Record'
import { useAtom } from 'jotai'
import { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'

import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'
import EmailSubmissionForm from './components/EmailSubmissionForm'
import { OpenModalButton } from './components/OpenModalButton'
import SuccessView from './components/SuccessView'
import { SKUS_THAT_DO_NOT_ENABLE_MODAL_TRIGGER } from './constants'
import type { SaveMySystemModalFragment } from './types'

import { ODMON_OVERNIGHT_MONITORING, SHIELD_SKU } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'

const mapLineItemToRequestBody = ([sku, quantity]: readonly [
  string,
  number
]) => ({
  quantity,
  sku
})

const skusThatDoNotEnableModalTrigger = ([sku, _]: readonly [string, number]) =>
  !SKUS_THAT_DO_NOT_ENABLE_MODAL_TRIGGER.includes(sku)

const quantityIsZero = ([_, quantity]: readonly [string, number]) =>
  quantity === 0

function SaveMySystemModal({
  button,
  description,
  disclaimer,
  email,
  headline,
  image,
  modalTrigger,
  success
}: SaveMySystemModalFragment) {
  const [clientId] = useGoogleAnalytics() || [null]
  const locale = useLocale()
  const [items] = useAtom(itemQuantityAtom)
  const currentPromoCode = useSelector(selectCurrentPromoCode)
  useCookieChange(COOKIE_LEAD_DATA, data =>
    setEmailAddress(
      pipe(
        JSON.parse(data),
        R.lookup('email'),
        O.getOrElse(() => '')
      )
    )
  )
  const lineItems = Array.from(items).map(mapLineItemToRequestBody)
  const dispatch = useDispatch()
  const { trackEvent } = useTracking()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const isOdmonVariant = useOdmonExperience().isVariant

  const { setIsSubmitted } = useLeadCaptureEmailSubmitted()

  const [isOpen, setIsOpen] = useState(false)
  const [emailAddress, setEmailAddress] = useState<string>(
    pipe(
      getLeadData(),
      O.fromNullable,
      O.chain(R.lookup('email')),
      O.getOrElse(() => '')
    )
  )
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const channel = useContext(ReferrerUrlContext).channel

  const successMessage = success?.raw ? (
    <ContentfulRichText
      raw={success.raw.replace(/{{email_address}}/g, emailAddress)}
    />
  ) : null
  const shouldDisplaySuccessView =
    Boolean(isEmailSent) && Boolean(successMessage)
  const shouldDisplayForm = Boolean(email)
  const disableOpenModalButton = useMemo(
    () =>
      Array.from(items)
        .filter(skusThatDoNotEnableModalTrigger)
        .every(quantityIsZero),
    [items]
  )

  const leadGenCaptureV2 = useLeadGenCaptureV2()

  const handleSubmit = (
    values: { readonly email: string },
    { setSubmitting, setFieldError }: FormikHelpers<{ readonly email: string }>
  ) => {
    setEmailAddress(values.email)

    const leadBody: LeadGenCaptureProps = {
      email: values.email,
      leadSource: 'exit_intent',
      promoCode: currentPromoCode.getOrElse('NO_CODE'),
      source: 'bms quote',
      sourceType: 'save_system'
    }

    const handleLeadError = () => {
      setIsLoading(false)
      setFieldError('email', 'Please enter a valid email address.')
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    const handleLeadSuccess = (response: LeadGenResponseProps) => {
      setIsLoading(false)

      const externalId = pipe(
        R.lookup('externalId', response),
        O.getOrElse(() => '')
      )
      const leadId = pipe(
        R.lookup('leadId', response),
        O.getOrElse(() => '')
      )

      const lineItemsHaveOutdoorCamera = lineItems.some(
        item => item.sku === SHIELD_SKU
      )
      const updatedLineItems =
        isOdmonVariant && lineItemsHaveOutdoorCamera
          ? [...lineItems, { sku: ODMON_OVERNIGHT_MONITORING, quantity: 1 }]
          : lineItems

      const saveMySystemBody = {
        email: values.email,
        externalId: externalId,
        leadId: leadId,
        products: updatedLineItems
      }

      // Don't wait for success/failure of IOSaveMySystem call, and immediately assume email is sent to reduce UI loading time
      dispatch(
        IOSaveMySystem(
          saveMySystemBody,
          () => null,
          () => null
        )
      )

      trackLeadCapture(response, trackEvent, optimizelyTrackSiteEvents, channel)

      // set google client id for UK as custom braze attribute ECP-12023
      locale === 'en-GB' &&
        clientId &&
        setBrazeAttributeGoogleClientId(clientId)

      console.info(
        `In SaveMySystemModal handleSubmit called trackLeadCapture with ${JSON.stringify(
          { leadId: response.leadId, externalId: response.externalId }
        )}`
      )
      // When a lead is captured from any source, hide PromoBanner lead form ECP-10763
      setIsSubmitted(true)
      setIsEmailSent(true)
      optimizelyTrackSiteEvents({ eventType: 'saved_system' })
      setSubmitting(false)
    }

    const sendLeadsRequest = () => {
      setIsLoading(true)
      leadGenCapture(leadBody)()
        .then(res => {
          return pipe(
            res,
            E.match(_ => {
              handleLeadError()
              return {}
            }, handleLeadSuccess)
          )
        })
        .catch(_ => handleLeadError())

      leadGenCaptureV2({
        source: 'bms quote',
        siteMetadata: { sourceType: 'save_system' },
        sourceDetail: 'exit_intent',
        email: values.email
      })
    }

    sendLeadsRequest()
  }

  const emailForm = email?.form || []
  const emailFormLabel = emailForm[0]?.title
  const emailFormPlaceholder = emailForm[0]?.placeholder

  return (
    <section>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        {image ? <GatsbyImage image={image} /> : null}
        <div className="mx-16 mb-12 mt-4">
          <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
            <h3>{headline}</h3>
            <ContentfulRichText raw={description?.raw} />
          </div>
          {shouldDisplaySuccessView ? (
            <SuccessView {...{ successMessage }} />
          ) : null}
          {isLoading ? (
            <div className="mt-4 flex justify-center">
              <LoadingSpinner
                className="text-primary-100"
                fillColor="currentColor"
                size={48}
              />
            </div>
          ) : null}
          {shouldDisplayForm && !shouldDisplaySuccessView ? (
            <EmailSubmissionForm
              buttonText={button?.text}
              disclaimerRaw={disclaimer?.raw}
              emailAddress={emailAddress}
              label={emailFormLabel}
              onSubmit={handleSubmit}
              placeholder={emailFormPlaceholder}
            />
          ) : null}
        </div>
      </Modal>
      {modalTrigger?.text ? (
        <OpenModalButton
          buttonText={modalTrigger.text}
          disabled={disableOpenModalButton}
          hideOnDisable={true}
          onClick={() => setIsOpen(true)}
        />
      ) : null}
    </section>
  )
}

export default SaveMySystemModal
