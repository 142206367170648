import { z } from 'zod'

import { countryRegex } from './regex'

export const countrySchema = z
  .string({ required_error: 'Country is a required field' })
  .refine(
    val => countryRegex.test(val),
    val => ({ message: `${val} is not a valid country` })
  )
