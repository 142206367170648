import { useLocale } from '@ecomm/data-hooks'
import { getPartnerCookie, getVisitorId } from '@ecomm/shared-cookies'
import { window } from 'browser-monads-ts'
import { selectCurrentPromoCode } from '@simplisafe/ss-ecomm-data/redux/select'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { isValidUrl, get as sessionStorageGet } from '@ecomm/utils'

import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

import { useGoogleAnalytics } from '../useGoogleAnalytics'
import { ReferrerUrlContext } from '../TrackingProvider/useReferrerUrl'
import { SESSION_STORAGE_FIRST_VISITED_HREF } from '../TrackingProvider/index'
import { voidFn } from '@simplisafe/ewok'
import {
  handleLeadCaptureV2Failure,
  leadGenCaptureV2,
  type LeadGenCaptureV2Props
} from './leadGenCaptureV2'
import { isRight } from 'fp-ts/lib/Either'

/**
 * In this hook we gather data required by the new eis-leads service POST /v1/leads endpoint
 * as described here https://simplisafe.atlassian.net/wiki/spaces/EIS/pages/4056154277/EIS+Leads+Feature+Brief#POST-%2Fv1%2Fleads
 *
 * The V2 suffix in its name is because this is intended to replace the current ecomm-leads service
 * in the future. See https://simplisafe.atlassian.net/wiki/spaces/EIS/pages/4056154277/EIS+Leads+Feature+Brief#Steps
 *
 * @returns Function that takes LeadGenCaptureV2Props and can be used to call the eis-leads POST /v1/leads endpoint with the provided data.
 */
export function useLeadGenCaptureV2() {
  const [clientId] = useGoogleAnalytics()
  const { channel } = useContext(ReferrerUrlContext)

  // We need to keep track of the user's first visited URL within a session
  // If a user navigates to a different page and submits a lead form on that page, we want to capture their channel based on the first page that they visited
  // We can't rely on referrer in this case, as it will be set to the SimpliSafe Site URL once the user navigates to another page
  const firstVisitedHref = sessionStorageGet(SESSION_STORAGE_FIRST_VISITED_HREF)
  const leadFirstUrl = isValidUrl(firstVisitedHref)
    ? new URL(firstVisitedHref)
    : window.location
  const { pathname: urlPath, search } = leadFirstUrl

  const locale = useLocale()
  const currentPromoCode = useSelector(selectCurrentPromoCode)

  return useCallback(
    (data: LeadGenCaptureV2Props) => {
      const partnerData = getPartnerCookie()
      const vid = getVisitorId()
      const deviceType = getDeviceType().toLowerCase()
      const queryParams = new URLSearchParams(search)
      const utmSource = queryParams.get('utm_source') || ''
      const utmMedium = queryParams.get('utm_medium') || ''
      const utmCampaign = queryParams.get('utm_campaign') || ''
      const country = locale === 'en-US' ? 'US' : 'GB'

      const siteMetadata = {
        rudderstackId: clientId,
        partner: partnerData?.partnerName,
        vid,
        channel,
        urlPath,
        deviceType,
        promoOffer: currentPromoCode.getOrElse('NO_CODE'),
        utmSource,
        utmMedium,
        utmCampaign,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
      pipe(
        TE.tryCatch(
          leadGenCaptureV2({
            country,
            ...data,
            siteMetadata: {
              ...siteMetadata,
              ...data.siteMetadata
            }
          }),
          reason => new Error(String(reason))
        ),
        TE.chain(result =>
          isRight(result) ? TE.right(result.right) : TE.left(result.left)
        ),
        TE.match(handleLeadCaptureV2Failure, voidFn)
      )()
    },
    [channel, clientId, urlPath, search, locale, currentPromoCode]
  )
}
