/**
 * This list represents the QA environments with a payment form set up in Zuora
 * and NO variation form
 */
export const qaPaymentEnvsWithoutVariation = [
  'https://uk-bofu-1.qa.commerce.simplisafe.com',
  'https://uk-bofu-2.qa.commerce.simplisafe.co.uk',
  'https://us-bofu-4.qa.commerce.simplisafe.com',
  'https://uk-bofu-4.qa.commerce.simplisafe.com',
  'https://us-bofu-5.qa.commerce.simplisafe.com',
  'https://uk-bofu-5.qa.commerce.simplisafe.com',
  'https://us-midfunnel-1.qa.commerce.simplisafe.com',
  'https://uk-midfunnel-1.qa.commerce.simplisafe.com',
  'https://us-midfunnel-4.qa.commerce.simplisafe.com',
  'https://uk-midfunnel-4.qa.commerce.simplisafe.com',
  'https://us-top-funnel.qa.commerce.simplisafe.com',
  'https://uk-top-funnel.qa.commerce.simplisafe.com',
  'https://us-top-funnel-2.qa.commerce.simplisafe.com',
  'https://uk-top-funnel-2.qa.commerce.simplisafe.com',
  'https://us-platform-3.qa.commerce.simplisafe.com',
  'https://uk-platform-3.qa.commerce.simplisafe.com'
]

/**
 * This list represents the QA environments with a payment form set up in Zuora
 * AND a variation payment form set up in Zuora
 */
export const qaPaymentEnvsWithVariation = [
  'https://us-bofu-1.qa.commerce.simplisafe.com',
  'https://us-bofu-2.qa.commerce.simplisafe.com',
  'https://us-bofu-3.qa.commerce.simplisafe.com',
  'https://uk-bofu-3.qa.commerce.simplisafe.com'
]
