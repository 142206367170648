import { useNinetailedFeatureFlag } from '..'

export const useOdmonExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '15AAdXWYGGLjW7WaZYuxK6' })

export const usePlanChoiceExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '5IjWWxTlRr75piRySVLNEt' })

export const usePlanChoiceNonCameraPushExperience = () =>
  useNinetailedFeatureFlag({ baselineId: 'FrsJwf42vVfkf0V82LoX0' })

export const usePlanChoiceNonCameraStayExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '6A1FNq5yR3mXyV3HRLTdda' })

export const usePlanChoiceDynamicNonCameraPushExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '5AtYB6NfqWMEqHPAATwszb' })

export const usePlanChoiceDynamicNonCameraStayExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '2uyqlam8bS9kEU7uJiS6y5' })

export const usePlanChoiceDynamicCameraPushExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '2x4wzUbAlbyMDXKu4Lb4jV' })

export const usePlanChoiceDynamicCameraStayExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '1g6supREDt7p6qdgrAMJkO' })

export const useOdmonDynamicPackagesExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '2ucPdNURhBiGOa1YjpG6t7' })
