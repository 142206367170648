import { getVisitorId } from '@ecomm/shared-cookies'
import { getRudderstack } from '@ecomm/shared-window'
import { noValue } from '@ecomm/utils'

// extend type if additional properties are required, or default properties need to be overridden
type Properties = {
  readonly referrer?: string
  readonly search?: string
  readonly title?: string
}

/**
 * Track page view.
 *
 * This abstraction will always include the visitor id as a property in the event. Since page
 * views are fired independently from identify calls, the visitor id is not always guaranteed
 * to be present in downstream destinations. This ensures that the visitor id is always present, even
 * if the users page view is tracked and the identify call is not(i.e. user bounces out of the site).
 *
 * https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#page
 *
 * By default, Rudder SDK will include initial_referrer, initial_referring_domain,
 * referrer, referrer_domain, url, path, title, search, tab_url as event properties. Manually
 * setting these values will override the default sdk behavior.
 */
export function trackPage(properties?: Properties) {
  // category and name arguments not leveraged
  getRudderstack(r =>
    r.page(noValue(), noValue(), {
      ...properties,
      vid: getVisitorId()
    })
  )
}
