import {
  ContentfulImage,
  type ContentfulImageSchema
} from '@ecomm/contentful/components'
import {
  type GuidedSystemBuilderSchema,
  QuoteWizardModal
} from '@ecomm/shared-components'
import { useTrackHomepage } from '@ecomm/tracking'
import { Link } from 'gatsby'
import React, { useState } from 'react'

export type GuaranteeSectionProps = {
  readonly quoteWizard?: GuidedSystemBuilderSchema | null
  readonly image?: ContentfulImageSchema | null
  readonly copy: ReadonlyMap<string, string>
}

export function GuaranteeSection({
  quoteWizard = null,
  image = null,
  copy
}: GuaranteeSectionProps) {
  const trackButtonClicks = useTrackHomepage()

  const imageTag = image?.url && (
    <ContentfulImage
      {...image}
      classNameOverride="w-full max-w-[392px]"
      width={392}
    />
  )

  const [showModal, setShowModal] = useState(false)
  const handleSetShowModalClick = (isShowing: boolean) => {
    setShowModal(isShowing)
    trackButtonClicks('button-click-take-our-quiz-guarantee-component')
  }

  return (
    <div
      className="bg-neutral-black flex items-center justify-between rounded-2xl px-9 py-9 md:px-11 lg:py-7"
      data-component="Guarantee"
      data-testid="Guarantee"
    >
      <div className="hidden h-[124px] w-[124px] basis-auto md:block">
        {imageTag}
      </div>
      <div className="flex basis-full flex-wrap justify-around md:basis-11/12">
        <h2 className="lg:leading-h2-height my-0 mb-6 basis-auto text-center text-[28px] leading-8 text-white md:mx-8 md:pt-2 md:text-left lg:mb-0 xl:text-4xl">
          {copy.get('text_1')}
        </h2>
        <div className="mb-7 md:hidden w-full flex justify-center">
          {imageTag}
        </div>
        <div className="flex w-full basis-full flex-wrap justify-between gap-4 md:w-auto md:basis-auto md:flex-nowrap">
          <button
            className="btn btn-solid-primary w-full md:w-fit"
            onClick={() => {
              handleSetShowModalClick(true)
            }}
          >
            {copy.get('text_2')}
          </button>
          {quoteWizard ? (
            <QuoteWizardModal
              // hard coding type here instead of querying another QW
              data={{ ...quoteWizard, type: 'guarantee' }}
              data-component="QWModal"
              onRequestClose={() => setShowModal(false)}
              show={showModal}
            />
          ) : null}
          <Link
            className="btn btn-outlined btn-outlined-tertiary !px-4 flex w-full items-center justify-center md:w-fit"
            onClick={() =>
              trackButtonClicks(
                'button-click-build-a-system-guarantee-component'
              )
            }
            to="/build-my-system"
          >
            {copy.get('text_3')}
          </Link>
        </div>
      </div>
    </div>
  )
}
