import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

const useTrackingBmsShieldCameraAccesoriesExpand = (productName: string) => {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(() => {
    trackEvent({
      event: 'bms_shield_camera_accesories_expand',
      pageTitle: document.title,
      sensor: productName
    })
  }, [trackEvent, productName])
}

export default useTrackingBmsShieldCameraAccesoriesExpand
