import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { schema } from './schema'
import { data } from './scoutProductPageData'

export const useScoutProductPageAlarmMomentsData = () => {
  const scoutProductPageAlarmMomentsData = useLocalizedData<typeof schema>(
    schema,
    data
  )

  return useMemo(
    () => scoutProductPageAlarmMomentsData,
    [scoutProductPageAlarmMomentsData]
  )
}
