import classNames from 'classnames'
import React from 'react'

import { FloatingOpenModalButtonProps } from '../types'

export function OpenModalButton({
  buttonText,
  onClick,
  disabled,
  hideOnDisable
}: FloatingOpenModalButtonProps) {
  return (
    <button
      className={classNames(
        'w-auto border-0 prose md:prose-md lg:prose-lg btn px-2 shadow-md md:px-5',
        {
          'bg-neutral-light-50 hover:border-neutral-light-100 hover:bg-neutral-light-100':
            !disabled,
          'btn-disabled': disabled,
          hidden: hideOnDisable && disabled
        }
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonText}
    </button>
  )
}
