//@ts-nocheck
import { getTrustPilot } from '@ecomm/shared-window'
import React, { FC, ReactNode, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

import useMediaQuery from '../hooks/useMediaQuery'

export type TrustpilotWidget = {
  readonly dataBusinessunitId: string
  readonly dataLocale: string
  readonly dataStars: string
  readonly dataStyleHeight: string
  readonly dataStyleWidth: string
  readonly dataTemplateId: string
  readonly dataTheme: string
}

export type TrustpilotReviewBannerProps = {
  /** Mobile widget created in Trustpilot */
  readonly mobileWidget?: TrustpilotWidget
  /** Desktop and tablet widget created in Trustpilot */
  readonly desktopWidget?: TrustpilotWidget
  /** fallback if Trustpilot doesn't load */
  readonly children?: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
}

/**
 * Automatically injects Trustpilot code into header when used.
 * You can pass it a mobile and deskopt/tablet widget, or just one widget for both.
 */
/** @deprecated Do not use ss-react-components*/
const TrustpilotReviewBanner: FC<TrustpilotReviewBannerProps> = ({
  mobileWidget,
  desktopWidget,
  children,
  dataComponent = TrustpilotReviewBanner.name
}: TrustpilotReviewBannerProps) => {
  /** True if the device is a tablet or desktop */
  const isTabletAndUp = useMediaQuery('TabletAndUp') || false

  /**
   * We want to use the correct widget for desktop or tablet
   * if it's available, but since both are optinal we want to fallback
   * if needed
   */
  const widget = isTabletAndUp
    ? desktopWidget || mobileWidget
    : mobileWidget || desktopWidget

  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null)

  /** If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
   * If it's not, it means the script isn't loaded yet.
   * When it is, it will automatically load the TrustBox.
   */
  useEffect(() => {
    const current = ref.current
    current &&
      getTrustPilot(Trustpilot => Trustpilot.loadFromElement(current, true))
  }, [])

  /** since widget types are optional we need to check that at least one is defined */
  return widget ? (
    <>
      <Helmet>
        <script
          async
          src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          type="text/javascript"
        ></script>
      </Helmet>

      <div data-component={dataComponent}>
        <div
          className="trustpilot-widget pointer-events-none -mb-4 cursor-pointer pb-2 pt-6 md:-mb-8 md:pb-0 md:pt-8"
          data-businessunit-id={widget.dataBusinessunitId}
          data-locale={widget.dataLocale}
          data-stars={widget.dataStars}
          data-style-height={widget.dataStyleHeight}
          data-style-width={widget.dataStyleWidth}
          data-template-id={widget.dataTemplateId}
          data-test-tablet={isTabletAndUp}
          data-theme={widget.dataTheme}
          ref={ref}
        >
          <div className="w-full text-center">{children}</div>
        </div>
      </div>
    </>
  ) : null
}

export default TrustpilotReviewBanner
