import { Span, Spans } from '@ecomm/ss-react-components'
import prop from 'ramda/src/prop'

import { ContentfulHeroBannerColumn } from '../../../graphql'

export const calculateSpans = (
  data: ContentfulHeroBannerColumn,
  colsLength: number
): Spans => {
  const mobileColSpan = prop<Span>('mobileColumnSpan', data)
  const tabletColSpan = prop<Span>('tabletColumnSpan', data)
  const desktopColSpan = prop<Span>('desktopColumnSpan', data)
  const tabletUpSpanSize = colsLength > 1 ? 6 : 12

  return [
    mobileColSpan || 12,
    tabletColSpan || 12,
    desktopColSpan || tabletUpSpanSize
  ]
}

const fullWidthSpan: Span = 12
const tabletColSpanWithSticker: Span = 8
export const colSpansWithSticker: Spans = [
  fullWidthSpan,
  tabletColSpanWithSticker,
  tabletColSpanWithSticker
]
export const colSpansWithoutSticker: Spans = [fullWidthSpan]

const stickerSpan: Span = 4
export const stickerSpans: Spans = [stickerSpan]
