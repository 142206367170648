import { GatsbyImage } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import type { FeatureSectionMediaSchema } from './schema'

export function FeatureSectionMedia({
  media,
  background = false,
  mediaClassName = ''
}: {
  readonly media: FeatureSectionMediaSchema
  readonly background?: boolean
  readonly mediaClassName?: string
}) {
  const contentType = media?.mimeType
  return contentType === 'video/mp4' ? (
    <video
      autoPlay
      className="rounded-base w-full"
      controls
      data-testid="FeatureSectionMediaVideo"
      key={media?.title}
      loop
      muted
      playsInline
      preload="auto"
    >
      {/* @ts-expect-error */}
      <source src={media?.file?.url} type={media?.file?.contentType} />
    </video>
  ) : (
    <div
      className={classNames({
        'absolute left-0 top-0 z-0 h-full w-full': background
      })}
      data-testid="feature-section-media"
    >
      <GatsbyImage
        className={`rounded-base h-full w-full ${mediaClassName}`}
        //  @ts-expect-error
        image={media}
      />
    </div>
  )
}
