import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import React from 'react'

import type { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import Package from '.'
import type { PackageFragment } from './schema'

type Props = {
  // package is a reserved keyword in TS strict mode
  readonly pkg: PackageFragment
  readonly attributeHash: string
  readonly metaTitle: string
  readonly metaDescription: string
}

function AffirmPackageWrapper({
  pkg,
  attributeHash,
  metaDescription,
  metaTitle
}: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <Package
      attributeHash={attributeHash}
      isAffirmExperience={isAffirmExperience}
      metaDescription={metaDescription}
      metaTitle={metaTitle}
      pkg={{ ...pkg }}
    />
  )
}

export default AffirmPackageWrapper
