import { ContentfulImage } from '@ecomm/contentful/components'
import { usePriceContext } from '@ecomm/data-price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { PackageGridSchema } from '../../templates/BusinessSecurity/schema'

export default function PackageGrid({ gridItems }: PackageGridSchema) {
  const { getPrice, formatPrice, getDiscountedPriceWithServicePlan } =
    usePriceContext()

  return (
    <div className="flex flex-col gap-4 md:flex-row md:gap-8 lg:gap-12">
      {gridItems.map((item, i) => (
        <div
          className="bg-neutral-light-50 rounded-base relative overflow-hidden md:basis-1/3"
          data-component="PackageCard"
          key={`package-${i}`}
        >
          <ContentfulImage
            className="h-72 w-full"
            description={item.image.description}
            originalHeight={item.image.file.details.image.height}
            originalWidth={item.image.file.details.image.width}
            url={item.image.file.url}
          />
          <div className="flex flex-col p-4 md:px-6 md:pb-6 lg:px-8 lg:pb-8">
            <p className="mb-0 font-bold">{item.displayName}</p>
            <div className="mt-4 flex items-center justify-between">
              <p className="mb-0 flex items-center gap-2">
                <span className="text-complementary-red-100">
                  {pipe(
                    formatPrice(getDiscountedPriceWithServicePlan(item.sku)),
                    O.getOrElse(() => '')
                  )}
                </span>
                <span className="line-through">
                  {pipe(
                    formatPrice(getPrice(item.sku)),
                    O.getOrElse(() => '')
                  )}
                </span>
              </p>
              <a className="btn btn-solid-primary" href={item.slug}>
                See details
              </a>
            </div>
          </div>
          <span className="bg-neutral-dark absolute right-0 top-6 px-4 py-1 text-white ">
            {item.products.length} pieces
          </span>
        </div>
      ))}
    </div>
  )
}
