import { graphql, useStaticQuery } from 'gatsby'

import { monitoringSchema } from './schema'

export const useMonitoringQuery = () => {
  return monitoringSchema.parse(
    useStaticQuery(graphql`
      query MonitoringQuery {
        twoMonths: contentfulJson(contentful_id: { eq: "1PzV6Wu4yGCtsQTYYBpTdi" }) {
          content {
            internal {
              content
            }
          }
        }
        oneMonth: contentfulJson(contentful_id: { eq: "QSAROh9FK7ZoRP7ocJSLR" }) {
          content {
            internal {
              content
            }
          }
        }
      }
    `)
  )
}
