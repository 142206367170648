import { TypeOf, z } from '@simplisafe/ewok'

export const contentfulJsonSchema = z.object({
  content: z.object({
    internal: z.object({
      content: z.string()
    })
  })
})

export const monitoringSchema = z.object({
  twoMonths: contentfulJsonSchema,
  oneMonth: contentfulJsonSchema.nullable()
})

export type MonitoringSchema = TypeOf<typeof monitoringSchema>
