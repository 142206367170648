import {
  getExpertReviews,
  useGuaranteeAssetQuery,
  useGuaranteeCopyQuery
} from '@ecomm/lander'
import { QuizBanner } from '@ecomm/lander'
import {
  type ExpertsReviewsProps,
  ConfidenceBar,
  ExpertSection,
  ExpertsReviews,
  FAQs,
  GuaranteeSection,
  parseJSONDataFromContentful
} from '@ecomm/scout'
import { useMoversPageFAQsData } from '@ecomm/scout/src/components/FAQs/useMoversPageFAQsData'
import { type UserReviewsProps, UserReviews } from '@ecomm/shared-components'
import { useEnv } from '@ecomm/utils'
import React from 'react'

import {
  useMoversComponentsQuery,
  useStaticMoversData
} from './useMoversComponentsQuery'

export function MoversComponents() {
  const staticQuery = useMoversComponentsQuery()
  const { locale } = useEnv()
  const faqsData = useMoversPageFAQsData()
  const { confidenceBarData } = useStaticMoversData()
  const guaranteeAsset = useGuaranteeAssetQuery()

  const expertReviewsData: ExpertsReviewsProps = parseJSONDataFromContentful(
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    staticQuery,
    'expertReviews'
  )
  const expertReviews = getExpertReviews(
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    staticQuery.expertReviewsAssets.nodes,
    locale,
    expertReviewsData.reviews
  )

  const guaranteeSectionCopy = useGuaranteeCopyQuery()
  const usersReviews: UserReviewsProps = parseJSONDataFromContentful(
    // @ts-expect-error - this needs a zod schema to change the type from unknown

    staticQuery,
    'usersReviews'
  )
  const expertsSection = {
    // @ts-expect-error - this needs a zod schema to change the type from unknown
    image: staticQuery.expertSectionAsset,
    title: 'Need help? Our experts are here.'
  }

  return (
    <>
      <ConfidenceBar data={confidenceBarData.content} />
      <ExpertsReviews reviews={expertReviews} />
      <QuizBanner
        // @ts-expect-error - this needs a zod schema to change the type from unknown
        image={staticQuery.quoteWizardAsset}
        // @ts-expect-error - this needs a zod schema to change the type from unknown
        quoteWizard={staticQuery.quoteWizard}
      />
      <FAQs data={faqsData} />
      <UserReviews {...usersReviews} />
      <GuaranteeSection
        copy={guaranteeSectionCopy}
        image={guaranteeAsset.guaranteeAsset}
        // @ts-expect-error - this needs a zod schema to change the type from unknown
        quoteWizard={staticQuery.quoteWizard}
      />
      <ExpertSection data={expertsSection} />
    </>
  )
}
