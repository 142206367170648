import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { IconDropDown } from '@ecomm/ss-react-components'
import { Profile } from '@ecomm/ss-react-components'
import { ItemIconDropDown } from '@ecomm/ss-react-components/IconDropDown'
import { prop } from '@simplisafe/ewok'
import React, { ReactElement } from 'react'
import { useTracking } from 'react-tracking'

import { linkToListItem } from './helpers'
import { ContentfulAccountNavFragment } from './query'

export function HeaderAccountNav({
  item
}: {
  readonly item: ContentfulAccountNavFragment
}): ReactElement {
  const login = prop('loginLink', item)
  const register = prop('registerLink', item)

  const { trackEvent } = useTracking()

  return (
    <IconDropDown
      a11yLabel="account menu"
      key={prop('id', item)}
      listItem={[linkToListItem(login), linkToListItem(register)].filter(
        Boolean
      )}
      onClick={(iconItem: ItemIconDropDown) => {
        trackEvent({
          label: iconItem.title,
          event: 'navigation-link',
          eventAction: 'click',
          eventCategory: 'navigation'
        })
        trackNavigationClick({
          action: 'click',
          navElement: iconItem.title
        })
      }}
      srcIcon={<Profile />}
    />
  )
}
