// extracted by mini-css-extract-plugin
export var additionalInfo = "CardItemSmallBanner-module--additionalInfo--13d87";
export var btmSection = "CardItemSmallBanner-module--btmSection--54947";
export var btnCol1 = "CardItemSmallBanner-module--btnCol1--e9463";
export var btnCol2 = "CardItemSmallBanner-module--btnCol2--98a61";
export var buildSystem = "CardItemSmallBanner-module--buildSystem--3649b";
export var button = "CardItemSmallBanner-module--button--59093";
export var cImage = "CardItemSmallBanner-module--cImage--8976b";
export var category = "CardItemSmallBanner-module--category--f1c33";
export var categoryContent = "CardItemSmallBanner-module--categoryContent--c71f3";
export var categoryOneItem = "CardItemSmallBanner-module--categoryOneItem--f07a0";
export var countText = "CardItemSmallBanner-module--countText--9f683";
export var gridItems = "CardItemSmallBanner-module--gridItems--d16e8";
export var imgResponsive = "CardItemSmallBanner-module--imgResponsive--d64e9";
export var noteText = "CardItemSmallBanner-module--noteText--9ab36";
export var offerBadge = "CardItemSmallBanner-module--offerBadge--f3767";
export var titleText = "CardItemSmallBanner-module--titleText--6b2d5";