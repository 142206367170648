//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import QuantitySelect, { QuantitySelectProps } from '../QuantitySelect'
import * as css from './PriceSection.module.scss'

export type PriceSectionProps = {
  /** An optional message to appear to the right of the quantity changer */
  readonly message?: string
  readonly priceDisclaimer?: string
  /** A component to show the price and discounts */
  readonly price: ReactNode
  /** @deprecated - use quantitySelector  */
  readonly quantitySelect?: QuantitySelectProps
  /** A quantity changer component */
  // TODO make this prop required when quantitySelect prop is removed
  readonly quantitySelector?: ReactNode
  /** If true, adds horizontal space between sections */
  readonly spaceBetween?: boolean
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const PriceSection: FC<PriceSectionProps> = ({
  message,
  priceDisclaimer,
  price,
  quantitySelect,
  quantitySelector,
  spaceBetween,
  dataComponent = PriceSection.name
}: PriceSectionProps) => {
  return (
    <div
      className={classNames(css.priceSection, {
        [css.spaceBetween]: spaceBetween
      })}
      data-component={dataComponent}
    >
      <div>
        <div
          className={classNames(css.priceSectionPriceText, {
            [css.priceSectionTextLineHeight]: !priceDisclaimer
          })}
        >
          {price}
        </div>
        {priceDisclaimer ? (
          <div className={css.priceSectionPriceDisclaimer}>
            {priceDisclaimer}
          </div>
        ) : null}
      </div>
      <div className={css.priceSectionQuantity}>
        {quantitySelector}
        {/* TODO remove QuantitySelect when quantitySelect prop is cleaned up */}
        {!quantitySelector && quantitySelect ? (
          <QuantitySelect {...quantitySelect} />
        ) : null}
      </div>
      {message ? (
        <div className={classNames('textSizeSM', css.priceSectionMessage)}>
          {message}
        </div>
      ) : null}
    </div>
  )
}

export default PriceSection
