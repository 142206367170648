import { useMemo } from 'react'

import { useCurrentPromoBannerData } from '../useCurrentPromoBannerData'

/**
 * Returns the discount amount in 'x off' format unless override data exists.
 *
 * @returns string
 */
export function useSitewidePromoOfferTitle(): string {
  const { offerTitle, overrideBannerText } = useCurrentPromoBannerData()

  return useMemo(() => {
    return overrideBannerText ? overrideBannerText : offerTitle
  }, [offerTitle, overrideBannerText])
}
