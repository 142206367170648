import { featureSectionSchema } from '@ecomm/shared-sections'
import { addVariationSchema } from '@ecomm/utils'
import { TypeOf, z } from '@simplisafe/ewok'

import { layoutSchema } from '../../assemblies/Layout/layoutSchema'
import { carouselSectionSchema } from '../../components/CarouselSection/schema'
import { packageSchema } from '../../components/Package/schema'
import { reviewSectionSchema } from '../../components/ReviewSection/schema'

const componentSchema = z.union([
  reviewSectionSchema,
  carouselSectionSchema,
  featureSectionSchema
])

export const pdpPageLayout = addVariationSchema(
  layoutSchema.extend({ components: z.array(componentSchema).optional() })
)

const pdpPageSchema = z.object({
  contentful_id: z.string(),
  layout: pdpPageLayout,
  package: addVariationSchema(packageSchema),
  ready: z.boolean().default(true),
  title: z.string(),
  breadcrumbTitle: z.string().optional()
})

export type ComponentTypes = TypeOf<typeof componentSchema>
export type PdpPageSchema = TypeOf<typeof pdpPageSchema>

export default pdpPageSchema
