import { Lens } from 'monocle-ts'

export type FetchOptions = {
  /** Method of the request */
  readonly method: 'GET' | 'POST'
  /** Request Headers */
  readonly headers: Record<string, string>
  /** Overrides the base url if set */
  readonly baseUrlOverride?: string
  readonly body?: string
  /**
   * API endpoint for the request.
   *
   * Needs to start with a leading `/`.
   */
  readonly endpoint: string
}

export const headersLens = Lens.fromProp<FetchOptions>()('headers')
