import { ContentfulImage } from '@ecomm/contentful/components'
import {
  useCurrentPromoBannerData,
  useSitewidePromoEndDateDisplay
} from '@ecomm/promotions-hooks'
import classNames from 'classnames'

import { Countdown } from '../../Countdown'

type LeftLayoutProps = {
  readonly hasCountdown: boolean
}

export function LeftLayout({ hasCountdown }: LeftLayoutProps) {
  const { endDate } = useSitewidePromoEndDateDisplay()
  const { mobileBannerImage } = useCurrentPromoBannerData()

  return (
    <div
      className={classNames(
        { 'basis-3/5 md:basis-1/4': !hasCountdown },
        'mr-8 flex justify-end'
      )}
      data-component="LeftLayout"
    >
      {!hasCountdown && mobileBannerImage ? (
        <ContentfulImage
          classNameOverride="bg-transparent object-contain w-full max-w-[220px]"
          description={mobileBannerImage.description}
          height={300}
          originalHeight={mobileBannerImage.file?.details.image.height || 1}
          originalWidth={mobileBannerImage.file?.details.image.width || 1}
          quality={100}
          url={mobileBannerImage.file?.url || ''}
          width={300}
        />
      ) : null}
      {hasCountdown ? <Countdown endTime={new Date(endDate)} /> : null}
    </div>
  )
}
