import { ProductData } from '@ecomm/bms/hooks'
import { ErrorBoundary } from '@ecomm/error-handling'
import { Modal } from '@ecomm/shared-components'
import React, { Suspense } from 'react'

import { SensorDescription } from '../SensorDescription'
import { SensorFAQs } from '../SensorFAQs'
import { SensorIcons } from '../SensorIcons'
import {
  SensorImageCarousel,
  SensorImageCarouselLoading
} from '../SensorImageCarousel'

type SensorModalProps = {
  readonly isOpen: boolean
  readonly setModalVisibility: (value: boolean) => void
  readonly sensorData: ProductData
}

export function SensorModal({
  isOpen,
  setModalVisibility,
  sensorData
}: SensorModalProps) {
  return (
    <Modal isOpen={isOpen} onRequestClose={() => setModalVisibility(false)}>
      <div className="flex max-w-screen-md flex-col px-6 lg:px-14 py-4">
        <ErrorBoundary>
          <header className="mb-6 p-0 text-center text-xl font-bold md:text-2xl">
            {sensorData.sensorName}
          </header>
          <Suspense fallback={<SensorImageCarouselLoading />}>
            <SensorImageCarousel productId={sensorData.productId} />
          </Suspense>
          {sensorData.features ? (
            <SensorIcons features={sensorData.features} />
          ) : null}
          <SensorDescription
            description={sensorData.description.content}
            isVisible={!sensorData.features}
            specs={sensorData.specs?.content}
          />
          <Suspense fallback={null}>
            {sensorData.faqs ? (
              <SensorFAQs faqContentfulId={sensorData.faqs.contentfulId} />
            ) : null}
          </Suspense>
        </ErrorBoundary>
      </div>
    </Modal>
  )
}
