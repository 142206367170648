import {
  getPartnerGroup,
  getValueFromPartnerCookie
} from '@ecomm/shared-cookies'
import { match } from 'ts-pattern'
import { useIsActivePromotion } from '../useIsActivePromotion'

/**
 * Returns a text to display a number of free months of service a customer gets
 * Text is used in the promo banners, Buy Box titles on Package pages and in cart
 *
 * @param serviceDurationOnly
 *  - If true, return only a copy with a number of free months (example: "free month" or "2 free months")
 *  - if false, return months of service copy to display in a sentence (example: "month is" or "months are")
 */

export const useMonthsOfFreeServiceText = (serviceDurationOnly: boolean) => {
  const oneMonthPartners = ['lightspeed']
  const partnerGroup = getPartnerGroup() || ''
  const partnerName = getValueFromPartnerCookie('partnerName') || ''
  const partnerOffersTwoMonthsService =
    !oneMonthPartners.includes(partnerName) && !partnerName.includes('cydcor')
  const isPromoActive = useIsActivePromotion()
  const numberFreeMonthsOfService: number = match(partnerGroup)
    .when(
      (p: string) =>
        p === 'partner-strategic-referral' && partnerOffersTwoMonthsService,
      () => 2
    )
    .when(
      (p: string) => p === 'standard-partner' && isPromoActive === true,
      () => 2
    )
    .otherwise(() => 1)
  const oneMonthCopy: string = serviceDurationOnly ? 'free month' : 'month is'
  const multipleMonthsCopy: string = serviceDurationOnly
    ? `${oneMonthCopy}s`
    : 'months are'
  return numberFreeMonthsOfService > 1
    ? `${numberFreeMonthsOfService} ${multipleMonthsCopy}`
    : oneMonthCopy
}
