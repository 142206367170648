import { fetchApi } from '@ecomm/data-simplisafe-api'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

export type PartnersSubmitBody = {
  readonly address1: string
  readonly address2?: string
  readonly city: string
  readonly customerKey: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly optIn?: boolean
  readonly partnerName: string
  readonly phone: string
  readonly policyId?: string
  readonly zoneCode?: string //zoneCode
  readonly postal: string //postal
  readonly country?: string //country
}

/**
 * Represents the result from the create partners request.
 */
export type CreatePartnerOrderResponse = {
  readonly statusCode: number
  readonly message?: string
  readonly error?: string
  readonly path: string
  readonly timestamp?: string
  readonly token?: string
  readonly expires?: number
  readonly orderId?: number
  readonly sid?: number
  readonly uid?: number
}

const isPartnerOrderData = (
  res: unknown
): res is CreatePartnerOrderResponse => {
  polyfillHasOwn()
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'statusCode') &&
    Object.hasOwn(res, 'path')
  )
}

/**
 * Create a new order request to mando
 *
 * @param request The parameters to generate a new order from.
 */
export const createPartnerOrder = (request: PartnersSubmitBody) => {
  return pipe(
    fetchApi({
      endpoint: `/partnerships/v1/insurance/create`,
      method: 'POST',
      headers: {},
      body: JSON.stringify(request)
    }),
    TE.chain(res => (isPartnerOrderData(res) ? TE.right(res) : TE.left(res))),
    TE.mapLeft(res =>
      Error(`Error sending member order data: ${JSON.stringify(res)}`)
    )
  )
}
