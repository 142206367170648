import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'

import { HowItWorksProps } from '../../../templates/ProtectorsTemplate/schema'

export default function HowItWorks({
  headline,
  imageContent,
  reviews
}: HowItWorksProps) {
  return (
    <div className="grid grid-cols-12 gap-4">
      <h2 className="col-span-12 text-center">{headline}</h2>
      {reviews.map((rev, i) => (
        <div
          className="col-span-12 prose-p:mb-0 text-center gap-4 md:gap-6 lg:col-span-4 flex flex-row lg:flex-col items-center bg-neutral-light-50 rounded-base p-4 md:p-6 lg:p-8"
          key={`review-${i}`}
        >
          <ContentfulImage
            classNameOverride="md:w-28 md:h-28 lg:h-24 lg:w-24 w-20 h-20"
            description={rev.logo.description}
            originalHeight={rev.logo.file.details.image.height}
            originalWidth={rev.logo.file.details.image.width}
            url={rev.logo.file.url}
          />
          <ContentfulRichText {...rev.review} />
        </div>
      ))}
      <div className="col-span-12 text-center mt-4">
        <ContentfulRichText {...imageContent} />
      </div>
    </div>
  )
}
