import { Breadcrumbs, BreadcrumbsProps } from '@ecomm/header'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { memo } from 'react'

type Props = {
  readonly breadcrumbTitle: O.Option<string>
  readonly slug: string
}

const videoSharing = (slug: string) =>
  slug.includes('/legal/video_sharing/')
    ? [
        {
          label: 'Video Sharing',
          slug: 'legal/video_sharing'
        }
      ]
    : []

export const LegalBreadCrumbs = memo(({ breadcrumbTitle, slug }: Props) => {
  const shouldRender = O.isSome(breadcrumbTitle) || slug === 'legal'
  const steps: BreadcrumbsProps['steps'] = pipe(
    breadcrumbTitle,
    O.match(
      () => [],
      title => [...videoSharing(slug), { label: title, slug }]
    )
  )

  return (
    <>
      {shouldRender ? (
        <Breadcrumbs steps={steps} template={'Legal'} type="main" />
      ) : null}
    </>
  )
})
