import * as O from 'fp-ts/lib/Option'

/**
 * @deprecated Use fp-ts `Record.lookup` instead
 */
function safeProp<K extends string>(key: K) {
  return <O extends { readonly [K1 in K]?: V }, V>(
    obj: O
  ): O.Option<NonNullable<O[K]>> => O.fromNullable(obj[key])
}

export default safeProp
