import { optionEquals } from '@ecomm/shared-fp-ts-utils'
import { addAtomDebugLabel } from '@ecomm/utils'
import { document } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import * as R from 'fp-ts/lib/Record'
import { pipe } from 'fp-ts/lib/function'
import { atom, useAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'

import { CookieOptions, formatCookie, parseCookie, setCookie } from './cookie'

export const cookieAtom = atom('')
addAtomDebugLabel(cookieAtom, 'cookies')

const lookupCookie = (key: string, cookies: string) =>
  R.lookup(key)(parseCookie(cookies))

/**
 * Provides the current value for a cookie and function to set a new value.
 * If the new value is null, the cookie is removed.
 *
 * NOTE: It is recommended to have all management for a particular cookie be handled by
 * useCookie to prevent the atom from being out of sync. Otherwise you may need to manually
 * use `useRefreshCookieAtom`.
 *
 * Somewhere in the app root you need to hydrate the cookie atom with `document.cookie`.
 *
 * @example
 * import * as O from 'fp-ts/lib/Option'
 * import { pipe } from 'fp-ts/lib/function'
 * import { useCookie, cookieAtom } from '@ecomm/data-storage`
 * import { useEffect } from 'react'
 * import { useHydrateAtoms } from 'jotai/utils'
 *
 * export function Component() {
 *  useHydrateAtoms([[cookieAtom, document.cookie]])
 *  const [userId, setUserId] = useCookie('userId')
 *
 *  useEffect(() => {
 *    pipe(
 *      userId,
 *      O.fold(() => {
 *        console.info('no user found, setting id')
 *        setUserId("001")
 *      },
 *      id => console.info(`user found: ${id}`)
 *     ))
 *  }, [userId])
 *
 *  return pipe(
 *    userId,
 *    O.map(id => <p>Hello {id}!</p>),
 *    O.getOrElse(() => <p>No user found</p>)
 *  )
 * }
 */
export const useCookie = (
  key: string,
  options?: CookieOptions
): readonly [O.Option<string>, (str: string) => void] => {
  const [cookies, setCookieAtom] = useAtom(cookieAtom)
  const [cookieVal, setCookieVal] = useState(lookupCookie(key, cookies))

  useEffect(() => {
    const newVal = lookupCookie(key, cookies)
    // This makes sure we return the same instance of Option from this hook if the actual value of the cookie hasn't changed
    setCookieVal(oldVal => (optionEquals(oldVal, newVal) ? oldVal : newVal))
  }, [cookies, key])

  const setVal = useCallback(
    (newVal: string | null) => {
      const cookieOptions: CookieOptions = {
        ...options,
        // If the new cookie is falsy, remove it by setting the expiry date in the past
        ...(!newVal ? { expires: 'Thu, 01 Jan 1970 00:00:00 GMT' } : {})
      }
      pipe(newVal || '', formatCookie(key, cookieOptions), setCookie)
      setCookieAtom(document.cookie)
    },
    [key, JSON.stringify(options), setCookieAtom]
  )

  return [cookieVal, setVal]
}

/**
 * Provides a callback function to force-refresh the cookieAtom based on document.cookie.
 * Should be used sparingly, but can be useful to update Jotai state if a cookie is set
 * outside of the useCookie hook.
 */
export const useRefreshCookieAtom = () => {
  const [_, setCookieAtom] = useAtom(cookieAtom)

  return () => {
    setCookieAtom(document.cookie)
  }
}
