import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'

import { COOKIE_LEAD_DATA } from './constants'
import { getCookie, setCookie } from './cookies'

export const getLeadData = () =>
  getCookie<Record<string, string> | null>(COOKIE_LEAD_DATA)

/**
 * Saves new lead data in cookie w/o overwriting unchanged fields
 */
export const updateLeadData = (newLeadData: Record<string, string>) =>
  setCookie(
    COOKIE_LEAD_DATA,
    { ...getLeadData(), ...newLeadData },
    {
      ...cookiesOption,
      secure:
        process.env['NODE_ENV'] !== 'development' &&
        process.env['NODE_ENV'] !== 'test' // disable secure for local dev + unit tests
    }
  )

export const getValueFromLeadData = (key: string): string | null => {
  const leadDataObj = getLeadData() || {}
  return leadDataObj?.[key] || null
}
