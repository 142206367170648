import { useMicroCopy } from '@ecomm/micro-copy'
import { stringToKebabCase } from '@ecomm/utils'
import { Link } from 'gatsby'
import React from 'react'

function StickyNavbar({
  anchorSections
}: {
  readonly anchorSections: readonly string[]
}) {
  const microcopy = useMicroCopy()
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    href: string
  ) => {
    event.preventDefault()
    const button = event.currentTarget
    const anchorTarget = button.ownerDocument.getElementById(href)

    anchorTarget &&
      anchorTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
  }

  return (
    <div
      aria-label="Sticky navigation bar"
      className="prose md:prose-md lg:prose-lg fixed top-0 z-10 hidden w-full bg-white text-inherit lg:flex"
      role="navigation"
    >
      <div className="max-w-8xl mx-auto flex w-full items-center justify-between p-4 md:h-24 md:gap-8 md:px-8 md:py-0">
        {microcopy ? microcopy['meet-the-system'] : null}
        <ul className="flex flex-row gap-12">
          {anchorSections.map((section, index) => (
            <li className="list-none" key={`${section}_${index}`}>
              <a
                className="no-underline hover:underline"
                href={`#${stringToKebabCase(section)}`}
                onClick={e => handleClick(e, stringToKebabCase(section))}
              >
                {section}
              </a>
            </li>
          ))}
        </ul>
        <Link
          className="btn btn-solid-primary !px-4 block min-w-0 lg:px-8"
          to="/home-security-shop"
        >
          Shop now
        </Link>
      </div>
    </div>
  )
}

export default StickyNavbar
