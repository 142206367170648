import { overloadMaybe } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getCartDiscountValue = (cart: ImmutableCart, locale: string) => {
  const discountAmount = cart.totalCartDiscount * -1
  return (
    discountAmount < 0 &&
    pipe(
      overloadMaybe(localizedDisplayPrice(locale)(discountAmount)),
      O.chain(displayPrice => O.fromNullable(displayPrice))
    )
  )
}
