import { Link } from 'gatsby'
import React from 'react'

export type LinkComponentProps = {
  readonly data: {
    readonly linkText: string
    readonly linkUrl: string
  }
}

/** Simple wrapper component for Gatsby's Link, intended to be used on componentMappings */
export function LinkComponent({ data }: LinkComponentProps) {
  const { linkText, linkUrl } = data

  const isInternalLink = linkUrl && linkUrl.startsWith('/')

  return (
    <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
      {isInternalLink ? (
        <Link to={linkUrl}>{linkText}</Link>
      ) : (
        <a href={linkUrl}>{linkText}</a>
      )}
    </div>
  )
}
