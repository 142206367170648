//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { SiteColor } from '../types/colors'
import * as css from './CardShop.module.scss'

export type CardShopProps = {
  /** Background color of the card. Can be any value of type SiteColor. Defaults to 'neutralLightGray'. */
  readonly backgroundColor?: SiteColor
  /** Text color of the card. Can be any value of type SiteColor. Defaults to 'neutralBlack'. */
  readonly color?: SiteColor
  /** data-component attribute value. */
  readonly dataComponent?: string
  /** Intended to flag products as 'new' or 'featured', etc. Displays a flag on top left corner of card. If empty, no flag will appear. */
  readonly flag?: string
  /** Anchors to the bottom of the card. Intended to accept SSButton for a link on the card from the front-end. */
  readonly footer?: ReactNode
  /** Padding setting of the card. Defaults to true. */
  readonly hasPadding?: boolean
  /** Product name, displayed as the card title. */
  readonly heading?: ReactNode
  /** An area to include the HTML markup for an image, styled with object-fit: contain. */
  readonly img: ReactNode
  /** Product name, used in Contentful only. */
  readonly title: string
}

/** @deprecated Do not use ss-react-components*/
const CardShop: FC<CardShopProps> = ({
  backgroundColor = 'neutralLightGray',
  color = 'neutralBlack',
  dataComponent = CardShop.name,
  flag,
  footer,
  hasPadding = true,
  heading,
  img
}: CardShopProps) => {
  return (
    <div
      className={classNames(css.card, {
        [css.cardPadding]: hasPadding,
        [`${backgroundColor}BackgroundColor`]: backgroundColor,
        [`${color}TextColor`]: color
      })}
      data-component={dataComponent}
    >
      <div>
        {heading}
        {flag ? <span className={css.flag}>{flag}</span> : null}
      </div>
      <div className={css.cardImage}>{img}</div>
      {footer ? <div className={css.cardFooter}>{footer}</div> : null}
    </div>
  )
}

export default CardShop
