import classNames from 'classnames'
import isEmpty from 'ramda/src/isEmpty'
import React, {
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react'
import { animated, useTransition } from 'react-spring'
import { useTracking } from 'react-tracking'

import { Heading, ProgressBar } from '..'
import type { SSButtonProps } from '../SSButton'
import WizardInitial from './WizardInitial'
import WizardMultipleChoice from './WizardMultipleChoice'
import { Response, WizardContent } from './WizardTypes'

export type WizardType = 'embedded' | 'floating' | 'popup'

export type WizardProps = {
  readonly backButtonText?: string
  readonly children: WizardContent
  readonly className?: string
  readonly nextButtonText?: string
  readonly title?: string
  readonly type: WizardType
  readonly dataComponent?: string
  readonly onClick?: () => void
}

const multipleChoiceButtonPropsByType: {
  readonly [key in WizardProps['type']]: Partial<SSButtonProps>
} = {
  embedded: { color: 'primaryOutline' },
  floating: { color: 'primaryOutline' },
  popup: { color: 'primaryOutline' }
}

const typesWithProgressBar: readonly WizardProps['type'][] = ['floating']

const headerStyler: {
  readonly [key in WizardProps['type']]: (header: string) => ReactNode
} = {
  embedded: header => (
    <Heading element="h2" headingAspect="h3" margin="small" useTailwind={true}>
      {header}
    </Heading>
  ),
  floating: header => (
    <Heading
      bold={true}
      element="h2"
      headingAspect="h4"
      margin="small"
      useTailwind={true}
    >
      {header}
    </Heading>
  ),
  popup: header => (
    <Heading element="h2" headingAspect="h3" margin="small" useTailwind={true}>
      {header}
    </Heading>
  )
}

/** @deprecated Do not use ss-react-components*/
const Wizard: FC<WizardProps> = ({
  backButtonText,
  children,
  className,
  nextButtonText,
  title,
  type,
  onClick,
  dataComponent = Wizard.name
}: WizardProps) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [response, setResponse] = useState({})
  const showProgressBar = typesWithProgressBar.includes(type)

  const childrenArray = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      React.Children.toArray(children) as readonly ReactElement[],
    [children]
  )

  const handleNextStep = useCallback(
    (nextResponse?: Response) => () => {
      const newStep =
        currentStep < childrenArray.length ? currentStep + 1 : currentStep

      setCurrentStep(newStep)

      onClick && onClick()

      nextResponse &&
        setResponse({
          ...response,
          [nextResponse.id]: nextResponse.value
        })
    },
    [currentStep, response, childrenArray, onClick]
  )

  const visibleChild = useMemo(() => {
    const element = childrenArray[currentStep - 1]
    return React.cloneElement(element, {
      backButtonText:
        currentStep > 1 && element.type === WizardMultipleChoice
          ? backButtonText
          : undefined,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      buttonProps:
        element.type === WizardMultipleChoice
          ? multipleChoiceButtonPropsByType[type]
          : element.props.buttonProps,
      nextButtonText:
        element.type === WizardMultipleChoice && element.props.isMultiSelect
          ? nextButtonText
          : undefined,
      onNextStep: handleNextStep,
      onPreviousStep: () => setCurrentStep(step => step - 1),
      response
    })
  }, [
    childrenArray,
    type,
    currentStep,
    handleNextStep,
    response,
    backButtonText,
    nextButtonText
  ])

  const transitions = useTransition(visibleChild, visibleChild.key, {
    enter: { opacity: 1 },
    from: { opacity: 0 },
    leave: {
      height: 0,
      opacity: 0
    }
  })

  const numQuestions = childrenArray.filter(
    (e: ReactElement) =>
      e.type === WizardMultipleChoice || e.type === WizardInitial
  ).length

  const trackingQWId = 'quoteWizard'
  const { Track } = useTracking({
    action: trackingQWId,
    appSection: trackingQWId,
    isFirstWizardStep: currentStep === 1 && isEmpty(response),
    wizardType: type
  })

  return (
    <Track>
      <div
        className={classNames(
          'min-h-0 py-2',
          {
            'bg-neutral-light-50': type === 'floating',
            'relative h-full w-full ': showProgressBar
          },
          className
        )}
        data-component={dataComponent}
        id={type}
      >
        <form className={classNames({ 'p-6 md:p-8': showProgressBar })}>
          {title ? headerStyler[type](title) : null}
          {transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={props}>
              {item}
            </animated.div>
          ))}
        </form>
        {showProgressBar ? (
          <ProgressBar
            className="bg-neutral-light-100 absolute bottom-0"
            fillClassName="bg-neutral-black"
            percent={Math.floor((currentStep / numQuestions) * 100)}
          />
        ) : null}
      </div>
    </Track>
  )
}

export default Wizard
