import { addAtomDebugLabel } from '@ecomm/utils'
import { atomWithReset } from 'jotai/utils'

import {
  MonitoringPlan,
  MonitoringPlanType
} from '../../components/DraftCart/types'

export type DraftCartMonitoring = {
  readonly plan: string
  readonly type: MonitoringPlanType
}

export const monitoringAtom = atomWithReset<DraftCartMonitoring>({
  plan: '',
  type: MonitoringPlan.none
})

addAtomDebugLabel(monitoringAtom, 'DraftCart: MonitoringPlan')
