import { ReactSDKClient } from '@optimizely/react-sdk'

const disposeFn = () => null

// https://github.com/optimizely/react-sdk/issues/24#issuecomment-597379810
export class TestableOptimizelyClient implements ReactSDKClient {
  user = {
    attributes: {},
    id: 'user-id'
  }

  initialConfig = {}

  notificationCenter = {
    addNotificationListener: () => 0,
    clearAllNotificationListeners: () => null,
    clearNotificationListeners: () => null,
    removeNotificationListener: () => true
  }

  constructor({
    getVariation = '',
    getFeatureVariables = {},
    isFeatureEnabled = true,
    track = () => null,
    activate = () => null,
    setForcedVariation = () => true,
    onReady = () => Promise.resolve({ success: true })
  }) {
    this.getVariation = () => getVariation
    this.getFeatureVariables = () => getFeatureVariables
    this.isFeatureEnabled = () => isFeatureEnabled
    this.track = track
    this.activate = activate
    this.setForcedVariation = setForcedVariation
    this.onReady = onReady
  }

  onReady() {
    return Promise.resolve({ success: true })
  }

  getFeatureVariables() {
    return {}
  }

  setUser() {}

  onUserUpdate() {
    return disposeFn
  }

  /* Core API */
  track() {}

  /* Experiment API */
  activate(): string | null {
    return null
  }

  getForcedVariation() {
    return null
  }

  getVariation(): string | null {
    return null
  }

  setForcedVariation() {
    return true
  }

  /* Feature Management API */
  getEnabledFeatures() {
    return []
  }

  getFeatureVariable() {}

  getFeatureVariableBoolean() {
    return null
  }

  getFeatureVariableDouble() {
    return null
  }

  getFeatureVariableInteger() {
    return null
  }

  getFeatureVariableString() {
    return null
  }

  getFeatureVariableJSON() {
    return null
  }

  getAllFeatureVariables() {
    return {}
  }

  isFeatureEnabled() {
    return true
  }

  onForcedVariationsUpdate() {
    return disposeFn
  }

  decide() {
    return {
      enabled: true,
      flagKey: 'mock-flag-key',
      reasons: ['mock-reason'],
      ruleKey: 'mock-rule-key',
      userContext: {
        attributes: {},
        id: ''
      },
      variables: {
        var1: 'var1-value',
        var2: 'var2-value'
      },
      variationKey: 'mock-variation-key'
    }
  }

  decideAll() {
    return {}
  }

  decideForKeys() {
    return {}
  }

  close() {
    return Promise.resolve({ success: true })
  }

  getOptimizelyConfig() {
    return null
  }

  /* Internal state */
  clientEngine = 'javascript-sdk-testable'

  clientVersion = '1.2.3'

  errorHandler() {}

  eventDispatcher() {}

  isReady() {
    return true
  }

  logger = {
    logLevel: 4,
    logLevelName: 'ERROR'
  }

  getIsReadyPromiseFulfilled() {
    return true
  }

  getIsUsingSdkKey() {
    return true
  }
  setForcedDecision() {}

  removeAllForcedDecisions() {
    return true
  }

  removeForcedDecision<T>(__decisionContext: T): boolean {
    return false
  }

  getForcedDecision<T, U>(__decisionContext: T): U | null {
    return null
  }
}
