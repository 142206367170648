import { Locale } from '../../hooks/useLocalizedData'
import { createMockRichText } from '../../utils'

export const data = {
  [Locale.EnGB]: {
    headline: {
      raw: createMockRichText([
        'Next-gen technology for the highest level of home security'
      ])
    },
    listItems: [
      {
        headline: 'Deters intruders',
        body: 'The only indoor camera that can trigger an alarm and deter intruders with a built-in siren and two way audio.',
        disclaimer: 'Requires Pro Premium Monitoring plan.',
        icon: 'Alert'
      },
      {
        headline: 'Fewer false alarms',
        body: 'AI-powered motion detection distinguishes between humans and pets, so you can be more confident that an alarm is real.',
        icon: 'FalseAlarm'
      },
      {
        headline: 'Police response',
        body: 'Advanced technology enables our professional monitoring centre to visually verify threats, so they can request police dispatch.',
        icon: 'PoliceCar',
        disclaimer:
          '*Vs. non-verified SimpliSafe® alarms. Requires Pro Premium Monitoring plan with video verification'
      }
    ]
  },
  [Locale.EnUS]: {
    headline: {
      raw: createMockRichText([
        'Next-gen technology for the highest level of home security'
      ])
    },
    listItems: [
      {
        headline: 'Helps stop crime in real time',
        body: 'When you subscribe to the Fast Protect™ monitoring plan, agents can see and speak to intruders, stopping them in their tracks.',
        icon: 'Microphone'
      },
      {
        headline: 'Deters intruders',
        body: 'The only indoor camera that can trigger an alarm and deter intruders with a built-in siren and live, two-way agent audio.*',
        disclaimer: '*Requires Fast Protect™ Monitoring plan',
        icon: 'Alert'
      },
      {
        headline: 'Fewer false alarms',
        body: 'AI-powered motion detection distinguishes between humans and pets, so you can be more confident that an alarm is real.',
        icon: 'FalseAlarm'
      },
      {
        headline: 'Faster police response',
        body: 'Agents can capture evidence to verify the alarm with emergency responders, so you can get priority dispatch.* ',
        icon: 'PoliceCar',
        disclaimer:
          '*Vs. non-verified SimpliSafe alarms. Requires Fast Protect™ Monitoring plan with video verification.'
      }
    ]
  }
}
