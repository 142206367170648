import { params } from '@ecomm/tracking'
import { noValue, removeLeadingSlash } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { localStorage } from '@simplisafe/ewok'
import { useEffect } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
const { get, set, remove } = localStorage

const utmCodeMap: Record<string, string> = {
  protect: '8XOKBCYX',
  safety: '8XOKBCYX'
}

/**
 * @deprecated NOTE: This code was ported directly from legacy under tight deadline for Scout launch and should be rewritten!
 * See: https://simplisafe.atlassian.net/browse/ECP-7325
 */
export const useUtmCode = (enable = true) => {
  const { pathname } = useLocation()
  const path = removeLeadingSlash(pathname)
  /** The promo code in the URL */
  const [queryPromoCode, setUtmPromoCode] = useQueryParam(
    params.promoCode,
    StringParam
  )
  const utmPromoCode = utmCodeMap[path] || queryPromoCode

  useEffect(() => {
    // if enable is true and utmCode is defined and remove query param, write it to locale storage
    const setUtmCode = () => {
      set('utm_code', utmPromoCode)
      setUtmPromoCode(noValue())
    }

    enable && utmPromoCode && setUtmCode()

    /** clear local storage if disable is true */
    !enable && remove('utm_code')

    /** clear utm_code from url if disable is true */
    !enable && setUtmPromoCode(noValue())
  }, [enable, setUtmPromoCode, utmPromoCode])

  /** To persist the utm_code across sessions and pages we write it to local storage */
  const localStorageUtmCode: string | null = get('utm_code')

  /** the code to apply to the cart */
  return utmPromoCode || localStorageUtmCode
}
