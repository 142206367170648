import ConfidenceItem from './ConfidenceItem'
import type { ConfidenceBarSchema } from './schema'

export function ConfidenceBar({ headline, items }: ConfidenceBarSchema) {
  return (
    <>
      {headline ? (
        <div>
          <div className="prose md:prose-md lg:prose-lg whitespace-pre-line text-center text-[1.75rem] font-bold">
            {headline}
          </div>
        </div>
      ) : null}
      <div
        className="m-0 flex flex-row flex-wrap gap-4 p-0 lg:flex-nowrap lg:gap-8"
        data-component="ConfidenceBar"
        data-testid="ConfidenceBar"
      >
        {items.map((item, index) => (
          <ConfidenceItem
            disclaimer={item.disclaimer}
            icon={item.icon}
            key={`item-${index}`}
            text={item.text}
          />
        ))}
      </div>
    </>
  )
}
