import {
  AFFIRM_MIN_PRICE,
  AFFIRM_QUALIFIED_MONTHLY,
  AFFIRM_NOT_QUALIFIED_PRICE
} from '@ecomm/data-constants'
import { useMicroCopy } from '@ecomm/micro-copy'
import { getAffirm } from '@ecomm/shared-window'
import { useTrackingAffirmInformationIcon } from '@ecomm/tracking'
import { overloadMaybe } from '@simplisafe/ewok'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import classNames from 'classnames'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import React, { useEffect } from 'react'

import type {
  AffirmMonthlyOptionProps,
  AffirmOptionType
} from '../types/affirm'
import { Divider } from './content/Divider'
import { FullOption } from './content/FullOption'
import { MonthlyOption } from './content/monthlyOptionContent/MonthlyOption'
import { useSelector } from 'react-redux'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'

export const localizedPrice = (locale: string, price?: number) =>
  pipe(
    O.fromNullable(price),
    O.chain(price => overloadMaybe(localizedDisplayPrice(locale)(price))),
    O.toUndefined
  )

export function AffirmMonthlyOption({
  discountedPrice,
  subTotalPrice,
  displaySubTotalText = false,
  stacked = false,
  className,
  isPlp = false,
  isCheckout = false,
  useSystemTermMonths = true,
  isAffirmExperience = false
}: AffirmMonthlyOptionProps) {
  //if we have a discount price, return the price in cents otherwise return the total price
  const priceInCents = discountedPrice
    ? Math.round(discountedPrice * 100)
    : Math.round(subTotalPrice * 100)

  useEffect(() => {
    getAffirm(affirm => {
      affirm.ui.ready(() => {
        affirm.ui.refresh()
      })
    })
  }, [priceInCents])

  const microCopy = useMicroCopy()
  const trackIconClickEvent = useTrackingAffirmInformationIcon('summary')

  const cart = useSelector(selectCart)

  const hasSystem = cart.map(_cart => _cart.isThereAnySecurity).orJust(false)

  const affirmOption: AffirmOptionType =
    priceInCents / 100 >= AFFIRM_MIN_PRICE && !(isCheckout && !hasSystem)
      ? AFFIRM_QUALIFIED_MONTHLY
      : AFFIRM_NOT_QUALIFIED_PRICE

  const showMonthlyPrice: boolean = affirmOption === AFFIRM_QUALIFIED_MONTHLY

  return (
    <div data-component="AffirmMonthlyOption">
      {displaySubTotalText ? (
        <h3 className="mb-3 text-2xl font-medium">{microCopy['sub-total']}</h3>
      ) : null}
      <div
        className={classNames(
          'flex flex-col justify-evenly rounded-xl px-4 py-2',
          {
            'border-neutral-medium-100 border border-solid shadow-md': !isPlp,
            'md:flex-row': !stacked,
            'pl-0': isPlp
          },
          className
        )}
      >
        <MonthlyOption
          affirmOption={affirmOption}
          discountedPrice={discountedPrice}
          hasSystem={hasSystem}
          isAffirmExperience={isAffirmExperience}
          isCheckout={isCheckout}
          isPlp={isPlp}
          onClick={trackIconClickEvent}
          priceInCents={priceInCents}
          showMonthlyPrice={showMonthlyPrice}
          stacked={stacked}
          totalPrice={subTotalPrice}
          useSystemTermMonths={useSystemTermMonths}
        />
        {!isPlp && <Divider stacked={stacked} />}
        <FullOption
          discountedPrice={discountedPrice}
          isPlp={isPlp}
          stacked={stacked}
          subTotalPrice={subTotalPrice}
        />
      </div>
    </div>
  )
}
