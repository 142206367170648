import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

const useTrackingBmsToggleMonitoring = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (hasMonitoringPlan: boolean) => {
      trackEvent({
        action: 'bms',
        category: 'monitoring-toggle',
        event: 'onChange',
        label: hasMonitoringPlan ? 'add-plan' : 'no-plan'
      })
    },
    [trackEvent]
  )
}

export default useTrackingBmsToggleMonitoring
