import type { ExperienceConfiguration } from '@ninetailed/experience.js-gatsby'
import { ExperienceMapper, Variant } from '@ninetailed/experience.js-utils'

export const mapExperiences = <T extends Variant>(
  experienceData: any
): ExperienceConfiguration<T>[] => {
  return (experienceData || [])
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience)
}

export const mapApolloExperiences = <T extends Variant>(
  experienceData: any
): ExperienceConfiguration<T>[] => {
  return (experienceData || [])
    .map((experience: any) => {
      return {
        ...experience,
        id: experience.id.id,
        audience: {
          ...experience.audience,
          id: experience.audience.id.id
        },
        variants: experience.variants.items.map((variant: any) => {
          return {
            ...variant,
            id: variant.id
          }
        })
      }
    })
    .filter(ExperienceMapper.isExperienceEntry)
    .map(ExperienceMapper.mapExperience)
}
