import { useLocale } from '@ecomm/data-hooks'
import { usePriceContext } from '@ecomm/data-price'
import { AffirmMonthlyOption, PriceBox } from '@ecomm/shared-components'
import { getPartnerGroup } from '@ecomm/shared-cookies'
import { exists, window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import type { PackageItem } from '../Package'
import { useFormattedPackagePrice, usePackagePrice } from '../Package/hooks'
import type { PackageType } from '../Package/schema'

export type PackagePriceProps = {
  readonly sku: string
  readonly packageType: PackageType
  readonly productItems: readonly PackageItem[]
  readonly isAffirmExperience: boolean
}

function PackagePrice({
  sku,
  packageType,
  productItems,
  isAffirmExperience
}: PackagePriceProps) {
  const isUs = useLocale() === 'en-US'
  const affirmClient = exists(window) ? window.affirm : null
  const { isLoading } = usePriceContext()
  const { regularPrice, discountedPrice } = useFormattedPackagePrice(
    sku,
    packageType,
    productItems
  )

  const isStrategicReferralPackage =
    getPartnerGroup() === 'partner-strategic-referral'

  const numberPrice = usePackagePrice(
    sku,
    packageType,
    productItems,
    1,
    isStrategicReferralPackage
  )

  const subTotalRegularPrice =
    numberPrice.regularPrice + numberPrice.extrasPrice
  const partialDiscountedPrice = O.toUndefined(numberPrice.discountedPrice)
  const hasDiscountedPrice = subTotalRegularPrice !== partialDiscountedPrice
  const subTotalDiscountedPrice = hasDiscountedPrice
    ? O.some(partialDiscountedPrice)
    : O.none

  const discountedPriceText = O.toUndefined(discountedPrice)

  return isLoading ? (
    <Skeleton containerClassName="w-full" count={3} />
  ) : isUs ? (
    <AffirmMonthlyOption
      affirmClient={affirmClient}
      discountedPrice={O.toUndefined(subTotalDiscountedPrice)}
      isAffirmExperience={isAffirmExperience}
      stacked
      subTotalPrice={subTotalRegularPrice}
    />
  ) : (
    <div className="text-heading-3-size">
      <PriceBox
        discountedPrice={discountedPriceText}
        regularPrice={regularPrice}
      />
    </div>
  )
}

export default PackagePrice
