import type { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import React from 'react'

export const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children) => {
      return <ul className="mt-4 list-disc pl-6 md:pl-8">{children}</ul>
    },
    [BLOCKS.LIST_ITEM]: (_, children) => {
      return (
        <li className="text-body-size py-2 font-normal text-white md:py-3">
          {children}
        </li>
      )
    },
    [BLOCKS.HEADING_6]: (_, children) => {
      return (
        <h6 className="mb-0 mt-6 text-xl font-normal text-white">{children}</h6>
      )
    },
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return <p className=" m-0 text-white">{children}</p>
    }
  }
}

export const disclaimerCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children) => {
      return (
        <ul
          className="m-0 flex list-none flex-col gap-3 p-0 text-xs text-white"
          data-testid="AppWidget-disclaimer"
        >
          {children}
        </ul>
      )
    },
    [BLOCKS.LIST_ITEM]: (_, children) => {
      return (
        <li className="list-none text-xs font-normal text-white">{children}</li>
      )
    },
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return <p className=" text-xs text-white">{children}</p>
    }
  }
}

export const ratingsRenderer: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return <p className=" m-auto md:text-sm md:text-white">{children}</p>
    }
  }
}

export const starsRenderer: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      return (
        <p className="m-auto font-bold md:text-sm md:text-white">{children}</p>
      )
    }
  }
}
