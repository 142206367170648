import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.FieldOfView,
    text: '90° field of view'
  },
  {
    icon: SharedIcons.PeelAndStick,
    text: 'Peel-and-stick install'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.FalseAlarm,
    text: 'Smart motion detection'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 4 yrs'
  }
]

const description = {
  content: `The motion sensor provides another layer of interior protection. With a 90° field of view and 35 ft. range, no intruder can slip past it.
  \nThanks to pet-friendly smart motion detection, you don't have to worry about false alarms from your four-legged friends.
  \nUse it in large rooms and as a backup for entry sensors.
  `
}

const specs = {
  content: `2.75 × 2.75 x 2 inches
    \nOptional screw kit for wall mounting
    \n1 CR123A battery (included)
    \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '48pUnpeiUahJRRCxdzm5XD'
}

export const motionSensorData: ProductData = {
  productId: '39STr1H3iZzezr2ktpBljr',
  sensorName: 'Motion Sensor',
  features,
  description,
  specs,
  faqs
}
