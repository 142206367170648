import React from 'react'

function Minus({ testId }: { readonly testId: string }) {
  return (
    <span data-testid={`btn-minus-${testId}`}>
      <svg height="44" width="44" xmlns="http://www.w3.org/2000/svg">
        <title>Minus</title>
        <path
          d="M22 0c12.1502645 0 22 9.8497355 22 22s-9.8497355 22-22 22S0 34.1502645 0 22 9.8497355 0 22 0zm0 4C12.0588745 4 4 12.0588745 4 22s8.0588745 18 18 18 18-8.0588745 18-18S31.9411255 4 22 4zm8 16c1.1045695 0 2 .8954305 2 2 0 1.0256717-.7720804 1.8710143-1.7667577 1.9865445L30 24H14c-1.1045695 0-2-.8954305-2-2 0-1.0256717.7720804-1.8710143 1.7667577-1.9865445L14 20h16z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </svg>
    </span>
  )
}

export default Minus
