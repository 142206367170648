import { trackNonBounce } from '@ecomm/cdp-tracking'
import {
  get as sessionStorageGet,
  set as sessionStorageSet
} from '@ecomm/utils'

import type { TrackEvent } from '../analytics'

const SESSION_STORAGE_NON_BOUNCE_VISITOR = 'nonBounceVisitor'

export const nonBounceSessionWasNotTracked = () =>
  sessionStorageGet(SESSION_STORAGE_NON_BOUNCE_VISITOR) !== 'true'

export function trackNonBounceVisit(trackEvent: TrackEvent) {
  sessionStorageSet(SESSION_STORAGE_NON_BOUNCE_VISITOR, 'true')
  trackEvent({
    event: 'non_bounce'
  })
  trackNonBounce()
}
