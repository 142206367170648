import { gql } from '@apollo/client/index.js'

export const GUARANTEE_ASSET_QUERY = gql`
query guaranteeAssetQuery($locale: String!, $preview: Boolean!) {
  guaranteeAsset: asset(
    id: "5FRJNkZAaZL4mHk6vuMNVb"
    locale: $locale
    preview: $preview
  ) {
    originalWidth: width
    originalHeight: height
    title
    url
    size
    description
  }
}`
