import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'

import { VerifyPassBannerSchema, VerifyPassFormSchema } from './schema'
import VerifyDropdown from './VerifyDropdown'

const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const formData: VerifyPassFormSchema = node.data.target

      return <VerifyDropdown {...formData} />
    }
  }
}

export default function VerifyPassBanner({ content }: VerifyPassBannerSchema) {
  return (
    <div className="prose-a:underline flex rounded-base overflow-hidden hover:prose-a:no-underline relative prose-headings:text-white prose-p:text-white prose-a:text-white items-center w-full flex-auto px-4 py-6 md:p-10 lg:p-16 min-h-[350px] md:min-h-[400px] lg:min-h-[650px]">
      {content.map((column, i) => (
        <div className="w-full" key={`hero-banner-column-${i}`}>
          <ContentfulImage
            classNameOverride="absolute -z-[1] w-full h-full left-0 top-0 !object-cover object-bottom"
            description={column.image.description}
            originalHeight={column.image.file.details.image.height}
            originalWidth={column.image.file.details.image.width}
            url={column.image.file.url}
          />
          <ContentfulRichText {...column.description} optionsCustom={options} />
        </div>
      ))}
    </div>
  )
}
