import { SiteColor } from '@ecomm/shared-components'
import { useReady } from '@ecomm/shared-hooks'
import { Column, Row, Text } from '@ecomm/ss-react-components'
import { useOptimizelyTrackSiteEvents, usePollVid } from '@ecomm/tracking'
import classNames from 'classnames'
import { BgImage } from 'gbimage-bridge'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'
import propOr from 'ramda/src/propOr'
import React from 'react'

import { ContentfulButton } from '../../../graphql'
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics'
import ButtonComponent from '../ButtonComponent'
import ContentfulRichText from '../ContentfulRichText'
import ContentfulVariationContainerComponent from '../ContentfulVariationContainerComponent'
import FloatingBadgeComponent from '../FloatingBadgeComponent'
import ModalComponent from '../ModalComponent'
import { mapPageComponentToTemplate, PageProps } from '../Page'
import { appendVIDandUniqueSessionIdtoButtonUrl } from '../QuoteWizardWrapper/lib'
import {
  calculateSpans,
  colSpansWithoutSticker,
  colSpansWithSticker
} from './helpers'
import { useHeroColumnImage } from './hooks'
import { PromoSticker } from './PromoSticker'
import PromoTag from './PromoTag'
import { HeroColumnObject, HeroComponent } from './types'

const colorTranslations: Record<string, string> = {
  brandPrimary: 'var(--primary-100)',
  complementaryBlue100: 'var(--complementary-blue-100)',
  neutralBlack: 'var(--neutral-black)',
  neutralLight100: 'var(--neutral-light-100)',
  neutralWhite: 'var(--white)'
}

function HeroColumn(
  { column, colsLength }: HeroColumnObject,
  pageContext: PageProps['pageContext']
) {
  const isReady = useReady()
  const vid = usePollVid()
  const [clientId, sessionId] = useGoogleAnalytics()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  // @ts-expect-error TS(2322) FIXME: Type 'readonly ContentfulButtonContentfulHeadingUn... Remove this comment to see the full error message
  const description = (
    <ContentfulRichText
      raw={column?.description?.raw}
      references={column?.description?.references || []}
    />
  )

  const quoteWizardModal = prop('quoteWizardModal', column)
  // @ts-expect-error
  const descriptionMobile = (
    <ContentfulRichText
      raw={column?.descriptionMobile?.raw}
      references={column?.descriptionMobile?.references || []}
    />
  )
  const additionalLinkText = path<string>(
    ['additionalLink', 'linkText'],
    column
  )
  const additionalLinkUrl = path<string>(['additionalLink', 'linkUrl'], column)
  const verticalAlign = prop('contentVerticalAlignment', column)
  const textAlignment = prop<'center' | 'left' | 'right'>(
    'textAlignment',
    column
  )
  const verticalOverflow = prop<'hidden' | 'visible'>(
    'verticalOverflow',
    column
  )

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const sidePadding = propOr<string, string>('', 'sidePadding', column)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const verticalPadding = propOr<string, string>('', 'verticalPadding', column)
  const linkColor = prop<string>('linkColor', column)
  const textColor = prop<string>('textColor', column)
  const backgroundColor = prop<SiteColor>('backgroundColor', column)
  const hasPromoSticker = prop<boolean>('hasPromoSticker', column)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const heroComponents = propOr<
    readonly HeroComponent[],
    readonly HeroComponent[]
  >([], 'components', column)

  const floatingBadgeData = prop('floatingBadge', column)

  const { images } = useHeroColumnImage(column)

  const promoTagProps = prop('promotionalTag', column)

  const containerClassNames = classNames(
    'flex items-center justify-center w-full h-full bg-center bg-no-repeat bg-cover',
    {
      'p-8 lg:p-16': !verticalPadding && !sidePadding && hasPromoSticker,
      'p-8 md:p-16 lg:p-16':
        !verticalPadding && !sidePadding && !hasPromoSticker,
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'px-4': sidePadding === 'small',
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'px-4 md:px-8': sidePadding === 'medium',
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'px-4 md:px-8 lg:px-16': sidePadding === 'large',
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'px-8 md:px-16 lg:px-32': sidePadding === 'extraLarge',
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'py-4': verticalPadding === 'small',
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'py-4 md:py-8': verticalPadding === 'medium',
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'py-4 md:py-8 lg:py-16': verticalPadding === 'large',
      // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
      'py-8 md:py-16 lg:py-32': verticalPadding === 'extraLarge'
    }
  )

  const linkColorReplacement =
    linkColor && colorTranslations[linkColor]
      ? { '--prose-links-color': `${colorTranslations[linkColor]}` }
      : {}

  const textColorReplacement =
    textColor && colorTranslations[textColor]
      ? {
          '--prose-body-color': `${colorTranslations[textColor]}`,
          '--prose-headings-color': `${colorTranslations[textColor]}`,
          color: `${colorTranslations[textColor]}`
        }
      : {}

  const innerSpans = hasPromoSticker
    ? colSpansWithSticker
    : colSpansWithoutSticker
  function renderQWModal() {
    const onClick = () => {
      optimizelyTrackSiteEvents({ eventType: 'impacted_22146810067' })
    }
    if (!quoteWizardModal) {
      return null
    } else if (prop('experimentId', quoteWizardModal)) {
      // @ts-expect-error TS(2322) FIXME: Type 'ContentfulModalContentfulVariationContainerU... Remove this comment to see the full error message
      return (
        <ContentfulVariationContainerComponent
          data={quoteWizardModal}
          renderVariant={variantData => {
            // @ts-expect-error TS(2339) FIXME: Property 'internal' does not exist on type 'Varian... Remove this comment to see the full error message
            if (variantData.internal.type === 'ContentfulButton') {
              return (
                <ButtonComponent
                  data={appendVIDandUniqueSessionIdtoButtonUrl(
                    variantData as ContentfulButton,
                    vid,
                    clientId,
                    sessionId
                  )}
                  onClick={onClick}
                />
              )
            } else {
              // @ts-expect-error TS(2322) FIXME: Type 'Variant' is not assignable to type 'Omit<Con... Remove this comment to see the full error message
              return (
                <ModalComponent
                  data={variantData}
                  onClick={onClick}
                  style={{ content: { padding: '32px' } }}
                />
              )
            }
          }}
        />
      )
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'ContentfulModalContentfulVariationContainerU... Remove this comment to see the full error message
      return (
        <ModalComponent
          data={quoteWizardModal}
          style={{ content: { padding: '32px' } }}
        />
      )
    }
  }

  return (
    <Column
      className={`!overflow-y-${verticalOverflow} h-full`}
      rounded="none"
      spans={calculateSpans(column, colsLength)}
    >
      {floatingBadgeData ? (
        <FloatingBadgeComponent data={floatingBadgeData} />
      ) : null}
      {isReady ? (
        <BgImage
          className={containerClassNames}
          critical={true}
          // @ts-expect-error TS(2739) FIXME: Type '(IGatsbyImageData | { media: string; layout:... Remove this comment to see the full error message
          image={images}
        >
          <div
            className={classNames('w-full', {
              [containerClassNames]: !images.length
            })}
            style={{
              ...textColorReplacement,
              ...linkColorReplacement,
              backgroundColor:
                backgroundColor && !images.length
                  ? colorTranslations[backgroundColor]
                  : 'inherit'
            }}
          >
            <Row rounded="none">
              <Column rounded="none" spans={innerSpans}>
                <Text
                  className={classNames('w-full', {
                    'self-baseline': verticalAlign === 'baseline',
                    'self-center': verticalAlign === 'center',
                    'self-end': verticalAlign === 'end',
                    'self-start': verticalAlign === 'start',
                    'self-stretch': verticalAlign === 'stretch'
                  })}
                  textAlignment={textAlignment}
                  useTailwind
                >
                  {
                    // @ts-expect-error TS(2741) FIXME: Property 'data' is missing in type 'ContentfulProm... Remove this comment to see the full error message
                    promoTagProps ? <PromoTag data={promoTagProps} /> : null
                  }
                  <div className="md:hidden">{descriptionMobile}</div>
                  <div className="mt-12 hidden md:!block">{description}</div>
                  {/* Both following elements will be removed when we be able to embed them inside of the text box */}
                  {renderQWModal()}
                  {additionalLinkUrl ? (
                    <div style={{ marginTop: '16px' }}>
                      <a href={additionalLinkUrl}>{additionalLinkText}</a>
                    </div>
                  ) : null}
                  {/* @ts-expect-error TS(2339) FIXME: Property 'map' does not exist on type '<V>(p: stri... Remove this comment to see the full error message */}
                  {heroComponents
                    ? heroComponents.map(comp => (
                        <React.Fragment key={comp?.id}>
                          {mapPageComponentToTemplate(comp, pageContext)}
                        </React.Fragment>
                      ))
                    : null}
                </Text>
              </Column>
              {hasPromoSticker ? <PromoSticker /> : null}
            </Row>
          </div>
        </BgImage>
      ) : null}
    </Column>
  )
}

export default HeroColumn
