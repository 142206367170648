import { useContentful } from '@ecomm/shared-apollo'
import type { FormQuerySchema } from '@ecomm/shared-components'
import { GRID_QUERY, gridSchema, GridSection } from '@ecomm/shared-sections'
import { Suspense } from 'react'

import PartnershipComponent from '../../components/PartnershipComponent'

const useGridQuery = (id: string) => {
  const { data } = useContentful(GRID_QUERY, { id })
  return gridSchema.parse(data.grid, {
    path: ['PartnerWithUsTemplate', 'GatsbyGrid', 'useGridQuery']
  })
}

type Props = {
  readonly id: string
  readonly formV2Data: FormQuerySchema | undefined
}

function Content({ id, formV2Data }: Props) {
  const grid = useGridQuery(id)
  return grid.headline === 'A Partnership That Works' ? (
    <div className="mb-4 mt-10 flex flex-col">
      <PartnershipComponent formV2Data={formV2Data} referencesData={grid} />
    </div>
  ) : (
    <div className="max-w-8xl mx-auto my-10 flex flex-col px-4 md:mb-16 md:px-8">
      <GridSection {...grid} />
    </div>
  )
}

/**
 * This is temporary while we are still on Gatsby.
 */
export function GatsbyGrid(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}
