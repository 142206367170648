import { Address } from '@commercetools/platform-sdk'
import { MaybeT, propOr } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'

export const getBillingAddress = (
  cart: RemoteData<ImmutableCart>
): MaybeT<Address> => {
  return cart.toMaybe().chain((_cart: ImmutableCart): MaybeT<Address> => {
    const defaultAddress = () =>
      _cart.get('shippingAddress').map(
        (address: Address): Address => ({
          country: propOr('', 'country', address),
          email: propOr('', 'email', address)
        })
      )

    return _cart.get('billingAddress').orElse(defaultAddress())
  })
}
