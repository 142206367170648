import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { schema } from './schema'
import { data } from './scoutPageData'

export const useScoutProductPageConfidenceBar = () => {
  const { headline, listItems } = useLocalizedData<typeof schema>(schema, data)

  return useMemo(
    () => ({
      headline,
      listItems
    }),
    [headline, listItems]
  )
}
