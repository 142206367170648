import { Node } from '@contentful/rich-text-types'
import { SSImg } from '@ecomm/ss-react-components'
import path from 'ramda/src/path'
import React from 'react'

export const getEmbeddedAssetComponent = (node: Node) => {
  const title = path<string>(['data', 'target', 'fields', 'title'], node) || ''
  const description = path<string>(
    ['data', 'target', 'fields', 'description'],
    node
  )
  const alt = description ? description : title
  const url =
    path<string>(['data', 'target', 'fields', 'file', 'url'], node) || ''
  const isImage = path<string>(
    ['data', 'target', 'fields', 'file', 'contentType'],
    node
  )?.startsWith('image')

  const { height, width } =
    path<{ readonly height?: number; readonly width?: number }>(
      ['data', 'target', 'fields', 'file', 'details', 'image'],
      node
    ) || {}

  return isImage ? (
    <SSImg alt={alt} height={height} src={url} width={width} />
  ) : null
}
