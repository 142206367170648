import classNames from 'classnames'
import React, { FC, HTMLAttributes } from 'react'

import { Row } from '..'
import FloatingBadge, { FloatingBadgeProps } from '../FloatingBadge'
import { useMediaQuery } from '../hooks'
import { HeightTypes } from '../Row'
import { SiteColor } from '../types/colors'

export type HeroBannerContainerProps = HTMLAttributes<HTMLDivElement> & {
  readonly backgroundColors?: readonly SiteColor[]
  readonly dataComponent?: string
  readonly floatingPromoBadgeProps?: FloatingBadgeProps
  readonly isFullWidth?: boolean
  readonly disclaimerText?: string | null
  readonly heightType?: HeightTypes
}

// should be the new colors mapping. To be globalized.
export const colorTranslations: Record<string, string> = {
  brandPrimary: 'var(--primary-100)',
  complementaryBlue100: 'var(--complementary-blue-100)',
  neutralBlack: 'var(--neutral-black)',
  neutralLight100: 'var(--neutral-light-100)',
  neutralWhite: 'var(--white)'
}

/** @deprecated Do not use ss-react-components*/
const HeroBannerContainer: FC<HeroBannerContainerProps> = ({
  backgroundColors = [],
  className,
  children,
  dataComponent = HeroBannerContainer.name,
  floatingPromoBadgeProps,
  isFullWidth = true,
  heightType = 'responsive',
  disclaimerText = '',
  ...rest
}: HeroBannerContainerProps) => {
  const backgroundStyle =
    !backgroundColors.length || backgroundColors.length === 1
      ? {
          backgroundColor: `${
            colorTranslations[backgroundColors[0]] || 'transparent'
          }`
        }
      : {
          background: `linear-gradient(90deg, ${backgroundColors
            .map(
              c => `${colorTranslations[c]} ${100 / backgroundColors.length}%`
            )
            .join(',')})`
        }
  const isTabletUp = useMediaQuery('TabletAndUp')

  return (
    <>
      <div
        className={classNames(
          'w-full',
          { 'lg:px-8 lg:pt-8': !isFullWidth },
          className
        )}
        data-component={dataComponent}
        style={isFullWidth ? backgroundStyle : {}}
        {...rest}
      >
        <div
          className={classNames(
            'relative mx-auto w-full max-w-[1312px] bg-cover bg-center bg-no-repeat',
            { 'lg:rounded-base lg:overflow-hidden': !isFullWidth }
          )}
        >
          <Row gap="none" height={heightType} rounded="none">
            {children}
          </Row>
        </div>
      </div>
      {floatingPromoBadgeProps ? (
        <div className="relative mx-auto -mt-8 flex w-full max-w-[1312px] justify-center md:-mt-24 lg:-mb-20 lg:justify-end lg:px-24">
          <FloatingBadge {...floatingPromoBadgeProps} />
        </div>
      ) : null}
      {disclaimerText && isTabletUp ? (
        <div className="textSizeXS w-100 py-4 pr-10 text-right text-neutral-dark">
          {disclaimerText}
        </div>
      ) : null}
    </>
  )
}

export default HeroBannerContainer
