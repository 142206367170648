import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import type {
  FlushResult,
  Identify,
  OnProfileChange,
  Page,
  ProfileState,
  Track
} from '@ninetailed/experience.js'
import { useNinetailed as _useNinetailed } from '@ninetailed/experience.js-gatsby'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'

type Properties = Parameters<Track>[1]
type EventFunctionOptions = Parameters<Track>[2]
type Traits = Parameters<Identify>[1]
type PageviewProperties = Parameters<Page>[0]

/**
 * This is an abstraction over Ninetailed's `useNinetailed` hook.
 * It behaves the same way, but it won't throw an error if the hook is used without a provider.
 *
 * https://docs.ninetailed.io/for-developers/experience-sdk/react-hooks#useninetailed
 */
export const useNinetailed = (): {
  identify: (
    uid: string,
    traits?: Traits,
    options?: EventFunctionOptions
  ) => E.Either<void, Promise<FlushResult>>
  track: (
    event: string,
    properties?: Properties,
    options?: EventFunctionOptions
  ) => E.Either<void, Promise<FlushResult>>
  page: (
    data?: PageviewProperties,
    options?: EventFunctionOptions
  ) => E.Either<void, Promise<FlushResult>>
  profileState: O.Option<ProfileState>
  onProfileChange: O.Option<OnProfileChange>
} => {
  const locale = useLocale()

  try {
    const { identify, track, page, profileState, onProfileChange } =
      _useNinetailed()
    return {
      identify: (
        uid: string,
        traits?: Traits,
        options?: EventFunctionOptions
      ) => E.right(identify(uid, traits, options)),
      track: (
        event: string,
        properties?: Properties,
        options?: EventFunctionOptions
      ) => E.right(track(event, properties, options)),
      page: (data?: PageviewProperties, options?: EventFunctionOptions) =>
        E.right(page(data, options)),
      profileState: O.some(profileState),
      onProfileChange: O.some(onProfileChange)
    }
  } catch (err) {
    // we want to log an error if the locale is en-US since we expect to have a ninetailed provider
    locale === 'en-US' &&
      logError(err instanceof Error ? err : Error(`${JSON.stringify(err)}`))

    return {
      identify: (
        uid: string,
        traits?: Traits,
        options?: EventFunctionOptions
      ) => E.left(voidFn(uid, traits, options)),
      track: (
        event: string,
        properties?: Properties,
        options?: EventFunctionOptions
      ) => E.left(voidFn(event, properties, options)),
      page: (data?: PageviewProperties, options?: EventFunctionOptions) =>
        E.left(voidFn(data, options)),
      profileState: O.none,
      onProfileChange: O.none
    }
  }
}
