import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { ConfidenceBarProps } from '@ecomm/scout'
import { Locale } from '@ecomm/utils'

import { NhwWrappedSchema } from './schema'

const confidenceBarData: Record<Locale, ConfidenceBarProps['listItems']> = {
  'en-US': [
    {
      headline:
        'Professional monitoring starting at less than $1/day & your first month free',
      body: '',
      icon: 'SupportIcon'
    },
    {
      headline: 'Easy to install yourself or choose optional pro install',
      body: '',
      icon: 'SecureHomeIcon'
    },
    {
      headline: 'Complete control with the SimpliSafe® App',
      body: '',
      icon: 'CellphoneIcon'
    },
    {
      headline: '60-day money-back guarantee with free return shipping',
      body: '',
      icon: 'SecureCheckmarkIcon'
    }
  ],
  'en-GB': [
    {
      headline: 'Professional monitoring under 53p/day & your first month free',
      body: '',
      icon: 'SupportIcon'
    },
    {
      headline: 'Easy to install yourself or choose optional pro install',
      body: '',
      icon: 'SecureHomeIcon'
    },
    {
      headline: 'Complete control with the SimpliSafe® App',
      body: '',
      icon: 'CellphoneIcon'
    },
    {
      headline: '60-day money-back guarantee with free return shipping',
      body: '',
      icon: 'SecureCheckmarkIcon'
    }
  ]
}

export const getConfidenceBarData = (locale: Locale) =>
  confidenceBarData[locale]

export const userReviewsData = {
  'en-US': [
    {
      name: 'John A.',
      text: '“The system scared the burglar away, and kept him away from our family upstairs. They caught the burglar 4 hours later. With this video, the police can secure the proof that this was him.”'
    },
    {
      name: 'Laura E.',
      text: '“Y’all, I just switched from a security company I will not name. I am extremely pleased.”'
    },
    {
      name: 'Crystal M.',
      text: '“Easiest. Thing. I. Have. Ever. Installed.”'
    },
    {
      name: 'Richard M.',
      text: '"This system just works. Zero complaints.”'
    }
  ],
  'en-GB': [
    {
      name: 'John A.',
      text: '“The system scared the burglar away, and kept him away from our family upstairs. They caught the burglar 4 hours later. With this video, the police can secure the proof that this was him.”'
    },
    {
      name: 'Laura E.',
      text: '“I just switched from a security company I will not name. I am extremely pleased.”'
    },
    {
      name: 'Crystal M.',
      text: '“Easiest. Thing. I. Have. Ever. Installed.”'
    },
    {
      name: 'Richard M.',
      text: '"This system just works. Zero complaints.”'
    }
  ]
}

export const getUserReviewsData = (locale: Locale) => {
  return userReviewsData[locale]
}

const findByContentfulId = (
  contentfulId: string,
  images: NhwWrappedSchema['expertReviewsAssets']['nodes']
) => {
  /* istanbul ignore next */
  return images.find(element => element.contentful_id === contentfulId)
}

export const getAppWidgetData = (
  images: NhwWrappedSchema['expertReviewsAssets']['nodes'],
  locale: Locale
) => {
  const appWidgetData = {
    'en-US': [
      {
        tab: 'Cameras',
        title: 'Keep watch indoors and out with live camera feeds',
        list: [
          'Crisp, HD video with night vision',
          'Unlimited 30-day cloud storage of recordings*',
          '2-way audio on indoor and outdoor cameras',
          'Smart motion detection instantly alerts you of activity'
        ],
        image: findByContentfulId('ZYB8WxMtOe454lse5FG2D', images),
        disclaimer: ['*For up to 10 cameras. Monitoring plan required.']
      },
      {
        tab: 'Controls',
        title: 'Protect your home from anywhere',
        list: [
          'Arm and disarm from anywhere',
          'Lock and unlock Smart Locks',
          'Check temperature sensors'
        ],
        image: findByContentfulId('6rmX3DElVdPZn4Hw3JNqV9', images)
      },
      {
        tab: 'Alerts',
        title: 'Get notified instantly of unusual activity',
        list: [
          'Receive an immediate notification when motion is detected',
          'Cancel an alarm using the app, keypad, or text message*',
          'Monitor against break-ins, fires, floods and more',
          'SimpliSafe® agents call to verify alarms**'
        ],
        disclaimer: [
          '*Monitoring plan required to cancel by text message.',
          '**Professional monitoring plan required.'
        ],
        image: findByContentfulId('50wlqz44dQwAzOoxzmPO90', images)
      },
      {
        tab: 'Doorbell',
        title: 'See who’s at the door',
        list: [
          'See and speak to visitors at the door',
          'Communicate safely from home or away',
          'Capture evidence of porch pirates*'
        ],
        disclaimer: ['*Monitoring plan with camera recordings is required.'],
        image: findByContentfulId('38iheGuzSVg8kPQ1aokAnw', images)
      }
    ],
    'en-GB': [
      {
        tab: 'Cameras',
        title: 'Keep watch indoors and out with live camera feeds',
        list: [
          'Crisp, HD video with night vision',
          'Unlimited 30-day cloud storage of recordings*',
          '2-way audio on indoor and outdoor cameras',
          'Smart motion detection instantly alerts you of activity'
        ],
        image: findByContentfulId('ZYB8WxMtOe454lse5FG2D', images),
        disclaimer: ['*For up to 10 cameras. Monitoring plan required.']
      },
      {
        tab: 'Controls',
        title: 'Protect your home from anywhere',
        list: ['Arm and disarm from anywhere', 'Check temperature sensors'],
        image: findByContentfulId('6LT6dvk157aAfFdKyeG2Kf', images)
      },
      {
        tab: 'Alerts',
        title: 'Get notified instantly of unusual activity',
        list: [
          'Receive an immediate notification when motion is detected',
          'Cancel an alarm using the app, keypad, or text message*',
          'Monitor against break-ins, fires, floods and more',
          'SimpliSafe® agents call to verify alarms**'
        ],
        disclaimer: [
          '*Monitoring plan required to cancel by text message.',
          '**Professional monitoring plan required.'
        ],
        image: findByContentfulId('50wlqz44dQwAzOoxzmPO90', images)
      },
      {
        tab: 'Doorbell',
        title: 'See who’s at the door',
        list: [
          'See and speak to visitors at the door',
          'Communicate safely from home or away',
          'Capture evidence of porch pirates*'
        ],
        disclaimer: ['*Monitoring plan with camera recordings is required.'],
        image: findByContentfulId('38iheGuzSVg8kPQ1aokAnw', images)
      }
    ]
  }

  return appWidgetData[locale]
}

const appStoreLogo: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=46&h=14&q=50&fm=webp 46w,\nhttps://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=92&h=27&q=50&fm=webp 92w,\nhttps://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=184&h=54&q=50&fm=webp 184w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=184&h=54&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 184,
    height: 54
  },
  title: 'App Store Logo',
  description: 'App Store Logo'
}

const playStoreLogo: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=46&h=14&q=50&fm=webp 46w,\nhttps://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=92&h=28&q=50&fm=webp 92w,\nhttps://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=184&h=56&q=50&fm=webp 184w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=184&h=56&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 184,
    height: 56
  },
  title: 'Play Store Logo',
  description: 'Play Store Logo'
}

const simplisafeIcon: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=32&h=32&q=50&fm=webp 32w,\nhttps://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=63&h=64&q=50&fm=webp 63w,\nhttps://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=126&h=127&q=50&fm=webp 126w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=126&h=127&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 126,
    height: 127
  },
  description: 'SimpliSafe Icon',
  title: 'SimpliSafe Icon'
}

export const appRatingImages = { appStoreLogo, playStoreLogo, simplisafeIcon }
