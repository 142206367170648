import type { FormikHelpers } from 'formik'
import { type TypeOf, z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { contentfulRichTextSchema } from '@ecomm/shared-components'
import { ukPhoneSchema, usPhoneSchema } from '@ecomm/checkout/shipping-schema'

const EMAIL_ERROR = 'Please enter a valid email address.'
const formFieldsSchemaBase = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string({ required_error: EMAIL_ERROR }).email(EMAIL_ERROR),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  postalCode: z.string()
})

const formFieldsSchema = formFieldsSchemaBase.merge(
  z.object({
    state: z.string(),
    phone: usPhoneSchema
  })
)

const formFieldsUKSchema = formFieldsSchemaBase.merge(
  z.object({
    country: z.string().optional(),
    phone: ukPhoneSchema
  })
)

const formQuerySchema = z.object({
  __typename: z.literal('ContentfulFormV2'),
  fields: z
    .object({
      label: z.string(),
      placeholder: z.string(),
      type: z.enum(['Text', 'Email', 'Telephone', 'Dropdown']),
      name: z.string()
    })
    .array(),
  button: z.object({
    text: z.string()
  }),
  note: contentfulRichTextSchema.nullish()
})

export const validationSchemaUS = toFormikValidationSchema(formFieldsSchema)
export const validationSchemaUK = toFormikValidationSchema(formFieldsUKSchema)
export type FormQuerySchema = TypeOf<typeof formQuerySchema>
export type FormFieldsSchema = TypeOf<typeof formFieldsSchema>
export type FormFieldsUKSchema = TypeOf<typeof formFieldsUKSchema>
export type OnSubmit = (
  values: FormFieldsSchema,
  formikHelpers: FormikHelpers<FormFieldsSchema>
) => void
export type SubmissionResultProps = {
  readonly result: 'error' | 'pending' | 'success'
}
