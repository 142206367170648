import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export const useTrackingProductOpenModal = (productName?: string) => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    productName &&
      trackEvent({
        event: 'package-sensor-info',
        label: productName
      })
  }, [trackEvent, productName])
}
