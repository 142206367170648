import { gql } from '@apollo/client/index.js'
import { SMALL_TEXT_FRAGMENT } from '@ecomm/shared-data'

export const GRID_FRAGMENT = gql`
  ${SMALL_TEXT_FRAGMENT}
  fragment Grid on Grid {
    headline
    headlineAlignment
    showTextOnMobile
    showBorders
    desktopColumns
    mobileColumns
    tabletColumns
    # TODO: grid on UK /press-kit page has 21 grid items
    gridItemsCollection(limit: 10) {
      items {
        ... on Text {
          ...SmallText
        }
        ... on Link {
              url
              linkText: text
              icon {
                ...image
              }
            }
      }
    }
  }
`

export const GRID_QUERY = gql`
  ${GRID_FRAGMENT}
  query Grid($id: String!, $locale: String!, $preview: Boolean!) {
    grid(id: $id, locale: $locale, preview: $preview) {
      ...Grid
  }
}`
