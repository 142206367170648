import { match } from 'ts-pattern'

import type { CarouselAction, CarouselState } from './CarouselTypes'
import { CarouselActionType } from './CarouselTypes'

type CarouselReducer = (
  state: CarouselState,
  action: CarouselAction
) => CarouselState

const carouselReducer: CarouselReducer = (state, action) =>
  match<CarouselAction, CarouselState>(action)
    .with({ type: CarouselActionType.Next }, () => ({
      ...state,
      currentIdx: state.currentIdx + 1
    }))
    .with({ type: CarouselActionType.Previous }, () => {
      const newIdx = state.currentIdx - 1

      if (newIdx < 0) {
        return { ...state }
      } else {
        return {
          ...state,
          currentIdx: state.currentIdx - 1
        }
      }
    })
    .with({ type: CarouselActionType.SetIdx }, action => {
      const newIdx = action.payload

      if (newIdx < 0) {
        return { ...state }
      } else {
        return {
          ...state,
          currentIdx: action.payload
        }
      }
    })
    .with({ type: CarouselActionType.SetAutoPlay }, action => ({
      ...state,
      enableAutoPlay: action.payload
    }))
    .with({ type: CarouselActionType.ToggleAutoPlay }, () => ({
      ...state,
      enableAutoPlay: !state.enableAutoPlay
    }))
    .with({ type: CarouselActionType.SetActiveSlide }, action => ({
      ...state,
      currentIdx: action.payload,
      enableAutoPlay: false
    }))
    .otherwise(() => state)

export default carouselReducer
