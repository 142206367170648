import classNames from 'classnames'
import React from 'react'

type Props = {
  /** For automated testing. */
  readonly dataComponent?: string
  /** The percent of the progress bar to be filled in. */
  readonly percent: number
  /** Click handler for step click */
  readonly stepClick: (e: React.MouseEvent<HTMLElement>) => void
  readonly shouldStepBeClickable?: (element: string) => boolean
  /** The labels for the steps to be completed below the progress bar. */
  readonly stepList: readonly string[]
}

export function LabeledProgressBar({
  percent,
  stepList,
  stepClick,
  dataComponent = 'ProgressBarLabels',
  shouldStepBeClickable = () => false
}: Props) {
  return (
    <div
      className="mx-auto mb-4 block w-full text-center md:m-0 md:my-auto md:inline-block md:w-full md:max-w-[528px] md:text-right"
      data-component={dataComponent}
    >
      <div
        className={classNames(
          'rounded-base bg-neutral-light-50 h-3 w-full md:w-auto'
        )}
        role="progressbar"
      >
        <div
          className="rounded-base bg-primary-100 h-full"
          style={{ width: `${percent}%` }}
        />
      </div>
      <div className="block">
        <ul
          className={classNames('mb-0 mt-1.5 p-0', {
            'flex justify-between': stepList.length > 1,
            'text-center': stepList.length === 1
          })}
        >
          {stepList.map(step => {
            return (
              <li
                className={classNames(
                  'list-none px-4 text-xs font-light !leading-4 first:pl-0 last:pr-0 md:text-sm',
                  {
                    'cursor-pointer':
                      shouldStepBeClickable && shouldStepBeClickable(step)
                  }
                )}
                key={step}
                onClick={e =>
                  stepClick && shouldStepBeClickable(step) && stepClick(e)
                }
              >
                {step}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
