//@ts-nocheck
import classNames from 'classnames'
import React, { cloneElement, FC, isValidElement, ReactNode } from 'react'

import { SiteColor } from '../types/colors'
import * as css from './SmallTextSection.module.scss'

export type pageContextType = {
  readonly locationCrimesTable?: {
    readonly head: readonly string[]
    readonly body: ReadonlyArray<ReadonlyArray<string>>
  }
}

// This should be support more theme names
export type themeName =
  | 'blackFridayUK1'
  | 'blackFridayUK2'
  | 'blackFridayUS'
  | 'brandPrimaryTitle'
  | 'cardIconTitle'
  | 'cyberMondayUK'
  | 'cyberMondayUS'
  | 'errorMessage'
  | 'grid'
  | 'iconTextButton'
  | 'iconTextCenterAlignment'
  | 'iconTextCenterInline'
  | 'iconTextLeftAlignment'
  | 'iconTextLeftDesktopLeftInlineMobile'
  | 'textDisclaimer'
  | 'textFullWidth'
  | 'textHalfWidth'
  | 'textLeftAlignment'
  | 'textRightAlignment'

export type shadowType = 'medium' | 'soft' | 'softBottom' | 'strong'
export type SidePadding = 'large' | 'medium' | 'none' | 'small' | 'standard'
export type VerticalPadding = 'large' | 'medium' | 'none' | 'small' | 'standard'

export type SmallTextSectionProps = {
  /** Sets a background color */
  readonly backgroundColor?: SiteColor
  /* Sets the border for whole section */
  readonly borderLine?: boolean
  /** Rich text json from Contentful. */
  /** The content of the component */
  readonly content?: ReactNode
  /** Sets the marker style for unordered lists */
  readonly listStyle?: 'checkmark' | 'standard'
  /** Sets a text color */
  readonly textColor?: SiteColor
  /** Sets the layout. */
  readonly theme?: themeName
  /** Padding side the content */
  readonly sidePadding?: SidePadding
  /** Padding above and below the content */
  readonly verticalPadding?: VerticalPadding
  /** Hold the page context comes from Page.tsx */
  readonly pageContext?: pageContextType
  /** Hold the visibility of other device */
  readonly otherDeviceVisibility?: string
  /** Shadow border type */
  readonly shadowBorder?: shadowType
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly className?: string
}

/** @deprecated Do not use ss-react-components*/
const SmallTextSection: FC<SmallTextSectionProps> = ({
  backgroundColor = 'none',
  content,
  listStyle = 'standard',
  textColor,
  theme,
  sidePadding = 'none',
  verticalPadding = 'none',
  shadowBorder,
  dataComponent = SmallTextSection.name,
  className,
  borderLine
}: SmallTextSectionProps) => {
  const richTextClasses = classNames(
    className,
    {
      'px-4': sidePadding === 'small',
      'px-4 md:px-16 lg:px-56': sidePadding === 'standard',
      'px-4 md:px-8': sidePadding === 'medium',
      'px-4 md:px-8 lg:px-16': sidePadding === 'large',
      'py-4': verticalPadding === 'small',
      'py-4 md:py-8': verticalPadding === 'medium',
      'py-4 md:py-8 lg:py-16': verticalPadding === 'large',
      'py-5 md:py-16 lg:py-[76px]': verticalPadding === 'standard'
    },
    { [css.cardIconTitle]: theme === 'cardIconTitle' },
    { alignCenter: theme === 'textFullWidth' },
    { alignRight: theme === 'textRightAlignment' },
    { [css.bannerContent]: theme === 'textLeftAlignment' },
    { [css.textHalfWidth]: theme === 'textHalfWidth' },
    { [css.iconTextButton]: theme === 'iconTextButton' },
    { [css.iconTextCenterInline]: theme === 'iconTextCenterInline' },
    {
      [css.iconTextLeftDesktopInlineMobile]:
        theme === 'iconTextLeftDesktopLeftInlineMobile'
    },
    { [css.grid]: theme === 'grid' },
    { [css.leftTextAlignment]: theme === 'iconTextLeftAlignment' },
    { [`${backgroundColor}BackgroundColor`]: backgroundColor !== 'none' },
    { [`${textColor}TextColor`]: !!textColor },
    { [css.errorMessage]: theme === 'errorMessage' },
    { [css.textCenterAlignment]: theme === 'iconTextCenterAlignment' },
    { [css.borderLine]: borderLine === true },
    // eslint-comments/no-duplicate-disable -- legacy code

    { shadowMedium: shadowBorder === 'medium' },
    { [css.blackFridayUS]: theme === 'blackFridayUS' },
    { [css.cyberMondayUS]: theme === 'cyberMondayUS' },
    //eslint-comments/no-duplicate-disable -- legacy code

    { [css.blackFridayUK1]: theme === 'blackFridayUK1' },
    { [css.blackFridayUK2]: theme === 'blackFridayUK2' },
    { [css.cyberMondayUK]: theme === 'cyberMondayUK' },
    { [css.brandPrimaryTitle]: theme === 'brandPrimaryTitle' }
  )

  const contentNode =
    content &&
    isValidElement(content) &&
    cloneElement(content, {
      className: richTextClasses,
      listStyle: listStyle === 'checkmark' ? 'checkmark' : undefined
    })

  return (
    <div
      className={classNames('rounded-base', className)}
      data-component={dataComponent}
    >
      {contentNode}
    </div>
  )
}

export default SmallTextSection
