import { warn } from '@ecomm/utils'
import { Map } from 'immutable'
import React from 'react'

import { ProductContext } from './ProductContext'
import { Product, ProductProviderProps } from './types'

const getProduct = (products: ReadonlyMap<string, Product>) => (sku: string) =>
  products.get(sku) || null

const getProducts =
  (products: ReadonlyMap<string, Product>) => (skus: readonly string[]) => {
    skus.forEach(
      sku => !products.has(sku) && warn(`${sku} not found in ProductContext`)
    )
    return skus
      .map(sku => products.get(sku))
      .filter((product): product is Product => !!product)
  }

export function ProductProvider({
  children,
  products: productsArr
}: ProductProviderProps) {
  const products = Map(productsArr.map(product => [product.sku, product]))

  return (
    <ProductContext.Provider
      value={{
        getProduct: getProduct(products),
        getProducts: getProducts(products)
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
