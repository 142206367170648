import { Address } from '@commercetools/platform-sdk'
import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { states } from '@ecomm/utils'
import {
  Address as UserAddress,
  UserCheckoutData
} from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

/**
 * Get state abbreviation
 */
export const getStateAbbreviation = (stateName: string) => {
  const stateInfo = states.find(
    state => state.name.toLowerCase() === stateName.toLowerCase()
  )
  return stateInfo?.abbreviation || stateName
}

/**
 * Format address
 */
export const formatFirstAddress = (userCheckoutData: UserCheckoutData) => {
  const optionCheckoutData = O.fromNullable(userCheckoutData)

  const emailAddress = pipe(
    optionCheckoutData,
    O.map(checkoutData => checkoutData.emailAddress),
    O.getOrElse(() => '')
  )

  return pipe(
    optionCheckoutData,
    O.chain(getFirstAddress),
    O.chain(formatAddress(emailAddress))
  )
}

const getFirstAddress = (userCheckoutData: UserCheckoutData) =>
  A.head([...userCheckoutData.addresses])

const formatAddress =
  (emailAddress: string) =>
  (address: UserAddress): O.Option<Partial<CheckoutFormValuesType>> =>
    pipe(
      O.fromNullable(address),
      O.map(address => ({
        additionalStreetInfo: address.street2 || '',
        city: address.city || '',
        email: emailAddress || '',
        firstName: address.firstName || '',
        lastName: address.lastName || '',
        phone: address.phone || '',
        postalCode: address.zip || '',
        state: getStateAbbreviation(address.state || ''),
        streetName: address.street1 || ''
      }))
    )

export const formatPrefilledAddress = (data: Address) => ({
  additionalStreetInfo: data.additionalStreetInfo || '',
  city: data.city || '',
  email: data.email || '',
  firstName: data.firstName || '',
  lastName: data.lastName || '',
  phone: data.phone || '',
  postalCode: data.postalCode || '',
  state: data.state || '',
  streetName: data.streetName || ''
})
