import {
  FormCheckbox,
  FormField,
  FormSection,
  FormSelect,
  FormTextInput
} from '@ecomm/checkout/components'
import {
  useReturningCustomerAddress,
  useUpdatePostalCode,
  useUserShippingAddress
} from '@ecomm/checkout/shipping-hooks'
import type { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { isSiteUS } from '@ecomm/checkout/utils'
import { usStates } from '@ecomm/data-constants'
import { useMicroCopy } from '@ecomm/micro-copy'
import type { Locale } from '@ecomm/utils'
import { voidFn } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import type React from 'react'
import { useEffect } from 'react'

export type ShippingAddressProps = {
  readonly locale: Locale
  readonly loginSuccess: boolean
  readonly selectCountry: readonly string[]
  readonly setCurrentCouponValue: React.Dispatch<React.SetStateAction<string>>
  readonly setLoginSuccess: React.Dispatch<React.SetStateAction<boolean>>
  readonly setUserAddress: React.Dispatch<
    React.SetStateAction<Partial<CheckoutFormValuesType>>
  >
  readonly userAddress: Partial<CheckoutFormValuesType>
}

export function ShippingAddress(props: ShippingAddressProps) {
  const {
    locale,
    loginSuccess,
    selectCountry,
    setLoginSuccess,
    setUserAddress,
    userAddress
  } = props

  const { shippingAddress } = useUserShippingAddress()

  const microCopy = useMicroCopy()
  // Uppercase the postal code when the user is in the UK page
  useUpdatePostalCode(locale)

  useEffect(() => {
    if (shippingAddress && O.isSome(shippingAddress)) {
      setUserAddress(shippingAddress.value)
      setLoginSuccess(true)
      return voidFn
    } else {
      return voidFn
    }
  }, [setLoginSuccess, setUserAddress, shippingAddress])

  // Set form data after user logged in
  useReturningCustomerAddress(loginSuccess, userAddress, setUserAddress)

  return (
    <div data-component="ShippingAddress">
      <FormSection title="Shipping address">
        <div className="rounded-base bg-neutral-light-50 p-4 pb-4 md:px-8 md:pt-8">
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full flex-col gap-4 md:flex-row lg:gap-6">
              <FormField
                className="w-full grow"
                id="firstName"
                label="First Name*"
              >
                <FormTextInput id="firstName" name="firstName" />
              </FormField>
              <FormField
                className="w-full grow"
                id="lastName"
                label="Last Name*"
              >
                <FormTextInput id="lastName" name="lastName" />
              </FormField>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
              <FormField
                className="w-full grow"
                id="streetName"
                label="Address 1*"
              >
                <FormTextInput id="streetName" name="streetName" />
              </FormField>
              <FormField
                className="w-full grow"
                id="additionalStreetInfo"
                label="Address 2 (Optional)"
              >
                <FormTextInput
                  id="additionalStreetInfo"
                  name="additionalStreetInfo"
                  placeholder="Apt/Suite/Other"
                />
              </FormField>
            </div>
            <div className="flex flex-col gap-4 lg:w-full lg:flex-row lg:gap-6">
              <FormField className="w-full grow" id="city" label="City*">
                <FormTextInput id="city" name="city" />
              </FormField>
              <div className="grid w-full grow grid-cols-2 gap-4 lg:gap-6">
                {props.locale && isSiteUS(props.locale) ? (
                  <FormField className="w-full grow" id="state" label="State*">
                    <FormSelect
                      name="state"
                      options={usStates}
                      placeholder="Select One"
                    />
                  </FormField>
                ) : null}
                <FormField
                  className="w-full grow"
                  id="postalCode"
                  label={`${microCopy['postal-code']}*`}
                >
                  <FormTextInput id="postalCode" name="postalCode" />
                </FormField>
              </div>
            </div>
            <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
              <FormField className="w-full grow" id="country" label="Country*">
                <FormSelect
                  name="country"
                  options={selectCountry}
                  placeholder="Select one"
                />
              </FormField>
              <div className="flex w-full grow flex-col">
                <FormField className="w-full" id="phone" label="Phone Number*">
                  <FormTextInput id="phone" name="phone" type="text" />
                </FormField>
                {isSiteUS(locale) && (
                  <p
                    className="mb-6 ml-0 text-sm md:mb-0"
                    data-component="phone_legal_disclaimer"
                  >
                    You agree to be contacted at this phone number about your
                    order.
                    <a
                      className="ml-1 text-neutral-black"
                      href="/legal/privacy-policy"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Privacy policy
                    </a>
                    .
                  </p>
                )}
              </div>
            </div>
          </div>
          <hr
            className={`border-neutral-medium-100 my-4 border border-b-0 border-solid`}
            data-component="Divider"
          />
          <FormField id="same-billing" labelClassName="hidden md:flex">
            <FormCheckbox
              className="text-base font-normal"
              label="My billing information is the same as my delivery information."
              name="sameAddress"
            />
          </FormField>
        </div>
      </FormSection>
    </div>
  )
}
