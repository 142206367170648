//@ts-nocheck
import always from 'ramda/src/always'
import both from 'ramda/src/both'
import compose from 'ramda/src/compose'
import contains from 'ramda/src/contains'
import equals from 'ramda/src/equals'
import F from 'ramda/src/F'
import find from 'ramda/src/find'
import head from 'ramda/src/head'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import join from 'ramda/src/join'
import juxt from 'ramda/src/juxt'
import last from 'ramda/src/last'
import map from 'ramda/src/map'
import not from 'ramda/src/not'
import path from 'ramda/src/path'
// eslint-disable-next-line no-restricted-imports -- legacy code
import pipe from 'ramda/src/pipe'
import propEq from 'ramda/src/propEq'
import split from 'ramda/src/split'
import T from 'ramda/src/T'
import tail from 'ramda/src/tail'
import toLower from 'ramda/src/toLower'
import toUpper from 'ramda/src/toUpper'
import trim from 'ramda/src/trim'
import type from 'ramda/src/type'
import when from 'ramda/src/when'
import React, { ComponentType, Fragment, ReactNode } from 'react'

export const mailformat =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const toSysType = path([
  'data',
  'target',
  'sys',
  'contentType',
  'sys',
  'id'
])

const wrapComponent = (text: string) => {
  return (
    <Fragment key={text}>
      {' '}
      {text} <br />
    </Fragment>
  )
}
const spanComponent = (text: string | unknown) => {
  return <span key={`key-span-${text}`}> {text} </span>
}

const mustCond = both(contains('\n'), pipe(trim, isEmpty, not))
export const wrapperText = when(mustCond, pipe(split('\n'), map(wrapComponent)))
export const newLineCheck = when(pipe(type, equals('String')), wrapperText)
export const spanWrap = when(
  pipe(type, equals('String')),
  pipe(split(' '), map(spanComponent), spanComponent)
)
export const toFirstCharLower = compose(
  join(''),
  juxt([compose(toLower, head), tail])
)

export const getMonthYear = (date: string | undefined) => {
  const dateValue = date || ''
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const getDate = new Date(dateValue)
  const months = getDate.getMonth()
  const years = getDate.getFullYear()
  const returnDate = `${month[months]} ${years}`
  return dateValue && returnDate
}

/**
 * Below are the const and function that used for Checkout and ContactUs Form
 */
//to check when the value isNil and then set the default value to empty string
export const ifNil = when(isNil, always(''))
//to check when the value of list isNil and then set the default value to empty array
export const ifNilToArray = when(isNil, always([]))
//to check when the value is isNil and then set the default value to empty object
export const ifNilToObj = when(isNil, always({}))
//to check when the value is isNil and then set the default value to false
export const ifNilToFalse = when(isNil, F)
//to check when the value is isNil and then set the default value to hash
export const ifNilToHash = when(isNil, always('#'))
//checkbox input type
export const CheckBoxInput = 'Checkbox'
//dropdown input type
export const DropDownInput = 'Dropdown'
//textarea input type
export const TextAreaInput = 'Double Text'
//radio input type
export const RadioInput = 'Radio'
// file input type
export const FileInput = 'File'

export const toFirstCharUpper = compose(
  join(''),
  juxt([compose(toUpper, head), tail])
)

//props for FieldValue
export type FieldValueProps = {
  readonly [key: string]: string
}

//props for FormValue
export type FormValueProps = {
  readonly [key: string]: FieldValueProps | boolean | undefined
  readonly isError?: boolean | undefined
  readonly isEmpty?: boolean | undefined
}

//props for FormHandle
export type FormHandle = {
  readonly doValidate: () => Promise<FormValueProps>
}

//props for radio option
export type RadioOptionProps = {
  readonly text?: string
  readonly value?: number | string
  /** The content of the component */
  readonly content?: ReactNode
}

//Props for fieldValidation
export type FieldValidationProps = {
  readonly errorMessage?: string
  readonly requirement?: string
}

export type InputTypeName =
  | 'Checkbox'
  | 'Double Text'
  | 'Dropdown'
  | 'Email'
  | 'Radio'
  | 'tel'
  | 'Text'

//Props for input field
export type inputFieldType = {
  readonly id: string
  readonly type: InputTypeName
  readonly title?: string | null | undefined
  readonly propName: string
  readonly placeholderText?: string | null | undefined
  readonly placeholderTextMobile?: string
  readonly answerOptions?: readonly string[] | null | undefined
  readonly maximumCharacter?: number | null | undefined
  readonly value: string | ''
  readonly fieldValidation?: readonly FieldValidationProps[]
  readonly errorMessage: string
  readonly fields: string
  readonly radioOption?: readonly RadioOptionProps[]
  readonly defaultValue?: string
  readonly checked?: boolean
  readonly defaultChecked?: boolean
  readonly deviceToDisplay?: readonly [] | undefined
  readonly validationCallbackFn?: ValidationFunction
}

export type ValidationFunction = (v: string) => boolean
export type ValidationFunctionType = {
  readonly [key: string]: (v: string) => boolean | string
}

// to validate whether all the fields are required
export const toRequiredFieldValidate = pipe(
  path(['fieldValidation']),
  when(isNil, always([])),
  find(propEq('requirement', 'Required Field')),
  path(['errorMessage']),
  when(isNil, F)
)

//to validate all input field and set with the error message when there's an invalid input
export const toInvalidInputFieldValidate = pipe(
  path(['fieldValidation']),
  when(isNil, always([])),
  find(propEq('requirement', 'Invalid Input')),
  path(['errorMessage']),
  when(isNil, always(''))
)

//to set value to be true // TODO this can just be T, so we should remove the usage of this function
export const alwaysTrue = T
//to check whether the prop 'type' is equals to 'Email'
export const isEmailValidation = propEq('Email', 'type')
//to check whether the prop 'characterType' is equals to 'Alphanumeric'
export const isAlphaNumeric = propEq('Alphanumeric', 'characterType')
//to check whether the prop 'characterType' is equals to 'Alphanumeric'
export const isAlphaNumericWithChars = propEq(
  'Alphanumeric with Symbols',
  'characterType'
)
//regex for alphanumeric
export const alphaNumericPattern = /^[A-Z0-9]+$/gi
//regex for alphanumeric with characters
export const alphaNumericWithCharsPattern = /^[A-Z0-9!@#$&()\\-`.+,/\"]*$/gi
//to set value to be empty object
export const alwaysEmpty = always({})
//to get maximum character value from prop 'maximumCharacter' and then set into emprty string when value isNil
export const getMaxCharValue = pipe(
  path(['maximumCharacter']),
  when(isNil, always(''))
)
// retrieves an input's custom validation callback if supplied.
export const getInputValidationFn = path<ValidationFunction | undefined>([
  'validationCallbackFn'
])
//fetch blob image url

export const fetchImage = (protocol: string | 'http', url: string) => {
  const urlParts = split('//', url)
  const isProtocolPresent = pipe(head, isEmpty, not)(urlParts)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const fetchUrl = ifElse(
    equals(true),
    always(url),
    always(`${protocol}${url}`)
  )(isProtocolPresent)
  const host = pipe(last, split('/'), head)(urlParts)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return fetch(fetchUrl, { headers: { Host: `${host}` } }).then(response =>
    response.blob()
  )
}
//create link and trigger click even to dowload image file
export const download = async (url: string) => {
  // TODO we can't mutate arrays, this needs to be fixed

  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, functional/immutable-data -- legacy code */
  const fileName = ifNil(url.split('/').pop())
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const contentType = fileName.split('.').pop()
  const element = document.createElement('a')
  const protocol = window.location.protocol
  const response = await fetchImage(protocol, url)
  const blobURL = new Blob([response], { type: `image/${contentType}` })
  element.setAttribute('name', 'download')
  element.setAttribute('className', 'hide')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  element.setAttribute(
    'download',
    when(isNil, always('download.png'))(fileName)
  )
  element.setAttribute('href', URL.createObjectURL(blobURL))
  document.body.appendChild(element)
  element.click()
  return element
}

// Background Component type

export type BackgroundComponentType = ComponentType<{
  readonly [x: string]: unknown
}>
