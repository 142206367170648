import { useLocale } from '@ecomm/data-hooks'
import { PackageFragment, usePackagePrice } from '@ecomm/partners-hooks'
import { useDisplayMonitoringDiscount } from '@ecomm/promotions-hooks'
import {
  AffirmMonthlyDivider,
  AffirmMonthlyOption,
  ContentfulRichText,
  GatsbyImage,
  OfferTag,
  Price
} from '@ecomm/shared-components'
import { window } from 'browser-monads-ts'
import classNames from 'classnames'
import * as O from 'fp-ts/lib/Option'
import React from 'react'

import DetailsButton from './DetailsButton/DetailsButton'

type Props = {
  readonly item: PackageFragment
  readonly itemCount?: number
  readonly showPartnerPackageAbsoluteDiscountAsRelative?: boolean
}
/**
 * This is a similar duplicate of apps/shop/src/components/PackageListItem/index.tsx but only for partner pages
 */
function PackageListItem({
  item,
  itemCount: overrideItemCount,
  showPartnerPackageAbsoluteDiscountAsRelative
}: Props) {
  const { image, displayName, description, product, products, slug } = item

  const displayMonitoringDiscount = useDisplayMonitoringDiscount()

  const { regularPrice, discountedPrice } = usePackagePrice(
    product,
    1,
    displayMonitoringDiscount
  )

  const itemCount = overrideItemCount || products.filter(p => !p.isNew).length
  const isUs = useLocale() === 'en-US'

  return (
    <div className="max-w-8xl mx-auto flex flex-col overflow-hidden rounded-2xl md:flex-row">
      <div className="relative flex-1 md:order-1">
        <OfferTag
          className="z-10 h-12 w-auto font-medium md:absolute md:top-8 md:min-w-[15.5rem] md:text-lg lg:px-4"
          extraText={item.offerTagExtraText?.raw}
          showPartnerPackageAbsoluteDiscountAsRelative={
            showPartnerPackageAbsoluteDiscountAsRelative
          }
          sku={product}
        />
        {image ? (
          <GatsbyImage
            className="h-full w-full"
            image={image}
            loading="eager"
          />
        ) : null}
        {itemCount > 0 && (
          <span className="bg-neutral-light-50 absolute bottom-0 right-0 px-7 py-3 font-bold capitalize md:bottom-8 md:text-lg">
            {itemCount} pieces
          </span>
        )}
      </div>

      <div className="bg-neutral-light-50 flex flex-1 items-center">
        <div
          className="prose-ul:my-2 prose-ul:pl-4 prose-ul:md:pl-5 flex flex-1  flex-col px-4 py-6 md:p-8 lg:p-16"
          id="package-info"
        >
          <h2 className="mb-3 md:mb-5">{displayName}</h2>
          {description ? <ContentfulRichText raw={description.raw} /> : null}
          {isUs ? (
            <AffirmMonthlyDivider className="-mt-2 mb-2" stacked />
          ) : null}
          <div
            className={classNames('mt-0 flex justify-between', {
              'flex-col lg:flex-row': isUs
            })}
          >
            {isUs ? (
              <>
                <AffirmMonthlyOption
                  affirmClient={window.affirm}
                  discountedPrice={O.toUndefined(discountedPrice)}
                  isPlp
                  stacked
                  subTotalPrice={regularPrice}
                />
                <div className="mt-auto">
                  <DetailsButton
                    className="mt-4 border-[0.175rem] px-10 py-4 font-medium"
                    product={product}
                    slug={slug}
                  />
                </div>
              </>
            ) : (
              <>
                <Price sku={product} />
                <DetailsButton product={product} slug={slug} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export { PackageListItem }
