import { getRudderstack } from '@ecomm/shared-window'
import {
  get as sessionStorageGet,
  set as sessionStorageSet
} from '@ecomm/utils'

/**
 * Added this sessionStorage value in addition to the one defined at libs/tracking/src/trackNonBounceVisit/index.ts
 * because when the user opens a quiz, this sessionStorage value at libs/tracking/src/trackNonBounceVisit/index.ts
 * will be set in gtm without a chance to track the non_bounce event in RS before that happens.
 */
const SESSION_STORAGE_NON_BOUNCE_VISITOR_RS = 'nonBounceVisitorRS'

/**
 * Track non bounce visit.
 */
export function trackNonBounce() {
  const nonBounceSessionWasNotTracked =
    sessionStorageGet(SESSION_STORAGE_NON_BOUNCE_VISITOR_RS) !== 'true'
  nonBounceSessionWasNotTracked && getRudderstack(r => r.track('non_bounce'))
  sessionStorageSet(SESSION_STORAGE_NON_BOUNCE_VISITOR_RS, 'true')
}
