import { addAtomDebugLabel } from '@ecomm/utils'
import { OrderedMap } from 'immutable'
import { atom } from 'jotai'
import { atomWithReset } from 'jotai/utils'

export const setQuantity = (
  draft: OrderedMap<string, number>,
  sku: string,
  quantity: number
) => {
  return draft.set(sku, quantity)
}

export const increment = (
  draft: OrderedMap<string, number>,
  sku: string,
  maxQuantity?: number
) => {
  return draft.update(sku, prev => {
    const canIncrease = !prev || !maxQuantity || prev < maxQuantity
    return canIncrease ? (prev || 0) + 1 : prev
  })
}

export const decrement = (draft: OrderedMap<string, number>, sku: string) => {
  const nextQty = draft.get(sku, 0) - 1
  return nextQty <= 0 ? draft.delete(sku) : draft.update(sku, () => nextQty)
}

export const swap = (
  draft: OrderedMap<string, number>,
  oldSku: string,
  newSku: string
) => {
  if (draft.has(oldSku)) {
    // existingQty should never be 0
    const existingQty = draft.get(oldSku, 0)
    const newDraft = draft.delete(oldSku)
    return newDraft.update(newSku, () => existingQty)
  } else {
    return draft
  }
}

/**
 * Base Atom for storing items in a draft cart with their quantity.
 * Ordered map to preserve display consistency.
 */
export const itemQuantityAtom = atomWithReset(OrderedMap<string, number>())
addAtomDebugLabel(itemQuantityAtom, 'DraftCart: ItemQuantity')

/**
 * Write only atom for setting an item(sku) quantity in the draft cart.
 */
export const setItemQtyAtom = atom(
  null,
  (get, set, sku: string, quantity: number) => {
    const cart = get(itemQuantityAtom)
    set(itemQuantityAtom, setQuantity(cart, sku, quantity))
  }
)
addAtomDebugLabel(setItemQtyAtom, 'DraftCart: setItemQtyAtom')

/**
 * Write only atom for increasing an item(sku) quantity in the draft cart.
 */
export const incrementItemQtyAtom = atom(
  null,
  (get, set, sku: string, maxQuantity?: number) => {
    const cart = get(itemQuantityAtom)
    set(itemQuantityAtom, increment(cart, sku, maxQuantity))
  }
)
addAtomDebugLabel(incrementItemQtyAtom, 'DraftCart: IncrementItemQty')

/**
 * Write only atom for decreasing an item(sku) quantity in the draft cart.
 * If the quantity decreases to zero, the item will be removed from the draft cart.
 */
export const decrementItemQtyAtom = atom(null, (get, set, sku: string) => {
  const cart = get(itemQuantityAtom)
  set(itemQuantityAtom, decrement(cart, sku))
})
addAtomDebugLabel(decrementItemQtyAtom, 'DraftCart: DecrementItemQty')

/**
 * Write only atom for swaping item(sku) in the draft cart.
 * If a product has a variant, switch the sku with a new sku
 */
export const changeColorVariantAtom = atom(
  null,
  (
    get,
    set,
    { oldSku, newSku }: { readonly oldSku: string; readonly newSku: string }
  ) => {
    const cart = get(itemQuantityAtom)
    set(itemQuantityAtom, swap(cart, oldSku, newSku))
  }
)
addAtomDebugLabel(changeColorVariantAtom, 'DraftCart: SwapItemSku')
