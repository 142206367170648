//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import React, { FC, ReactElement } from 'react'

import { CarouselContainer } from '..'
import useMediaQuery from '../hooks/useMediaQuery'
import * as css from './DefaultBanner.module.scss'

export type DefaultBannerProps = {
  /** ReactNode to Render the  cartMiniBanner with iconRichText */
  readonly banner?: readonly ReactElement[]
  /** headerText for cartMiniBanner */
  readonly headerText?: string
  /** A boolean for show vertical banner **/
  readonly showVerticalBanner?: boolean
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const DefaultBanner: FC<DefaultBannerProps> = ({
  banner,
  headerText,
  showVerticalBanner,
  dataComponent = DefaultBanner.name
}: DefaultBannerProps) => {
  const isTabletUp = useMediaQuery('TabletAndUp')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const bannerClass = ifElse(
    equals(true),
    always(classNames([css.cartBannerSection], [css.defaultBanner])),
    always(classNames([css.cartBannerSection]))
  )(showVerticalBanner)
  return ifElse(
    equals(true),
    always(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <div className={bannerClass} data-component={dataComponent}>
        {!isNil(headerText) && (
          <h4 className={classNames(css.headerTxt)}>{headerText}</h4>
        )}
        {banner}
      </div>
    ),
    always(
      <div
        className={classNames([css.cartBannerSection])}
        data-component={dataComponent}
      >
        <CarouselContainer
          autoplay={true}
          enableDots={false}
          playButton={false}
          slides={banner}
        />
      </div>
    )
  )(isTabletUp)
}

export default DefaultBanner
