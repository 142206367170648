import { ChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import { none, some } from 'fp-ts/lib/Option'
import React, { HTMLAttributes, useState } from 'react'

import NavLink from './NavLink'
import { SimpleMenuProps } from './schema'

type NavDropdownProps = HTMLAttributes<HTMLDivElement> &
  SimpleMenuProps & {
    readonly hideLinkIcons?: boolean
  }

export default function NavDropdown({
  links,
  title,
  hideLinkIcons = false
}: NavDropdownProps) {
  const [showDropdown, setShowDropdown] = useState(false)
  const linksLength = links.length

  return (
    <div
      className="flex h-full flex-col lg:flex-row lg:items-center"
      onClick={() => setShowDropdown(prev => !prev)}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <button className="text-body-size hover:text-complementary-blue-100 lg:hover:text-primary-100 inline-flex h-full cursor-pointer items-center gap-1 border-none bg-transparent p-0 lg:text-white">
        {title}
        <ChevronDown
          className={classNames(
            'mt-1 h-4 w-4 transition-transform duration-200',
            {
              'rotate-180': showDropdown,
              'rotate-0': !showDropdown
            }
          )}
        />
      </button>
      <div
        className={classNames(
          'py-4 lg:absolute lg:left-0 lg:top-0 lg:z-[1] lg:mt-24 lg:w-full lg:bg-white lg:py-8 lg:shadow-md',
          {
            hidden: !showDropdown,
            'block lg:flex': showDropdown
          }
        )}
        data-component="DropdownMenu"
      >
        <div className="lg:max-w-8xl flex flex-col gap-4 lg:mx-auto lg:grid lg:h-44 lg:grid-flow-col lg:grid-cols-12 lg:gap-x-20">
          {links.map((link, index) => (
            <NavLink
              className={classNames(
                'text-neutral-black hover:text-complementary-blue-100 min-h-0',
                'lg:text-neutral-black lg:hover:text-complementary-blue-100',
                {
                  'lg:col-span-3 lg:row-span-2': index <= 1 && linksLength >= 4,
                  'lg:col-span-3 lg:row-span-1': index > 1 && linksLength >= 4,
                  'lg:col-span-4': linksLength < 4
                }
              )}
              key={index}
              {...link}
              hideIcon={hideLinkIcons}
              trackingLabel={
                hideLinkIcons ? some(`${title} - ${link.text}`) : none
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}
