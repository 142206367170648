import React, { FC } from 'react'

import { OutOfStockButtonSchema } from './schema'

const OutOfStockButton: FC<OutOfStockButtonSchema> = ({
  primaryText,
  secondaryText
}: OutOfStockButtonSchema) => (
  <div
    className="rounded-base border-neutral-light-50 w-full border-2 border-solid px-6 py-2 text-center"
    data-component="OutOfStockButton"
  >
    <p className="m-0 text-2xl font-bold">{primaryText}</p>
    {secondaryText ? (
      <p className="text-neutral-dark-100 mb-1 mt-0 text-sm">{secondaryText}</p>
    ) : null}
  </div>
)

export default OutOfStockButton
