import { TrackEvent } from '@ecomm/tracking/src/analytics'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { toItem } from './toItem'

export const toItemList =
  (lineItems: readonly LineItem[]) =>
  (
    trackEvent: TrackEvent,
    onQuantityChange?: (lineItemId: string, quantity: number) => void,
    onRemoveProduct?: (lineItem: LineItem) => void
  ) =>
    pipe(
      O.fromNullable(lineItems),
      O.chain(lineItems =>
        O.fromNullable(
          lineItems.map((item: LineItem) =>
            toItem(
              trackEvent,
              lineItems,
              onQuantityChange,
              onRemoveProduct
            )(item)
          )
        )
      ),
      O.fold(
        () => null,
        lineItems => lineItems
      )
    )
