import { getCartId } from '@ecomm/data-storage'
import { useEffect } from 'react'
import { useSetCart } from '../atoms/useCart'
import { useCartState } from '../getters/useCartState'

/**
 * If a new cart is created by redux after initial page load, jotai won't know
 * about the new cartId value in localStorage and can get stuck in a `no_cart_id`
 * state until a full page refresh. This hook updates jotai cart state to
 * `not_initialized` if it finds a cartId in localStorage.
 */
export const useHydrateCartState = () => {
  const cartId = getCartId()
  const cartState = useCartState()
  const setCart = useSetCart()

  useEffect(() => {
    cartState === 'no_cart_id' && !!cartId && setCart(['not_initialized'])
  }, [cartId])
}
