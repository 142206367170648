import { parseWithoutNulls, z } from '@simplisafe/ewok'
import type { Prettify } from 'ts-essentials'
import type { ZodSchema, ZodTypeDef } from 'zod'

/**
 * This type will check if the data has a `sys` object or a `contentful_id` property and return the correct type.
 *
 * It's pretty complex TypeScript, but it allows us to infer the correct type based on the input.
 *
 * https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
 */
type DataWithID<
  T extends
    | { readonly contentful_id: string }
    | { readonly sys: { readonly id: string } },
  SYS extends { readonly sys: { readonly id: string } } = {
    readonly sys: { readonly id: string }
  },
  CONTENTFUL extends { readonly contentful_id: string } = {
    readonly contentful_id: string
  }
> = T extends { readonly sys: { readonly id: string } }
  ? SYS
  : T extends { readonly contentful_id: string }
    ? CONTENTFUL
    : never

/**
 * Takes in an object with either `sys.id` or `contentful_id` property and
 * returns an object with `id` property instead.
 */
export function flattenId<T extends { readonly contentful_id: string }>(
  data: T
): Prettify<Omit<T, 'contentful_id'> & { readonly id: string }>
export function flattenId<T extends { readonly sys: { readonly id: string } }>(
  data: T
): Prettify<Omit<T, 'sys'> & { readonly id: string }>
export function flattenId<
  T extends {
    readonly sys?: { readonly id: string }
    readonly contentful_id?: string
  }
>(
  data: T
): Prettify<Omit<Omit<T, 'sys'>, 'contentful_id'> & { readonly id: string }>
export function flattenId<
  T extends
    | { readonly contentful_id: string }
    | { readonly sys: { readonly id: string } },
  U extends { readonly sys: { readonly id: string } },
  V extends { readonly contentful_id: string }
>(data: DataWithID<T, U, V>) {
  if ('contentful_id' in data) {
    const { contentful_id, ...rest } = data
    return { ...rest, id: contentful_id }
  } else {
    const { sys, ...rest } = data
    return { ...rest, id: sys.id }
  }
}

/**
 * Takes in a schema with either `sys.id` or `contentful_id` property, removes any null values, parses data,
 * and returns an object with `id` property instead.
 *
 * Schemas that you pass to this do not need to have anything marked as `nullish`, just mark the field as optional.
 * `null` inputs will just become undefined.
 */
export function parseAndFlattenId<
  T extends { readonly contentful_id: string },
  U
>(
  data: unknown,
  schema: ZodSchema<T, ZodTypeDef, U>
): Prettify<Omit<T, 'contentful_id'> & { readonly id: string }>
export function parseAndFlattenId<
  T extends { readonly sys: { readonly id: string } },
  U
>(
  data: unknown,
  schema: ZodSchema<T, ZodTypeDef, U>
): Prettify<Omit<T, 'sys'> & { readonly id: string }>
export function parseAndFlattenId<
  T extends {
    readonly sys?: { readonly id: string }
    readonly contentful_id?: string
  },
  U
>(
  data: unknown,
  schema: ZodSchema<T, ZodTypeDef, U>
): Prettify<Omit<Omit<T, 'sys'>, 'contentful_id'> & { readonly id: string }>
export function parseAndFlattenId<
  T extends
    | { readonly contentful_id: string }
    | { readonly sys: { readonly id: string } },
  U extends { readonly sys: { readonly id: string } },
  V extends { readonly contentful_id: string }
>(data: unknown, schema: ZodSchema<T, ZodTypeDef, DataWithID<T, U, V>>) {
  const result = parseWithoutNulls(schema, data)
  return flattenId(result)
}

/**
 * You can use this to add an ID that might come from Gatsby or Apollo.
 *
 * @example
 * const schema = z
 *  .object({
 *     foo: z.string(),
 *    bar: z.string().optional()
 *   })
 *   .and(idSchema)

 */
export const idSchema = z
  .object({ contentful_id: z.string() })
  .or(z.object({ sys: z.object({ id: z.string() }) }))

/**
 * Contentful stores an entries ID inside of a `sys` object. This schema will transform sys.id into id and verify that it is a string.
 * @deprecated - we want to flatten sys.id to just always be id.
 */
export const id = z
  .object({
    sys: z.object({
      id: z.string()
    })
  })
  .transform(({ sys, ...rest }) => ({ id: sys.id, ...rest }))
