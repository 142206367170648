import { useLocale } from '@ecomm/data-hooks'
import type { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'
import { useMemo } from 'react'

const leadSourceI18N: Record<Locale, string> = {
  'en-GB': 'uk_promo_banner',
  'en-US': 'us_promo_banner'
}

const leadSourceNewPromoBanner: Record<Locale, string> = {
  'en-GB': 'uk_new_promo_banner',
  'en-US': 'us_new_promo_banner'
}

export const useGetPromoLeadSource = () => {
  const locale = useLocale()
  const deviceType = getDeviceType().toLowerCase()
  const isMobile = deviceType === 'mobile'

  return useMemo(
    () =>
      isMobile
        ? `${leadSourceNewPromoBanner[locale]}_${deviceType}`
        : `${leadSourceI18N[locale]}_${deviceType}`,
    [isMobile, locale, deviceType]
  )
}
