import { useDesktopMiddleLayout } from '@ecomm/promotions-hooks'

import { MiddleLayoutDefault } from './MiddleLayoutDefault'
import { MiddleLayoutNoGift } from './MiddleLayoutNoGift'

export function MiddleLayout() {
  const layout = useDesktopMiddleLayout()
  switch (layout) {
    case 'noGift':
      return <MiddleLayoutNoGift />
    default:
      return <MiddleLayoutDefault />
  }
}
