import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { useTrackingShippingFormUserErrors } from '@ecomm/tracking'
import { useFormikContext } from 'formik'

import { SubmitButton } from '../SubmitButton'

export type SubmissionProps = {
  readonly disableSubmit: boolean
  readonly showErrorMessage: boolean
  readonly onClick: () => void
}

export function Submission({
  disableSubmit,
  showErrorMessage,
  onClick
}: SubmissionProps) {
  const { errors } = useFormikContext<CheckoutFormValuesType>()
  const trackUserErrors = useTrackingShippingFormUserErrors()

  const handleClick = () => {
    onClick()
    Object.keys(errors).length !== 0 && trackUserErrors(errors)
  }

  return (
    <div className="mt-5" data-component="CheckoutFormSubmission">
      <div className="flex flex-col-reverse md:flex-row md:items-center md:justify-end md:gap-5">
        <SubmitButton
          disableSubmit={disableSubmit}
          onClick={handleClick}
          spinner
          text="Continue to payment"
        />
      </div>
      <div className="mt-5">
        {showErrorMessage ? (
          <p className="text:left text-sale text-base font-medium md:text-right">
            Please review your form for any missing fields.
          </p>
        ) : null}
      </div>
    </div>
  )
}
