import { useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'

import { setItemQtyAtom } from '../../atoms/draftCart/itemQuantityAtom'
import { useCustomPackageQuery } from './useCustomPackageQuery'

/**
 * @description Pre-Populates sensor QTYs based on the provided URL query param
 * sets the QTY from setItemQtyAtom in atoms/drafCart
 */
export function useDecodeProductsFromHash() {
  const [qtyUpdated, setQtyUpdated] = useState(false)
  const updateQty = useSetAtom(setItemQtyAtom)

  const { customPackage, customPackageHash } = useCustomPackageQuery()

  const products = Object.entries(customPackage).map(([sku, quantity]) => ({
    quantity,
    sku
  }))

  useEffect(() => {
    customPackageHash &&
      !qtyUpdated &&
      products.forEach(prod => updateQty(prod.sku, prod.quantity))
    setQtyUpdated(true)
  }, [customPackageHash, products, updateQty, qtyUpdated])
}
