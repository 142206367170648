import { useContentfulFaqData } from '@ecomm/bms/hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'

import { SensorCollapsibleSection } from '../SensorCollapsibleSection'

type SensorFAQsProps = {
  readonly faqContentfulId: string
}

export function SensorFAQs({ faqContentfulId }: SensorFAQsProps) {
  const microCopy = useMicroCopy()
  const faqs = useContentfulFaqData(faqContentfulId)

  return (
    <SensorCollapsibleSection title={microCopy['faq-title-short']}>
      {faqs.map(({ question, answer }, idx) => (
        <SensorCollapsibleSection
          key={`${idx}-${question}`}
          title={question}
          withBackground
        >
          {answer.raw}
        </SensorCollapsibleSection>
      ))}
    </SensorCollapsibleSection>
  )
}
