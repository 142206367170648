import equals from 'ramda/src/equals'
import when from 'ramda/src/when'
import { RefObject, useEffect, useRef } from 'react'

const TABBABLE_ELEMS =
  'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), area[href], form'

const useFocusTrap = <T extends HTMLElement>(
  ref: RefObject<T>,
  isActive = true // isActive state of target pop up
) => {
  const lastFocusedElem = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const target = <T>ref.current

    // all focusable elements for the given target
    const focusableElems = target ? target.querySelectorAll(TABBABLE_ELEMS) : []
    // number of focusable elements on the target
    const numFocusableElems = focusableElems.length

    // elements on the target
    const firstElement = <HTMLElement>focusableElems[0]
    const lastElement = <HTMLElement>focusableElems[numFocusableElems - 1]

    const handleTab = (event: KeyboardEvent) => {
      when(equals('Tab'), () => {
        const focusedElement = <HTMLElement>document.activeElement

        const focusEvent = (elem: HTMLElement) => {
          elem.focus()
          return event.preventDefault()
        }

        const shouldFocusFirst =
          !event.shiftKey && focusedElement === lastElement
        const shouldFocusLast =
          event.shiftKey && focusedElement === firstElement

        shouldFocusFirst && focusEvent(firstElement)
        shouldFocusLast && focusEvent(lastElement)
      })(event.key)
    }

    when(equals(true), () => {
      // setting the last focused element
      lastFocusedElem.current = <HTMLElement>document.activeElement
      // blur the last focused element
      lastFocusedElem.current.blur()

      target.addEventListener('keydown', handleTab)
    })(isActive)

    return () => {
      lastFocusedElem.current && lastFocusedElem.current.focus()
      target ? target.removeEventListener('keydown', handleTab) : null
    }
  }, [isActive, ref])
}

export default useFocusTrap
