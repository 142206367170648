import { iconTypes } from '@ecomm/shared-icons'
import { z } from 'zod'

import { Locale } from '../../hooks/useLocalizedData'

export const headlineSchema = z
  .object({
    raw: z.string()
  })
  .optional()

export const listItemSchema = z.object({
  icon: z.enum(iconTypes),
  headline: z.string(),
  mobileHeadline: z.string().optional(),
  body: z.string().optional(),
  disclaimer: z.string().optional(),
  showBodyInMobile: z.boolean().optional()
})

export const schema = {
  [Locale.EnGB]: z.object({
    headline: headlineSchema,
    listItems: z.array(listItemSchema)
  }),
  [Locale.EnUS]: z.object({
    headline: headlineSchema,
    listItems: z.array(listItemSchema)
  })
}
