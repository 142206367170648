import { useGetPromoRelativeEndText } from '@ecomm/promotions-hooks'
import React from 'react'

export type RelativeEndTimeProps = {
  readonly endDate: Date
  readonly displayEndsText?: boolean
}

export function RelativeEndTime({
  endDate,
  displayEndsText = false
}: RelativeEndTimeProps) {
  const relativeEndText = useGetPromoRelativeEndText(endDate)

  return (
    <span data-component="RelativeEndTime">
      {displayEndsText ? 'ends ' : null}
      {relativeEndText}
    </span>
  )
}
