import type { CommercetoolsProduct } from '@ecomm/data-simplisafe-api'
import * as O from 'fp-ts/lib/Option'
import { Map as ImmutableMap } from 'immutable'
import { useAtom } from 'jotai'
import { SetStateAction } from 'jotai/vanilla'

import { ContentfulProductTopic } from './productTopicSchema'
import { productsAtom } from './productsAtom'

/**
 * @deprecated You should query for product information directly with graphql and create a template specific atom.
 */
export const useProducts = (): readonly [
  ImmutableMap<
    string,
    CommercetoolsProduct & { readonly topic: O.Option<ContentfulProductTopic> }
  >,
  (
    update: SetStateAction<
      ImmutableMap<
        string,
        CommercetoolsProduct & {
          readonly topic: O.Option<ContentfulProductTopic>
        }
      >
    >
  ) => void
] => {
  const [products, setProducts] = useAtom(productsAtom)
  return [products, setProducts]
}
