import {
  useCurrentPromoBannerData,
  useSitewidePromoEndDateDisplay
} from '@ecomm/promotions-hooks'
import classNames from 'classnames'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { RelativeEndTime } from '../../RelativeEndTime'

export function SaleEndsTitle() {
  const { endDate, hasEndDateCallout } = useSitewidePromoEndDateDisplay()

  const { hasSaleName, saleNameLabel, saleName } = useCurrentPromoBannerData()
  const saleNameText = hasSaleName && saleName ? saleName : ''
  const separator = hasSaleName && saleName && hasEndDateCallout ? '  |  ' : ''

  const saleText = [saleNameText, saleNameLabel].filter(Boolean).join(' ')

  const endDateDisplay = pipe(
    O.of(endDate),
    O.filter(() => hasEndDateCallout),
    // Convert the string to an actual Date instance we can execute logic on.
    O.map(dateString => new Date(dateString))
  )

  return pipe(
    endDateDisplay,
    O.match(
      () => <span>{saleText}</span>,
      dateInstance => (
        <div>
          {saleText}
          <span className={classNames({ 'hidden md:inline': saleNameLabel })}>
            {separator}
          </span>
          <span className={classNames({ 'block md:inline': saleNameLabel })}>
            ENDS <RelativeEndTime endDate={dateInstance} />
          </span>
        </div>
      )
    )
  )
}
