//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import * as css from './CardItemSmallBanner.module.scss'
export type CardItemSmallBannerProps = {
  /** Child node - gatsby-image from frontend */
  readonly bannerImage: ReactNode
  /** The content of the component */
  readonly productDescriptionContent?: ReactNode
  /** The content of the component */
  readonly productHighlightsContent?: ReactNode
  /** CTA button */
  readonly button?: ReactNode
  readonly title: string
  readonly price: ReactNode
  /** The content of the component */
  readonly additionalInfoContent?: ReactNode
  readonly dataComponent?: string
  /** The content of the component */
  readonly offerTagContent?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const CardItemSmallBanner: FC<CardItemSmallBannerProps> = ({
  bannerImage,
  productDescriptionContent,
  productHighlightsContent,
  button,
  title,
  price,
  offerTagContent,
  additionalInfoContent,
  dataComponent = CardItemSmallBanner.name
}: CardItemSmallBannerProps) => {
  return (
    <div className={classNames(css.buildSystem)} data-component={dataComponent}>
      <div className={classNames('rounded-base overflow-hidden', css.category)}>
        <div className={classNames(css.cImage)}>
          {bannerImage}
          {offerTagContent}
        </div>
        <div className={classNames(css.gridItems)}>
          <div className={classNames(css.categoryContent)}>
            <h3 className={classNames(css.titleText)}>{title}</h3>
            {productDescriptionContent}
            <div className={classNames(css.noteText)}>
              {productHighlightsContent}
            </div>
            <div className={classNames(css.btmSection)}>
              <div className={classNames(css.btnCol1)}>
                <span className={classNames('fontSize18')}>{price}</span>
                {additionalInfoContent ? (
                  <span className={classNames(css.additionalInfo)}>
                    {additionalInfoContent}
                  </span>
                ) : null}
              </div>
              <div className={classNames(css.btnCol2)}>{button}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardItemSmallBanner
