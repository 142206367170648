//@ts-nocheck
import classNames from 'classnames'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import T from 'ramda/src/T'
import React, { FC, ReactNode, useContext, useEffect, useMemo } from 'react'
import { throttle } from 'throttle-debounce'

import { ChevronUpXs } from '../icons'
import HeaderContext from './context'
import * as css from './Header.module.scss'

export type HeaderDropdownProps = {
  /** Label to display for the dropdown. This is what you hover over or click on to expand the dropdown content. */
  readonly label: string
  /** The content of the dropdown when expanded. */
  readonly children: ReactNode
  /** A unique identifier for the dropdown. */
  readonly id: string
}

/** @deprecated Do not use ss-react-components*/
const HeaderDropdown: FC<HeaderDropdownProps> = ({
  children,
  id,
  label
}: HeaderDropdownProps) => {
  const { activeDropdown, setActiveDropdown } = useContext(HeaderContext)

  useEffect(() => {
    const onDocumentKeydown = (event: KeyboardEvent) => {
      event.key === 'Escape' && setActiveDropdown('')
    }

    document.addEventListener('keydown', onDocumentKeydown, false)
    return () => {
      document.removeEventListener('keydown', onDocumentKeydown, false)
    }
  }, [setActiveDropdown])

  /**
   * We want to make sure if a user hovers over a dropdown and clicks right away we don't quickly open and close it.
   * We want to allow clicks to toggle to support touch screen tapping and screen readers.
   */
  const throttledSetActiveDropdown = useMemo(() => {
    return throttle(650, setActiveDropdown, true)
  }, [setActiveDropdown])

  const handleMouseOver = () => {
    ifElse(equals(activeDropdown), T, throttledSetActiveDropdown)(id)
  }

  const handleMouseLeave = () => {
    ifElse(equals(activeDropdown), () => throttledSetActiveDropdown(''), T)(id)
  }

  const handleClickToggle = () => {
    ifElse(
      equals(activeDropdown),
      () => throttledSetActiveDropdown(''),
      throttledSetActiveDropdown
    )(id)
  }

  return (
    <nav
      aria-labelledby={`button-${id}`}
      className={classNames('lg:mx-3', {
        'after:absolute after:left-0 after:block after:h-16 after:w-full lg:after:content-[""]':
          activeDropdown === id
      })}
      data-jest-id="nav"
      data-jest-state={activeDropdown === id}
      id={id}
      key={id}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
    >
      <button
        aria-controls={activeDropdown ? `menu-${id}` : undefined}
        aria-expanded={activeDropdown === id}
        className={css.dropdownButton}
        data-jest-id="label"
        id={`button-${id}`}
        onClick={handleClickToggle}
      >
        {label}
        <ChevronUpXs
          className={classNames(
            'ml-2 transition-transform duration-200 ease-in-out',
            {
              'rotate-0': activeDropdown === id,
              'rotate-180': activeDropdown !== id
            }
          )}
        />
      </button>

      {activeDropdown === id && (
        <div
          className={classNames(
            'text-neutral-black bg-white',
            css.dropdownContent
          )}
          data-jest="content"
          id={`menu-${id}`}
        >
          <div className={css.dropdownContentInner}>{children}</div>
        </div>
      )}
    </nav>
  )
}

export default HeaderDropdown
