import { affirmBannerSchema } from '@ecomm/shared-components'
import { addVariationSchema } from '@ecomm/utils'
import { TypeOf, z } from '@simplisafe/ewok'

import { layoutSchema } from '../../assemblies/Layout/layoutSchema'
import authenticationSchema from '../../components/Authentication/schema'
import { draftCartSchema } from '../../components/DraftCart/schema'
import { saveMySystemModalSchema } from '../../components/SaveMySystemModal/schema'
import { sensorGroupSchema } from '../../components/SensorGroup/schema'
import { systemHeroTextSchema } from '../../components/SystemHero/schema'
import { systemHeroItemSchema } from '../../components/SystemHeroItem/schema'

const bmsPageSchema = z.object({
  __typename: z.literal('ContentfulBmsPage'),
  affirmBanner: affirmBannerSchema,
  cartSummary: draftCartSchema,
  contentful_id: z.string(),
  heroItems: z.array(systemHeroItemSchema),
  heroText: z.optional(systemHeroTextSchema).default({}),
  layout: addVariationSchema(layoutSchema),
  modal: saveMySystemModalSchema.nullish(),
  ready: z.boolean().default(true),
  sensorGroups: z.array(addVariationSchema(sensorGroupSchema)),
  slug: z.string(),
  title: z.string(),
  breadcrumbTitle: z.string().optional(),
  authentication: authenticationSchema.nullish()
})

export type BmsPageSchema = TypeOf<typeof bmsPageSchema>

export default bmsPageSchema
