import { useLocale } from '@ecomm/data-hooks'
import { capitalizeFirst, useMicroCopy } from '@ecomm/micro-copy'
import { ChevronDown } from '@ecomm/shared-icons'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import {
  useTrackingBmsCollapseSection,
  useTrackingBmsExpandSection,
  useTrackingBmsNextSection
} from '@ecomm/tracking'
import { window } from 'browser-monads-ts'
import classNames from 'classnames'
import { useAtom } from 'jotai'
import { MouseEvent, useEffect, useRef } from 'react'

import { totalPriceAtom } from '../../atoms/draftCart/totalPriceAtom'
import { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import { AffirmExperienceMonthlyPricingBanner } from './AffirmExperienceMonthlyPricingBanner'
import AffirmProductCardListWrapper from './AffirmProductCardListWrapper'
import ProductCardsList from './ProductCardsList'
import type { SensorGroupFragment } from './schema'
import { createId } from './utils'

type Props = SensorGroupFragment & {
  readonly currentGroup: string
  readonly nextGroup: string
  readonly setCurrentGroup?:
    | ((Args0: string, Args1: void, ...Args2: readonly unknown[]) => unknown)
    | null
    | undefined
}

function SensorGroup(sensorProps: Props) {
  const {
    currentGroup,
    image,
    items,
    nextGroup,
    setCurrentGroup = null,
    title
  } = sensorProps
  const locale = useLocale()

  // Retrieve the total price of the draft cart for the Affirm promo messages
  const [cartPrices] = useAtom(totalPriceAtom)
  const [discountedPrice, total] = Object.values(cartPrices)
  const affirmClient = window.affirm

  // Tracking the Sensor Groups
  const trackNextSensorGroup = useTrackingBmsNextSection(nextGroup)
  const trackExpandSensorGroup = useTrackingBmsExpandSection(title)
  const trackCollapseSensorGroup = useTrackingBmsCollapseSection(title)

  const isUs = useLocale() === 'en-US'
  const isCurrentGroup = title === currentGroup

  const handleToggle = (title: string) => (e: MouseEvent) => {
    e.stopPropagation()

    !isCurrentGroup && trackExpandSensorGroup()
    isCurrentGroup && trackCollapseSensorGroup()

    setCurrentGroup && setCurrentGroup(title === currentGroup ? '' : title)
  }

  const handleClick = (title: string) => (e: MouseEvent) => {
    e.stopPropagation()

    trackNextSensorGroup()

    setCurrentGroup && setCurrentGroup(title === currentGroup ? '' : title)
  }

  //Needed to scroll item into view.
  const groupRef = useRef<HTMLElement>(null)

  //If current group changes, scroll it into view.
  useEffect(() => {
    const pageHasScrolled = window.scrollY !== 0

    //Only runs if the page has been scrolled to avoid scrolling on first load.
    isCurrentGroup &&
      pageHasScrolled &&
      groupRef.current !== null &&
      groupRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [currentGroup])

  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()

  const microCopy = useMicroCopy()

  return (
    <section
      className={classNames(
        'border-neutral-medium-100 md:rounded-base md:bg-neutral-light-50 -mx-4 mt-6 border-0 border-t border-solid px-4 py-6 last-of-type:border-b md:mx-0 md:mt-10 md:border-0 md:px-10 md:py-8 md:last-of-type:border-b-0',
        { 'bg-neutral-light-50': isCurrentGroup }
      )}
      data-testid={`${createId(title)}_group`}
      id={createId(title)}
      ref={groupRef}
    >
      <header
        className="flex items-center justify-between md:cursor-default"
        onClick={handleToggle(title)}
      >
        <div className="flex items-center gap-3 md:gap-9">
          {image ? (
            <img
              alt="presentation"
              className="h-5 w-5 object-contain md:h-8 md:w-8"
              src={image.file.url}
            />
          ) : null}
          {title ? (
            <h2 className="mb-0 mt-0 text-base md:text-3xl">{title}</h2>
          ) : null}
        </div>
        <div className="flex gap-14">
          <span>{items.length} products</span>
          <button
            aria-controls={isCurrentGroup ? `${createId(title)}_content` : ''}
            aria-expanded={isCurrentGroup}
            className="flex border-0 bg-transparent p-0"
            data-component="toggle"
            onClick={handleToggle(title)}
          >
            <ChevronDown
              className={classNames('w-8 h-8 text-neutral-dark', {
                '-scale-y-100': isCurrentGroup
              })}
              titleA11y={
                isCurrentGroup
                  ? microCopy['a11y-close-section'](title)
                  : microCopy['a11y-open-section'](title)
              }
            />
          </button>
        </div>
      </header>
      <div
        className={!isCurrentGroup ? 'hidden' : 'block'}
        data-testid={`${createId(title)}_content`}
        id={`${createId(title)}_content`}
      >
        {locale === 'en-US' ? (
          <Experience
            {...affirmFeatureFlagQueryData}
            component={AffirmProductCardListWrapper}
            experiences={mapExperiences<AffirmFeatureFlagSchema>(
              affirmFeatureFlagQueryData.nt_experiences
            )}
            passthroughProps={{
              isCurrent: isCurrentGroup,
              items: items
            }}
          />
        ) : (
          <ProductCardsList
            isAffirmExperience={false}
            isCurrent={isCurrentGroup}
            items={items}
          />
        )}
        <footer
          className={classNames(
            'border-t-neutral-medium-100 mt-10 flex flex-col items-center border-0 border-t border-solid pt-6 md:flex-row md:gap-6',
            isUs ? 'justify-between' : 'justify-end'
          )}
        >
          {isUs ? (
            <div className="justify-center md:justify-start">
              <Experience
                {...affirmFeatureFlagQueryData}
                component={AffirmExperienceMonthlyPricingBanner}
                experiences={mapExperiences<AffirmFeatureFlagSchema>(
                  affirmFeatureFlagQueryData.nt_experiences
                )}
                passthroughProps={{
                  affirmClient: affirmClient,
                  className: 'justify-start',
                  discountedPrice:
                    (discountedPrice && discountedPrice * 100) ||
                    (total && total * 100),
                  isBms: true,
                  mappedExperiences: affirmFeatureFlagQueryData.nt_experiences
                }}
              />
            </div>
          ) : null}
          {nextGroup ? (
            <a
              className="btn btn-outlined btn-outlined-primary min-h-[3rem]"
              data-testid={`btn-next-group-${nextGroup}`}
              href={`#${createId(nextGroup)}`}
              onClick={handleClick(nextGroup)}
            >
              {`${capitalizeFirst(microCopy.next)}: ${nextGroup}`}
            </a>
          ) : null}
        </footer>
      </div>
    </section>
  )
}

export default SensorGroup
