import { SSButton, SSInput, Text } from '@ecomm/ss-react-components'
import { useFormik } from 'formik'
import React, { ReactNode } from 'react'
import { useTracking } from 'react-tracking'
import { object, string } from 'yup'

import { usOptionalPhoneSchema } from './schema'

type WizardSubmitProps = {
  readonly buttonProps?: Partial<React.ComponentProps<typeof SSButton>>
  readonly hideLabel?: boolean
  readonly id: string
  readonly label: ReactNode
  readonly labelClass?: string
  readonly legalText?: ReactNode
  readonly message?: ReactNode
  readonly onSubmit: (x: Record<string, unknown>) => void
  readonly onNextStep?: (response: {
    readonly value: string
    readonly id: string
  }) => () => void
  /** Placeholder text for input field */
  readonly emailInputPlaceholder?: string
  readonly response?: Record<string, unknown>
  readonly submitButtonLabel: string
  readonly defaultEmail?: string
  readonly defaultPhone?: string
  readonly emailErrorMessage?: string
  readonly phoneInputPlaceholder?: string
  readonly phoneErrorMessage?: string
  readonly phoneLabel?: ReactNode
}

const schema = object().shape({
  email: string()
    .email('Please, add a valid Email.')
    .strict()
    .required('The field Email is required.'),
  phoneNumber: usOptionalPhoneSchema
})

export function WizardSubmit(props: WizardSubmitProps) {
  const {
    buttonProps,
    hideLabel = true,
    id,
    message,
    onSubmit,
    onNextStep,
    emailInputPlaceholder,
    submitButtonLabel,
    legalText,
    label,
    labelClass,
    response,
    defaultEmail = '',
    defaultPhone = '',
    emailErrorMessage,
    phoneErrorMessage = 'Valid phone number required',
    phoneInputPlaceholder,
    phoneLabel = 'Phone Number'
  } = props

  const { Track, trackEvent } = useTracking({ appSection: 'wizardEmailSubmit' })
  const formik = useFormik({
    initialValues: {
      email: defaultEmail,
      phoneNumber: defaultPhone
    },
    onSubmit: values => {
      onSubmit({
        [id]: values.email,
        phone: values.phoneNumber,
        ...response
      })

      trackEvent({ event: 'submit' })
      onNextStep &&
        onNextStep({
          id: 'submitted',
          value: 'true'
        })()
    },
    validationSchema: schema
  })

  return onNextStep ? (
    <Track>
      <div className="mx-auto mt-6 max-w-lg">
        {message ? <div>{message}</div> : null}
        <div className="flex">
          <div className="w-full">
            <SSInput
              className="h-14 max-w-none"
              error={!!(formik.errors.email && formik.touched.email)}
              errorMsg={
                formik.errors.email && formik.touched.email
                  ? emailErrorMessage
                  : ''
              }
              hideLabel={hideLabel}
              id="email"
              label={label}
              labelClass={labelClass}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={emailInputPlaceholder}
              type="email"
              value={formik.values.email}
            />
            <div className="w-full" style={{ marginTop: '20px' }}>
              <SSInput
                className="mt-[20px] h-14 max-w-none"
                error={
                  !!(formik.errors.phoneNumber && formik.touched.phoneNumber)
                }
                errorMsg={
                  formik.errors.phoneNumber && formik.touched.phoneNumber
                    ? phoneErrorMessage
                    : undefined
                }
                hideLabel={hideLabel}
                id="phoneNumber"
                label={phoneLabel}
                labelClass={labelClass}
                name="phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder={phoneInputPlaceholder}
                type="tel"
                value={formik.values.phoneNumber}
              />
            </div>
            <div className="flex justify-center" style={{ marginTop: '20px' }}>
              <SSButton
                disabled={!!(formik.errors.email || formik.errors.phoneNumber)}
                onClick={e => {
                  formik.handleSubmit()
                  e.preventDefault()
                }}
                type="submit"
                {...buttonProps}
              >
                {submitButtonLabel}
              </SSButton>
            </div>
            <div className="flex justify-center" style={{ marginTop: '20px' }}>
              <Text
                className="prose-a:m-0 prose-a:!text-xs prose-p:text-xs mt-5"
                useTailwind
              >
                {legalText}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </Track>
  ) : null
}
