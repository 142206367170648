import classNames from 'classnames'
import React, { ReactNode } from 'react'

type ChooseMonitoringBoxProps = {
  readonly content: ReactNode
  readonly buttonText: string
  readonly hasMonitoring?: boolean
  readonly onButtonClick: () => void
}

export function ChooseMonitoringBox({
  buttonText,
  content,
  hasMonitoring = true,
  onButtonClick
}: ChooseMonitoringBoxProps) {
  return (
    <div
      className={classNames(
        'border-neutral-medium-100 mb-4 flex flex-col rounded-xl border border-solid p-6 shadow-md sm:text-xs md:order-2 md:flex-row md:justify-end md:text-sm',
        { 'bg-white ': hasMonitoring },
        { 'bg-neutral-light-50': !hasMonitoring }
      )}
      data-component="ChooseMonitoringBox"
    >
      <div className="w-full">{content}</div>
      <div className="mt-4 flex items-end justify-end md:m-0">
        <button
          className="text-complementary-blue-100 cursor-pointer whitespace-nowrap border-none bg-transparent font-medium"
          onClick={onButtonClick}
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}

export default ChooseMonitoringBox
