import { TypeOf, z } from '@simplisafe/ewok'

export const paymentFormWrapperSchema = z.object({
  __typename: z.literal('ContentfulPaymentForm'),
  id: z.string().optional(),
  navigationLinkText: z.string().optional(),
  backButtonUrl: z.string().optional(),
  title: z.string().optional(),
  creditCardCheckoutOptionTitle: z.string().optional(),
  creditCardCheckoutOptionTitleWithSavedCc: z.string().optional(),
  affirmEnabled: z.boolean().optional(),
  affirmCheckoutOptionTitle: z.string().optional(),
  cardVerificationNote: z.object({ raw: z.string().optional() }).nullish()
})

export type PaymentFormWrapperFragment = TypeOf<typeof paymentFormWrapperSchema>
