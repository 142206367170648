/**
 * These are query params that can be used to adjust the behavior of a page for development.
 */

type DevParams = {
  /** Disables country redirect modal, quote wizard popup, and chat popup */
  readonly hidePopUps: 'xpop'
}

export const devParams: DevParams = { hidePopUps: 'xpop' }

type Params = {
  /** This is a promo code that should be applied if present. */
  readonly promoCode: 'utm_code'
  /** Payment form error message */
  readonly error: 'error'
  /** utm content param */
  readonly content: 'utm_content'
  /** Optimizely experiment key */
  readonly experimentKey: 'experiment'
  /** Optimizely variation key */
  readonly variationKey: 'variation'
  /** awn query param */
  readonly awc: 'awc'
  /** Campaign aligning to customerGroup/partnerName combo */
  readonly campaign: 'campaign'
}

export const params: Params = {
  awc: 'awc',
  campaign: 'campaign',
  content: 'utm_content',
  error: 'error',
  experimentKey: 'experiment',
  promoCode: 'utm_code',
  variationKey: 'variation'
}
