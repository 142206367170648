import React from 'react'

type Elements = JSX.Element | string

export function replaceTags(input: string) {
  const regex = /(SUP\((.*?)\))|(SUB\((.*?)\))/g

  const parts = input
    .split(regex)
    .reduce((acc: readonly Elements[], part, index) => {
      if (index % 5 === 0 && part) {
        return [...acc, part]
      } else if (index % 5 === 2 && part) {
        return [...acc, <sup key={`sup_${index}`}>{part}</sup>]
      } else if (index % 5 === 4 && part) {
        return [...acc, <sub key={`sub_${index}`}>{part}</sub>]
      } else {
        return acc
      }
    }, [])

  return parts
}
