import { useLocale } from '@ecomm/data-hooks'
import { useAtom } from 'jotai'

import microCopyAtom from './microCopyAtom'

// note: at some point in the future we might build this with contentful data, but for now it's just JS objects.

/**
 * A hook that uses a readonly Jotai atom to return an object with key value pairs of micro-copy.
 *
 * @example
 *
 * const { color } = useMicroCopy()
 *
 * return <p>Choose a {color}</p> // => Choose a colour
 */
const useMicroCopy = () => {
  const locale = useLocale()
  const [microCopy] = useAtom(microCopyAtom)
  return microCopy[locale]
}

export default useMicroCopy
