import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import classNames from 'classnames'
import React from 'react'

import { ReviewFragment } from './schema'

export type CompanyLogosProps = {
  readonly logos: readonly Pick<ReviewFragment, 'company' | 'logo'>[]
  readonly activeReviewIndex?: number
  readonly setHoveredReviewIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >
  readonly setActiveReviewIndex: React.Dispatch<React.SetStateAction<number>>
}

const renderCompanyLogo = (
  company: string,
  logoImage?: GatsbyImageSchema | null
) =>
  logoImage ? (
    <GatsbyImage className="h-[50px]" image={logoImage} objectFit="contain" />
  ) : (
    <span className="text-neutral-dark-100 text-center font-bold">
      {company}
    </span>
  )

function CompanyLogos({
  logos,
  activeReviewIndex,
  setHoveredReviewIndex,
  setActiveReviewIndex
}: CompanyLogosProps) {
  const handleMouseEnter = (index: number) => {
    setHoveredReviewIndex(index)
    return setActiveReviewIndex(index)
  }
  const handleMouseLeave = () => setHoveredReviewIndex(null)

  return (
    <div className="flex-center flex justify-around">
      <ul className="m-0 grid w-full grid-cols-2 items-center justify-center p-0 md:flex">
        {logos.map((logo, index) => {
          const { company, logo: logoImage } = logo

          return (
            <li
              className={classNames(
                'cursor-pointer list-none py-8 text-center first:pt-0 hover:opacity-100 md:pl-4 md:first:py-0 md:first:pl-0',
                {
                  'opacity-100': activeReviewIndex === index,
                  'opacity-50': activeReviewIndex !== index
                }
              )}
              key={logoImage?.title || company}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              {renderCompanyLogo(company, logoImage)}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default CompanyLogos
