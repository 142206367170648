import { gql } from '@apollo/client/index.js'
export const BLOG_ARTICLE_QUERY = gql`
  query BLOG_ARTICLE_QUERY($preview: Boolean!, $locale: String!, $slug: String!, $site: [String!]) {
    blogCollection(
      preview: $preview
      locale: $locale
      where: { site_contains_all: $site, slug: $slug}
      limit: 1
    ) {
      items {
        sys {
          id
        }
        title
        publishDate
        slug
        content {
          json
          links {
            entries {
              block {
                sys {
                  id
                }
                ... on YoutubeVideo {
                  title
                  link
                }
              }
            }
            assets {
              block {
                sys {
                  id
                }
                ...image
              }
            }
          }
        }
        image {
          ...image
        }
      }
    }
  }
`
