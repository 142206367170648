//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import AccordionLinks, { AccordionLinksProps } from '../AccordionLinks'
import * as css from './FooterLinksGroup.module.scss'

export type FooterLinksGroupProps = {
  readonly className?: string
  readonly footerLinkList?: readonly AccordionLinksProps[]
  readonly onClick?: (menuItem: unknown) => void
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const FooterLinksGroup: FC<FooterLinksGroupProps> = ({
  className,
  footerLinkList = [],
  onClick,
  dataComponent = FooterLinksGroup.name
}: FooterLinksGroupProps) => {
  const colClass = classNames(css.col)
  const linksGroup = footerLinkList.map((data: AccordionLinksProps) => {
    return (
      <div className={colClass} key={data.title}>
        <AccordionLinks {...data} onClick={onClick} />
      </div>
    )
  })
  return (
    <nav
      className={classNames(css.row, className)}
      data-component={dataComponent}
    >
      {linksGroup}
    </nav>
  )
}

export default FooterLinksGroup
