import { useTrackingFaqExpand } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useOnFaqExpand() {
  const handleTrackingFaqExpand = useTrackingFaqExpand()

  return useCallback(
    (accordionIndex: number, isOpen: boolean) => {
      isOpen && handleTrackingFaqExpand(accordionIndex)
    },
    [handleTrackingFaqExpand]
  )
}
