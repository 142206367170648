import {
  MonitoringPlan,
  MonitoringPlanType,
  PriceToggleSchema,
  ProductFragment
} from './types'

// Ensures that draft cart items includes products that are not in dependentProducts
export const validateCart = (
  skus: readonly string[],
  dependentProducts: readonly ProductFragment[]
) => skus.some(sku => !dependentProducts.some(el => el.sku === sku))

// Determines which monitoring plan type is associated with the corresponding monitoring plan.
// If both the priceToggle and the given monitoring sku are null, the corresponding monitoring
// plan type is returned instead of falling back to "none", in order to account for ss2.
export const getMonitoringPlanType = (
  monitoringPlanSku: string,
  priceToggles: readonly PriceToggleSchema[]
): MonitoringPlanType => {
  return (
    priceToggles.find(pt => (pt.sku || null) === (monitoringPlanSku || null))
      ?.type || MonitoringPlan.none
  )
}

export const hasMonitoringPlan = (type: MonitoringPlanType) => {
  return type !== 'none' && type !== 'ss2'
}
