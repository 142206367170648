import { useFragmentWithVariations } from '@ecomm/optimizely-hooks'

import { layoutNoHeaderSchema } from '../../assemblies/Layout/layoutSchema'
import { sensorPageSchema } from './sensorPageSchema'

export const useSensorPageFragment = <
  T extends { readonly contentfulProductLandingPage: U },
  U
>(
  query: T
) => {
  const sensorPageFragment = query.contentfulProductLandingPage

  const sensorPage = useFragmentWithVariations(
    sensorPageFragment,
    sensorPageSchema
  )
  const layout = sensorPage.layout

  return {
    ...sensorPageSchema.parse(sensorPage),
    layout: layoutNoHeaderSchema.parse(layout)
  }
}
