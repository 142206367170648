import { gql } from '@apollo/client/index.js'

import type { BaselineIdProps } from './index'

export const NT_FEATURE_FLAG_QUERY = ({ baselineId }: BaselineIdProps) => {
  return gql`
  fragment ninetailedFeatureFlagFrag on NtFeatureFlag {
    __typename
    sys {
      id
    }
    title
    json
  }

  fragment ninetailedFeatureFlagExp on NtExperience {
    sys {
      id
    }
    __typename
    ntName
    ntType
    ntAudience {
      sys {
        id
      }
      ntName
    }
    ntConfig
    ntVariantsCollection {
      items {
        ... on NtFeatureFlag {
          ...ninetailedFeatureFlagFrag
        }
      }
    }
  }

  query NinetailedFeatureFlagQuery(
    $preview: Boolean!
    $locale: String!
  ) {
    ntFeatureFlag(
      id: "${baselineId}"
      preview: $preview
      locale: $locale
    ) {
      ...ninetailedFeatureFlagFrag
      ntExperiencesCollection {
        items {
          ...ninetailedFeatureFlagExp
        }
      }
    }
  }
`
}
