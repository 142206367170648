import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

const indexMap = ['gen-3', 'gen-2']
export function useTrackingAlarmTile() {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(
    (index: number) => {
      trackEvent({
        event: 'alarm-sensor-generation-click',
        label: indexMap[index] ?? 'invalid-gen'
      })
      return true
    },
    [trackEvent]
  )
}
