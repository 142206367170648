import { AccessoryCard } from './components'

type Accessory = {
  readonly name: string
  readonly description: JSX.Element
  readonly image: {
    readonly url: string
    readonly description: string
    readonly originalWidth: number
    readonly originalHeight: number
  }
  readonly maxQuantity: number
  readonly sku: string
}

function AccessoryCards({
  heading,
  subheading,
  accessories
}: {
  readonly heading: string
  readonly subheading: string
  readonly accessories: readonly Accessory[]
}) {
  return (
    <div className="md:order-3 lg:col-span-full">
      <div className="px-4 md:px-0">
        <h1 className="m-0 p-0 text-3xl font-medium md:text-4xl">{heading}</h1>
        <h3 className="m-0 pb-4 pt-3 text-base font-normal md:text-lg">
          {subheading}
        </h3>
      </div>
      <div className="m-0 p-0 md:-ml-4 lg:-ml-11">
        <section className="max-w-8xl mx-auto mt-8 first:mt-0 lg:mt-16 lg:px-7">
          <div className="grid md:grid-cols-3 lg:grid-cols-4">
            {accessories.map((accessory, index) => (
              <AccessoryCard key={index} {...accessory} />
            ))}
          </div>
        </section>
      </div>
    </div>
  )
}

export default AccessoryCards
