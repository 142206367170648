import React, { ReactNode } from 'react'

export type CartAffirmMonthlyOptionProps = {
  readonly content: ReactNode
}

function CartAffirmMonthlyOption({ content }: CartAffirmMonthlyOptionProps) {
  return <div>{content}</div>
}

export default CartAffirmMonthlyOption
