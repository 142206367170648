//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import * as css from './ProductPlan.module.scss'

export type ProductPlanProps = {
  /** The button will be defined as a LinkAddToCart or ButtonPopup in ss-ecomm-frontend and passed down as a prop. */
  readonly button?: ReactNode
  /** The list of features for each monitoring plan that opens up modals. */
  readonly keyFeatures: readonly Feature[]
  /** Additional text to display next to the price */
  readonly extraPriceText?: string
  /** The rich text at the top of the monitoring plan card. */
  readonly planDescription: ReactNode
  /** Price component for the monitoring plan that renders the fully formatted price string, including currency symbol and per-unit text */
  readonly price?: ReactNode
  /** The content of the product offer */
  readonly productOfferContent?: ReactNode
  /** The product discount that will be placed inside productOffer. */
  /** @deprecated this is only used to render productOffer with a placeholder. Use productOfferContent prop to pass a ReactNode instead of a Document */
  readonly productOfferValue?: string
  /** The text below the button. */
  readonly relatedInformation?: string
  /** The name of the monitoring plan. */
  readonly title: string
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly theme?: 'fullwidth' | 'fullWidthBlue' | 'fullWidthWhite' | 'standard'
  /** Type of monitoring plan */
  readonly planType?:
    | 'interactive'
    | 'pro premium'
    | 'pro'
    | 'self'
    | 'standard'
}

type Feature = {
  readonly id: string
  readonly clickTarget?: ReactNode
}

const colorThemes: ReadonlyArray<ProductPlanProps['theme']> = [
  'fullWidthBlue',
  'fullWidthWhite'
]

/** @deprecated Do not use ss-react-components*/
const ProductPlan: FC<ProductPlanProps> = ({
  button,
  keyFeatures,
  extraPriceText,
  planDescription,
  price,
  productOfferContent,
  relatedInformation,
  title,
  productOfferValue,
  dataComponent = 'SSProductPlan',
  theme = 'standard'
}: ProductPlanProps) => {
  const hasProductOfferContent = !!productOfferContent
  const shouldRenderProductOfferWithPlaceholder =
    (productOfferContent && productOfferValue) || hasProductOfferContent

  const renderProductOffer = ({ textColor = 'neutralWhite' }) => (
    <div className={classNames(css.productOffer, `${textColor}TextColor`)}>
      {productOfferContent}
    </div>
  )

  const renderProductOfferWithPlaceholder = () => (
    <div
      className={classNames('darkOrangeTextColor', {
        [css.productOfferFullWidth]: theme === 'fullwidth'
      })}
    >
      {productOfferContent}
    </div>
  )

  const renderPlanDescription = () => {
    return (
      <div className={css.description}>
        <h3 className={classNames('h3', css.planName)}>{title}</h3>
        {planDescription}
      </div>
    )
  }

  const renderPlanDescriptionWithDetails = () => {
    return (
      <>
        <div className={css.planNameContainer}>
          <h3 className={classNames('h3 text-white', css.planName)}>{title}</h3>
        </div>
        <div className={css.description}>{planDescription}</div>
        <div className={css.productInfoMobile}>
          <div className={css.priceDetails}>
            <p className={css.price}>{price}</p>
          </div>
          {renderProductOffer({})}
          <p className={classNames('bodyText', css.relatedInformation)}>
            {relatedInformation}
          </p>
        </div>
      </>
    )
  }

  const renderFeatures = () => {
    return (
      <ul className={css.ulKeyFeatures}>
        {keyFeatures.map(feature => {
          return (
            <li
              className={classNames('mt-4 first:mt-0 md:mt-8', css.keyFeature)}
              key={feature.id}
            >
              {feature.clickTarget}
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div
      className={classNames('rounded-base bg-neutral-light-50', css.container, {
        [css.containerFullWidth]: theme === 'fullwidth',
        [css.containerColor]: colorThemes.includes(theme),
        [css.containerBlue]: theme === 'fullWidthBlue',
        [css.containerWhite]: theme === 'fullWidthWhite'
      })}
      data-component={dataComponent}
    >
      {colorThemes.includes(theme)
        ? renderPlanDescriptionWithDetails()
        : renderPlanDescription()}

      <div className={css.featuresContainer}>{renderFeatures()}</div>
      <div
        className={classNames(css.productInfo, {
          [css.productPlanFullWidth]: theme === 'fullwidth',
          [css.productPlanNoButton]: !button
        })}
      >
        <div className={classNames(css.priceDetails)}>
          {price ? <p className={css.price}>{price}</p> : null}
          {extraPriceText ? (
            <p className={css.extraPriceText}>{extraPriceText}</p>
          ) : null}
        </div>
        {colorThemes.includes(theme)
          ? renderProductOffer({})
          : shouldRenderProductOfferWithPlaceholder &&
            renderProductOfferWithPlaceholder()}

        {button ? <div className={css.buttonContainer}>{button}</div> : null}
        {relatedInformation ? (
          <p className={classNames('bodyText', css.relatedInformation)}>
            {relatedInformation}
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default ProductPlan
