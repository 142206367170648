import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { contentfulImageSchema } from '@ecomm/contentful/components'
import { TypeOf, z } from '@simplisafe/ewok'

export const stepperStepSchema = z.object({
  id: z.string(),
  __typename: z.string(),
  title: z.string(),
  description: contentfulRichTextSchema,
  disclaimer: z.string().nullish(),
  image: contentfulImageSchema
})

export const stepperSchema = z.object({
  __typename: z.literal('ContentfulStepper'),
  title: z.string(),
  subtitle: z.string().nullish(),
  badge: z.string().nullish(),
  steps: stepperStepSchema.array().nonempty()
})

export type StepperSchema = TypeOf<typeof stepperSchema>
export type StepperStepSchema = TypeOf<typeof stepperStepSchema>
