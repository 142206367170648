//@ts-nocheck
import { Maybe } from '@simplisafe/ewok'
import rTest from 'ramda/src/test'
import React, {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useState
} from 'react'
import { useTracking } from 'react-tracking'

import { Text } from '..'
import type { SSButtonProps } from '../SSButton'
import SSButton from '../SSButton'
import SSInput from '../SSInput'
import { WizardType } from './Wizard'
import type { Response } from './WizardTypes'

type WizardEmailSubmitProps = {
  readonly buttonProps?: Partial<SSButtonProps>
  readonly hideLabel?: boolean
  readonly id: string
  readonly label: ReactNode
  readonly labelClass?: string
  readonly legalText?: ReactNode
  readonly message?: ReactNode
  /** You don't need to add this prop. It's injected by the Wizard container */
  readonly onNextStep?: (response: Response) => () => void
  readonly onSubmit: (x: Record<string, unknown>) => void
  /** Placeholder text for input field */
  readonly placeholder?: string
  readonly response?: Record<string, unknown>
  readonly submitButtonLabel: string
  readonly type?: WizardType
  readonly defaultEmail?: string
  readonly emailErrorMessage?: string
}

// regex source: https://emailregex.com/
export const validateEmail = rTest(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

/** @deprecated Do not use ss-react-components*/
const WizardEmailSubmit: FC<WizardEmailSubmitProps> = ({
  buttonProps,
  hideLabel = true,
  id,
  message,
  onNextStep,
  onSubmit,
  placeholder,
  submitButtonLabel,
  legalText,
  label,
  labelClass,
  response,
  defaultEmail = '',
  emailErrorMessage
}: WizardEmailSubmitProps) => {
  const { Track, trackEvent } = useTracking({ appSection: 'wizardEmailSubmit' })

  const [value, setValue] = useState(defaultEmail)

  const [isValid, setIsValid] = useState(true)

  const [touched, setTouched] = useState(!!defaultEmail)

  const handleBlur = () => {
    setIsValid(validateEmail(value))
  }

  const handleChange = useCallback(e => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const v = e.target.value
    setTouched(true)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setValue(v)
    setIsValid(true)
  }, [])

  const handleSubmit: MouseEventHandler<HTMLElement> = useCallback(
    e => {
      const isValidEmail = validateEmail(value)
      setIsValid(isValidEmail)

      Maybe.fromFalsy(isValidEmail).forEach(() => {
        onSubmit({
          [id]: value,
          ...response
        })

        trackEvent({ event: 'submit' })

        onNextStep &&
          onNextStep({
            id: 'submitted',
            value: 'true'
          })()
      })

      e.preventDefault()
    },
    [onSubmit, id, value, response, trackEvent, onNextStep]
  )

  return onNextStep ? (
    <Track>
      <div className="mx-auto mt-6 max-w-lg">
        {message ? <div>{message}</div> : null}
        <div className="flex flex-col">
          <div className="grid w-full grid-cols-12">
            <SSInput
              className="col-span-12 h-14 max-w-none md:col-span-9"
              error={!isValid && touched}
              errorMsg={!isValid && touched ? emailErrorMessage : ''}
              hideLabel={hideLabel}
              id={id}
              label={label}
              labelClass={labelClass}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder}
              type="email"
              value={value}
            />
            <SSButton
              className="col-span-12 mt-4 md:col-span-3 md:ml-3 md:mt-0"
              disabled={!touched}
              onClick={handleSubmit}
              type="submit"
              {...buttonProps}
            >
              {submitButtonLabel}
            </SSButton>
          </div>
        </div>
        <Text
          className="prose-a:m-0 prose-a:!text-xs prose-p:text-xs mt-2"
          useTailwind
        >
          {legalText}
        </Text>
      </div>
    </Track>
  ) : null
}

export default WizardEmailSubmit
