import { overloadMaybe, prop } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { formatCTPriceToNumber } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getShippingInfoPrice = (cart: ImmutableCart) =>
  pipe(
    overloadMaybe(prop('shippingInfo', cart)),
    O.chain(shippingInfo => O.fromNullable(shippingInfo.price)),
    O.chain(price => O.fromNullable(formatCTPriceToNumber(price))),
    O.fold(
      () => 0,
      formattedPrice => formattedPrice
    )
  )
