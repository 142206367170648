import ReactModal from 'react-modal'

/**
 * setAppElement abstracts ReactModal's setAppElement to help with testing.
 *
 * This previously was defined on a per-component basis in ecomm-frontend, but the value was always #__gatsby.
 * Since the jsdom has no concept of #__gatsby, this will avoid ReactModal from throwing a runtime error complaining that the selector returned nothing.
 *
 * Also see https://github.com/reactjs/react-modal/issues/632
 */
const setAppElement = (environment?: string) => {
  ReactModal.setAppElement(environment === 'test' ? 'body' : '#___gatsby')
}

export default setAppElement
