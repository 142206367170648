import { useCallback } from 'react'

import useEventListener from '../useEventListener'

type useOnKeyUpProps = (
  key: string,
  callback: (evt: KeyboardEvent) => void
) => void

const useOnKeyUp: useOnKeyUpProps = (key, callback) => {
  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      event.key === key ? callback(event) : null
    },
    [callback, key]
  )

  useEventListener('keyup', <EventListener>handleKeyUp)
}

export default useOnKeyUp
