import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click change plan in cart.
 */
export function trackCartChangePlan() {
  getRudderstack(r => {
    r.track('cart_change_plan')
  })
}
