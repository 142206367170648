import { params } from '@ecomm/tracking'
import { noValue } from '@ecomm/utils'
import { Locale } from '@simplisafe/ewok'
import { StringParam, useQueryParam } from 'use-query-params'
import { useSetPartnerLogic } from '..'
import {
  CUSTOMER_GROUP_COOKIE_TIMEOUT,
  Campaign,
  LocaleMap,
  emptyCampaign
} from './constants'

/**
 * Pulls a campaign from the URL query params and looks it up to determine the associated
 * customerGroup/partnerName, then sets all associated partner logic
 */
export function usePartnerUtmCampaign(
  locale: Locale,
  campaignList: readonly Campaign[]
) {
  const [partnerCampaign] = useQueryParam(params.campaign, StringParam)

  // look for matching campaign, assign it an empty one if none exists
  // let the useSetPartnerLogic hook handle the empty case
  const matchingCampaign =
    campaignList
      .filter(
        list =>
          list.campaignName === partnerCampaign &&
          LocaleMap[list.region] === locale
      )
      .pop() ?? emptyCampaign

  const { customerGroup, partnerName, partnerUrl } = matchingCampaign

  useSetPartnerLogic(
    partnerName,
    customerGroup,
    partnerUrl,
    noValue(),
    CUSTOMER_GROUP_COOKIE_TIMEOUT
  )
}
