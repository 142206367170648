import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

const useTrackingAffirmInformationIcon = (label: string) => {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(() => {
    trackEvent({
      action: 'click',
      event: 'affirm_informational_click',
      label: label
    })
  }, [label, trackEvent])
}
export default useTrackingAffirmInformationIcon
