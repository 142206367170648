import { MonitoringTwoColumnModal } from '@ecomm/monitoring/components'
import { useIsOnScreen } from '@ecomm/shared-hooks'
import {
  useTrackingOutdoorCameraWarning,
  useTrackOutdoorCamWarningRender,
  useTrackOutdoorCamWarningView
} from '@ecomm/tracking'
import React, { useEffect, useRef, useState } from 'react'

import { WarningIcon } from '../icons/WarningIcon'

export function CartOdmonWarning() {
  const [showModal, setShowModal] = useState(false)
  const trackOutdoorCamWarningClick = useTrackingOutdoorCameraWarning('cart')

  const trackComponentRender = useTrackOutdoorCamWarningRender()
  useEffect(() => trackComponentRender(), [trackComponentRender])

  const containerRef = useRef<HTMLDivElement>(null)
  const isSeen = useIsOnScreen(containerRef)
  const trackCamWarningView = useTrackOutdoorCamWarningView()
  useEffect(() => {
    isSeen && trackCamWarningView()
  }, [isSeen, trackCamWarningView])

  return (
    <div
      className="mb-8 rounded-[10px] bg-neutral-light-50 p-0 md:p-1"
      data-component="ODMONWarning"
      ref={containerRef}
    >
      <div className="flex items-start justify-around gap-8 md:items-center">
        <div className="ml-10 mt-6 md:mt-0">
          <WarningIcon />
        </div>
        <p
          className="text-neutral-black mr-10 text-base"
          data-component="MissingODMONWarningText"
        >
          Any
          <button
            className=" cursor-pointer border-none bg-transparent text-base text-neutral-black underline"
            onClick={() => {
              trackOutdoorCamWarningClick()
              setShowModal(true)
            }}
          >
            outdoor camera(s)
          </button>
          in your system will be updated (price will not change). To receive a
          camera that enables Active Guard Outdoor Protection you must have a
          Pro or Pro Plus plan.
        </p>
      </div>
      <MonitoringTwoColumnModal
        closeModal={() => setShowModal(false)}
        disclaimer={[
          '*With select monitoring plans.',
          '**Battery life may vary depending on your usage.'
        ]}
        imageSrc="https://images.ctfassets.net/v6awd0kabi65/4m2OkKAh8SJ8bfXBXlTt2n/54ded545e8d67e6824bd2615f676aa2f/image__8_.png"
        paragraphs={[
          <>
            Get HD security footage in full color — day or night. With an
            ultra-wide 140° field of view and 10x digital zoom, it’s easy to see
            what’s happening. And if it’s dark outside, your camera’s
            motion-activated spotlight will switch on so you can see who’s
            there.
          </>,
          <>
            <strong>Designed to conserve battery life.</strong> Cameras that
            record all the time drain batteries fast. Plus they give you tons of
            useless footage that you don’t need. We built ours with smart person
            detection, so you only get recordings that matter*. And that means
            your battery can easily last up to 3 months before it needs to be
            recharged.**
          </>
        ]}
        showModal={showModal}
        title="Outdoor Camera"
      />
    </div>
  )
}
