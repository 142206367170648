import { packageSchema } from '../../components/Package/schema'
import { sensorGroupSchema } from '../../components/SensorGroup/schema'
import useFragmentWithVariations from '../../hooks/useFragmentWithVariations'
import pdpPageSchema, { pdpPageLayout } from './pdpPageSchema'

export const usePdpPageFragment = <
  T extends { readonly contentfulPackageDetailPage: U },
  U extends { readonly package: V; readonly slug: string },
  V extends { readonly personalizePackage: W },
  W
>(
  query: T
) => {
  const pdpPageFragment = query.contentfulPackageDetailPage
  const pdpPage = useFragmentWithVariations(pdpPageFragment, pdpPageSchema)
  const layoutFragment = pdpPage.layout
  const layout = useFragmentWithVariations(layoutFragment, pdpPageLayout)
  const packageFragment = pdpPage.package
  const pagePackage = useFragmentWithVariations(packageFragment, packageSchema)
  const sensorGroupFragment = pdpPageFragment?.package?.personalizePackage
  const sensorGroup = useFragmentWithVariations(
    sensorGroupFragment,
    sensorGroupSchema
  )

  return {
    ...pdpPageSchema.parse(pdpPage),
    layout: pdpPageLayout.parse(layout),
    slug: pdpPageFragment.slug,
    package: {
      ...packageSchema.parse(pagePackage),
      personalizePackage: sensorGroupSchema.parse(sensorGroup)
    }
  }
}
