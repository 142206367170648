import { useNinetailedFeatureFlag } from '@ecomm/shared-ninetailed'

const NINETAILED_CTFL_ID_CONTROL = '7qh0io92alQM0oIcD8dSXM'

export const useIsBMSMoreDetailsExperienceEnabled = () => {
  const featureFlagData = useNinetailedFeatureFlag({
    baselineId: NINETAILED_CTFL_ID_CONTROL
  })

  return featureFlagData.isVariant
}
