import { useResetAtom } from 'jotai/utils'
import { useCallback } from 'react'

import { freeGiftItemAtom } from '../../atoms/draftCart/freeGiftAtom'
import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'
import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import { totalPriceAtom } from '../../atoms/draftCart/totalPriceAtom'

/**
 *
 * @returns A function to reset all Draft Cart atoms to their intial state.
 */
export function useResetDraftCart() {
  const resetItems = useResetAtom(itemQuantityAtom)
  const resetFreeGift = useResetAtom(freeGiftItemAtom)
  const resetMonitoringPlan = useResetAtom(totalPriceAtom)
  const resetTotalPrice = useResetAtom(monitoringAtom)

  const reset = useCallback(() => {
    resetItems()
    resetFreeGift()
    resetMonitoringPlan()
    resetTotalPrice()
  }, [])

  return reset
}
