import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BlogPostPreview } from '@ecomm/blog-data'
import { ContentfulImage } from '@ecomm/contentful/components'
import { Link, useNavigate } from '@ecomm/framework'
import { useMicroCopy } from '@ecomm/micro-copy'
import { format, parseISO } from 'date-fns'

export function BlogPreview({
  title,
  previewContent,
  publishDate,
  image,
  slug,
  index
}: BlogPostPreview & { readonly index: number }) {
  const navigate = useNavigate()

  const formattedDate = publishDate
    ? format(parseISO(publishDate), 'MMMM do, yyyy')
    : ''
  const redirectUrl = `/${slug}`
  const microCopy = useMicroCopy()

  const imageLoading = index === 0 ? 'high' : index <= 3 ? 'normal' : 'lazy'

  const text = previewContent?.json

  return (
    <li>
      <h2 className="text-heading-3-size mb-4 md:mb-6 lg:mb-8">
        <Link
          className="hover:text-complementary-blue-100 no-underline"
          to={redirectUrl}
        >
          {title}
        </Link>
      </h2>
      <div className="grid grid-cols-12 gap-4 md:gap-8">
        {image ? (
          <ContentfulImage
            className="rounded-base col-span-12 cursor-pointer md:col-span-6"
            loading={imageLoading}
            onClick={() => navigate(redirectUrl)}
            quality={70}
            width={400}
            {...image}
          />
        ) : null}
        <div className="col-span-12 md:col-span-6 md:flex md:flex-col">
          <p className="text-neutral-medium-100 mt-2 md:mb-8">
            {microCopy['blog-posted-date'](formattedDate)}
          </p>
          {text ? documentToReactComponents(text) : null}
          <Link
            className="block w-full text-left font-bold md:mt-auto md:text-right"
            to={redirectUrl}
          >
            Full Post &gt;
          </Link>
        </div>
      </div>
    </li>
  )
}
