import { useMicroCopy } from '@ecomm/micro-copy'
import { useFormStyles } from '@ecomm/promotions-hooks'
import { useTracking } from 'react-tracking'

import { PromoButton } from '../PromoButton'

export function LeadCaptureSuccess() {
  const microCopy = useMicroCopy()
  const { textStyles } = useFormStyles()
  const { trackEvent } = useTracking()

  const onClick = () => {
    trackEvent({
      event: 'promo_banner_shop_now'
    })
  }

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center"
      data-component="LeadCaptureFormSuccess"
    >
      <PromoButton
        href="/home-security-shop"
        onClick={onClick}
        text={microCopy['shop-now']}
      />
      <p
        className={`max-lg:text-neutral-black m-0 p-0 pt-1 text-center ${textStyles}`}
      >
        Your code will automatically apply at checkout.
      </p>
    </div>
  )
}
