import { useMicroCopy } from '@ecomm/micro-copy'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import { Header as HeaderLander, HeaderProps } from '@ecomm/shared-components'
import { Locale } from '@ecomm/utils'

type PartnerHeaderProps = HeaderProps & {
  readonly locale: Locale
}

export function PartnerHeader(data: PartnerHeaderProps) {
  const headerData = useHeaderRedesignQuery()
  const microcopy = useMicroCopy()

  return data.locale === 'en-GB' ? (
    <Header {...headerData} />
  ) : (
    <HeaderLander {...data}>
      {data.type === 'lander' ? (
        <p className="m-0 mx-auto hidden font-bold text-white lg:block">
          {microcopy['60-day-money-back']}
        </p>
      ) : null}
    </HeaderLander>
  )
}
