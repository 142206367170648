import { useVisitorId } from '@ecomm/data-storage'
import { variationAtom } from '@ecomm/optimizely-utils'
import * as O from 'fp-ts/lib/Option'
import { flow } from 'fp-ts/lib/function'
import { useAtom } from 'jotai'
import { useCallback } from 'react'

import { experimentKeyIsValid } from './lib/experimentKeyIsValid'
import { useReadyClient } from './lib/useReadyClient'

/**
 * Returns an IO function that forces the user into a variation.
 */
export const useOptimizelyForceVariation = (
  experimentKey: string,
  variationKey: string
) => {
  const [variations, setVariations] = useAtom(variationAtom)

  const client = useReadyClient()

  const visitorId = useVisitorId()

  return useCallback(() => {
    experimentKeyIsValid(experimentKey) &&
      setVariations(variations.set(experimentKey, variationKey))

    return client().then(
      flow(
        O.bindTo('c'),
        O.bind('userId', () => visitorId),
        O.bind('_experimentKey', () =>
          experimentKeyIsValid(experimentKey) ? O.of(experimentKey) : O.none
        ),
        O.fold(
          () => false,
          ({ c, userId, _experimentKey }) => {
            return c.setForcedVariation(_experimentKey, userId, variationKey)
          }
        )
      )
    )
  }, [experimentKey, variationKey, client])
}
