import { useCallback } from 'react'

import { useTrackMetricEvent } from '../useTrackMetricEvent'

export type LoginStatus =
  | 'API_FAILURE'
  | 'INVALID_OR_CLOSED'
  | 'NO_DATA'
  | 'PROCESSING_ERROR'
  | 'SUCCESS'
  | 'TIMEOUT'
export type LoginLocation = 'CHECKOUT'

export const useTrackingLoggedIn = () => {
  const trackMetricEvent = useTrackMetricEvent()

  return useCallback(
    (loginStatus: LoginStatus, loginLocation: LoginLocation) => {
      trackMetricEvent('logged_in', {
        status: loginStatus,
        location: loginLocation
      })
    },
    [trackMetricEvent]
  )
}
