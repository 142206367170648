import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'

import type { SubItem } from '../../types'

type Props = {
  readonly subItems: readonly SubItem[]
}

export function CartSubItem({ subItems }: Props) {
  const microCopy = useMicroCopy()
  const getSubItemName = (sku: string, name: string) => {
    const replacements = {
      CMOB1: 'Outdoor Camera',
      'CM021-01DWW': 'Outdoor Camera'
    }
    return replacements[sku] || name
  }

  return (
    <section
      className="my-0 mb-0 ml-16 mt-[-0.5rem] hidden w-9/12 items-center md:mt-1 md:flex "
      data-component="OrderSummarySubitems"
      data-testid="OrderSummarySubitems"
    >
      <ul className="mt-0 list-none columns-2 md:mb-1 md:mt-3">
        {subItems
          ? subItems.map(subItem => (
              <li
                className="pb-1 pt-1 text-xs text-[#78746F] md:py-0 md:pb-0 md:pt-0 md:text-lg"
                key={subItem.subItemSku}
              >
                {subItem.subItemQuantity}{' '}
                {subItem.subItemIsFree
                  ? microCopy['free-text'].toUpperCase()
                  : ''}{' '}
                {getSubItemName(subItem.subItemSku, subItem.subItemName)}
              </li>
            ))
          : null}
      </ul>
    </section>
  )
}
