import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

type OutdoorCameraWarningRenderEvent = 'cart-outdoor-cam-warning-render'

export const useTrackOutdoorCamWarningRender = () => {
  const { trackEvent } =
    useTracking<TrackingData<OutdoorCameraWarningRenderEvent>>()

  return useCallback(() => {
    trackEvent({
      event: 'cart-outdoor-cam-warning-render'
    })
  }, [trackEvent])
}
