import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track navigation header events.
 */
export function trackNavigationClick({
  action,
  navElement
}: {
  readonly action: 'click' | 'close' | 'open'
  readonly navElement: string
}) {
  getRudderstack(r =>
    r.track('navigation_click', {
      action,
      nav_element: navElement.toLowerCase()
    })
  )
}
