import {
  optionalContentfulRichTextSchema,
  optionalGatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

/**
 * Parses the Product topic from contentful.
 *
 * Intended to only be used internally for this package and for hydration in `WrapRootElement`.
 *
 * @deprecated You should create a template specific schema instead.
 */
export const productTopicSchema = z
  .object({
    contentful_id: z.string(),
    description: optionalContentfulRichTextSchema,
    disclaimer: optionalContentfulRichTextSchema,
    features: optionalContentfulRichTextSchema,
    isNew: z.boolean(),
    longDescription: optionalContentfulRichTextSchema,
    maxQuantity: z.number(),
    name: z.string(),
    namePlural: z.string(),
    priceText: z.string(),
    proTip: optionalContentfulRichTextSchema,
    shortDescription: optionalContentfulRichTextSchema,
    sku: z.string(),
    slug: z.string(),
    image: optionalGatsbyImageSchema,
    imageWithBg: optionalGatsbyImageSchema,
    lifestyleImage: optionalGatsbyImageSchema,
    floorplanCarouselItem: z
      .makeOptional(z.object({ image: optionalGatsbyImageSchema }).nullable())
      .optional(),
    productModal: z.makeOptional(
      z
        .object({
          description: z.object({ raw: z.string() }),
          headline: z.string(),
          image: optionalGatsbyImageSchema
        })
        .optional()
        .nullable()
    ),
    outOfStock: z.makeOptional(
      z
        .object({
          primaryText: z.string(),
          secondaryText: z.string()
        })
        .optional()
        .nullable()
    ),
    accessoriesModal: z
      .makeOptional(
        z
          .object({
            contentful_id: z.string(),
            description: optionalContentfulRichTextSchema,
            headline: z.string(),
            modalTrigger: z.object({ text: z.string() }),
            products: z.array(z.object({ sku: z.string() }))
          })
          .nullable()
      )
      .optional(),
    variant: z.array(z.object({ sku: z.string(), productColor: z.string() })),
    variations: z.any().optional() // TODO: get these to be correct? I don't actually think I need to do anything here.
  })
  .passthrough()

export type ContentfulProductTopic = TypeOf<typeof productTopicSchema>
