import { gql } from '@apollo/client/index.js'

export const DEFAULT_HEADER_REDESIGNED_QUERY = gql`
  fragment qwBaseFragment on QuoteWizard {
    __typename
    sys {id}
    id: sys {id}
    jebbitUrl
    type
    buttonText
  }
  fragment qwExperienceFragment on NtExperience {
    id: sys {id}
    sys {id}
    name: ntName
    type: ntType
    audience: ntAudience {
      id: sys {id}
      name: ntName
    }
    config: ntConfig
    variants: ntVariantsCollection {
      items {
        ... on QuoteWizard {
          ...qwBaseFragment
        }
      }
    }
  }
  query HeaderRedesignQuery($preview: Boolean!, $locale: String!) {
    images: assetCollection(preview: $preview, locale: $locale,
      where: {
        sys: {
          id_in: [
            "4ISQo32VbzFoCuz0sPSEIb"
            "3X477mLg2byYfBD0MzmdMe"
            "30qjwcrVYFr7ZLmfts9yID"
            "5C0JVOfwJLkJK6WF6NZ9PW"
            "6pToedl3jApJdlviDDSSFi"
            "2H1lDKAlSNUC4E4nSolJIV"
            "2n9okk7IQZPtmVYvSD3KXg"
            "2S40uNCo4M8wvKgkKPQ17m"
            "4bC09EpnddkbaJd0Rw1GS8"
            "26vernKYKmkWfqIw6BiBFm"
            "75LoLEcNP7nJnbsRPpG3W6"
            "2gnj0MqS2RvadjpecJDG6K"
          ]
        }
      }
    ) {
      items {
        contentful_id: sys { id }
        title
        fileName
        size
        url
        width
        height
        description
      }
    }
    quoteWizard: quoteWizard(
      preview: $preview,
      locale: $locale,
      id: "1L1QlxR38aLrLL718EWGUh"
    ) {
      ...qwBaseFragment
      ntExperiencesCollection {
        items {
          ...qwExperienceFragment
        }
      }
    }
  }
`
