import { documentSchema } from '@ecomm/contentful-schemas'
import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

export const benefitsTextSchema = z.object({
  text: z.object({
    json: documentSchema
  })
})

export type BenefitsText = TypeOf<typeof benefitsTextSchema>

export const benefitsSchema = z.object({
  images: z.object({
    nodes: gatsbyImageSchema.array()
  }),
  text: benefitsTextSchema
})

export type BenefitsSchema = TypeOf<typeof benefitsSchema>
