import React, { forwardRef } from 'react'

export type HiddenAnchorProps = {
  readonly dataComponent?: string
  readonly id: string
  readonly top?: number
}

/** @deprecated Do not use ss-react-components*/
const HiddenAnchor = forwardRef<HTMLAnchorElement, HiddenAnchorProps>(
  ({ dataComponent, id, top = -150 }: HiddenAnchorProps, ref) => {
    return (
      <a
        className="invisible relative block"
        data-component={dataComponent}
        id={id}
        ref={ref}
        style={{ top: `${top}px` }}
      ></a>
    )
  }
)

export default HiddenAnchor
