import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

const appWidgetTabSchema = z.object({
  __typename: z.literal('ContentfulAppWidgetTab'),
  id: z.string(),
  tab: z.string(),
  title: z.string(),
  subtitle: z.string().nullish(),
  list: contentfulRichTextSchema,
  disclaimer: contentfulRichTextSchema.nullish(),
  appImage: gatsbyImageSchema
})

export const appWidgetSchema = z.object({
  __typename: z.literal('ContentfulAppWidget'),
  headline: z.string().optional(),
  height: z.enum(['medium', 'large']).nullish(),
  tabs: appWidgetTabSchema.array(),
  appleLogo: gatsbyImageSchema.optional(),
  googlePlayLogo: gatsbyImageSchema.optional(),
  simpliSafeLogo: gatsbyImageSchema.optional()
})

export const appWidgetIcons = z.object({
  appleLogo: gatsbyImageSchema,
  googlePlayLogo: gatsbyImageSchema,
  simpliSafeLogo: gatsbyImageSchema
})

export const ratingsSchema = z.object({
  appleStars: z.string(),
  appleRatings: z.string(),
  googleStars: z.string(),
  googleRatings: z.string(),
  appleLogo: gatsbyImageSchema,
  googlePlayLogo: gatsbyImageSchema,
  simpliSafeLogo: gatsbyImageSchema
})

export type RatingsSchema = TypeOf<typeof ratingsSchema>
export type AppWidgetSchema = TypeOf<typeof appWidgetSchema>
