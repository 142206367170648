import { TrackCheckoutStartedProps } from '@ecomm/cdp-tracking-utils'
import { TrackMetricProps } from '@ecomm/tracking'
import { trackShippingPageView } from '@ecomm/tracking'
import { TrackEvent } from '@ecomm/tracking/src/analytics'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/commercetools/cart'

export const handleShippingPageView = (
  cart: RemoteData<ImmutableCart>,
  trackEvent: TrackEvent,
  setHasViewedShippingPage: React.Dispatch<React.SetStateAction<boolean>>,
  setHasViewedPaymentPage: React.Dispatch<React.SetStateAction<boolean>>,
  trackMetricEvent: TrackMetricProps,
  trackCheckoutStarted: (args: TrackCheckoutStartedProps) => void
) => {
  cart.forEach(response => {
    setHasViewedShippingPage(true)
    setHasViewedPaymentPage(false)
    const itemList = response.lineItems
    trackShippingPageView(itemList, trackEvent, trackMetricEvent)
    trackCheckoutStarted({
      cart: response,
      originalEvent: 'continue_to_payment'
    })
  })
}
