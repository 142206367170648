import { useCartQuantity, useMediaQuery } from '@ecomm/shared-hooks'
import { useEffect, useState } from 'react'

export function useHeaderData() {
  const isDesktop = useMediaQuery('DesktopAndUp')
  const isTablet = useMediaQuery('TabletAndUp')
  const [
    mobileOrTabletRightSideContentFieldName,
    setMobileOrTabletRightSideContentFieldName
  ] = useState<'mobileHeaderContent' | 'tabletHeaderContent'>(
    'mobileHeaderContent'
  )

  useEffect(() => {
    isTablet
      ? setMobileOrTabletRightSideContentFieldName('tabletHeaderContent')
      : setMobileOrTabletRightSideContentFieldName('mobileHeaderContent')
  }, [isTablet])

  const { cartQuantity } = useCartQuantity()

  return {
    cartItemCount: cartQuantity,
    isDesktop,
    isTablet,
    mobileOrTabletRightSideContentFieldName
  }
}
