import { returnNextBusinessDay } from '@ecomm/utils'
import classNames from 'classnames'
import React from 'react'

type Props = {
  readonly message: string
  readonly className?: string
  readonly displaySeparateLines?: boolean
}

const getShippingText = (
  displaySeparateLines: boolean,
  text: string,
  nextBusinessDay: string
) => {
  /* This is a bit hacky, but we're implementing it this way for the sake of the monthly pricing experiment.
   * If the experiment wins, we should clean this up by changing the content in Contentful
   * to avoid having to slice a string like this.
   */
  const preDate = text.slice(0, text.indexOf(nextBusinessDay))
  const dateToEnd = text.slice(text.indexOf(nextBusinessDay))
  return displaySeparateLines ? (
    <>
      {preDate}
      <div className="font-bold">{dateToEnd}</div>
    </>
  ) : (
    text
  )
}

function ShippingEstimateDate({
  message,
  className = '',
  displaySeparateLines = false
}: Props) {
  const nextBusinessDay = returnNextBusinessDay()
  const text = message.replace('{{date}}', nextBusinessDay)

  return (
    <div className={classNames('mb-0', className)}>
      {getShippingText(displaySeparateLines, text, nextBusinessDay)}
    </div>
  )
}

export default ShippingEstimateDate
