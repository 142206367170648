import { useReady } from '@ecomm/shared-hooks'
import { getTrustPilot } from '@ecomm/shared-window'
import classNames from 'classnames'
import React, { type ReactNode, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

export type TrustPilot = {
  readonly dataBusinessunitId: string
  readonly dataLocale: string
  readonly dataStars?: string
  readonly dataStyleHeight: string
  readonly dataStyleWidth: string
  readonly dataTemplateId: string
  readonly dataTheme: string
}

export type TrustPilotBannerProps = {
  readonly children?: ReactNode
  readonly className?: string
  readonly widget: TrustPilot
}

export function TrustpilotBanner({
  widget,
  children = null,
  className = ''
}: TrustPilotBannerProps) {
  // Create a reference to the <div> element which will represent the TrustBox
  const isReady = useReady()
  const ref = useRef(null)

  /** If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
   * If it's not, it means the script isn't loaded yet.
   * When it is, it will automatically load the TrustBox.
   */
  useEffect(() => {
    const current = ref.current
    isReady && current && getTrustPilot(t => t.loadFromElement(current, true))
  }, [isReady])

  // TODO: we'll have to refactor this for Remix
  // Currently on Remix the Trustpilot banner only loads on full page reloads and not in app navigation
  // This seems like some hacky stuff in here that is related to Gatsby
  return isReady ? (
    <>
      <Helmet>
        <script
          async
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          type="text/javascript"
        ></script>
      </Helmet>

      <div
        className={classNames('trustpilot-widget', className)}
        data-businessunit-id={widget.dataBusinessunitId}
        data-component={TrustpilotBanner.name}
        data-locale={widget.dataLocale}
        data-stars={widget.dataStars}
        data-style-height={widget.dataStyleHeight}
        data-style-width={widget.dataStyleWidth}
        data-template-id={widget.dataTemplateId}
        data-theme={widget.dataTheme}
        ref={ref}
      >
        <div className="w-full text-center">{children}</div>
      </div>
    </>
  ) : null
}
