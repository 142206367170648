import { exists, window } from 'browser-monads-ts'

import { useCurrentPromoBannerData } from '../useCurrentPromoBannerData'
import { useTopBannerVisible } from '../useTopBannerVisible'

export const useShouldShowPromoBanners = () => {
  const hasBanner = useTopBannerVisible()
  const { discountText } = useCurrentPromoBannerData()
  // Checking if the window exists makes sure this isn't rendered during gatsby build, but only when the page
  // hydrates when the end user hits the site
  const isBrowser = exists(window)

  return hasBanner && discountText && isBrowser
}
