import range from 'ramda/src/range'
import reduce from 'ramda/src/reduce'

/**
 * To get the list of previous dates in the format [YYYY-MM]
 *
 * @param noOfMonths number
 * @param prevMonth number
 *
 * @example getPreviousDates(2, 1) if current date is 01/JAN/2021
 * Output: [2020-12, 2020-11]
 */
export const getPreviousDates = (noOfMonths: any, prevMonth: any) => {
  const date = new Date()
  // TODO this date mutation is a code smell
  date.setDate(1)
  date.setMonth(date.getMonth() - prevMonth)

  return reduce(
    (acc: ReadonlyArray<string>) => {
      const month = date.getMonth()
      const newDate = `${date.getFullYear()}-${('0' + (month + 1)).slice(-2)}`
      // TODO is this meant to change the date after using the value above?
      // This needs to be refactored to be clearer and not mutate date
      date.setMonth(month - 1)
      return [...acc, newDate]
    },
    [],
    range(0, noOfMonths)
  )
}

/** To get the time in UTC milliseconds for the given date string. */
export const getTime = (date: string) => new Date(date).getTime()

/** To check the given start and end date is valid or not. */
export const isValidFromToDate = ({ validFrom, validUntil }: any) => {
  const fromTime = getTime(validFrom)

  const toTime = getTime(validUntil)
  const today = new Date()

  return today.getTime() >= fromTime && today.getTime() <= toTime
}
