import React from 'react'

export type TextSectionProps = {
  readonly title: string
  readonly paragraphs: readonly string[]
}

export function TextSection({ data }: { readonly data: TextSectionProps }) {
  return (
    <div data-component="TextSection">
      <h2 className="mb-4 text-[28px] leading-8 lg:text-[55px] lg:leading-[60px]">
        {data.title}
      </h2>
      {data.paragraphs.map((paragraph, index) => (
        <p className="mb-4 max-w-4xl" key={index}>
          {paragraph}
        </p>
      ))}
    </div>
  )
}
