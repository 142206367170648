import { TrackingProvider } from '@ecomm/tracking'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { safePath, safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import { ContentfulRichTextGatsbyReference } from 'gatsby-source-contentful/rich-text'
import { Maybe } from 'monet'
import React, { useState } from 'react'

import { ContentfulSmallTextSection } from '../../graphql'
import { locale } from '../commercetools/utils'
import Page, { PageProps } from '../components/Page'
import { HidePopupWizard } from '../contexts/hidePopupWizardContext'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'

export type SmallTextSectionRedirect = {
  readonly id: ContentfulSmallTextSection['id']

  readonly contentful_id: ContentfulSmallTextSection['contentful_id']

  readonly node_locale: ContentfulSmallTextSection['node_locale']
  readonly description: {
    readonly raw: string

    readonly references: readonly ContentfulRichTextGatsbyReference[]
  }
  readonly internal: {
    readonly type: 'ContentfulSmallTextSection'
  }
}

export type DefaultPageProps = PageProps & {
  readonly data: {
    readonly allContentfulSmallTextSection?: readonly {
      readonly node: SmallTextSectionRedirect
    }[]
  }
}

/** filters out an array of text sections (used for the country redirect) and returns 1 that matches the current locale */
export const getRedirectByLocale =
  (locale: string) =>
  (
    textSections: readonly { readonly node: SmallTextSectionRedirect }[] = []
  ): Maybe<SmallTextSectionRedirect> =>
    Maybe.fromUndefined(
      textSections
        .filter(section => path(['node', 'node_locale'], section) === locale)
        .map(prop('node'))[0]
    )

/**
 * Standard Page wrapping AbstractPage, used for most things
 */
function DefaultPage({ data, pageContext, location }: DefaultPageProps) {
  const [hidePopupWizard, setHidePopupWizard] = useState(true)

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const contentfulSmallTextSection = safePath(
    ['allContentfulSmallTextSection', 'edges'],
    data
  )
    .chain(getRedirectByLocale(locale))
    .orUndefined()

  const handleHidePopup = (hide: boolean) => setHidePopupWizard(hide)

  const pageTitle = data?.contentfulPage?.seoDetails?.metaTitle || ''

  return (
    <TrackingProvider metaTitle={pageTitle}>
      <HidePopupWizard.Provider
        value={{
          handleHidePopup,
          hidePopupWizard
        }}
      >
        {/* @ts-expect-error TS(2322) FIXME: Type 'Partial<ContentfulSiteWideMessages>' is not ... Remove this comment to see the full error message */}
        <SiteWideMessagesContext.Provider
          value={safeProp('contentfulSiteWideMessages', data).orJust({})}
        >
          <Page
            data={{
              ...data,
              contentfulSmallTextSection
            }}
            location={location}
            pageContext={pageContext}
          />
        </SiteWideMessagesContext.Provider>
      </HidePopupWizard.Provider>
    </TrackingProvider>
  )
}

// the #graphql tag is required for eslint and GraphQL for VSCode

// TODO fragment for ContentfulProduct type needs to be added back in once it's fixed to match the most recent
// Contentful changes
export const query = graphql`
  #graphql
  query PageQuery($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulPage(id: { eq: $id }) {
      ...pageFragment
    }
    # Country Redirect Modal
    # TODO This should probably be handled as part of the pageLayout instead of requesting a specific id here.
    # To make that change, this should probably also be its own content type, because the pageLayout component code
    # assumes that any SmallTextSection in the pageLayout is a SimpleFooter. -csims
    #
    # this has to be filtered by locale since we are querying by ID here
    allContentfulSmallTextSection(
      filter: { contentful_id: { eq: "5XAuk7m84n6SKiMzt4hdfy" } }
    ) {
      edges {
        node {
          id
          node_locale
          contentful_id
          description {
            raw
            references {
              ... on ContentfulHeading {
                ...headingFragment
              }
              ... on ContentfulButton {
                ...contentfulButtonFragment
              }
            }
          }
        }
      }
    }
  }
`

export default DefaultPage
