import classNames from 'classnames'
import React from 'react'

import ShopImageLink, { ShopImageLinkProps } from '../ShopImageLink'

type ShopImageLinkListProps = {
  readonly links?: readonly ShopImageLinkProps[]
  readonly className?: string
}
export default function ShopImageLinkList({
  links = [],
  className = ''
}: ShopImageLinkListProps) {
  return links ? (
    <section
      className={classNames(
        'flex flex-col items-center justify-around gap-16 md:flex-row',
        className
      )}
    >
      {links.map(link => (
        <ShopImageLink key={`Image-link-${link.title}`} {...link} />
      ))}
    </section>
  ) : null
}
