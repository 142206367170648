import React from 'react'

type TierTwoProps = {
  readonly children: React.ReactNode
}

/**
 * Tier two (gift line item) text styles.
 *
 * Example: "+ free wireless indoor camera".
 *
 * Accounts for the typography styles on mobile/tablet bottom banner and desktop
 * top banner. Mobile starts with extra bold (800) weight at 18px, tablet starts
 * at 22px, and desktop uses normal (400) weight at 20px.
 */
export function TierTwo({ children }: TierTwoProps) {
  return (
    <div
      className="text-[18px] font-extrabold leading-[22px] md:text-[22px] md:leading-[28px] lg:text-[20px] lg:font-normal lg:leading-[22px]"
      data-component="TierTwo"
    >
      {children}
    </div>
  )
}
