//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import unless from 'ramda/src/unless'
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react'

import useUniqueId from '../hooks/useUniqueId'
import * as css from './SSInput.module.scss'

// This should be support more types assigned here.
export type inputTypeName =
  | 'checkbox'
  | 'email'
  | 'file'
  | 'hidden'
  | 'password'
  | 'tel'
  | 'text'

export type SSInputProps = {
  readonly className?: string
  readonly dataComponent?: string
  readonly defaultValue?: string
  readonly disabled?: boolean
  readonly error?: boolean
  readonly errorMsg?: string
  /**
   * If true, will apply a utility class to visually hide the label, but make
   * it still available for screen readers.
   */
  readonly hideLabel?: boolean
  /** @deprecated No longer needed because unique ids are automatically generated. This prop to be cleaned up after usage is removed from the frontend. */
  readonly id?: string
  /**
   * Should be a string, or very minimal inline HTML for phrasing content.
   * https://html.spec.whatwg.org/#the-label-element
   * https://html.spec.whatwg.org/#phrasing-content-2
   **/
  readonly label: ReactNode
  readonly labelClass?: string
  readonly maxLength?: number
  readonly name?: string
  readonly onBlur?: (event: FocusEvent) => void
  readonly onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  readonly onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void
  readonly placeholder?: string
  readonly required?: boolean
  readonly showRequiredSymbol?: boolean | true
  readonly type?: inputTypeName
  readonly value?: string
  readonly checked?: boolean | false
  readonly defaultChecked?: boolean | false
  readonly readOnly?: boolean | false
  readonly fileAcceptType?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const Input = forwardRef<HTMLInputElement, SSInputProps>(
  (
    {
      className,
      dataComponent = 'Input',
      defaultValue,
      disabled,
      error,
      errorMsg,
      hideLabel,
      id,
      label,
      labelClass,
      maxLength,
      name,
      onBlur: onBlurProp,
      onChange,
      onKeyPress,
      placeholder,
      required,
      showRequiredSymbol,
      type = 'text',
      value,
      checked,
      defaultChecked,
      readOnly,
      fileAcceptType
    }: SSInputProps,
    ref
  ) => {
    const normalizedType = type.toLowerCase()
    const inputId = useUniqueId(dataComponent)
    const [touched, setTouched] = useState(false)
    const [filename, setFilename] = useState<string>()
    const inputRequired = useMemo(() => {
      return touched && required
    }, [required, touched])

    const onBlur = (event: FocusEvent) => {
      onBlurProp && onBlurProp(event)
      setTouched(true)
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const fileLabelClass = ifElse(
      equals('file'),
      always(css.fileLabel),
      always('')
    )(normalizedType)

    const labelPart = (
      <label
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        className={classNames(css.label, fileLabelClass, labelClass, {
          screenReaderText: hideLabel
        })}
        data-component={`${dataComponent}_label`}
        htmlFor={inputId}
      >
        {label}
        {required && showRequiredSymbol ? <span>*</span> : null}
      </label>
    )

    const localOnChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event)
        normalizedType === 'file' && setFilename(event.target.files?.[0]?.name)
      },
      [onChange, normalizedType]
    )

    const inputPart = (
      <input
        aria-required={required}
        className={classNames(
          'rounded-base border-neutral-medium-100 max-h-14 w-full max-w-[427px] border border-solid px-4',
          css['input'],
          { [css['error']]: error },
          'bodyText text-input-color',
          className,
          // use styled label in place of native file input
          { 'sr-only': type === 'file' }
        )}
        data-component={`${dataComponent}_input`}
        defaultValue={defaultValue}
        disabled={disabled}
        id={inputId}
        maxLength={maxLength}
        name={unless(isNil, always(id))(name)}
        onBlur={onBlur}
        onChange={localOnChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={ref}
        required={inputRequired}
        type={type}
        value={value}
        {...(checked && { checked: checked })}
        {...(defaultChecked && { defaultChecked: defaultChecked })}
        // TODO: support custom file accept types
        {...(fileAcceptType && { accept: '.docx, .ppt, .pdf' })}
      />
    )

    const errorPart = errorMsg && (
      <div
        className={classNames(
          css['errorText'],
          'inputError col-span-12 text-left text-sm'
        )}
        data-component={`${dataComponent}_error`}
      >
        {errorMsg}
      </div>
    )

    return (
      <>
        {normalizedType === 'checkbox' ? (
          <div
            className={classNames(css.checkbox)}
            data-component={`${dataComponent}_checkbox`}
          >
            {inputPart}
            {labelPart}
          </div>
        ) : normalizedType === 'file' ? (
          <div className="flex flex-col items-center md:flex-row">
            {inputPart}
            {labelPart}
            <div className={css.fileStatus}>
              {filename ? (
                <div className="font-bold">{filename}</div>
              ) : (
                <div className={css.fileAcceptType}>{fileAcceptType}</div>
              )}
            </div>
          </div>
        ) : (
          <>
            {labelPart}
            {inputPart}
          </>
        )}
        {errorPart}
      </>
    )
  }
)

export default Input
