type Props = {
  readonly handleSubmit: () => void
  readonly isLoading?: boolean
}

export function ShippingValidationModalSubmission({
  handleSubmit,
  isLoading = false
}: Props) {
  return (
    <button
      className="bg-btn-primary w-full justify-center rounded-lg border-0 px-3 py-2.5 text-white md:w-auto md:px-8"
      data-component="ValidateButton"
      disabled={isLoading}
      onClick={handleSubmit}
    >
      <span className="justify-center text-base font-medium lg:text-lg">
        Continue with verified address
      </span>
    </button>
  )
}
