import { useReady } from '@ecomm/shared-hooks'
import classNames from 'classnames'
import React, { useEffect } from 'react'

import { AffirmClient, AffirmPageType } from '../types/affirm'

export type AffirmLogoType = 'symbol' | 'text' | null

export type AffirmPromoMessageProps = {
  readonly dataComponent?: string
  readonly elementType?: 'p' | 'span'
  readonly onLearnMoreClick: (e: React.MouseEvent<HTMLElement>) => void
  readonly affirmClient?: AffirmClient | null
  /** Decimal price, e.g. 34.99 for a product priced at $34.99 */
  readonly price: number
  readonly pageType: AffirmPageType
  readonly dataSku?: string | null
  readonly elementStyles?: string
  readonly parentStyles?: string
  readonly logoType?: AffirmLogoType
}

export function AffirmPromoMessage({
  dataComponent = AffirmPromoMessage.name,
  elementStyles = '',
  pageType,
  parentStyles = 'font-normal text-xs text-left',
  dataSku = null,
  elementType = 'p',
  onLearnMoreClick,
  price,
  logoType = null,
  affirmClient
}: AffirmPromoMessageProps) {
  const isReady = useReady()

  // Affirm's refresh function must be called when the price changes
  useEffect(() => {
    affirmClient &&
      affirmClient.ui.ready(() => {
        affirmClient.ui.refresh()
      })
  }, [affirmClient, price])

  return affirmClient && isReady ? (
    <div className={parentStyles} data-component={dataComponent}>
      {React.createElement(elementType, {
        className: classNames(
          'affirm-as-low-as [&>a]:text-inherit',
          elementStyles
        ),
        'data-amount': Math.round(price * 100),
        'data-page-type': pageType,
        'data-sku': dataSku,
        'data-affirm-type': logoType,
        onClick: onLearnMoreClick
      })}
    </div>
  ) : null
}
