//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'

import * as css from './CardBadge.module.scss'

export type CardBadgeProps = {
  readonly title: string
  readonly description: string
  readonly image: ReadonlyArray<ReactElement>
  readonly cardImage: ReadonlyArray<ReactElement>
  readonly className?: string
  readonly type?: 'mobile' | 'overlay'
  readonly dataComponent?: string
}

const CardBadge: FC<CardBadgeProps> = ({
  title,
  description,
  image,
  cardImage,
  className,
  type = 'overlay',
  dataComponent = CardBadge.name
}: CardBadgeProps) => {
  return type === 'mobile' ? (
    <div className={classNames('p1', className)} data-component={dataComponent}>
      <div className={css.cardBadgeMobile}>
        <div className={css.cardBadgeWrapper}>
          <div className={css.img}>{cardImage}</div>
          <h4 className={css.h4}>{title}</h4>
        </div>
        <p className={classNames('fontSize14', css.desc)}>{description}</p>
      </div>
    </div>
  ) : (
    <div className={className} data-component={dataComponent}>
      <div className={css.pdpGuarantee}>
        <div
          className={classNames(css.guaranteeBadgeimage, css.closedStateImg)}
        >
          {image}
        </div>
        <div className={classNames(css.guaranteeBadgeimage, css.imgHover)}>
          {cardImage}
        </div>
        <div className={css.guaranteeBadgecontent}>
          <h4 className={css.guaranteeBadgeTitle}>{title}</h4>
          <p
            className={classNames(
              'colorDark',
              'fontSize14',
              css.guaranteeBadgeDesc
            )}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}
export default CardBadge
