import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 2 yrs'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.RedSiren,
    text: 'Built-in 85 dB siren'
  }
]

const description = {
  content: `Every second counts in a fire. That's why the Smoke Detector alerts you immediately, sounding both the built-in siren and your Base Station siren.
    \nWith the Fast Protect™ Monitoring plan, the monitoring center will dispatch fire services if you can't be reached.
  `
}

const specs = {
  content: `4.75 × 4.75 x 1.12 inches
  \n1 CR-123A Lithium 3V battery (included)
  \nPhotoelectric sensors
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '59CDSzdLdCvIUvnvInU4Yg'
}

export const smokeDetectorData: ProductData = {
  productId: 'OHnYHze09tNbnu8DN7raE',
  sensorName: 'Smoke Detector',
  features,
  description,
  specs,
  faqs
}
