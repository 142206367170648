import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './data'
import { schema } from './schema'

export const useScoutProductPageDeadEndIIData = () => {
  const scoutProductPageDeadEndIIData = useLocalizedData<typeof schema>(
    schema,
    data
  )

  return useMemo(
    () => scoutProductPageDeadEndIIData,
    [scoutProductPageDeadEndIIData]
  )
}
