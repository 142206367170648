import classNames from 'classnames'
import React from 'react'

type DotControlsProps = {
  readonly length: number
  readonly currentIdx: number
  readonly handleSetIdx: (idx: number) => void
}

function DotControls({ length, currentIdx, handleSetIdx }: DotControlsProps) {
  return (
    <div className="absolute -bottom-8 z-10 flex w-full md:bottom-10">
      <ul className="flex w-full flex-row justify-center pr-10 md:justify-end">
        {Array.from({ length }).map((_, idx) => {
          const isActive = idx === currentIdx

          return (
            <li className={'m-1 flex items-center justify-center'} key={idx}>
              <button
                aria-label={`Slide ${idx + 1}`}
                className={classNames(
                  'border-radius-50% transition-background-color-250ms outline-revert h-[7px] w-[7px] cursor-pointer rounded-full border-none p-0',
                  {
                    'bg-neutral-dark': isActive,
                    'bg-neutral-medium': !isActive
                  }
                )}
                onClick={() => handleSetIdx(idx)}
                type="button"
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default DotControls
