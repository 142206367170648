export const createMockRichText = (text: readonly string[]) => {
  const content = text.map(
    t =>
      `{"data":{},"marks":[],"content":[{"data":{},"marks":[],"value":"${t}","nodeType":"text"}],"nodeType":"paragraph"}`
  )
  return `{"nodeType":"document","data":{},"content":[${content.join(',')}]}`
}

export const createMockRichTextWithItalics = (text: readonly string[]) => {
  const content = text.map((t, idx) =>
    idx === 0
      ? `{"data":{},"marks":[],"content":[{"data":{},"marks":[],"value":"${t}","nodeType":"text"}],"nodeType":"paragraph"}`
      : `{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"${t}","marks":[{"type":"italic"}],"data":{}}]}`
  )
  return `{"data":{},"content":[${content.join(',')}],"nodeType":"document"}`
}

export const createMockRichHeadingText = (
  text: string,
  headingValue: number
) => {
  return `{"nodeType":"document","data":{},"content":[{"nodeType":"heading-${headingValue}","data":{},"content":[{"nodeType":"text","value":"${text}","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}`
}

/**
 * Converts strings with markdown link patterns into rich text format.
 *
 * @param {string} markdown - The string containing markdown link patterns.
 * @returns {string} - The stringified representation of the Contentful RichText format.
 */
export function createMockRichTextWithLinks(markdown: string) {
  // Regular expression to match the markdown link pattern: [text](url).
  const regex = /\[([^\[]+)\]\(([^\s\)]+)\)/g

  // Splitting the markdown into pieces based on the links.
  const pieces = markdown.split(regex)

  const content = pieces
    .map((piece, index) => {
      // Every 3rd segment starting from 0 (0, 3, 6, ...) contains text outside a link.
      if (index % 3 === 0 && piece.trim()) {
        return {
          nodeType: 'text',
          value: piece,
          marks: [],
          data: {}
        }

        // Every 3rd segment starting from 1 (1, 4, 7, ...) contains the display text for a link.
      } else if (index % 3 === 1) {
        return {
          nodeType: 'hyperlink',
          data: { uri: pieces[index + 1] },
          content: [
            {
              nodeType: 'text',
              value: piece,
              marks: [],
              data: {}
            }
          ]
        }
      } else {
        return null
      }
    })
    .filter(Boolean) // Filtering out any null values.

  const result = {
    nodeType: 'document',
    data: {},
    content: [
      {
        nodeType: 'paragraph',
        data: {},
        content: content
      }
    ]
  }

  return JSON.stringify(result)
}
