import { useLocale } from '@ecomm/data-hooks'
import { Campaign, usePartnerUtmCampaign } from '@ecomm/partners-hooks'
import { useContentful } from '@ecomm/shared-apollo'
import { PARTNER_CAMPAIGN_QUERY } from './query'
import { partnerUtmCampaignSchema } from './schema'

export function PartnerUtmCampaign() {
  const locale = useLocale()
  const { data: apolloData } = useContentful(PARTNER_CAMPAIGN_QUERY)

  const partnerQuery = partnerUtmCampaignSchema.parse(apolloData)

  const campaigns: readonly Campaign[] =
    partnerQuery.partnerCampaignCollection.items

  usePartnerUtmCampaign(locale, campaigns)

  return null
}
