import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { Link } from '@ecomm/framework'
import { ReactNode } from 'react'
import { useTracking } from 'react-tracking'

type CartLinkProps = {
  readonly text: string
  readonly icon: ReactNode
  readonly url?: string
  readonly cartQuantity: number
}

export function CartLink({
  text,
  icon,
  cartQuantity,
  url = ''
}: CartLinkProps) {
  const { trackEvent } = useTracking()

  const ariaLabelText =
    cartQuantity === 1
      ? `Cart ${cartQuantity} item`
      : `Cart ${cartQuantity} items`

  return (
    <Link
      activeClassName="min-[1126px]:border-primary-100 min-[1126px]:border-b-2 min-[1126px]:mt-0.5 min-[1126px]:pb-[16px] min-[1126px]:pt-[15px]"
      aria-label={ariaLabelText}
      className="hover:border-b-primary-100 relative grid grid-cols-1 items-center justify-items-center gap-0 border-0 border-b border-solid border-b-transparent p-0 text-[10px] text-white no-underline transition-[border-bottom-color] duration-300 min-[1126px]:h-auto min-[1126px]:text-[13px] min-[1126px]:text-white"
      data-testid="header-add-to-cart-link"
      onClick={() => {
        trackEvent({
          event: 'navigation-link',
          eventAction: 'click',
          eventCategory: 'navigation',
          label: text
        })
        trackNavigationClick({
          action: 'click',
          navElement: text
        })
      }}
      to={url}
    >
      {cartQuantity > 0 && (
        <div
          className="bg-primary-100 text-neutral-black absolute -right-1 -top-1 h-4 w-4 rounded-full pt-[1px] text-center text-[11px]"
          data-testid="cart-quantity"
        >
          <span>{cartQuantity}</span>
        </div>
      )}
      {icon}
      <div className={'mt-0 flex min-[1126px]:mt-1'}>
        <span>{text}</span>
      </div>
    </Link>
  )
}
