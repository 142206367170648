import {
  formatPrice,
  getMatchingDynamicPrice,
  priceDefault
} from '@ecomm/data-price'
import { Locale } from '@ecomm/utils'
import { DynamicDiscount } from '@simplisafe/ss-ecomm-data/prices/service'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const formatThresholdPrice = (locale: Locale) => (num: number) =>
  pipe(O.of(num), formatPrice(priceDefault, locale, true), O.toUndefined)

export const getMatchingDynamicDiscount = (
  thresholds: O.Option<readonly DynamicDiscount[]>,
  price: number
) => {
  return pipe(
    thresholds,
    getMatchingDynamicPrice(price),
    O.map(discount => discount.value),
    O.getOrElse(() => 0)
  )
}

export const getPriceAccordingToType = (
  locale: Locale,
  discount: DynamicDiscount
) => {
  const formatPrice = formatThresholdPrice(locale)
  const isRelative = discount.discountType === 'relative'
  return pipe(discount.value, discountValue =>
    isRelative ? `${discountValue}%` : formatPrice(discountValue) || ''
  )
}
