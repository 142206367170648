import { useLocale } from '@ecomm/data-hooks'
import { selectProducts } from '@simplisafe/ss-ecomm-data/redux/select'
import { format, isAfter, parse } from 'date-fns'
import React from 'react'
import { useSelector } from 'react-redux'

import { ProductFragment } from '../types'
import OutOfStock from './OutOfStock'
import ShippingEstimate from './ShippingEstimate'

type Props = {
  readonly shippingEstimate: string
  readonly outOfStock: string
  readonly items: readonly ProductFragment[]
}

function DraftCartShipping({ shippingEstimate, outOfStock, items }: Props) {
  const isUs = useLocale() === 'en-US'

  const skus = items.map(item => item.sku)

  const productsEither = useSelector(selectProducts(skus))

  const today = new Date()

  const backorderDate = productsEither
    .map(products =>
      products
        .map(product => product.restockDate)
        .filter((dateStr): dateStr is string => !!dateStr)
        .map(dateStr => parse(dateStr, 'yyyy-MM-dd', new Date()))
        // restock dates are possibly out of date (e.g. 1970-01-01)
        .filter(date => isAfter(date, today))
        .sort((a, b) => b.getTime() - a.getTime())
    )
    .cata(
      () => null,
      dates => (dates.length > 0 ? format(dates[0], 'yyyy-MM-dd') : null)
    )

  return backorderDate ? (
    <OutOfStock
      backorderDate={backorderDate}
      items={items}
      message={outOfStock}
    />
  ) : (
    <ShippingEstimate
      className={isUs ? 'text-center md:text-left' : ''}
      displaySeparateLines={isUs}
      message={shippingEstimate}
    />
  )
}

export default DraftCartShipping
