import { useMicroCopy } from '@ecomm/micro-copy'
import { useTopBannerVisible } from '@ecomm/promotions-hooks'
import { useScrollPosition } from '@ecomm/shared-hooks'
import { useUniqueId } from '@ecomm/shared-hooks'
import { Cancel } from '@ecomm/shared-icons'
import { devParams } from '@ecomm/tracking'
import { get as sessionStorageGet } from '@ecomm/utils'
import { localStorage } from '@simplisafe/ewok'
import classNames from 'classnames'
import { useState } from 'react'
import { BooleanParam, useQueryParam } from 'use-query-params'

import type { GuidedSystemBuilderSchema } from '../GuidedSystemBuilder/schema'
import { QuoteWizardModal } from '../QuoteWizardModal'
const { get, set } = localStorage

interface PopupQuoteWizardProps {
  readonly data: GuidedSystemBuilderSchema
}

const popupOpenedStorageKey = 'popupWizardOpened'

export function PopupQuoteWizard({ data }: PopupQuoteWizardProps) {
  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)
  const bottomBannerVisible = sessionStorageGet('bottom_banner_visible')
  const [modalOpen, setModalOpen] = useState(false)
  const isPopupShowable = get(popupOpenedStorageKey) ? false : true
  const isPromoTopBanner = useTopBannerVisible()
  const { hasGoneDownAndUp } = useScrollPosition()

  const microCopy = useMicroCopy()

  const [mobileShowable, setMobileShowable] = useState(
    isPopupShowable &&
      !hidePopups &&
      (!isPromoTopBanner || bottomBannerVisible === 'hidden')
  )
  const [desktopShowable, setDesktopShowable] = useState(
    isPopupShowable && !hidePopups
  )
  const onClose = () => {
    // brazeLogCustomEvent('qw_dismiss')
    set(popupOpenedStorageKey, true)
    setDesktopShowable(false)
    setMobileShowable(false)
  }

  const dialogLabelId = useUniqueId('pop-up-quote-wizard')

  return (
    <div
      aria-labelledby={dialogLabelId}
      className={classNames(
        `rounded-base fixed bottom-0 z-[4] w-full transform overflow-hidden border
      bg-white shadow-[-0.1875rem_0.1875rem_0.4375rem_0_rgba(0,0,0,.2)] transition-all
      duration-300 ease-in-out  md:right-[12%] md:w-[32rem]`,
        {
          '-translate-y-4 opacity-100 visible':
            mobileShowable && hasGoneDownAndUp,
          'translate-y-full opacity-0 invisible':
            !mobileShowable || !hasGoneDownAndUp,
          // for Desktop
          'lg:-translate-y-6 lg:opacity-100 lg:visible':
            desktopShowable && hasGoneDownAndUp,
          'lg:translate-y-full lg:opacity-0 lg:invisible':
            !desktopShowable || !hasGoneDownAndUp
        }
      )}
      role="dialog"
    >
      <button
        aria-label="Close Popover"
        className="text-neutral-medium-120 absolute right-4 top-4 cursor-pointer border-0 bg-transparent p-0 md:right-4"
        onClick={() => onClose()}
      >
        <Cancel className="h-6 w-6" titleA11y="Close" />
      </button>
      <div className="pb-6 pl-8 pr-8 pt-8">
        <h3
          className="mb-2.5 mt-0 text-3xl font-medium md:text-4xl"
          id={dialogLabelId}
        >
          {microCopy['the-right-security-system']}
        </h3>
        <p className="mb-6 md:mb-10 lg:mb-10">
          {microCopy['take-a-short-quiz']}
        </p>
        <button
          className="btn btn-solid-primary mb-16 mt-4 block w-fit min-w-[190px] justify-center md:mb-12 md:mt-6"
          onClick={() => setModalOpen(true)}
        >
          {microCopy['take-our-quiz-label']}
        </button>
      </div>
      <QuoteWizardModal
        data={{ ...data, type: 'popup' }}
        onRequestClose={() => setModalOpen(false)}
        show={modalOpen}
      />
    </div>
  )
}
