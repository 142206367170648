import { Locale } from '@ecomm/utils'
type Props = {
  readonly locale: Locale
}
export function CartRetrievalErrorMessage({ locale }: Props) {
  const cartRetrievalErrorPhoneNumber: string =
    locale === 'en-GB' ? '0800-456-1789' : '888-910-1458'
  const retrievalErrorHeading = "Something isn't right. Try reloading the page."
  const supportText = `If the issue persists, please call ${cartRetrievalErrorPhoneNumber} to complete your order.`
  return (
    <div
      className="h-full min-h-[350px] text-center md:min-h-[400px] lg:min-h-[600px]"
      data-component="CartRetrievalErrorMessage"
    >
      <h2>{retrievalErrorHeading}</h2>
      <p>{supportText}</p>
    </div>
  )
}
