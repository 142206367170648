import {
  ukPostalCodeSchema,
  usPostalCodeSchema
} from '@ecomm/checkout/shipping-schema'
import { Locale } from '@ecomm/utils'

/**
 * Validate postal code based on schema
 */
export const validatePostalCode = (code: string, locale: Locale) =>
  locale === 'en-US'
    ? usPostalCodeSchema.safeParse(code).success
    : ukPostalCodeSchema.safeParse(code).success
