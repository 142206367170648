import classNames from 'classnames'
import React from 'react'

type CarouselItemProps = {
  readonly children: React.ReactNode
  readonly idx: number
  readonly currentIdx: number
}

function CarouselItem({ children, idx, currentIdx }: CarouselItemProps) {
  const isVisible = currentIdx === idx ? true : false

  return (
    <div
      className={classNames(
        'transition-visibility t-0 l-0 absolute h-full w-full transition-opacity duration-500',
        {
          'opacity-0': !isVisible,
          'opacity-100': isVisible
        }
      )}
      data-component="CarouselItem"
    >
      {children}
    </div>
  )
}

export default CarouselItem
