import * as Icons from '@ecomm/shared-icons'

import { TypeOf } from 'zod'

import { bubblesSchema } from './schema'

type DetailBubbleProps = TypeOf<typeof bubblesSchema>

export function DetailBubble({ icon, text }: DetailBubbleProps) {
  const Icon = Icons[icon]
  return (
    <div
      className="bg-neutral-black box-border flex min-h-[95px] min-w-[240px] max-w-[240px] items-center rounded-[20px] py-3 pr-6"
      data-component="DetailBubble"
    >
      <div className="m-2 mx-3 flex items-center justify-center">
        {icon ? <Icon className="h-8 w-8 text-white" /> : null}
      </div>
      <p className="m-0 p-0 text-xs text-white">{text}</p>
    </div>
  )
}
