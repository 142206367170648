//@ts-nocheck
import React, { FC, ReactNode } from 'react'

import * as css from './HowItWorksDetail.module.scss'

export type HowItWorksDetailProps = {
  /**
   * Any RichText type content and HTML markup can be passed as the content.
   * Renders below the title
   */
  readonly content: ReactNode
  /** Should be the markup for an image tag. */
  readonly image: ReactNode
  /** Renders an h3 style title above the content. */
  readonly title: string
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const HowItWorksDetail: FC<HowItWorksDetailProps> = ({
  content,
  image,
  title,
  dataComponent = HowItWorksDetail.name
}: HowItWorksDetailProps) => {
  return (
    <div className={css.detail} data-component={dataComponent}>
      <div className={css.detailImage}>{image}</div>
      <div className={css.detailBody}>
        <div className={css.detailBodyInner}>
          {title ? <h3 className={'h3'}>{title}</h3> : null}
          {content}
        </div>
      </div>
    </div>
  )
}

export default HowItWorksDetail
