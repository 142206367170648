import { addAtomDebugLabel } from '@ecomm/utils'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'

export const promoBannerVisibilityAtom = atomWithStorage(
  'promobanner_isVisible',
  true
)

addAtomDebugLabel(promoBannerVisibilityAtom, 'Promo Banner - Is Visible?')

export function usePromoBannerVisibility() {
  const [isVisible, setVisibility] = useAtom(promoBannerVisibilityAtom)

  return useMemo(
    () => ({
      isVisible,
      setVisibility
    }),
    [isVisible, setVisibility]
  )
}
