import { useOdmonExperience } from '@ecomm/shared-ninetailed'

import DraftCartAddToCartButton, {
  Props as DraftCartAddToCartButtonProps
} from '../../components/DraftCart/DraftCartAddToCartButton'

export function OdmonDraftCartAddToCartButton(
  props: Omit<DraftCartAddToCartButtonProps, 'isOdmonVariant'>
) {
  const odmonExperienceData = useOdmonExperience()

  return (
    <DraftCartAddToCartButton
      {...props}
      isOdmonVariant={odmonExperienceData.isVariant}
    />
  )
}
