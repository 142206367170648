import { useEffect } from 'react'
import { isSSR } from './utils'
/**
 * This is extracted to a function so it cane be tested
 */

export function useLockBodyScroll(block?: boolean, isLockPosition = true) {
  useEffect(() => {
    const ssr = isSSR()
    const originalStyle = ssr
      ? ''
      : window.getComputedStyle(document.body).overflow

    document.body.style.overflow = block ? 'hidden' : originalStyle

    const originalStylePosition = ssr
      ? ''
      : window.getComputedStyle(document.body).position

    document.body.style.position =
      block && isLockPosition === true ? 'fixed' : originalStylePosition

    return function () {
      document.body.style.overflow = originalStyle
      document.body.style.position = originalStylePosition
    }
  }, [block, isLockPosition])
}
