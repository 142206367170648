import { useOnClickOutside } from '@ecomm/shared-hooks'
import { voidFn } from '@simplisafe/ewok'
import classNames from 'classnames'
import React, { useRef } from 'react'

export interface PromoBannerBaseTemplateProps {
  readonly children: React.ReactNode
  readonly className?: string
  readonly position?: 'bottom' | 'top'
  readonly role?: string
  readonly onClick?: () => void
  readonly dataComponent?: string
  readonly onClickOutside?: () => void
  readonly style?: object
}

export function PromoBannerBaseTemplate({
  children,
  className = '',
  position = 'top',
  role = '',
  onClick = voidFn,
  onClickOutside = voidFn,
  dataComponent = PromoBannerBaseTemplate.name,
  style = {}
}: PromoBannerBaseTemplateProps) {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(onClickOutside, ref, '___gatsby')

  const containerClassNames: string = classNames(
    'bg-promo-banner-bg text-promo-primary-text left-0 overflow-hidden w-full',
    {
      'fixed bottom-0': position === 'bottom',
      'sticky top-0 p-4': position === 'top'
    },
    className
  )

  return (
    <div
      className={containerClassNames}
      data-component={dataComponent}
      onClick={onClick}
      ref={ref}
      role={role}
      style={style}
    >
      {children}
    </div>
  )
}
