import React from 'react'

import { ScoutPlanComparisonProps } from './types'

export function AtcButtonRow({
  professionalMonitoringAtcButton,
  selfMonitoringAtcButton
}: ScoutPlanComparisonProps) {
  return (
    <tr className="hidden md:table-row">
      <td colSpan={2}></td>
      <td className="border-spacing-y-2 border-0 py-2 text-center font-bold">
        {professionalMonitoringAtcButton}
      </td>
      <td className="border-spacing-y-2 border-0 py-2 text-center font-bold">
        {selfMonitoringAtcButton}
      </td>
    </tr>
  )
}
