import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from 'zod'

import { layoutNoHeaderSchema } from '../../assemblies/Layout/layoutSchema'

export const videoSchema = z.object({
  entryTitle: z.string(),
  webmLink: z.string(),
  mp4Link: z.string(),
  posterImage: z.string().optional()
})

export const productSchema = z.object({
  contentful_id: z.string(),
  name: z.string(),
  slug: z.string(),
  isNew: z.boolean().nullish().default(false),
  sku: z.string(),
  namePlural: z.string(),
  maxQuantity: z.number(),
  longDescription: contentfulRichTextSchema,
  features: contentfulRichTextSchema.nullish(),
  disclaimer: contentfulRichTextSchema.nullish(),
  imageWithBg: gatsbyImageSchema,
  lifestyleImage: gatsbyImageSchema.nullish(),
  installationInstructions: contentfulRichTextSchema.nullish(),
  installationImage: gatsbyImageSchema.nullish(),
  overviewVideo: videoSchema.nullish(),
  faq: z
    .object({
      primary: z
        .object({
          contentful_id: z.string(),
          question: z.object({
            question: z.string()
          }),
          answer: contentfulRichTextSchema
        })
        .array()
    })
    .nullish()
})

export const sensorPageSchema = z.object({
  title: z.string(),
  site: z.string().array(),
  id: z.string(),
  layout: layoutNoHeaderSchema,
  product: productSchema,
  updatedAt: z.string()
})

export type SensorPageSchema = TypeOf<typeof sensorPageSchema>
export type ProductSchema = TypeOf<typeof productSchema>
