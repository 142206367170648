import { heroBannerSchema } from '@ecomm/shared-components'
import { z } from '@simplisafe/ewok'

import { layoutSchema } from '../../assemblies/Layout/layoutSchema'

const formV2Schema = z.object({
  __typename: z.literal('ContentfulFormV2'),
  fields: z
    .object({
      label: z.string(),
      placeholder: z.string(),
      type: z.enum(['Text', 'File', 'Text Area', 'Email']),
      name: z.string()
    })
    .array(),
  button: z.object({
    text: z.string()
  })
})

/**
 * This is just for Gatsby
 */
const gridSchema = z.object({
  __typename: z.literal('ContentfulGrid'),
  contentful_id: z.string()
})

const componentsSchema = z.union([heroBannerSchema, gridSchema, formV2Schema])

export const partnerWithUsLayoutSchema = layoutSchema.extend({
  components: z.array(componentsSchema),
  promoBannerStyle: z.string(),
  footer: z.object({
    contentful_id: z.string()
  })
})

export const partnerWithUsPageContentSchema = z.object({
  slug: z.string(),
  breadcrumbTitle: z.string().nullish(),
  layout: partnerWithUsLayoutSchema
})
