import { logError } from '@ecomm/error-handling'
import {
  BrazeChildComponents,
  BrazeLineItem,
  brazeLogCustomEvent,
  setFirstName,
  setLastName,
  setPhoneNumber
} from '@ecomm/tracking'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { subspaceBrazeRequest } from '@simplisafe/ss-ecomm-data/leads/subspace'
import { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'

import { getCommerceDataFromLineItems } from '../../util/analytics'

/**
 * Format cart/checkout products to send them to Braze
 */

export const getLastCheckoutComponents = (
  cart: ImmutableCart,
  locale: string
) => {
  const items = getCommerceDataFromLineItems('checkout')(cart?.lineItems)

  // remove products without variants or brands
  const commerceProducts = items.checkout.products.filter(
    product =>
      Array.isArray(product.variant) || typeof product.brand === 'undefined'
  )

  // creates an array of objects with name, quantity, and package components if available
  const brazeCartProducts: readonly BrazeLineItem[] = commerceProducts.map(
    product => {
      if (!Array.isArray(product.variant)) {
        return {
          name: product.name,
          quantity: product.quantity,
          sku: product.id
        }
      }

      // If there are variants, reduce the variants to an object of package components
      const packageComponents: BrazeChildComponents = product.variant.map(
        childProduct => ({
          name: childProduct?.name[locale],
          quantity: childProduct?.quantity?.toString()
        })
      )

      return {
        name: product?.name,
        quantity: product?.quantity,
        sku: product.id,
        packageComponents
      }
    }
  )

  return brazeCartProducts
}

type UserData = {
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly leadId?: string
  readonly externalId: string
  readonly phone: string
}

/**
 * Send data thought cart abandon custom event
 * Updates the Braze profile first name
 * Updates the Braze profile last name
 * Updates the Braze profile phone number
 * @private
 */
export const setCustomBrazeProfileData = ({
  email,
  firstName,
  lastName,
  leadId,
  externalId,
  phone
}: UserData) => {
  brazeLogCustomEvent('cart_abandon', { email, leadId, externalId, phone })
  setFirstName(firstName)
  setLastName(lastName)
  setPhoneNumber(phone)
}

/**
 * Send last cart/checkout components to braze as custom attribute
 * @private
 */
export const sendCheckoutComponentsToBraze =
  (cart: RemoteData<ImmutableCart>, locale: string) => (user: UserData) =>
    cart.forEach(_cart => {
      const lastCheckoutComponents = getLastCheckoutComponents(_cart, locale)

      return subspaceBrazeRequest({
        attributes: {
          last_checkout_cart: lastCheckoutComponents
        },
        events: [],
        locale: locale === 'en-US' ? 'en-US' : 'en-GB',
        userData: {
          emailAddress: user.email,
          externalId: user.externalId
        }
      })(err => logError(err))(() => null)
    })

/**
 * Combines all the data that we need to sent to Braze
 */
export const sendDataToBrazeOnAbandonCheckout =
  (cart: RemoteData<ImmutableCart>, locale: string) => (user: UserData) => {
    // We should only add the phone number and the name for the US (because there we have the legal disclaimer)
    locale === 'en-US' && setCustomBrazeProfileData(user)
    // sending this for both (US & UK)
    sendCheckoutComponentsToBraze(cart, locale)(user)
  }
