import React from 'react'

type TierThreeProps = {
  readonly children: React.ReactNode
}

/**
 * Tier three (disclaimer) text styles.
 *
 * Example: "on any new system with Fast Protect™ Monitoring".
 *
 * Accounts for the typography styles on mobile/tablet bottom banner and desktop
 * top banner. Mobile starts with medium (500) weight at 14px, tablet starts
 * at 18px, and desktop uses normal (400) weight at 12px.
 */
export function TierThree({ children }: TierThreeProps) {
  return (
    <div
      className="text-[14px] font-medium leading-[18px] md:text-[18px] md:leading-[20px] lg:text-[12px] lg:font-normal lg:leading-[18px]"
      data-component="TierThree"
    >
      {children}
    </div>
  )
}
