import { Maybe } from '@simplisafe/ewok'
import { useEffect, useState } from 'react'

/**
 * @deprecated Use `ScriptStatus` from `@ecomm/shared-hook` instead
 */
export type Status = 'error' | 'loading' | 'ready'
export type ScriptElt = HTMLScriptElement | null

const dataAttr = 'data-status'

// Borrowed from 'usehooks-ts/src/useScript/useScript':
// https://github.com/juliencrn/usehooks-ts/blob/master/lib/src/useScript/useScript.ts

/**
 * @deprecated Use `useScript` from `@ecomm/shared-hook` instead
 */
export const useScript = (src: string): Status => {
  const [status, setStatus] = useState<Status>('loading')
  const [script, setScript] = useState<HTMLScriptElement>()

  useEffect(() => {
    // Fetch existing script element by src
    // It may have been added by another instance of this hook
    const existingScript: ScriptElt = document.querySelector(
      `script[src="${src}"]`
    )

    Maybe.fromNull(existingScript).cata(
      // If no existing script exists, create one.
      () => {
        const newScript = document.createElement('script')
        newScript.setAttribute('src', src)
        newScript.setAttribute('async', '')
        newScript.setAttribute(dataAttr, 'loading')

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          newScript.setAttribute(
            dataAttr,
            event.type === 'load' ? 'ready' : 'error'
          )
        }

        newScript.addEventListener('load', setAttributeFromEvent)
        newScript.addEventListener('error', setAttributeFromEvent)

        // Add script to document body
        document.body.appendChild(newScript)

        // Set the current script for this hook.
        setScript(newScript)
      },
      // Grab existing script status from attribute and set to state.
      (_existingScript: HTMLScriptElement) => {
        setScript(_existingScript)
        setStatus(<Status>_existingScript.getAttribute(dataAttr))
      }
    )

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error')
    }

    // Add event listeners
    script && script.addEventListener('load', setStateFromEvent)
    script && script.addEventListener('error', setStateFromEvent)

    // Remove event listeners on cleanup
    return () => {
      script && script.removeEventListener('load', setStateFromEvent)
      script && script.removeEventListener('error', setStateFromEvent)
    }
  }, [script, src])

  return status
}
