import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { schema } from './schema'
import { data } from './scoutPageData'

export const useScoutProductPageFAQsData = () => {
  const faqsData = useLocalizedData<typeof schema>(schema, data)

  return useMemo(() => faqsData, [faqsData])
}
