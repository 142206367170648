import { Script, ScriptStrategy } from 'gatsby'
import React from 'react'

const MENTION_ME_TEXT = 'Mention Me'

const ReferAFriendPlaceholder = React.memo(
  ({ type }: { readonly type: string }) => {
    const talkAbleElement = <div id="talkable-offer"></div>
    const mentionMe = <div id="mmWrapper"></div>

    return (
      <>
        <Script
          src={`/mention-me.js?id=${Math.floor(Math.random() * 10000 + 1)}`}
          strategy={ScriptStrategy.idle}
        />
        {type === MENTION_ME_TEXT ? mentionMe : talkAbleElement}
      </>
    )
  }
)

export default ReferAFriendPlaceholder
