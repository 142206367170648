import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.GlassDetection,
    text: '20 ft range'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 3 yrs'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.PeelAndStick,
    text: 'Peel-and-stick install'
  }
]

const description = {
  content: `For complete protection, add a Glassbreak sensor. It can detect the unique sound of broken glass from up to 20 feet, potentially alerting you instantly to forced intrusions.
  \nInstall it in a central area with as few obstructions as possible between the sensor and points of entry.`
}

const specs = {
  content: `2.5 x 2.5 x 1.25 inches
  \nOptional screw kit
  \n1 CR123A Lithium 3V battery (included)
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '5fYtHkWAAP0FnDQM1DHl0t'
}

export const glassbreakSensorData: ProductData = {
  productId: '4j11x6jdDrO0UuoBbT5j32',
  sensorName: 'Glassbreak Sensor',
  features,
  description,
  specs,
  faqs
}
