import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

type PageSectionProps = {
  readonly children: ReactNode
  readonly className?: string
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const PageSection: FC<PageSectionProps> = ({
  children,
  className,
  dataComponent = PageSection.name
}: PageSectionProps) => {
  return (
    <section
      className={classNames(
        'max-w-8xl mx-auto mt-8 first:mt-0 lg:mt-16 lg:px-7',
        className
      )}
      data-component={dataComponent}
    >
      {children}
    </section>
  )
}

export default PageSection
