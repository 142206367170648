import { trackClickStopCrimeCamera } from '@ecomm/cdp-tracking'
import { trackClickStopCrimeMonitoring } from '@ecomm/cdp-tracking'
import { ContentfulImage } from '@ecomm/contentful/components'
import { useTrackHomepage } from '@ecomm/tracking'
import { Link } from 'gatsby'
import React from 'react'

import type { CameraBannerAsset } from '../../data'

export type CameraBannerProps = {
  readonly image: CameraBannerAsset
  readonly title?: string | null
}

export function CameraBanner({ image, title = null }: CameraBannerProps) {
  const trackButtonClicks = useTrackHomepage()

  const mainTitle = title || 'The SimpliSafe Difference'
  const text =
    'With Fast Protect™ Monitoring and intruder intervention, SimpliSafe® agents can act on alarms within 5 seconds following the false alarm grace period. And only SimpliSafe® agents can see, speak to and deter intruders via the Smart Alarm indoor camera.'
  const buttonText = 'See why our monitoring is different'
  const link = 'Meet the camera that makes it possible'

  return (
    <div
      className="grid grid-cols-1 gap-5 rounded-2xl bg-gray-100 px-4 py-8 md:p-8 lg:grid-cols-2 lg:p-12"
      data-component="CameraBanner"
      data-testid="CameraBanner"
    >
      <div className="m-auto w-full">
        {image ? (
          <ContentfulImage
            {...image.cameraBannerAsset}
            className="rounded-2xl"
          />
        ) : null}
      </div>
      <div className="flex flex-col gap-4 md:mx-0 lg:order-first lg:max-w-[34rem]">
        <div className="bg-primary-100 w-fit rounded-full p-1 px-3 text-sm">
          ONLY FROM SIMPLISAFE
        </div>
        <h2 className="max-md:text-heading-3-size max-md:leading-h3-height my-1">
          {mainTitle}
        </h2>
        <p>{text}</p>
        <Link
          className="border-complementary-blue-150 text-complementary-blue-150 hover:text-btn-primary w-full rounded-md border-2 border-solid px-4 py-3 text-center no-underline duration-200 ease-in-out lg:w-fit"
          onClick={() => {
            trackButtonClicks('button-click-stop-crime-monitoring')
            trackClickStopCrimeMonitoring()
          }}
          to="/features-alarm-monitoring"
        >
          {buttonText}
        </Link>
        <Link
          className="text-complementary-blue-150 hover:text-btn-primary w-full text-center underline duration-200 ease-in-out lg:text-start"
          onClick={() => {
            trackButtonClicks('link-click-stop-crime-camera')
            trackClickStopCrimeCamera()
          }}
          to="/smart-alarm-wireless-indoor-camera"
        >
          {link}
        </Link>
      </div>
    </div>
  )
}
