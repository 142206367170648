import { ninetailedFeatureFlagSchema } from '@ecomm/promotions-components'
import { graphql, useStaticQuery } from 'gatsby'
import { parseObjectKey } from '@simplisafe/ewok'

export const usePromoBannerExperimentQuery = () => {
  const data = useStaticQuery(graphql`
      fragment miscFeatureFlagBase on ContentfulNinetailedFeatureFlag {
        id: contentful_id
        title
        json {
          id
          tag
        }
      }

      fragment miscFeatureFlagExperience on ContentfulNinetailedExperience {
        id: contentful_id
        name: nt_name
        type: nt_type
        audience: nt_audience {
          id: contentful_id
          name: nt_name
        }
        config: nt_config {
          components {
            baseline {
              id
            }
            variants {
              id
              hidden
            }
          }
          traffic
          distribution
        }
        variants: nt_variants {
          ... on ContentfulNinetailedFeatureFlag {
            ...miscFeatureFlagBase
          }
        }
      }
      query MiscPromoBannerExperiment {
        promoBannerExperiment: contentfulNinetailedFeatureFlag(
          contentful_id: { eq: "1GaRfPYfJQJuediSrCar1r" }
        ) {
          ...miscFeatureFlagBase
          nt_experiences {
            ...miscFeatureFlagExperience
          }
        }
      }
    `)
  return parseObjectKey(
    data,
    'promoBannerExperiment',
    ninetailedFeatureFlagSchema
  )
}
