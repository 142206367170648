import { prop } from '@simplisafe/ewok'
import { Script, ScriptStrategy } from 'gatsby'
import React from 'react'

import { ContentfulReferAFriendPlaceholder } from '../../../checkout.graphql'

type ReferAFriendPlaceholderComponentProps = {
  readonly data: Partial<ContentfulReferAFriendPlaceholder>
}

const MENTION_ME_TEXT = 'Mention Me'

const ReferAFriendPlaceholderComponent = React.memo(
  ({ data }: ReferAFriendPlaceholderComponentProps) => {
    const typeReferFriend = prop('type', data) || ''

    const talkAbleElement = <div id="talkable-offer"></div>
    const mentionMe = <div id="mmWrapper"></div>

    return (
      <>
        <Script
          src={`/mention-me.js?id=${Math.floor(Math.random() * 10000 + 1)}`}
          strategy={ScriptStrategy.idle}
        />
        {typeReferFriend === MENTION_ME_TEXT ? mentionMe : talkAbleElement}
      </>
    )
  }
)

export { ReferAFriendPlaceholderComponent }
