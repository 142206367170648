import { prop } from '@simplisafe/ewok'
import { liftSelectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Maybe } from 'monet'
import isEmpty from 'ramda/src/isEmpty'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

// Leftover code from Optimizely integration, to be removed in a future PR.
const useOptimizelyPricingFeature = () => O.none

export const usePriceVariations = (skus: ReadonlyArray<string>) => {
  const optimizelySDK = useOptimizelyPricingFeature()

  return useMemo(
    () =>
      pipe(
        optimizelySDK,
        O.chain(({ isFeatureEnabled, variables }) => {
          const priceVariations = skus
            .filter(sku => Boolean(variables[sku]))
            .reduce<Record<string, number>>(
              (accumulator, currentValue) => ({
                ...accumulator,
                [currentValue]: Number(variables[currentValue])
              }),
              {}
            )
          return isFeatureEnabled ? O.some(priceVariations) : O.none
        })
      ),
    [optimizelySDK, skus]
  )
}

/**
 * @deprecated
 * use PriceProvider instead
 */
export const usePriceVariation = (sku: string) => {
  const optimizelySDK = useOptimizelyPricingFeature()

  // Retrieve the product from redux, and conditionally override its price.
  const product = useSelector(
    liftSelectProduct(
      // Filter out empty strings.
      Maybe.of(sku).filter(x => !isEmpty(x))
    )
  )

  return useMemo(
    () =>
      pipe(
        optimizelySDK,
        O.match(
          () => product,
          ({ isFeatureEnabled, variables }) => {
            const optimizelyPrice = prop(sku, variables)
            const hasOptimizelyPrice = !!optimizelyPrice

            return isFeatureEnabled && hasOptimizelyPrice
              ? product.map(p => ({
                  ...p,
                  price: Number(optimizelyPrice)
                }))
              : product
          }
        )
      ),
    [optimizelySDK, product, sku]
  )
}

export default usePriceVariation
