import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

import {
  decrementItemQtyAtom,
  incrementItemQtyAtom
} from '../atoms/draftCart/itemQuantityAtom'
import { useItemQuantity } from './draftCart/useItemQuantity'

export default function useCartQuantity(sku: string, maxQuantity: number) {
  const increase = useSetAtom(incrementItemQtyAtom)
  const decrease = useSetAtom(decrementItemQtyAtom)
  const quantity = useItemQuantity(sku)

  const onDecrease = useCallback(() => {
    decrease(sku)
  }, [decrease, sku])

  const onIncrease = useCallback(() => {
    increase(sku, maxQuantity)
  }, [sku, increase, maxQuantity])

  return {
    onDecrease,
    onIncrease,
    quantity
  }
}
