import { logError } from '@ecomm/error-handling'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { useLocation } from '@reach/router'
import { window } from 'browser-monads-ts'
import { useContext, useEffect } from 'react'

const useScrollToHash = () => {
  const { hash } = useLocation()
  const o = useContext(OptimizelyContext)

  useEffect(() => {
    o.optimizely
      .onReady()
      .then(() => {
        const element =
          document.getElementById(hash?.replace('#', '')) || undefined
        function scrollToElement(element: HTMLElement) {
          const timer = setTimeout(
            () =>
              window.scrollTo({
                behavior: 'smooth',
                top: element.offsetTop
              }),
            0
          )
          return () => clearTimeout(timer)
        }

        return element && scrollToElement(element)
      })
      .catch(logError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useScrollToHash
