import classNames from 'classnames'

import type { ColorVariantSchema } from '../schema'
import { useUniqueId } from '@ecomm/shared-hooks'

export type Props = {
  readonly data: ColorVariantSchema
  readonly isSelected: boolean
  readonly onClick: () => void
  readonly name?: string
}

const bgColorOptions = (color: string, isSelected: boolean) => {
  return classNames(
    'p-0.5 mt-0 inline-block h-12 w-12 cursor-pointer appearance-none rounded-full bg-clip-content outline-offset-8',
    { 'bg-neutral-light-50': color === 'White' },
    { 'bg-neutral-light-50': color === 'Cloud' },
    { 'bg-neutral-black': color === 'Obsidian' },
    { 'shadow-[0_0_0_3px_var(--complementary-blue-100)]': isSelected }
  )
}

function ColorBody({ data, isSelected, onClick, name }: Props) {
  const color = data.productColor
  const inputId = useUniqueId('color-body-input')

  return (
    <div
      className={classNames(
        'prose md:prose-md lg:prose-lg whitespace-pre-line md:order-0 prose-p:!m-0 prose-p:text-sm md:prose-p:text-base flex items-center pl-1.5 text-left first:mt-0 md:mt-0'
      )}
      data-component="ColorBody"
    >
      <input
        checked={isSelected}
        className={bgColorOptions(color, isSelected)}
        id={inputId}
        name={name}
        onChange={onClick}
        type="radio"
        value={data.sku}
      />
      <label className="sr-only" htmlFor={inputId}>
        {color}
      </label>
    </div>
  )
}

export function ColorBodySlim({ data, isSelected, onClick, name }: Props) {
  const color = data.productColor
  const inputId = useUniqueId('color-body-slim-input')

  return (
    <div
      className={classNames(
        'prose md:prose-md lg:prose-lg whitespace-pre-line md:order-0 prose-p:!m-0 prose-p:text-sm md:prose-p:text-base flex items-center pl-1.5 text-left first:mt-0 md:mt-0'
      )}
      data-component="ColorBody"
      onClick={onClick}
    >
      <input
        checked={isSelected}
        className={classNames(
          bgColorOptions(color, isSelected),
          'border-neutral-light-100 mr-0 h-[18px] w-[18px] border-[1px] border-solid',
          {
            'shadow-[0_0_0_2px_var(--complementary-blue-100)]': isSelected
          }
        )}
        id={inputId}
        name={name}
        onChange={onClick}
        type="radio"
        value={data.sku}
      />
      <label className="cursor-pointer pl-2" htmlFor={inputId}>
        {color}
      </label>
    </div>
  )
}

export default ColorBody
