import { getCookie } from '@ecomm/shared-cookies'
import { getRudderstack } from '@ecomm/shared-window'
import { window } from 'browser-monads-ts'

export type WizardType =
  | 'embedded'
  | 'floating'
  | 'guarantee'
  | 'hero'
  | 'monitoring_component'
  | 'nav'
  | 'popup'

type QWAction = 'complete' | 'open' | 'start'
type QWDataLayerEventNames = 'quote-wizard-complete' | 'quote-wizard-start'

/**
 * Track quote wizard events.
 */
export function trackQuoteWizard({
  qw_type,
  qw_action
}: {
  readonly qw_type: WizardType
  readonly qw_action: QWAction
}) {
  getRudderstack(r => r.track('quote_wizard', { qw_type, qw_action }))
}

const dataLayerToQWAction: Record<QWDataLayerEventNames, QWAction> = {
  'quote-wizard-start': 'start',
  'quote-wizard-complete': 'complete'
}

export function listenToJebbitPostMessages() {
  const childOrigin = 'https://simplisafe.jebbit.com'
  const childOriginNew = 'https://quiz.simplisafe.com'
  const pollInterval = 400

  const postCallback = (event: any) => {
    if (event.origin !== childOrigin && event.origin !== childOriginNew) {
      return
    }

    if (
      event.data === 'childReady' &&
      event.source &&
      event.source.postMessage
    ) {
      // Send event that parent is ready
      try {
        event.source.postMessage('parentReady', event.origin)
      } catch (e) {
        console.error(e)
      }

      const poll = setInterval(() => {
        // Only proceed if GA loaded
        const gaClientId = getCookie('_ga')

        if (window.gaGlobal && gaClientId) {
          // Send message back to frame with Client ID
          try {
            event.source.postMessage(
              {
                event: 'clientId',
                clientId: gaClientId
              },
              event.origin
            )
          } catch (e) {
            console.error(e)
          } finally {
            clearInterval(poll)
          }
        }
      }, pollInterval)
    }

    if (
      event.data.event === 'quote-wizard-start' ||
      event.data.event === 'quote-wizard-complete'
    ) {
      const key: QWDataLayerEventNames = event.data.event
      trackQuoteWizard({
        qw_type: event.data.wizardType,
        qw_action: dataLayerToQWAction[key]
      })
    }
  }

  window.addEventListener('message', postCallback)
}
