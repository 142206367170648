import { TypeOf, z } from '@simplisafe/ewok'

import { AuthenticationType } from './types'

const authenticationSchema = z.object({
  type: z.nativeEnum(AuthenticationType)
})

export type AuthenticationSchema = TypeOf<typeof authenticationSchema>

export default authenticationSchema
