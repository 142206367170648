//@ts-nocheck
import classNames from 'classnames'
import isNil from 'ramda/src/isNil'
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import { Heading } from '..'
import { useMediaQuery } from '../hooks'
import { Info } from '../icons'
import Modal, { SSModalProps } from '../Modal'
import * as css from './IncludedItem.module.scss'

export type ItemProduct = {
  readonly dataComponent?: string
  /** When defined, outputs an Info icon that when clicked opens a modal. */
  readonly modalContent?: ReactNode
  // @deprecated: this prop is kept to for backwards compatibility and no effect on the component.
  readonly modalProps?: Partial<SSModalProps>
  readonly modalType?: 'icon' | 'link'
  readonly productName: string
  readonly outOfStockMessage?: ReactNode
  readonly onHoverStart?: () => void
  readonly onHoverEnd?: () => void
  readonly onClick?: () => void
  readonly isFreeItem?: boolean
}

export type IncludedItemProps = {
  readonly includedItemsTitle: string
  readonly includedItemsAccentedTitleText?: string
  readonly includedItems?: ReactNode
  /** The content of the component */
  readonly includedItemsLinkContent?: ReactNode
  readonly dataComponent?: string
  readonly numColumnsMobile?: number
  readonly numColumnsTablet?: number
  readonly numColumnsDesktop?: number
}

const renderIncludedItems = (includedItems?: ReactNode, columnCount?: number) =>
  includedItems && (
    <ul
      className={css.pdpDescrptionlist}
      style={{ gridTemplateColumns: `repeat(${columnCount}, 1fr)` }}
    >
      {includedItems}
    </ul>
  )

const renderIncludedItemsLinkContent = (
  includedItemsLinkContent: ReactNode
) => {
  return (
    <>
      {includedItemsLinkContent ? (
        <div className={css.editYourSystem}> {includedItemsLinkContent} </div>
      ) : null}
    </>
  )
}

/** @deprecated Do not use ss-react-components*/
export const IncludedItemProduct: FC<ItemProduct> = ({
  dataComponent = IncludedItemProduct.name,
  onHoverEnd,
  onHoverStart,
  onClick,
  modalContent,
  modalType = 'icon',
  outOfStockMessage,
  productName,
  isFreeItem = false
}: ItemProduct) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = () => {
    onClick && onClick()
    setIsModalOpen(true)
  }

  const isModalLink = !!modalContent && modalType === 'link'

  const handleHoverStart = useCallback(() => {
    setTimeout(() => onHoverStart && onHoverStart(), 100)
  }, [onHoverStart])

  const handleHoverEnd = useCallback(() => {
    setTimeout(() => onHoverEnd && onHoverEnd(), 100)
  }, [onHoverEnd])

  return (
    <li
      data-component={dataComponent}
      onBlur={handleHoverEnd}
      onFocus={handleHoverStart}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
    >
      {isModalLink ? (
        <button
          className={classNames('text-left', {
            [css.modalLink]: isModalLink,
            'text-neutral-black': !isFreeItem,
            'text-red-600': isFreeItem
          })}
          onClick={openModal}
        >
          {productName}
        </button>
      ) : (
        <span
          className={classNames({
            fontWeightMedium: !!modalContent,
            'text-red-600': isFreeItem
          })}
        >
          {productName}
        </span>
      )}
      {!isNil(outOfStockMessage) ? (
        <div className={classNames(css.pdpCaution)} tabIndex={0}>
          {outOfStockMessage}
        </div>
      ) : null}
      {modalContent && modalType === 'icon' ? (
        <button className={css.infoIcon} onClick={openModal}>
          <Info />
        </button>
      ) : null}
      {modalContent ? (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        >
          {modalContent}
        </Modal>
      ) : null}
    </li>
  )
}

// TODO: The name of this is inclear. What is the item? Where is it included?
/** @deprecated Do not use ss-react-components*/
const IncludedItem: FC<IncludedItemProps> = ({
  numColumnsMobile,
  numColumnsTablet,
  numColumnsDesktop,
  includedItemsAccentedTitleText,
  includedItemsTitle,
  includedItems,
  includedItemsLinkContent,
  dataComponent = IncludedItem.name
}: IncludedItemProps) => {
  const isTabletUp = useMediaQuery('TabletAndUp')
  const isDesktopUp = useMediaQuery('DesktopAndUp')
  const isMobile = !isTabletUp && !isDesktopUp
  const [columnCount, setColumnCount] = useState<number>()

  useEffect(() => {
    isDesktopUp && numColumnsDesktop
      ? setColumnCount(numColumnsDesktop)
      : isTabletUp && numColumnsTablet
        ? setColumnCount(numColumnsTablet)
        : isMobile && numColumnsMobile
          ? setColumnCount(numColumnsMobile)
          : setColumnCount(1)
  }, [
    isMobile,
    isTabletUp,
    isDesktopUp,
    numColumnsMobile,
    numColumnsTablet,
    numColumnsDesktop
  ])

  return (
    <div data-component={dataComponent}>
      <Heading
        className="prose-h2:font-normal"
        element="h2"
        headingAspect="h4"
        margin="tiny"
        useTailwind
      >
        {includedItemsTitle}
        {includedItemsAccentedTitleText ? (
          <span className="saleRedTextColor">
            {includedItemsAccentedTitleText}
          </span>
        ) : null}
      </Heading>
      {renderIncludedItems(includedItems, columnCount)}
      {renderIncludedItemsLinkContent(includedItemsLinkContent)}
    </div>
  )
}

export default IncludedItem
