import {
  AFFIRM_NINETAILED_CTFL_CONTROL_ID,
  AFFIRM_NINETAILED_CTFL_EXPERIENCE,
  AFFIRM_NINETAILED_CTFL_VARIANT_ID
} from '@ecomm/data-constants'
import { getLocale } from '@ecomm/utils'
import { useExperience } from '@ninetailed/experience.js-react'
import type {
  ExperienceConfiguration,
  Reference
} from '@ninetailed/experience.js-shared'
import { useMemo } from 'react'

export const useAffirmAPRExperimentLegacy = () => {
  const locale = getLocale()

  const baseline = {
    id: AFFIRM_NINETAILED_CTFL_CONTROL_ID
  }

  const experienceConfiguration: readonly ExperienceConfiguration<Reference>[] =
    [
      {
        components: [
          {
            baseline,
            variants: [
              {
                id: AFFIRM_NINETAILED_CTFL_VARIANT_ID
              }
            ]
          }
        ],
        distribution: [
          {
            index: 0,
            start: 0,
            end: 0.5
          },
          {
            index: 1,
            start: 0.5,
            end: 1
          }
        ],
        id: AFFIRM_NINETAILED_CTFL_EXPERIENCE,
        trafficAllocation: 1,
        type: 'nt_experiment'
      }
    ]

  const experience = useExperience<Reference, Reference>({
    baseline,
    experiences: [...experienceConfiguration]
  })

  return useMemo(
    () => locale === 'en-US' && experience.variantIndex === 1,
    [experience.variantIndex, locale]
  )
}
