import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import { useTracking } from 'react-tracking'

type WizardFinalProps = {
  readonly children?: ReactNode
  readonly className?: string
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const WizardFinal: FC<WizardFinalProps> = ({
  children,
  className,
  dataComponent = WizardFinal.name
}: WizardFinalProps) => {
  const { Track } = useTracking({ appSection: 'wizardFinal' })
  return (
    <Track>
      <div
        className={classNames('min-h-[240px] text-center ', className)}
        data-component={dataComponent}
      >
        {children}
      </div>
    </Track>
  )
}

export default WizardFinal
