// There is a simplified version of this component located at `libs/monitoring/components/src/AddPlanToCartButton`.
// It was created to address specific use cases with fewer dependencies and a more streamlined approach.
//
// TODO: Consider merging this component with the `AddPlanToCartButton` in a shared location to improve code reuse and maintenance.

import { LoadingSpinner } from '@ecomm/checkout-icons'
import { getCartId } from '@ecomm/data-storage'
import { logError } from '@ecomm/error-handling'
import { useMicroCopy } from '@ecomm/micro-copy'
import { getPartnerCookie } from '@ecomm/shared-cookies'
import {
  IOAddToCart,
  IOCreateOrSetPartnerAssociationCart
} from '@simplisafe/ss-ecomm-data/cart'
import * as O from 'fp-ts/lib/Option'
import { navigate } from 'gatsby'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useEcommerceProduct } from '../../templates/SensorPage/hooks'
import AddToCartError from './AddToCartError'
import { useAddToCartButtonTracking } from './hooks'

export type AddToCartButtonProps = {
  readonly sku: string
  readonly quantity?: number
  readonly className?: string
}

export type AddToCartErrorType = 'recoverable' | 'unrecoverable' | null

type AddToCartButtonState = {
  readonly showSpinner: boolean
  readonly addToCartError: AddToCartErrorType
}

/**
 *
 * Rewrite of the AddToCartButton component from apps/legacy/src/components/AddToCartButton/index.tsx
 */
export default function AddToCartButtonProduct({
  sku,
  quantity = 1,
  className
}: AddToCartButtonProps) {
  const dispatch = useDispatch()
  const { isOnStock, isSellable } = useEcommerceProduct(sku)

  const [state, setState] = useState<AddToCartButtonState>({
    showSpinner: true,
    addToCartError: null
  })

  const { trackSuccess } = useAddToCartButtonTracking(sku)

  const microCopy = useMicroCopy()

  const partnerData = getPartnerCookie()

  const addToCart = useCallback(() => {
    const handleSuccess = () => {
      setState({ ...state, showSpinner: false })
      trackSuccess(quantity)
      navigate('/cart')
    }

    const handleFailure = () =>
      setState({ ...state, showSpinner: false, addToCartError: 'recoverable' })

    O.fold(
      () => {
        setState({
          ...state,
          showSpinner: false,
          addToCartError: 'unrecoverable'
        })
        logError(Error('Cannot add to cart: received null/empty sku'))
      },
      (_sku: string) => {
        const product = {
          quantity,
          sku: _sku
        }

        dispatch(
          IOAddToCart({ products: [product] }, handleFailure, handleSuccess)
        )
        const cartId = getCartId() || ''
        partnerData?.partnerName &&
          dispatch(
            IOCreateOrSetPartnerAssociationCart(
              partnerData.partnerGroup,
              partnerData.partnerName,
              cartId,
              handleFailure
            )
          )
      }
    )(O.fromNullable(sku))
  }, [quantity, sku])

  useEffect(() => {
    sku && setState({ ...state, showSpinner: false })
  }, [sku])

  const buttonText = !isOnStock
    ? microCopy['out-of-stock']
    : microCopy['add-to-cart']
  const disableButton = state.showSpinner || !isOnStock || !isSellable

  return (
    <>
      <button
        className={`btn h-14 shrink-0 self-center text-base md:mr-4 md:text-lg lg:self-auto ${
          disableButton
            ? 'bg-neutral-medium-100 border-0 text-white'
            : 'btn-solid-primary'
        } ${className}`}
        disabled={disableButton}
        onClick={addToCart}
      >
        {state.showSpinner ? (
          <LoadingSpinner fillColor="white" size={24} />
        ) : (
          buttonText
        )}
      </button>
      {state.addToCartError ? (
        <div className="m-auto ml-2 max-w-[200px]">
          <AddToCartError
            errorType={state.addToCartError!}
            textAlign="center"
          />
        </div>
      ) : null}
    </>
  )
}
