import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './monitoringPageData'
import { schema } from './schema'

export const useMonitoringPageFAQsData = () => {
  const faqsData = useLocalizedData<typeof schema>(schema, data)

  return useMemo(() => faqsData, [faqsData])
}
