import {
  COOKIE_LEAD_DATA,
  getLeadData,
  useCookieChange
} from '@ecomm/shared-cookies'
import { useUniqueId } from '@ecomm/shared-hooks'
import { leadingSlashIt } from '@simplisafe/ewok'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import propOr from 'ramda/src/propOr'
import { type ReactNode, useState } from 'react'

import { FormField } from '../FormField'
import { FormTextInput } from '../FormTextInput'
import type { SimpliSafeCSSProperties } from '../types/SimpliSafeCSSProperties'
import { EmailFormSchema } from './schema'

type FormStyles = 'auto' | 'full' | 'split' | 'stack'

export type LeadCaptureFormPropsOnSubmit = (
  _email: string,
  _onFailure: (_errorText: string) => void
) => void

export type LeadCaptureFormProps = {
  readonly buttonStyles?: SimpliSafeCSSProperties
  /** Variant for submit and CTA buttons */
  readonly buttonVariant?: 'outlined' | 'solid'
  /** The link for the CTA button after form submission, e.g. "/home-security-shop" */
  readonly ctaButtonHref: string
  /** Text to show on CTA button after form submission, e.g. "shop now" */
  readonly ctaButtonText: string
  /** Whether to hide the email field label. Default: true */
  readonly hideLabel?: boolean
  /** If true, shows the success message instead of the form. Default: false */
  readonly isSubmitted?: boolean
  /** The email field label */
  readonly label: string
  /** Optional legal text to show below the email field */
  readonly legal?: ReactNode
  /** Function to call on form submission. The onFailure callback takes error text to be shown below the email field. */
  readonly onSubmit: LeadCaptureFormPropsOnSubmit
  /** Placeholder text for the email field */
  readonly placeholder?: string
  /** Whether to display a compact email input field. Default: true */
  readonly compactInput?: boolean
  /** Whether to show the CTA button after form submission. Default: true */
  readonly showCta?: boolean
  /** Whether to show the submit button next to the email input (full), split the width of the form and button more evenly on the same line (split),  below it (stack), or decide automatically based on screen size (auto). Default: auto */
  readonly style?: FormStyles
  /** Text to be shown on the email submit button */
  readonly submitButtonText: string
  /** Message to show after successful email submission */
  readonly successMessage: string
}

export function LeadCaptureForm({
  buttonStyles,
  buttonVariant = 'solid',
  hideLabel = true,
  isSubmitted = false,
  label,
  legal,
  onSubmit,
  compactInput = true,
  placeholder,
  showCta = true,
  style = 'auto',
  submitButtonText,
  ctaButtonHref,
  ctaButtonText,
  successMessage
}: LeadCaptureFormProps) {
  const fieldId = useUniqueId('LeadCaptureForm')
  const [defaultEmail, setDefaultEmail] = useState<string>(
    propOr('', 'email', getLeadData())
  )
  useCookieChange(COOKIE_LEAD_DATA, data => {
    setDefaultEmail(propOr('', 'email', JSON.parse(data)))
  })

  const submitButton = (
    <button
      className={classNames('h-10 !p-0 btn items-center w-full', {
        'btn-solid-primary': buttonVariant === 'solid',
        'btn-outlined btn-outlined-primary': buttonVariant === 'outlined'
      })}
      style={buttonStyles}
      type="submit"
    >
      {submitButtonText}
    </button>
  )

  return !isSubmitted ? (
    <Formik
      enableReinitialize={true}
      initialValues={{ email: defaultEmail }}
      onSubmit={(values, formikBag) => {
        onSubmit(values.email, errorText =>
          formikBag.setErrors({ email: errorText })
        )
      }}
      validationSchema={EmailFormSchema}
    >
      <Form data-component="LeadCaptureForm" data-style={style}>
        <FormField
          className={`${hideLabel ? '[&_label]:hidden [&_label]:sr-only' : ''}`}
          htmlFor="email"
          label={label}
          name="email"
        >
          <div className="text-inherit gap-4 grid grid-cols-12 w-full">
            <div
              className={classNames('self-end', {
                'col-span-12 md:col-span-8': style === 'auto',
                'col-span-8': style === 'full',
                'col-span-7': style === 'split',
                'col-span-12': style === 'stack'
              })}
            >
              <FormTextInput
                aria-label={label}
                id={fieldId}
                name="email"
                placeholder={placeholder}
                style={{
                  maxHeight: compactInput ? '40px' : 'initial',
                  width: '100%'
                }}
                type="email"
              />
            </div>
            <div
              className={classNames('self-end', {
                'col-span-12': style === 'auto' || style === 'stack',
                'col-span-4': style === 'full',
                'col-span-5': style === 'split',
                'md:col-span-4': style === 'auto'
              })}
            >
              <div data-component="SubmitButton">{submitButton}</div>
            </div>
          </div>
        </FormField>
        <div className="text-inherit gap-4 grid grid-cols-12 w-full">
          <div className="col-span-12">
            {legal ? (
              <div
                className="mt-1.5 text-inherit text-center md:text-left text-xs"
                data-component="legal-text"
              >
                {legal}
              </div>
            ) : null}
          </div>
        </div>
      </Form>
    </Formik>
  ) : (
    <div className="text-inherit grid grid-cols-12">
      <div className="col-span-12">
        {showCta ? (
          <a
            className={classNames('btn inline-block w-full', {
              'btn-solid-primary': buttonVariant === 'solid',
              'btn-outlined btn-outlined-primary': buttonVariant === 'outlined'
            })}
            href={leadingSlashIt(ctaButtonHref)}
          >
            {ctaButtonText}
          </a>
        ) : null}
        <div className="mt-1 text-inherit text-sm text-center">
          {successMessage}
        </div>
      </div>
    </div>
  )
}
