import { useLocale } from '@ecomm/data-hooks'
import {
  type GatsbyImageSchema,
  type GuidedSystemBuilderSchema,
  GatsbyImage,
  GuidedSystemBuilder
} from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import classNames from 'classnames'
import React from 'react'

import useOdmonVariant from '../ExperimentUtils/useOdmonVariant'

export type QuizBannerProps = {
  readonly quoteWizard: GuidedSystemBuilderSchema
  readonly image: GatsbyImageSchema
  readonly backgroundColor?: string
  readonly imageOdmon?: GatsbyImageSchema | null
  readonly title?: string
  readonly text?: string
}

const DEFAULT_BG = 'bg-[#9AC3DF]'
const ODMON_DEFAULT_BG = 'bg-[#D2D2D2]'

export function QuizBanner({
  backgroundColor = DEFAULT_BG,
  image,
  imageOdmon = null,
  quoteWizard,
  title = 'Need help choosing?',
  text = 'We know everyone’s security needs are different. Answer a few questions and we’ll recommend a system that suits you.'
}: QuizBannerProps) {
  const locale = useLocale()
  const isOdmonVariant = useOdmonVariant()
  const bgIsDefaultColor = backgroundColor === DEFAULT_BG

  const bgColor =
    isOdmonVariant && imageOdmon && bgIsDefaultColor
      ? ODMON_DEFAULT_BG
      : backgroundColor

  return (
    <div
      className={classNames(
        'relative flex gap-10 overflow-hidden rounded-[20px] p-2 md:px-12 md:py-[34px] md:pb-0',
        bgColor
      )}
      data-component="QuizBanner"
      data-testid="QuizBanner"
    >
      <div className="absolute bottom-0 left-0 z-0 w-1/2">
        <GatsbyImage
          className="hidden w-full md:block"
          image={isOdmonVariant && imageOdmon ? imageOdmon : image}
        />
      </div>

      <div className="hidden md:block md:flex-1 z-10">
        <h2 className="mb-4 mt-4 text-5xl font-bold text-[#0F2648] lg:text-[56px] lg:leading-[60px]">
          {title}
        </h2>
        <p className="text-lg text-neutral-black">{text}</p>
      </div>

      <div className="w-full overflow-hidden rounded-[20px] md:mb-[34px] md:flex-1">
        {locale === 'en-US' ? (
          <Experience
            {...quoteWizard}
            component={GuidedSystemBuilder}
            experiences={mapExperiences(quoteWizard.nt_experiences)}
            key={quoteWizard.id}
          />
        ) : (
          <GuidedSystemBuilder {...quoteWizard} />
        )}
      </div>
    </div>
  )
}
