import { TypeOf, z } from '@simplisafe/ewok'

const contentfulRichTextReferenceSchema = z.object({
  __typename: z.string(),
  contentful_id: z.string()
})

export const contentfulRichTextSchema = z.object({
  raw: z.string().optional(),
  references: z.array(contentfulRichTextReferenceSchema).optional()
})

export type ContentfulRichTextFragment = TypeOf<typeof contentfulRichTextSchema>
