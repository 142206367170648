import { useScript } from '@ecomm/shared-hooks'
import { setSprinklrChatAppId } from '@ecomm/shared-window'
import { getLiveChatScriptUrl } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'

import { salesChatAppId } from '../chatAppId'
import { useLiveChatAvailability } from '../useLiveChatAvailability'
import { useLiveChatTracking } from '../useLiveChatTracking'

// The underlying embed code. Loads the Sprinklr LiveChat script to the document
// after defining its settings on the window object.
function LiveChatScript() {
  setSprinklrChatAppId(salesChatAppId)

  const status = useScript(getLiveChatScriptUrl(salesChatAppId))

  useLiveChatTracking(status)
  return null
}

// Wrapper component to dynamically enable livechat according to Optimizely feature/experiment status and agent availability
/**
 * Enables Sprinklr livechat on page. Requires page to be whitelisted with Sprinklr. Hard-coded to sales chat experience.
 */
export function LiveChat() {
  const enabled = useLiveChatAvailability()

  return enabled ? <LiveChatScript /> : null
}
