//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import { IHouseViewDevice } from './data'
import * as css from './HowItWorksHouse.module.scss'

/**
 * Defines a device within a house view.
 *
 * Used for creating each point in the house view that will be highlighted
 * when a device is active.
 */
export type HouseViewDeviceProps = IHouseViewDevice & {
  readonly isActive?: boolean
  readonly dataComponent?: string
}

const HouseViewDevice: FC<HouseViewDeviceProps> = ({
  deviceName,
  isActive,
  locationName,
  dataComponent = HouseViewDevice.name
}: HouseViewDeviceProps) => {
  return (
    <div
      className={classNames(
        css.houseDevice,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        css[deviceName],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        css[locationName],
        { [css.isActive]: isActive }
      )}
      data-component={dataComponent}
    >
      <span className={css.houseDeviceCircle}></span>
      <span className={css.houseDeviceLabel}>{deviceName}</span>
    </div>
  )
}

export default HouseViewDevice
