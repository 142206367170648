import { trackProductRemoved } from '@ecomm/cdp-tracking'
import { createProductAddedRemovedPayload } from '@ecomm/cdp-tracking-utils'
import { brazeTrackCartEvent } from '@ecomm/tracking'
import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { getCommerceDataFromLineItem, TrackEvent } from '.'

const GTM_REMOVE_CART_EVENT = 'eec.remove'
const GTM_REMOVE_CART_CATEGORY = 'eec'
const GTM_REMOVE_CART_ACTION = 'removeFromCart'

export const trackRemoveCartEvent = (
  lineItem: LineItem,
  trackEvent: TrackEvent,
  lineItems: readonly LineItem[],
  coupon: string,
  cartId: string,
  quantity: number
) => {
  const quantityAdjustedLineItem = {
    ...lineItem,
    quantity: quantity || lineItem.quantity
  }

  const ecomm = getCommerceDataFromLineItem('remove')(lineItems)(
    quantityAdjustedLineItem
  )

  trackEvent({
    ecommerce: ecomm,
    event: 'removeFromCart'
  })

  trackEvent({
    ecommerce: ecomm,
    event: GTM_REMOVE_CART_EVENT,
    eventAction: GTM_REMOVE_CART_ACTION,
    eventCategory: GTM_REMOVE_CART_CATEGORY
  })

  brazeTrackCartEvent({
    eventType: 'remove_from_cart',
    productTrackingData: ecomm
  })

  const productRemovedPayload = createProductAddedRemovedPayload(
    lineItem,
    coupon,
    cartId
  )
  trackProductRemoved(productRemovedPayload)
}
