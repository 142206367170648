export default function Input({
  label,
  errorMessage,
  onChange
}: {
  readonly label: string
  readonly errorMessage: string
  readonly onChange: React.ChangeEventHandler<HTMLInputElement>
}) {
  return (
    <div className="flex-1">
      <label
        className="breakLine ssProse fontWeightMedium neutralBlackTextColor text-left"
        htmlFor="email"
      >
        {label}
      </label>
      <input
        className={`placeholder:text-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size rounded-base h-14 w-full border border-solid p-4 ${
          errorMessage ? 'border-sale' : 'border-neutral-medium-100'
        }`}
        id="email"
        name="email"
        onChange={onChange}
        placeholder="Enter email"
      />
      {errorMessage ? (
        <div className="text-sale mt-1">{errorMessage}</div>
      ) : null}
    </div>
  )
}
