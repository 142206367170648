import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { contentfulImageSchema } from '@ecomm/contentful/components'
import { TypeOf, z } from '@simplisafe/ewok'

const termsAndConditionsModalSchema = z.object({
  __typename: z.literal('ContentfulModal'),
  contentful_id: z.string(),
  description: contentfulRichTextSchema,
  modalTrigger: z.object({
    text: z.string()
  })
})

const fieldsSchema = z.object({
  label: z.string(),
  name: z.string(),
  placeholder: z.string().optional(),
  required: z.boolean(),
  type: z.enum(['Text', 'Text Area', 'Email', 'Dropdown']),
  options: z.array(z.string()).optional()
})

const captureSchema = fieldsSchema.extend({
  name: z.enum(['memberNumber', 'lastName', 'fullName', 'idNumber']),
  type: z.enum(['Text', 'Dropdown'])
})

const partnerCaptureSchema = z.object({
  leftSide: z.object({ text: contentfulRichTextSchema }),
  rightSide: z.object({
    modalTrigger: z.object({
      text: z.string()
    }),
    headline: z.string().optional(),
    disclaimer: z
      .object({
        raw: z.string(),
        references: z.array(termsAndConditionsModalSchema)
      })
      .nullable(),
    image: contentfulImageSchema,
    form: z.object({
      __typename: z.literal('ContentfulFormV2'),
      contentful_id: z.string().optional(),
      fields: z.array(captureSchema),
      button: z.object({
        text: z.string(),
        type: z.union([z.literal('Primary'), z.literal('Primary Outline')])
      }),
      cancelButton: z
        .object({
          text: z.string(),
          type: z.union([z.literal('Primary'), z.literal('Primary Outline')])
        })
        .nullable(),
      successMessage: contentfulRichTextSchema.optional(),
      submittedMessage: z.string()
    }),
    dismissText: z.string().optional()
  })
})

const partnerCaptureRewardValueSchema = z.object({
  content: z.object({
    internal: z.object({
      content: z.string()
    })
  })
})

export const partnerCampaignSchema = z.object({
  partnerName: z.string(),
  partnerCapture: partnerCaptureSchema,
  partnerCaptureRewardValue: partnerCaptureRewardValueSchema,
  partnerCaptureMandatory: z.boolean().default(false)
})

const partnerRewardValueSchema = z.object({
  default: z.number(),
  partnerGroups: z.object({
    airlines: z.object({
      default: z.number(),
      united: z.number()
    })
  })
})

export const allContentfulPartnerCampaignSchema = z.object({
  allContentfulPartnerCampaign: z.object({
    nodes: z.array(partnerCampaignSchema)
  })
})

export const partnerMembershipFormSchema = z.object({
  memberNumber: z.string(),
  lastName: z.string()
})

export type Fields = TypeOf<typeof captureSchema>
export type PartnerCampaign = TypeOf<typeof partnerCampaignSchema>
export type PartnerMembershipForm = TypeOf<typeof partnerMembershipFormSchema>
export type PartnerRewardValue = TypeOf<typeof partnerRewardValueSchema>
export type PartnerCaptureRewardValue = TypeOf<
  typeof partnerCaptureRewardValueSchema
>
