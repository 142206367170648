import { useProduct } from '@ecomm/data-products'

const emptyProductProps = {
  isOnStock: true,
  isSellable: true,
  type: ''
}

export const useEcommerceProduct = (sku: string) => {
  const selectedProduct = useProduct(sku)

  const productProps = selectedProduct.cata(
    () => emptyProductProps,
    prod => ({
      isOnStock: prod.isOnStock,
      isSellable: prod.isSellable,
      type: prod.productType
    })
  )

  return productProps
}
