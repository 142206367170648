import { useLocale } from '@ecomm/data-hooks'
import classNames from 'classnames'
import React from 'react'

export type Props = {
  readonly maxQuantity: number
  readonly minQuantity?: number
  readonly onDecrease: () => void
  readonly onIncrease: () => void
  readonly quantity: number
}

function DraftCartQuantitySelector({
  maxQuantity,
  minQuantity = 0,
  onDecrease,
  onIncrease,
  quantity
}: Props) {
  const isUs = useLocale() === 'en-US'
  return (
    <div className="mr-2 flex flex-nowrap rounded-md">
      <button
        aria-label="Click here to decrease the quantity"
        className={classNames(
          'mr-1 w-6 cursor-pointer rounded-md border-0',
          isUs ? 'bg-gray' : 'bg-white'
        )}
        data-testid="draft-quantity-decrease-button"
        disabled={quantity <= minQuantity}
        onClick={onDecrease}
      >
        -
      </button>
      <span className="w-4 text-center" inputMode="numeric" role="textbox">
        {quantity}
      </span>
      <button
        aria-label="Click here to increase the quantity"
        className={classNames(
          'ml-1 w-6 cursor-pointer rounded-md border-0',
          isUs ? 'bg-gray' : 'bg-white'
        )}
        data-testid="draft-quantity-increase-button"
        disabled={quantity >= maxQuantity}
        onClick={onIncrease}
      >
        +
      </button>
    </div>
  )
}

export default DraftCartQuantitySelector
