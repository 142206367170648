// Braze need a proper format for SMS group messages
export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode = process.env['LOCALE'] === 'en-GB' ? '44' : '1'
) =>
  phoneNumber &&
  !phoneNumber.startsWith('+') &&
  !phoneNumber.startsWith(`${countryCode}`)
    ? `+${countryCode}${phoneNumber}`
    : phoneNumber
