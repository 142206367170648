import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'

import { Locale } from '../../hooks/useLocalizedData'

const baseSchema = z.object({
  description1: contentfulRichTextSchema,
  description2: contentfulRichTextSchema,
  image1: gatsbyImageSchema.optional(),
  image2: gatsbyImageSchema.optional()
})

export const schema = {
  [Locale.EnGB]: baseSchema,
  [Locale.EnUS]: baseSchema
}
