import { useLocale } from '@ecomm/data-hooks'
import {
  formatPrice as priceProviderFormatPrice,
  priceDefault
} from '@ecomm/data-price'
import { Locale } from '@simplisafe/ewok'
import { DynamicDiscount } from '@simplisafe/ss-ecomm-data/prices/service'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyArray'
import { useMemo } from 'react'

import { BannerSectionSchema } from '../BuyMoreSaveMore/SavingsBanner/schema'

const formatThresholdPrice = (locale: Locale) => (num: number) =>
  pipe(
    O.of(num),
    priceProviderFormatPrice(priceDefault, locale, true),
    O.toUndefined
  )

export const useDynamicTextData = (
  dynamicDiscounts: readonly DynamicDiscount[]
): readonly BannerSectionSchema[] => {
  const locale = useLocale()
  const formatPrice = formatThresholdPrice(locale)

  return useMemo(() => {
    return pipe(
      dynamicDiscounts,
      A.reduceWithIndex(
        [],
        (
          index,
          acc: readonly BannerSectionSchema[],
          {
            discountType,
            value,
            threshold: { lowerThreshold: min, upperThreshold: max }
          }
        ) => [
          ...acc,
          {
            title:
              discountType === 'relative'
                ? `SAVE ${value}%`
                : `Get ${formatPrice(value)} off`,
            disclaimer:
              index === 0
                ? `with minimum order of ${formatPrice(min)}`
                : index === dynamicDiscounts.length - 1
                  ? `with order ${formatPrice(min)} or more`
                  : `with order ${formatPrice(min)}-${formatPrice(max)}`
          }
        ]
      )
    )
  }, [dynamicDiscounts, formatPrice])
}
