function FreeShipping({
  copy,
  cost,
  disclaimer
}: {
  copy: string
  cost: string
  disclaimer?: string
}) {
  return (
    <div className="mt-0 w-full py-2.5 pt-1.5 md:pb-0 md:pt-3">
      <div className="flex justify-between md:justify-start">
        <span className="text-base md:text-lg">{copy}</span>
        <div className="bg-neutral-light-100 mx-4 mb-1.5 hidden h-px flex-1 self-end md:flex"></div>
        <span className="text-base font-normal md:text-lg md:font-medium">
          {cost}
        </span>
      </div>
      {disclaimer ? (
        <div className="flex w-7/12 items-center justify-start md:w-9/12">
          <p className="md:text-md text-neutral-medium-120 mb-1 mt-0 text-xs md:text-base">
            {disclaimer}
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default FreeShipping
