//@ts-nocheck
import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { ButtonWithIcon } from '..'
import { Column, Row } from '..'
import { Status } from '../ButtonWithIcon'
import * as css from './ContentSelector.module.scss'

export type ContentSelectorProps = {
  readonly description?: ReactNode
  readonly buttonGroupTitle: string

  readonly firstButtonText: string
  readonly firstButtonIcon: ReactNode
  /** Content to show when click on first button */
  readonly firstContent: ReactNode

  readonly secondButtonText: string
  readonly secondButtonIcon: ReactNode

  /** Content to show when click on second button */
  readonly secondContent: ReactNode
  readonly dataComponent?: string
}

enum SelectedContent {
  none = 'none',
  firstContent = 'firstContent',
  secondContent = 'secondContent'
}

/** @deprecated Do not use ss-react-components*/
const ContentSelector: React.FC<ContentSelectorProps> = ({
  description,
  buttonGroupTitle,
  firstButtonText,
  firstButtonIcon,
  secondButtonText,
  secondButtonIcon,
  firstContent,
  secondContent,
  dataComponent = ContentSelector.name
}: ContentSelectorProps) => {
  const [selected, setSelected] = React.useState<SelectedContent>(
    SelectedContent.none
  )

  const getButtonStatus = (button: SelectedContent) => {
    return selected === SelectedContent.none
      ? Status.DEFAULT
      : selected === button
        ? Status.ACTIVE
        : Status.DISABLED
  }

  const ButtonRow = () => (
    <Row alignItems={'center'}>
      <Column alignSelf={'center'} spans={[12, 6, 6]}>
        <ButtonWithIcon
          className="rounded-base"
          icon={firstButtonIcon}
          onClick={() => setSelected(SelectedContent.firstContent)}
          status={getButtonStatus(SelectedContent.firstContent)}
          text={firstButtonText}
        />
      </Column>
      <Column alignSelf={'center'} spans={[12, 6, 6]}>
        <ButtonWithIcon
          className="rounded-base"
          icon={secondButtonIcon}
          onClick={() => setSelected(SelectedContent.secondContent)}
          status={getButtonStatus(SelectedContent.secondContent)}
          text={secondButtonText}
        />
      </Column>
    </Row>
  )

  return (
    <>
      <div className={css.contentSelectorHeader} data-component={dataComponent}>
        <Row height={'full'} padding={'none'}>
          <Column key="description" spans={[12, 6, 6]}>
            {description}
          </Column>
          <Column key="buttons" spans={[12, 6, 6]}>
            <div
              className={classNames(
                'bg-neutral-light-50 m-auto items-center justify-center',
                css.secondColumn
              )}
            >
              <h4 className="h4 m-auto mb-6 p-0 text-center">
                {buttonGroupTitle}
              </h4>
              <ButtonRow />
            </div>
          </Column>
        </Row>
      </div>
      {selected !== SelectedContent.none && (
        <>
          {selected === SelectedContent.firstContent && firstContent}
          {selected === SelectedContent.secondContent && secondContent}
        </>
      )}
    </>
  )
}

export default ContentSelector
