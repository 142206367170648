import React from 'react'

import { ScoutVideo } from '../../'
import { MonitoringPageVideoWrapperSchema } from './schema'

export function MonitoringPageVideoWrapper({
  data
}: {
  readonly data: MonitoringPageVideoWrapperSchema
}) {
  return (
    <div data-component="MonitoringPageVideoWrapper">
      <div className="text-center">
        <h2 className="mb-4 md:mb-6">{data.headline}</h2>
      </div>
      <div className="mx-2 mb-2 text-center md:mx-4 md:mb-4 lg:mb-8">
        {data.body}
      </div>
      <ScoutVideo
        className="h-auto"
        containerClassName="mx-auto max-w-5xl"
        type="AlarmMonitoringScoutVideo"
      />
    </div>
  )
}
