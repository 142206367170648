import { trackContinueToCheckout } from '@ecomm/cdp-tracking'
import { useLocale } from '@ecomm/data-hooks'
import { SSButton } from '@ecomm/ss-react-components'
import { SSButtonProps } from '@ecomm/ss-react-components/SSButton'
import { useTrackingCartContinueButton } from '@ecomm/tracking'
import classNames from 'classnames'
import React, { ReactElement, ReactNode } from 'react'

import { LinkHeaderProps } from '../CartContent'
import CartAffirmCartMessage from './CartAffirmCartMessage'

export type CartFooterProps = {
  readonly affirmPromoMessage: ReactNode | null
  readonly checkOutButtonFooter: SSButtonProps
  readonly creditCardLogo: readonly ReactElement[]
  readonly disabledCartSubmit: boolean
  readonly link: LinkHeaderProps
  /** Handler for clicking checkout link. Return true to follow link and return false to remain on current page. */
  readonly onClickContinueToCheckout?: () => boolean
  readonly errorMessage?: string
}

function CartFooter({
  affirmPromoMessage,
  creditCardLogo,
  checkOutButtonFooter,
  link,
  disabledCartSubmit,
  onClickContinueToCheckout = () => true,
  errorMessage
}: CartFooterProps) {
  const trackContinueButton = useTrackingCartContinueButton()
  const isUs = useLocale() === 'en-US'

  return (
    <div
      className={classNames(
        'order-none w-full text-right md:order-1  md:float-right md:ml-7 md:mt-4 md:w-5/12 lg:grid lg:w-full lg:grid-cols-[auto_max-content]',
        { 'lg:ml-0 lg:text-left': isUs }
      )}
      data-component="CartFooter"
    >
      <div
        className="mb-4 flex w-full cursor-pointer flex-col items-center md:items-end lg:order-1  lg:w-auto"
        data-component="CartButtonWrapperFooter"
        onClick={e => {
          const shouldContinue =
            !disabledCartSubmit && onClickContinueToCheckout()
          shouldContinue && trackContinueButton()
          shouldContinue && trackContinueToCheckout()
          !shouldContinue && e.preventDefault()
        }}
      >
        <SSButton
          {...checkOutButtonFooter}
          className="ml-0 w-full lg:w-auto"
          disabled={disabledCartSubmit}
          type="link"
        />
        {errorMessage ? (
          <div className="text:left text-sale my-4 text-base font-medium md:text-right">
            {errorMessage}
          </div>
        ) : null}
      </div>
      <div
        className="text-neutral-medium-120 mb-4 hover:underline md:!hidden"
        onClick={trackContinueButton}
      >
        <SSButton color="none" href={link.linkUrl} type="link">
          {' '}
          {link.linkText}{' '}
        </SSButton>
      </div>

      <div className="order-none">
        <div>
          <p className="mr-0 mt-0 inline-grid text-sm lg:mr-12 ">
            All orders come with our unconditional
            <b>60 Day Money-Back Guarantee</b>
          </p>
        </div>
      </div>
      <div
        className="order-3 col-start-2 col-end-3 my-3 flex justify-end"
        key={`credit_logo_card_${Math.random()}`}
      >
        {creditCardLogo}
      </div>
      {affirmPromoMessage && !isUs ? <CartAffirmCartMessage /> : null}
    </div>
  )
}

export default CartFooter
