import { useCartLineItems, useCartTotalAmount } from '@ecomm/data-cart'
import { AffirmMonthlyOption } from '@ecomm/shared-components'
import { getLocale } from '@ecomm/utils'
import { getPricesBeforeDiscount } from '@simplisafe/eis-commercetools-carts'
import { formatPrice } from '@simplisafe/eis-commercetools-shared'
import { window } from 'browser-monads-ts'

function Subtotal() {
  const locale = getLocale()

  const lineItems = useCartLineItems()

  const priceBeforeDiscount = getPricesBeforeDiscount(lineItems, locale)
  const priceAfterDiscount = useCartTotalAmount()

  return locale === 'en-US' ? (
    <AffirmMonthlyOption
      affirmClient={window.affirm}
      discountedPrice={priceAfterDiscount / 100}
      displaySubTotalText
      subTotalPrice={priceBeforeDiscount / 100}
      useSystemTermMonths={true}
    />
  ) : (
    <div className="bg-neutral-light-50 my-4 w-full rounded-base px-10 py-4">
      <h4 className="m-0 mb-2 text-right text-lg font-medium leading-none">
        Subtotal: {formatPrice(priceAfterDiscount, locale)}
      </h4>
      <p className="m-0 mb-2 text-right text-lg leading-none">
        (Including VAT)
      </p>
    </div>
  )
}

export default Subtotal
