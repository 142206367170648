export default function Checkbox({
  label,
  checked,
  onChange
}: {
  readonly label: string
  readonly checked: boolean
  readonly onChange: React.ChangeEventHandler<HTMLInputElement>
}) {
  return (
    <div className="flex flex-row-reverse justify-end">
      <label
        className="ml-3 cursor-pointer font-medium"
        htmlFor="marketingEmails"
      >
        {label}
      </label>
      <input
        checked={checked}
        className="h-5 w-5 cursor-pointer"
        id="marketingEmails"
        name="marketingEmails"
        onChange={onChange}
        type="checkbox"
      />
    </div>
  )
}
