import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { getCommerceDataFromLineItems, TrackEvent } from '.'

export const trackLoadCartEvent = (
  lineItems: readonly LineItem[],
  trackEvent: TrackEvent
) => {
  const ecommerce = {
    ...getCommerceDataFromLineItems('checkout')(lineItems),
    actionField: { step: 1 }
  }

  trackEvent({
    ecommerce,
    event: 'cartDetails'
  })

  trackEvent({
    ecommerce,
    event: 'eec.cart',
    eventAction: 'checkout',
    eventCategory: 'eec',
    eventLabel: 'cart'
  })
}
