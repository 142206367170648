import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { useLocale } from '@ecomm/data-hooks'
import { ContentfulRichText } from '@ecomm/shared-components'
import type { LineItem as LineItemType } from '@simplisafe/eis-commercetools-carts'
import { getPriceBeforeDiscount } from '@simplisafe/eis-commercetools-carts'
import { formatPrice } from '@simplisafe/eis-commercetools-shared'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { OutOfStockWarning } from '../OutOfStock/OutOfStockWarning'
import { useProductContext } from '../ProductContext'
import { DeleteButton } from './DeleteButton'
import { MaxAmountWarning } from './MaxAmountWarning'
import { MonitoringPlanDescription } from './MonitoringPlanDescription'
import { PackageItems } from './PackageItems'
import { QuantitySelector } from './QuantitySelector'
import { useBrandFromRedux } from './useBrandFromRedux'
import { useIsViewable } from './useIsViewable'
import { useName } from './useName'

export type Props = {
  readonly data: LineItemType & {
    readonly totalQuantityInCart: number
  }
}

const productDescriptionRenderOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="m-0 text-inherit">{children}</p>
    )
  }
}

function LineItem({ data }: Props) {
  const locale = useLocale()
  const { getPlan, getProduct } = useProductContext()

  const {
    name: name_,
    isGift,
    disabledQuantityUpdates,
    id,
    quantity,
    packageItems,
    packageParentId,
    pricesAfterDiscount,
    sku,
    isIndeterminatePrice,
    totalQuantityInCart,
    dynamicPackageMeta
  } = data

  const product = pipe(O.fromNullable(sku), O.chain(getProduct))

  const productMaxQuantity = pipe(
    product,
    O.map(product => product.maxQuantity),
    O.toUndefined
  )

  const productDescription = pipe(
    product,
    O.map(product => product.cartDescription),
    O.toUndefined
  )

  const price = pipe(
    O.fromNullable(getPriceBeforeDiscount(data, locale)),
    O.map(p => formatPrice(p, locale)),
    O.getOrElse(() => '')
  )

  // Retrieves the price per unit after discounts are applied,
  // used for analytics purposes.
  const priceAfterDiscount = pipe(
    O.fromNullable(pricesAfterDiscount[locale]),
    O.map(p => p.centAmount / (100 * quantity)),
    O.getOrElse(() => 0)
  )

  const name = useName(name_[locale], sku, dynamicPackageMeta)

  const monitoringPlan = pipe(
    O.fromNullable(sku),
    O.chain(getPlan),
    O.toNullable
  )

  // Populates brand on eec.cart.add or eec.cart.remove payloads
  const brand = useBrandFromRedux(sku, locale)

  const trackingData = {
    name,
    id: sku ?? '',
    price: priceAfterDiscount,
    brand
  }

  const isViewable = useIsViewable(sku)

  return (
    <div>
      {isViewable ? (
        <div
          className="mt-0 w-full py-2.5 pt-1.5 md:pb-0 md:pt-3 [&_a]:text-complementary-blue-100"
          data-component="LineItem"
        >
          <MaxAmountWarning
            productMaxQuantity={productMaxQuantity}
            sku={sku}
            totalQuantityInCart={totalQuantityInCart}
          />
          <div className="flex justify-between md:justify-start">
            <div className="flex items-end">
              <QuantitySelector
                disabled={disabledQuantityUpdates || isGift}
                id={id}
                maxQuantity={productMaxQuantity}
                quantity={quantity}
                tracking={trackingData}
              />
              <span className="pl-3 text-base md:text-lg">{name}</span>
            </div>
            <div className="bg-neutral-light-100 mx-4 mb-1.5 hidden h-px flex-1 self-end md:flex" />
            <div className="flex flex-row items-start md:justify-between">
              <span
                className={classNames(
                  'text-base font-normal md:text-lg md:font-medium',
                  { 'line-through': isGift }
                )}
              >
                {isIndeterminatePrice ? '--' : price}
              </span>
              {isGift ? (
                <span className="text-sale ml-5 text-right text-base font-normal md:ml-1 md:text-lg md:font-medium">
                  Free
                </span>
              ) : null}
              <DeleteButton
                id={id}
                tracking={{
                  ...trackingData,
                  quantity
                }}
              />
            </div>
          </div>
          <PackageItems items={packageItems} />
          {monitoringPlan?.cartDescription ? (
            <MonitoringPlanDescription
              description={monitoringPlan.cartDescription}
              parentId={packageParentId}
              sku={sku}
            />
          ) : null}
          {productDescription ? (
            <div className="text-neutral-medium-120 my-1 ml-20 w-7/12 text-xs md:ml-24 md:w-9/12 md:text-lg">
              <ContentfulRichText
                optionsCustom={productDescriptionRenderOptions}
                raw={productDescription.raw}
              />
            </div>
          ) : null}
          <OutOfStockWarning lineItem={data} />
        </div>
      ) : null}
    </div>
  )
}

export default LineItem
