import {
  useBannerInteractionState,
  useHandleFormSubmission
} from '@ecomm/promotions-hooks'

import { PromoBannerPageType } from '../../types'
import { MobileBannerBottom } from '../MobileBannerBottom'
import { MobileBannerTop } from '../MobileBannerTop'

type MobileBannerProps = {
  readonly type: PromoBannerPageType
}

export function MobileBanner({ type }: MobileBannerProps) {
  const isMidfunnel = type === 'pdp-plp'
  const { isFormSubmitted, handleEmailSubmit } = useHandleFormSubmission()
  const { isVisible, onCloseBanner, onOpenBanner, toggleBannerVisibility } =
    useBannerInteractionState()

  return (
    <div className="lg:hidden">
      {isMidfunnel ? (
        <>
          <MobileBannerTop
            isFormSubmitted={isFormSubmitted}
            onRedeemClick={onOpenBanner}
            type={type}
          />
          {isVisible ? (
            <MobileBannerBottom
              hasToggles={false}
              isFormSubmitted={isFormSubmitted}
              isVisible={isVisible}
              onCloseBanner={onCloseBanner}
              onEmailSubmit={handleEmailSubmit}
              toggleBannerVisibility={toggleBannerVisibility}
            />
          ) : null}
        </>
      ) : (
        <MobileBannerBottom
          hasToggles={true}
          isFormSubmitted={isFormSubmitted}
          isVisible={isVisible}
          onCloseBanner={onCloseBanner}
          onEmailSubmit={handleEmailSubmit}
          toggleBannerVisibility={toggleBannerVisibility}
        />
      )}
    </div>
  )
}
