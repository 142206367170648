import { exists, window } from 'browser-monads-ts'
import { useEffect, useLayoutEffect } from 'react'

/**
 * Returns `useEffect` on the server and `useLayoutEffect` client-side to avoid warning
 * messages at build time.
 *
 * **NOTE**: To avoid potential hydration issues, this hook should ONLY be used for truly
 * client-side behavior that has no impact on what gets rendered on the page.
 *
 * @see https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
 */
export const useIsomorphicLayoutEffect = exists(window)
  ? useLayoutEffect
  : useEffect
