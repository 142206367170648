import {
  useCurrentPromoBannerData,
  useSitewidePromoOfferTitle
} from '@ecomm/promotions-hooks'

import { EndDatePill } from '../../EndDatePill'
import { LeadCapture as LeadCaptureForm } from '../../LeadCaptureForm'
import { TierOne } from '../../TierOne'
import { TierThree } from '../../TierThree'
import { TierTwo } from '../../TierTwo'

/**
 * The default desktop banner layout.
 *
 * Intended for evergreen promotions/business as usual.
 * Renders when there is no salename or countdown enabled.
 * Supports end date, offer details, and lead capture form.
 */
export function LayoutDefault() {
  const { disclaimerText, giftLineItemText, offerLabel, overrideBannerText } =
    useCurrentPromoBannerData()
  const offerTitle = useSitewidePromoOfferTitle()

  const shouldRenderTierTwo = !!giftLineItemText && !!offerLabel

  // Upperase offer like 'X OFF' when its the only TierOne text and not override text.
  const offerUppercased = !overrideBannerText
    ? offerTitle.toUpperCase()
    : offerTitle
  const offerWithLabel = offerLabel
    ? `${offerTitle} ${offerLabel}`
    : offerUppercased
  const offerWithGift = giftLineItemText
    ? `${offerTitle} ${giftLineItemText}`
    : offerUppercased

  return (
    <div className="grid w-full max-w-[1300px] grid-flow-col grid-cols-12">
      <div className="col-span-8" data-component="LayoutDefault">
        <div className="">
          <div className="mb-1">
            <EndDatePill />
          </div>
          <TierOne>{!!offerLabel ? offerWithLabel : offerWithGift}</TierOne>
          {shouldRenderTierTwo ? <TierTwo>{giftLineItemText}</TierTwo> : null}
          <div className="pt-1">
            <TierThree>{disclaimerText}</TierThree>
          </div>
        </div>
      </div>
      <div className="col-span-4">
        <LeadCaptureForm />
      </div>
    </div>
  )
}
