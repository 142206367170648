import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import React from 'react'

type PackageCarouselSlideProps = {
  readonly image: GatsbyImageSchema
}

function PackageCarouselSlide({ image }: PackageCarouselSlideProps) {
  return (
    <GatsbyImage
      className="rounded-base prose-img:rounded-base h-full w-full"
      image={image}
    />
  )
}

export default PackageCarouselSlide
