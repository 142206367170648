import { ErrorMessage } from 'formik'
import React from 'react'

type FormFieldProps = {
  readonly label: string
  readonly name: string
  readonly children: React.ReactNode
  readonly className?: string
}

export function FormField({
  label,
  name,
  children,
  className = ''
}: FormFieldProps) {
  return (
    <div className={className}>
      <label className="font-medium" htmlFor={name}>
        {label}
      </label>
      {children}
      <ErrorMessage
        className="text-complementary-red-100"
        component="span"
        name={name}
      />
    </div>
  )
}
