import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click checkout progress bar to navigate to previous checkout step.
 */
export function trackCheckoutProgressSteps(clickText: string) {
  getRudderstack(r =>
    r.track('checkout_progress_steps', {
      click_text: clickText.toLowerCase()
    })
  )
}

/**
 * Track click on terms and conditions in checkout.
 */

export type TermsAndConditionsTypes =
  | 'privacy policy'
  | 'terms of sale'
  | 'terms of service'

export function trackTermsAndConditionsClick(label: TermsAndConditionsTypes) {
  getRudderstack(r => {
    r.track('terms_conditions', {
      label
    })
  })
}
