import { Locale } from '../../hooks/useLocalizedData'

const ukData = {
  description:
    '{ "nodeType": "document", "data": {}, "content": [ { "nodeType": "heading-3", "data": {}, "content": [ { "nodeType": "text", "value": "Your privacy is protected", "marks": [], "data": {} } ] }, { "nodeType": "unordered-list", "data": {}, "content": [ { "nodeType": "list-item", "data": {}, "content": [ { "nodeType": "paragraph", "data": {}, "content": [ { "nodeType": "text", "value": "The privacy shutter is closed by default, and opens with an audible sound.", "marks": [], "data": {} } ] } ] }, { "nodeType": "list-item", "data": {}, "content": [ { "nodeType": "paragraph", "data": {}, "content": [ { "nodeType": "text", "value": "Agents cannot access your camera or microphone, only recordings after an active alarm.", "marks": [], "data": {} } ] } ] }, { "nodeType": "list-item", "data": {}, "content": [ { "nodeType": "paragraph", "data": {}, "content": [ { "nodeType": "text", "value": "The camera feed data is encrypted.", "marks": [], "data": {} } ] } ] } ] }, { "nodeType": "paragraph", "data": {}, "content": [ { "nodeType": "text", "value": "", "marks": [], "data": {} } ] } ] }',
  image2Url:
    'https://images.ctfassets.net/v6awd0kabi65/5tt9nGw63EYABoxB8VeY8h/6795dcc5483a88d573dcb524861616f1/UK_DogAI.jpg'
}
const usData = {
  description:
    '{"nodeType":"document","data":{},"content":[{"nodeType":"heading-3","data":{},"content":[{"nodeType":"text","value":"Your privacy is protected","marks":[],"data":{}}]},{"nodeType":"unordered-list","data":{},"content":[{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The privacy shutter is closed by default, and opens with an audible sound.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Agents cannot access your camera or microphone except during an active alarm.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The camera feed data is encrypted.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/privacy"},"content":[{"nodeType":"text","value":"Read about our commitment to privacy","marks":[],"data":{}}]},{"nodeType":"text","value":"","marks":[],"data":{}}]}]}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
  image2Url:
    'https://images.ctfassets.net/br4ichkdqihc/2E0dQjgDSDRya4YrNFP0Kg/6e13855bf6612d8e08927622e57c969e/DogImg_2x.png'
}

export const createdata = ({
  description,
  image2Url
}: {
  readonly description: string
  readonly image2Url: string
}) => ({
  description1: {
    raw: description
  },
  description2: {
    raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"heading-3","data":{},"content":[{"nodeType":"text","value":"Fewer false alarms with advanced motion detection","marks":[],"data":{}}]},{"nodeType":"unordered-list","data":{},"content":[{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"AI distinguishes between humans and pets — and that means fewer false alarms.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"You can be more confident a threat is real (and not Rocky sniffing around for a toy).","marks":[],"data":{}}]}]}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}'
  },
  image1: {
    description:
      'Image of the Wireless Indoor Camera opening and closing its shutter.',
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet:
              'https://images.ctfassets.net/br4ichkdqihc/6OL6PrziVcwZj7OI7iQd2L/4c517fb88a50a4c918cc913a8ba0d33f/shutter-close-0123.webp?w=325&h=183&&fm=webp 325w,\nhttps://images.ctfassets.net/br4ichkdqihc/6OL6PrziVcwZj7OI7iQd2L/4c517fb88a50a4c918cc913a8ba0d33f/shutter-close-0123.webp?w=650&h=365&&fm=webp 650w,\nhttps://images.ctfassets.net/br4ichkdqihc/6OL6PrziVcwZj7OI7iQd2L/4c517fb88a50a4c918cc913a8ba0d33f/shutter-close-0123.webp?w=1300&h=730&&fm=webp 1300w',
            sizes: '(min-width: 1300px) 1300px, 100vw',
            type: 'image/webp'
          }
        ]
      },
      layout: 'constrained',
      width: 1300,
      height: 730,
      placeholder: {
        fallback:
          'data:image/webp;base64,UklGRrICAABXRUJQVlA4WAoAAAACAAAAEwAACgAAQU5JTQYAAAD/////AABBTk1GkAAAAAAAAAAAABMAAAoAAB4AAAJWUDggeAAAAHAEAJ0BKhQACwA+yVKiSyekoyGwCADwGQllAJ0AJ0V810lAwtd1zpRNZAkAAP7ys1G7xnv1QEFXNg0TGZWhhyXq+WhOQeha6byrrZ0zRW+QQFYn5RjLLDxs2nIoDZlj6GusuNxbJ04+3ZAyDWYv8TLoal2vomYAAEFOTUZCAAAAAAAABAAAAgAAAQAApAEAAFZQOCAqAAAAtAEAnQEqAwACAAAASiWMAANfEcUSAAD++KEuPZ3m8T9ypQse2TvEAAAAQU5NRkQAAAACAAABAAABAAABAABoAQAAVlA4ICwAAACUAQCdASoCAAIAAABKJZQAA18Q5CAA/upWo2hoEv2aUnGcXbypOL1oaQAAAEFOTUY+AAAABQAAAQAAAQAAAQAAWgAAAFZQOCAmAAAAtAEAnQEqAgACAAAASiWMAAM6G45HAAD+6nvi0fcn6Xvtk7xAAABBTk1GQAAAAAUAAAEAAAEAAAEAAEYFAABWUDggKAAAALQBAJ0BKgIAAgAAAEoljAADOnhI9WAA/vU/DPGf29yfrIPbJ3iAAABBTk1GPAAAAAUAAAEAAAEAAAEAAFoAAABWUDggJAAAAJQBAJ0BKgIAAgAAAEoljAADOj0fQAD+77b4v6D4yu+dj2uAAEFOTUY+AAAABQAAAQAAAQAAAQAAHAIAAFZQOCAmAAAAtAEAnQEqAgACAAAASiWMAAM6P8ccAAD+53e16/7k/S99sneIAABBTk1GQAAAAAIAAAEAAAEAAAEAAA4BAABWUDggKAAAALQBAJ0BKgIAAgAAAEollAADXxVhhQAA/u0oMuh+zCm3he4ppZLwAAA='
      }
    },
    title: ''
  },
  image2: {
    description:
      'Image of a dog being flagged as a pet during the advanced motion detection feature of the Wireless Indoor Camera.',
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet: `${image2Url}?w=311&h=199&q=50&fm=webp 311w,\n${image2Url}?w=622&h=397&q=50&fm=webp 622w,\n${image2Url}?w=1244&h=794&q=50&fm=webp 1244w`,
            sizes: '(min-width: 1244px) 1244px, 100vw',
            type: 'image/webp'
          }
        ],
        fallback: {
          src: `${image2Url}?w=1244&h=794&q=50&fm=png`,
          srcSet: `${image2Url}?w=311&h=199&q=50&fm=png 311w,\n${image2Url}?w=622&h=397&q=50&fm=png 622w,\n${image2Url}?w=1244&h=794&q=50&fm=png 1244w`,
          sizes: '(min-width: 1244px) 1244px, 100vw'
        }
      },
      layout: 'constrained',
      width: 1244,
      height: 794,
      placeholder: {
        fallback:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAMAAACejr5sAAAC/VBMVEUEBgcBAQMAAAEAAAAWGh92lat8nLKBoriEpLuerLvC2/BVQUwtKiuJk55AUVkxPC1LWlpgcntyhJcBAQEAAQIBAQICAwMOEBV0kqh/nbONp7yWrsGgr72/1fBNXXZUaoKPoJ+HlISAhneTko6jop6wr7QFBwgFBgYDAwMDAgESEBG0u8TCyNHCyM/Ax9Czu8SrtMtraXZPTlUvzi9T309dtUOom4+/s6fJv70TExMyMzRJR0hUTktXR0FYQTdjTUTAxs64v8WHzo6duaKOj4RhTChFMhWKkYJnh1JukDuHc2K0qJynmZJbRTxdPy9MKxpaMBhnOh5rQCNdNR6DhYSBiIajtaq4ub2lrcqOe3V+Y09pZGWWe2CafkmkdUmWdFiEdm5rNxZwPR1aMhtfNB1nOyNpPiVjOSMQCwgUFBBZY068ppaOjZlDQkVNUFc/P0apglfJol3BkmSFWzWIdGRfMBVhMxpZMhxkNx5nOiBrPiJlOyALBgQGBgRTVDa3mYiPgYiimJCFhYWUgnC9lmiqklihf12CYkJQOilhMxhiNRteMhlOJxNSLBRMKxRGKBc6JBFNNBuAbkCgi4Wloq2gm5inqKeWnZ6Zm5eUl3abgGSKa0eCXzlRJxBEIAw8HAhJJg1iPBx5Uix8UiiFWi2CWjB6cEyGgX+Vl5edoaGgqKmfqKmmrK2Yqp2hpaSel5CahXZhORhsPxiBUiCDUB2IWimAUyN9UiR8YEN+dGp4knqUm56ZoqOaoqSZo6qcpauao6aKqpaaoqWkqa2kpquUYjGOWSaOXSiHUx+BVyt+Z0+CfHWJioaTmJl3sICLrZWUq6GKpZmMpZuQpqCBsIl/sIudpaqsrrHPyMumbzqYZjeSdFaMgXSSkpCenZ2foaKfpaiiqaygqKyXn6SaoqeXoaaYoaiepambpKqgpqvEt7m0q7CtmoijoZ+jpKSkpKWoqqyrrKussLCnra+mrbGfp6qkq7GnrK6fp6ugqK6fqK2jrLOen6KplJS4oJNwTVaqAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAB3RJTUUH5wUeDgQdskeqkQAAAClJREFUGNNjZGBgxAAswm+FMUWZlbGoNGPEIigy6AUZIN5EU/WPmZERAEAFBEKkQbtaAAAAAElFTkSuQmCC'
      }
    },
    title: ''
  }
})

export const data = {
  [Locale.EnGB]: createdata(ukData),
  [Locale.EnUS]: createdata(usData)
}
