import React, { ReactNode } from 'react'

import { Accordion } from '../Accordion'
import { Collapsible } from '../Collapsible'
import { CollapsibleSection } from '../Collapsible/CollapsibleSection'

type FAQSectionProps = {
  readonly faq: {
    readonly primary: readonly {
      readonly question: { readonly question: string }
      readonly answer: {
        readonly raw: string
      }
    }[]
  }
  readonly title?: string
  readonly collapsible?: boolean
}

const dataComponent = 'FAQSection'

function Wrapper({
  collapsible,
  children,
  title
}: {
  readonly collapsible: boolean
  readonly children: ReactNode
  readonly title: string
}) {
  return collapsible ? (
    <Collapsible dataComponent={dataComponent} isOpen={false} title={title}>
      <CollapsibleSection>{children}</CollapsibleSection>
    </Collapsible>
  ) : (
    <section data-component={dataComponent}>
      <header>
        <h2 className="mb-4">{title}</h2>
      </header>
      {children}
    </section>
  )
}

export default function FAQSection({
  collapsible = false,
  faq,
  title = ''
}: FAQSectionProps) {
  return (
    <Wrapper collapsible={collapsible} title={title}>
      {faq.primary.map(({ answer, question: { question } }, index) => (
        <div className={'mb-4 md:mb-6'} key={`FAQ-${index}`}>
          <Accordion
            description={answer.raw}
            longTitle={question}
            shortTitle={question}
          />
        </div>
      ))}
    </Wrapper>
  )
}
