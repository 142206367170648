//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'

import * as css from './Header.module.scss'

type HeaderDropdownMultipleItemsProps = {
  readonly children: ReactElement | readonly ReactElement[]
  readonly position?: 'column' | 'row'
}

/** @deprecated Do not use ss-react-components*/
const HeaderDropdownMultipleItems: FC<HeaderDropdownMultipleItemsProps> = ({
  children,
  position = 'column'
}: HeaderDropdownMultipleItemsProps) => {
  return (
    <div
      className={classNames(
        css.headerDropdownMultipleItems,
        { [css.multipleItemsRow]: position === 'row' },
        { [css.multipleItemsColumn]: position === 'column' }
      )}
    >
      {children}
    </div>
  )
}

export default HeaderDropdownMultipleItems
