//@ts-nocheck
import classNames from 'classnames'
import range from 'ramda/src/range'
import React, { ChangeEvent, FC, useCallback, useState } from 'react'

import * as css from './QuantitySelect.module.scss'

export type QuantitySelectProps = {
  readonly className?: string
  readonly id?: string
  readonly label: string
  readonly onChange?: (event: ChangeEvent<HTMLSelectElement>) => unknown
  readonly max?: number
  readonly min?: number
  readonly selected?: number
  readonly dataComponent?: string
}

const renderOption = (n: number) => {
  return (
    <option key={`option-${n}`} value={n}>
      {n}
    </option>
  )
}

const QuantitySelect: FC<QuantitySelectProps> = ({
  className,
  id,
  label,
  onChange,
  max = 10,
  min = 0,
  selected,
  dataComponent = QuantitySelect.name
}: QuantitySelectProps) => {
  const [value, setValue] = useState(selected)
  const handleChange = useCallback(
    e => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setValue(e.target.value)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      onChange && onChange(e)
    },
    [setValue, onChange]
  )

  return (
    <select
      aria-label={label}
      className={classNames(css.select, className)}
      data-component={dataComponent}
      id={id}
      onChange={handleChange}
      value={value}
    >
      {range(min, max < min ? min + 1 : max + 1).map(x => renderOption(x))}
    </select>
  )
}

export default QuantitySelect
