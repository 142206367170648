import { contentfulImageSchema } from '@ecomm/contentful/components'
import { TypeOf, z } from '@simplisafe/ewok'

export const installationSmallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: z.object({
    raw: z.string(),
    references: contentfulImageSchema
      .extend({
        __typename: z.literal('ContentfulAsset'),
        contentful_id: z.string()
      })
      .array()
      .optional()
  })
})

export type InstallationSmallTextSchema = TypeOf<
  typeof installationSmallTextSchema
>
