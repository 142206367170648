import { gql } from '@apollo/client/index.js'

export const BLOG_PREVIEW_LIST_QUERY = gql`
  query BLOG_PREVIEW_LIST_QUERY(
    $preview: Boolean!
    $locale: String!
    $site: [String!]
    $date: DateTime
  ) {
    blogCollection(
      preview: $preview
      locale: $locale
      where: { site_contains_all: $site, sys: { publishedAt_lt: $date } }
      order: publishDate_DESC
    ) {
      items {
        sys {
          id
        }
        title
        publishDate
        site
        previewContent {
          json
          links {
            entries {
              inline {
                sys {
                  id
                }
              }
              hyperlink {
                sys {
                  id
                }
              }
              block {
                sys {
                  id
                }
              }
            }
          }
        }
        image {
          url
          description
          originalWidth: width
          originalHeight: height
        }
        slug
      }
    }
  }
`
