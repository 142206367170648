import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'
import { useState } from 'react'

import { InstallationCollapsible } from './Collapsible'
import { InstallationHowItWorksSectionSchema } from './schema'

export function InstallationHowItWorksSection({
  description,
  items
}: InstallationHowItWorksSectionSchema) {
  const [indexOpen, setIndexOpen] = useState(0)

  const handleOnToggle = (index: number) => {
    setIndexOpen(index)
  }

  return (
    <div className="prose-h2:text-center">
      <ContentfulRichText {...description} />
      <div className="bg-neutral-light-50 overflow-hidden items-center rounded-base grid grid-cols-2">
        <div className="block lg:col-span-1 order-1 lg:order-2 md:rounded-none col-span-2 h-full w-full grow overflow-hidden lg:h-[504px]">
          <ContentfulImage
            classNameOverride="w-full h-full"
            {...items[indexOpen].image}
          />
        </div>
        <div className="col-span-2 lg:col-span-1 order-2 lg:order-1 flex flex-col gap-1 rounded-b-base p-4">
          {items.map((item, i) => (
            <InstallationCollapsible
              className="first:rounded-t-base last:rounded-b-base"
              description={item.description}
              isOpen={i === indexOpen}
              key={`how-it-works-accordion-${i}`}
              onToggle={() => handleOnToggle(i)}
              title={item.headline}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
