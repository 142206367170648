import { CommercetoolsProduct } from '@ecomm/data-simplisafe-api'
import { addAtomDebugLabel } from '@ecomm/utils'
import * as O from 'fp-ts/lib/Option'
import { Map as ImmutableMap } from 'immutable'
import { atom } from 'jotai'

import { ContentfulProductTopic } from './productTopicSchema'

/**
 * Exported only for hydration purposes only in `WrapRootElement`.
 *
 * @deprecated You should create a template specific atom instead.
 */
export const productsAtom = atom<
  ImmutableMap<
    string,
    CommercetoolsProduct & { readonly topic: O.Option<ContentfulProductTopic> }
  >
>(
  ImmutableMap<
    string,
    CommercetoolsProduct & { readonly topic: O.Option<ContentfulProductTopic> }
  >()
)
addAtomDebugLabel(productsAtom, 'products')
