import { getLocale } from '@ecomm/utils'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import { transformObject } from '@simplisafe/ewok'
import type { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { flow } from 'fp-ts/lib/function'

export const getNameWithUSFallback = (item: {
  readonly sku: string
  readonly name: Record<string, string>
}): string => {
  const locale = getLocale()
  const usName: string = path(['name', 'en-US'], item)

  const localeName: string = path(['name', locale], item)

  return localeName || usName
}

export function prefixIncSkuNameWithIncludedText(item: {
  readonly sku: string
  readonly name: Record<string, string>
}) {
  return item && item.sku && item.sku.includes('-INC')
    ? {
        ...item,
        name: {
          ...Object.keys(item.name).reduce(
            (acc, i) => ({
              ...acc,
              [i]: `Included ${item.name[i]}`
            }),
            {}
          )
        }
      }
    : item
}

export const toSubItem = transformObject<
  LineItem & { readonly sku: string; readonly name: Record<string, string> },
  {
    readonly subItemName: string
    readonly subItemQuantity: number
    readonly subItemSku: string
  }
>({
  subItemName: flow(prefixIncSkuNameWithIncludedText, getNameWithUSFallback),
  subItemQuantity: prop('quantity'),
  subItemSku: prop('sku')
})
