import { PhoneRinging, PoliceCar } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React from 'react'

import FeatureModal from '../FeatureModal/FeatureModal'
import { FeatureModalProps } from '../FeatureModal/types'
import { FastProtectMonitoringCard } from './FastProtectMonitoringCard'
import { SelfMonitoringCard } from './SelfMonitoringCard'

type IconText =
  | 'fastFireAmbulanceDispatch'
  | 'fastPoliceDispatch'
  | 'hardwareDiscount'
  | 'instantAlarmAlerts'
  | 'lifetimeSubscriberWarranty'
  | 'professionalMonitoring'
  | 'unlimitedVideoRecording'
  | 'wholeHomeProtection'

const icons: Record<IconText, () => React.ReactNode> = {
  professionalMonitoring: () => (
    <ProfessionalMonitoringIcon className="h-[52px] w-[52px] shrink-0" />
  ),
  fastPoliceDispatch: () => (
    <FastPoliceDispatchIcon className="h-[52px] w-[52px] shrink-0" />
  ),
  wholeHomeProtection: () => (
    <WholeHomeProtectionIcon className="h-[52px] w-[52px] shrink-0" />
  ),
  unlimitedVideoRecording: () => (
    <UnlimitedVideoRecordingIcon className="h-[52px] w-[52px] shrink-0" />
  ),
  instantAlarmAlerts: () => (
    <InstantAlarmAlertsIcon className="h-[52px] w-[52px] shrink-0" />
  ),
  hardwareDiscount: () => (
    <HardwareDiscountIcon className="h-[52px] w-[52px] shrink-0" />
  ),
  lifetimeSubscriberWarranty: () => (
    <LifetimeSubscriberWarrantyIcon className="h-[52px] w-[52px] shrink-0" />
  ),
  fastFireAmbulanceDispatch: () => (
    <FastFireAmbulanceDispatchIcon className="h-[52px] w-[52px] shrink-0" />
  )
}

const texts: Record<IconText, string> = {
  professionalMonitoring: '24/7 live guard protection',
  fastPoliceDispatch: 'Faster police response*',
  fastFireAmbulanceDispatch: 'Faster fire & ambulance response*',
  wholeHomeProtection: 'Whole home monitoring',
  unlimitedVideoRecording: 'Unlimited video recording',
  instantAlarmAlerts: 'Instant alarm alerts on your phone',
  hardwareDiscount: '10% new hardware discount',
  lifetimeSubscriberWarranty: 'Lifetime subcriber warranty'
}

type Feature = {
  readonly text: string
  readonly type: 'check' | 'cross'
  readonly modal?: FeatureModalProps
}

type MonitoringCardProps = {
  readonly hasFlag: boolean
  readonly name: string
  readonly features: readonly Feature[]
  readonly subtext: string
  readonly price: string
  readonly iconTexts: readonly IconText[]
  readonly atcButton?: React.ReactNode
  readonly footer?: React.ReactNode
}

type IconProps = {
  readonly className: string
}

export function MonitoringCard({
  hasFlag,
  name,
  features,
  subtext,
  price,
  iconTexts,
  atcButton = null,
  footer = null
}: MonitoringCardProps) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row">
        <div className="border-neutral-medium-100 flex flex-grow basis-[55%] flex-col gap-3 rounded-t-2xl border border-solid px-4 py-5 md:rounded-l-2xl md:rounded-tr-none md:pb-6 md:pt-8 lg:pl-16 lg:pr-14">
          <span
            className={classNames(
              'bg-primary-100 mr-auto px-[10px] py-[7px] text-xs',
              { invisible: !hasFlag }
            )}
          >
            RECOMMENDED
          </span>
          <h3 className="my-0">{name}</h3>
          <div>
            <ul className="list-image-plan-checkmark my-0">
              {features
                .filter(f => f.type === 'check')
                .map((feature, idx) => (
                  <li key={idx}>
                    {feature.text}
                    {feature.modal ? (
                      <FeatureModal modal={feature.modal} />
                    ) : null}
                  </li>
                ))}
            </ul>
            <ul className="list-image-plan-cross my-0">
              {features
                .filter(f => f.type === 'cross')
                .map((feature, idx) => (
                  <li key={idx}>{feature.text}</li>
                ))}
            </ul>
          </div>
          <div className="mb-6 flex flex-grow flex-col">
            <p className="mb-2.5 flex-grow font-bold">{subtext}</p>
            <div className="mb-[3px] flex items-center gap-2">
              {atcButton ? atcButton : null}
              <span
                className={classNames(
                  'text-3xl font-medium mb-2.5 md:text-4xl',
                  {
                    'text-complementary-red-100': hasFlag
                  }
                )}
              >
                {price}
              </span>
              <span className="text-xs">per day</span>
            </div>
            <p>No contract. Cancel anytime.</p>
          </div>
        </div>
        <div className="bg-neutral-black box-border grid basis-[45%] auto-rows-[fr1] grid-cols-2 gap-7 rounded-b-2xl px-4 pb-6 pt-4 text-sm font-bold text-white md:grid-rows-4 md:gap-1 md:rounded-r-2xl md:rounded-bl-none md:px-2 md:pb-[5.5rem] md:pt-16 md:text-base lg:pl-[3.75rem] lg:pr-[2.375rem]">
          {iconTexts.map((iconText, idx) => (
            <div className="flex items-center gap-2" key={idx}>
              {icons[iconText]()}
              <span>{texts[iconText]}</span>
            </div>
          ))}
        </div>
      </div>
      {footer ? (
        <span className="pt-5 text-center text-xs">{footer}</span>
      ) : null}
    </div>
  )
}

function ProfessionalMonitoringIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M63.9092 27.6363C63.9092 23.6197 60.653 20.3636 56.6364 20.3636V34.9091C60.653 34.9091 63.9092 31.6529 63.9092 27.6363Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M23.9092 34.9091V20.3636C19.8926 20.3636 16.6364 23.6197 16.6364 27.6363C16.6364 31.6529 19.8926 34.9091 23.9092 34.9091Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M27.5455 20.3636C27.5455 13.3457 33.2549 7.63629 40.2728 7.63629C47.2907 7.63629 53.0001 13.3457 53.0001 20.3636V38.5454C53.0001 39.5481 52.1845 40.3636 51.1818 40.3636H43.4187C42.7897 39.2779 41.6181 38.5454 40.2728 38.5454C38.2646 38.5454 36.6365 40.1735 36.6365 42.1819C36.6365 44.1901 38.2646 45.8181 40.2728 45.8181C41.6181 45.8181 42.7897 45.0857 43.4187 43.9999H51.1818C54.1895 43.9999 56.6364 41.5531 56.6364 38.5454V20.3636C56.6364 11.3406 49.2957 4 40.2728 4C31.2499 4 23.9092 11.3406 23.9092 20.3636V34.9089H27.5455V20.3636Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M40.2728 49.4546C25.2347 49.4546 13 61.6889 13 76.7272H16.6363C16.6363 63.6941 27.2397 53.0909 40.2728 53.0909C53.3059 53.0909 63.9093 63.6943 63.9093 76.7274H67.5456C67.5456 61.6891 55.311 49.4546 40.2728 49.4546Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  )
}

function FastPoliceDispatchIcon({ className }: IconProps) {
  return <PoliceCar className={`h-20 w-20 ${className}`} />
}

function FastFireAmbulanceDispatchIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M41.684 39.4178V43.4834C41.684 50.8031 44.2261 54.7025 46.6847 58.4734C49.481 62.7626 52.1377 66.8366 49.5491 74.4818C54.3912 73.6121 57.9976 70.7578 60.292 65.969C62.6029 61.146 63.377 54.668 62.4716 47.7291C61.9392 43.6495 60.8532 39.714 59.3522 36.2957C59.4402 39.4528 59.2291 42.1137 57.6486 43.776C56.7282 44.744 55.469 45.2351 53.9058 45.2351C52.3504 45.2351 51.0978 44.7463 50.1832 43.7824C48.2029 41.6955 48.3953 38.0291 48.6179 33.7837C48.6906 32.3982 48.7657 30.9655 48.7657 29.5502C48.7657 22.8326 46.6269 17.5447 42.4083 13.8337C40.9448 12.5463 39.4922 11.6878 38.3583 11.1378C38.4839 25.8718 32.0794 32.7514 26.3929 38.8597C21.5143 44.0998 17.3011 48.6258 17.3011 57.4163C17.3011 63.9446 20.5012 68.8339 26.8121 71.9483C28.5304 72.7962 30.2527 73.3953 31.7649 73.8154C29.813 71.0522 28.7155 67.8826 28.5464 64.449C28.1918 57.245 31.9325 49.1692 38.8092 42.2926L41.684 39.4178ZM41.718 78.5269L36.3876 78.1173C36.1611 78.1001 30.7757 77.6602 25.3217 74.9686C17.8712 71.2918 13.933 65.2223 13.933 57.4163C13.933 47.3006 19.014 41.8428 23.9276 36.5647C29.7489 30.3118 35.7681 23.8459 34.91 8.74621L34.78 6.45898L37.0018 7.01745C37.1595 7.05697 40.905 8.02535 44.633 11.3049C48.0548 14.3153 52.1338 19.9242 52.1338 29.5502C52.1338 31.0537 52.0564 32.5313 51.9815 33.9601C51.8223 36.9912 51.6423 40.4268 52.6265 41.4641C52.7905 41.6369 53.1018 41.867 53.9058 41.867C54.7219 41.867 55.0397 41.6321 55.2078 41.4553C56.2065 40.4047 56.0316 36.9441 55.8771 33.8908C55.806 32.4839 55.7323 31.0293 55.7323 29.5502V24.4981L58.7636 28.5398C62.3033 33.2595 64.872 40.095 65.8113 47.2933C66.8 54.8709 65.9186 62.0204 63.3294 67.4242C60.0964 74.1721 54.4578 77.8709 47.0231 78.1215L44.3256 78.2122L45.4298 75.7495C48.8922 68.0267 46.7828 64.791 43.8633 60.3128C41.8248 57.186 39.3751 53.4287 38.5793 47.539C34.0489 53.0147 31.6488 58.9703 31.9104 64.2834C32.1214 68.5691 34.0305 72.2206 37.5847 75.1364L41.718 78.5269Z"
        fill="white"
        fillRule="evenodd"
      />
      <mask
        className={className}
        id="mask0_1_4743"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M0 80H80V0H0V80Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_1_4743)"></g>
    </svg>
  )
}

function WholeHomeProtectionIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M70.4312 27.5784L72.2207 24.5956L36.5432 3.18922L0.865906 24.5956L2.65549 27.5784L36.5432 7.24553L70.4312 27.5784Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M60.8432 34.7346C54.1041 34.7346 48.6212 40.2173 48.6212 46.9566H52.0994C52.0994 42.1353 56.0219 38.2128 60.8432 38.2128C65.6645 38.2128 69.5869 42.1353 69.5869 46.9566H73.0651C73.0651 40.2173 67.5824 34.7346 60.8432 34.7346Z"
        fill="white"
        fillRule="evenodd"
      />
      <mask
        className={className}
        id="mask0_1_4791"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M0 80H80V0H0V80Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_1_4791)">
        <path
          clipRule="evenodd"
          d="M48.7173 73.0436H73.0651V55.6521H48.7173V73.0436ZM45.2391 52.1739V59.1305H13.9346V29.5652H10.4563V62.6087H45.2391V76.5216H76.5433V52.1739H45.2391Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

function UnlimitedVideoRecordingIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.20788 63.6366H72.7919V16.3638H7.20788V63.6366ZM3.57159 67.2727H76.4282V12.7273H3.57159V67.2727Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M49.1505 40L34.4859 49.3783V30.6217L49.1505 40Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  )
}

function InstantAlarmAlertsIcon({ className }: IconProps) {
  return <PhoneRinging className={`h-20 w-20 ${className}`} />
}

function HardwareDiscountIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.1442 63.6618H60.9875L72.6216 50.2311V33.2963L60.867 16.3634H7.1442V63.6618ZM62.6486 67.2981H3.50772V12.7273H62.7692L76.2578 32.1581V51.5869L62.6486 67.2981Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M65.0999 40C65.0999 41.8124 63.6306 43.2817 61.8182 43.2817C60.0058 43.2817 58.5365 41.8124 58.5365 40C58.5365 38.1876 60.0058 36.7183 61.8182 36.7183C63.6306 36.7183 65.0999 38.1876 65.0999 40Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M25.4546 30.2405C24.0834 30.2405 22.9678 31.3559 22.9678 32.7273C22.9678 34.0984 24.0834 35.2138 25.4546 35.2138C26.8257 35.2138 27.9413 34.0984 27.9413 32.7273C27.9413 31.3559 26.8257 30.2405 25.4546 30.2405ZM25.4546 38.8501C22.0784 38.8501 19.3317 36.1034 19.3317 32.7273C19.3317 29.3511 22.0784 26.6042 25.4546 26.6042C28.8307 26.6042 31.5774 29.3511 31.5774 32.7273C31.5774 36.1034 28.8307 38.8501 25.4546 38.8501Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M40 44.7861C38.6288 44.7861 37.5132 45.9015 37.5132 47.2729C37.5132 48.644 38.6288 49.7594 40 49.7594C41.3712 49.7594 42.4868 48.644 42.4868 47.2729C42.4868 45.9015 41.3712 44.7861 40 44.7861ZM40 53.3957C36.6238 53.3957 33.8771 50.649 33.8771 47.2729C33.8771 43.8967 36.6238 41.1498 40 41.1498C43.3762 41.1498 46.1229 43.8967 46.1229 47.2729C46.1229 50.649 43.3762 53.3957 40 53.3957Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M23.3402 52.431L20.2963 49.3871L42.1144 27.569L45.1584 30.6129L23.3402 52.431Z"
        fill="white"
        fillRule="evenodd"
      />
      <mask
        className={className}
        id="mask0_1_4690"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M0 80H80V0H0V80Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_1_4690)"></g>
    </svg>
  )
}

function LifetimeSubscriberWarrantyIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.5207 15.9438V40.0934C14.5207 51.7471 21.5721 62.4373 32.4853 67.3279L40.0876 70.7349L47.69 67.3279C58.6032 62.4373 65.6546 51.7471 65.6546 40.0934V15.9438L40.0876 9.15408L14.5207 15.9438ZM40.0876 74.7198L30.9981 70.6462C18.7793 65.1705 10.8842 53.1779 10.8842 40.0934V13.147L40.0876 5.39159L69.2909 13.147V40.0934C69.2909 53.1779 61.3958 65.1705 49.1772 70.6462L48.4335 68.9869L49.1772 70.6462L40.0876 74.7198Z"
        fill="white"
        fillRule="evenodd"
      />
      <mask
        className={className}
        id="mask0_1_4660"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M0 80H80V0H0V80Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_1_4660)">
        <path
          clipRule="evenodd"
          d="M14.6333 29.1565H65.5422V25.5202H14.6333V29.1565Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M36.5356 56.2126L28.8312 48.508L31.4025 45.9369L36.5352 51.0696L50.6493 36.9511L53.221 39.5218L36.5356 56.2126Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export { FastProtectMonitoringCard, SelfMonitoringCard }
