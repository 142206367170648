import { useMicroCopy } from '@ecomm/micro-copy'
import { Cancel, ChevronDown } from '@ecomm/shared-icons'
import React, { ReactNode } from 'react'
import { useTracking } from 'react-tracking'

export type HeaderProps = {
  readonly isVisible: boolean
  readonly toggleBannerVisibility: () => void
  readonly hasToggles: boolean
  readonly children: ReactNode
}

const getTrackEventLabel = (hasToggles: boolean, isVisible: boolean) =>
  !hasToggles && isVisible ? 'close_banner' : isVisible ? 'collapse' : 'expand'

export function Header({
  isVisible,
  toggleBannerVisibility,
  hasToggles,
  children
}: HeaderProps) {
  const microCopy = useMicroCopy()
  const { trackEvent } = useTracking()
  const onClick = () => {
    trackEvent({
      event: 'promo_detail',
      label: getTrackEventLabel(hasToggles, isVisible)
    })
    toggleBannerVisibility()
  }

  return (
    <div
      className="bg-promo-banner-bg relative w-full overflow-hidden px-4 py-5 text-center font-bold uppercase"
      data-component="Header"
    >
      {children}
      <div className="absolute bottom-0 left-0 right-0 top-0 mr-4 flex items-center">
        <button
          className="flex w-full cursor-pointer justify-end border-none bg-transparent text-inherit"
          data-component="HeaderActionBtn"
          onClick={onClick}
        >
          <span className="sr-only">
            {hasToggles && !isVisible
              ? microCopy['see-promo-details']
              : microCopy['hide-details']}
          </span>
          {hasToggles && isVisible ? (
            <ChevronDown
              className="text-promo-primary-text h-6 w-6"
              data-component="ChevronDown"
            />
          ) : null}
          {hasToggles && !isVisible ? (
            <ChevronDown
              className="text-promo-primary-text h-6 w-6 rotate-180"
              data-component="ChevronUp"
            />
          ) : null}
          {!hasToggles && isVisible ? (
            <Cancel
              className="text-promo-primary-text h-6 w-6"
              data-component="Close"
            />
          ) : null}
        </button>
      </div>
    </div>
  )
}
