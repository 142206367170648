import { AffirmPromoMessage } from '@ecomm/shared-components'
import { useOptimizelyAffirm } from '@ecomm/tracking'
import { window } from 'browser-monads-ts'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import React from 'react'

import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import { PackageItem } from '.'
import { usePackagePrice } from './hooks'
import { PackageType } from './schema'

type Props = {
  readonly sku: string
  readonly type: PackageType
  readonly productItems: readonly PackageItem[]
}

/**
 * An abstraction on the AffirmPromoMessage that reads from Jotai
 * to better match the PackagePrice component.
 */
export default function Affirm({ sku, type, productItems }: Props) {
  const affirmClient = window.affirm
  const { optimizelyAffirmLearnMore } = useOptimizelyAffirm()

  const [monitoringPlan] = useAtom(monitoringAtom)

  const { regularPrice, discountedPrice } = usePackagePrice(
    sku,
    type,
    productItems
  )

  const price = pipe(
    monitoringPlan.type,
    O.fromPredicate(type => type === 'interactive'),
    O.chain(() => discountedPrice),
    O.getOrElse(() => regularPrice)
  )

  return (
    <AffirmPromoMessage
      affirmClient={affirmClient}
      onLearnMoreClick={optimizelyAffirmLearnMore}
      pageType="category"
      price={price}
    />
  )
}
