import { useMicroCopy } from '@ecomm/micro-copy'
import { useTrackOdmonPlanSelection } from '@ecomm/tracking'
import React from 'react'

import { MonitoringCard } from './MonitoringCard'

export function FastProtectMonitoringCard({
  atcButton = null
}: {
  readonly atcButton?: React.ReactNode
}) {
  const microCopy = useMicroCopy()
  const price = microCopy['fast-protect-daily-price']
  const trackCTA = useTrackOdmonPlanSelection()

  return (
    <MonitoringCard
      atcButton={
        <div onClick={() => trackCTA('fast protect', 'cards')}>{atcButton}</div>
      }
      features={[
        {
          type: 'check',
          text: '24/7 live guard protection, only from SimpliSafe®',
          modal: {
            headline: '24/7 live guard protection, only from SimpliSafe®',
            description:
              "With Live Guard Protection, agents can use the Smart Alarm indoor camera to see, speak to and deter intruders in real time during an alarm. Agents can only access your camera's microphone and video feed during an active alarm."
          }
        },
        {
          type: 'check',
          text: 'Professional monitoring agents respond to alarms 24/7'
        },
        {
          type: 'check',
          text: 'Faster police dispatch after agents verify threats*',
          modal: {
            headline: 'Faster police response after agents verify threats*',
            description:
              'When you subscribe to the Fast Protect™ monitoring plan, agents will attempt to verify threats with video while communicating with 911 centers, which can result in a faster police response.'
          }
        },
        {
          type: 'check',
          text: 'Whole home protection against break-ins, fire, floods, and more'
        },
        {
          type: 'check',
          text: 'Instant alarm alerts on mobile app'
        },
        {
          type: 'check',
          text: 'Unlimited camera recordings & 30-day cloud storage'
        },
        {
          type: 'check',
          text: 'Lifetime subscriber warranty on hardware',
          modal: {
            headline: 'Lifetime subscriber warranty on hardware',
            description: (
              <>
                <p>
                  SimpliSafe provides a 1-year limited hardware warranty on all
                  products.
                </p>
                <p>
                  To get the lifetime subscriber warranty for hardware
                  protection, customers must sign up for the Fast Protect™
                  before the 1-year limited hardware warranty expires.
                </p>
                <p>
                  Once the 1-year limited warranty expires, the device will be
                  covered by the respective product protection plan, unless the
                  service plan is disrupted. Please see our Terms of Sale and
                  Terms of Service for details and conditions.
                </p>
              </>
            )
          }
        }
      ]}
      footer="*Vs. non-verified SimpliSafe® alarms. With video verification, our agents capture evidence so that you can get priority response. Fast Protect™ Monitoring plan is required."
      hasFlag={true}
      iconTexts={[
        'professionalMonitoring',
        'wholeHomeProtection',
        'fastPoliceDispatch',
        'fastFireAmbulanceDispatch',
        'unlimitedVideoRecording',
        'instantAlarmAlerts',
        'hardwareDiscount',
        'lifetimeSubscriberWarranty'
      ]}
      name="Fast Protect™ Professional Monitoring"
      price={price}
      subtext="Complete protection for complete peace of mind."
    />
  )
}
