import { ZuoraErrorResponse, ZuoraSuccessResponse } from '@ecomm/data-zuora'
import { TrackingData } from '@ecomm/tracking'

import { ZuoraOrderData } from './createOrder'
import { logErrorWithOrderInfo } from './utils/common'

type CreateOrder = (_orderData: ZuoraOrderData) => void
type OnFormLoadError = () => void
type OnPaymentError = (_error: Error) => void
type OnPaymentProcessing = () => void
type TrackEvent = (_data: TrackingData) => void

/**
 * Handle the response from a credit card payment method submission with Zuora.
 *
 * On success, the payment is tracked, an order is created in our system payment is processed, and the user
 * is forwarded appropriately to either the confirmation page or the preactivation flow.
 *
 * On error, the Zuora error code is tracked, the response is logged and payment state is updated.
 */

export const handleZuoraSuccess = (
  zuoraResponse: ZuoraSuccessResponse,
  createOrder: CreateOrder,
  onPaymentProcessing: OnPaymentProcessing
): void => {
  onPaymentProcessing()

  createOrder({
    paymentMethodId: zuoraResponse.refId,
    provider: 'zuora',
    token: zuoraResponse.token,
    type: 'credit'
  })
}

export const handleZuoraError = (
  zuoraResponse: ZuoraErrorResponse,
  onFormLoadError: OnFormLoadError,
  onPaymentError: OnPaymentError,
  trackEvent: TrackEvent
): void => {
  const errorCode = zuoraResponse.errorCode ?? ''
  const errorMessage = zuoraResponse.errorMessage ?? ''
  const source = zuoraResponse.responseFrom ?? ''

  trackEvent({
    errorID: errorCode,
    event: 'paymentFormCreError'
  })

  source === 'Response_From_Submit_Page'
    ? handleErrorOnSubmit(errorMessage, onPaymentError)
    : handleErrorOnLoad(errorMessage, onFormLoadError)
}

const handleErrorOnSubmit = (
  message: string,
  onPaymentError: OnPaymentError
): void => {
  const error = Error(
    `submitCreditOrder: Credit Card submission failed - ${message}`,
    { cause: Error(message) }
  )

  /** Form submission was successful, but an error occurred during decryption and verification process, or
   * form submission was unsuccessful
   */
  logErrorWithOrderInfo(error)
  onPaymentError(error)
}

const handleErrorOnLoad = (
  message: string,
  onFormLoadError: OnFormLoadError
): void => {
  const error = Error(
    `submitCreditOrder: Credit Card form failed to load - ${message}`
  )

  logErrorWithOrderInfo(error)
  onFormLoadError()
}
