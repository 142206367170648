import { NativeVideo } from '@ecomm/shared-components'
import { useTrackingLiveGuardCta } from '@ecomm/tracking'
import { Link } from 'gatsby'
import React from 'react'
import type { TypeOf } from 'zod'

import type { schema } from './schema'
import { trackMonitoringCTA } from '@ecomm/cdp-tracking'

export type VideoBannerProps = TypeOf<typeof schema>

interface Props {
  readonly data: VideoBannerProps
}

function VideoBanner({ data }: Props) {
  const { badge, preTitle, title, text, buttonText, redirectUrl } = data

  const preTitleParts = preTitle.split(/(®)/)
  const trackMonitoringCtaClick = useTrackingLiveGuardCta()

  return (
    <div
      className="bg-neutral-black flex flex-col gap-5 rounded-2xl lg:flex-row px-4 py-8 md:p-8 lg:grid-cols-2 lg:p-12"
      data-component="VideoBanner"
      data-testid="VideoBanner"
    >
      <div className="m-auto w-full">
        <NativeVideo
          captionsSrcUrl={data.video.captionsSrc}
          muted={false}
          poster={data.video.posterImage}
          videoSrcUrlMp4={data.video.mp4Link}
          videoSrcUrlWebm={data.video.webmLink}
        />
      </div>
      <div className="flex flex-col gap-4 md:mx-0 lg:order-first lg:max-w-[30rem]">
        <div className="bg-primary-100 w-fit rounded-full py-2 px-3 text-sm font-bold leading-none">
          {badge}
        </div>
        <div className="flex flex-col md:gap-3">
          <h4 className="m-0 text-sm leading-8 md:text-2xl font-semibold text-white font-arizona lg:min-w-[500px]">
            {preTitleParts.map((part, index) =>
              part === '®' ? (
                <sup className=" md:text-sm" key={index}>
                  ®
                </sup>
              ) : (
                part
              )
            )}
          </h4>
          <h2 className="text-[28px] leading-8 md:text-[56px] md:leading-[60px] mb-0 md:mb-2 text-white">
            {title}
          </h2>
        </div>
        <p className="text-white">{text}</p>
        <Link
          className="hover:text-btn-secondary w-full rounded-md border-2 border-solid border-white px-4 py-3 text-center text-white no-underline duration-200 ease-in-out lg:w-fit"
          onClick={() => {
            trackMonitoringCtaClick()
            trackMonitoringCTA()
          }}
          to={redirectUrl}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  )
}

export { VideoBanner }
