import { parseJSONDataFromContentful } from '@ecomm/scout'
import {
  GatsbyImage,
  GatsbyImageSchema,
  GuidedSystemBuilderSchema,
  QuoteWizardModal
} from '@ecomm/shared-components'
import { TrackingData } from '@ecomm/tracking'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useTracking } from 'react-tracking'

import { SystemBannerSchema, systemBannerSchema } from './schema'

export type SystemBannerProps = {
  readonly image: GatsbyImageSchema
  readonly quoteWizard: GuidedSystemBuilderSchema
}

export type SystemBannerData = {
  readonly title: string
  readonly titleMobile: string
  readonly text?: string
  readonly primaryButtonText: string
  readonly secondaryButtonText: string
  readonly quoteWizard: GuidedSystemBuilderSchema
}

export const getSystemBannerData = (
  data: SystemBannerSchema & { readonly copy: ReadonlyMap<string, string> }
): SystemBannerData & SystemBannerProps => {
  const parsedData = systemBannerSchema.parse(data)
  const systemBannerData: SystemBannerData = parseJSONDataFromContentful(
    parsedData,
    'copy'
  )

  return {
    image: parsedData.image,
    quoteWizard: parsedData.quoteWizard,
    titleMobile: systemBannerData.titleMobile,
    title: systemBannerData.title,
    text: systemBannerData.text,
    primaryButtonText: systemBannerData.primaryButtonText,
    secondaryButtonText: systemBannerData.secondaryButtonText
  }
}

export function SystemBanner({
  data
}: {
  readonly data: SystemBannerData & SystemBannerProps
}) {
  const {
    image,
    quoteWizard,
    title,
    titleMobile,
    text,
    primaryButtonText,
    secondaryButtonText
  } = data

  const { trackEvent } = useTracking<TrackingData>()

  const [showModal, setShowModal] = useState(false)
  const handleSetShowModalClick = (isShowing: boolean) => {
    setShowModal(isShowing)
    trackEvent({
      event: 'button-click-get-recommendation-secured-systems-component'
    })
  }

  return (
    <div
      className="bg-neutral-black flex flex-wrap rounded-3xl"
      data-component="SystemBanner"
    >
      <div className="relative w-full rounded-3xl md:w-auto md:basis-7/12 lg:basis-2/4">
        <GatsbyImage
          className="w-full rounded-t-3xl md:mt-0 md:h-full md:w-auto md:rounded-3xl"
          image={image}
        />
      </div>
      <div className="grid px-6 pb-8 pt-6 md:basis-5/12 md:pl-0 md:pr-7 md:pt-9 lg:basis-2/4 lg:content-center lg:pb-0 lg:pt-0">
        <h2 className="mb-0 text-[28px] leading-[34px] text-white md:mb-3 lg:hidden">
          {titleMobile}
        </h2>
        <h2 className="m-0 hidden text-[55px] text-white lg:block">{title}</h2>
        {text ? (
          <p className="mb-0 mt-2 text-lg text-white md:text-base">{text}</p>
        ) : null}
        <div className="mt-6 flex flex-col self-end md:mt-8">
          <QuoteWizardModal
            data={quoteWizard}
            onRequestClose={() => setShowModal(false)}
            show={showModal}
          />
          <button
            className="rounded-base border-btn-primary bg-btn-primary text-btn-accent hover:border-btn-dark hover:bg-btn-dark w-full cursor-pointer border
             border-solid py-3 text-center text-base transition-colors duration-200 lg:w-64 lg:text-lg"
            onClick={() => {
              handleSetShowModalClick(true)
            }}
            type="button"
          >
            {primaryButtonText}
          </button>
          <Link
            onClick={() =>
              trackEvent({
                event: 'button-click-bms-secured-systems-component'
              })
            }
            to="/build-my-system"
          >
            <button
              className="rounded-base text-btn-accent hover:text-neutral-black mt-3 w-full cursor-pointer border border-solid border-white bg-transparent
              py-3 text-center text-base transition-colors duration-200 hover:bg-white lg:mt-4 lg:w-64 lg:text-lg"
              type="button"
            >
              {secondaryButtonText}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
