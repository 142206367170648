import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'

import { AffirmReviewGridSchema } from './schema'

export default function AffirmReviewGrid({
  headline,
  reviews
}: AffirmReviewGridSchema) {
  return (
    <div className="flex flex-col">
      <h2 className="w-full text-center">{headline}</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {reviews.map((review, i) => (
          <div
            className="col-span-1 max-w-md mx-auto overflow-hidden rounded-base bg-neutral-light-50 pt-4 md:pt-6 lg:pt-8 flex flex-col justify-between items-center"
            key={`review-${i}`}
          >
            <span className="rounded-full flex items-center justify-center font-bold w-8 h-8 md:w-9 md:h-9 lg:h-12 lg:w-12 bg-primary-100">
              {i + 1}
            </span>
            <div className="px-4 md:px-6 lg:px-8 mt-4 text-center w-full">
              <ContentfulRichText {...review.review} />
            </div>
            <ContentfulImage
              description={review.logo.description}
              originalHeight={review.logo.file.details.image.height}
              originalWidth={review.logo.file.details.image.width}
              url={review.logo.file.url}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
