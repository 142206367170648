import React, { FC, ReactElement } from 'react'

export type CustomerReviewCardProps = {
  /** A IconWithText that is displayed with title text. */
  readonly title: ReactElement
  /** ReactElement that gets displayed for content. */
  readonly content: ReactElement
}

/** @deprecated Do not use ss-react-components*/
const CustomerReviewCard: FC<CustomerReviewCardProps> = ({
  content,
  title
}: CustomerReviewCardProps) => {
  return (
    <div
      className="rounded-base neutralLight50BackgroundColor overflow-hidden px-4 py-4 md:px-8 md:py-8"
      data-component="CustomerReviewCard"
    >
      {title}
      {content}
    </div>
  )
}

export default CustomerReviewCard
