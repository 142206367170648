import { NativeVideo } from '@ecomm/shared-components'

export type OverviewVideoSectionProps = {
  readonly title: string
  readonly posterImageLink?: string
  readonly mp4VideoLink: string
  readonly webmVideoLink: string
  readonly captions?: string
}

export default function OverviewVideoSection({
  posterImageLink = '',
  mp4VideoLink = '',
  webmVideoLink = '',
  captions = '',
  title
}: OverviewVideoSectionProps) {
  return (
    <section>
      <h2 className="mb-4 w-full text-center md:mb-6">{title}</h2>
      <NativeVideo
        captionsSrcUrl={captions}
        poster={posterImageLink}
        videoSrcUrlMp4={mp4VideoLink}
        videoSrcUrlWebm={webmVideoLink}
      />
    </section>
  )
}
