//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import { BackgroundComponentType } from '../common'
import Menu from '../Menu'
import * as css from './CountryGridItem.module.scss'

export type CountryGridProps = {
  readonly linkText: string
  readonly linkUrl: string
  /** To get the gatsby-background-image component for rendering backdrop image */
  readonly BackgroundComponent?: BackgroundComponentType
  /** Props of gatsby-background-image component for rendering background banner image */
  readonly backgroundComponentProps?: Record<string, unknown>
  readonly className?: string
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const CountryGridItem: FC<CountryGridProps> = ({
  linkText,
  linkUrl,
  BackgroundComponent,
  backgroundComponentProps,
  dataComponent = CountryGridItem.name
}: CountryGridProps) => {
  const renderContent = (__linkUrl: string) => {
    return (
      <span className={classNames(css.titleWrap)}>
        <div className={classNames(css.titleContent)}>
          <p className={classNames(css.titleHeader)}>{linkText}</p>
        </div>
      </span>
    )
  }

  return (
    <div
      className={classNames('rounded-base overflow-hidden', css.locationGrid)}
      data-component={dataComponent}
    >
      <Menu url={linkUrl}>
        {BackgroundComponent ? (
          <BackgroundComponent
            {...backgroundComponentProps}
            className={classNames(css.locationTitle)}
          >
            {renderContent(linkUrl)}
          </BackgroundComponent>
        ) : (
          <section
            className={classNames(css.locationTitle)}
            data-component={`${dataComponent}_noBackground`}
          >
            {renderContent(linkUrl)}
          </section>
        )}
      </Menu>
    </div>
  )
}

export default CountryGridItem
