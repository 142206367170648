//@ts-nocheck
import { FloorPlanCarousel } from '@ecomm/shared-components'
import { stringToKebabCase } from '@ecomm/utils'
import React from 'react'
import { match } from 'ts-pattern'
import { literal } from 'zod'

import { FeatureSection } from '../FeatureSection'
import { AnchorSectionSchema } from './schema'

export function AnchorSection({
  name,
  component,
  classNames
}: AnchorSectionSchema) {
  return (
    <div className={classNames} id={stringToKebabCase(name)}>
      {match(component)
        .with(
          { __typename: literal('ContentfulFeatureSection').value },
          data => <FeatureSection {...data} className="rounded-lg" />
        )
        .with(
          {
            __typename: literal('ContentfulCarousel').value
          },
          data => {
            const items = data.items.map(item => ({
              ...item,
              name: item.headline,
              floorplanCarouselItem: {
                image: item.image
              }
            }))

            return (
              <FloorPlanCarousel description={data.description} items={items} />
            )
          }
        )
        .otherwise(() => null)}
    </div>
  )
}
