import { RawGiftItemContainer } from '@ecomm/data-price'
import { addAtomDebugLabel } from '@ecomm/utils'
import { atomWithReset } from 'jotai/utils'

export const freeGiftItemAtom = atomWithReset<RawGiftItemContainer>({
  withMonitoring: null,
  withoutMonitoring: null
})

addAtomDebugLabel(freeGiftItemAtom, 'DraftCart: FreeGiftItem')
