import { ContentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { ContentfulRichText, NativeVideo } from '@ecomm/shared-components'

type VideoMetadata = {
  readonly videoSrcUrlWebm: string
  readonly videoSrcUrlMp4: string
  readonly captionsSrcUrl?: string | null
  readonly poster?: string
  readonly preload?: string
}

export function TwoColumnVideo({
  video,
  description
}: {
  readonly video: VideoMetadata
  readonly description: ContentfulRichTextSchema
}) {
  return (
    <div className="prose md:prose-md lg:prose-lg grid grid-cols-12 gap-4 md:gap-8 lg:gap-16">
      <div className="col-span-12 md:col-span-7">
        <NativeVideo {...video} />
      </div>
      <div className="col-span-12 md:col-span-5 ">
        <ContentfulRichText raw={description.raw} />
      </div>
    </div>
  )
}
