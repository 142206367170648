import { logError } from '@ecomm/error-handling'
import { setPartnerCookie } from '@ecomm/shared-cookies'
import { IOCreateOrSetPartnerAssociationCart } from '@simplisafe/ss-ecomm-data/cart'
import { IOPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions'
import { getLocalStorageCartIdAsString } from '.'

type PartnerLogicProps = {
  readonly partnerGroup: string
  readonly partnerName: string
  readonly partnerUrl: string
  readonly dispatch: ({}) => void
  readonly delay: number
  readonly partnerMemberId?: string
}

export const setPartnerLogic = ({
  partnerGroup,
  partnerName,
  partnerUrl,
  dispatch,
  delay,
  partnerMemberId
}: PartnerLogicProps) => {
  // TODO: find a better way to do this, likely with reactQuery
  /**
   * Context:
   * Our request for partners pricing is coming in faster than normal pricing,
   * so there is a race condition occurring, and the partner price is overwritten
   * by the normal price, even though it was requested later
   *
   * The solution is to wait on setting the partner cookie (cookie only, not the banner or cart)
   * until that request completes. By waiting on the cookie, we can ensure the first
   * request completes before making the second request, and ensure the correct price
   * displays on the site.
   *
   * This value is also defaulted to 0 unless specifically set, so it should not impact
   * existing placements
   */
  setTimeout(() => {
    setPartnerCookie({
      partnerGroup,
      partnerName,
      partnerUrl,
      partnerMemberId
    })
  }, delay)

  dispatch(IOPartnerBanner(partnerName, partnerGroup))

  const cartId = getLocalStorageCartIdAsString()

  dispatch(
    IOCreateOrSetPartnerAssociationCart(partnerGroup, partnerName, cartId, () =>
      logError(
        Error('there was an error when adding a partner association to cart')
      )
    )
  )
}
