import { ReactElement } from 'react'

interface CartPromoBannerProps {
  readonly title: string
  readonly description: string
  readonly image: ReactElement
  readonly backgroundColor: string
}

export function CartPromoBanner({
  title,
  description,
  image,
  backgroundColor
}: CartPromoBannerProps) {
  return (
    <div
      className={`${backgroundColor} mb-6 flex flex-col rounded-lg px-6 py-6 md:flex-row`}
    >
      <div className="mb-2 flex flex-1 flex-shrink-0 items-start pr-2 lg:mb-0 lg:items-center">
        {image}
      </div>
      <div className="lg:px-4">
        <h3 className="mb-2 mt-0 text-xl text-white">{title}</h3>
        <p className="m-0 text-sm text-white">{description}</p>
      </div>
    </div>
  )
}
