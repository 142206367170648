import React from 'react'

type PriceProps = {
  /** The data-component attribute value. */
  readonly dataComponent?: string
  /** The regular price of a product. */
  readonly regularPrice?: string
  /** The discounted price of a product. */
  readonly discountedPrice?: string
}

const strike = (shouldStrike: boolean, price?: string) =>
  shouldStrike ? <s>{price}</s> : price

export function PriceBox({
  dataComponent = PriceBox.name,
  discountedPrice = '',
  regularPrice = ''
}: PriceProps) {
  return discountedPrice || regularPrice ? (
    <span data-component={dataComponent}>
      {discountedPrice ? (
        <span className="text-complementary-red-100">
          {regularPrice ? (
            <span className="sr-only">Discounted price: </span>
          ) : null}
          {discountedPrice}
        </span>
      ) : null}
      {discountedPrice && regularPrice ? ' ' : null}
      {regularPrice ? (
        <span>
          {discountedPrice ? (
            <span className="sr-only">Actual price: </span>
          ) : null}
          {strike(!!discountedPrice, regularPrice)}
        </span>
      ) : null}
    </span>
  ) : null
}
