import { getBraze } from '@ecomm/shared-window'

import { shouldTrackUserEvent } from './shouldTrackUserEvent'

export const brazeLogCustomEvent = (
  eventId: string,
  properties?: Record<string, unknown>
) => {
  shouldTrackUserEvent() &&
    getBraze(braze => braze.logCustomEvent(eventId, properties))
}
