import { ReactElement, useEffect, useState } from 'react'

const useDelayedRender = (delay: number) => {
  const [delayed, setDelayed] = useState(true)
  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay)
    return () => clearTimeout(timeout)
  }, [delay])
  return (fn: () => ReactElement) => (!delayed ? fn() : null)
}

export default useDelayedRender
