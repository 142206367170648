import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const twoColumnImageBlurbsSchema = z.object({
  __typename: z.literal('ContentfulTwoColumnSection'),
  leftSide: z.object({
    title: z.string(),
    image: gatsbyImageSchema,
    blurb: contentfulRichTextSchema
  }),
  rightSide: z.object({
    title: z.string(),
    image: gatsbyImageSchema,
    blurb: contentfulRichTextSchema
  })
})

export type TwoColumnImageBlurbsSchema = TypeOf<
  typeof twoColumnImageBlurbsSchema
>
