import { gql } from '@apollo/client/index.js'

export const LEGAL_TEMPLATE_QUERY = gql`
  fragment textPage on TextPage {
    sys {
      id
    }
    title
    slug
    breadcrumbTitle
    displayContentOnly
    text {
      json
      links {
        entries {
          inline {
            __typename
            sys {
              id
            }
            ... on JumpLink {
              identifier
            }
            ... on Link {
              url
              text
            }
          }
          block {
            __typename
          }
          hyperlink {
            __typename
            ... on TextPage {
              sys {
                __typename
                id
              }
              slug
              __typename
            }
            ... on Link {
              sys {
                __typename
                id
              }
            }
          }
        }
      }
    }
    form {
      sys {
        id
      }
      fields: formCollection {
        items {
          ... on Input {
            label: title
            name: inputName
            type
            placeholder
          }
        }
      }
      button {
        text
      }
    }
  }

  query LegalTemplateQuery(
    $slug: String!
    $preview: Boolean!
    $locale: String!
  ) {
    textPageCollection(
      where: { slug: $slug }
      preview: $preview
      limit: 1
      locale: $locale
    ) {
      items {
        ...textPage
      }
    }
  }
`
