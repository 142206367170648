import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import { getCookie, getLeadData } from '@ecomm/shared-cookies'
import { currencyCode, getLocale, handleError, noValue } from '@ecomm/utils'
import { prop, voidFn } from '@simplisafe/ewok'
import { window } from 'browser-monads-ts'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'
import { UAParser } from 'ua-parser-js'

import { visitorIdAtAt } from '../atat'
import { OptimizelyPurchaseEvent } from '../optimizely'
import type { Product } from '../types/tracking'
import { getClientIPAddress, normalizePhone, sha256Web } from './lib'
import { handleFacebookEvent } from './lib/api'
import { CustomData, FacebookEventData } from './lib/types'

const userAgentParser = new UAParser()
const userAgentData = userAgentParser.getResult()

function getEventTime() {
  return Math.floor(Date.now() / 1000)
}

export type FacebookPageViewData = {
  readonly eventId: string
  readonly pageUrl: string
}

export type FacebookPurchaseEventData = {
  readonly phone: string
  readonly email: string
  readonly eventId: string
  readonly orderId: string
  readonly value: number
  readonly contents: ReadonlyArray<OptimizelyPurchaseEvent>
}

export const generateFacebookEventId = (): string =>
  (visitorIdAtAt() || '') + Date.now()

export function generateCommonFacebookEventData(
  retrieveLeadData = true
): TE.TaskEither<Error, Omit<FacebookEventData, 'eventName'>> {
  // @ts-expect-error
  return TE.tryCatch(async () => {
    const leadData = getLeadData()
    const leadEmail = prop('email', leadData)
    const leadPhone = prop('phone', leadData)
    return {
      eventId: generateFacebookEventId(),
      eventSourceUrl: window.location.href,
      eventTime: getEventTime(),
      testCode: process.env['FACEBOOK_CAPI_TEST_CODE'],
      userData: {
        clientIpAddress: await getClientIPAddress(),
        clientUserAgent: userAgentData.ua,
        em: retrieveLeadData && leadEmail && (await sha256Web(leadEmail)),
        externalId: visitorIdAtAt() || '',
        fbc: getCookie<string>('_fbc'),
        fbp: getCookie<string>('_fbp'),
        ph:
          retrieveLeadData &&
          leadPhone &&
          (await sha256Web(normalizePhone(leadPhone, getLocale())))
      }
    }
  }, handleError)
}

export const buildContents = (
  contents: ReadonlyArray<OptimizelyPurchaseEvent>
) => {
  return contents.map(product => {
    return {
      id: prop('sku', product),
      item_price: prop('price', product),
      quantity: prop('qty', product)
    }
  })
}

export const useFacebookTrackProductPurchase = () => {
  const locale = useLocale()
  return useCallback(
    (data: FacebookPurchaseEventData) => {
      return pipe(
        TE.Do,
        TE.bind('commonEventData', () =>
          generateCommonFacebookEventData(false)
        ),
        TE.bind('phone', () =>
          TE.tryCatch(
            () => sha256Web(normalizePhone(data?.phone, getLocale())),
            handleError
          )
        ),
        TE.map(({ commonEventData, phone }) => {
          const value = prop('value', data)
          const email = prop('email', data)
          const orderId = prop('orderId', data)
          const contents = data.contents
          const contentType: CustomData['contentType'] = 'product'
          const eventData: FacebookEventData = {
            ...commonEventData,
            actionSource: 'website',
            customData: {
              ...commonEventData.customData,
              contentType,
              contents: buildContents(contents),
              currency: currencyCode,
              orderId: orderId,
              value: value
            },
            // our GTM tag FCP - Facebook - System Purchase - OT uses orderId as the eventId
            eventId: orderId,
            eventName: 'Purchase',
            userData: {
              ...commonEventData.userData,
              em: email,
              ph: phone
            }
          }
          handleFacebookEvent(
            eventData,
            locale,
            () => logError(Error('Conversion API Purchase failure')),
            voidFn
          )
        })
      )()
    },
    [locale]
  )
}

export function getPageViewEventName(pageUrl: string) {
  if (
    pageUrl &&
    (pageUrl.includes('home-security-shop-packages') ||
      pageUrl.includes('business-security-shop'))
  ) {
    return 'ShopPageView'
  } else if (pageUrl && pageUrl.includes('home-security-system')) {
    return 'PackagePageView'
  } else {
    return 'PageView'
  }
}

export const useFacebookTrackSiteVisits = () => {
  const locale = useLocale()
  return useCallback(
    (data: FacebookPageViewData) => {
      const pageUrl = prop('pageUrl', data)
      return pipe(
        generateCommonFacebookEventData(),
        TE.map(commonEventData => {
          const eventData: FacebookEventData = {
            ...commonEventData,
            actionSource: 'website',
            eventId: prop('eventId', data),
            eventName: getPageViewEventName(pageUrl),
            eventSourceUrl: pageUrl
          }
          handleFacebookEvent(
            eventData,
            locale,
            () => logError(Error('Conversion API PageView failure')),
            voidFn
          )
        })
      )()
    },
    [locale]
  )
}

export function fbTrackAddProductsToCart(
  products: ReadonlyArray<Product>,
  packageName?: string
) {
  return pipe(
    generateCommonFacebookEventData(true),
    TE.map(commonEventData => {
      const contentType: CustomData['contentType'] = 'product'
      const eventData: FacebookEventData = {
        ...commonEventData,
        customData:
          products.length > 0
            ? {
                ...commonEventData.customData,
                contentIds: products.map(x => x.id ?? '').filter(x => x !== ''),
                contentName: packageName ?? noValue(),
                contentType,
                contents: products.map(x => {
                  return {
                    id: x.id,
                    item_price: x.price,
                    quantity: x.quantity
                  }
                }),
                currency: currencyCode
              }
            : noValue(),
        eventName: 'AddToCart'
      }
      handleFacebookEvent(
        eventData,
        getLocale(),
        () => logError(Error('facebook trackAddToCart failed')),
        voidFn
      )
    })
  )()
}

function hashPhone(phone: string | undefined) {
  return phone
    ? TE.tryCatch(
        () => sha256Web(normalizePhone(phone, getLocale())),
        handleError
      )
    : TE.of(noValue())
}

export function fbTrackLeadCreated(email: string, phone?: string) {
  return pipe(
    TE.Do,
    TE.bind('hashedEmail', () =>
      TE.tryCatch(() => sha256Web(email), handleError)
    ),
    TE.bind('hashedPhone', () => hashPhone(phone)),
    TE.bind('commonEventData', () => generateCommonFacebookEventData()),
    TE.map(({ commonEventData, hashedPhone, hashedEmail }) => {
      const eventData: FacebookEventData = {
        ...commonEventData,
        eventName: 'Lead',
        userData: {
          ...commonEventData.userData,
          em: hashedEmail,
          ph: hashedPhone || noValue()
        }
      }
      handleFacebookEvent(
        eventData,
        getLocale(),
        () => logError(Error('facebook TrackLeadCreated failed')),
        voidFn
      )
    })
  )()
}
