import {
  ContentfulRichTextSchema,
  GatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'

type InstallationInstructionProps = {
  readonly installationInstructions?: ContentfulRichTextSchema | null
  readonly installationImage?: GatsbyImageSchema | null
}

export default function InstallationInstructions({
  installationInstructions,
  installationImage
}: InstallationInstructionProps) {
  return !!installationImage && !!installationInstructions ? (
    <section className="flex flex-col items-center gap-4 md:flex-row md:gap-8 lg:gap-12">
      <div className="basis-full md:basis-1/2">
        <GatsbyImage className="rounded-base" image={installationImage} />
      </div>
      <div className="prose-headings:mb-4 prose-p:mb-3 md:prose-headings:mb-6 basis-full md:basis-1/2">
        <ContentfulRichText {...installationInstructions} />
      </div>
    </section>
  ) : null
}
