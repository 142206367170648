import { addAtomDebugLabel } from '@ecomm/utils'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'

const promoBannerIsFormSubmittedAtom = atomWithStorage(
  'promobanner_isFormSubmitted',
  false
)
addAtomDebugLabel(
  promoBannerIsFormSubmittedAtom,
  'Promo Banner - Is Form Submitted?'
)

const leadCaptureEmailSubmittedAtom = atomWithStorage(
  'leadCaptureEmail_isSubmitted',
  false
)
addAtomDebugLabel(
  leadCaptureEmailSubmittedAtom,
  'Lead Capture Email - Is Submitted?'
)

export function useLeadCaptureEmailSubmitted() {
  const [isSubmitted, setIsSubmitted] = useAtom(leadCaptureEmailSubmittedAtom)

  // Backwards compatibility only. See https://github.com/simplisafe/ss-ecomm-frontend/pull/6626#discussion_r1611750179
  const [isPromoBannerFormSubmitted] = useAtom(promoBannerIsFormSubmittedAtom)

  return useMemo(
    () => ({
      isSubmitted: isSubmitted || isPromoBannerFormSubmitted,
      setIsSubmitted
    }),
    [isSubmitted, isPromoBannerFormSubmitted, setIsSubmitted]
  )
}
