import { CheckmarkDarkBlue, Star, XmarkLightGray } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React from 'react'

import { replaceTags } from '../../utils'
import InfoButtonIcon from './InfoButtonModal'
import type { ComparisonTableRow } from './types'

export function Row({
  row,
  hasExpandableRows = false
}: {
  readonly row: ComparisonTableRow
  readonly hasExpandableRows?: boolean
}) {
  return (
    <tr
      className={classNames(
        'border-neutral-light-100 flex gap-1 border-x-0 border-b-0 border-t border-solid py-3 lg:py-5',
        { 'border-t first-of-type:border-t-0': hasExpandableRows }
      )}
    >
      <td
        className={classNames(
          'flex items-center justify-start lg:basis-2/3',
          hasExpandableRows ? 'basis-3/5' : 'basis-1/2'
        )}
      >
        <p
          className={classNames(
            hasExpandableRows ? 'text-xs lg:text-base' : 'text-xs md:text-lg'
          )}
        >
          {replaceTags(row.feature)}
          {row.infoIcon ? <InfoButtonIcon {...row.infoIcon} /> : null}
        </p>
      </td>

      {row.values
        ? row.values.map((value, index) => (
            <td
              className={classNames(
                'flex items-center justify-center text-sm lg:basis-1/6',
                hasExpandableRows ? 'basis-1/5' : 'basis-1/4'
              )}
              key={index}
            >
              {typeof value === 'string' ? (
                <span
                  className={classNames(
                    'text-center text-[10px] leading-3',
                    index === 0
                      ? 'font-bold text-neutral-black lg:text-base'
                      : 'font-normal text-neutral-dark lg:text-sm'
                  )}
                >
                  {replaceTags(value)}
                </span>
              ) : typeof value === 'boolean' && value ? (
                <CheckmarkDarkBlue className="h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10" />
              ) : (
                <XmarkLightGray className="h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10" />
              )}
            </td>
          ))
        : null}

      {row.ratings
        ? row.ratings.map((rating, index) => (
            <td
              className={classNames(
                'flex items-center justify-center text-sm lg:basis-1/6',
                hasExpandableRows ? 'basis-1/5' : 'basis-1/4'
              )}
              key={index}
            >
              <span
                className={classNames(
                  'text-center text-sm leading-3',
                  index === 0
                    ? 'font-bold text-neutral-black lg:text-base'
                    : 'font-normal text-neutral-dark'
                )}
              >
                {rating}{' '}
                <Star
                  className={classNames(
                    'h-3 w-3 lg:h-[14px] lg:w-[14px]',
                    index === 0 ? 'text-[#F79E00]' : 'text-[#8E8883]'
                  )}
                />
              </span>
            </td>
          ))
        : null}
    </tr>
  )
}
