import { getRudderstack } from '@ecomm/shared-window'

export function trackPackagePageMoreSensors() {
  getRudderstack(r => r.track('package_page_more_sensors'))
}

export function trackPackagePageProInstall(
  add_remove: 'checked' | 'unchecked'
) {
  getRudderstack(r =>
    r.track('package_page_pro_install', {
      add_remove
    })
  )
}

export function trackPackagePageSeeDetails() {
  getRudderstack(r => r.track('package_page_see_details'))
}

export function trackProInstallLearnMore() {
  getRudderstack(r => r.track('pro_install_learn_more'))
}

export function trackPackageSensorInfo(sensor: string) {
  getRudderstack(r =>
    r.track('package_sensor_info', {
      sensor
    })
  )
}

export function trackMonitoringTogglePackagePage(
  monitoring_plan: 'add-plan' | 'no-plan'
) {
  getRudderstack(r =>
    r.track('monitoring_toggle_package_page', {
      monitoring_plan
    })
  )
}
