import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { useOptimizelyParams, userAttributes } from '@ecomm/tracking'
import { selectCustomerGroupKey } from '@simplisafe/ss-ecomm-data/cart/select'
import {
  PriceData,
  Prices,
  requestPrices
} from '@simplisafe/ss-ecomm-data/prices/service'
import { selectItemsFromSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import { Maybe } from 'monet'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getRawDiscountedPrice, getRawPrice } from '../providers/PriceProvider'
import {
  getDiscountedTextHelper,
  getFormattedPriceHelper,
  getRawDiscountedPriceWithServicePlan
} from '../providers/PriceProvider/PriceProvider'

const useRequestPrice = (
  sku: string,
  showAbsoluteDiscountAsRelative = false,
  months = 1
) => {
  const [isMounted, setIsMounted] = useState(true)
  const [prices, setPrices] = useState<Prices>({})
  const [isLoading, setLoading] = useState(true)

  const maybeCustomerGroup = useSelector(selectCustomerGroupKey)
  const partnerCookie = getValueFromPartnerCookie('partnerGroup') || undefined
  const customerGroup = maybeCustomerGroup.orUndefined() || partnerCookie
  const partnerName = getValueFromPartnerCookie('partnerName') || undefined

  const optimizelyParams = useOptimizelyParams()

  const fallback = useSelector(selectItemsFromSkus([sku]))

  useEffect(() => {
    const attributes = userAttributes()
    isMounted &&
      requestPrices(
        [sku],
        attributes,
        customerGroup,
        optimizelyParams,
        partnerName
      )(() => {
        setLoading(false)
      })((response: Maybe<Record<string, PriceData>>) => {
        response.forEach((newPrices: Record<string, PriceData>) =>
          setPrices(newPrices)
        )
        setLoading(false)
      })

    return () => setIsMounted(false)
  }, [sku, isMounted, customerGroup, optimizelyParams, partnerName])

  return {
    getDiscountedPrice: getRawDiscountedPrice(
      prices,
      fallback,
      isLoading
    )(sku, months),
    getDiscountedPriceWithServicePlan: getRawDiscountedPriceWithServicePlan(
      prices,
      fallback,
      isLoading
    )(sku, months),
    getDiscountedText: getDiscountedTextHelper(
      prices,
      fallback,
      false,
      isLoading,
      showAbsoluteDiscountAsRelative
    )(sku, months),
    getDiscountedTextWithServicePlan: getDiscountedTextHelper(
      prices,
      fallback,
      true,
      isLoading,
      showAbsoluteDiscountAsRelative
    )(sku, months),
    getFormattedPrice: getFormattedPriceHelper(
      prices,
      fallback,
      isLoading
    )(sku, months),
    getPrice: getRawPrice(prices, fallback, isLoading)(sku, months)
  }
}

export default useRequestPrice
