import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { z } from '@simplisafe/ewok'

import { MonitoringPlan } from './types'

export const keyValueSchema = z.object({
  key: z.string(),
  value: z.string()
})

const modalSchema = z.object({
  description: z.object({ raw: z.string() }),
  headline: z.string()
})

export const productSchema = z.object({
  maxQuantity: z.number().optional(),
  name: z.string(),
  namePlural: z.string().optional(),
  sku: z.string()
})

export const priceToggleSchema = z.object({
  cta: z.string().nullable(),
  description: z.object({ raw: z.string() }).nullable(),
  modal: modalSchema.nullable(),
  name: z.string(),
  shortDescription: z.object({ raw: z.string() }).nullable(),
  sku: z.string().nullable(),
  type: z.nativeEnum(MonitoringPlan)
})

export const buttonSchema = z.object({
  text: z.string(),
  url: z.string()
})

export const bundleSchema = z.object({
  baseProduct: z.string(),
  otherItems: keyValueSchema.array(),
  products: productSchema.array()
})

const draftCartNoExperienceSchema = z.object({
  button: buttonSchema,
  contentful_id: z.string(),
  defaultBundle: bundleSchema,
  dependentProducts: z.array(productSchema),
  disclaimer: z.object({ raw: z.string() }).nullish(),
  errors: keyValueSchema.array().default([]),
  id: z.string(),
  outOfStock: z.string(),
  priceToggles: z.array(priceToggleSchema),
  satisfactionGuarantee: z.object({ raw: z.string() }).optional(),
  satisfactionGuaranteeImage: gatsbyImageSchema.optional(),
  shippingEstimateDate: z.string(),
  title: z.string().optional()
})

const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(draftCartNoExperienceSchema)
})

export const draftCartSchema = draftCartNoExperienceSchema.extend({
  nt_experiences: z.array(experimentSchema).default([])
})
