import { useCurrentPromoBannerData } from '@ecomm/promotions-hooks'
import React from 'react'

import { EndDatePill } from '../EndDatePill'

export function LeftLayoutWithSaleName() {
  const { saleName, saleNameLabel } = useCurrentPromoBannerData()
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <span
        className="mb-1 align-middle text-[36px] font-light uppercase leading-[30px]"
        data-component="sale-name"
      >
        {saleName}
      </span>
      {!!saleNameLabel && (
        <span
          className="mb-1 align-middle text-[26px] uppercase font-bold leading-[30px]"
          data-component="sale-label"
        >
          {saleNameLabel}
        </span>
      )}
      <div className="mt-2">
        <EndDatePill />
      </div>
    </div>
  )
}
