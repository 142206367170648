// extracted by mini-css-extract-plugin
export var additionalText = "CardItem-module--additionalText--b1894";
export var cardItem = "CardItem-module--cardItem--9a7f6";
export var cartUpdatedText = "CardItem-module--cartUpdatedText--b0f1e";
export var color = "CardItem-module--color--282c4";
export var colorDisabled = "CardItem-module--colorDisabled--cf46e";
export var content = "CardItem-module--content--ab101";
export var cta = "CardItem-module--cta--6aef4";
export var ctaContainer = "CardItem-module--ctaContainer--a647f";
export var description = "CardItem-module--description--ea281";
export var image = "CardItem-module--image--e54dd";
export var imageTag = "CardItem-module--imageTag--3184e";
export var price = "CardItem-module--price--a935b";
export var productLimitMessage = "CardItem-module--productLimitMessage--43d54";
export var quantity = "CardItem-module--quantity--0b653";
export var quantityChecker = "CardItem-module--quantityChecker--3669d";
export var selected = "CardItem-module--selected--9eb5c";
export var title = "CardItem-module--title--b3a53";
export var titleWrapper = "CardItem-module--titleWrapper--4e7d8";
export var variations = "CardItem-module--variations--9586f";