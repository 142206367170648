import { addAtomDebugLabel } from '@ecomm/utils'
import { atomWithReset } from 'jotai/utils'

import { DraftCartTotal } from '../../components/DraftCart/types'

export const totalPriceAtom = atomWithReset<DraftCartTotal>({
  discountedTotal: 0,
  total: 0
})

addAtomDebugLabel(totalPriceAtom, 'DraftCart: TotalPrice')
