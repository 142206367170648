import { contentfulImageSchema } from '@ecomm/contentful/components'
import { smallTextSchema } from '@ecomm/shared-data'
import { type TypeOf, z } from '@simplisafe/ewok'

export const linkSchema = z.object({
  __typename: z.literal('Link'),
  url: z.string(),
  linkText: z.string(),
  icon: contentfulImageSchema.nullish()
})

const items = z.union([
  z.array(smallTextSchema).readonly(),
  z.array(linkSchema).readonly()
])

type Items = TypeOf<typeof items>

export const iconSchema = contentfulImageSchema

export const gridSchema = z.object({
  headline: z.string().nullish(),
  headlineAlignment: z.enum(['left', 'center', 'right']).nullish(),
  showBorders: z.boolean().nullish(),
  showTextOnMobile: z.boolean().nullish(),
  className: z.string().optional(),
  gridItemsCollection: z.object({
    items
  }),
  desktopColumns: z.string().nullable(),
  tabletColumns: z.string().nullable(),
  mobileColumns: z.string().nullable(),
  __typename: z.literal('Grid')
})

export type GridSchema = TypeOf<typeof gridSchema>
export type IconSchema = TypeOf<typeof iconSchema>

export const isSmallTextArray = (
  items: Items
): items is ReadonlyArray<TypeOf<typeof smallTextSchema>> => {
  return items.findIndex(item => item.__typename === 'Text') !== -1
}

export const isLinkArray = (
  items: Items
): items is ReadonlyArray<TypeOf<typeof linkSchema>> => {
  return items.findIndex(item => item.__typename === 'Link') !== -1
}
