import { useCallback } from 'react'
import { useTracking } from 'react-tracking'
import { bannerClickEvent } from '../../../SmallTextSection'
import NavyCheckmark from '../../icons/NavyCheckmark'
import CartWidgetItem from '../CartWidgetItem'

export function TryItTestItCard() {
  const { trackEvent } = useTracking()
  return useCallback(() => {
    return (
      <CartWidgetItem
        dataComponentName="TryItTestItCard"
        description={
          <ul className="mt-6 list-none p-0">
            <li className="ml-6 pb-[1.1rem] pl-1 text-sm last:pb-0 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="m-0 px-2.5">60 day trial period</p>
              </div>
            </li>
            <li className="ml-6 pb-[1.1rem] pl-1 text-sm last:pb-0 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="m-0 px-2.5">
                  Subscription lifetime hardware warranty
                </p>
              </div>
            </li>
            <li className="ml-6 pb-[1.1rem] pl-1 text-sm last:pb-0 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="m-0 px-2.5">No long-term contracts</p>
              </div>
            </li>
            <li className="ml-6 pb-[1.1rem] pl-1 text-sm last:pb-0 md:ml-2.5">
              <div className="flex items-baseline">
                <NavyCheckmark color="#0b1d3c" />
                <p className="m-0 px-2.5">100% money-back guarantee</p>
              </div>
            </li>
          </ul>
        }
        heading={
          <h3 className="text-5 mb-3 mt-2 text-center">
            Try it, test it, love it or return it
          </h3>
        }
        icon={
          <img
            alt="100% Satisfaction Guarantee"
            className={'mx-auto block h-auto w-[5.625rem]'}
            height="440"
            src="//images.ctfassets.net/br4ichkdqihc/dXDphKOK8PVlC0FMxBazu/e4e699c89ba2e8e5316aff8af8545b99/100-seal_2x.png"
            width="436"
          />
        }
        onClick={() => bannerClickEvent(trackEvent, 'Try It Test It Card')}
        shouldTrackClicks
      />
    )
  }, [trackEvent])
}
