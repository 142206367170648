import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { useCookie } from './lib/useCookie'

export const useOptimizelyExcluded = (): readonly [
  boolean,
  (isExcluded: boolean) => void
] => {
  const [isExcludedStr, setExcludedStr] = useCookie('optimizely_excluded')

  const isExcluded = pipe(
    isExcludedStr,
    O.fold(
      () => false,
      _isExcluded => _isExcluded === 'true' || _isExcluded === '1'
    )
  )

  return [isExcluded, isExcluded => setExcludedStr(isExcluded.toString())]
}
