import {
  useOdmonDynamicPackagesExperience,
  useOdmonExperience
} from '@ecomm/shared-ninetailed-odmon'
import { getShowedOdmonIntentCookie } from '../../../utils/odmonIntentCookies'
import type { PackageType } from '../schema'
import { match } from 'ts-pattern'

/**
 * All non-dynamic packages should show ODMON content if in the ODMON Full Launch test
 *
 * When in control of the ODMON Dynamic Packages test:
 * No ODMON content on the dynamic PDPs
 *
 * When in variation of the ODMON Dynamic Packages test:
 * if “showedODMONIntent” = True: ODMON content on the dynamic PDPs
 * if “showedODMONIntent” = False: no ODMON content on the dynamic PDPs (same as control)
 */
export const useShowOdmonContent = (packageType: PackageType) => {
  const isInOdmonFullLaunchVariant = useOdmonExperience().isVariant
  const isInOdmonDynamicPackagesVariant =
    useOdmonDynamicPackagesExperience().isVariant
  const showedOdmonIntent = getShowedOdmonIntentCookie()

  const showContentOnDynamicPackagePage =
    isInOdmonFullLaunchVariant &&
    isInOdmonDynamicPackagesVariant &&
    showedOdmonIntent

  return match(packageType)
    .with('Dynamic', () => showContentOnDynamicPackagePage)
    .otherwise(() => isInOdmonFullLaunchVariant)
}
