import * as O from 'fp-ts/lib/Option'
import { createContext } from 'react'

export const ExperiencesContext = createContext<
  O.Option<Record<string, string>>
>(O.none)

/**
 * It takes an array of objects, where each object has the id and name for
 * a Ninetailed experiment, and returns a dictionary that maps these experiment's ids
 * to their names.
 */
export function createExperiencesDictionary(
  experiencesArray: readonly { readonly id: string; readonly name: string }[]
): O.Option<Record<string, string>> {
  return O.some(
    experiencesArray.reduce((acc, e) => ({ ...acc, [e.id]: e.name }), {})
  )
}
