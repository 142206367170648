import { Locale } from '../../hooks/useLocalizedData'

const createData = (body: string) => ({
  image: {
    description: 'Try it risk free',
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet:
              'https://images.ctfassets.net/br4ichkdqihc/6tlY4pZ17VZ1w8zz5yGXco/fa549fbe30751f2b4108511d204ac6c2/Scout-3x.png?w=327&h=241&q=50&fm=webp 327w,\nhttps://images.ctfassets.net/br4ichkdqihc/6tlY4pZ17VZ1w8zz5yGXco/fa549fbe30751f2b4108511d204ac6c2/Scout-3x.png?w=654&h=482&q=50&fm=webp 654w,\nhttps://images.ctfassets.net/br4ichkdqihc/6tlY4pZ17VZ1w8zz5yGXco/fa549fbe30751f2b4108511d204ac6c2/Scout-3x.png?w=1308&h=964&q=50&fm=webp 1308w',
            sizes: '(min-width: 1308px) 1308px, 100vw',
            type: 'image/webp'
          }
        ],
        fallback: {
          src: 'https://images.ctfassets.net/br4ichkdqihc/6tlY4pZ17VZ1w8zz5yGXco/fa549fbe30751f2b4108511d204ac6c2/Scout-3x.png?w=1308&h=964&q=50&fm=png',
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/6tlY4pZ17VZ1w8zz5yGXco/fa549fbe30751f2b4108511d204ac6c2/Scout-3x.png?w=327&h=241&q=50&fm=png 327w,\nhttps://images.ctfassets.net/br4ichkdqihc/6tlY4pZ17VZ1w8zz5yGXco/fa549fbe30751f2b4108511d204ac6c2/Scout-3x.png?w=654&h=482&q=50&fm=png 654w,\nhttps://images.ctfassets.net/br4ichkdqihc/6tlY4pZ17VZ1w8zz5yGXco/fa549fbe30751f2b4108511d204ac6c2/Scout-3x.png?w=1308&h=964&q=50&fm=png 1308w',
          sizes: '(min-width: 1308px) 1308px, 100vw'
        }
      },
      layout: 'constrained',
      width: 1308,
      height: 964,
      placeholder: {
        fallback:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAMAAADTRh9nAAAAqFBMVEUAAADMzMzS0tLT09PW1tbBwcHU1NTDw8PFxcXFxcXNzs3AwcDZ2dm5ubnR0dG9vb3W1tbBwsHa2trBwcG9vb3Q0NCAgIB+fn6ys7O1tbUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC0tLStra27u7vExMQAAAAAAADDw8O5ubk8PDwoKChfYGBHR0fQ0NDR0dHCwsLKysrOzs7U1NTNzs3S0tLExMTFxsVtV3aNAAAAKHRSTlMALl5eOsvv0fXR9dH10fXR9dH10bzfW+DbfgIEBQcICQwSw/f34AMBytaTxwAAAAlwSFlzAAAhOAAAITgBRZYxYAAAAAd0SU1FB+cFDBIgGavuSi4AAABdSURBVBjTY2DAAhjhDCD4A2WzwARZlBnvMqALMjExMlEkyIBFkBGhkgm/IJLj4dqBSn9hKGBkYuLEtOghI5zNKMWIBBi+MPAy3GRgesbzj/Pv3z+/f/74BnQARCUAoUAPIc75C1wAAAAASUVORK5CYII='
      }
    }
  },
  headline: 'Try it risk free',
  body: {
    raw: body
  },
  buttonText: 'Build a system',
  buttonLink: '/build-my-system',
  productSku: 'CM006-01DWW',
  className: 'mb-4 md:mr-4 md:mb-0 w-full md:h-full'
})

export const data = {
  [Locale.EnGB]: createData(
    `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Test out the Smart Alarm Wireless Indoor Camera. If you aren't 100% delighted, call us within 60 days for a full refund and let us pay for return shipping.","marks":[],"data":{}}]}]}`
  ),
  [Locale.EnUS]: createData(
    `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Test out the Smart Alarm Wireless Indoor Camera. If you aren't 100% delighted, call us within 60 days for a full refund and let us pay for return shipping.","marks":[],"data":{}}]}]}`
  )
}
