import {
  type ConfirmationModalSchema,
  confirmationModalSchema
} from '@ecomm/monitoring/components'
import { parseJSONDataFromContentful } from '@ecomm/scout'
import { graphql, useStaticQuery } from 'gatsby'

export const useConfirmMonitoringPlanQuery = (): ConfirmationModalSchema => {
  const json = parseJSONDataFromContentful(
    useStaticQuery(graphql`
      #graphql
      query ConfirmMonitoringPlanQuery {
        confirmationModal: contentfulJson(
          contentful_id: { eq: "4Sz4Pf9a5e9c97BO6xCwqC" }
        ) {
          ...jsonFragment
        }
      }
    `),
    'confirmationModal'
  )

  return confirmationModalSchema.parse(json)
}
