import mapboxgl from 'mapbox-gl'
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

export type MapProps = {
  readonly longitude: number
  readonly latitude: number
  readonly zoom: number
}

mapboxgl.accessToken = process.env.GATSBY_MAPBOX_TOKEN || ''

export function Map({ longitude, latitude, zoom }: MapProps) {
  const mapContainerRef = useRef(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current || 'map-container',
      style: 'mapbox://styles/simplisafe/clnxhxwut005t01p6889rgqqu',
      center: [longitude, latitude],
      zoom: zoom
    })

    map.addControl(new mapboxgl.NavigationControl(), 'top-right')
    map.scrollZoom.disable()

    return () => map.remove()
  }, [latitude, longitude, zoom])

  return (
    <>
      <Helmet>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v2.15.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>

      <div
        className="h-[520px] rounded-[20px]"
        data-component="map-container"
        id="map-container"
        ref={mapContainerRef}
      />
    </>
  )
}
