import { MaybeT } from '@simplisafe/ewok'
import {
  Product as _Product,
  Variant as _Variant
} from '@simplisafe/ss-ecomm-data/commercetools/products'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import type { GqlProductVariant } from './types'

type Option<T> = MaybeT<T & {}> & O.Option<T>

export type Product = _Product & {
  readonly discountedPrice: Option<number>
  readonly isIndeterminatePrice: boolean
}
export type Variant = _Variant & { readonly isIndeterminatePrice: boolean }

export function parseDate(d: string | null | undefined): number | undefined {
  return pipe(
    d,
    O.fromNullable,
    O.map(x => new Date(x).getTime()),
    O.toUndefined
  )
}

export function parseVariant(t: GqlProductVariant): Variant {
  return { ...t, productName: { 'en-US': t.name.en_US, 'en-GB': t.name.en_GB } }
}
