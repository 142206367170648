import { baseApiUrl } from '@ecomm/data-env-variables'
import { safeFetchJson } from '@jvlk/fp-ts-fetch'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

export type PayPalOrderSubmissionData = {
  readonly billingAgreementId: string
  readonly paymentMethodId: string
}

export type BraintreePaymentAuthData = {
  readonly nonce: string
  readonly details?: {
    readonly email: string
    readonly firstName: string
    readonly lastName: string
    readonly payerId: string
  }
}

export type BraintreeTokenizePayload = BraintreePaymentAuthData & {
  readonly billingAgreementId: string
}

export type ClientToken = { readonly clientToken: string }

const PAYMENT_DECORATOR_BASE_URL = `/payment-decorator/v1`

export const fetchClientToken = (): TE.TaskEither<
  Error | Response,
  ClientToken
> => {
  return pipe(
    TE.Do,
    TE.bind('baseUrl', () => baseApiUrl),
    TE.chain(({ baseUrl }) =>
      safeFetchJson<ClientToken>(
        `${baseUrl}${PAYMENT_DECORATOR_BASE_URL}/paypal/token`,
        { method: 'GET' }
      )
    )
  )
}

export const emptyBraintreePaymentAuthData: BraintreePaymentAuthData = {
  nonce: '',
  details: {
    email: '',
    firstName: '',
    lastName: '',
    payerId: ''
  }
}

export const fetchBillingAgreementId = (
  payment: BraintreePaymentAuthData
): TE.TaskEither<Error | Response, PayPalOrderSubmissionData> => {
  return pipe(
    TE.Do,
    TE.bind('baseUrl', () => baseApiUrl),
    TE.chain(({ baseUrl }) =>
      safeFetchJson<PayPalOrderSubmissionData>(
        `${baseUrl}${PAYMENT_DECORATOR_BASE_URL}/paypal/vault`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            nonce: payment.nonce,
            email: payment.details?.email,
            firstName: payment.details?.firstName,
            lastName: payment.details?.lastName,
            payerId: payment.details?.payerId
          })
        }
      )
    )
  )
}
