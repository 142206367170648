import { contentfulImageSchema } from '@ecomm/contentful/components'
import { contentfulRichTextSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const modalSchema = z.object({
  __typename: z.literal('ContentfulModal'),
  contentful_id: z.string(),
  description: contentfulRichTextSchema,
  headlineText: z.string(),
  image: contentfulImageSchema
})

export const odmonMonitoringPlanCardSchema = z.object({
  title: z.string(),
  subtitle: z
    .object({
      raw: z.string(),
      references: z.array(modalSchema).optional()
    })
    .nullish(),
  description: contentfulRichTextSchema,
  image: contentfulImageSchema.optional(),
  cta: z.string(),
  sku: z.string(),
  badge: z.string().optional(),
  disclaimer: z.string().optional()
})

export type OdmonMonitoringPlanCardSchema = TypeOf<
  typeof odmonMonitoringPlanCardSchema
>
