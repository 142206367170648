import { useCallback } from 'react'

import { useEventListener } from '../useEventListener'

export function useOnKeyUp(
  key: string,
  callback: (evt: KeyboardEvent) => void
) {
  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      event.key === key ? callback(event) : null
    },
    [callback, key]
  )

  useEventListener('keyup', <EventListener>handleKeyUp)
}
