import { LeadCapture as LeadCaptureForm } from '../../LeadCaptureForm'
import { LeftLayoutWithSaleName } from '../../LeftLayoutWithSaleName'
import { MiddleLayout } from '../MiddleLayout'

export function LayoutWithSaleName() {
  return (
    <div className="grid w-full max-w-[1300px] grid-flow-col grid-cols-12 items-center justify-items-center">
      <div className="col-span-3" data-component="LayoutSaleName">
        <LeftLayoutWithSaleName />
      </div>
      <div className="col-span-5" data-component="LayoutMiddle">
        <MiddleLayout />
      </div>
      <div className="col-span-4" data-component="LeadCapture">
        <LeadCaptureForm />
      </div>
    </div>
  )
}
