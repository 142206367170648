import {
  DetailBubble,
  type DetailBubbleProps,
  type DetailBubbleTheme
} from './DetailBubble'

type DetailBubblesProps = {
  readonly bubbles: readonly DetailBubbleProps[]
}

type Props = DetailBubblesProps & { readonly theme: DetailBubbleTheme }

export function DetailBubbles({ bubbles, theme }: Props) {
  return (
    <div className="inset-x-1/2 ml-[-50vw] mr-[-50vw] flex w-screen max-w-[100vw] justify-center">
      <div
        className="flex gap-4 overflow-x-auto p-2"
        data-component="DetailBubbles"
      >
        {bubbles.map((bubble, i) => (
          <DetailBubble
            icon={bubble.icon}
            key={i}
            text={bubble.text}
            theme={theme}
          />
        ))}
      </div>
    </div>
  )
}
