import React from 'react'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { bannerClickEvent } from '../../../SmallTextSection'
import CartWidgetItem from '../CartWidgetItem'

export function CustomerServiceCard(siteLocale: string) {
  const { trackEvent } = useTracking()
  return useCallback(() => {
    return (
      siteLocale === 'en-US' && (
        <CartWidgetItem
          dataComponentName="CustomerServiceCard"
          description={
            <>
              <p className="text-center text-sm leading-normal">
                Have questions before you checkout? We&apos;re here to help.
              </p>
              <p className="mb-0 text-center text-sm leading-normal">
                Call us at 800-548-9508
              </p>
            </>
          }
          heading={<h3 className="mb-3 mt-2 text-center">Help is available</h3>}
          icon={
            <img
              alt="customer support yellow"
              className={'mx-auto block h-auto w-[5.625rem]'}
              height="120"
              src="//images.ctfassets.net/br4ichkdqihc/3cxGSXyzBAjCV0kTx5DbBD/8be983945d6b8f15815f98112a12da63/png_Support-60_2x.png"
              width="120"
            />
          }
          onClick={() => bannerClickEvent(trackEvent, 'Customer Service Card')}
          shouldTrackClicks
        />
      )
    )
  }, [trackEvent])
}
