import { trackCartChangeAddCode } from '@ecomm/cdp-tracking'
import React from 'react'
import { useTracking } from 'react-tracking'

import { onOpenCouponFormTrack } from '../utils'

type Props = {
  readonly handleOpenForm: () => void
  readonly cartCouponCode: string
}

export function CartClosedCoupon({ handleOpenForm, cartCouponCode }: Props) {
  const { trackEvent } = useTracking()
  const buttonText = cartCouponCode ? 'Change code' : 'Add coupon code'

  const handleClick = () => {
    handleOpenForm()
    onOpenCouponFormTrack(
      trackEvent,
      buttonText.includes('Change') ? 'Change' : 'Add'
    )
    trackCartChangeAddCode(cartCouponCode)
  }

  return (
    <div className={`mb-4 flex items-center ${cartCouponCode ? '' : 'mt-4'}`}>
      <button
        className="text-complementary-blue-100 cursor-pointer border-none bg-transparent pl-0 text-sm font-medium underline md:text-lg"
        onClick={handleClick}
      >
        {buttonText}
      </button>
    </div>
  )
}
