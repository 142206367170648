import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.WarningBlack,
    text: 'Hidden panic button'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 1 yr'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.PeelAndStick,
    text: 'Peel-and-stick install'
  }
]

const description = {
  content: `One keypad is required for any system. An extra Keypad isn't required, but it can be convenient. Consider where adding one might make your life easier.
  \nIf you routinely use different entryways to your home, installing a Keypad by each door can save time. Or keep one in the bedroom for full control at any hour.
  `
}

const specs = {
  content: `3 x 6.25 x 0.87 inches
    \n4 AA Alkaline batteries (included)
    \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '1XMO7WimwbzkBYzZOWKE56'
}

export const extraKeyPadData: ProductData = {
  productId: '5XuXJr6VnVYqUu3evbkqa6',
  sensorName: 'Keypad',
  features,
  description,
  specs,
  faqs
}
