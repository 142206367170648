import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import { noValue } from '@ecomm/utils'
import { ContentfulRichTextGatsbyReference } from 'gatsby-source-contentful/rich-text'

import { contentfulRichTextSchema } from './contentfulRichTextSchema'

/**
 * @deprecated - Gatsby 4
 * use contentfulRichTextSchema instead
 */
export const jsonSchema = (__typename: string) => contentfulRichTextSchema

export function isContentfulRichTextGatsbyReference(
  t: ContentfulRichTextGatsbyReference | unknown
): t is ContentfulRichTextGatsbyReference {
  polyfillHasOwn()
  return (
    t !== null &&
    t !== noValue() &&
    (<ContentfulRichTextGatsbyReference>t).hasOwnProperty('__typename') &&
    (<ContentfulRichTextGatsbyReference>t).hasOwnProperty('contentful_id')
  )
}
