import { ctCartSchema } from '@simplisafe/eis-commercetools-carts'
import { z } from 'zod'

export const suggestedAddressSchema = z.object({
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipcode: z.string().optional(),
  plus4Code: z.string().optional(),
  additionalStreetInfo: z.string().optional()
})

export const addressSuggestionSchema = z.object({
  address: suggestedAddressSchema.optional(),
  corrections: z.array(z.string()).optional(),
  footnotes: z.array(z.string()).optional()
})

export const addressValidationSchema = z.object({
  validated: z.boolean(),
  foundValidAddress: z.boolean(),
  suggestions: z.array(addressSuggestionSchema)
})

export const checkoutResponseSchema = z
  .object({
    cart: ctCartSchema,
    addressValidation: addressValidationSchema
  })
  .passthrough()
