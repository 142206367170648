import { z } from 'zod'

import { ukPostalCodeRegex, usPostalCodeRegex } from './regex'

export const usPostalCodeSchema = z
  .string({ required_error: 'Postal Code is a required field' })
  .refine(
    val => usPostalCodeRegex.test(val.replaceAll(' ', '')),
    val => ({ message: `${val} is not a valid US postal code` })
  )

export const ukPostalCodeSchema = z
  .string({ required_error: 'Postal Code is a required field' })
  .refine(
    val => ukPostalCodeRegex.test(val),
    val => ({ message: `${val} is not a valid UK postal code` })
  )

export const usPostalCodeSchemaExperiment = z
  .string({ required_error: 'ZIP Code is a required field' })
  .refine(
    val => usPostalCodeRegex.test(val.replaceAll(' ', '')),
    val => ({ message: `${val} is not a valid US ZIP code` })
  )
