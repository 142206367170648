import { useIntersection } from '@ecomm/shared-hooks'
import { RefObject } from 'react'

const useAffirmBannerIntersection = (
  id: string,
  ref: RefObject<HTMLElement>
) => {
  const validIds = [
    '121ede03-6f35-5da4-99a7-bb6af37957dd',
    '9636871b-13d2-5ddd-b3d6-9cc097bdb2bd'
  ]

  const isAffirmBanner = (contentfulId: string) =>
    validIds.includes(contentfulId)

  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1
  })

  return !isAffirmBanner(id) ? null : intersection
}

export default useAffirmBannerIntersection
