import type { Address } from '@simplisafe/eis-commercetools-carts'
import equals from 'fast-deep-equal'
import { match } from 'ts-pattern'

import { addAtomDebugLabel } from '@ecomm/utils'
import { useAtom } from 'jotai'
import { atomWithReducer } from 'jotai/utils'
import { useEffect } from 'react'
import { useCartValue } from '../atoms/useCart'

// https://jotai.org/docs/recipes/atom-with-compare
const baseShippingAddressAtom = atomWithReducer(
  null,
  (prev: Address | null, next: Address | null) => {
    return equals(prev, next) ? prev : next
  }
)
addAtomDebugLabel(baseShippingAddressAtom, 'Cart - Shipping Address')

/**
 * Get the shipping address from a jotai cart, with deep equality checks
 */
export const useCartShippingAddress = (): Address | null => {
  const cart = useCartValue()
  const [shippingAddress, setShippingAddress] = useAtom(baseShippingAddressAtom)

  const cartShippingAddress = match(cart)
    .with({ _tag: 'not_initialized' }, _ => null)
    .with({ _tag: 'error' }, _ => null)
    .with({ _tag: 'empty' }, _ => null)
    .with({ _tag: 'no_cart_id' }, _ => null)
    .with({ _tag: 'updating' }, cart => cart.val.shippingAddress)
    .with({ _tag: 'with_items' }, cart => cart.val.shippingAddress)
    .exhaustive()

  useEffect(() => {
    setShippingAddress(cartShippingAddress)
  }, [cartShippingAddress, setShippingAddress])

  return shippingAddress
}
