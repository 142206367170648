/**
 * A more strict version of devError that will break the development build instead of just logging the error to the console.
 */
export function devThrowError(err: Error): void {
  if (
    process.env['NODE_ENV'] === 'development' ||
    import.meta.env?.MODE === 'development'
  ) {
    throw err
  } else {
    return undefined
  }
}
