import { useConfidenceBarQuery } from '@ecomm/lander/src/data/queries'
import type { PackageFragment } from '@ecomm/partners-hooks'
import { ConfidenceBar } from '@ecomm/scout'

import { BuildMySystemBanner } from '../BuildMySystemBanner'
import { MonitoringWrapper } from '../Monitoring'
import { PackageListItem } from '../PackageListItem'

type Props = {
  readonly packagesData: readonly PackageFragment[]
  readonly showPartnerPackageAbsoluteDiscountAsRelative?: boolean
}

export default function StrategicReferral({
  packagesData,
  showPartnerPackageAbsoluteDiscountAsRelative
}: Props) {
  const confidenceBarData = useConfidenceBarQuery('2yJuhWpaW0nmKtDSEIEkxM')
  return (
    <div>
      <div className="p-5 lg:p-8">
        <ConfidenceBar data={confidenceBarData.content} />
      </div>

      <p id="packages"></p>
      {/* this id is to auto-scroll the user down from the hero banner shop now button*/}
      <div className="max-w-8xl mx-auto flex flex-col gap-6 p-4 md:gap-8 md:p-8">
        {packagesData.map((partnerPackage, index) => (
          <>
            <PackageListItem
              item={partnerPackage}
              showPartnerPackageAbsoluteDiscountAsRelative={
                showPartnerPackageAbsoluteDiscountAsRelative
              }
            />
            {index === 0 && <MonitoringWrapper />}
          </>
        ))}
      </div>
      <BuildMySystemBanner />
    </div>
  )
}
