import type { BraintreePaymentAuthData } from '@ecomm/data-simplisafe-api'
import { useTrackPaypal } from '@ecomm/tracking'
import {
  type CreateBillingAgreementActions,
  type OnApproveBraintreeActions,
  BraintreePayPalButtons
} from '@paypal/react-paypal-js'
import type { BraintreePayPalCheckoutTokenizationOptions } from '@paypal/react-paypal-js/dist/types/types/braintree/paypalCheckout'
import { useCallback } from 'react'

import type { PaymentState } from '../PaymentFormWrapper/types'

export type PayPalButtonWrapperProps = {
  readonly handleSubmitOrder: (
    orderData: BraintreePaymentAuthData
  ) => Promise<void>
  readonly setPaymentState: (paymentState: PaymentState) => void
}

export function PayPalButtonWrapper({
  handleSubmitOrder,
  setPaymentState
}: PayPalButtonWrapperProps) {
  const { trackPaypalCheckoutClick, trackPaypalAuthState } = useTrackPaypal()

  const onSubmit = useCallback(
    (orderData: BraintreePaymentAuthData) => {
      trackPaypalAuthState('success')
      handleSubmitOrder(orderData)
    },
    [handleSubmitOrder, trackPaypalAuthState]
  )

  const createBillingAgreement = async (
    _data: Record<string, unknown>,
    actions: CreateBillingAgreementActions
  ) => {
    return actions.braintree
      .createPayment({
        flow: 'vault',
        currency: '840'
      })
      .then(orderId => {
        trackPaypalCheckoutClick()
        return orderId
      })
  }

  const onApprove = async (
    data: BraintreePayPalCheckoutTokenizationOptions,
    actions: OnApproveBraintreeActions
  ) => {
    const payment = await actions.braintree.tokenizePayment(data)

    return onSubmit({ ...payment })
  }

  const onError = () => {
    trackPaypalAuthState('failure')
    setPaymentState('error')
  }

  const onCancel = () => {
    setPaymentState('ready')
  }

  return (
    <BraintreePayPalButtons
      createBillingAgreement={createBillingAgreement}
      fundingSource="paypal"
      onApprove={onApprove}
      onCancel={onCancel}
      onError={onError}
      style={{ shape: 'rect', height: 42 }}
    />
  )
}
