import { featuresAlarmMonitoringTemplateSchema } from './schema'

export const useFeaturesAlarmPageFragment = (query: {
  readonly contentfulMonitoringPage: unknown
}) => {
  const featuresAlarmFragment = query.contentfulMonitoringPage

  return {
    ...featuresAlarmMonitoringTemplateSchema.parse(featuresAlarmFragment)
  }
}
