import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { TypeOf } from '@simplisafe/ewok'
import z from 'zod'

const idSchema = z.object({
  id: z.string()
})

const guidedSystemBuilderSchemaDraft = z.object({
  __typename: z.literal('QuoteWizard').optional(),
  sys: idSchema,
  id: idSchema.transform(sysObj => sysObj.id),
  jebbitUrl: z.string().optional(),
  type: z.enum([
    'embedded',
    'floating',
    'guarantee',
    'hero',
    'monitoring_component',
    'popup',
    'nav'
  ]),
  buttonText: z.string().nullish()
})

const experimentSchema = experimentTemplateSchema.extend({
  variants: z.object({
    items: z.array(guidedSystemBuilderSchemaDraft).default([])
  })
})

export const guidedSystemBuilderSchema = guidedSystemBuilderSchemaDraft.extend({
  ntExperiencesCollection: z.object({
    items: z.array(experimentSchema).default([])
  })
})

export type GuidedSystemBuilderSchema = TypeOf<typeof guidedSystemBuilderSchema>
