import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

const reviewSchema = z.object({
  company: z.string(),
  logo: gatsbyImageSchema.nullish(),
  review: z.object({ raw: z.string() })
})

export const reviewSectionSchema = z.object({
  __typename: z.literal('ContentfulReviewSection'),
  button: z
    .object({
      newTab: z.boolean().nullable(),
      text: z.string(),
      url: z.string()
    })
    .optional(),
  reviews: z.array(reviewSchema).optional()
})

export type ReviewFragment = TypeOf<typeof reviewSchema>
export type ReviewSectionFragment = TypeOf<typeof reviewSectionSchema>
