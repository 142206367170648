import { HeaderNavigation } from '@ecomm/ss-react-components'
import { safeProp } from '@simplisafe/monda'
import { Maybe } from 'monet'
import map from 'ramda/src/map'
import React, { HTMLAttributes, ReactElement } from 'react'
import { useTracking } from 'react-tracking'

import { useHeaderData } from './hooks'
import { renderNavItem } from './NavItem'
import { ContentfulHeaderFragment, NavItem } from './query'

const getContentElements = (
  data: Maybe<readonly NavItem[]>,
  cartItemCount: number,
  trackEvent: any,
  side: string
) => {
  return data
    .map(
      map<NavItem, ReactElement | null>(item =>
        renderNavItem(item, true, cartItemCount, false)
      )
    )
    .map(links => (
      <HeaderNavigation key={`${side}-navigation`}>
        <>{links}</>
      </HeaderNavigation>
    ))
}

function DesktopHeader({
  data,
  className
}: HTMLAttributes<HTMLDivElement> & {
  readonly data: ContentfulHeaderFragment
}) {
  const { cartItemCount } = useHeaderData()
  const { trackEvent } = useTracking()

  const leftContent = getContentElements(
    safeProp('leftContent', data),
    cartItemCount,
    trackEvent,
    'left'
  )
  const rightContent = getContentElements(
    safeProp('rightContent', data),
    cartItemCount,
    trackEvent,
    'right'
  )

  return (
    <div className={`${className}`}>
      {leftContent.orNull()}
      {rightContent.orNull()}
    </div>
  )
}

export default DesktopHeader
