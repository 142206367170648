//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import path from 'ramda/src/path'
import React, { FC, ReactElement, ReactNode } from 'react'

import { SSInput } from '..'
import { BackgroundComponentType } from '../common'
import SSButton, { SSButtonProps } from '../SSButton'
import * as css from './LocationBanner.module.scss'

type ButtonIcon = {
  readonly icon: ReactElement | ReactNode
  readonly text: string
  readonly onClick: () => void
}

export type SquareSection = {
  readonly color: string
  readonly count: string
  readonly title: string
}

export type LocationBannerProps = {
  readonly title: string
  readonly enterLocationText?: string
  readonly locationLink: ButtonIcon
  /** The content of the component */
  readonly content?: ReactNode
  /** Discalaimer text Description prop */
  /** The content of the disclaimer */
  readonly disclaimerContent?: ReactNode
  readonly placeholder: string
  readonly searchButton: SSButtonProps
  /** To get the gatsby-background-image component for rendering backdrop image */
  readonly BackgroundComponent?: BackgroundComponentType
  /** Props of gatsby-background-image component for rendering background banner image */
  readonly backgroundComponentProps?: Record<string, unknown>
  /** The content when there are results */
  readonly searchResultsContent?: ReactNode
  /** The content when there are no results */
  readonly noResultsContent?: ReactNode
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  readonly onSubmit: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  readonly response?: Record<string, unknown>
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const LocationBanner: FC<LocationBannerProps> = ({
  title,
  enterLocationText,
  locationLink,
  content,
  disclaimerContent,
  searchButton,
  BackgroundComponent,
  backgroundComponentProps,
  onSubmit,
  onChange,
  placeholder,
  searchResultsContent,
  noResultsContent,
  response,
  dataComponent = LocationBanner.name
}: LocationBannerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const searchLocation = ifElse(isNil, always(''), path(['location']))(response)

  const roundedClasses = 'rounded-base overflow-hidden rounded-b-none'

  const renderImageContent = () => {
    return (
      <>
        <div className={classNames(roundedClasses, css.cbannerWrap)}>
          <h3 className={classNames('h3 text-white', css.cbannerTitle)}>
            {title}
          </h3>
          <form>
            <div className={classNames(css.cbannerInputGroup)}>
              <SSInput
                className={classNames(css.cbannerInputAddress)}
                disabled={false}
                hideLabel={true}
                // TODO This shouldn't have a default value. Supply via Contentful.
                id="locationInput"
                label="Enter Location"
                onChange={onChange}
                placeholder={placeholder}
                required={false}
                type="text"
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={searchLocation}
              />
              <SSButton
                {...searchButton}
                className={classNames(css.cbannerSubmit)}
                disabled={isEmpty(searchLocation)}
                hasHover={false}
                onClick={onSubmit}
                type="submit"
              ></SSButton>
            </div>
            <div className={classNames(css.cbannerLabel)}>
              <label>{enterLocationText}</label>
              <div className={classNames(css.locationIcon)}>
                <button
                  aria-label={locationLink.text}
                  className={classNames(css.locationBtn)}
                  onClick={locationLink.onClick}
                  type="button"
                >
                  {locationLink.icon}
                  <span>{locationLink.text}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        {content ? (
          <div
            className={classNames(css.cbannerContent, 'neutralWhiteTextColor')}
          >
            {content}
          </div>
        ) : null}
      </>
    )
  }

  return (
    <div data-component={dataComponent}>
      {BackgroundComponent ? (
        <BackgroundComponent
          {...backgroundComponentProps}
          className={classNames(roundedClasses, css.crimeBanner)}
        >
          {renderImageContent()}
        </BackgroundComponent>
      ) : (
        <section
          className={classNames(roundedClasses, css.crimeBanner)}
          data-component={`${dataComponent}_noBackground`}
        >
          {renderImageContent()}
        </section>
      )}
      {disclaimerContent ? (
        <div className="neutralMediumGrayBackgroundColor px-4 py-4">
          {disclaimerContent}
        </div>
      ) : null}
      {searchResultsContent && !isNil(response) ? (
        <div
          className={classNames(
            css.searchResult,
            'breakLine neutralBlackTextColor'
          )}
        >
          {searchResultsContent}
        </div>
      ) : null}
      {noResultsContent && !isNil(response) ? (
        <div
          className={classNames(
            css.searchResult,
            'breakLine neutralBlackTextColor'
          )}
        >
          {noResultsContent}
        </div>
      ) : null}
    </div>
  )
}

export default LocationBanner
