import { useCartLoaded } from '@ecomm/data-cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

export const useIsCartLoaded = (useJotaiCart: boolean) => {
  const reduxCart = useSelector(selectCart)
  const reduxCartLoaded = reduxCart.cata(
    () => false,
    () => false,
    () => false,
    () => true
  )
  const jotaiCartLoaded = useCartLoaded()

  return useJotaiCart ? jotaiCartLoaded : reduxCartLoaded
}
