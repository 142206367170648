import { useHasPremiumMonitoringPlan } from '@ecomm/cart-line-items'
import { useIsVariationOfExperiment } from '@ecomm/promotions-hooks'

export const useIsVisaGiftCardPromotionActive = () => {
  // Check if the user has premium monitoring in the cart
  const hasMonitoring = useHasPremiumMonitoringPlan()

  // Check if the user is in the experiment
  const isFree100VisaGiftCardExperiment = useIsVariationOfExperiment(
    'all___us___promo___free__100_visa_gift_card'
  )

  return hasMonitoring && isFree100VisaGiftCardExperiment
}
