import React from 'react'

type Props = {
  readonly onPrevious: () => void
  readonly onNext: () => void
  readonly current: number
  readonly max: number
}

export default function Arrows({ onPrevious, onNext, current, max }: Props) {
  return (
    <div className="flex items-center gap-4">
      <button
        aria-label="Previous Slide"
        className="outline-revert text-neutral-medium flex cursor-pointer items-center justify-center rounded-full border-none bg-transparent p-0 text-black"
        data-component="ArrowControls/PreviousButton"
        name="Previous Slide"
        onClick={onPrevious}
        type="button"
      >
        <svg
          fill="none"
          height="48"
          viewBox="0 0 48 48"
          width="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" fill="white" r="24" />
          <path
            d="M28 14L17 23.5L28 33"
            stroke="#0F2648"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </svg>
      </button>
      <p className="m-0 text-[18px]" data-component="ArrowControls/Page">
        {current} of {max}
      </p>
      <button
        aria-label="Next Slide"
        className="outline-revert text-neutral-medium flex cursor-pointer items-center justify-center rounded-full border-none bg-transparent p-0 text-black"
        data-component="ArrowControls/NextButton"
        name="Next Slide"
        onClick={onNext}
        type="button"
      >
        <svg
          fill="none"
          height="48"
          viewBox="0 0 48 48"
          width="48"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="24"
            cy="24"
            fill="white"
            r="24"
            transform="rotate(-180 24 24)"
          />
          <path
            d="M20 34L31 24.5L20 15"
            stroke="#0F2648"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </svg>
      </button>
    </div>
  )
}
