import { fetchApi } from '@ecomm/data-simplisafe-api'
import { Maybe } from '@simplisafe/ewok' // Redux needs a Maybe type to continue to function the same way
import { CTCartToRecord } from '@simplisafe/ss-ecomm-data/cart'
import { setCart, setCartLoading } from '@simplisafe/ss-ecomm-data/cart/actions'
import type { ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import {
  selectCart,
  selectCartId
} from '@simplisafe/ss-ecomm-data/redux/select'
import type { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import {
  expandCustomerGroup,
  expandDiscountCode,
  expandProductType
} from '@simplisafe/ss-ecomm-data/secrets/commercetools'
import type { RejectFunction, ResolveFunction } from 'fluture'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import type { ThunkDispatch } from 'redux-thunk'

import { isCartResponse } from './isCartResponse'

export const applyDiscount =
  (
    discountCode: string,
    handleFailure?: RejectFunction<void>,
    handleSuccess?: ResolveFunction<void>
  ) =>
  (
    dispatch: ThunkDispatch<ImmutableState, void, ACTION>,
    getState: () => ImmutableState
  ) => {
    const cart = selectCart(getState())
    const cartId = selectCartId(getState())

    const updateCart = (cartId: string) => {
      dispatch(setCartLoading(true))
      Maybe.some(
        dispatch(
          pipe(
            fetchApi({
              endpoint: `/carts/v2/carts/${cartId}:applyDiscount?${expandProductType}&${expandDiscountCode}&${expandCustomerGroup}`,
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ discountCode })
            }),
            TE.chain(_cart => {
              if (isCartResponse(_cart)) {
                dispatch(setCartLoading(false))
                dispatch(setCart(Maybe.fromUndefined(CTCartToRecord(_cart))))
                return TE.right(handleSuccess && handleSuccess())
              } else {
                return TE.left(_cart)
              }
            }),
            TE.mapLeft(res => {
              dispatch(setCartLoading(false))
              handleFailure && handleFailure()
              return res
            })
          )
        )
      )
    }

    cart.cata(
      () => null, // don't do anything if cart is loading
      // if cart is in error, only update it if we have a cart
      () => cartId.forEach(_cartId => updateCart(_cartId)),
      () => null, // don't do anything if we don't have a cart
      cart => updateCart(cart.id)
    )
  }
