import { Link } from '@ecomm/framework'
import React, { type HTMLAttributes, type ReactElement } from 'react'
import { useTracking } from 'react-tracking'
import type { Z } from '@simplisafe/ewok'
import type {
  LegalLink,
  LegalLinks,
  trackingEventSchema
} from '@ecomm/footer-data'

type LegalLinksProps = HTMLAttributes<HTMLUListElement> & {
  readonly links: LegalLinks
}

function LegalLink({
  link
}: {
  readonly link: LegalLink
}): ReactElement {
  const { trackEvent } = useTracking<{
    readonly event: Z.infer<typeof trackingEventSchema>
  }>()

  return (
    <li className="text-end md:text-center">
      <Link
        className="mr-6 text-xs no-underline hover:underline md:mr-0 lg:ml-4"
        onClick={() =>
          link.trackingEvent &&
          trackEvent({
            event: link.trackingEvent
          })
        }
        to={link.url}
      >
        {link.text}
      </Link>
    </li>
  )
}

export function LegalLinks({
  links,
  className = '',
  style
}: LegalLinksProps): ReactElement {
  return (
    <ul className={`m-0 flex p-3 ${className} items-end`} style={style}>
      {links.map((link, index) => (
        <LegalLink key={`tertiary_links${index}`} link={link} />
      ))}
    </ul>
  )
}
