import { useTrackAddProductToCart } from '@ecomm/cdp-tracking-utils'
import { logError } from '@ecomm/error-handling'
import { useMicroCopy } from '@ecomm/micro-copy'
import { LoadingSpinner, SimpleButton } from '@ecomm/ss-react-components'
import { useOptimizelyTrackSiteEvents } from '@ecomm/tracking'
import { useLocation } from '@reach/router'
import { localStorage } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import {
  IOAddServicePlanToCart,
  IOAddToCart
} from '@simplisafe/ss-ecomm-data/cart'
import type { AddServicePlanBody } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { liftSelectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import AddToCartError, {
  type AddToCartErrorType
} from '../../errorComponents/AddToCartError'
import { trackAddToCartEvent } from '../../util/analytics/addToCart'

export type AddToCartButtonProps = {
  readonly productSku: string
  readonly className?: string
  readonly buttonText?: ReactNode
}

export default function AddToCartButton({
  data,
  quantity = 1
}: {
  readonly data: AddToCartButtonProps
  readonly quantity?: number
}) {
  const dispatch = useDispatch()
  const skuID = safeProp('productSku', data)
  const location = useLocation()
  const isChangePlan = location.pathname === '/change-monitoring'
  const product = useSelector(liftSelectProduct(skuID))
  const parentId: string = localStorage.get('parentId')
  const url = '/cart'
  const className = data.className || ''

  const [addToCartError, setAddToCartError] = useState<AddToCartErrorType>(null)
  const [showSpinner, setShowSpinner] = useState(true)

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const microCopy = useMicroCopy()
  const { Track, trackEvent } = useTracking()

  const buttonLabel = safeProp('buttonText', data).getOrElse(
    microCopy['add-to-cart']
  )

  useEffect(() => {
    skuID && setShowSpinner(false)
  }, [skuID])

  const urlRedirect = (url: string) => {
    navigate(url)
  }

  const { trackAddAccessoryToCartEvent } = useTrackAddProductToCart()

  const addToCart = useCallback(() => {
    const handleSuccess = () => {
      setShowSpinner(false)
      optimizelyTrackSiteEvents({ eventType: 'add_to_cart_clicked' })
      trackAddToCartEvent(product, trackEvent, quantity)
      trackAddAccessoryToCartEvent({ product, quantity })
      url && urlRedirect(url)
    }

    const handleFailure = () => {
      setShowSpinner(false)
      setAddToCartError('recoverable')
      optimizelyTrackSiteEvents({ eventType: 'website_error' })
    }

    skuID.cata(
      () => {
        setShowSpinner(false)
        setAddToCartError('unrecoverable')
        logError(Error('Cannot add to cart: received null/empty sku'))
      },
      _sku => {
        const product = {
          quantity,
          sku: _sku
        }
        const productWithParentId: AddServicePlanBody = {
          action: 'addServicePlan',
          packageParentId: parentId,
          sku: _sku
        }

        isChangePlan
          ? dispatch(
              IOAddServicePlanToCart(
                [productWithParentId],
                handleFailure,
                handleSuccess
              )
            )
          : dispatch(
              IOAddToCart({ products: [product] }, handleFailure, handleSuccess)
            )
      }
    )
  }, [quantity])

  return (
    <Track>
      <SimpleButton
        aria-label="Add to cart"
        className={classNames(
          'w-52 shrink-0 self-center text-base md:w-64 md:text-lg lg:self-auto',
          className
        )}
        disabled={showSpinner}
        onClick={addToCart}
        variant="solid"
      >
        {showSpinner ? (
          <LoadingSpinner fillColor="var(--complementary-blue-100)" size={24} />
        ) : (
          buttonLabel
        )}
      </SimpleButton>
      {addToCartError ? (
        <div
          style={{
            margin: '8px auto auto',
            maxWidth: '200px'
          }}
        >
          <AddToCartError errorType={addToCartError} textAlign="center" />
        </div>
      ) : null}
    </Track>
  )
}
