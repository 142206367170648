import { Header as HeaderAtom } from '@ecomm/ss-react-components'
import React from 'react'

import DesktopHeader from './DesktopHeader'
import { MobileOrTabletHeader } from './MobileOrTabletHeader'
import { ContentfulHeaderFragment } from './query'

export type HeaderProps = {
  readonly data: ContentfulHeaderFragment
  readonly hasProgressBar?: boolean
}

function Header({ data, hasProgressBar }: HeaderProps) {
  return (
    <HeaderAtom hasProgressBar={hasProgressBar} key="header">
      <MobileOrTabletHeader
        className="flex w-full items-center justify-between lg:hidden"
        data={data}
      />
      <DesktopHeader
        className="hidden w-full items-center justify-between lg:flex"
        data={data}
      />
    </HeaderAtom>
  )
}

export default Header
