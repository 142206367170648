import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 5 yrs'
  },
  {
    icon: SharedIcons.MedicalCross,
    text: 'Medical alerts'
  },
  {
    icon: SharedIcons.GlassDetection,
    text: '30 ft range'
  },
  {
    icon: SharedIcons.WarningBlack,
    text: 'Silent or audible alarms'
  }
]

const description = {
  content: `Our Key Fob works just like your car's. You can arm and disarm your whole system with the push of a button, and it works from up to 30' away. With a professional monitoring plan, you can press the Panic Button to alert the monitoring center immediately in case of emergency.
  `
}

const specs = {
  content: `1 x 3 x 0.25 inches
  \n1 CR-2032 Lithium 3V battery (included; non replaceable)
  \nCustomize to send an audible or silent alarm
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: 'WnGwHa5n6VOnkjuylRzdF'
}

export const keyFobData: ProductData = {
  productId: '4byr1XE8ZCzIjVWg2ToBk8',
  sensorName: 'Key Fob',
  features,
  description,
  specs,
  faqs
}
