import { useShouldShowPromoBanners } from '@ecomm/promotions-hooks'
import { match } from 'ts-pattern'

import { CartBanner } from '../lib/CartBanner'
import { DesktopBanner } from '../lib/DesktopBanner'
import { MobileBanner } from '../lib/MobileBanner'
import { PromoStyler } from '../lib/PromoStyler'
import { PromoBannerPageType } from '../types'

export interface SitewidePromoBannerProps {
  readonly type: PromoBannerPageType
}

export function SitewidePromoBanner({ type }: SitewidePromoBannerProps) {
  const _shouldShowPromoBanners = useShouldShowPromoBanners()
  const shouldShowPromoBanners = _shouldShowPromoBanners && type !== 'none'

  return shouldShowPromoBanners ? (
    <PromoStyler>
      {match(type)
        .with('cart', () => <CartBanner />)
        .otherwise(() => (
          <>
            <DesktopBanner />
            <MobileBanner type={type} />
          </>
        ))}
    </PromoStyler>
  ) : null
}
