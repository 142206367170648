import { useMicroCopy } from '@ecomm/micro-copy'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useState } from 'react'
import { ZodError } from 'zod'

import useUnsubscribeEmail from '../../hooks/useUnsubscribeEmail'
import { emailSchema } from '../../schemas/email'
import { ContentSchema } from '../../templates/UnsubscribeEmail/schema'
import Checkbox from './Checkbox'
import Input from './Input'
import ResultMessage from './ResultMessage'

export default function UnsubscribeForm({
  contactText,
  contactTitle,
  emailForm,
  resubscribeForm
}: ContentSchema) {
  const [emailInputState, setEmailInputState] = useState({
    errorMessage: '',
    error: false,
    value: ''
  })
  const [resubscribeCheckboxActive, setResubscribeCheckboxActive] =
    useState(false)
  const { isLoading, unsubscribeEmail, resubscribeEmail, status } =
    useUnsubscribeEmail()

  const validateEmail = (value?: string) => {
    return E.tryCatch(
      () => {
        emailSchema.parse(value)
        setEmailInputState({
          error: false,
          errorMessage: '',
          value: value ?? ''
        })
      },
      (error: unknown) => {
        const msg = pipe(
          E.fromPredicate(
            (error: unknown): error is ZodError => error instanceof ZodError,
            () => new Error('Invalid email')
          )(error),
          E.map((error: ZodError) => error.issues[0].message),
          E.getOrElse(() => 'Invalid email')
        )

        setEmailInputState({ error: true, errorMessage: msg, value: '' })
      }
    )
  }

  const submitForm = (method: 'resubscribe' | 'unsubscribe') => {
    pipe(
      emailInputState.value,
      validateEmail,
      E.map(() => {
        method === 'resubscribe' &&
          resubscribeCheckboxActive &&
          resubscribeEmail(emailInputState.value)
        method === 'unsubscribe' && unsubscribeEmail(emailInputState.value)
      })
    )
  }

  const microcopy = useMicroCopy()

  return (
    <form className="grid w-full grid-cols-2 gap-6 p-4 md:gap-10 md:p-8 lg:gap-x-16 lg:gap-y-12">
      <h1 className="col-span-2 mb-0">{emailForm.title}</h1>
      <ResultMessage status={status} />
      <div className="col-span-2 flex flex-col gap-6 md:col-span-1 md:gap-8 lg:gap-10">
        <div className="flex flex-col">
          <p>{emailForm.description}</p>
          <Input
            errorMessage={emailInputState.errorMessage}
            label={emailForm.inputLabel}
            onChange={input => validateEmail(input.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <h2 className="mb-4">{emailForm.unsubscribeTitle}</h2>
          <p>{emailForm.unsubscribeDescription}</p>
          <button
            className="btn btn-solid-primary flex w-fit min-w-[150px] items-center justify-center"
            disabled={isLoading}
            onClick={() => submitForm('unsubscribe')}
            type="button"
          >
            {isLoading ? (
              <div className="border-b-btn-primary h-8 w-8 animate-spin rounded-full border-2 border-solid border-white" />
            ) : (
              emailForm.submitButtonText
            )}
          </button>
          <p className="mb-0 mt-4 text-sm">{emailForm.disclaimer}</p>
        </div>
      </div>
      {/* Resubscribe */}
      <div className="col-span-2 flex flex-col gap-6 md:col-span-1 md:gap-8 lg:gap-10">
        <div className="rounded-base border-neutral-black flex flex-col border border-solid p-4">
          <h2 className="mb-4">{resubscribeForm.title}</h2>
          <p>{resubscribeForm.description}</p>
          <Checkbox
            checked={resubscribeCheckboxActive}
            label={resubscribeForm.inputLabel}
            onChange={() => setResubscribeCheckboxActive(prev => !prev)}
          />
          <button
            className="btn btn-solid-primary mt-4 flex  w-fit min-w-[150px] items-center justify-center"
            disabled={isLoading}
            onClick={() => submitForm('resubscribe')}
            type="button"
          >
            {isLoading ? (
              <div className="border-b-btn-primary h-8 w-8 animate-spin rounded-full border-2 border-solid border-white" />
            ) : (
              resubscribeForm.submitButtonText
            )}
          </button>
        </div>
        <div>
          <h2 className="mb-4">{contactTitle}</h2>
          <p className="mb-0">
            {contactText}{' '}
            <a href={`mailto:${microcopy['contact-email']}`}>
              {microcopy['contact-email']}
            </a>
          </p>
        </div>
      </div>
    </form>
  )
}
