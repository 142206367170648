import { usePlaceholderForMonitoringDiscount } from '@ecomm/promotions-hooks'
import { parseJSONDataFromContentful } from '@ecomm/scout'
import { Link } from 'gatsby'

import { useBuildMySystemBannerQuery } from './query'

export function BuildMySystemBanner() {
  const data = useBuildMySystemBannerQuery()
  const copy: {
    readonly title: string
    readonly description: string
    readonly button: {
      readonly label: string
      readonly url: string
    }
  } = parseJSONDataFromContentful(data, 'contentfulJson')

  const description = usePlaceholderForMonitoringDiscount(
    copy.description,
    '15%'
  )

  return (
    <div className="rounded-base bg-primary-100 text-neutral-black p-8 text-center">
      <h2 className="font-default mb-6 text-3xl font-medium md:text-4xl">
        {copy.title}
      </h2>
      <p className="text-base">{description}</p>
      <Link
        className="btn btn-outlined !px-4 border-neutral-black hover:bg-neutral-black mx-auto mt-3 block w-fit py-3 text-center leading-normal no-underline hover:text-white md:px-6"
        to={copy.button.url}
      >
        {copy.button.label}
      </Link>
    </div>
  )
}
