import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { getImage } from 'gatsby-plugin-image'
import { isEmpty } from 'ramda'

import { ContentfulHeroBannerColumn } from '../../../graphql'

export const useHeroColumnImage = (column: ContentfulHeroBannerColumn) => {
  const imageMobile = column?.imageMobile
    ? { ...getImage(column.imageMobile), placeholder: undefined }
    : null
  const imageTablet = column?.imageTable
    ? { ...getImage(column.imageTablet), placeholder: undefined }
    : null
  const imageDesktop = column?.image
    ? { ...getImage(column.image), placeholder: undefined }
    : null

  const images = [
    imageDesktop || imageTablet || imageMobile,
    imageTablet
      ? {
          ...imageTablet,
          media: '(max-width: 1239px)'
        }
      : null,
    imageMobile
      ? {
          ...imageMobile,
          media: '(max-width: 767px)'
        }
      : null
    // @ts-expect-error TS(2677) FIXME: A type predicate's type must be assignable to its ... Remove this comment to see the full error message
  ].filter((el): el is GatsbyImageSchema => !!el)

  return { images: !isEmpty(images) ? images : [] }
}
