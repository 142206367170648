import { TrackEvent } from '@ecomm/tracking/src/analytics'
import { useEffect } from 'react'
import { useTracking } from 'react-tracking'

/**
 * Fire track event
 */
export const handleTrackLeadOptedIn = (
  trackEvent: TrackEvent,
  value: boolean
) => trackEvent({ checkoutLeadOptedIn: value })

/**
 * Firing track event if the user opted in
 */
export const useTrackCheckoutLeadOptedIn = (offerAndTip: boolean) => {
  const { trackEvent } = useTracking()

  useEffect(() => {
    handleTrackLeadOptedIn(trackEvent, offerAndTip)
  }, [trackEvent, offerAndTip])
}
