import { overloadMaybe } from '@simplisafe/ewok'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { pipe } from 'fp-ts/lib/function'
import { useSelector } from 'react-redux'

export const useCartLineItems = () => {
  const cart = useSelector(selectCart)

  return pipe(
    cart,
    c => c.toMaybe(),
    c => c.map(({ lineItems }) => lineItems),
    overloadMaybe
  )
}
