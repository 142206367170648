import React, { FC, ReactNode } from 'react'

import Text from '../Text'

export type CheckoutLoginProps = {
  readonly content: ReactNode
}

// see first test case for why this component exists: packages/site/src/components/ContentfulRichText/ContentfulRichText.test.tsx

/** @deprecated Do not use ss-react-components*/
const CheckoutLogin: FC<CheckoutLoginProps> = ({
  content
}: CheckoutLoginProps) => {
  return (
    <Text className="prose-a:text-complementary-blue-100" useTailwind>
      {content}
    </Text>
  )
}

export default CheckoutLogin
