import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { Link } from '@ecomm/framework'
import classNames from 'classnames'
import { useTracking } from 'react-tracking'

import { accountMenuItems } from './data'

type AccountDropdownMenuProps = {
  readonly onToggleDropdown: (close?: boolean) => void
  readonly showDropdown: boolean
  // (sideNav === true) => This dropdown menu is being displayed in the side nav and not in desktop mode
  readonly sideNav: boolean
}

export function AccountDropdownMenu({
  showDropdown,
  sideNav,
  onToggleDropdown
}: AccountDropdownMenuProps) {
  const { trackEvent } = useTracking()
  const trackClick = (label: string) => {
    trackEvent({
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation',
      label: label
    })
    trackNavigationClick({
      action: 'click',
      navElement: label
    })
  }

  const itemClasses =
    'hover:underline relative z-10 flex h-[60px] w-full min-[1126px]:w-[245px] items-center justify-end border-0 min-[1126px]:border-solid border-primary-100 border-b-neutral-300 text-lg first:border-b-2 min-[1126px]:hover:bg-neutral-100 no-underline text-white min-[1126px]:text-neutral-black'

  return (
    <div
      className={classNames(
        "bg-neutral-black top-[60px] z-10 w-full shadow-md before:absolute before:-top-1 before:left-1/2 before:z-[0] before:block before:h-6 before:w-6 before:-translate-x-1/2 before:rotate-45 before:transform before:bg-white min-[1126px]:absolute min-[1126px]:left-1/2 min-[1126px]:w-max min-[1126px]:-translate-x-1/2 min-[1126px]:bg-white min-[1126px]:before:content-['']",
        !sideNav && 'transition-[transform,opacity] duration-150',
        !sideNav &&
          (showDropdown
            ? 'translate-y-0 opacity-100'
            : 'pointer-events-none -translate-y-3 opacity-0'),
        sideNav && (showDropdown ? 'block' : 'hidden')
      )}
      data-testid="account-dropdown-menu"
    >
      {accountMenuItems.map((item, i) =>
        /(http|https):\/\/.*/.test(item.url) ? (
          <a
            className={itemClasses}
            href={item.url}
            key={i}
            onClick={() => {
              onToggleDropdown(true)
              trackClick(item.text)
            }}
            rel="noreferrer"
            tabIndex={showDropdown ? 0 : -1}
            target="_blank"
          >
            <div className="ml-4 mr-3">{item.icon}</div>
            <div className="mb-1 flex-grow">{item.text}</div>
          </a>
        ) : (
          <Link
            className={itemClasses}
            key={i}
            onClick={() => {
              onToggleDropdown(true)
              trackClick(item.text)
            }}
            tabIndex={showDropdown ? 0 : -1}
            to={item.url}
          >
            <div className="ml-4 mr-3">{item.icon}</div>
            <div className="mb-1 flex-grow">{item.text}</div>
          </Link>
        )
      )}
    </div>
  )
}
