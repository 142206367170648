import { addAtomDebugLabel } from '@ecomm/utils'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'

import { StorageHook } from './lib/types'

const key = 'popupWizardResponses'

export const popupWizardResponsesAtom = atomWithStorage<Record<
  number | string,
  number | string
> | null>(key, null)
addAtomDebugLabel(popupWizardResponsesAtom, 'popup wizard responses')

/**
 * Json value of Popup Wizard Responses
 */
export const usePopupWizardResponses = (): StorageHook<
  Record<number | string, number | string>
> => {
  const [val, setVal] = useAtom(popupWizardResponsesAtom)

  const parsedVal = useMemo(() => {
    return O.fromNullable(val)
  }, [val])

  return [parsedVal, setVal]
}
