import { z } from '@simplisafe/ewok'
import type { TypeOf } from 'zod'

export const videoButtonAssetSchema = z.object({
  videoButtonAsset: z.object({
    url: z.string(),
    originalHeight: z.number(),
    originalWidth: z.number(),
    description: z.string(),
    title: z.string()
  })
})

export type VideoButtonAsset = TypeOf<typeof videoButtonAssetSchema>
