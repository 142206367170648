import { odmonPreLaunchRenameLineItemProps } from '@ecomm/shared-hooks'
import { useOdmonExperience } from '@ecomm/shared-ninetailed'

import { CartContent, CartDetailsProps } from './CartContent'

export function OdmonPreLaunchCartContent(props: CartDetailsProps) {
  const odmonExperienceQuery = useOdmonExperience()

  const items = props.itemList || []
  const itemList = odmonExperienceQuery.isVariant
    ? items.map(odmonPreLaunchRenameLineItemProps)
    : items

  return <CartContent {...props} itemList={itemList} />
}
