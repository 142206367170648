import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

import { layoutNoHeaderSchema } from '../../assemblies/Layout/layoutSchema'
import { productCardInternalSchema } from '../../components/ProductCard/schema'

const groupSchema = z.object({
  id: z.string(),
  subtitle: z.string(),
  title: z.string(),
  internalTitle: z.string(),
  image: gatsbyImageSchema,
  products: z.array(productCardInternalSchema),
  bannerType: z.string().nullable(),
  disclaimerCardType: z.string().nullable()
})

const alarmSensorPageSchema = z.object({
  layout: layoutNoHeaderSchema,
  updatedAt: z.string(),
  id: z.string(),
  entryTitle: z.string(),
  slug: z.string(),
  groups: z.array(groupSchema),
  breadcrumbTitle: z.string().nullish()
})

export type AlarmSensorPageSchema = TypeOf<typeof alarmSensorPageSchema>

export type GroupSchema = TypeOf<typeof groupSchema>

export default alarmSensorPageSchema
