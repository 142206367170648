import { SecureHeaderProgressBarComponent } from '@ecomm/checkout/layout'
import { useLocale } from '@ecomm/data-hooks'
import { configureNewRelic } from '@ecomm/error-handling'
import { ErrorBoundary } from '@ecomm/error-handling'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { SkipToContentLink } from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'
import { PageBody, PageSection } from '@ecomm/ss-react-components'
import {
  devParams,
  TrackingProvider,
  useOptimizelyParams,
  useTrackMetricEvent
} from '@ecomm/tracking'
import { safePath } from '@simplisafe/monda'
import { graphql, type PageProps, Script, ScriptStrategy } from 'gatsby'
import { None } from 'monet'
import React from 'react'
import { useEffect } from 'react'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import type {
  ContentfulCartDetails,
  ContentfulCartPage,
  ContentfulCartPageTemplate,
  ContentfulHeaderWithProgressBar,
  ContentfulSiteWideMessages,
  ContentfulSmallTextSection
} from '../../graphql'
import CartDetailAffirmWrapper from '../components/CartDetailsComponent/CartDetailAffirmWrapper'
import CountryRedirectModal from '../components/CountryRedirectModal'
import LiveChat from '../components/LiveChat'
import SimpleFooterComponent from '../components/SimpleFooterComponent'
import { SiteWideMessagesContext } from '../contexts/siteWideMessagesContext'
import useAwinTracking from '../hooks/useAwinTracking'
import useUtmContent from '../hooks/useUtmContent'
import { trackEventIsMobile } from '../util/analytics'
import SEO from '../util/seo'

const CartDetailsComponent = React.lazy(
  () =>
    import(
      '../components/CartDetailsComponent' /* webpackChunkName: "legacy" */
    )
)

export type CartPageContext = {
  readonly id: string
  readonly locale: Locale
  readonly slug?: string
}

export type CartPageQuery = {
  readonly contentfulSiteWideMessages?: Partial<ContentfulSiteWideMessages> | null
  readonly contentfulCartPage?: Pick<
    ContentfulCartPage,
    'metaTitle' | 'pageTitle' | 'site' | 'url'
  > & {
    readonly template?: Pick<
      ContentfulCartPageTemplate,
      'defaultPageTitle' | 'hasLiveChat' | 'liveChatAppId'
    > & {
      readonly Header?: Partial<ContentfulHeaderWithProgressBar> | null
      readonly Footer?: {
        readonly description?: {
          readonly raw?: string | null
        }
      } | null
      readonly countryRedirectModal?: Partial<ContentfulSmallTextSection> | null
    }
    readonly cart?: Partial<ContentfulCartDetails> | null
  }
}

export type CartPageProps = PageProps<CartPageQuery, CartPageContext>

function CartPage({ data, pageContext }: CartPageProps) {
  const { trackEvent } = useTracking()
  const isTabletUp = useMediaQuery('TabletAndUp')

  const contentfulCartPage = data?.contentfulCartPage

  const pageUrl = contentfulCartPage?.url || ''
  const metaTitle = contentfulCartPage?.metaTitle || ''

  const locale = useLocale()

  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)

  useUtmContent()
  useOptimizelyParams()
  useAwinTracking(locale === 'en-GB')

  useEffect(() => {
    trackEventIsMobile(trackEvent, isTabletUp)
  }, [isTabletUp, trackEvent])

  useEffect(() => {
    // This sets up New Relic with our configuration from ecomm-data
    configureNewRelic()
    // Scroll to top on route change
    window && window.scrollTo(0, 0)
  }, [])

  const trackMetricEvent = useTrackMetricEvent()
  trackMetricEvent('cart-page-view')

  const cartDetails: Partial<ContentfulCartDetails> =
    contentfulCartPage?.cart || {}

  const footer: ContentfulSmallTextSection =
    contentfulCartPage.template.Footer || {}

  const liveChatId: string = contentfulCartPage.template.liveChatAppId || ''

  const liveChatEnabled: boolean =
    contentfulCartPage.template.hasLiveChat || false

  const countryRedirectModal: Partial<ContentfulSmallTextSection> = safePath(
    ['contentfulCartPage', 'template', 'countryRedirectModal'],
    data
  ).orJust({})

  return (
    <ErrorBoundary>
      <Script
        src={`/mention-me.js?id=${Math.floor(Math.random() * 10000 + 1)}`}
        strategy={ScriptStrategy.idle}
      />
      <TrackingProvider metaTitle={metaTitle}>
        <SiteWideMessagesContext.Provider
          value={data.contentfulSiteWideMessages || {}}
        >
          <SkipToContentLink />
          <SEO
            lang={locale}
            metaTitle={metaTitle}
            pageUrl={pageUrl}
            productId={None()}
          />
          <PageToaster />
          <ApplyPromoCode />
          <PromoBannerWrapper type="cart" />
          <ErrorBoundary>
            <SecureHeaderProgressBarComponent />
          </ErrorBoundary>
          <ErrorBoundary>
            <PageBody>
              <PageSection>
                <React.Suspense fallback={null}>
                  {locale === 'en-US' ? (
                    <CartDetailAffirmWrapper
                      data={cartDetails}
                      pageContext={pageContext}
                    />
                  ) : (
                    <CartDetailsComponent
                      data={cartDetails}
                      isAffirmExperience={false}
                      pageContext={pageContext}
                    />
                  )}
                </React.Suspense>
              </PageSection>
            </PageBody>
          </ErrorBoundary>
          <ErrorBoundary>
            <SimpleFooterComponent data={footer} />
          </ErrorBoundary>
          {liveChatEnabled ? <LiveChat appId={liveChatId} /> : null}
          {!hidePopups && (
            <ErrorBoundary>
              <CountryRedirectModal data={countryRedirectModal} />
            </ErrorBoundary>
          )}
        </SiteWideMessagesContext.Provider>
      </TrackingProvider>
    </ErrorBoundary>
  )
}
export default CartPage

export const query = graphql`
  #graphql
  query CartPage($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulCartPage(id: { eq: $id }) {
      pageTitle
      site
      url
      metaTitle
      cart {
        ... on ContentfulCartDetails {
          ...cartDetailsInformation
        }
      }
      template {
        defaultPageTitle
        hasLiveChat
        liveChatAppId
        Footer {
          ... on ContentfulSmallTextSection {
            description {
              raw
              references {
                ... on ContentfulLink {
                  contentful_id
                  ...contentfulLinkFragment
                }
              }
            }
            descriptionMobile {
              raw
              references {
                ... on ContentfulLink {
                  contentful_id
                  ...contentfulLinkFragment
                }
              }
            }
          }
        }
        countryRedirectModal {
          ... on ContentfulSmallTextSection {
            ...smallTextSectionFragment
          }
        }
      }
    }
  }
`
