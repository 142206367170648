import { useEffect, useState } from 'react'

let counter = 0

// Generate unique value for html id attributes to prevent clashes when multiple instances of a component are active.
export function useUniqueId(prefix?: string) {
  const [id, setId] = useState<string>()

  useEffect(() => {
    counter++
    setId(prefix ? `${prefix}-${counter}` : counter.toString())
  }, [prefix])

  return id
}
