import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { contentfulImageSchema } from '@ecomm/contentful/components'
import { iconAndTextSchema } from '@ecomm/shared-components'
import { iconTypes } from '@ecomm/shared-icons'
import { type TypeOf, z } from '@simplisafe/ewok'

import { featureModalSchema } from '../FeatureModal/schema'

export const confirmationModalSchema = z.object({
  title: z.string(),
  description: z.string(),
  recommendedText: z.string()
})

export const planFeatureSchema = z.object({
  contentful_id: z.string(),
  __typename: z.literal('ContentfulMonitoringFeature'),
  icon: z.enum(iconTypes),
  helpText: contentfulRichTextSchema,
  modal: featureModalSchema.nullish(),
  trackingCode: z.string().optional()
})

export const imageAndTextSchema = z.object({
  __typename: z.literal('ContentfulImageAndText'),
  contentful_id: z.string(),
  text: contentfulRichTextSchema,
  image: contentfulImageSchema
})

const additionalFeaturesSchema = z.union([
  iconAndTextSchema,
  imageAndTextSchema
])

export const monitoringPlanCardSchema = z.object({
  __typename: z.literal('ContentfulMonitoringPlan'),
  sku: z.string(),
  recommended: z.boolean(),
  name: z.string(),
  features: planFeatureSchema.array().optional(),
  cta: z.string(),
  description: contentfulRichTextSchema, //text below the features
  additionalFeatures: additionalFeaturesSchema.array().nonempty(),
  disclaimer: z.string().optional(),
  planConfirmationModal: confirmationModalSchema.optional()
})

export type MonitoringPlanCardSchema = TypeOf<typeof monitoringPlanCardSchema>
export type PlanFeatureSchema = TypeOf<typeof planFeatureSchema>
export type AdditionalFeaturesSchema = TypeOf<typeof additionalFeaturesSchema>
export type ImageAndTextSchema = TypeOf<typeof imageAndTextSchema>
export type ConfirmationModalSchema = TypeOf<typeof confirmationModalSchema>
