import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.WeatherResistant,
    text: 'Outdoor use'
  },
  {
    icon: SharedIcons.MotionDetection,
    text: '140° field of view'
  },
  {
    icon: SharedIcons.NoDrill,
    text: 'No-drill install'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.RedSiren,
    text: 'Built-in 80 dB siren'
  },
  {
    icon: SharedIcons.RedSiren,
    text: 'Rechargable battery'
  }
]

const description = {
  content: `Entry sensors are your first line of defense against intrusion. Use them to secure your most commonly used and accessible ground-floor doors and windows, because that's where criminals will look first. (For rooms with multiple windows, consider a motion sensor.)
    \nEntry sensors can also protect cabinets and safes with optional secret alerts.
  `
}

const specs = {
  content: `1.25 x 1.5 x 0.62 inches
    \nOptional screw kit
    \n1 CR-2032 Lithium 3V battery (included)
    \n1 year manufacturer limited warranty. Subscriber lifetime warranty available with
    Fast Protect Monitoring plan.
  `
}

const faqs = {
  contentfulId: '5YQUa9LuGluUxLgcAwtLTE'
}

export const outDoorCameraData: ProductData = {
  productId: '6g2VIZbCdIch3njY4vAQRi',
  sensorName: 'Outdoor Camera',
  features,
  description,
  specs,
  faqs
}
