import { documentSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const faqSchema = z.object({
  faq: z.object({
    sys: z.object({
      id: z.string()
    }),
    primaryCollection: z.object({
      items: z.array(
        z.object({
          question: z.string(),
          answer: z.object({
            json: documentSchema
          })
        })
      )
    })
  })
})

export type FAQSchema = TypeOf<typeof faqSchema>
