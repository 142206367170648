import { useLocale } from '@ecomm/data-hooks'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { PriceToggleSchema } from '../../../components/DraftCart/types'
import { ProductCardFragment } from '../../../components/ProductCard/schema'
import { useAmountSavedWithServicePlanBMS } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanBMS'
import { useDiscountWithServicePlan } from '../../../hooks/IMAPS/useDiscountWithServicePlan'
import { LegalDisclaimer } from '../../LegalDisclaimer'
import { ProSetup } from '../../ProSetup'
import { shouldRenderMonitoring } from '../helpers'
import { OptInOrOut } from '../OptInOrOut'

export type DynamicPackageMonitoringWrapperProps = {
  readonly priceToggles: readonly PriceToggleSchema[]
  readonly proSetup?: ProductCardFragment | null
  readonly sku?: string
  readonly showProsetupHighlightedComponent?: boolean
  readonly total?: number
}

export function DynamicPackageMonitoringWrapper({
  priceToggles,
  sku = '',
  total,
  proSetup,
  showProsetupHighlightedComponent = false
}: DynamicPackageMonitoringWrapperProps) {
  const isUs = useLocale() === 'en-US'
  const { discountWithServicePlan, isLoading } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS(total)

  return (
    <div>
      {isLoading ? (
        <Skeleton count={2.5} />
      ) : (
        <div data-component="DynamicPackageMonitoringWrapper">
          {shouldRenderMonitoring(priceToggles) && (
            <OptInOrOut
              amountSavedWithServicePlan={amountSavedWithServicePlan}
              discountWithServicePlan={discountWithServicePlan}
              priceToggles={priceToggles}
            />
          )}
          <LegalDisclaimer className="!mb-0 mt-4 !pl-0" />
          {proSetup && !showProsetupHighlightedComponent && !isUs ? (
            <ProSetup proSetup={proSetup} />
          ) : null}
        </div>
      )}
    </div>
  )
}
