import { localStorage } from '@simplisafe/ewok'
import fetchIntercept from 'fetch-intercept'

import { fetchAtAtToken } from '../atat'

export const getRequestConfig = async (
  config: RequestInit,
  isOverride: boolean
): Promise<RequestInit> => {
  const atatToken: string | null = (await fetchAtAtToken()) || ''
  !atatToken &&
    console.warn('Cannot find at-at token when intercepting fetch headers.')

  const locale = process.env['LOCALE'] || ''
  !locale &&
    console.warn(
      'Cannot find process.env.LOCALE when intercepting fetch headers.'
    )

  // Retrieve the PerimeterX cookie from local storage
  const pxCookie: string = localStorage.get('pxcookie') || ''
  !pxCookie &&
    console.warn('Cannot find pxcookie when intercepting fetch headers.')

  const modifiedHeaders: RequestInit['headers'] = {
    ...(config && config.headers),
    'Accept-Language': locale,
    'X-PX-Cookies': pxCookie,
    'X-SimpliSafe-Locale': locale,
    'X-Vid-Token': atatToken
  }

  const baseConfig = {
    ...config,
    headers: modifiedHeaders
  }

  return {
    ...baseConfig,
    ...(isOverride && { credentials: 'include' })
  }
}

const knownApiUrlOverrides = [
  process.env['PRICES_API_OVERRIDE_URL'],
  process.env['PROMOTIONS_API_OVERRIDE_URL']
]

export const interceptFetchHeaders = () =>
  fetchIntercept.register({
    request: async (url: Request | string, config: RequestInit) => {
      // If `url` is a string, we deal with a direct URL string. If it's not a string,
      // we've received a Request object, and we need to extract the url from it.
      const _url = typeof url === 'string' ? url : url.url

      const isLocalSSApiRequest = knownApiUrlOverrides.some(
        x => x && _url.includes(x)
      )
      const isSSApiRequest =
        _url.includes(process.env['BASE_SS_API_URL'] || '') ||
        isLocalSSApiRequest
      const requestConfig = isSSApiRequest
        ? await getRequestConfig(config, isLocalSSApiRequest)
        : config

      return [url, requestConfig]
    }
  })
