import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'
import React from 'react'

export type NewFlagProps = {
  readonly additionalClasses?: string
  /** True if this is a flag that overlays a product image, false if it is text that should go beside the product title */
  readonly hasBackground: boolean
  /** True if this is an overlay flag that should be in the upper-right corner of the image, false if it should sit directly on top of the product itself */
  readonly isCornered?: boolean
  /** True if this flag will be included on the Nav dropdown */
  readonly isNavItem?: boolean
}

export function NewFlag({
  hasBackground,
  isCornered = false,
  additionalClasses = '',
  isNavItem = false
}: NewFlagProps) {
  const microCopy = useMicroCopy()

  const className = classNames(
    'font-medium text-center',
    {
      'text-primary-100': !hasBackground,
      'align-center rounded-md bg-primary-100 px-2 py-1 text-xs md:text-lg':
        hasBackground && !isNavItem,
      'align-center rounded-md bg-primary-100 px-1 py-0.5 text-xs':
        hasBackground && isNavItem,
      'rounded-none': isCornered
    },
    additionalClasses
  )

  return <span className={className}>{microCopy.new}</span>
}
