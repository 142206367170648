//@ts-nocheck

import classNames from 'classnames'
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import CartUpdatedMessage from '../CartUpdatedMessage'
import QuantityChanger from '../QuantityChanger'
import SSButton, { SSButtonProps } from '../SSButton'
import * as css from './AddExtraSensors.module.scss'

export type AddExtraSensorsProps = {
  /** Additional copy to display below the quantity changer */
  readonly additionalText?: ReactNode
  /** Image to show in front of additional copy */
  readonly additionalTextIcon?: ReactNode
  /** Props for the CTA button */
  readonly buttonProps: SSButtonProps
  /** Message to show on successful cart update */
  readonly cartUpdateSuccessMessage?: string
  /** Default value for the quantity changer */
  readonly defaultQuantity?: number
  /** Header text to display above the quantity changer */
  readonly headerText: string
  /** If false, CTA button will be disabled */
  readonly isCartable?: boolean
  /** If not cartable, displays this text below price. */
  readonly isNotCartableText?: ReactNode
  /** Maximum allowed value for the quantity changer */
  readonly maxQuantity?: number
  /** Handler for clicking the CTA button */
  readonly onButtonClick: (quantity: number, onSuccess: () => void) => void
  /** Out Of Stock message, displayed when a sensor is out of stock */
  readonly outOfStockMessage?: ReactNode
  /** Component with formatted price text including currency symbol */
  readonly price: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly errorMessage?: ReactNode
}

/**
 * @deprecated This component was rewritten in frontend using tailwind.
 *
 * To be removed.
 */
const AddExtraSensors: FC<AddExtraSensorsProps> = ({
  additionalText,
  additionalTextIcon,
  buttonProps,
  cartUpdateSuccessMessage,
  defaultQuantity = 0,
  headerText,
  isCartable = true,
  isNotCartableText,
  maxQuantity = 10,
  onButtonClick,
  outOfStockMessage,
  price,
  dataComponent = AddExtraSensors.name,
  errorMessage
}: AddExtraSensorsProps) => {
  const [isCartUpdateSuccess, setCartUpdateSuccess] = useState(false)
  const [quantity, setQuantity] = useState(defaultQuantity)

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdateSuccess(false), 5000)
    return () => clearTimeout(timer)
  }, [isCartUpdateSuccess])

  const handleButtonClick = useCallback(() => {
    onButtonClick(quantity, () => {
      setCartUpdateSuccess(true)
    })
  }, [onButtonClick, quantity])

  return (
    <div className={css.addExtraSensors} data-component={dataComponent}>
      <h3 className={'h3'}>{headerText}</h3>
      <div className={css.addToCart}>
        <div className={css.priceSection}>
          <span className={classNames('bodyText', 'mediumText', css.price)}>
            {price}
          </span>
          {outOfStockMessage ? (
            <div
              className={classNames(
                'fontSize12',
                'outOfStockText',
                'hideFadeOut',
                css.outOfStockMsg,
                { showFadeIn: quantity > 0 }
              )}
            >
              {outOfStockMessage}
            </div>
          ) : null}
          {!isCartable && isNotCartableText ? (
            <p className="paragraph textSizeXS m-0 font-medium">
              {isNotCartableText}
            </p>
          ) : null}
        </div>
        <QuantityChanger
          className={css.quantityChanger}
          defaultValue={defaultQuantity}
          max={maxQuantity}
          min={0}
          onChange={q => setQuantity(q)}
        />
        <div className={css.ctaSection}>
          <SSButton
            {...buttonProps}
            className={css.ctaButton}
            disabled={!isCartable || quantity < 1}
            onClick={handleButtonClick}
          />
          <CartUpdatedMessage
            className={css.ctaSuccessMsg}
            isVisible={isCartUpdateSuccess ? !errorMessage : null}
            message={cartUpdateSuccessMessage}
          />
        </div>
      </div>
      {errorMessage ? <div className={'m1_t'}>{errorMessage}</div> : null}
      <div className={css.additionalText}>
        {additionalTextIcon ? (
          <span className={css.additionalTextIcon}>{additionalTextIcon}</span>
        ) : null}
        {additionalText ? <span>{additionalText}</span> : null}
      </div>
    </div>
  )
}

export default AddExtraSensors
