import { useApplyPromoCode, useUtmCode } from '@ecomm/promotions-hooks'
import * as O from 'fp-ts/lib/Option'
/**
 * @deprecated NOTE: This code was ported directly from legacy under tight deadline for Scout launch and should be rewritten!
 * See: https://simplisafe.atlassian.net/browse/ECP-7325
 *
 * ApplyPromoCode is a wrapper for the useApplyPromoCode hook
 * so Redux dependencies don't end up spilling on the Page component and cause unnecessary rerenders.
 */
export function ApplyPromoCode({
  useJotaiCart = false
}: {
  readonly useJotaiCart?: boolean
}) {
  const utmCode = useUtmCode()
  useApplyPromoCode(O.fromNullable(utmCode), useJotaiCart)

  return null
}
