import {
  selectPartnerBanner,
  selectPartnerBannerLoading
} from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  selectCurrentPromoLoading,
  selectCurrentPromoTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export function usePromoBannerState() {
  const partnerBanner = useSelector(selectPartnerBanner)
  const isPartnerBannerLoading = useSelector(selectPartnerBannerLoading)
  const isSitewideBannerLoading = useSelector(selectCurrentPromoLoading)
  const isTopBannerVisible = useSelector(selectCurrentPromoTopBannerVisible)

  const isLoading = isPartnerBannerLoading || isSitewideBannerLoading

  return useMemo(
    () => ({
      hasBanner: !isLoading && isTopBannerVisible,
      isPartnerBanner: !isLoading && partnerBanner.isSome(),
      isSitewideBanner: !isLoading && !partnerBanner.isSome()
    }),
    [isLoading, isTopBannerVisible, partnerBanner]
  )
}
