import { localStorage } from '@simplisafe/ewok'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setPartnerLogic } from './setPartnerLogic'

export const getLocalStorageCartIdAsString = () => {
  const localStorageCartId: string | null =
    localStorage.get(LOCAL_STORAGE_CARTID)
  return localStorageCartId === null
    ? ''
    : localStorageCartId.replace(/['"]+/g, '')
}

/**
 * Sets partner cookie, fetches partner banner, and adds partner association to cart
 */
export function useSetPartnerLogic(
  partnerName?: string,
  partnerGroup?: string,
  partnerUrl?: string,
  partnerMemberId?: string,
  delay = 0
) {
  const dispatch = useDispatch()

  useEffect(() => {
    const hasAllRequiredParams = !!partnerGroup && !!partnerName && !!partnerUrl

    !!hasAllRequiredParams &&
      setPartnerLogic({
        partnerGroup,
        partnerName,
        partnerUrl,
        dispatch,
        delay,
        partnerMemberId
      })
  }, [dispatch, partnerGroup, partnerMemberId, partnerName, partnerUrl])
}
