import { navigate } from '@reach/router'
import { exists, window } from 'browser-monads-ts'
import Cypress from 'cypress'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import UAParser from 'ua-parser-js'

interface WindowWithCypress extends Window {
  readonly Cypress?: typeof Cypress
}

const isBrowser = (w: Window) => exists(w)
const isCart = (url: string) => url === '/cart'
/* If UAParser detects a device `type`, the device is non-desktop */
const isDesktop = () => !new UAParser().getDevice().type
/* Safari has stricter pop-up blocking than other browsers, so check for it specifically */
const isDesktopSafari = () => new UAParser().getBrowser().name === 'Safari'
const isCypressTest = (w: WindowWithCypress) => Boolean(w?.Cypress)

export const handleAddToCartRedirection = (url: string, packageSku: string) => {
  return pipe(
    window,
    E.fromPredicate(
      w =>
        !isCypressTest(w) &&
        isCart(url) &&
        isBrowser(w) &&
        isDesktop() &&
        !isDesktopSafari(),
      () => () => {
        navigate(url, { state: { packageSku } })
      }
    ),
    E.match(
      goToCartSameTab => goToCartSameTab(),
      w => {
        const newWindow = w.open(url, 'cartTab')
        newWindow && newWindow.focus()
      }
    )
  )
}
