import { type AlarmMomentsTabsHeaders } from '@ecomm/shared-components'

export const odmonAlarmMomentsTabHeaders: AlarmMomentsTabsHeaders = {
  outdoor: {
    headline: 'Prevent crime before it happens',
    description:
      'AI-powered cameras + a team of monitoring agents work together to help deter criminals*'
  },
  'break-in': {
    headline: 'Stop intruders in their tracks',
    description:
      'If an entry sensor triggers an alarm, agents can intervene using the indoor camera.*'
  },
  'smoke & co': {
    headline: 'Detect smoke & dangerous gas',
    description:
      'Our sensors catch the first signs of danger from fire and carbon monoxide.'
  },
  water: {
    headline: 'Avoid costly water damage',
    description:
      'Get instant notifications when water leaks, overflows, or floods.'
  },
  temp: {
    headline: 'Monitor extreme temperatures',
    description: 'Know instantly when temps are beyond the range you set.'
  },
  deliveries: {
    headline: 'Defend your porch',
    description:
      'Keep an eye on deliveries and record video with the Video Doorbell Pro.'
  }
}
