import { useFragmentWithVariations } from '@ecomm/optimizely-hooks'

import shopPageSchema, { shopPageLayout } from './shopPageSchema'

export const useShopPageFragment = <
  T extends { readonly contentfulShopPage: U },
  U
>(
  query: T
) => {
  const shopPageFragment = query.contentfulShopPage

  const shopPage = useFragmentWithVariations(shopPageFragment, shopPageSchema)

  const layoutFragment = shopPage.layout

  const layout = useFragmentWithVariations(layoutFragment, shopPageLayout)

  return {
    ...shopPageSchema.parse(shopPage),
    layout: shopPageLayout.parse(layout)
  }
}
