import { trackAppCarousel } from '@ecomm/cdp-tracking'
import { GatsbyImage } from '@ecomm/shared-components'
import { ContentfulRichText } from '@ecomm/shared-components'
import { useSwipeMotion } from '@ecomm/shared-hooks'
import classNames from 'classnames'
import React, { useState } from 'react'

import AppRating from './AppRating'
import {
  descriptionCustomOptions,
  disclaimerCustomOptions
} from './contentfulRenderers'
import type { AppWidgetSchema } from './schema'
import { useOnTabClick } from './useOnTabClick'

interface AppWidgetProps {
  readonly data: AppWidgetSchema
}

export function AppWidget({ data }: AppWidgetProps) {
  const { tabs, headline, appleLogo, googlePlayLogo, simpliSafeLogo, height } =
    data

  const onSwipeLeft = () => handleTabClick((activeTab + 1) % tabs.length)
  const onSwipeRight = () =>
    handleTabClick((activeTab - 1 + tabs.length) % tabs.length)
  const touchEvents = useSwipeMotion({
    onSwipeLeft,
    onSwipeRight,
    sensitivity: 120
  })

  const [activeTab, setActiveTab] = useState<number>(0)

  const onTabClick = useOnTabClick()
  const handleTabClick = (index: number) => {
    setActiveTab(index)
    onTabClick(tabs[index].tab)
    trackAppCarousel(tabs[index].tab.toLowerCase())
  }
  const activeTabData = tabs[activeTab]

  return (
    <div
      className="prose md:prose-md lg:prose-lg relative w-full"
      data-testid="AppWidget"
    >
      <div
        className="absolute h-full w-full"
        data-testid="TouchControls"
        {...touchEvents}
      ></div>
      {headline ? (
        <h2 className="mb-6 w-full px-4 text-center md:mb-8 md:px-0">
          {headline}
        </h2>
      ) : null}
      <div className="flex flex-col lg:flex-row-reverse">
        <div className="bg-neutral-light-50 pointer-events-none rounded-t-2xl pt-2 lg:w-5/12 lg:flex-1 lg:rounded-l-none lg:rounded-r-2xl">
          <div className="space-end flex h-full flex-col-reverse">
            {activeTabData.appImage ? (
              <GatsbyImage
                className="max-h-96 lg:max-h-[34.5rem]"
                decoding="sync"
                image={activeTabData.appImage}
                imgStyle={{ objectFit: 'contain' }}
                loading="eager"
              />
            ) : null}
          </div>
        </div>
        <div
          className={classNames(
            'bg-neutral-black flex  flex-col rounded-b-2xl p-6 md:h-auto  md:p-8  lg:w-7/12 lg:rounded-l-2xl lg:rounded-r-none lg:p-12',
            {
              'min-h-[25rem] md:min-h-[35.5rem] lg:min-h-[40.5rem]':
                !height || height === 'large',
              'min-h-[25rem] md:min-h-[30.5rem] lg:min-h-[30.5rem]':
                height === 'medium'
            }
          )}
        >
          <div className="mb-4 hidden w-full justify-around gap-6 md:flex md:px-4 lg:mb-6">
            {tabs.map((tab, index) => (
              <button
                className={`cursor-pointer border-0 bg-inherit px-3 pb-2 text-lg text-white ${
                  activeTab === index &&
                  ' border-primary-100 border-x-0 border-b-4 border-t-0 border-solid font-bold '
                }`}
                key={tab.tab}
                onClick={() => handleTabClick(index)}
              >
                <span
                  className={activeTab === index ? 'opacity-100' : 'opacity-95'}
                >
                  {tab.tab}
                </span>
              </button>
            ))}
          </div>
          <div className="pointer-events-none grow">
            <div data-testid="AppWidget-headline">
              <h3 className="mb-0 mt-0 text-white">{tabs[activeTab].title}</h3>
            </div>
            <div className="text-sm opacity-90 md:text-xl">
              {tabs[activeTab].subtitle ? (
                <p className="hidden text-white md:block">
                  {tabs[activeTab].subtitle}
                </p>
              ) : null}
              <ContentfulRichText
                optionsCustom={descriptionCustomOptions}
                raw={tabs[activeTab].list.raw}
              />
              {tabs[activeTab].disclaimer ? (
                <ContentfulRichText
                  optionsCustom={disclaimerCustomOptions}
                  raw={tabs[activeTab].disclaimer?.raw}
                />
              ) : null}
            </div>
          </div>
          {appleLogo && simpliSafeLogo && googlePlayLogo ? (
            <div className="relative bottom-0 hidden lg:block">
              <AppRating
                appleLogo={appleLogo}
                appleRatings="300K Ratings (as of 6/24)"
                appleStars="4.8"
                googlePlayLogo={googlePlayLogo}
                googleRatings="23K Ratings (as of 6/24)"
                googleStars="4.6"
                simpliSafeLogo={simpliSafeLogo}
              />
            </div>
          ) : null}
        </div>
        <div className="z-10 my-4 flex flex-row justify-center gap-4 md:hidden">
          {tabs.map((tab, index) => (
            <button
              aria-label={`Slide ${index + 1} of ${tabs.length}`}
              className={`h-2 w-2 cursor-pointer rounded-full border-0 p-0 md:h-3 md:w-3 ${
                activeTab === index ? 'bg-neutral-black' : 'bg-[#D9D9D9]'
              }`}
              key={tab.tab}
              onClick={() => setActiveTab(index)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
