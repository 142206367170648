import { createContext, Dispatch, SetStateAction } from 'react'

export const setActiveDropdown: Dispatch<SetStateAction<string>> = (
  _setState: SetStateAction<string>
) => undefined

export const setIsOpen: Dispatch<SetStateAction<boolean>> = (
  _setState: SetStateAction<boolean>
) => undefined

const intitialContext = {
  activeDropdown: '',
  isOpen: false,
  setActiveDropdown,
  setIsOpen
}

const HeaderContext = createContext(intitialContext)

export default HeaderContext
