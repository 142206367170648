import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {
  ContentfulRichText,
  GatsbyImage,
  Modal
} from '@ecomm/shared-components'
import React, { useState } from 'react'

export const customGatsbyImageRenderer: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { gatsbyImageData } = node.data.target || {}

      return gatsbyImageData ? (
        <GatsbyImage
          className="max-h-12"
          image={gatsbyImageData}
          imgClassName="object-left"
          objectFit="scale-down"
        />
      ) : null
    }
  }
}

const usePackageContentfulRenderers = () => {
  const [disclaimerOpen, setDisclaimerOpen] = useState(false)

  const disclaimerRenderer: Options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => {
        const {
          modalTrigger: { text: modalTriggerText },
          headline: modalHeadline,
          description: modalDescription
        } = node.data.target

        return (
          <>
            <span
              className="m-0 cursor-pointer text-center text-lg underline"
              onClick={e => {
                e.preventDefault()
                !disclaimerOpen && setDisclaimerOpen(true)
              }}
            >
              {modalTriggerText}
            </span>
            <Modal
              isOpen={disclaimerOpen}
              onRequestClose={() => setDisclaimerOpen(false)}
            >
              <div className="bg-neutral-50 px-4 pb-6 pt-6 md:px-10 md:pb-20 md:pt-14">
                <div className="mb-10">
                  <h2>{modalHeadline}</h2>
                  <ContentfulRichText
                    optionsCustom={customGatsbyImageRenderer}
                    raw={modalDescription.raw}
                    references={modalDescription.references}
                  />
                </div>
              </div>
            </Modal>
          </>
        )
      }
    }
  }

  return { disclaimerRenderer }
}

export default usePackageContentfulRenderers
