import {
  apolloRichTextSchema,
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'
import { contentfulImageSchema } from '@ecomm/contentful/components'

export const trackingEventSchema = z
  .enum([
    'link-click-protectors-footer',
    'link-click-manual-footer',
    'link-click-privacy-promise-footer',
    'link-click-privacy-footer',
    'link-click-meet-the-system-footer',
    'link-click-reviews-footer',
    'link-click-locations-footer'
  ])
  .nullish()

export const footerMenuSchema = z
  .object({
    linksCollection: z
      .object({
        items: z
          .array(
            z.object({
              text: z.string().default(''),
              url: z.string().nullable(),
              trackingEvent: trackingEventSchema
            })
          )
          .default([])
      })
      .default({ items: [] }),
    title: z.string(),
    titleUrl: z.string().nullable().default('')
  })
  .passthrough()

export const socialLinkSchema = z.object({
  icon: contentfulImageSchema,
  text: z.string(),
  url: z.string()
})

export const socialLinksSchema = z.array(socialLinkSchema).default([])

export const legalLinkSchema = z.object({
  text: z.string(),
  url: z.string(),
  trackingEvent: trackingEventSchema
})

export const simpleLinkSchema = z
  .object({
    text: z.string(),
    url: z.string()
  })
  .nullish()

export const legalLinksSchema = z.object({
  items: z.array(legalLinkSchema).default([])
})

export const leadGenFormSchema = z.object({
  button: z.object({ text: z.string().default('') }),
  formCollection: z.object({
    items: z
      .array(
        z.object({
          placeholder: z.string().default(''),
          title: z.string().default(''),
          type: z.string().default('')
        })
      )
      .default([])
  }),
  note: apolloRichTextSchema.optional(),
  title: z.string().default('')
})

export const countrySchema = z.object({
  linkIcon: contentfulImageSchema,
  linkSubtext: z.string('USA'),
  linkText: z.string(),
  linkUrl: z.string()
})

export const footerSchema = z
  .object({
    sys: z.object({
      id: z.string()
    }),
    copyrightText: z.string().default(''),
    leadGenForm: leadGenFormSchema.optional(),
    legalLinksCollection: legalLinksSchema,
    menusCollection: z.object({ items: z.array(footerMenuSchema) }),
    socialLinksCollection: z.object({ items: socialLinksSchema }),
    disclaimerLink: simpleLinkSchema,
    type: z.enum(['Full', 'Slim']).optional()
  })
  .transform(({ sys, ...rest }) => ({ id: sys.id, ...rest }))

export const countriesSchema = z.array(countrySchema).default([])

export type Footer = TypeOf<typeof footerSchema>
export type LeadGenForm = TypeOf<typeof leadGenFormSchema>

export type LegalLinks = Footer['legalLinksCollection']['items']
export type LegalLink = TypeOf<typeof legalLinkSchema>

export type SocialLinks = TypeOf<typeof socialLinksSchema>
export type SocialLink = TypeOf<typeof socialLinkSchema>

export type FooterMenus = Footer['menusCollection']['items']
export type FooterMenu = TypeOf<typeof footerMenuSchema>

export type Countries = TypeOf<typeof countriesSchema>
export type Country = TypeOf<typeof countrySchema>
