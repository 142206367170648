import classNames from 'classnames'
import React, { ButtonHTMLAttributes } from 'react'

export type SimpleButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  readonly padding?: boolean
  readonly size?: 'auto' | 'full' | 'large' | 'medium' | 'small'
  readonly variant?: 'outlined' | 'solid'
}

/** @deprecated Do not use ss-react-components*/
const SimpleButton: React.FC<SimpleButtonProps> = ({
  padding = true,
  size = 'auto',
  variant,
  className,
  ...rest
}: SimpleButtonProps) => (
  <button
    className={classNames(
      'text-[var(--text)]', // text color
      'text-base',
      'rounded-base relative min-h-[40px] min-w-min cursor-pointer border border-solid text-center transition-colors duration-200',
      {
        'bg-neutral-medium border-neutral-medium pointer-events-none text-white':
          rest.disabled,
        'px-6 py-3': !!padding,
        'text-btn-accent bg-btn-primary border-btn-primary hover:bg-btn-dark hover:border-btn-dark':
          variant === 'solid' && !rest.disabled,
        'text-btn-primary border-btn-primary hover:text-btn-accent hover:bg-btn-primary bg-inherit':
          variant === 'outlined' && !rest.disabled,
        'w-36': size === 'small',
        'w-48': size === 'medium',
        'w-64': size === 'large',
        'w-full': size === 'full'
      },
      className
    )}
    data-component="SimpleButton"
    {...rest}
  />
)

export default SimpleButton
