import React, { FC, ReactNode } from 'react'

export type CartItemDialogProps = {
  /** Component with fully formatted price string, including currency symbol and any text like 'each' */
  readonly price: ReactNode
  readonly productImage: ReactNode
  readonly title: string
  /** The content of the component */
  readonly content?: ReactNode
  readonly quantity: number
  readonly doContinueShopping: () => void
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const CartItemDialog: FC<CartItemDialogProps> = (
  cartItemDialogProps: CartItemDialogProps
) => {
  const { dataComponent = CartItemDialog.name, content } = cartItemDialogProps
  return (
    <div className="px-16 pb-20 pt-12" data-component={dataComponent}>
      <div>
        <div className="popupContent p-4">{content}</div>
      </div>
    </div>
  )
}

export default CartItemDialog
