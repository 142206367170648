import type { Cart } from '@commercetools/platform-sdk'
export const isCartResponse = (res: unknown): res is Cart => {
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'id') &&
    Object.hasOwn(res, 'cartState') &&
    Object.hasOwn(res, 'lineItems')
  )
}
