import Checkbox from './Checkbox'
import Stepper from './Stepper'

export type Props = {
  readonly maxQuantity: number
  readonly minQuantity?: number
  readonly onDecrease: () => void
  readonly onIncrease: () => void
  readonly quantity: number
  readonly name: string
}

function QuantitySelector({
  maxQuantity,
  minQuantity = 0,
  onDecrease,
  onIncrease,
  quantity,
  name
}: Props) {
  return maxQuantity === 1 ? (
    <Checkbox
      checked={quantity > 0}
      name={name}
      onDecrease={onDecrease}
      onIncrease={onIncrease}
    />
  ) : (
    <Stepper
      maxQuantity={maxQuantity}
      minQuantity={minQuantity}
      onDecrease={onDecrease}
      onIncrease={onIncrease}
      quantity={quantity}
      testId={name}
    />
  )
}

export default QuantitySelector
