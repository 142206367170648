import { useAffirmAPRExperimentLegacy } from '@ecomm/shared-hooks'
import React from 'react'

import type { ContentfulCartDetails } from '../../../graphql'
import type { CartPageContext } from '../../templates/CartPage'
import CartDetailsComponent from '.'

type Props = {
  readonly data: Partial<ContentfulCartDetails>
  readonly pageContext: CartPageContext
}
function CartDetailAffirmWrapper({ data, pageContext }: Props) {
  const isAffirmExperience = useAffirmAPRExperimentLegacy()

  return (
    <CartDetailsComponent
      data={data}
      isAffirmExperience={isAffirmExperience}
      pageContext={pageContext}
    />
  )
}

export default CartDetailAffirmWrapper
