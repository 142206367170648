import { IconProps } from '@ecomm/shared-icons'
import React from 'react'

type SensorIconsProps = {
  readonly features: ReadonlyArray<{
    readonly text: string
    readonly icon: React.FC<IconProps>
  }>
}

export function SensorIcons({ features }: SensorIconsProps) {
  return (
    <section className="container text-left mt-4 mb-6">
      <div className="grid grid-cols-2 gap-x-6 gap-y-4 md:grid-cols-3">
        {features.map(({ icon: Icon, text }, idx) => {
          return (
            <span
              aria-label={text}
              className="flex items-center"
              key={`${idx}-${text}`}
            >
              <Icon
                alt="Sensor Feature Icon"
                className="full-current mr-2 shrink-0"
                height="26"
                titleA11y={text}
                width="26"
              />
              {text}
            </span>
          )
        })}
      </div>
    </section>
  )
}
