import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './data'
import { schema } from './schema'

export function useScoutProductPageTwoColumnImageBlurbsData() {
  const twoColumnImageBlurbsData = useLocalizedData<typeof schema>(schema, data)

  return useMemo(() => twoColumnImageBlurbsData, [twoColumnImageBlurbsData])
}
