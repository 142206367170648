//@ts-nocheck
/* eslint-disable max-lines */
import classNames from 'classnames'
import defaultTo from 'ramda/src/defaultTo'
import React, {
  FC,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState
} from 'react'
import { useTracking } from 'react-tracking'

import LoadingSpinner from '../LoadingSpinner'
import { SiteColor } from '../types/colors'
import * as css from './SSButton.module.scss'

export type ButtonColor =
  | 'anchorNav'
  | 'customPlain'
  | 'link'
  | 'none'
  | 'primary'
  | 'primaryHollow'
  | 'primaryOutline'
  | 'primaryPlain'
  | 'secondary'
  | 'secondaryOutline'
  | 'tertiary'
  | 'tertiaryOutline'

export type ButtonTarget = '_blank' | '_parent' | '_self' | '_top'
export type ButtonType =
  | 'button'
  | 'CustomPlain'
  | 'div'
  | 'link'
  | 'reset'
  | 'submit'
export type MinWidthType =
  | 'auto'
  | 'fitContent'
  | 'full'
  | 'large'
  | 'medium'
  | 'small'

type SpinnerProps = {
  readonly size?: number
  readonly color?: SiteColor
}

export type SSButtonProps = HTMLAttributes<HTMLButtonElement> & {
  readonly children: ReactNode
  readonly color?: ButtonColor
  readonly buttonColor?: string
  readonly textColor?: string
  readonly disabled?: boolean
  readonly form?: string
  /** only for link */
  readonly hasHover?: boolean
  readonly href?: string
  readonly id?: string
  /** Shows a spinner on the button and blocks interactions. Useful when we are waiting on something before allowing the user to continue. */
  readonly isLoading?: boolean
  readonly name?: string
  readonly onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void //Define onClick to support either for link or button type
  readonly target?: ButtonTarget
  /** Whether to render a button element, vs an anchor tag or div. The 'div' type should be used if nesting an SSButton inside an anchor tag. */
  readonly type?: ButtonType
  readonly value?: string
  readonly className?: string
  readonly onClickCtaButton?: (buttonItem: ButtonItem) => void // TODO this prop is overly specific, not necessary, and should be removed.
  readonly showSpinner?: boolean
  /** On some mobile screens, such as on the Product Plan Page (/choose-monitoring2) the word-wrap interferes with the height, so this
   * flag can be used to add an additional class which will enable height:auto for mobile.
   * */
  readonly spinnerMobileAutoHeight?: boolean
  readonly buttonSpinner?: SpinnerProps
  readonly dataComponent?: string
  /** Defaults to 'auto'. If set to 'full', the button will take up the full width of its container. */
  readonly minWidth?: MinWidthType
  readonly a11yLabel?: string
  readonly url?: string
}

export type ButtonItem = {
  readonly id?: string
  readonly name?: string
  readonly href?: string
}

const renderLoadingSpinner = (color?: SiteColor, size?: number) => (
  <LoadingSpinner
    className={classNames(css.spinner)}
    fillColor={color}
    size={size}
  />
)

type RenderProps = {
  readonly a11yLabel?: string
  readonly children?: ReactNode
  readonly classes?: string
  readonly color?: SiteColor
  readonly dataComponent?: string
  readonly disabled?: boolean
  readonly form?: string
  readonly href?: string
  readonly id?: string
  readonly inlineStyle?: React.CSSProperties
  readonly name?: string
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement>
  readonly onClickLink?: React.MouseEventHandler<HTMLAnchorElement>
  readonly onClickProp?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void
  readonly shouldShowLoadingSpinner?: boolean
  readonly showSpinner?: boolean
  readonly size?: number
  readonly spinnerMobileAutoHeight?: boolean
  readonly target?: string
  readonly value?: number | string | readonly string[]
}

const renderLinkButton = ({
  a11yLabel,
  classes,
  dataComponent,
  href,
  id,
  onClickLink,
  inlineStyle,
  target,
  children
}: RenderProps) => (
  <a
    aria-label={a11yLabel}
    className={classes}
    data-component={dataComponent}
    href={href}
    id={id}
    onClick={onClickLink}
    role="button"
    style={inlineStyle}
    target={target}
  >
    {children}
  </a>
)

const renderOtherButton = (
  _type: Exclude<ButtonType, 'CustomPlain' | 'div' | 'link'>,
  {
    a11yLabel,
    classes,
    spinnerMobileAutoHeight,
    showSpinner,
    dataComponent,
    disabled,
    form,
    id,
    name,
    onClickProp,
    onClick,
    inlineStyle,
    value,
    shouldShowLoadingSpinner,
    color,
    size,
    children
  }: RenderProps
) => (
  <button
    aria-label={a11yLabel}
    className={classNames(
      classes,
      { [css.spinnerMobileAutoHeight]: spinnerMobileAutoHeight },
      { [css.spinnerActive]: showSpinner }
    )}
    data-component={dataComponent}
    disabled={disabled}
    form={form}
    id={id}
    name={name}
    onClick={onClickProp ? onClick : undefined}
    style={inlineStyle}
    type={_type}
    value={value}
  >
    {shouldShowLoadingSpinner ? renderLoadingSpinner(color, size) : null}
    <span
      className={classNames('leading-normal', {
        invisible: shouldShowLoadingSpinner
      })}
    >
      {children}
    </span>
  </button>
)

const renderDivButton = ({
  classes,
  dataComponent,
  id,
  inlineStyle,
  children
}: RenderProps) => (
  <div
    className={classes}
    data-component={dataComponent}
    id={id}
    style={inlineStyle}
  >
    {children}
  </div>
)

/** @deprecated Do not use ss-react-components*/
const SSButton: FC<SSButtonProps> = ({
  a11yLabel,
  children,
  color = 'primary',
  buttonColor,
  disabled,
  form,
  href,
  id,
  isLoading = false,
  hasHover = true,
  name,
  onClick: onClickProp,
  onClickCtaButton,
  target,
  textColor,
  type = 'button',
  value,
  className,
  showSpinner = false,
  spinnerMobileAutoHeight = false,
  buttonSpinner: buttonSpinnerProp,
  dataComponent = SSButton.name,
  minWidth,
  ...rest
}: SSButtonProps) => {
  const classes = classNames(
    'text-base md:text-lg px-6 py-3 justify-center',
    css.button,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    css[color],
    { [css.hasHover]: hasHover && !disabled },
    { [css.disabled]: disabled },
    {
      'w-auto': minWidth === 'auto',
      'w-fit min-w-[150px]': minWidth === 'fitContent',
      'w-full': minWidth === 'full',
      'w-full md:w-auto': !minWidth,
      'w-full md:w-auto min-w-[150px]': minWidth === 'small',
      'w-full md:w-auto min-w-[190px]': minWidth === 'medium',
      'w-full md:w-auto min-w-[250px]': minWidth === 'large'
    },
    className
  )
  // Added custom inline background color code style to override any of the button type.
  const inlineStyle = useMemo(() => {
    return color === 'customPlain' && buttonColor
      ? {
          backgroundColor: buttonColor,
          border: 0,
          color: defaultTo('')(textColor),
          ...rest.style
        }
      : textColor
        ? {
            color: textColor,
            ...rest.style
          }
        : rest.style
  }, [color, buttonColor, textColor, rest.style])

  const { trackEvent } = useTracking()

  const [isSpinnerActive, setIsSpinnerActive] = useState(false)

  const onClick: MouseEventHandler<HTMLElement> = useCallback(
    e => {
      setIsSpinnerActive(true)
      trackEvent({ event: 'buttonClick' })
      onClickProp && onClickProp(e)
    },
    [onClickProp, trackEvent]
  )

  const onClickLink = useCallback(() => {
    const buttonItem = {
      id: id,
      name: name,
      url: href
    }
    trackEvent({ event: 'linkClick' })
    onClickCtaButton && onClickCtaButton(buttonItem)
  }, [onClickCtaButton, trackEvent, id, name, href])

  const buttonSpinner: SpinnerProps = useMemo(
    () =>
      buttonSpinnerProp || {
        color: 'neutralLightTan',
        size: 40
      },
    [buttonSpinnerProp]
  )

  const shouldShowLoadingSpinner = useMemo(
    () => isLoading || (isSpinnerActive && showSpinner),
    [isSpinnerActive, showSpinner, isLoading]
  )

  return type === 'link'
    ? renderLinkButton({
        a11yLabel,
        children,
        classes,
        dataComponent,
        href,
        id,
        inlineStyle,
        onClickLink,
        target
      })
    : type === 'div'
      ? renderDivButton({
          children,
          classes,
          dataComponent,
          id,
          inlineStyle
        })
      : // TODO: All of the types for button are somewhat messed up
        // Our long term plan is to remove usage of SSButton in favor of SimpleButton so it might not be worth fixing this.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
        // @ts-expect-error
        renderOtherButton(type, {
          a11yLabel,
          children,
          classes,
          color: buttonSpinner.color,
          dataComponent,
          disabled,
          form,
          id,
          inlineStyle,
          name,
          onClick,
          onClickProp,
          shouldShowLoadingSpinner,
          showSpinner,
          size: buttonSpinner.size,
          spinnerMobileAutoHeight,
          value
        })
}

export default SSButton
