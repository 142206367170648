import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import { FAQSection, toPromoBannerStyleValue } from '@ecomm/shared-components'
import { ColoredSection } from '@ecomm/shared-sections'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { type PageProps, graphql, Script } from 'gatsby'
import { match } from 'ts-pattern'

import { BreadcrumbsWrapper } from '../../components/BreadcrumbsWrapper'
import { GatsbyCardGrid } from '../../components/ProtectorsPage/CardGrid/GatsbyCardGrid'
import HowItWorks from '../../components/ProtectorsPage/HowItWorks'
import VerifyPassBanner from '../../components/ProtectorsPage/VerifyPassBanner'
import VerifyPassText from '../../components/ProtectorsPage/VerifyPassText'
import type { SeoNodeSchema } from '../../config/homeLandingResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { parseProtectorsPage } from './schema'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function ProtectorsTemplate<
  T extends { readonly contentfulGenericLandingPage: U },
  U
>({ data, pageContext: { locale, seoDetails } }: Props<T>) {
  const { layout, breadcrumbTitle, slug } = parseProtectorsPage(data)
  const {
    components,
    footer: { contentful_id: footerId }
  } = layout
  const { metaTitle, metaDescription, metaKeywords } = seoDetails
  const { isNofollow, isNoindex, canonicalLink } = seoDetails
  const headerData = useHeaderRedesignQuery()
  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle) || 'none'
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <Script
        data-key="e7d426c1b"
        src="https://cdn.verifypass.com/seller/launcher.js"
        strategy="idle"
      ></Script>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <div className="relative lg:z-20">
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={bannerStyle}
        />
      </div>
      <Header {...headerData} />
      <BreadcrumbsWrapper breadcrumbTitle={breadcrumbTitle} slug={slug} />
      <main
        className="prose md:prose-md lg:prose-lg flex flex-col max-w-8xl gap-6 md:gap-8 lg:gap-12 whitespace-pre-line mx-auto p-4 md:p-6 lg:p-8"
        id="content"
      >
        {components.map((c, i) =>
          match(c)
            .with({ __typename: 'ContentfulHeroBanner' }, d => (
              <VerifyPassBanner {...d} key={`component-${d.__typename}-${i}`} />
            ))
            .with({ __typename: 'ContentfulColoredSection' }, cc => (
              <ColoredSection {...cc} key={`component-${cc.__typename}-${i}`} />
            ))
            .with({ __typename: 'ContentfulReviewSection' }, rs => (
              <HowItWorks {...rs} key={`component-${rs.__typename}-${i}`} />
            ))

            .with({ __typename: 'ContentfulGrid' }, ({ contentful_id }) => (
              <GatsbyCardGrid id={contentful_id} />
            ))
            .with({ __typename: 'ContentfulFaq' }, faq => (
              <>
                <h2
                  className="mb-0 text-center w-full"
                  key={`component-${faq.__typename}-${i}`}
                >
                  {faq.title}
                </h2>
                <FAQSection faq={faq} key={`faq-section-${i}`} />
              </>
            ))
            .with({ __typename: 'ContentfulSmallText' }, data => (
              <VerifyPassText {...data} key={`small-text-${i}`} />
            ))
            .otherwise(() => null)
        )}
      </main>
      <Footer footerId={footerId} type="Full" />
    </TrackingProvider>
  )
}

export const query = graphql`
  query ProtectorsTemplate($id: String) {
    contentfulGenericLandingPage(id: { eq: $id }) {
      ...protectorsPageFragment
    }
  }
`
