import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const twoColumnImagesSchema = z.object({
  __typename: z.literal('ContentfulTwoColumnImageSection'),
  leftSideImage: gatsbyImageSchema,
  rightSideImage: gatsbyImageSchema,
  variant: z.literal('dark').or(z.literal('light')).default('dark')
})

export type TwoColumnImagesSchema = TypeOf<typeof twoColumnImagesSchema>
