import { path } from '@simplisafe/ewok'

import type { ContentfulProductCardAccessories } from '../../../graphql'
import { PriceProvider } from '../../providers/PriceProvider'
import AccessoriesCardComponent from '../AccessoriesCardComponent'

type AccessoriesCardWrapperProps = {
  readonly data: {
    readonly groupTitle: string
    readonly accessoriesToGroup: readonly Partial<ContentfulProductCardAccessories>[]
  }
}

export default function AccessoriesCardWrapper({
  data
}: AccessoriesCardWrapperProps) {
  const groupTitle = path(['groupTitle'], data)
  const accessoriesToGroup = path(['accessoriesToGroup'], data) || []

  const skus = accessoriesToGroup.map(content => path(['productId'], content))

  return (
    <PriceProvider skus={skus}>
      <div data-component="AccessoriesCardWrapper">
        <h2 className="h2">{groupTitle}</h2>
        <div className="flex flex-row flex-wrap gap-4 md:flex-nowrap">
          {accessoriesToGroup.map((data, i) => (
            <div className="flex flex-col md:basis-1/4" key={i}>
              <AccessoriesCardComponent data={data} />
            </div>
          ))}
        </div>
      </div>
    </PriceProvider>
  )
}
