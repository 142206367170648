import {
  getCartCustomValues,
  getCartShippingAddress,
  getCartShippingInfo
} from '@ecomm/data-cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

export const usePrefilledFormData = () => {
  const cart = useSelector(selectCart)

  return cart.isSome()
    ? {
        preFillShippingAddress: getCartShippingAddress(cart.some()),
        preFillCustomValues: getCartCustomValues(cart.some()),
        preFillShippingInfo: getCartShippingInfo(cart.some())
      }
    : {
        preFillShippingAddress: null,
        preFillCustomValues: null,
        preFillShippingInfo: null
      }
}
