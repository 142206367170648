//@ts-nocheck
import { Maybe } from '@simplisafe/ewok'
import classNames from 'classnames'
import { equals, ifElse } from 'ramda'
import React, { FC, ReactNode, useEffect, useState } from 'react'

import { Heading, SSInput, Text } from '..'
import CartUpdatedMessage from '../CartUpdatedMessage'
import { alwaysEmpty } from '../common'
import { useMediaQuery } from '../hooks'
import { Info } from '../icons'
import QuantityChanger from '../QuantityChanger'
import * as css from './BuildMySystemCardItem.module.scss'

export type QuantityType = 'Checkbox' | 'Stepper'

export type BuildMySystemCardItemProps = {
  readonly additionalDescription?: ReactNode
  readonly additionalLink?: ReactNode
  /** Message to show when the quantity is changed to indicate that the cart has been updated */
  readonly cartUpdatedMessage?: string //TODO remove this prop when ss-ecomm-frontend is updated not to use this prop
  /** Message to show when the quantity is changed to indicate that the cart has been updated */
  readonly cartUpdatedText?: ReactNode
  readonly description: ReactNode
  readonly errorMessage?: ReactNode
  /** Component with fully formatted unit price, including currency symbol and any text like 'each' */
  readonly formattedPrice: ReactNode
  readonly image: ReactNode
  /** Determines if any add to cart controls appear or not. */
  readonly isNotSellable?: boolean
  /** Maximum allowable value for the quantity selector. Must be a positive integer. */
  readonly maxQuantity?: number
  /** Out Of Stock message, displayed when a sensor is out of stock and quantity > 0 is selected */
  readonly outOfStockMessage?: ReactNode
  /** Handler for clicking the quantity selector. Passes the selected quantity value as the first arg. */
  readonly onQuantityChange: (newQuantity: number) => void
  /** Name of the free gift item included with the selected promotion */
  readonly freeGiftItem?: string | null
  /** Helper text for quantity selector (placed above). Only displayed for desktop. */
  readonly quantityText: string
  /** Additional text for quantity selector (placed below). Only displayed for desktop & tablet. */
  readonly quantityAdditionalText: string
  /** Content for sensor's modal (either use sensorModal or showInfoIcon and onIconClick) */
  readonly sensorModal?: ReactNode
  /** Function to get content for sensor's modal with clickTarget determined by current viewport width. */
  readonly sensorModalResponsiveTarget?: (clickTarget: ReactNode) => ReactNode
  /** Product name, displayed as the card title */
  readonly title: string
  /** Component with fully formatted total price (for selected quantity), including currency symbol and any text like 'each' */
  readonly totalPrice: ReactNode
  /** Pass default value for Quantity Changer */
  readonly defaultQuantityValue?: number
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly selected?: boolean
  readonly quantityType?: QuantityType
  /* Tracking functions */
  readonly onIncrease?: () => void
  readonly onDecrease?: () => void
  readonly onAddProSetup?: (event: 'add' | 'remove') => void
}

/** @deprecated Do not use ss-react-components*/
const BuildMySystemCardItem: FC<BuildMySystemCardItemProps> = ({
  additionalDescription,
  additionalLink,
  cartUpdatedMessage,
  cartUpdatedText,
  description,
  errorMessage,
  formattedPrice,
  freeGiftItem,
  image,
  isNotSellable,
  maxQuantity = 10,
  onQuantityChange,
  outOfStockMessage,
  quantityText,
  quantityAdditionalText,
  sensorModal,
  sensorModalResponsiveTarget,
  title,
  totalPrice,
  defaultQuantityValue = 0,
  dataComponent = BuildMySystemCardItem.name,
  quantityType = 'Stepper',
  selected = false,
  onIncrease,
  onDecrease,
  onAddProSetup
}: BuildMySystemCardItemProps) => {
  const [quantity, setQuantity] = useState(defaultQuantityValue)
  const [checked, setChecked] = useState(false)
  // TODO remove all of the isCartUpdated state functionality once cartUpdatedText is promoted to be used for successful quantity change message
  const [isCartUpdated, setCartUpdated] = useState(false)

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdated(false), 5000)
    return () => clearTimeout(timer)
  }, [isCartUpdated])

  const tabletAndUp = useMediaQuery('TabletAndUp')

  const updateCart = (newQuantity: number) => {
    onQuantityChange(newQuantity)
    setQuantity(newQuantity)
    setCartUpdated(true)
  }

  const handleQuantityChange = (newQuantity: number) => {
    updateCart(newQuantity)

    ifElse(
      equals(true),
      () => Maybe.fromUndefined(onIncrease).cata<void>(alwaysEmpty, fn => fn()),
      () => Maybe.fromUndefined(onDecrease).cata<void>(alwaysEmpty, fn => fn())
    )(quantity < newQuantity)
  }
  const handleCheckboxChange = (checked: boolean) => {
    const newQuantity = checked ? 1 : 0
    updateCart(newQuantity)
    setChecked(checked)

    Maybe.fromUndefined(onAddProSetup).cata<void>(alwaysEmpty, fn =>
      fn(quantity < newQuantity ? 'add' : 'remove')
    )
  }

  return (
    <div
      className="mb:border-t-0 md:border-neutral-medium-100 border-neutral-light-50 rounded-base mb-2 grid grid-cols-12 items-start gap-x-2 border border-solid p-2 md:mb-0 md:gap-x-6 md:rounded-none md:border-l-0 md:border-r-0 md:border-t-0 md:px-0 md:py-8"
      data-component={dataComponent}
    >
      {/* Left content */}
      <div
        className={classNames(
          'col-span-3 max-h-16 self-center justify-self-center px-1 md:max-h-40 lg:max-h-52',
          css.image
        )}
      >
        {image}
      </div>
      {/* Center content */}
      <div className="col-span-6 text-left lg:col-span-6">
        {
          // show clickable title if info button hidden
          !tabletAndUp && sensorModalResponsiveTarget ? (
            sensorModalResponsiveTarget(
              <Heading
                className="title prose-h3:mb-1 md:prose-h3:mb-4 prose-h3:text-base md:prose-h3:text-heading-4-size inline"
                element="h3"
                headingAspect="h4"
                useTailwind
              >
                <button className="m-0 cursor-pointer border-none bg-transparent p-0 text-left text-base leading-none text-inherit">
                  {title}{' '}
                  <span className="screenReaderText">. show more info</span>
                </button>
              </Heading>
            )
          ) : (
            <Heading
              className="title prose-h3:mb-1 md:prose-h3:mb-4 prose-h3:text-base md:prose-h3:text-heading-4-size inline"
              element="h3"
              headingAspect="h4"
              useTailwind
            >
              {title}
              {sensorModalResponsiveTarget ? (
                <span className="hidden cursor-pointer md:relative md:top-1 md:ml-2.5 md:!inline-block md:opacity-70 md:hover:opacity-100">
                  {sensorModalResponsiveTarget(
                    <Info forceButtonMode titleA11y="show more info" />
                  )}
                </span>
              ) : null}
            </Heading>
          )
        }
        {sensorModal ? (
          <span className="hidden cursor-pointer md:relative md:top-1 md:inline-block md:opacity-70 md:hover:opacity-100">
            {sensorModal}
          </span>
        ) : null}
        <Text
          className="prose-p:!text-xs md:prose-p:!text-body-size md:prose-p:!leading-normal prose-p:mb-0"
          useTailwind
        >
          {description}
        </Text>
        {additionalDescription ? (
          <Text className="hidden text-left md:block" useTailwind>
            {additionalDescription}
          </Text>
        ) : null}
        {additionalLink ? (
          <div className="md:text-body-size mt-1 text-left text-xs">
            {additionalLink}
          </div>
        ) : null}
        {!tabletAndUp && freeGiftItem ? (
          <Text
            className="my-1 block text-xs font-light text-red-600 md:mb-2"
            inheritTextColor
            useTailwind
          >
            {`One free ${freeGiftItem} will be automatically added to your new system`}
          </Text>
        ) : null}
      </div>
      {/* Right content */}
      <div className="col-span-3 pt-0.5 text-right md:pt-3 md:text-left lg:col-span-2 lg:col-start-11">
        {!isNotSellable ? (
          <>
            <div
              className={classNames(
                'hideFadeOut text-alert-100 -top-0.5 h-0 text-xs md:top-0',
                { 'showFadeIn h-auto': quantity > 0 }
              )}
            >
              {outOfStockMessage}
            </div>
            <p className="my-0 hidden font-medium md:mb-2 md:block md:text-lg">
              {quantityText}
            </p>
            {freeGiftItem ? (
              <Text
                className="my-0 hidden font-light text-red-600 md:mb-2 md:!block md:text-xs"
                inheritTextColor
                useTailwind
              >
                {`One free ${freeGiftItem} will be automatically added to your new system`}
              </Text>
            ) : null}
            {quantityType === 'Stepper' ? (
              <div className="md:flex md:items-center md:justify-between">
                <div className="text-body-size mb-1 font-medium">
                  {formattedPrice}
                </div>
                <QuantityChanger
                  className="mb-1 mr-0 justify-end md:justify-center"
                  defaultValue={defaultQuantityValue}
                  max={maxQuantity}
                  min={0}
                  onChange={(q: number) => handleQuantityChange(q)}
                />
              </div>
            ) : (
              <div className="text-body-size mb-1 flex flex-col-reverse items-center font-medium md:flex-col">
                <SSInput
                  checked={selected}
                  className="my-2"
                  hideLabel={true}
                  id="checkbox-bms"
                  label={`add ${title} to system`}
                  onChange={() => handleCheckboxChange(!checked)}
                  type="checkbox"
                />
                <Text
                  className="prose-p:!mb-1 prose-p:!text-xs md:prose-p:!text-body-size text-center font-medium"
                  useTailwind
                >
                  {formattedPrice}
                </Text>
              </div>
            )}
            <div
              className={classNames('md:hidden', {
                'text-center': quantityType !== 'Stepper'
              })}
            >
              {totalPrice}
            </div>
            <div
              className={classNames('disclaimerText hidden text-xs md:block', {
                'text-center': quantityType !== 'Stepper'
              })}
            >
              {quantityAdditionalText}
            </div>
          </>
        ) : (
          <div className="col-span-3 block">
            <div className="hidden md:mb-2 md:block">{quantityText}</div>
            <div className="hidden md:block">{quantityAdditionalText}</div>
          </div>
        )}
        {cartUpdatedMessage ? (
          <CartUpdatedMessage
            className={classNames('mt-1 text-left !text-xs', {
              hidden: !isCartUpdated
            })}
            isVisible={isCartUpdated}
            message={cartUpdatedMessage}
          />
        ) : (
          <div
            className={classNames('mt-1 text-left !text-xs', {
              hidden: !isCartUpdated
            })}
          >
            {cartUpdatedText}
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  )
}

export default BuildMySystemCardItem
