import { isSiteUS } from '@ecomm/checkout/shipping-schema'
import {
  formatAttribution,
  formatPrefilledAddress
} from '@ecomm/checkout/utils'
import { Locale } from '@ecomm/utils'
import { get as sessionStorageGet } from '@ecomm/utils'

import { usePrefilledFormData } from '../usePrefilledFormData'

export const useBuildPrefillData = (
  locale: Locale,
  email: string,
  initialCoupon: string
) => {
  const { preFillShippingAddress, preFillCustomValues, preFillShippingInfo } =
    usePrefilledFormData()

  const offerAndTipFromSessionStorage = sessionStorageGet('offerAndTip')
  const sameAddressFromSessionStorage = sessionStorageGet('sameAddress')

  const initialValues = {
    additionalFoundInfoThrough: '',
    additionalStreetInfo: '',
    city: '',
    country: isSiteUS(locale) ? 'US' : 'GB',
    couponCode: initialCoupon,
    email: email,
    epsilonAbacusOptIn: false,
    firstName: '',
    foundInfoThrough: '',
    lastName: '',
    offerAndTip:
      offerAndTipFromSessionStorage !== ''
        ? JSON.parse(offerAndTipFromSessionStorage)
        : true,
    phone: '',
    postalCode: '',
    sameAddress:
      sameAddressFromSessionStorage !== ''
        ? JSON.parse(sameAddressFromSessionStorage)
        : true,
    shippingOption: '',
    state: '',
    streetName: ''
  }

  if (preFillShippingAddress !== null) {
    return {
      ...initialValues,
      ...formatPrefilledAddress(preFillShippingAddress),
      ...formatAttribution(preFillCustomValues),
      country: preFillShippingAddress.country,
      couponCode: initialCoupon,
      shippingOption: preFillShippingInfo?.shippingMethod?.id || ''
    }
  } else {
    return initialValues
  }
}
