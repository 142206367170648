import { ninetailedExperimentsDataKey } from '@ecomm/data-constants'
import {
  COOKIE_BRAZE_PROMO_VIEWED,
  COOKIE_BRAZE_SITE_VISITED,
  COOKIE_LEAD_DATA,
  getCookie,
  getUserId,
  getValueFromLeadData,
  getVisitorId,
  setCookie
} from '@ecomm/shared-cookies'
import { getBraze } from '@ecomm/shared-window'
import {
  get as sessionStorageGet,
  remove as sessionStorageRemove
} from '@ecomm/utils'
import { isNotEmpty, isNotNil } from '@simplisafe/ewok'
import { localStorage } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import { LeadGenCaptureResponse } from '@simplisafe/ss-ecomm-data/leads/capture'
import {
  apiKey,
  brazeUKSubscriptionGroup,
  brazeUSSubscriptionGroup,
  proxyApiUrl
} from '@simplisafe/ss-ecomm-data/secrets/braze'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'

import { SESSION_STORAGE_FIRST_VISITED_URL } from '../TrackingProvider/index'
import { BrazeProps } from '../types/braze'
import { BrazeQuoteWizardResponse, Ecommerce, Product } from '../types/tracking'
import { brazeLogCustomEvent } from './lib/brazeLogCustomEvent'
import { brazeSetCustomUserAttributes } from './lib/brazeSetCustomUserAttributes'
import { EVENTS_LOGGING_DELAY } from './lib/constants'
import { handleBrazeImmediateFlush } from './lib/handleBrazeImmediateFlush'
import { shouldTrackUserEvent } from './lib/shouldTrackUserEvent'
import { updateLeadsData } from './lib/updateLeadsData'
export { brazeLogCustomEvent } from './lib/brazeLogCustomEvent'
export { brazeSetCustomUserAttributes } from './lib/brazeSetCustomUserAttributes'

import { formatPhoneNumber } from './lib/formatPhoneNumber'
const { get, set } = localStorage

export const generateDynamicPackagePageURL = (attributeHash: string): string =>
  `/product/system/${attributeHash}`

export const convertQueryParamsToObject = (
  queryParams: string
): Record<string, string> => {
  return Object.fromEntries(new URLSearchParams(queryParams))
}

export type BrazeChildComponent = {
  readonly name: string
  readonly quantity: string
}

export type BrazeChildComponents = readonly BrazeChildComponent[]

export type BrazeLineItem = {
  readonly name: string
  readonly sku: string
  readonly quantity: number
  readonly packageComponents?: BrazeChildComponents
}

export type VariationEnteredEvent = {
  readonly Test_name: string
  readonly Variation_name: string
  readonly Test_start_date?: string
  readonly Test_end_date?: string
}

const LOCAL_STORAGE_EMAIL_PROMO = 'email_promo'
export let brazeInitialized = false

// initialize Braze Web SDK
export const initBraze = () => {
  const loggingEnabled =
    process.env['GATSBY_ENABLE_BRAZE_SDK_LOGGING'] === 'true'
  getBraze(braze => {
    const location = window.location
    loggingEnabled &&
      braze.setLogger((message: string) => {
        console.info(
          message.replace(apiKey, 'REDACTED').replace(proxyApiUrl, 'REDACTED')
        )
      })
    brazeInitialized = braze.initialize(apiKey, {
      baseUrl: proxyApiUrl,
      enableLogging: loggingEnabled
    })
    braze.automaticallyShowInAppMessages()
    // optionally set the current user's External ID
    location.hash.includes('braze_eid') ? setUserIdOnInit(braze) : null
  })
}

// Set User ID from URL hash if available
const setUserIdOnInit = (braze: BrazeProps) => {
  const windowUrl: string = path<string>(['location', 'href'], window) || ''
  const match: readonly string[] = windowUrl.match(
    /#braze_eid=[A-Za-z0-9]+/
  ) || ['']
  const brazeEid: string = match.toString().split('=')[1]
  braze.openSession()
  braze.changeUser(brazeEid)
  set('braze_eid', brazeEid)
  console.info(
    `Braze: In function setUserIdOnInit found braze_eid query param, called changeUser with braze_eid : ${brazeEid}`
  )
  sendStoredNinetailedExperimentVariationsData()
}

// Fires up when a leads form is submitted
export const handleBrazeTrackingEvent = async (
  data?: LeadGenCaptureResponse,
  channel?: string,
  firstSubmission = false
) => {
  !getCookie(COOKIE_LEAD_DATA) &&
    setCookie(COOKIE_LEAD_DATA, data, cookiesOption)
  await updateLeadsData(data, channel, firstSubmission)
  console.info(
    `Braze: In function handleBrazeTrackingEvent, called updateLeadsData with lead data: ${JSON.stringify(
      { leadId: data?.leadId, externalId: data?.externalId }
    )}`
  )
  sendStoredNinetailedExperimentVariationsData()
}

// Tracks page visit
export const brazeTrackPageVisit = () =>
  shouldTrackUserEvent() && pageVisitTrackingEvent()

const pageVisitTrackingEvent = () => {
  getBraze(braze => {
    const utmParams = getUTMParams()
    setEmailSessionStorageValues(utmParams)
    !getCookie(COOKIE_BRAZE_SITE_VISITED) &&
      braze.logCustomEvent('site_visited', utmParams)
    setCookie(COOKIE_BRAZE_SITE_VISITED, true)
    setEmailAddressFromLeadDataCookie()
    logPageVisited()
  })
}

const setEmailSessionStorageValues = (utmParams: Record<string, string>) => {
  const emailPromoLocalStorage = get(LOCAL_STORAGE_EMAIL_PROMO) || null
  const setEmailPromo: boolean =
    isNotNil(utmParams['utm_code']) && isNil(emailPromoLocalStorage)
  const utmSource: string = prop<string>('utm_source', utmParams) || ''
  const utmMedium: string = prop<string>('utm_medium', utmParams) || ''
  const isEmailUrl: boolean =
    equals('lead_email', utmSource) && equals('email', utmMedium)
  const captureEmailVisit: boolean = isEmailUrl && isNil(emailPromoLocalStorage)
  const captureEmailUser: boolean =
    isEmailUrl && isNotNil(emailPromoLocalStorage)

  setEmailPromo && set(LOCAL_STORAGE_EMAIL_PROMO, utmParams['utm_code'])
  captureEmailVisit && set('email_visit', 'true')
  captureEmailUser && set('email_user', 'true')
}

// returns an object containing each UTM parameter in page URL
const getUTMParams = () => {
  const locationSearch: string = getWindowLocationValue('search')
  return locationSearch
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj: Record<string, string>, pair) => {
      const [key, value] = pair.map(decodeURIComponent)
      return { ...obj, [key]: value }
    }, {})
}

// Capture pages visited events
const logPageVisited = () => {
  getBraze(braze => {
    const pageUrl: string = getWindowLocationValue('href')
    const urlHash: string = getWindowLocationValue('hash')

    const cartPage: boolean =
      pageUrl.includes('cart') && !pageUrl.includes('checkout')
    const checkoutShippingPage: boolean =
      pageUrl.includes('cart/checkout') && isEmpty(urlHash)

    const urlEventIdMapping: { [key: string]: string } = {
      'build-my-system': 'visit_bms',
      'choose-monitoring': 'visit_choose_monitoring',
      'contact-us': 'visit_contact_us',
      feature: 'visit_features',
      'home-security-shop': 'visit_shop',
      'home-security-system-': 'visit_package',
      reviews: 'visit_reviews'
    }

    Object.keys(urlEventIdMapping).forEach(
      key =>
        pageUrl.includes(key) && braze.logCustomEvent(urlEventIdMapping[key])
    )
    cartPage && braze.logCustomEvent('visit_cart')
    checkoutShippingPage && braze.logCustomEvent('visit_checkout_shipping')
  })
}

// Capture answers submitted in Quote Wizard
export const brazeTrackQuoteWizardSubmission = (
  responses: readonly BrazeQuoteWizardResponse[],
  qwWithRecs = false
) => {
  getBraze(braze => {
    setTimeout(() => {
      updateBrazeLeadCustomData()
      const eventId = `qw_${qwWithRecs ? 'recs_' : ''}complete`
      brazeLogCustomEvent(eventId)
      braze &&
        responses
          .filter(
            response => isNotNil(response.question) && isNotNil(response.answer)
          )
          .forEach(response =>
            braze
              .getUser()
              .setCustomUserAttribute(
                safeProp('question', response).getOrElse(''),
                safeProp('answer', response).getOrElse('')
              )
          )
      braze.requestImmediateDataFlush(
        handleBrazeImmediateFlush({ caller: 'brazeTrackQuoteWizardSubmission' })
      )
    }, EVENTS_LOGGING_DELAY)
  })
}

export const brazeTrackGuidedSystemBuilderRecommendation = (
  attributeHash: string,
  guidedSystemBuilderQueryParams: string,
  responses: readonly BrazeQuoteWizardResponse[]
) => {
  getBraze(braze => {
    setTimeout(() => {
      const eventId = 'qw_recs_complete'
      const queryParams = convertQueryParamsToObject(
        guidedSystemBuilderQueryParams
      )
      updateBrazeLeadCustomData()
      brazeLogCustomEvent(eventId, queryParams)

      braze
        .getUser()
        .setCustomUserAttribute(
          'QW_RECS_URL',
          generateDynamicPackagePageURL(attributeHash)
        )

      responses
        .filter(
          response => isNotNil(response.question) && isNotNil(response.answer)
        )
        .forEach(response => {
          braze
            .getUser()
            .setCustomUserAttribute(
              safeProp('question', response).getOrElse(''),
              safeProp('answer', response).getOrElse('')
            )
        })

      queryParams['name'] && braze.getUser().setFirstName(queryParams['name'])

      braze.requestImmediateDataFlush(
        handleBrazeImmediateFlush({
          caller: 'brazeTrackGuidedSystemBuilderRecommendation',
          eventId,
          ...queryParams
        })
      )
    }, EVENTS_LOGGING_DELAY)
  })
}

// Capture whether a user saw the promo
export const brazeTrackPromoView = () => {
  const logPromoViewedEvent: boolean =
    shouldTrackUserEvent() && !getCookie(COOKIE_BRAZE_PROMO_VIEWED)
  logPromoViewedEvent &&
    brazeLogCustomEvent('promo_viewed', {
      channel: attributeChannel(),
      promo_offer_seen: true
    })
  shouldTrackUserEvent() && setCookie(COOKIE_BRAZE_PROMO_VIEWED, true)
  updateBrazeLeadCustomData()
}

export const updateBrazeLeadCustomData = () => {
  getBraze(() => {
    const leadId = getValueFromLeadData('leadId')
    leadId ? setEventUserAttributes() : null
  })
}

export const setCustomBrazeCheckoutError = () => {
  getBraze(braze => {
    setEmailAddressFromLeadDataCookie()
    brazeLogCustomEvent('checkout_error')
    braze.requestImmediateDataFlush(
      handleBrazeImmediateFlush({ caller: 'setCustomBrazeCheckoutError' })
    )
  })
}

// Track which products are added, removed, or clicked from cart
export const brazeTrackCartEvent = (eventData: {
  readonly eventType: 'add_to_cart' | 'remove_from_cart' | 'system_click'
  readonly productTrackingData: Ecommerce
}) => {
  getBraze(braze => {
    const eventType: string = prop('eventType', eventData)
    const actionTypeKey: string = eventType.substring(0, eventType.indexOf('_'))

    const productData: Product =
      path<Product>(
        ['productTrackingData', actionTypeKey, 'products', '0'],
        eventData
      ) || {}
    const properties = {
      channel: attributeChannel(),
      env: getWindowLocationValue('hostname'),
      id: prop<string>('id', productData) || '',
      name: prop<string>('name', productData) || '',
      price: prop<number>('price', productData) || 0,
      quantity: prop<number>('quantity', productData) || 1
    }
    braze.openSession()
    brazeLogCustomEvent(eventType, properties)
    braze.requestImmediateDataFlush()
  })
}

// Purchase data capturing
export const brazeTrackPurchaseComplete = (eventData: {
  readonly orderId: string
  readonly systemInOrder?: boolean
  readonly phoneNumber: string
  readonly products: readonly Product[]
  readonly currencyCode: string // it is passed in as string type from where it's coming from in ss-ecomm-frontend
  readonly onTechUrl?: string
}) => {
  const userId: string = getUserId() || ''
  const orderId: string = prop('orderId', eventData)
  const currencyCode: string = prop('currencyCode', eventData)
  const products = prop('products', eventData)
  const phoneNumber = prop('phoneNumber', eventData)
  const systemInOrder: boolean = safeProp('systemInOrder', eventData).getOrElse(
    false
  )
  const onTechUrl: string = safeProp('onTechUrl', eventData).getOrElse('')

  brazeSetCustomUserAttributes({
    'Last Lead Source': 'purchase',
    'Last Order ID': orderId,
    'Last User ID': userId,
    phone_number: phoneNumber,
    onTechUrl: onTechUrl
  })

  getBraze(braze => {
    braze &&
      (setEventUserAttributes(),
      isNotEmpty(userId) &&
        braze.getUser().addAlias(userId, 'simplisafe_user_id'),
      setEmailAddressFromLeadDataCookie(),
      setPhoneNumber(phoneNumber),
      addUserToSubscriptionGroup())

    const logPurchase = (sku: string, price: number, quantity = 1) =>
      braze.logPurchase(sku, price, currencyCode, quantity, {
        order_id: orderId
      })

    products.forEach(product => {
      const sku: string = prop<string>('id', product) || ''
      const price: number = prop<number>('price', product) || 0
      const quantity: number = prop<number>('quantity', product) || 1
      logPurchase(sku, price, quantity)
      // if it has an underscore, strip and log that too; see ECP-1838
      sku.includes('_') &&
        logPurchase(sku.substr(0, sku.indexOf('_')), price, quantity)
    })

    systemInOrder && logPurchase('SSCS3', 0)

    const properties = {
      order_id: orderId,
      user_id: userId
    }

    brazeLogCustomEvent('purchase_complete', {
      ...properties,
      order_source: 'website'
    })
    brazeLogCustomEvent('checkout', properties)
    braze.requestImmediateDataFlush()
  })
}

export const brazeTrackCartDetails = (eventData: {
  readonly cartId: string
  readonly cartTotal: string
  readonly coupon: string
  readonly discountedCartTotal: string
  readonly products: readonly BrazeLineItem[]
}) => {
  const properties = {
    last_cart_components: prop('products', eventData),
    last_cart_coupon: prop('coupon', eventData),
    last_cart_id: prop('cartId', eventData),
    last_cart_total: prop('cartTotal', eventData),
    last_discounted_cart_value: prop('discountedCartTotal', eventData)
  }
  brazeLogCustomEvent('cart_details', properties)
}

const setEventUserAttributes = () =>
  brazeSetCustomUserAttributes({
    Channel: attributeChannel(),
    Environment: getWindowLocationValue('hostname'),
    page_path: getWindowLocationValue('pathname'),
    vid: getVisitorId() || '',
    'First Visited Url': sessionStorageGet(SESSION_STORAGE_FIRST_VISITED_URL)
  })

const setEmailAddressFromLeadDataCookie = () => {
  getBraze(braze => {
    const emailAddress = getValueFromLeadData('email')
    emailAddress && braze.getUser().setEmail(emailAddress)
  })
}

export const setPhoneNumber = (phoneNumber: string) =>
  getBraze(braze =>
    braze.getUser().setPhoneNumber(formatPhoneNumber(phoneNumber))
  )

export const setFirstName = (firstName: string) =>
  getBraze(braze => braze.getUser().setFirstName(firstName?.trim()))

export const setLastName = (lastName: string) =>
  getBraze(braze => braze.getUser().setLastName(lastName?.trim()))

const addUserToSubscriptionGroup = () =>
  getBraze(braze =>
    braze
      .getUser()
      .addToSubscriptionGroup(
        process.env['LOCALE'] === 'en-GB'
          ? brazeUKSubscriptionGroup
          : brazeUSSubscriptionGroup
      )
  )

export const addUserToSMSSubscriptionGroup = () =>
  process.env['GATSBY_BRAZE_SMS_SUBSCRIPTION_ID'] &&
  getBraze(braze =>
    braze
      .getUser()
      .addToSubscriptionGroup(process.env['GATSBY_BRAZE_SMS_SUBSCRIPTION_ID'])
  ) //Marketing sms subscription group id

const getWindowLocationValue = (key: string) =>
  path<string>(['location', key], window) || ''

const attributeChannel = () =>
  `FCP ${process.env['LOCALE'] === 'en-GB' ? 'UK' : 'US'} Site`

export const sendNinetailedExperimentVariationsData = (
  experimentsData: readonly VariationEnteredEvent[]
) =>
  !!getBraze(braze => {
    experimentsData.forEach(e => {
      brazeLogCustomEvent('Variation_entered', e)
    })
    braze.requestImmediateDataFlush()
    return true
  })

const sendStoredNinetailedExperimentVariationsData = () => {
  const events: O.Option<readonly VariationEnteredEvent[]> = O.tryCatch(() =>
    JSON.parse(sessionStorageGet(ninetailedExperimentsDataKey) || '[]')
  )

  O.isSome(events) && sendNinetailedExperimentVariationsData(events.value)
  sessionStorageRemove(ninetailedExperimentsDataKey)
}

export const setBrazeAttributeGoogleClientId = (clientId: string) => {
  brazeSetCustomUserAttributes({ 'Google Client ID': clientId })
}
