import { PageSection } from '@ecomm/ss-react-components'
import { isArray } from '@simplisafe/ewok'
import { safeFind } from '@simplisafe/monda'
import { safePath } from '@simplisafe/monda'
import { Maybe, None } from 'monet'
import always from 'ramda/src/always'
import compose from 'ramda/src/compose'
import F from 'ramda/src/F'
import filter from 'ramda/src/filter'
import ifElse from 'ramda/src/ifElse'
import pathEq from 'ramda/src/pathEq'
import propEq from 'ramda/src/propEq'
import T from 'ramda/src/T'
import React from 'react'

import {
  ContentfulDefaultBanner,
  ContentfulFloatingBar,
  ContentfulFloatingPromoWidget,
  ContentfulHeaderPartner,
  ContentfulHeaderWithProgressBar,
  ContentfulModalPromoPopup,
  ContentfulTrustpilotReviewsBanner,
  ContentfulVariationContainer
} from '../../../graphql'
import { ContentfulHeaderFragment } from '../Header/query'
import { mapPageComponentToTemplate } from '.'
import { PageComponent, PageLayoutComponent, PageProps } from './types'

export const getPageLayoutComponent = (
  components: readonly PageLayoutComponent[],
  contentType: string
): Maybe<PageLayoutComponent> =>
  ifElse(
    isArray,
    safeFind<PageLayoutComponent>(propEq('__typename', contentType)),
    always(None<PageLayoutComponent>())
  )(components)

/**
 * Will return the first ContentfulVariationContainer found in the components argument, whose variations are of type contentType. Thus enabling the ability
 * to run more than one experiment in the Web Config layout components.
 */
// @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent> | Maybe<Contentfu... Remove this comment to see the full error message
const getVariationContainerComponent = (
  components: readonly PageLayoutComponent[],
  contentType: string
): Maybe<ContentfulVariationContainer> =>
  ifElse(
    isArray,
    compose(
      safeFind<PageLayoutComponent>(
        pathEq(['variations', '0', '__typename'], contentType)
      ),
      filter(pathEq(['internal', 'type'], 'ContentfulVariationContainer'))
    ),
    always(None<ContentfulVariationContainer>())
  )(components)

export function getPageLayoutComponents(
  components: readonly PageLayoutComponent[]
) {
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const headerProgressBar: Maybe<ContentfulHeaderWithProgressBar> =
    getPageLayoutComponent(components, 'ContentfulHeaderWithProgressBar')
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const headerPartner: Maybe<ContentfulHeaderPartner> = getPageLayoutComponent(
    components,
    'ContentfulHeaderPartner'
  )
  const simpleFooter = getPageLayoutComponent(
    components,
    'ContentfulSmallTextSection'
  )
  // TODO fix this type
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
  const contentfulHeader = getPageLayoutComponent(
    components,
    'ContentfulHeader'
  ) as Maybe<ContentfulHeaderFragment>
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const contentfulDefaultBanner: Maybe<ContentfulDefaultBanner> =
    getPageLayoutComponent(components, 'ContentfulDefaultBanner')
  const contentfulHeaderVariationContainer = getVariationContainerComponent(
    components,
    'ContentfulHeader'
  )
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const contentfulFloatingPromoWidget: Maybe<ContentfulFloatingPromoWidget> =
    getPageLayoutComponent(components, 'ContentfulFloatingPromoWidget')
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const popupWizard: Maybe<ContentfulFloatingBar> = getPageLayoutComponent(
    components,
    'ContentfulFloatingBar'
  )
  // @ts-expect-error TS(2552) FIXME: Cannot find name 'ContentfulFooterFragment'. Did y... Remove this comment to see the full error message
  const contentfulFooter: Maybe<ContentfulFooterFragment> =
    getPageLayoutComponent(components, 'ContentfulFooter')
  const modalExitIntent = getPageLayoutComponent(
    components,
    'ContentfulModalExitIntent'
  )
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const modalPromoPopup: Maybe<ContentfulModalPromoPopup> =
    getPageLayoutComponent(components, 'ContentfulModalPromoPopup')
  const contentfulModalPromoPopupVariationContainer =
    getVariationContainerComponent(components, 'ContentfulModalPromoPopup')
  const authentication = getPageLayoutComponent(
    components,
    'ContentfulAuthentication'
  )
  // @ts-expect-error TS(2322) FIXME: Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const trustpilotBanner: Maybe<ContentfulTrustpilotReviewsBanner> =
    getPageLayoutComponent(components, 'ContentfulTrustpilotReviewsBanner')
  // @ts-expect-error TS(2322): Type 'Maybe<PageLayoutComponent>' is not assignabl... Remove this comment to see the full error message
  const pageBanner: Maybe<ContentfulPageBanner> = getPageLayoutComponent(
    components,
    'ContentfulPageBanner'
  )

  return {
    authentication,
    contentfulDefaultBanner,
    contentfulFloatingPromoWidget,
    contentfulFooter,
    contentfulHeader,
    contentfulHeaderVariationContainer,
    contentfulModalPromoPopupVariationContainer,
    headerPartner,
    headerProgressBar,
    modalExitIntent,
    modalPromoPopup,
    pageBanner,
    popupWizard,
    simpleFooter,
    trustpilotBanner
  }
}

export const renderPageComponents =
  (pageContext?: PageProps['pageContext'], location?: PageProps['location']) =>
  (pageComponents: readonly PageComponent[]) =>
    pageComponents.map((pageComponent: PageComponent) => {
      const contents = mapPageComponentToTemplate(
        pageComponent,
        pageContext,
        location
      )

      return (
        <React.Fragment key={pageComponent.id}>
          {shouldSkipPageSectionWrapper(pageComponent) ? (
            <>{contents}</>
          ) : (
            <PageSection>{contents}</PageSection>
          )}
        </React.Fragment>
      )
    })

/**
 * Some content types need to skip the PageSection wrapper for display purposes,
 * for example ContentCollection as its inner components also get wrapped for
 * padding/margin purposes and end up getting doubled.
 *
 * A different way to do this would be to add a consistent flag on the content
 * type in the CMS but that seems awkward. What would be nice is selecting out
 * a literal value in the component's own graphql fragment but that doesn't seem
 * to be possible with graphql.
 */
const typesToSkipPageSectionWrapper = [
  'ContentfulContentCollection',
  'ContentfulCrimeLocationResults',
  'ContentfulVariationContainer'
]
const shouldSkipPageSectionWrapper = (pageComponent: PageComponent) =>
  safePath(['internal', 'type'], pageComponent)
    .filter((type: string) => typesToSkipPageSectionWrapper.indexOf(type) > -1)
    .cata(F, T)
