import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { ContentfulRichText } from '@ecomm/shared-components'

const options: Options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul className="break-before-avoid break-inside-avoid">{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (_, children) => (
      <li className="list-disc">{children}</li>
    )
  }
}

/**
 * A page section used to show a multi-column bulleted list of product features,
 * specs, etc. on featured product pages.
 */
export default function ProductDetails({
  content
}: {
  readonly content: ContentfulRichTextSchema
}) {
  return (
    <div
      className={'bg-neutral-light-50 flex rounded-xl md:justify-center'}
      data-component="ProductDetails"
    >
      <div className="max-w-[900px] columns-1 p-8 md:columns-2 md:gap-16">
        <ContentfulRichText optionsCustom={options} raw={content.raw} />
      </div>
    </div>
  )
}
