import { selectCurrentPromoTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

/**
 *
 * Checks if the top promo banner is currently visible.
 *
 * @example
 * const isPromoTopBanner = useTopBannerVisible() // => boolean
 */
export const useTopBannerVisible = () => {
  const topBannerVisible = useSelector(selectCurrentPromoTopBannerVisible)
  return topBannerVisible
}
