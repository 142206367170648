import React, { FC, ReactNode } from 'react'

type PageMastheadProps = {
  readonly children: ReactNode
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const PageMasthead: FC<PageMastheadProps> = ({
  children,
  dataComponent = PageMasthead.name
}: PageMastheadProps) => (
  <div className="mb-8 lg:mb-16" data-component={dataComponent}>
    {children}
  </div>
)

export default PageMasthead
