import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.WeatherResistant,
    text: 'Outdoor use'
  },
  {
    icon: SharedIcons.MotionDetection,
    text: '162° field of view'
  },
  {
    icon: SharedIcons.Zoom,
    text: 'Pan and zoom'
  },
  {
    icon: SharedIcons.Wired,
    text: 'Wired'
  },
  {
    icon: SharedIcons.SmartMotion,
    text: 'Smart motion detection'
  },
  {
    icon: SharedIcons.NoDrill,
    text: 'No-drill install'
  }
]

const description = {
  content: `Now you can see and speak to anyone at your front door, whether or not they ring the doorbell. Thoughtful motion alerts let you know when someone's there. Keep an eye on packages, mail, and other activity on your doorstep, even when you're not at home.
  \nThe Video Doorbell Pro connects to almost any existing doorbell wiring. With select monitoring plans, the camera starts recording when motion is detected or an alarm is triggered.
  `
}

const specs = {
  content: `4.35 × 1.35 x 1.12 inches
  \nRequires Wi-Fi (2.4GHz) and existing wired doorbell setup with mechanical chime
  and a transformer with 8-24 VAC, 30VA max. Not compatible with digital chimes.
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '5BU6jngNJETr8ykDdlBRF7'
}

export const videoDoorbellProData: ProductData = {
  productId: '1DInh8ieU6Ay20jWmdBcoW',
  sensorName: 'Video Doorbell Pro',
  features,
  description,
  specs,
  faqs
}
