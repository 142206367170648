import { footerSchema } from '@ecomm/footer-data'
import { type TypeOf, z } from '@simplisafe/ewok'

export const layoutSchema = z.object({
  __typename: z.literal('Layout'),
  sys: z.object({
    id: z.string()
  }),
  internalTitle: z.string(),
  footer: footerSchema.optional(),
  promoBannerStyle: z.string(),
  ready: z.boolean().default(true)
})

export type LayoutFragment = TypeOf<typeof layoutSchema>
