import { benefitsTextSchema } from '@ecomm/city-state-data'
import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

export const benefitsSchema = z.object({
  images: z.object({
    nodes: gatsbyImageSchema.array()
  }),
  text: benefitsTextSchema
})

export type BenefitsSchema = TypeOf<typeof benefitsSchema>
