//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import Text from '../Text'
import * as css from './LookingForUsSiteModal.module.scss'

export type LookingForUsSiteModalProps = {
  /** The content of the component */
  readonly content?: ReactNode
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const LookingForUsSiteModal: FC<LookingForUsSiteModalProps> = ({
  content,
  dataComponent = LookingForUsSiteModal.name
}: LookingForUsSiteModalProps) => {
  return (
    <Text
      className="prose-a:hover:!text-white max-w-md bg-white md:max-w-lg"
      data-component={dataComponent}
      useTailwind
    >
      <div
        className={classNames(
          'mx-auto my-0 px-4 py-8 text-center md:px-8 md:py-16 lg:p-16',
          css.siteRedirectContent
        )}
      >
        {content}
      </div>
    </Text>
  )
}

export default LookingForUsSiteModal
