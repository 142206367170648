import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'

import { SensorCollapsibleSection } from '../SensorCollapsibleSection'

type SensorDescriptionProps = {
  readonly description: string
  readonly specs?: string
  readonly isVisible?: boolean
}

export function SensorDescription({
  description,
  specs = '',
  isVisible = false
}: SensorDescriptionProps) {
  const microCopy = useMicroCopy()

  return (
    <>
      <SensorCollapsibleSection
        isVisible={isVisible}
        title={microCopy['how-it-keeps-you-safe']}
      >
        {description}
      </SensorCollapsibleSection>
      {specs ? (
        <SensorCollapsibleSection title={microCopy['specs']}>
          {specs}
        </SensorCollapsibleSection>
      ) : null}
    </>
  )
}
