import classNames from 'classnames'
import React from 'react'

type PartnerOfferTextProps = {
  readonly discountText?: string
  /** show primary and secondary text on single line when space available at all breakpoints */
  readonly isSingleLine?: boolean
  /** show standard offer without extra spacing or line breaks at all breakpoints */
  readonly isCompact?: boolean
  readonly lineItemText?: string
  readonly monitoringText?: string
  readonly primaryText?: string
  readonly secondaryText?: string
  readonly secondaryTextMobile?: string
}

const joinWithElement = (
  strings: readonly (string | undefined)[],
  element: JSX.Element
) =>
  strings
    .filter(val => !!val)
    .map((text, index, arr) => (
      <span key={index}>
        {text} {index < arr.length - 1 && element}
      </span>
    ))

export function PartnerOfferText({
  discountText = '',
  lineItemText = '',
  monitoringText = '',
  primaryText = '',
  secondaryText = '',
  secondaryTextMobile = '',
  isSingleLine = false,
  isCompact = false
}: PartnerOfferTextProps) {
  const hasStandardOfferText = discountText || lineItemText || monitoringText

  const standardOfferText = joinWithElement(
    [`${discountText} ${lineItemText}`, monitoringText],
    <span className={classNames(!isCompact && 'md:mx-4 lg:mx-7')}> + </span>
  )

  const standardOfferToken = '{standard-offer}'
  const splitPrimaryText = primaryText && primaryText.split(standardOfferToken)
  const primaryTextWithStandardOffer =
    splitPrimaryText && splitPrimaryText.length > 1 ? (
      <>
        {splitPrimaryText[0]}
        {standardOfferText}
        {splitPrimaryText[1]}
      </>
    ) : (
      primaryText
    )

  return (
    <div className="text-center">
      <div
        className={classNames(
          'flex flex-wrap justify-center gap-x-6 gap-y-1 md:gap-y-3',
          isSingleLine ? 'flex-row' : 'flex-col'
        )}
      >
        {hasStandardOfferText || primaryText ? (
          <div className="font-bold uppercase">
            {primaryText ? primaryTextWithStandardOffer : standardOfferText}
          </div>
        ) : null}
        {secondaryText ? (
          <div
            className={`text-promo-secondary-text ${
              secondaryTextMobile ? 'hidden md:block' : ''
            }`}
          >
            {secondaryText}
          </div>
        ) : null}
        {secondaryTextMobile ? (
          <div className="text-promo-secondary-text-mobile md:hidden">
            {secondaryTextMobile}
          </div>
        ) : null}
      </div>
    </div>
  )
}
