//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import React, { FC } from 'react'

import CountryGridItem, { CountryGridProps } from '../CountryGridItem'
import * as css from './CountryGridContainer.module.scss'

export type CountryGridContainerProps = {
  readonly locationGridProps: readonly CountryGridProps[]
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const CountryGridContainer: FC<CountryGridContainerProps> = ({
  locationGridProps,
  dataComponent = CountryGridContainer.name
}: CountryGridContainerProps) => {
  const gridItemList = locationGridProps.map(
    ({ linkText, linkUrl, backgroundComponentProps, BackgroundComponent }) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const keyValue = ifElse(
        isEmpty,
        always('empty'),
        always(linkText)
      )(linkText)

      return (
        <div
          className={classNames(css.locationGridItem)}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          key={keyValue}
        >
          <CountryGridItem
            BackgroundComponent={BackgroundComponent}
            backgroundComponentProps={backgroundComponentProps}
            linkText={linkText}
            linkUrl={linkUrl}
          />
        </div>
      )
    }
  )

  return (
    <div
      className={classNames(css.crimeLocation)}
      data-component={dataComponent}
    >
      {gridItemList}
    </div>
  )
}

export default CountryGridContainer
