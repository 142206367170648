import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './data'
import { schema } from './schema'

export function useScoutProductPageInstallationTwoColumnVideo() {
  const { description } = useLocalizedData<typeof schema>(schema, data)

  return useMemo(
    () => ({ description: { raw: description.raw } }),
    [description.raw]
  )
}
