//@ts-nocheck
import React, { FC } from 'react'

import HouseViewButton, { HouseViewButtonProps } from './HouseViewButton'
import * as css from './HowItWorksHouse.module.scss'

export type HouseViewButtonGroupProps = {
  readonly buttons: readonly HouseViewButtonProps[]
  readonly dataComponent?: string
}

const HouseViewButtonGroup: FC<HouseViewButtonGroupProps> = ({
  buttons,
  dataComponent = HouseViewButtonGroup.name
}: HouseViewButtonGroupProps) => {
  return (
    <ul className={css.buttonGroup} data-component={dataComponent}>
      {buttons.map((buttonProps: HouseViewButtonProps) => (
        <li
          className="flex md:mt-2.5 md:first:mt-0"
          key={`button-${buttonProps.id}`}
        >
          <HouseViewButton {...buttonProps} />
        </li>
      ))}
    </ul>
  )
}

export default HouseViewButtonGroup
