import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import {
  COOKIE_FCP_ORDER_ID,
  COOKIE_LEAD_DATA,
  COOKIE_PURCHASED_CART_ID,
  getCookie,
  getValueFromPartnerCookie,
  setCookie
} from '@ecomm/shared-cookies'
import {
  type OptimizelyPurchaseEvent,
  type TrackingData,
  brazeSetCustomUserAttributes,
  fbTrackLeadCreated,
  generateFacebookEventId,
  handleBrazeTrackingEvent,
  setBrazeAttributeGoogleClientId,
  sha256Web,
  toTrackCartItemsList,
  trackPaymentConfirmation,
  trackTransactionEvent,
  useFacebookTrackProductPurchase,
  useLeadGenCaptureV2,
  useGoogleAnalytics,
  useOptimizelyTrackProductPurchase,
  useOptimizelyTrackSiteEvents
} from '@ecomm/tracking'
import { type MaybeT, Maybe } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import { selectCartShippingAddress } from '@simplisafe/ss-ecomm-data/cart/select'
import { getCartDetails } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import {
  type LeadGenCaptureParams,
  type LeadGenCaptureResponse,
  leadGenCapture
} from '@simplisafe/ss-ecomm-data/leads/capture'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import type { PaymentState } from '../usePayment/usePayment'

const AFFIRM_SELECTED = 'affirm'

const handleFailure = (error: Error) => logError(error)

const handleSuccess = (value: MaybeT<LeadGenCaptureResponse>) => {
  setCookie(COOKIE_LEAD_DATA, value.orUndefined(), cookiesOption)

  handleBrazeTrackingEvent(value.orUndefined())

  value.forEach(
    async response =>
      response.email && (await fbTrackLeadCreated(response.email))
  )
}

const leadCapture = (email: string) => {
  const leadGenParams: LeadGenCaptureParams = {
    email,
    source: 'purchase',
    sourceType: 'purchase'
  }
  leadGenCapture(leadGenParams)(handleFailure)(handleSuccess)
}

/**
 * Tracks various purchase events upon payment completion.
 */
export const useTrackPurchaseComplete = (
  paymentState: PaymentState,
  selectedPaymentOption: TrackingData['selectedPaymentOption'],
  trackEvent: (_data: TrackingData) => void
) => {
  const cart = useSelector(selectCart)
  const shippingAddress = useSelector(selectCartShippingAddress)
  const locale = useLocale()
  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  const optimizelyTrackProductPurchase = useOptimizelyTrackProductPurchase()
  const facebookTrackProductPurchase = useFacebookTrackProductPurchase()
  const leadGenCaptureV2 = useLeadGenCaptureV2()
  const [clientId] = useGoogleAnalytics()

  useEffect(() => {
    cart.forEach(
      /**
       * Check if the Payment Complete flag is
       * true and send some tracking data out
       */
      _cart =>
        Maybe.fromFalsy(paymentState === 'complete').forEach(() => {
          const email = _cart.shippingAddress
            .chain(safeProp('email'))
            .orJust('')
          const phone = _cart.shippingAddress
            .chain(safeProp('phone'))
            .orJust('')

          leadCapture(email)

          leadGenCaptureV2({
            source: 'purchase',
            siteMetadata: { sourceType: 'purchase' },
            email
          })

          // set google client id for UK as custom braze attribute ECP-12023
          locale === 'en-GB' &&
            clientId &&
            setBrazeAttributeGoogleClientId(clientId)

          const partnerName = getValueFromPartnerCookie('partnerName')
          partnerName &&
            brazeSetCustomUserAttributes({ 'Partner Name': partnerName })

          safeProp('id', _cart).forEach(cartId =>
            setCookie(COOKIE_PURCHASED_CART_ID, cartId, cookiesOption)
          )

          const orderId = getCookie<string>(COOKIE_FCP_ORDER_ID)
          const cartTotal = safeProp('totalPrice', _cart).getOrElse(0)
          const systemInOrder = _cart.isThereAnySecurity

          trackPaymentConfirmation(
            trackEvent,
            _cart,
            systemInOrder,
            orderId,
            cartTotal,
            email,
            selectedPaymentOption,
            shippingAddress,
            locale
          )

          trackTransactionEvent(
            trackEvent,
            _cart,
            selectedPaymentOption,
            cartTotal,
            orderId
          )

          // Optimizely events
          optimizelyTrackSiteEvents({
            eventKey: 'track_revenue',
            revenue: Number(cartTotal)
          })

          selectedPaymentOption === AFFIRM_SELECTED &&
            optimizelyTrackSiteEvents({
              eventKey: 'affirm_payment_revenue',
              revenue: Number(cartTotal)
            })

          const cartItems = toTrackCartItemsList(
            getCartDetails([])(_cart.get('lineItems'))
          )

          cartItems.forEach((cartItem: OptimizelyPurchaseEvent) =>
            optimizelyTrackProductPurchase(cartItem)
          )
          // Facebook events
          sha256Web(email)
            .then((emailHash: string) =>
              facebookTrackProductPurchase({
                contents: cartItems,
                email: emailHash,
                eventId: generateFacebookEventId(),
                orderId: orderId,
                phone,
                value: cartTotal
              })
            )
            .catch((e: Error) => logError(e))
        })
    )
  }, [
    cart,
    paymentState,
    trackEvent,
    selectedPaymentOption,
    optimizelyTrackProductPurchase,
    optimizelyTrackSiteEvents,
    shippingAddress,
    facebookTrackProductPurchase,
    locale
  ])
}
