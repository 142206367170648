//@ts-nocheck
import classNames from 'classnames'
import { differenceInDays, differenceInWeeks, isValid, parse } from 'date-fns'
import React, { type FC, useMemo, useState } from 'react'

import * as css from './OutOfStockMessage.module.scss'

type OutOfStockMessageProps = {
  readonly backInStockDate: string
  readonly includedInPackage?: boolean
  readonly lowStockMessage?: boolean
  readonly coreComponentsDesc?: string
  readonly coreComponentNotInStock?: boolean
}

export const getShippingEstimate = (
  backInStockDate: string
): readonly [string, boolean] => {
  const parsedDate = parse(backInStockDate, 'yyyy-MM-dd', new Date())
  const restockDate = isValid(parsedDate) ? parsedDate : new Date()
  const inDaysDifference = differenceInDays(restockDate, new Date())
  const inWeeksDifference = differenceInWeeks(restockDate, new Date(), {
    roundingMethod: 'round'
  })

  const weeksDifferenceLabel = `${inWeeksDifference} - ${inWeeksDifference + 1}`
  const shippingEstimate =
    inDaysDifference < 8
      ? `${inDaysDifference} days`
      : `${weeksDifferenceLabel} weeks`

  const displayOutOfStockMessage = inDaysDifference > 0
  return [shippingEstimate, displayOutOfStockMessage]
}

/** @deprecated Do not use ss-react-components*/
const OutOfStockMessage: FC<OutOfStockMessageProps> = ({
  backInStockDate,
  includedInPackage = false,
  lowStockMessage = false,
  coreComponentsDesc,
  coreComponentNotInStock = false
}: OutOfStockMessageProps) => {
  const [shippingEstimate, displayOutOfStockMessage] = useMemo(() => {
    return getShippingEstimate(backInStockDate)
  }, [backInStockDate])

  const [showLearnMore, setShowLearnMore] = useState(false)

  // TODO: this needs to be refactored to show content from Contentful
  return displayOutOfStockMessage ? (
    <>
      {lowStockMessage ? 'Low Stock: ' : null}
      Ships{includedInPackage ? ' separately' : null} in {shippingEstimate}
      {coreComponentNotInStock ? '. ' : null}
      {coreComponentNotInStock && coreComponentsDesc ? (
        <div
          className={classNames(css.learnMoreWrapper)}
          data-component="OutOfStockMessage"
        >
          <span
            className={css.learnMoreBtn}
            onClick={() => setShowLearnMore(true)}
          >
            Learn more
          </span>
          <div
            className={classNames([
              css.learnMorePopup,
              'neutralBlackTextColor',
              'hideFadeOut',
              { showFadeIn: showLearnMore }
            ])}
          >
            <span
              className={classNames(css.closeBtn)}
              onClick={() => setShowLearnMore(false)}
            ></span>
            <p>{coreComponentsDesc}</p>
          </div>
        </div>
      ) : null}
    </>
  ) : null
}

export default OutOfStockMessage
