//@ts-nocheck
import React, { FC } from 'react'

import HouseView, { HouseViewProps } from './HouseView'
import * as css from './HowItWorksHouse.module.scss'

export type HouseViewGroupProps = {
  readonly views: readonly HouseViewProps[]
  readonly dataComponent?: string
}

const HouseViewGroup: FC<HouseViewGroupProps> = ({
  views,
  dataComponent = HouseViewGroup.name
}: HouseViewGroupProps) => {
  return (
    <div className={css.houseViews} data-component={dataComponent}>
      {views.map((viewProps: HouseViewProps, index: number) => (
        <HouseView key={`${viewProps.name}-${index}`} {...viewProps} />
      ))}
    </div>
  )
}

export default HouseViewGroup
