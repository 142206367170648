import { useLocale } from '@ecomm/data-hooks'
import { useMemo } from 'react'

import { data as dataUK } from './scoutProductPageData-en-GB'
import { data as dataUS } from './scoutProductPageData-en-US'

export function useScoutProductPageAppWidgetData() {
  const locale = useLocale()
  return useMemo(() => (locale === 'en-US' ? dataUS : dataUK), [locale])
}
