import { FeatureListItemProps } from './FeatureListItem'

export const featureListItemsLeftData: readonly FeatureListItemProps[] = [
  {
    children: 'Up to 3 year total warranty*',
    isFeatured: true
  },
  {
    children: '24/7 priority police dispatch',
    isFeatured: true
  },
  {
    children: '24/7 fire department dispatch',
    isFeatured: true
  },
  {
    children: '24/7 ambulance dispatch',
    isFeatured: true
  },
  {
    children: 'HD live view on all cameras',
    isFeatured: true
  }
]

export const featureListItemsRightData: readonly FeatureListItemProps[] = [
  {
    children: 'Unlimited camera recordings',
    isFeatured: false
  },
  {
    children: 'Video verification',
    isFeatured: false
  },
  {
    children: 'Hazard monitoring',
    isFeatured: false
  },
  {
    children: 'Extra Hardware Discount',
    isFeatured: false
  }
]
