import { ContentfulRichText } from '@ecomm/shared-components'
import * as O from 'fp-ts/lib/Option'
import React from 'react'

import { PaymentState } from '../ZuoraPaymentForm/ZuoraPaymentForm'

export type RawProps = {
  readonly raw?: string
}

type CardVerificationNoteProps = {
  readonly cardVerificationNote?: O.Option<RawProps>
  readonly paymentState: PaymentState
}

export function CardVerificationNote({
  cardVerificationNote = O.none,
  paymentState
}: CardVerificationNoteProps): JSX.Element {
  return O.isSome(cardVerificationNote) && paymentState === 'ready' ? (
    <div
      className="p-4 grid grid-cols-12 items-center"
      key="payment-verification-note"
    >
      <div className="relative max-w-full col-span-12 justify-self-center bg-neutral-light-100 p-4">
        <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
          <ContentfulRichText
            raw={O.match(
              () => '',
              (x: RawProps) => x.raw ?? ''
            )(cardVerificationNote)}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
