import { fetchApi } from '@ecomm/data-simplisafe-api'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import { pipe } from 'fp-ts/lib/function'
import { Option } from 'fp-ts/lib/Option'
import * as TE from 'fp-ts/lib/TaskEither'

export type BusinessCaptureResponse = {
  readonly returnCode: string
  readonly message: string
  readonly email: Option<string>
  readonly leadId: Option<string>
  readonly leadSource: Option<string>
}

export type BusinessCapturePayload = {
  readonly businessType?: string
  readonly companyName: string
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly numLocations?: string
  readonly phone?: string
  readonly source?: string
}

const isPartnerWithUsResponse = (
  res: unknown
): res is BusinessCaptureResponse => {
  polyfillHasOwn()
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'message') &&
    Object.hasOwn(res, 'email') &&
    Object.hasOwn(res, 'return_code')
  )
}

export const businessCapture = (payload: BusinessCapturePayload) => {
  return pipe(
    TE.Do,
    TE.chain(() => {
      return fetchApi({
        method: 'POST',
        endpoint: '/leads/v1/business/capture',
        headers: {},
        body: JSON.stringify(payload)
      })
    }),
    TE.chain(res =>
      isPartnerWithUsResponse(res) ? TE.right(res) : TE.left(res)
    ),
    TE.mapLeft(res => Error(`Error fetching business capture method ${res}`))
  )
}
