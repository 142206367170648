export function createMockImage(
  src = '/test-image.png',
  description = 'test description'
) {
  return {
    url: src,
    originalWidth: 512,
    originalHeight: 512,
    description
  }
}
