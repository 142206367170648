import { ContentfulImage } from '@ecomm/contentful/components'

import { CartPromoBanner } from '../CartPromoBanner'
import { useIsProInstallPromotionActive } from './useIsProInstallPromotionActive'

export function ProInstallPromotionBanner() {
  const isProInstallGiftCardPromotionActive = useIsProInstallPromotionActive()

  return (
    isProInstallGiftCardPromotionActive && (
      <CartPromoBanner
        backgroundColor="bg-complementary-green-100"
        description="We'll send you an email with a promo code to schedule your FREE installation appointment where a highly trained technician will help set up your system. Installation must be scheduled by August 11th, 2024 to qualify. If you prefer, you always have the option to set it up yourself instead."
        image={
          <ContentfulImage
            classNameOverride="bg-complementary-green-100 h-14 w-auto"
            description="Professional Installation Icon"
            originalHeight={56}
            originalWidth={54.41}
            url="https://images.ctfassets.net/v6awd0kabi65/4jojWOiZubBU1sr7eCASEU/071ad704d76d976141601177c0c597f7/pro_install.png"
          />
        }
        title="FREE Professional Installation Added"
      />
    )
  )
}
