import { useState } from 'react'

import { ImageGalleryCurrent } from './ImageGalleryCurrent'
import { ImageGalleryPager } from './ImageGalleryPager'
import { ImageSchema } from './schema'
export function ImageGallery({
  productImages
}: {
  readonly productImages: readonly ImageSchema[]
}) {
  const [currentImage, setCurrentImage] = useState(0)
  const current = productImages?.[currentImage]

  return (
    <div
      className="col-span-12 mb-6 inline-grid max-w-none grid-cols-12 items-stretch overflow-hidden md:col-span-12 lg:col-span-6 lg:row-span-2"
      data-component="ImageGallery"
    >
      <ImageGalleryPager
        currentImage={currentImage}
        onPagerClick={setCurrentImage}
        productImages={productImages}
      />
      <ImageGalleryCurrent current={current} />
    </div>
  )
}
