import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { ContentfulImageSchema } from '@ecomm/contentful/components'
import { head } from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export const getContentfulImageFromGatsbyData = (
  originalImage: GatsbyImageSchema
): ContentfulImageSchema => {
  const { gatsbyImageData, title, description } = originalImage

  const imageSources = gatsbyImageData.images.sources
  const fallbackSources = gatsbyImageData.images.fallback

  const url = pipe(
    O.fromNullable(imageSources),
    O.chain(sources => head(sources)),
    O.chain(source => O.fromNullable(source.srcSet)),
    O.fold(
      () => {
        // if srcSet not exists in the first element of imageSources,
        // we try fallbackSources
        return pipe(
          O.fromNullable(fallbackSources),
          O.chain(fallback => O.fromNullable(fallback.srcSet || fallback.src)),
          O.fold(
            () => '',
            fallbackSrcset =>
              pipe(
                head(fallbackSrcset.split('?')),
                O.getOrElse(() => '')
              )
          )
        )
      },
      //Remove previous query params and srcset so the ContentfulImage builds its own
      srcSet =>
        pipe(
          head(srcSet.split('?')),
          O.getOrElse(() => '')
        )
    )
  )

  return {
    description: description ?? title,
    originalHeight: gatsbyImageData.height,
    originalWidth: gatsbyImageData.width,
    url
  }
}
