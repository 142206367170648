import type { TrustPilot } from '.'

const trustPilotUKBaseConfig = {
  dataBusinessunitId: '5c35aeae94a7770001024810',
  dataLocale: 'en-GB',
  dataTheme: 'light'
}

export const trustPilotUKReviewPageCarousel: TrustPilot = {
  ...trustPilotUKBaseConfig,
  dataTemplateId: '539adbd6dec7e10e686debee',
  dataStars: '4,5',
  dataStyleHeight: '500px',
  dataStyleWidth: '100%'
}

export const trustPilotUKHomePageCarousel: TrustPilot = {
  ...trustPilotUKBaseConfig,
  dataTemplateId: '53aa8912dec7e10d38f59f36',
  dataStars: '4,5',
  dataStyleHeight: '140px',
  dataStyleWidth: '100%'
}

/**
 * this is deprecated, use TrustpilotUKTopBanner component instead
 */
export const trustPilotUKPageTopBanner: TrustPilot = {
  ...trustPilotUKBaseConfig,
  dataTemplateId: '5419b6ffb0d04a076446a9af',
  dataStars: '5',
  dataStyleHeight: '50px',
  dataStyleWidth: '100%'
}

/**
 * use TrustpilotUKTopBanner component instead of this widget
 */
export const trustPilotUKPageBannerWidget: TrustPilot = {
  ...trustPilotUKBaseConfig,
  dataTemplateId: '5406e65db0d04a09e042d5fc',
  dataStyleHeight: '28px',
  dataStyleWidth: '100%'
}
