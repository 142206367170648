import { useActivate } from '@ecomm/tracking'
import {
  parseSchemaWithVariations,
  parseVariants,
  safeProp,
  validateString,
  VariationFragment,
  variationSchema
} from '@ecomm/utils'
import { TypeOf } from '@simplisafe/ewok'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { findFirst, map } from 'fp-ts/lib/ReadonlyArray'
import { useEffect, useMemo, useState } from 'react'
import { DeepNullable, DeepPartial } from 'ts-essentials'
import { z } from 'zod'

/** Infers the type of the fragment based on the Zod schema with everything being nullable and optional. */
type Fragment<U extends z.ZodTypeAny> = DeepPartial<DeepNullable<TypeOf<U>>>

/**
 * An easy way to parse an array of fragments with a Zod schema and automatically activate any available optimizely experiments.
 * 1 experiment can run for the given entity; it will not support 2 different optimizely experiments for the same content type on the same page
 *
 * @deprecated ⚠️⚠️ use `@ecomm/optimizely-hooks` instead ⚠️⚠️
 */
function useFragmentsWithVariations<T extends z.ZodTypeAny, U>(
  fragments: ReadonlyArray<U> | null | undefined,
  baseSchema: T
) {
  const schemaWithVariations = z.intersection(
    baseSchema,
    variationSchema(baseSchema)
  )

  type S = TypeOf<typeof schemaWithVariations>

  const parsedFragmentsWithVariations: ReadonlyArray<S & VariationFragment<T>> =
    (fragments || []).map(fragment =>
      parseSchemaWithVariations<Fragment<typeof baseSchema>, S, TypeOf<T>>(
        fragment,
        schemaWithVariations
      )
    )

  const experimentKey: string = validateString(
    pipe(
      parsedFragmentsWithVariations,
      findFirst(fragment => fragment.variations.experimentKey),
      O.chain(safeProp('variations.experimentKey')),
      O.toUndefined
    )
  )

  /** This can be used later to know if we need a loading state for SSR */
  const activeExperiment: boolean = parsedFragmentsWithVariations.some(
    fragment => fragment.variations.variations.length > 0
  )

  const variation = useActivate(experimentKey)

  /**
   * If ready is false we need to show a loading skeleton to avoid a flicker when react switches from SSR to client content.
   * Ready is true if there is not an active experiment, we can just render the content.
   * Ready is false if there is an active experiment, it will be set to true after React has hydrated and rendered for the first time.
   */
  const [ready, setReady] = useState(!activeExperiment)

  useEffect(() => {
    setReady(true)
  }, [])

  const parsedFragments = useMemo(() => {
    const fragmentThatMatchesVariation = pipe(
      variation,
      O.fromNullable,
      O.fold(
        () => parsedFragmentsWithVariations,
        variation =>
          pipe(
            parsedFragmentsWithVariations,
            map(fragment => parseVariants(variation, fragment))
          )
      )
    )

    return fragmentThatMatchesVariation
  }, [parsedFragmentsWithVariations, variation])

  const parsedFragmentsWithState: readonly [
    ParsedFragments: ReadonlyArray<S>,
    isReady: boolean
  ] = [z.array(schemaWithVariations).parse(parsedFragments), ready]

  return parsedFragmentsWithState
}

export default useFragmentsWithVariations
