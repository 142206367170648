import { ApolloRichText, ContentfulImage } from '@ecomm/contentful/components'
import classNames from 'classnames'
import React from 'react'
import { match } from 'ts-pattern'

import { type GridSchema, type IconSchema, isSmallTextArray } from './schema'

const renderIconImage = (icon: IconSchema) => {
  return match(icon)
    .when(
      i => i.url.includes('svg'),
      () => (
        <img alt={icon.description || ''} className="h-8 w-8" src={icon.url} />
      )
    )
    .otherwise(() => <ContentfulImage {...icon} className="h-8 w-8" />)
}

export function GridSection({
  headline,
  headlineAlignment,
  showBorders,
  showTextOnMobile,
  gridItemsCollection: { items: gridItems },
  desktopColumns = '4',
  mobileColumns = '1',
  tabletColumns = '2'
}: GridSchema) {
  const length = gridItems.length //number of cols
  const totalDesktopColumns = Math.min(Number(desktopColumns), length)
  const totalTabletColumns = Math.min(Number(tabletColumns), length)
  const totalMobileColumns = Math.min(Number(mobileColumns), length)

  const desktopColSpan = Math.floor(12 / totalDesktopColumns)
  const tabletColSpan = Math.floor(12 / totalTabletColumns)
  const mobileColSpan = Math.floor(12 / totalMobileColumns)

  return (
    <section>
      {headline ? (
        <h2
          className={classNames(
            'mb-4 md:mb-8',
            headlineAlignment === 'center' && 'text-center',
            headlineAlignment === 'right' && 'text-right'
          )}
        >
          {headline}
        </h2>
      ) : null}
      <ul className="m-0 grid list-none grid-cols-12 gap-4 p-0 md:gap-8">
        {match(gridItems)
          .when(isSmallTextArray, items =>
            items.map((item, index) => {
              const json = item.text?.json
              return (
                <li
                  className={classNames(
                    'col-span-12 flex flex-row items-center gap-4 md:flex-col md:items-start md:gap-0',
                    {
                      'col-span-6': mobileColSpan === 6,
                      'md:col-span-6': tabletColSpan === 6,
                      'md:col-span-1': tabletColSpan === 1,
                      'md:col-span-2': tabletColSpan === 2,
                      'md:col-span-3': tabletColSpan === 3,
                      'md:col-span-4': tabletColSpan === 4,
                      'lg:col-span-1': desktopColSpan === 1,
                      'lg:col-span-2': desktopColSpan === 2,
                      'lg:col-span-6': desktopColSpan === 6, // default settings
                      'lg:col-span-4': desktopColSpan === 4, // default
                      'lg:col-span-3': desktopColSpan === 3 // default
                    },
                    showBorders &&
                      'rounded-lg border border-solid border-gray-100 p-10'
                  )}
                  key={index}
                >
                  {item.icon ? renderIconImage(item.icon) : null}
                  <span
                    className={classNames(
                      'prose-h3:mb-2 prose-h3:text-body-size prose-h3:font-bold prose-h3:leading-normal prose-p:mb-0 mt-2',
                      !showTextOnMobile && 'prose-p:hidden md:prose-p:block'
                    )}
                  >
                    {json ? <ApolloRichText json={json} /> : null}
                  </span>
                </li>
              )
            })
          )
          .otherwise(() => null)}
      </ul>
    </section>
  )
}
