import {
  useCurrentPromoBannerData,
  useSitewidePromoEndDateDisplay,
  useSitewidePromoOfferTitle
} from '@ecomm/promotions-hooks'
import React from 'react'

import { RelativeEndTime } from '../../RelativeEndTime'

export function Header() {
  const { endDate } = useSitewidePromoEndDateDisplay()

  const { giftLineItemText, hasSaleName, saleName, overrideBannerText } =
    useCurrentPromoBannerData()
  const offerTitle = useSitewidePromoOfferTitle()

  // Upperase offer like 'X OFF' when its the only offerTitle text and not override text.
  const offerUppercased = !overrideBannerText
    ? offerTitle.toUpperCase()
    : offerTitle

  return (
    <div
      className="inline-grid pr-3 text-xs uppercase"
      data-component="MobileBannerTopHeader"
    >
      <div className="inline-grid">
        <RelativeEndTime displayEndsText endDate={new Date(endDate)} />
        {hasSaleName ? saleName : null}
      </div>
      <div className="mt-2 font-bold">
        <span className="mr-1">{offerUppercased}</span>
        {giftLineItemText ? (
          <span className="text-center">{giftLineItemText}</span>
        ) : null}
      </div>
    </div>
  )
}
