import { ninetailedFeatureFlagSchema } from '@ecomm/promotions-components'
import { parseObjectKey } from '@simplisafe/ewok'
import { graphql, useStaticQuery } from 'gatsby'

export const usePromoBannerExperimentQuery = () => {
  const promoBannerExperiment = useStaticQuery(graphql`
      query MonitoringPromoBannerExperiment {
        promoBannerExperiment: contentfulNinetailedFeatureFlag(
          contentful_id: { eq: "1GaRfPYfJQJuediSrCar1r" }
        ) {
          ...monitoringFeatureFlagBase
          nt_experiences {
            ...monitoringFeatureFlagExperience
          }
        }
      }
    `)

  return parseObjectKey(
    promoBannerExperiment,
    'promoBannerExperiment',
    ninetailedFeatureFlagSchema
  )
}
