import React, { ReactNode } from 'react'

/**
 * Acts like PageSection, but without the padding on the sides. Useful for
 * embedding each component within a Collapsible so they are spaced apart
 * consistently.
 */
export function CollapsibleSection({
  children
}: {
  readonly children: ReactNode
}) {
  return (
    <div
      className="mt-8 first:mt-0 md:mt-12 lg:mt-16"
      data-component="CollapsibleSection"
    >
      {children}
    </div>
  )
}
