import { useTrackingMonitoringMeetTheSystem } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useOnButtonClick() {
  const trackMonitoringMeetTheSystem = useTrackingMonitoringMeetTheSystem()

  return useCallback(() => {
    trackMonitoringMeetTheSystem()
  }, [trackMonitoringMeetTheSystem])
}
