import { getRudderstack } from '@ecomm/shared-window'

export function trackMonitoringCTA() {
  getRudderstack(r => r.track('monitoring_cta'))
}

/**
 * Track click compare plans on choose/change and features-alarm-monitoring pages.
 */
export function trackComparePlanClick() {
  getRudderstack(r => r.track('compare_plan_click'))
}
