import useFragmentWithVariations from '../../hooks/useFragmentWithVariations'
import plpPageSchema, { plpPageLayout } from './plpPageSchema'

export const usePlpPageFragment = <
  T extends { readonly contentfulPackageListingPage: U },
  U extends { readonly slug: string }
>(
  query: T
) => {
  const plpPageFragment = query.contentfulPackageListingPage

  const plpPage = useFragmentWithVariations(plpPageFragment, plpPageSchema)

  const layoutFragment = plpPage.layout

  const layout = useFragmentWithVariations(layoutFragment, plpPageLayout)

  return {
    ...plpPageSchema.parse(plpPage),
    slug: plpPageFragment.slug,
    layout: plpPageLayout.parse(layout)
  }
}
