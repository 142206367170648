import getLocale from './getLocale'

const LOCALE_KEY = 'locale'

/**
 * Writes locale to local storage.
 */
export const localeToLocalStorage = () => {
  const locale = getLocale()
  localStorage &&
    !localStorage.getItem(LOCALE_KEY) &&
    localStorage.setItem(LOCALE_KEY, locale)
}
