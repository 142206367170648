import React from 'react'

type Props = {
  readonly className?: string
}

export function ChevronIcon({ className = '' }: Props) {
  return (
    <svg
      className={className}
      data-component="ChevronIcon"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#006fee">
          <path d="M21.304 9.282L22.696 10.718 11.665 21.414 1.282 10.696 2.718 9.304 11.709 18.586z" />
        </g>
      </g>
    </svg>
  )
}
