import { ODMON_DISCOUNT_CODE } from '@ecomm/data-constants'
import { useCurrentPromoBannerData } from '../useCurrentPromoBannerData/index'

const experimentId_ = 'apply_third_tier_code'

export const useConditionalOdmonDiscountData = () => {
  const { experimentId } = useCurrentPromoBannerData()

  const isApplicablePromo = experimentId === experimentId_

  return isApplicablePromo ? [ODMON_DISCOUNT_CODE] : []
}
