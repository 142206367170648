import {
  selectCurrentPromoCode,
  selectCurrentPromoCodeWithMonitoring,
  selectCurrentPromoDynamicDiscountCodes,
  selectPartnerPromoCodes
} from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

/**
 * The responsibility of this hook is to access all the data
 * we need that is stored in redux, create the needed local
 * states and return all this data.
 */
export function useCurrentPromotionsData() {
  const currentPromoCodeMaybe = useSelector(selectCurrentPromoCode)
  const currentPromoCodeWithMonitoringMaybe = useSelector(
    selectCurrentPromoCodeWithMonitoring
  )
  const partnerPromoCodesMaybe = useSelector(selectPartnerPromoCodes)
  const dynamicPromoCodesMaybe = useSelector(
    selectCurrentPromoDynamicDiscountCodes
  )

  return {
    currentPromoCode: currentPromoCodeMaybe.getOrElse(''),
    currentPromoCodeWithMonitoring:
      currentPromoCodeWithMonitoringMaybe.getOrElse(''),
    partnerPromoCodes: partnerPromoCodesMaybe.getOrElse([]),
    dynamicPromoCodes: dynamicPromoCodesMaybe.getOrElse([])
  }
}
