import React from 'react'

type AlarmMomentSlideProps = {
  readonly text: string
  readonly isSelected?: boolean
  readonly onClick: () => void
}

export function AlarmMomentSlide({
  text,
  isSelected = false,
  onClick
}: AlarmMomentSlideProps) {
  const selected = isSelected ? 'bg-complementary-blue-100' : 'bg-white'
  const textColor = isSelected ? 'text-white' : 'text-neutral-black'

  return (
    <button
      className={`${selected} border-complementary-blue-100 flex min-w-fit	cursor-pointer items-center justify-center rounded-full border border-solid p-2 px-4 transition duration-300`}
      data-component="AlarmMomentSlide"
      onClick={onClick}
    >
      <p className={`${textColor} m-0 p-0 text-base transition duration-300`}>
        {text}
      </p>
    </button>
  )
}
