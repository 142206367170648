import {
  ComparisonTableProps,
  ConfidenceBarProps,
  parseJSONDataFromContentful,
  transformToComparisonTableData
} from '@ecomm/scout'
import { Region } from '@ecomm/seo-locations'

import { BestSystemsCTFLProps } from '../components/BestSystems'
import { DropdownRedirectorProps } from '../components/DropdownRedirector'
import { LocationMapAndDropdown } from '../components/LocationMapAndDropdown'
import { CityStatePage } from '../queries/cityState'
import { getDynamicCityPageMapCoordinates } from './dynamicCityLinks'

export type City = {
  readonly name: string
  readonly url: string
  readonly lat?: number
  readonly lng?: number
}

export type BestSystemsSectionArgs = {
  readonly mapCoordinates: { readonly lat?: number; readonly lng?: number }
  readonly bestSystemsData: BestSystemsCTFLProps
  readonly city?: string
  readonly dropdownRedirectorData: DropdownRedirectorProps
  readonly region: Region
  readonly humanReadableLocation: string
}

export const renderBestSystemSection = ({
  mapCoordinates,
  bestSystemsData,
  city,
  dropdownRedirectorData,
  region,
  humanReadableLocation
}: BestSystemsSectionArgs) => {
  if (mapCoordinates.lng && mapCoordinates.lat) {
    return (
      <LocationMapAndDropdown
        bestSystemsData={{
          buttons: bestSystemsData.buttons,
          title: bestSystemsData.title.replace(
            '{{location}}',
            humanReadableLocation
          ),
          description: bestSystemsData.description[region].replace(
            '{{location}}',
            humanReadableLocation
          )
        }}
        dropdownRedirectorData={city ? null : dropdownRedirectorData}
        mapData={{
          longitude: mapCoordinates.lng,
          latitude: mapCoordinates.lat,
          zoom: city ? 13 : 8
        }}
      />
    )
  } else {
    return null
  }
}

export const getParsedData = (
  cityStatePageData: CityStatePage,
  cities: readonly City[]
) => {
  const confidenceBarData: ConfidenceBarProps = parseJSONDataFromContentful(
    cityStatePageData,
    'confidenceBar'
  )
  const bestSystemsData: BestSystemsCTFLProps = parseJSONDataFromContentful(
    cityStatePageData,
    'bestSystems'
  )
  const dropdownRedirectorData: DropdownRedirectorProps = {
    ...parseJSONDataFromContentful(cityStatePageData, 'dropdownRedirector'),
    links: cities
  }
  const comparisonTableData: ComparisonTableProps =
    transformToComparisonTableData(cityStatePageData)

  return {
    confidenceBarData,
    bestSystemsData,
    dropdownRedirectorData,
    comparisonTableData
  }
}

export const locationDisplayName = (
  state: string,
  stateId?: string,
  city?: string
) => {
  const cityWithStateId = stateId ? `${city}, ${stateId}` : city
  // If city is defined, so is stateId. Hence cityWithStateId is a string and here we apply the String constructor to it to avoid TS errors
  return city && city !== '' ? String(cityWithStateId) : state
}

export const getMapCoordinates = (
  cities: readonly City[],
  isDynamicCityPage: boolean,
  cityName?: string,
  lat?: number,
  lng?: number
) => {
  const mapCoordinatesDynamic =
    isDynamicCityPage && cityName
      ? getDynamicCityPageMapCoordinates(cities, cityName)
      : null
  return mapCoordinatesDynamic ? mapCoordinatesDynamic : { lat, lng }
}
