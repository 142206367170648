import { getShippingEstimate } from '@ecomm/utils'
import React, { useMemo } from 'react'

type Props = {
  readonly includedInPackage?: boolean
  readonly lowStockTemplate?: boolean
  readonly restockDate: string
}

function BackorderMessage({
  includedInPackage = false,
  lowStockTemplate = false,
  restockDate
}: Props) {
  const [shippingEstimate, displayOutOfStockMessage] = useMemo(() => {
    return getShippingEstimate(restockDate)
  }, [restockDate])

  return displayOutOfStockMessage ? (
    <p
      className={'text-alert-100 mb-0 font-medium'}
      data-component="BackorderMessage"
    >
      {lowStockTemplate ? 'Low Stock: ' : null}
      Ships{includedInPackage ? ' separately' : null} in {shippingEstimate}
    </p>
  ) : null
}

export default BackorderMessage
