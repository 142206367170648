import { voidFn } from '@simplisafe/ewok'
import { window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

/**
 * Gets window.navigator and accepts a function that uses the navigator.
 * Handles checking if window.navigator is defined for you and the function will only run if window.navigator is defined.
 *
 * Components and functions outside of `@ecomm/shared-window` should not directly access the window object.
 */
export function getNavigator<T>(fn: (n: Navigator) => T): T {
  const navigator = O.fromNullable(window.navigator)
  // @ts-ignore -- there isn't a way to tell TS that T could be void in a way that doesn't break the implementation
  return pipe(navigator, O.fold(voidFn, fn))
}
