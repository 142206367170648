/**
 * Regex to match a variety of formats.
 *
 * Example matches:
 * - youtube.com/watch?v=test-id
 * - youtube.com/embed/watch?v=test-id
 * - youtu.be/test-id
 *
 * Excludes user, channel, and feed urls.
 */
const youtubeVideoRegex =
  /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?!.*channel|feed)(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/

/**
 * Converts any youtube url to the embed format for iframing.
 *
 * @param url string Link to Youtube video.
 * @param autoPlay boolean Optional. Adds an autoplay query string to embed link.
 * @returns Embedded format of Youtube link. If not a Youtube link, returns link as-is.
 */
export const getYoutubeEmbedUrl = (url: string, autoPlay?: boolean) => {
  const urlParams = autoPlay ? '?autoplay=1' : ''
  const id = getYoutubeVideoId(url)

  return id ? `https://www.youtube.com/embed/${id}${urlParams}` : url
}

/**
 * Extracts the ID from a variety of youtube link formats.
 *
 * @param url string Link to Youtube video.
 * @returns string | null The ID portion of the Youtube video link, otherwise null.
 */
const getYoutubeVideoId = (url: string) => {
  const match = url.match(youtubeVideoRegex)

  return match && match[1] ? match[1] : null
}

export const isYoutubeVideo = (url: string) => youtubeVideoRegex.test(url)
