import React from 'react'

type NavyCheckmarkProps = {
  readonly color?: string | null
}

function NavyCheckmark({ color }: NavyCheckmarkProps) {
  return (
    <svg
      data-component="CartCheckmarkIcon"
      height="14"
      style={{
        minHeight: '14px',
        minWidth: '16px'
      }}
      viewBox="0 0 16 14"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fillRule="nonzero"
          stroke={color || '#000'}
          strokeWidth="3"
          transform="translate(-856 -1130)"
        >
          <g transform="translate(857 869)">
            <g>
              <path
                d="M3.19108324 271.301313L10.3910832 271.301313 10.3910832 259.301313"
                transform="rotate(30 6.791 265.301)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default NavyCheckmark
