import { ApolloClient, NormalizedCacheObject } from '@apollo/client/index.js'
import { document, exists } from 'browser-monads-ts'

export const domId = 'apollo-state'
export const domAttribute = 'data-apollostate'

/**
 * Read a cache that has been written to the DOM during SSG/SSR.
 */
export const readCacheFromDom = (): NormalizedCacheObject =>
  exists(document)
    ? (() => {
        const t = `${document
          .getElementById(domId)
          ?.getAttribute(domAttribute)}`
        // it has to be parsed so apollo can read it correctly
        return t !== 'undefined' ? JSON.parse(t) : {}
      })()
    : {}

/**
 * Create a dom node that contains the cache for Apollo.
 */
export const createDomCache = (client: ApolloClient<NormalizedCacheObject>) => {
  const state = JSON.stringify(client.extract()).replace(/</g, '\\u003c')
  return <div data-apollostate={state} id={domId}></div>
}
