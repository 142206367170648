import { getLocale } from '@ecomm/utils'
import { EitherT, MaybeT, path } from '@simplisafe/ewok'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { brazeTrackCartEvent } from '../braze'
import { fbTrackAddProductsToCart } from '../facebook'
import { TrackingData } from '../types/tracking'
import {
  getCommerceDataFromPackageWithExtras,
  getCommerceDataFromProduct,
  getNameFromProductName,
  getPackagePrice
} from './../analytics'

export const useTrackAddToCart = () => {
  const { trackEvent } = useTracking<TrackingData>()

  const trackAddToCartEvent = useCallback(
    (
      productDetail: EitherT<Error, Product> | MaybeT<Product>,
      quantity: number,
      label?: string
    ) => {
      productDetail.forEach(productDetail => {
        const productName = path(['name', getLocale()], productDetail)

        const ecomm = getCommerceDataFromProduct(productDetail, 'add', quantity)

        trackEvent({
          ecommerce: ecomm,
          event: 'addToCart'
        })

        trackEvent({
          ecommerce: ecomm,
          event: 'eec.add',
          eventAction: 'addtocart',
          eventCategory: 'eec',
          eventLabel: label ? label : productName
        })

        brazeTrackCartEvent({
          eventType: 'add_to_cart',
          productTrackingData: ecomm
        })
        ecomm.add?.products && fbTrackAddProductsToCart(ecomm.add.products)
      })
    },
    [trackEvent]
  )

  /**
   * @deprecated this depends on the entire redux state and it needs to be rewritten
   * to not depend on state.
   *
   * Please use or create another way to do this.
   */
  const trackAddToCartPackageWithExtrasEvent = useCallback(
    (
      pkg: MaybeT<Package>,
      pkgProduct: MaybeT<Product>,
      extraLineItems: readonly MaybeT<
        Pick<Product, 'masterSku' | 'name' | 'price'>
      >[],
      isBms: boolean,
      selectState: ImmutableState,
      hasMonitoring: boolean,
      label?: string | null,
      packagePrice?: number | null,
      applyPackageBrandtoExtras = false
    ) => {
      pkg.forEach(_package => {
        pkgProduct.forEach(_packageProduct => {
          const adjustedPackagePrice =
            packagePrice || getPackagePrice(hasMonitoring)(_package)

          const ecomm = getCommerceDataFromPackageWithExtras(
            _package,
            _packageProduct,
            extraLineItems,
            isBms,
            'add',
            selectState,
            adjustedPackagePrice,
            applyPackageBrandtoExtras
          )

          trackEvent({
            ecommerce: ecomm,
            event: 'addToCart'
          })

          const productName = getNameFromProductName(getLocale())(
            path(['name'], _packageProduct)
          ).getOrElse('')

          trackEvent({
            ecommerce: ecomm,
            event: 'eec.add',
            eventAction: 'addtocart',
            eventCategory: 'eec',
            eventLabel: label || productName
          })

          brazeTrackCartEvent({
            eventType: 'add_to_cart',
            productTrackingData: ecomm
          })

          ecomm.add?.products &&
            fbTrackAddProductsToCart(ecomm.add.products, productName)
        })
      })
    },
    [trackEvent]
  )

  return {
    trackAddToCartEvent,
    trackAddToCartPackageWithExtrasEvent
  }
}
