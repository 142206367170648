//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import ProgressBar from '../ProgressBar'
import * as css from './LabeledProgressbar.module.scss'

export type LabeledProgressbarProps = {
  /** For automated testing. */
  readonly dataComponent?: string
  /** The percent of the progress bar to be filled in. */
  readonly percent: number
  /** The labels for the steps to be completed below the progress bar. */
  readonly stepList: readonly string[]
}

/** @deprecated Do not use ss-react-components*/
const LabeledProgressbar: FC<LabeledProgressbarProps> = ({
  dataComponent = LabeledProgressbar.name,
  percent,
  stepList
}: LabeledProgressbarProps) => {
  return (
    <div className={css.headerProgress} data-component={dataComponent}>
      <ProgressBar className={css.fill} percent={percent} />
      <div className={css.progressBarLabel}>
        <ul
          className={classNames({
            [css.steps]: stepList.length > 1,
            [css.stepCentered]: stepList.length === 1
          })}
        >
          {stepList.map((step: string) => {
            return (
              <li className={css.progressStep} key={step}>
                {step}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default LabeledProgressbar
