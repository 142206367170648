import { SmallBannerItem } from '@ecomm/ss-react-components'
import { DefaultBanner } from '@ecomm/ss-react-components'
import { path } from '@simplisafe/ewok'
import { isNotNil } from '@simplisafe/ewok'
import { safePath } from '@simplisafe/monda'
import { selectTopBannerVisible } from '@simplisafe/ss-ecomm-data/redux/select'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Maybe } from 'monet'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isEmpty from 'ramda/src/isEmpty'
import propOr from 'ramda/src/propOr'
import React from 'react'
import { useSelector } from 'react-redux'

import {
  ContentfulDefaultBanner,
  ContentfulIconWithText
} from '../../../graphql'
import ContentfulRichText from '../ContentfulRichText'

type DefaultBannerComponentProps = {
  readonly data: ContentfulDefaultBanner
}

type ContentfulIcon = ContentfulIconWithText | undefined

const renderDefaultBanner = (bannerDetails: readonly ContentfulIcon[]) => {
  return bannerDetails.map((bannerdata, i) => (
    <SmallBannerItem
      icon={renderImgIcon(bannerdata)}
      isDefaultBanner={true}
      key={`bannerId-${i}`}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      text={
        <ContentfulRichText
          raw={path(
            ['childContentfulIconWithTextTextRichTextNode', 'raw'],
            bannerdata
          )}
        />
      }
    />
  ))
}

const renderImgIcon = (bannerData: ContentfulIcon) => {
  const image = bannerData?.icon
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
  return (
    image && <GatsbyImage image={getImage(image)} style={{ width: '75px' }} />
  )
}

const DefaultBannerComponent = ({ data }: DefaultBannerComponentProps) => {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const bannerDetails = propOr<
    readonly ContentfulIconWithText[],
    readonly ContentfulIconWithText[]
  >([], 'sectionContent', data).filter(isNotNil)
  const desktopShowInHeader = safePath(['desktopShowInHeader'], data).orJust(
    false
  )
  const isPromoTopBanner = useSelector(selectTopBannerVisible)

  return (
    Maybe.fromNull(bannerDetails)
      // Filter out an empty array.
      .filter(bannerProp => !isEmpty(bannerProp))
      // If null or undefined, always return null.
      .cata(
        () => null,
        bannerProp => {
          return ifElse(
            equals(true),
            always(
              <DefaultBanner
                banner={renderDefaultBanner(bannerProp)}
                showVerticalBanner={true}
              />
            ),
            () => null
          )(!isPromoTopBanner && desktopShowInHeader)
        }
      )
  )
}

export const query = graphql`
  #graphql
  fragment defaultBannerFragment on ContentfulDefaultBanner {
    id
    desktopShowInHeader
    mobileCarouselDuration
    sectionContent {
      textAlignment
      textPosition
      title
      iconPosition
      icon {
        title
        description
        gatsbyImageData(layout: CONSTRAINED, width: 300, placeholder: BLURRED)
      }
      # childContentfulIconWithTextTextRichTextNode {
      #   raw
      # }
    }
  }
`

export default DefaultBannerComponent
