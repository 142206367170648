//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode, useState } from 'react'

import * as css from './Expander.module.scss'

export type ExpanderProps = {
  /** className for implementation of component */
  readonly className?: string
  /** id for implementation of component */
  readonly id?: string
  /** flag for default component state - false when not supplied */
  readonly isOpen?: boolean | false
  /** child component with conent which is watched for mosue hover events */
  readonly left: ReactNode
  /** child component with content displayed and hidden on mouse hover */
  readonly right: ReactNode
  /** Determines when component has hover capabilities */
  readonly hasHover?: boolean
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const Expander: FC<ExpanderProps> = ({
  className,
  id,
  isOpen,
  left,
  right,
  hasHover,
  dataComponent = Expander.name
}: ExpanderProps) => {
  const [isOpenState, setIsOpenState] = useState(isOpen)

  const classes = classNames(
    css.wrapper,
    { [css.openClass]: isOpenState },
    'neutralWhiteTextColor',
    className
  )

  return (
    <div className={classes} data-component={dataComponent} id={id}>
      {hasHover ? (
        <div className={classNames(css.imageWrapper)}>{left}</div>
      ) : (
        <div
          className={classNames(css.imageWrapper)}
          onMouseLeave={() => setIsOpenState(false)}
          onMouseOver={() => setIsOpenState(true)}
        >
          {left}
        </div>
      )}
      <div className={classNames(css.body)}>
        <div className={classNames(css.bodyInner)}>{right}</div>
      </div>
    </div>
  )
}

export default Expander
