import { contentfulImageSchema } from '@ecomm/contentful/components'
import { TypeOf, z } from '@simplisafe/ewok'

export const productImageCarouselSchema = z.object({
  product: z.object({
    imageCarouselCollection: z.object({
      items: z.array(contentfulImageSchema)
    })
  })
})

export type ProductImageCarouselSchema = TypeOf<
  typeof productImageCarouselSchema
>
