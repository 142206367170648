import { useGoogleAnalytics, usePollVid } from '@ecomm/tracking'
import classNames from 'classnames'
import React, { useRef } from 'react'
import * as pkg from 'react-lazy-load-image-component'

import { embedJebbit } from './jebbitOnLoadScript'
import { createGSBLaunchUrlParams } from './lib'
import { GuidedSystemBuilderSchema } from './schema'

const { LazyLoadComponent } = pkg

export const GuidedSystemBuilder = React.memo(function ({
  type,
  jebbitUrl
}: GuidedSystemBuilderSchema) {
  const containerRef = useRef<HTMLDivElement>(null)
  const vid = usePollVid()
  const [clientId, sessionId] = useGoogleAnalytics()

  const url =
    jebbitUrl ||
    'https://quiz.simplisafe.com/kaedkhe4?L=Full+Page&uid=&deferred=true'

  function renderGSB() {
    return (
      <div ref={containerRef}>
        <LazyLoadComponent>
          <iframe
            allowFullScreen
            className={classNames(
              'jebbit-iframe min-h-[610px] w-full lg:h-fit'
            )}
            frameBorder="0"
            onLoad={() => embedJebbit('.jebbit-iframe')}
            seamless={true}
            src={`${url}&${createGSBLaunchUrlParams(
              vid,
              clientId,
              sessionId,
              type
            )}`}
            title="Guided System Builder Quiz"
          />
        </LazyLoadComponent>
      </div>
    )
  }

  return type === 'embedded' ? (
    <div
      className={classNames(
        'col-span-12 overflow-hidden rounded-2xl bg-white px-4'
      )}
    >
      {renderGSB()}
    </div>
  ) : (
    renderGSB()
  )
})
