import { Options } from '@contentful/rich-text-react-renderer'
import { UnsafeArray } from '@ecomm/utils'
import { isNotNil } from '@simplisafe/ewok'
import {
  ContentfulRichTextGatsbyReference,
  renderRichText
} from 'gatsby-source-contentful/rich-text'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import React, { ReactNode } from 'react'

import {
  defaultRenderBlockNode,
  defaultRenderInlineNode,
  defaultRenderMark
} from './defaultRenderOptions'

function narrowArray<T>(t: ReadonlyArray<T> | UnsafeArray<T>): UnsafeArray<T> {
  return Array.of(...t)
}

// https://www.npmjs.com/package/@contentful/rich-text-react-renderer

export type Children = { readonly children: ReactNode }

/**
 * This list of options should be mapped out to include any possible components that can be embedded in RichText.
 */
const options = (
  onLinkClick?: (
    _e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    _url: string
  ) => void
): Options => ({
  renderMark: { ...defaultRenderMark },
  renderNode: {
    ...defaultRenderInlineNode(onLinkClick),
    ...defaultRenderBlockNode
  }
})

export type RichTextProps = {
  readonly onLinkClick?: (
    _e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    _url: string
  ) => void
  /** From Contentful */
  readonly raw?: string | null
  readonly references?:
    | ReadonlyArray<ContentfulRichTextGatsbyReference>
    | UnsafeArray<ContentfulRichTextGatsbyReference>
  readonly optionsCustom?: Options
}

/**
 * A component to render Contentful Rich Text with embedded
 * and inline entires.
 *
 * Does not do any styling or layout, it just converts the
 * source into the matching React components and HTML.
 *
 * To style text the component that contains the rich text should target the html primitives used,
 * such as `p { color: blue }`.
 */
export default function ContentfulRichText({
  onLinkClick,
  raw,
  references = [],
  optionsCustom
}: RichTextProps) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const customComponent = ifElse(
    equals(true),
    always(optionsCustom),
    always(options(onLinkClick))
  )(isNotNil(optionsCustom)) as Partial<Options>

  const mergedOptions = {
    renderMark: {
      ...options(onLinkClick).renderMark,
      ...customComponent.renderMark
    },
    renderNode: {
      ...options(onLinkClick).renderNode,
      ...customComponent.renderNode
    }
  }

  return (
    <>
      {raw
        ? renderRichText(
            {
              raw,
              references: narrowArray(references)
            },
            mergedOptions
          )
        : null}
    </>
  )
}
