import { ReactElement } from 'react'

type HeaderProps = {
  readonly children: ReactElement | readonly ReactElement[]
}

export function Header({ children }: HeaderProps) {
  return (
    <header className="bg-neutral-black h-auto w-full justify-center text-white md:pb-8 md:pr-8 md:pt-4 lg:px-16 lg:py-8">
      <div className="flex h-full flex-col items-center justify-between md:max-h-[77px] md:flex-row lg:py-2">
        {children}
      </div>
    </header>
  )
}
