import { type TypeOf, z } from '@simplisafe/ewok'

import { listItemSchema } from '../../components/ConfidenceBar/schema'

export const confidenceBarSchema = z.object({
  confidenceBar: z.object({
    content: z.object({
      listItems: z.array(listItemSchema)
    })
  })
})

export type ConfidenceBarType = TypeOf<typeof confidenceBarSchema>
