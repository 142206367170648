import type { Address, ShippingInfo } from '@commercetools/platform-sdk'
import { trackEditShippingButtonClick } from '@ecomm/cdp-tracking'
import { CheckMark } from '@ecomm/shared-icons'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export type ShippingCardProps = {
  readonly shippingAddress: O.Option<Address>
  readonly shippingInfo: O.Option<ShippingInfo>
}

export function ShippingCard({
  shippingAddress,
  shippingInfo
}: ShippingCardProps) {
  const getFormatattedShippingAddress = () => {
    return pipe(
      shippingAddress,
      O.map(address =>
        address.state
          ? `${address.streetName}, ${address.city}, ${address.state} ${address.postalCode}`
          : `${address.streetName}, ${address.city} ${address.postalCode}`
      ),
      O.toNullable
    )
  }

  const getFormattedShippingInfo = () => {
    return pipe(
      shippingInfo,
      O.map(info => info.shippingMethodName),
      O.toNullable
    )
  }

  return (
    <div data-testid="Shipping">
      <div className="mt-4 flex w-full justify-between text-lg">
        <span className="flex shrink-0 items-center whitespace-nowrap text-xl font-medium md:text-2xl">
          Shipping
        </span>

        <a
          className="text-complementary-blue-100 hover:text-black"
          href="/cart/checkout"
          onClick={trackEditShippingButtonClick}
        >
          Edit
        </a>
      </div>
      <div className="rounded-base bg-neutral-light-50 my-2.5 p-4 md:my-5">
        <span className="block pb-2 font-bold">
          {getFormattedShippingInfo()}
        </span>

        <div className="flex items-center">
          <div className="rotate-12 px-1 text-complementary-green-100">
            <CheckMark className="h-4 w-4" />
          </div>

          <span>{getFormatattedShippingAddress()}</span>
        </div>
      </div>
    </div>
  )
}
