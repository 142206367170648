import { getValueFromPartnerCookie, PartnerCookie } from '@ecomm/shared-cookies'
import { selectPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/select'
import { useSelector } from 'react-redux'

const useIsPartner = (): boolean => {
  const isPartnerPageRedux = useSelector(selectPartnerBanner).isSome()
  const partnerCookiesKeys: ReadonlyArray<keyof PartnerCookie> = [
    'partnerGroup',
    'partnerName',
    'partnerUrl'
  ]
  const hasPartnerCookie = partnerCookiesKeys
    .map(getValueFromPartnerCookie)
    .some(Boolean)

  return isPartnerPageRedux || hasPartnerCookie
}

export default useIsPartner
