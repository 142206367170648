import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { contentfulRichTextSchema } from '@ecomm/shared-components'
import { z } from '@simplisafe/ewok'

import { Locale } from '../../hooks/useLocalizedData'

export const scoutProductPageRiskFreeSchema = z.object({
  image: z.object({
    description: gatsbyImageSchema.shape.description,
    gatsbyImageData: gatsbyImageSchema.shape.gatsbyImageData
  }),
  headline: z.string(),
  body: contentfulRichTextSchema,
  buttonText: z.string(),
  buttonLink: z.string(),
  productSku: z.string(),
  className: z.string()
})

export const schema = {
  [Locale.EnGB]: scoutProductPageRiskFreeSchema,
  [Locale.EnUS]: scoutProductPageRiskFreeSchema
}
