//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode, useState } from 'react'

import { ChevronDown } from '../icons'
import * as css from './AccordionItem.module.scss'

export type AccordionItemProps = {
  readonly id: string
  /** Clickable title of the accordion */
  readonly title: string
  /** Content to display when the accordion is expanded */
  readonly children: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly theme?: 'border' | 'default'
}

/**
 * {@link https://www.w3.org/TR/wai-aria-practices/#accordion}
 * {@link https://www.w3.org/TR/wai-aria-practices/examples/accordion/accordion.html}
 */
/**
 * @deprecated React-components will be deprecated soon!. Do not use this component anymore.
 *
 * React components is gonna be deprecated and replaced by the new eis-simpliecom library!
 * You can create a new component in your new app or in shared/components if you consider that your component is reusable.
 * If you think your component is super reusable and generic, we could consider to include it in eis-simpliecom. Reach out platform team in that case!
 */
const AccordionItem: FC<AccordionItemProps> = ({
  children,
  id,
  title,
  dataComponent = AccordionItem.name,
  theme = 'default'
}: AccordionItemProps) => {
  const [isExpanded, setExpanded] = useState(false)
  const headingId = `AccordionItemHeading-${id}`
  const regionId = `AccordionItemRegion-${id}`

  return (
    <div className={css.accordionItem} data-component={dataComponent}>
      {/* heading tags implicitly set role and aria-level attributes */}
      <h4
        className={classNames(css.heading, {
          'border-neutral-light-100 rounded-base border border-solid bg-white px-4 py-2 md:px-9 md:py-5':
            theme === 'border'
        })}
      >
        <button
          aria-controls={regionId}
          aria-expanded={isExpanded}
          className={css.headingButton}
          id={headingId}
          onClick={() => setExpanded(!isExpanded)}
        >
          <span className={css.headingText}>{title}</span>
          <ChevronDown
            className={classNames(
              css.headingArrow,
              { [css['headingArrow--expanded']]: isExpanded },
              { [css['headingArrow--centerIcon']]: theme === 'border' }
            )}
          />
        </button>
      </h4>
      <div
        aria-hidden={true}
        className={classNames(css.contentDivider, {
          [css['contentDivider--expanded']]: isExpanded
        })}
      ></div>
      <div
        aria-labelledby={headingId}
        className={classNames(
          { hidden: !isExpanded },
          { [css.bottomTopPadding]: theme === 'border' }
        )}
        id={regionId}
        role="region"
      >
        {children}
      </div>
    </div>
  )
}

export default AccordionItem
