import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { getStateAbbreviation } from '@ecomm/checkout/utils'
import {
  UserAddressResponse,
  fetchUserShippingAddress
} from '@ecomm/data-simplisafe-api'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useEffect, useState } from 'react'

// empty email for now- will need to update when earlier ODMON login is added
const formatAddress = (
  address: UserAddressResponse
): O.Option<Partial<CheckoutFormValuesType>> =>
  pipe(
    O.fromNullable(address),
    O.map(address => ({
      additionalStreetInfo: address.street2 || '',
      city: address.city || '',
      email: '',
      firstName: address.firstName || '',
      lastName: address.lastName || '',
      phone: address.phone || '',
      postalCode: address.zip || '',
      state: getStateAbbreviation(address.state || ''),
      streetName: address.street1 || ''
    }))
  )

const useUserShippingAddress = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const [shippingAddress, setShippingAddress] =
    useState<O.Option<Partial<CheckoutFormValuesType>>>()

  useEffect(() => {
    fetch()
  }, [])

  const fetch = async () => {
    setLoading(true)
    pipe(
      await fetchUserShippingAddress()(),
      E.match(
        (res: Error) => {
          setError(res)
        },
        res => setShippingAddress(formatAddress(res[0]))
      )
    )
    setLoading(false)
  }

  return {
    shippingAddress,
    loading,
    error
  }
}

export { useUserShippingAddress }
