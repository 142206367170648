import { useOnClickOutside, useOnKeyUp } from '@ecomm/shared-hooks'
import { useEnv } from '@ecomm/utils'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { createMockImage } from '@ecomm/test-utils'

import { getCountryInformation } from './helpers'
import type { Countries } from '@ecomm/footer-data'

type Props = {
  readonly className?: string
}

//TODO: Move this data to a more appropiate place after releasing the new version of BMS
export const countries: Countries = [
  {
    linkIcon: createMockImage(
      '//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=5&h=5&q=50 5w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=10&h=10&q=50 10w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=20&h=20&q=50 20w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=30&h=30&q=50 30w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=40&h=40&q=50 40w,\n//images.ctfassets.net/br4ichkdqihc/7ura6edmMqhbAKdBa3yuMv/5d0ab7ef73e09461dc2caf5f5f562077/uk-flag.png?w=60&h=60&q=50 60w'
    ),
    linkSubtext: 'UK',
    linkText: 'United Kingdom',
    linkUrl: 'https://simplisafe.co.uk'
  },
  {
    linkIcon: createMockImage(
      '//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=5&h=5&q=50 5w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=10&h=10&q=50 10w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=20&h=20&q=50 20w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=30&h=30&q=50 30w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=40&h=40&q=50 40w,\n//images.ctfassets.net/br4ichkdqihc/8aTUF1NMqCWP2paOkKc7N/b11d8c93d31ccdf83243eb0f966197c3/Flag_-_USA.png?w=60&h=60&q=50 60w'
    ),
    linkSubtext: 'USA',
    linkText: 'United States',
    linkUrl: 'https://simplisafe.com'
  }
]

export function CountrySelector({ className = '' }: Props) {
  const { locale } = useEnv()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(() => setIsOpen(false), ref)
  useOnKeyUp('Escape', () => setIsOpen(false))

  const handleClick = () => {
    setIsOpen(prev => !prev)
  }

  const { a11yLabel, label, srcIcon, listItem } = getCountryInformation(
    countries,
    locale
  )

  return (
    <div className={className}>
      <div className="inline-block relative py-2.5" ref={ref}>
        <button
          className="flex items-center p-0 m-0 border-0 text-white bg-transparent cursor-pointer"
          onClick={handleClick}
        >
          {a11yLabel ? <span className="sr-only">{a11yLabel}</span> : null}
          {label ? (
            <div className="flex items-baseline gap-1.5">
              {srcIcon}
              <span className="text-base hover:underline">{label}</span>
            </div>
          ) : null}
          {!label && srcIcon}
        </button>
        <ul
          className={classNames(
            'bg-white shadow-[-2px_2px_6px_0_rgba(0,0,0,0.18)] -left-[70px] bottom-[calc(100%-20px)] list-none my-5 min-w-[175px] pl-0 absolute w-full z-10',
            "after:translate-y-1/2 after:content-[''] after:bottom-0 after:rotate-45 after:w-4 after:-z-[1] after:bg-white after:h-4 after:absolute",
            {
              'items-center flex flex-col justify-start': isOpen,
              hidden: !isOpen
            }
          )}
        >
          {listItem.map(item => {
            const srcIcon = item.srcIcon
            return (
              <li
                className={classNames('flex p-0 w-full text-neutral-black')}
                key={`${item.title}-${item.href}`}
              >
                <a
                  className="m-0 flex items-center py-2.5 px-4 no-underline whitespace-nowrap w-full hover:bg-neutral-light-50 text-neutral-black hover:text-primary-100"
                  href={item.href}
                >
                  {srcIcon} &nbsp; {item.title}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
