import { useTrackingAppCarousel } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useOnTabClick() {
  const trackAppCarousel = useTrackingAppCarousel()

  return useCallback(
    (tabName: string) => {
      trackAppCarousel(tabName.toLowerCase())
    },
    [trackAppCarousel]
  )
}
