enum CarouselActionType {
  Next = 'next',
  Previous = 'previous',
  SetAutoPlay = 'setAutoPlay',
  SetIdx = 'setIdx',
  ToggleAutoPlay = 'toggleAutoPlay',
  EnableAutoPlay = 'enableAutoPlay',
  DisableAutoPlay = 'disableAutoPlay',
  SetActiveSlide = 'setActiveSlide'
}

export type NoPayloadAction = {
  readonly type:
    | CarouselActionType.DisableAutoPlay
    | CarouselActionType.EnableAutoPlay
    | CarouselActionType.Next
    | CarouselActionType.Previous
    | CarouselActionType.ToggleAutoPlay
}
export type NumberPayloadAction = {
  readonly type: CarouselActionType.SetActiveSlide | CarouselActionType.SetIdx
  readonly payload: number
}
export type SetAutoPlayAction = {
  readonly type: CarouselActionType.SetAutoPlay
  readonly payload: boolean
}

export type CarouselAction =
  | NoPayloadAction
  | NumberPayloadAction
  | SetAutoPlayAction

export type CarouselState = {
  readonly currentIdx: number
  readonly enableAutoPlay: boolean
}

export type CarouselProps = {
  readonly children?: React.ReactNode
  readonly autoPlay?: boolean
  readonly autoPlaySpeed?: number
  readonly infinite?: boolean
  readonly dotControls?: boolean
  readonly arrowControls?: boolean
  readonly activeSlide?: number | null
}

export { CarouselActionType }
