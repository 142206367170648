import { City } from './cityStateTemplateHelpers'

const capitalizeStringAfterCharacter = (str: string, character: string) =>
  str
    .split(character)
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(character)

const formatAfbCities = (city: string) =>
  city.slice(-4) === ' Afb' ? city.replace(' Afb', ' AFB') : city
const formatStCities = (city: string) =>
  city.substring(0, 3) === 'St ' || city.includes(' St ')
    ? city.replace('St ', 'St. ')
    : city

const formatByTheSeaCities = (city: string) =>
  city.includes('-By-The-Sea') ? city.replace('-By-The-', '-by-the-') : city

export const convertPathToCityName = (name: string) => {
  const location = name.replaceAll('_', ' ')
  const capitalizedLocation = capitalizeStringAfterCharacter(
    capitalizeStringAfterCharacter(location, ' '),
    '-'
  )

  const formattedLocationName = formatAfbCities(
    formatStCities(capitalizedLocation)
  )
  return formatByTheSeaCities(formattedLocationName)
}

export const validateDynamicCityUrl = (
  cities: readonly City[],
  cityName: string
) => cities.some(city => city.name === cityName)

export const getDynamicCityPageMapCoordinates = (
  cities: readonly City[],
  dynamicCityName: string
) => {
  const mapCoordinates = cities.find(city => city.name === dynamicCityName)
  return { lat: mapCoordinates?.lat, lng: mapCoordinates?.lng }
}
