import {
  useHasPremiumMonitoringPlan,
  useHasProInstall
} from '@ecomm/cart-line-items'
import { useIsVariationOfExperiment } from '@ecomm/promotions-hooks'

export const useIsProInstallPromotionActive = () => {
  // Check if the user has Pro-Install in cart
  const hasProInstall = useHasProInstall()

  // Check if the user has premium monitoring in the cart
  const hasMonitoring = useHasPremiumMonitoringPlan()

  // Check if the user is in the experiment
  const isProInstallExperiment = useIsVariationOfExperiment(
    'all___us___promo___free_pro_install'
  )

  return hasProInstall && hasMonitoring && isProInstallExperiment
}
