/* eslint-disable react/function-component-definition */
import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { GuidedSystemBuilder } from '@ecomm/shared-components'
import { StickyPopoverLink } from '@ecomm/ss-react-components'
import {
  OptimizelyEvent,
  useOptimizelyTrackSiteEvents,
  useTrackGuidedSystemBuilderOpenEvent
} from '@ecomm/tracking'
import { brazeLogCustomEvent, TrackingData } from '@ecomm/tracking'
import { safePath } from '@simplisafe/monda'
import * as R from 'fp-ts/lib/Record'
import { graphql } from 'gatsby'
import React, { FC, useContext, useState } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulFloatingBar } from '../../../graphql'
import { getMappedComponent } from '../../componentMappings'
import { HidePopupWizard } from '../../contexts/hidePopupWizardContext'
import ContentfulVariationContainerComponent, {
  ContentfulVariationContainerComponentProps
} from '../ContentfulVariationContainerComponent'
import QuoteWizardWrapper, {
  QuoteWizardWrapperProps
} from '../QuoteWizardWrapper'

type FloatingQuizDialogueProps = {
  readonly data: Partial<ContentfulFloatingBar>
}

const FloatingQuizDialogue: FC<FloatingQuizDialogueProps> = ({
  data
}: FloatingQuizDialogueProps) => {
  const trackGSBStart = useTrackGuidedSystemBuilderOpenEvent(
    'floating',
    data.title
  )
  const [isJebbitVariation, setIsJebbitVariation] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const { hidePopupWizard: showStickyPopup } = useContext(HidePopupWizard)
  // @ts-expect-error TS(2322): Argument of type 'ContentfulFindYourPerfectSystem | ContentfulVariationContainer | { readonly __typename?: "%other" | undefined; }' is not assignable to parameter of type 'ContentfulComponent'. Type '{ readonly __typename?: "%other" | undefined; }' has no properties in common with type 'ContentfulComponent'.
  const Component =
    data.component &&
    getMappedComponent<
      ContentfulVariationContainerComponentProps | QuoteWizardWrapperProps
    >(data.component)
  const { Track, trackEvent } = useTracking({
    appSection: 'quoteWizard',
    wizardType: 'floating'
  })

  const handleLinkClick =
    (trackEvent: (_data: Partial<TrackingData>) => void) =>
    (optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void) => {
      optimizelyTrackSiteEvents({ eventKey: 'get_a_quote_click_fs' })
      isJebbitVariation && trackGSBStart()
      // TODO this event needs to be added to @ecomm/tracking
      // @ts-expect-error TS(2322) FIXME: Type '"quote-wizard-floating-start"' is not assign... Remove this comment to see the full error message
      !isJebbitVariation && trackEvent({ event: 'quote-wizard-floating-start' })
      !isJebbitVariation &&
        trackEvent({
          label: data.title,
          event: 'navigation-link',
          eventAction: 'click',
          eventCategory: 'navigation'
        })
      !isJebbitVariation &&
        trackNavigationClick({
          action: 'click',
          navElement: data.title || ''
        })
      brazeLogCustomEvent('qw_start')
    }

  const optimizelyTrackSiteEvents = useOptimizelyTrackSiteEvents()
  function renderComponent() {
    return Component
      ? safePath(['component', 'variations'], data).cata(
          () => (
            <Component
              columnType="none"
              // @ts-expect-error
              data={data.component}
              type="floating"
              // @ts-expect-error
            />
          ),
          _ => (
            <ContentfulVariationContainerComponent
              // @ts-expect-error
              data={data.component}
              renderVariant={variant => {
                // @ts-expect-error
                if (R.has('wizardType', variant)) {
                  return (
                    <QuoteWizardWrapper
                      columnType="none"
                      data={variant}
                      type="floating"
                    />
                  )
                } else {
                  setIsJebbitVariation(true)
                  return <GuidedSystemBuilder {...variant} />
                }
              }}
            ></ContentfulVariationContainerComponent>
          )
        )
      : null
  }

  return data.title && data.component && (isShow || showStickyPopup) ? (
    <Track>
      <StickyPopoverLink
        linkText={data.title}
        onLinkClick={() => {
          handleLinkClick(trackEvent)(optimizelyTrackSiteEvents)
          setIsShow(true)
        }}
      >
        {renderComponent()}
      </StickyPopoverLink>
    </Track>
  ) : null
}

export default FloatingQuizDialogue

export const fragment = graphql`
  #graphql
  fragment floatingQuizDialogue on ContentfulFloatingBar {
    title
    internal {
      type
    }
    component {
      ... on ContentfulFindYourPerfectSystem {
        ...quoteWizard
      }
      ... on ContentfulGuidedSystemBuilder {
        contentful_id
        type
        jebbitUrl
        internal {
          type
        }
      }
      ... on ContentfulVariationContainer {
        id
        internal {
          type
        }
        experimentId
        experimentKey
        experimentTitle
        meta {
          internal {
            content
          }
        }
        variations {
          ... on ContentfulGuidedSystemBuilder {
            contentful_id
            type
            jebbitUrl
            internal {
              type
            }
          }
        }
      }
    }
  }
`
