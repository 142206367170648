import { getVisitorId } from '@ecomm/shared-cookies'
import { useIntersection } from '@ecomm/shared-hooks'
import { getRudderstack } from '@ecomm/shared-window'
import {
  ExperienceConfiguration,
  Reference
} from '@ninetailed/experience.js-shared'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useExperience } from './useExperience'
import type {
  NinetailedImpactedEventName,
  NinetailedImpactedEventPayload
} from './useNinetailedImpactedEvent/types'

type Props<
  T extends Reference,
  U extends Reference,
  V extends NinetailedImpactedEventPayload
> = {
  name: NinetailedImpactedEventName
  event: V
  shouldReset: (prev: V, curr: V) => boolean
  baseline: T
  experiences: ExperienceConfiguration<U>[]
}

export function useNinetailedImpactedEvent<
  Baseline extends Reference,
  Variant extends Reference,
  Event extends NinetailedImpactedEventPayload
>({
  name,
  event,
  shouldReset,
  baseline,
  experiences
}: Props<Baseline, Variant, Event>) {
  const [hasFired, setHasFired] = useState(false)
  const [lastState, setLastState] = useState<Event | null>(null)

  const { audience, experience, loading, variant, variantIndex, status } =
    useExperience({ baseline, experiences })

  const intersectionRef = useRef(null)
  const intersection = useIntersection(intersectionRef, {})

  const variantName = variantIndex === 0 ? 'control' : `variant ${variantIndex}`

  const payload = useMemo(
    () => ({
      impacted_event: event,
      ninetailed_variant: variantName,
      ninetailed_audience: audience?.id,
      ninetailed_component: variant.id,
      ninetailed_experience: experience?.id,
      ninetailed_experience_name: experience?.name,
      vid: getVisitorId()
    }),
    [
      event,
      audience?.id,
      experience?.id,
      experience?.name,
      variant.id,
      variantName
    ]
  )

  useEffect(() => {
    const fn = () => {
      setLastState(event)
      setHasFired(false)
    }
    ;(lastState === null || shouldReset(lastState, event)) && fn()
  }, [event, lastState, shouldReset])

  useEffect(() => {
    const fn = () => {
      getRudderstack(r => r.track(name, payload))
      setHasFired(true)
    }

    // After the setTimeout elapses, check if the component is still in user's viewport
    const asyncCheck = () => intersection?.isIntersecting && fn()

    const id =
      !loading &&
      status === 'success' &&
      !hasFired &&
      intersection?.isIntersecting
        ? setTimeout(asyncCheck, 500)
        : null

    return () => (id ? clearTimeout(id) : void 0)
  }, [intersection, hasFired, payload, name, loading, status])

  return <div ref={intersectionRef} />
}

// {
//   "name": "nt_impacted_event",
//   "impacted_event": {{action - e.g. 'Question2Answered'}},
//   "ninetailed_variant": {{variant of experience user is in}},
//   "ninetailed_audience": {{audience user is in}},
//   "ninetailed_component": {{contentful entry ID of component shown}}
//   "ninetailed_experience": {{contentful entry ID of experience}}
//   "ninetailed_experience_name": {{user friendly title of experience}}
// }
