import { FREE_CABLES_NAME, SHIELD_SKU } from '@ecomm/data-constants'
import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'
import { useAtom } from 'jotai'
import React from 'react'

import { freeGiftItemAtom } from '../../../atoms/draftCart/freeGiftAtom'
import { itemQuantityAtom } from '../../../atoms/draftCart/itemQuantityAtom'
import { monitoringAtom } from '../../../atoms/draftCart/monitoringPlanAtom'
import { MonitoringPlan } from '../../DraftCart/types'
import DraftCartStaticItem from '../DraftCartStaticItem'
import type { KeyValueFragment, ProductFragment } from '../types'

type Props = {
  readonly baseProducts: readonly ProductFragment[]
  readonly otherItems: readonly KeyValueFragment[]
  readonly isAffirmExperience: boolean
}

function DraftCartStaticList({
  baseProducts,
  otherItems,
  isAffirmExperience = false
}: Props) {
  const isOdmonExperiment = useOdmonExperience().isVariant
  const [monitoring] = useAtom(monitoringAtom)
  const [freeGiftItems] = useAtom(freeGiftItemAtom)
  const [items] = useAtom(itemQuantityAtom)

  const olympusInCart = items
    .toArray()
    .find(([sku]) => sku === SHIELD_SKU && isOdmonExperiment)

  const giftItem =
    monitoring.type === MonitoringPlan.interactive ||
    monitoring.type === MonitoringPlan.odmonOvernight ||
    monitoring.type === MonitoringPlan.odmon247
      ? freeGiftItems.withMonitoring
      : freeGiftItems.withoutMonitoring

  const baseProductCount = baseProducts.reduce<Record<string, number>>(
    (counts, product) => ({
      ...counts,
      [product.sku]: counts[product.sku] || 1
    }),
    {}
  )

  return (
    <ul className="m-0 list-none p-0">
      {Object.entries(baseProductCount).map(([sku, quantity]) => (
        <DraftCartStaticItem
          forFree={false}
          isAffirmExperience={isAffirmExperience}
          key={sku}
          name={baseProducts.find(p => p.sku === sku)?.name}
          quantity={quantity}
          sku={sku}
        />
      ))}
      {giftItem
        ? giftItem.map(item => (
            <DraftCartStaticItem
              forFree={true}
              key={item.sku}
              name={item.title}
              quantity={item.quantity || 1}
              sku={item.sku || ''}
            />
          ))
        : null}
      {olympusInCart ? (
        <DraftCartStaticItem
          forFree={true}
          key={'freecables'}
          name={FREE_CABLES_NAME}
          quantity={olympusInCart[1]}
          sku=""
        />
      ) : null}
      {otherItems.map(({ key }) => (
        <DraftCartStaticItem
          forFree={true}
          key={key}
          name={key}
          quantity={0}
          sku={key}
        />
      ))}
    </ul>
  )
}

export default DraftCartStaticList
