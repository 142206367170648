//@ts-nocheck
import { safeHead, safePath } from '@simplisafe/monda'
import classNames from 'classnames'
import F from 'ramda/src/F'
import path from 'ramda/src/path'
import React, { FC, ReactNode, useMemo } from 'react'

import { ColumnProps } from '../Column'
import { BackgroundComponentType } from '../common'
import useMediaQuery from '../hooks/useMediaQuery'
import { SiteColor } from '../types/colors'
import { SiteGridGap } from '../types/grid-gap'
import { SitePadding } from '../types/padding'
import { SiteRounded } from '../types/rounded'
import * as css from './Row.module.scss'

type Child = {
  readonly props?: Partial<ColumnProps>
} | null

const firstRow = path(['props', 'firstRow'])

export const spans12onMobile = (col: Child) =>
  safePath(['props', 'spans'], col)
    .chain(safeHead)
    .map(val => val === 12)
    .cata(F, (i: boolean) => i)

export const columnShouldBeFirst = (col: Child) =>
  spans12onMobile(col) && firstRow(col)

export const moveFirstToTop = (children: Child | readonly Child[]) =>
  Array.isArray(children)
    ? children.reduce(
        (acc, item) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          columnShouldBeFirst(item)
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              [item, ...acc]
            : // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              [...acc, item],
        []
      )
    : children

export type HeightTypes = 'any' | 'full' | 'responsive' | 'standard'

export type RowProps = {
  /** Defaults to 'center' if undefined. Supports stretch and center. */
  readonly alignItems?: 'center' | 'stretch'
  /** A Function Component that accepts any props and will replace the row's div */
  readonly BackgroundComponent?: BackgroundComponentType
  /** The row's background color */
  readonly backgroundColor?: SiteColor
  /** Props that are passed to the BackgroundComponent */
  readonly backgroundComponentProps?: Record<string, unknown>
  /** The contents of the row */
  readonly children: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
  /** Whether to show divider lines between columns */
  readonly divider?: boolean
  /** If multiple rows, determines if the heights of the rows should be equal. Useful for making grid items equal in height. */
  readonly equalHeightRows?: boolean
  /** Grid gap between columns. Leave empty (undefined) for responsive defaults. Choose 'none' to remove the grid gap. */
  readonly gap?: SiteGridGap
  /** The height constraints of the row */
  readonly height?: HeightTypes
  /** Unique HTML id to pass to the parent div */
  readonly id?: string
  /** Side padding on the inside of the row */
  readonly sidePadding?: SitePadding
  /** Side padding on the inside of the row */
  readonly verticalPadding?: SitePadding
  /** Padding on the inside of the row */
  readonly padding?: SitePadding
  /** Border radius */
  readonly rounded?: SiteRounded
  /** Color of the text inside the row */
  readonly textColor?: SiteColor
  /** Text color will not be set */
  readonly inheritTextColor?: boolean
  /** Changes body copy/paragraph size to be either 'xs' (12px), 'sm' (14px), or 'md' (16/18px; the default setting). */
  readonly textSize?: 'md' | 'sm' | 'xs'
}

/** @deprecated Do not use ss-react-components*/
const Row: FC<RowProps> = ({
  alignItems,
  BackgroundComponent,
  backgroundColor = 'none',
  backgroundComponentProps,
  children,
  dataComponent = Row.name,
  divider,
  equalHeightRows,
  gap,
  height = 'any',
  id,
  inheritTextColor,
  padding,
  rounded = 'base',
  sidePadding,
  textColor = 'default',
  textSize,
  verticalPadding
}: RowProps) => {
  /**
   * This is for a11y.
   *
   * The .first class sets a column to be on top when the columns switch over to rows.
   * This produces the correct result, but changing the structure through css causes issues with screen readers.
   * The css class is enough to produce the expected results on first render (or gatsby build), and this will actually move the first row to the top.
   * This produces the correct markup, and since the item is already in the same spot through CSS this change doesn't cause any visible change.
   */

  const isTabletOrUp = useMediaQuery('TabletAndUp')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const nodes = useMemo(
    () =>
      isTabletOrUp === false
        ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
          moveFirstToTop(children as readonly Child[])
        : children,
    [children, isTabletOrUp]
  )

  // Check the first Column's spans to determine if content will be displayed in rows or columns. Used to display divider lines.
  // This approach assumes that all Columns will be oriented in the same direction.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const firstCol = Array.isArray(nodes) ? nodes[0] : nodes
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [mobileSpan, tabletSpan, desktopSpan] = safePath(
    ['props', 'spans'],
    firstCol
  ).getOrElse([])
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isFullRowMobile = mobileSpan && mobileSpan === 12
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isFullRowTablet = tabletSpan ? tabletSpan === 12 : isFullRowMobile
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const isFullRowDesktop = desktopSpan ? desktopSpan === 12 : isFullRowTablet

  const classes = classNames(
    css.row,
    {
      'rounded-base overflow-hidden': rounded === 'base',
      'rounded-none': rounded === 'none'
    },
    { [css.gapNone]: gap === 'none' },
    { [css.gapSmall]: gap === 'small' },
    { [css.gapMedium]: gap === 'medium' },
    { [css.gapLarge]: gap === 'large' },
    { [css.equalHeightRows]: equalHeightRows },
    {
      'p-4': padding === 'small',
      'px-4': sidePadding === 'small',
      'px-4 md:px-8': sidePadding === 'medium',
      'px-4 md:px-8 lg:px-16': sidePadding === 'large',
      'py-4': verticalPadding === 'small',
      'py-4 md:py-8': verticalPadding === 'medium',
      'py-4 md:py-8 lg:py-16': verticalPadding === 'large',
      'py-8 px-4 md:py-8': padding === 'medium',
      'py-8 px-4 md:py-8 lg:p-16': padding === 'large'
    },
    { [css.standardHeight]: height === 'standard' },
    { [css.fullHeight]: height === 'full' },
    { [css.responsiveHeight]: height === 'responsive' },
    { [css.alignItemsCenter]: alignItems === 'center' },
    { [css.alignItemsStretch]: alignItems === 'stretch' },
    { [css.divider]: divider },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    { [css.fullRowMobile]: divider && isFullRowMobile },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    { [css.fullRowTablet]: divider && isFullRowTablet },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    { [css.fullRowDesktop]: divider && isFullRowDesktop },
    { textSizeSM: textSize === 'sm' },
    { textSizeXS: textSize === 'xs' },
    `${backgroundColor}BackgroundColor`,
    { [`${textColor}TextColor`]: !inheritTextColor }
  )
  return BackgroundComponent ? (
    <BackgroundComponent {...backgroundComponentProps} className={classes}>
      {nodes}
    </BackgroundComponent>
  ) : (
    <div className={classes} data-component={dataComponent} id={id}>
      {nodes}
    </div>
  )
}

export default Row
