import { expertSectionSchema } from './schema'

const data = {
  image: {
    gatsbyImageData: {
      images: {
        sources: [
          {
            srcSet:
              'https://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png?w=464&h=443&q=50&fm=webp 464w,\nhttps://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png?w=928&h=887&q=50&fm=webp 928w,\nhttps://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png?w=1855&h=1773&q=50&fm=webp 1855w',
            sizes: '(min-width: 1855px) 1855px, 100vw',
            type: 'image/webp'
          }
        ],
        fallback: {
          src: 'https://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png?w=1855&h=1773&q=50&fm=png',
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png?w=464&h=443&q=50&fm=png 464w,\nhttps://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png?w=928&h=887&q=50&fm=png 928w,\nhttps://images.ctfassets.net/v6awd0kabi65/4l8DenaxSNBl1LH3mmDLMv/fe50f4e2ba6626d04ea685ca65477e45/230411_SimpliSafe_MonitoringCenter_CSA_077_FIN_1_3x.png?w=1855&h=1773&q=50&fm=png 1855w',
          sizes: '(min-width: 1855px) 1855px, 100vw'
        }
      },
      layout: 'constrained',
      width: 1855,
      height: 1772.9999999999998,
      placeholder: {
        fallback:
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAACE4AAAhOAFFljFgAAAAB3RJTUUH5wUKBDk4QUdzdgAAA8JJREFUGBklwU1TE2ccAPD//9lnd5MNIYZAgtCAihSDpTMOU0drL7148tRLr52e/AKdHnvxS/RL9FQvPThWq21VBOngGEYSgrwkIW+7yWaf3eflX9DfDx/89IPr2I5jO2dsh9s2tzjjZyzO7Y/4YbPT6fqrK1cyXhronDFGK8UBgDGG5wAQEAEREM4RGUB4/nL794ePYwUWx19+vp+/MAlEDAHAYjwecgsZQwSGiPAJwhkE9IPRemniwfd3PDU6Pmk9evovY8gYMmTnBrXXnDFABAQiAAIDQERwBrFW/1C5vACKvl0pc9C901MERGT4EevEHBDBkDEEAASAAAjnRBzH0Xjvff3hs82xEF8vXezsbB/Ua/gJY9bS4uzSpfnV2dpMoT+p61leM7CvrWmLZTo9/+baypQxMhgUPDdtW1uNpuU4X6xdhzMEXFieUXEv3ms3plDnImF/iGcMi69dkSnXGYxFTlJ9v2Vs8KdW94N38unLCNwwToDAWlu9OjNdUMnWRrV0b2lczppKrlvJNjwMQqskld7raZfEvm/e9sH3Iz8UE2lwU24YjvhJZyCknmQk9ZjzVBKPGWilyYDsj/2Nnc7c/Nqd1ZVmPqP+3pjI5qXOWm5+Yb6YxAnPzZc3m2GOLQfjkYiQSBKRMSiVCEZhqyeXK8XtKBV7qSSWQEbG0V/P37i2mZvNMx3pg9rhYduLRrbSpDUZA1KqWIhglByfBJ2R2OkVEyGTWAxHvSQOlZIvNqpKK1ZZXuYIg74/OzO93fZeNItbw6uvR6sNrPQGCXf5ve/uXr++qMc9hjwaB6SNxXgUJWSIz5eKhbTd7wzCofNklykVp1KDMBwnuqFx+tY3t0szuTs3+G+/vgiCFhjSIBExkRIA+GQm5bluVxk3kf5YbBy0LIsjY65jX/t8YfFS2RiqVavhcDToniAgERnQQEYb4jaDmze+vHtrXe/tdvvdiYyHqcyr3WaSyK3/3uXnyjfWb795vRH4La1VKmNU4pA0zAIg4KT1QbP3R/V9ebYoDo6PTvuelzo58sNEFKcWw4Rebb598vh5f3BMhrJ5UjoZnnqEgoi4CIeo5aSXqtYPmZW7sJCbduHHq4tXigWjDEyJOWiUPf8kWwA02fzI9UTQluu3p3eqNR70u1lUn3lgB+KoN9wdiZqbKk1m/9xt5y/kFrz2P88evTkKpJYI6Lo6k1NOOs7P4ebLuvXVtcuJGDMj0w7Ppa3ShANxlFysHPf6u7VGlMRzxdmWqHfaiVLazig7TSKSEwU6/RD/DxpEOUfFVxMuAAAAAElFTkSuQmCC'
      }
    }
  }
}

export const useExpertSectionData = () => {
  return expertSectionSchema.parse(data)
}
