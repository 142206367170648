//@ts-nocheck
import classNames from 'classnames'
import { useField } from 'formik'
import React, { FC, ReactNode } from 'react'

import * as css from './FormField.module.scss'

export type FormFieldProps = {
  readonly children?: ReactNode

  /** Field helper text */
  readonly helperText?: string
  readonly hideLabel?: boolean
  /** Field id */
  // TODO this should be made a required prop
  readonly id?: string
  /** Field label text */
  readonly label?: string
  /** Field name, note that this prop is being used to wire the field to an specific formik field */
  readonly name: string
  /** Sets position of the label to either be on top or the side of the component */
  readonly labelPosition?: 'side' | 'top'
  readonly opacityZero?: boolean
}

/** @deprecated Do not use ss-react-components*/
const FormField: FC<FormFieldProps> = ({
  children,
  helperText,
  hideLabel,
  id,
  label,
  name,
  labelPosition = 'side',
  opacityZero
}: FormFieldProps) => {
  const [, meta] = useField(name)

  return (
    <div
      className={classNames({
        [css.container]: labelPosition === 'side',
        [css.hideLabel]: hideLabel
      })}
    >
      <label
        className={classNames({
          [css.label]: labelPosition === 'side',
          block: labelPosition === 'top',
          'font-bold': labelPosition === 'top',
          'mb-1': labelPosition === 'top',
          'opacity-0': opacityZero,
          screenReaderText: hideLabel
        })}
        // TODO remove the `name` fallback once `id` is a required prop
        htmlFor={id || name}
      >
        {label}
      </label>
      <div className={css.input}>{children}</div>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        helperText ? <div className={css.helperText}>{helperText}</div> : null
      }
      {meta.error && meta.touched ? (
        <div className={css.errorMessage}>{meta.error}</div>
      ) : null}
    </div>
  )
}

export default FormField
