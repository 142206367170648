//@ts-nocheck
import classnames from 'classnames'
import defaultTo from 'ramda/src/defaultTo'
import React, { FC, MouseEventHandler } from 'react'

import * as css from './Icons.module.scss'
import { ReactComponent as arrowLeftSvg } from './svg/arrow-left.svg'
import { ReactComponent as arrowRightSvg } from './svg/arrow-right.svg'
import { ReactComponent as PhoneSvg } from './svg/atoms-icons-call.svg'
import { ReactComponent as HamburgerSvg } from './svg/atoms-icons-hamburger.svg'
import { ReactComponent as CancelSvg } from './svg/cancel.svg'
import { ReactComponent as CartSvg } from './svg/cart.svg'
import { ReactComponent as cautionSvg } from './svg/caution.svg'
import { ReactComponent as CheckMarkSvg } from './svg/checkmark.svg'
import { ReactComponent as CheckMarkBlueSvg } from './svg/checkmark-blue.svg'
import { ReactComponent as CheckMarkBlueCircleSvg } from './svg/checkmark-comparison-blue.svg'
import { ReactComponent as CheckMarkGrayCircleSvg } from './svg/checkmark-comparison-gray.svg'
import { ReactComponent as CheckMarkGreenCircleSvg } from './svg/checkmark-comparison-green.svg'
import { ReactComponent as ChevronDownSvg } from './svg/chevron-down.svg'
import { ReactComponent as ChevronDownSmSvg } from './svg/chevron-down-sm.svg'
import { ReactComponent as ChevronDownXsSvg } from './svg/chevron-down-xs.svg'
import { ReactComponent as ChevronUpXsSvg } from './svg/chevron-up-xs.svg'
import { ReactComponent as CloseSvg } from './svg/close.svg'
import { ReactComponent as CloseGraySvg } from './svg/close-gray.svg'
import { ReactComponent as CloseXSvg } from './svg/close-x.svg'
import { ReactComponent as CloseXGraySvg } from './svg/close-x-gray.svg'
import { ReactComponent as FacebookSvg } from './svg/facebook_logo.svg'
import { ReactComponent as InfoSvg } from './svg/info.svg'
import { ReactComponent as InfoGraySvg } from './svg/info-gray.svg'
import { ReactComponent as LinkedinSvg } from './svg/linkedin_logo.svg'
import { ReactComponent as LocationSvg } from './svg/location.svg'
import { ReactComponent as MediaPlaySvg } from './svg/media-play-small.svg'
import { ReactComponent as PinterestSvg } from './svg/pinterest_logo.svg'
import { ReactComponent as primaryAddSvg } from './svg/primary-add.svg'
import { ReactComponent as ChevronRightPrimarySvg } from './svg/primary-chevron-right.svg' // TODO can we reuse & recolor arrow-right?
import { ReactComponent as ProfileSvg } from './svg/profile.svg'
import { ReactComponent as SearchSvg } from './svg/search.svg'
import { ReactComponent as TwitterSvg } from './svg/twitter_logo.svg'
import { ReactComponent as ukSvg } from './svg/uk.svg'
import { ReactComponent as usSvg } from './svg/us.svg'
import { ReactComponent as ChevronRightWhiteSvg } from './svg/white-chevron-right.svg' // TODO can we reuse & recolor arrow-right?
import { ReactComponent as MediaPlayWhiteSvg } from './svg/white-media-play-small.svg'
import { ReactComponent as YoutubeSvg } from './svg/youtube_logo.svg'

export type IconProps = {
  /** alt text for accessibility */
  readonly titleA11y?: string
  readonly className?: string
  /** Attaches click handler and enables button mode */
  readonly onClick?: MouseEventHandler
  // TODO: implement icon button mode for SSButton so this prop can be deprecated
  /** Enable button mode when click handler is set on parent element */
  readonly forceButtonMode?: boolean
  /** Dynamic CSS class name to be assigned for social icons */
  readonly dynamicClassName?: string
  readonly dataComponent?: string
  // Technically, because this icon component is just an <svg>, it can accept basically any props in
  // SVGProps<SVGSVGElement>. However, that's a huge list of properties we'll likely never use and it seems confusing to
  // have all those props displayed as options in the IDE. If you want to pass a new SVG prop to the Icon component,
  // just add it in here & as long as it's a valid SVG property it should be passed down to the underlying <svg>.
}

const createIconComponent =
  (Svg: Svgr.ReactComponent): FC<IconProps> =>
  ({
    titleA11y,
    className,
    dynamicClassName,
    onClick,
    forceButtonMode,
    dataComponent = Svg.name,
    ...props
  }: IconProps) =>
    onClick || forceButtonMode ? (
      <button
        className={classnames(
          'text-inherit',
          css.button,
          className,
          css[defaultTo('')(dynamicClassName)]
        )}
        data-component={dataComponent}
        onClick={onClick}
      >
        <span className="screenReaderText">{titleA11y}</span>
        <Svg className={css.icon} title={titleA11y} {...props} />
      </button>
    ) : (
      <Svg
        aria-hidden={titleA11y ? undefined : true}
        data-component={dataComponent}
        role={titleA11y ? 'img' : undefined}
        {...props}
        aria-label={titleA11y}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        className={classnames(
          css.icon,
          className,
          css[defaultTo('')(dynamicClassName)]
        )}
        title={titleA11y}
      />
    )

export const Cancel = createIconComponent(CancelSvg)
export const Cart = createIconComponent(CartSvg)
export const ChevronDown = createIconComponent(ChevronDownSvg)
export const ChevronDownSm = createIconComponent(ChevronDownSmSvg)
export const ChevronDownXs = createIconComponent(ChevronDownXsSvg)
export const ChevronRightPrimary = createIconComponent(ChevronRightPrimarySvg)
export const ChevronRightWhite = createIconComponent(ChevronRightWhiteSvg)
export const ChevronUpXs = createIconComponent(ChevronUpXsSvg)
export const Close = createIconComponent(CloseSvg)
export const Info = createIconComponent(InfoSvg)
export const InfoGray = createIconComponent(InfoGraySvg)
export const Profile = createIconComponent(ProfileSvg)
export const CheckMark = createIconComponent(CheckMarkSvg)
export const CheckMarkBlue = createIconComponent(CheckMarkBlueSvg)
export const CheckMarkBlueCircle = createIconComponent(CheckMarkBlueCircleSvg)
export const CheckMarkGreenCircle = createIconComponent(CheckMarkGreenCircleSvg)
export const CheckMarkGrayCircle = createIconComponent(CheckMarkGrayCircleSvg)
export const uk = createIconComponent(ukSvg)
export const us = createIconComponent(usSvg)
export const Caution = createIconComponent(cautionSvg)
export const ArrowLeft = createIconComponent(arrowLeftSvg)
export const ArrowRight = createIconComponent(arrowRightSvg)
export const PrimaryAdd = createIconComponent(primaryAddSvg)
export const CloseGray = createIconComponent(CloseGraySvg)
export const Hamburger = createIconComponent(HamburgerSvg)
export const Phone = createIconComponent(PhoneSvg)
export const MediaPlay = createIconComponent(MediaPlaySvg)
export const MediaPlayWhite = createIconComponent(MediaPlayWhiteSvg)
export const CloseXGray = createIconComponent(CloseXGraySvg)
export const CloseX = createIconComponent(CloseXSvg)
export const Twitter = createIconComponent(TwitterSvg)
export const Pinterest = createIconComponent(PinterestSvg)
export const Facebook = createIconComponent(FacebookSvg)
export const Youtube = createIconComponent(YoutubeSvg)
export const Linkedin = createIconComponent(LinkedinSvg)
export const Location = createIconComponent(LocationSvg)
export const Search = createIconComponent(SearchSvg)

// we've gotta keep a new line at the end of this file for webpack bundling not to fail
