import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useMemo } from 'react'

import { useCookie } from './lib/useCookie'

/**
 * Returns a get and set function for the at-at token.
 */
export const useAtAtToken = (): readonly [
  O.Option<string>,
  (str: string) => void
] => {
  const dateOneYear = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  )

  const atatCookieOptions = {
    expires: dateOneYear.toUTCString(),
    path: '/',
    sameSite: 'strict',
    secure: process.env['NODE_ENV'] === 'production' // disable secure for local dev + unit tests
  }

  const [token, setToken] = useCookie('atat_v2', atatCookieOptions)

  return [token, setToken]
}

/**
 * Returns the visitor id from the `sub` field of the user's decoded AT-AT token.
 */
export const useVisitorId = (): O.Option<string> => {
  const [token] = useAtAtToken()

  const visitorIdOption = useMemo(() => {
    return pipe(
      token,
      O.map(token => jwtDecode<JwtPayload>(token)),
      O.chain(token => O.fromNullable(token.sub))
    )
  }, [O.toNullable(token)])

  return visitorIdOption
}
