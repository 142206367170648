import classNames from 'classnames'
import isNil from 'ramda/src/isNil'
import pathOr from 'ramda/src/pathOr'
import React, { FC, useCallback, useState } from 'react'

import { Heading } from '..'
import OptionItemBody from './OptionItemBody'
import OptionItemHeader from './OptionItemHeader'
import OptionItemWrapper from './OptionItemWrapper'
import { AdditionalOptionItemsProps, AdditionalOptionItemType } from './types'

/**
 * @deprecated React-components will be deprecated soon!. Do not use this component anymore.
 *
 * React components is gonna be deprecated and replaced by the new eis-simpliecom library!
 * You can create a new component in your new app or in shared/components if you consider that your component is reusable.
 * If you think your component is super reusable and generic, we could consider to include it in eis-simpliecom. Reach out platform team in that case!
 */
export const AdditionalOptionItems: FC<AdditionalOptionItemsProps> = ({
  priceOptionsTitle,
  additionalOptionItems = [],
  theme = 'tab',
  dataComponent = AdditionalOptionItems.name,
  onClick,
  priceOptionsTitleContent
}: AdditionalOptionItemsProps) => {
  const hasAdditionalOptionItems = additionalOptionItems.length > 0
  const hasContentProp =
    hasAdditionalOptionItems && !isNil(priceOptionsTitleContent)

  const [selectedOption, setSelectedOption] = useState(
    pathOr('', [0, 'skuId'], additionalOptionItems)
  )

  const handleClick = useCallback(
    (value: string, planSkuId?: string) => {
      setSelectedOption(value)
      onClick && onClick(value, planSkuId)
    },
    [onClick]
  )

  const renderContent = hasContentProp ? (
    <div className="prose prose-headings:text-heading-4-size prose-headings:!leading-h4-height prose-headings:mb-2 md:prose-md prose-headings:font-default prose-headings:font-normal neutralBlackTextColor">
      {priceOptionsTitleContent}
    </div>
  ) : (
    <Heading
      data-component={`${dataComponent}_title`}
      element="h2"
      headingAspect="h4"
      margin="extraSmall"
      useTailwind
    >
      {priceOptionsTitle}
    </Heading>
  )

  return hasAdditionalOptionItems ? (
    <>
      {renderContent}
      <div
        className={classNames({
          'md:mt-1 md:flex md:flex-wrap': theme === 'tab'
        })}
        data-component={`${dataComponent}_AdditionalOptionItem_container`}
      >
        {additionalOptionItems.map(
          (item: AdditionalOptionItemType, index: number) => {
            const onSelect = () =>
              handleClick && handleClick(item.skuId, item.planSkuId)
            const isSelected = item.skuId === selectedOption

            return (
              <OptionItemWrapper
                isSelected={isSelected}
                key={index}
                theme={theme}
              >
                <OptionItemHeader
                  groupName={priceOptionsTitle}
                  isSelected={isSelected}
                  item={item}
                  onChange={onSelect}
                  theme={theme}
                />
                <OptionItemBody
                  isSelected={isSelected}
                  item={item}
                  theme={theme}
                />
              </OptionItemWrapper>
            )
          }
        )}
      </div>
    </>
  ) : null
}

export default AdditionalOptionItems
