import { useMemo } from 'react'

export enum LayoutTypes {
  countdown = 'countdown',
  saleName = 'saleName',
  default = 'default'
}

export function useDesktopBannerLayout(
  hasSaleName: boolean,
  hasCountdown: boolean
): string {
  return useMemo(() => {
    if (hasCountdown) {
      return LayoutTypes.countdown
    } else if (hasSaleName) {
      return LayoutTypes.saleName
    } else {
      return LayoutTypes.default
    }
  }, [hasSaleName, hasCountdown])
}
