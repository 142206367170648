import { graphql, useStaticQuery } from 'gatsby'

import { productCardSchema } from '../components/ProductCard/schema'
import { parseObjectKey } from '@simplisafe/ewok'

const useOdmonCameraQuery = () => {
  const cameraQuery = useStaticQuery(graphql`
    #graphql
    query OdmonCameraQuery {
        odmonOlympusCamera: contentfulProduct(
            contentful_id: { eq: "1gfqsE26yZDiT7lwX1bjiO" }
        )  {
            ...productCard2
        }
    }
`)

  return parseObjectKey(cameraQuery, 'odmonOlympusCamera', productCardSchema)
}

export { useOdmonCameraQuery }
