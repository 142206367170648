import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { SimpliSafeLogo } from '@ecomm/shared-icons'
import { Header, HeaderNavigation } from '@ecomm/ss-react-components'
import { LabeledProgressbar } from '@ecomm/ss-react-components'
import { LabeledProgressbarProps } from '@ecomm/ss-react-components/LabeledProgressbar'
import { useLocation } from '@reach/router'
import { path } from '@simplisafe/ewok'
import { safeHead, safeProp } from '@simplisafe/monda'
import { Link } from 'gatsby'
import { Maybe } from 'monet'
import isNil from 'ramda/src/isNil'
import React, { useMemo } from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulHeaderWithProgressBar } from '../../../graphql'
import { logoLinkUrl as headerLogoLinkUrl } from '../Header/helpers'

type HeaderWithProgressBarProps = {
  readonly data: Partial<ContentfulHeaderWithProgressBar>
}

const progressByPath = {
  '^build-my-system$': 10,
  '^cart$': 10,
  '^cart/checkout$': 50,
  '^change-monitoring$': 53,
  '^choose-monitoring$': 53,
  '^choose-monitoring2$': 53,
  '^dynamic-package$': 10,
  '^home-security-system-': 10,
  '^payment-confirmation$': 100,
  '^payment-page$': 75,
  '^product/system/': 10
}

function HeaderProgressBarComponent({ data }: HeaderWithProgressBarProps) {
  const logoLinkUrl: string | undefined = path(
    ['simpliSafeLogo', 'linkUrl'],
    data
  )

  const steps: LabeledProgressbarProps['stepList'] = safeProp('steps', data)
    .getOrElse([])
    .filter((s): s is string => !!s)

  const pageLocation = useLocation()

  const getProgress = () => {
    const path = pageLocation.pathname
    const cleanPath = path?.replace(/^\/|\/$/g, '')

    return safeHead(
      Object.entries(progressByPath)
        .filter(([pathFragment, _percentage]) =>
          Maybe.fromNull(cleanPath.match(pathFragment)).isSome()
        )
        .map(([_pathFragment, percentage]) => percentage)
    ).getOrElse(0)
  }

  const progress = useMemo(getProgress, [pageLocation])

  const { trackEvent } = useTracking()

  return (
    <div data-component="HeaderProgressBar">
      <Header hasProgressBar>
        <HeaderNavigation>
          {!isNil(logoLinkUrl) ? (
            <Link
              onClick={() => {
                trackEvent({
                  label: 'logo',
                  event: 'navigation-link',
                  eventAction: 'click',
                  eventCategory: 'navigation'
                })
                trackNavigationClick({
                  action: 'click',
                  navElement: 'logo'
                })
              }}
              to={headerLogoLinkUrl(logoLinkUrl)}
            >
              <SimpliSafeLogo className="h-7 w-32 md:h-10 md:w-48" />
            </Link>
          ) : (
            <div className="flex w-full items-center justify-between"></div>
          )}
        </HeaderNavigation>
        <HeaderNavigation>
          <LabeledProgressbar percent={progress} stepList={steps} />
        </HeaderNavigation>
      </Header>
    </div>
  )
}

export default HeaderProgressBarComponent
