import { getLeadData } from '@ecomm/shared-cookies'
import { getBraze } from '@ecomm/shared-window'
import { isNotNil } from '@simplisafe/ewok'
import { localStorage } from '@simplisafe/ewok'
const { get } = localStorage

// Braze events need to fire up only if Braze EID is set in local storage or if Lead Data cookie is set
// If a lead data cookie is set, it will contain a Leads Email. It also gets set when handleBrazeTrackingEvent() is fired up
// If handleBrazeTrackingEvent() fired up, User was captured in Braze
export const shouldTrackUserEvent = (): boolean => {
  return getBraze(() => {
    const brazeSessionId = get('braze_eid') || null
    const leadData = getLeadData()
    return isNotNil(leadData) || isNotNil(brazeSessionId)
  })
}
