import { useMicroCopy } from '@ecomm/micro-copy'
import { useOptimizelyAffirmIconClick } from '@ecomm/optimizely-hooks'
import { InformationIcon } from '@ecomm/shared-icons'
import { getAffirm } from '@ecomm/shared-window'
import classNames from 'classnames'
import { useEffect } from 'react'

import { AffirmLogo } from './AffirmLogo'
import type { AffirmBannerFragment } from './schema'

export type UIThemeType = 'compact' | 'normal'

type AffirmBannerProps = {
  readonly data: AffirmBannerFragment
  readonly uiTheme?: UIThemeType
  readonly className?: string
}

export function AffirmBanner({
  data: { title },
  uiTheme = 'compact',
  className
}: AffirmBannerProps) {
  const microCopy = useMicroCopy()

  /*
   * since shop uses componentmappings from  legacy to render content based on reference,
   * we don't have clean a way passing the affirmclient as a prop to the component on that page.
   * This useEffect will fire one time when the component renders
   * This should provide more confidence affirm will be available so that
   * clicking the icon loads the modal. note the long term fix for this
   * is to get shop out of legacy so the component can be added directly
   *  to the template instead of being fetched by reference.
   */
  useEffect(() => {
    getAffirm(affirm => {
      affirm.ui.ready(() => {
        affirm.ui.refresh()
      })
    })
  }, [])

  const affirmIconClick = useOptimizelyAffirmIconClick()

  return (
    <div
      className={classNames(
        'bg-neutral-light-50 text-center leading-none md:mx-0',
        uiTheme === 'normal' &&
          'rounded-base mt-8 hidden p-4 md:p-8 lg:block lg:px-16',
        uiTheme === 'compact' &&
          'h-full max-h-[61px] min-h-[61px] rounded-xl px-2 py-3  md:max-h-[140px] md:min-h-[140px] md:rounded-2xl md:px-4 md:py-6 md:pb-1 md:pt-5 lg:min-h-[auto] lg:min-h-[auto] lg:px-8',
        className
      )}
      data-component="AffirmBanner"
      data-testid="AffirmBanner"
    >
      <div className="flex items-center justify-center">
        <h2
          className={classNames(
            'm-0  pr-2 font-medium',
            uiTheme === 'normal' && 'mt-2.5 text-4xl',
            uiTheme === 'compact' && 'text-base md:mt-3 md:text-2xl lg:mt-1'
          )}
        >
          {title}
        </h2>
        <div className="flex flex-row">
          <div
            className={classNames(
              'h-auto overflow-hidden',
              uiTheme === 'normal' && 'mt-0 w-[105px]',
              uiTheme === 'compact' &&
                '-mt-2.5 w-[60px] md:mt-0.5 md:w-[75px] lg:-mt-1.5'
            )}
          >
            <AffirmLogo />
          </div>
          <button
            aria-label="get more information"
            className="affirm-site-modal mb-1.5 cursor-pointer self-end border-none bg-transparent p-0 pl-2 text-inherit md:mb-1"
            data-page-type="banner"
            onClick={affirmIconClick}
          >
            <InformationIcon height="24" width="24" />
          </button>
        </div>
      </div>
      <p
        className={classNames(
          'm-0',
          uiTheme === 'normal' && 'mt-5 text-lg',
          uiTheme === 'compact' && 'mb-7 mt-2 hidden text-sm leading-5 md:block'
        )}
      >
        {microCopy['affirm-buy-now-description']}
      </p>
    </div>
  )
}
