import { QuizBanner } from '@ecomm/lander'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { quoteWizardEmbeddedSchema } from '../../schemas/quoteWizard'

export default function QuoteWizardEmbedded() {
  const data = quoteWizardEmbeddedSchema.parse(
    useStaticQuery(graphql`
      fragment MiscQuizBannerQWBaseFragment on ContentfulQuoteWizard {
        __typename
        id: contentful_id
        jebbitUrl
        type
        buttonText
      }

      fragment MiscQuizBannerQWExperienceFragment on ContentfulNinetailedExperience {
        id: contentful_id
        name: nt_name
        type: nt_type
        audience: nt_audience {
          id: contentful_id
          name: nt_name
        }
        config: nt_config {
          components {
            baseline {
              id
            }
            variants {
              id
              hidden
            }
          }
          traffic
          distribution
        }
        variants: nt_variants {
          ... on ContentfulQuoteWizard {
            ...MiscQuizBannerQWBaseFragment
          }
        }
      }
      #graphql
      query MiscQuoteWizardQuery {
        quoteWizard: contentfulQuoteWizard(
          contentful_id: { eq: "3GT1zG3PE5djEurbN7NQmm" }
        ) {
          ...MiscQuizBannerQWBaseFragment
          nt_experiences {
            ...MiscQuizBannerQWExperienceFragment
          }
        }
        quoteWizardAsset: contentfulAsset(
          contentful_id: { eq: "4mI9KaGvxh51fi1GYOSaVr" }
        ) {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
      }
    `)
  )

  return (
    <div data-component="QuoteWizardEmbedded">
      <QuizBanner
        image={data.quoteWizardAsset}
        quoteWizard={data.quoteWizard}
      />
    </div>
  )
}
