import { useLocation } from '@reach/router'

import { getLineItemsFromHash } from './utils'

/**
 * @description a hook that retuns an array of [SKU]: QTY based on cart package subItems and a URL query param
 *
 * @returns
 * customPackage: [sku: string]: number (QTY) - key: value pair for each subItem and its QTY
 * customPackageHash - string - the URL query param. ASCII string containing decoded data
 */
export function useCustomPackageQuery() {
  const { search } = useLocation()

  const customPackageHash =
    new URLSearchParams(search).get('customPackage') || ''
  const customPackage = getLineItemsFromHash(customPackageHash)

  return {
    customPackage,
    customPackageHash
  }
}
