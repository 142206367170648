import { contentfulImageSchema } from '@ecomm/contentful/components'
import { contentfulRichTextSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

const smallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: contentfulRichTextSchema,
  icon: contentfulImageSchema
})

const imageAndTextSchema = z.object({
  __typename: z.literal('ContentfulImageAndText'),
  text: contentfulRichTextSchema,
  image: contentfulImageSchema
})

const installationOptionCardSchema = z.object({
  content: z.union([smallTextSchema, imageAndTextSchema]).array()
})

export const installationOptionsSectionSchema = z.object({
  __typename: z.literal('ContentfulTwoColumnSection'),
  leftSide: installationOptionCardSchema,
  rightSide: installationOptionCardSchema
})

export type InstallationOptionsSectionSchema = TypeOf<
  typeof installationOptionsSectionSchema
>
export type InstallationOptionCardSchema = TypeOf<
  typeof installationOptionCardSchema
>
