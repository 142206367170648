import React, { FC, ReactNode } from 'react'

import useMediaQuery from '../hooks/useMediaQuery'

export type ResponsiveContainerProps = {
  readonly children: ReactNode
  /** Render on mobile */
  readonly mobile: boolean
  /** Render on Table */
  readonly tablet: boolean
  /** Render on Desktop */
  readonly desktop: boolean
}

/** @deprecated Do not use ss-react-components*/
const ResponsiveContainer: FC<ResponsiveContainerProps> = ({
  children,
  mobile,
  tablet,
  desktop
}: ResponsiveContainerProps) => {
  const isTabletUp = useMediaQuery('TabletAndUp')
  const isDesktopUp = useMediaQuery('DesktopAndUp')
  const isMobile = !isTabletUp && !isDesktopUp

  const shouldRender = () => {
    return (
      (mobile && isMobile) ||
      (tablet && isTabletUp && !isDesktopUp) ||
      (desktop && isDesktopUp)
    )
  }

  return (
    <div data-component="ResponsiveContainer">{shouldRender() && children}</div>
  )
}

export default ResponsiveContainer
