import { atom, useAtomValue } from 'jotai'

export const frameworkAtom = atom('gatsby')

/**
 * A hook to let you know if you are on Gatsby or Remix.
 * Useful for mapping to different components or functions
 * that are unique to each framework.
 */
export const useWhichFramework = () => {
  return useAtomValue(frameworkAtom)
}
