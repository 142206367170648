import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.WarningBlack,
    text: 'Silent or audible alarms'
  },
  {
    icon: SharedIcons.PeelAndStick,
    text: 'Peel-and-stick install'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.MedicalCross,
    text: 'Medical alerts'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 5 yrs'
  }
]

const description = {
  content: `It's a one-touch alarm - no need to remember a PIN. Keep one in the bedroom or other areas of the house where you may not have keypad access.
  \nWith a professional monitoring plan, the panic button will instantly trigger the alarm and alert the monitoring center. It's a great solution for elderly relatives or anyone who might need the quickest way to call for help.
  \nAn optional "silent panic" setting calls for help without alerting an intruder.
  `
}

const specs = {
  content: `1.5 x 2.25 x 0.62 inches
  \n1 CR-2032 Lithium 3V battery (required)
  \nOptional screw kit for wall mounting
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '2XhGwyX13JFrnBZNOGMMrR'
}

export const panicButtonData: ProductData = {
  productId: '2Ef8UENjDSBIC6gjJuupit',
  sensorName: 'Panic Button',
  features,
  description,
  specs,
  faqs
}
