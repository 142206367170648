import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import { FAQSection, toPromoBannerStyleValue } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { match } from 'ts-pattern'

import { BreadcrumbsWrapper } from '../../components/BreadcrumbsWrapper'
import AffirmReviewGrid from '../../components/Financing/AffirmReviewGrid'
import ConfidenceBanner from '../../components/Financing/ConfidenceBanner'
import FinancingBanner from '../../components/Financing/FinancingBanner'
import FinancingSmallText from '../../components/Financing/FinancingSmallText'
import PackageCardFinancingGrid from '../../components/Financing/PackageCardFinancingGrid'
import type { SeoNodeSchema } from '../../config/homeLandingResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { parseFinancingPage } from './schema'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function FinancingTemplate<
  T extends { readonly contentfulGenericLandingPage: U },
  U
>({ data, pageContext: { locale, seoDetails } }: Props<T>) {
  const { layout, breadcrumbTitle, slug } = parseFinancingPage(data)
  const {
    components,
    footer: { contentful_id: footerId }
  } = layout
  const { metaTitle, metaDescription, metaKeywords } = seoDetails
  const { isNofollow, isNoindex, canonicalLink } = seoDetails
  const headerData = useHeaderRedesignQuery()
  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle) || 'none'
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <div className="relative lg:z-20">
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={bannerStyle}
        />
      </div>
      <Header {...headerData} />
      <BreadcrumbsWrapper breadcrumbTitle={breadcrumbTitle} slug={slug} />
      <div className="prose md:prose-md lg:prose-lg flex flex-col max-w-8xl gap-6 md:gap-8 lg:gap-12 whitespace-pre-line mx-auto p-4 md:p-6 lg:p-8">
        {components.map(c =>
          match(c)
            .with({ __typename: 'ContentfulHeroBanner' }, d => (
              <FinancingBanner {...d} key={`component-${d.__typename}`} />
            ))
            .with({ __typename: 'ContentfulReviewSection' }, cr => (
              <AffirmReviewGrid {...cr} />
            ))
            .with({ __typename: 'ContentfulSmallText' }, st => (
              <FinancingSmallText {...st} />
            ))
            .with({ __typename: 'ContentfulColoredSection' }, cs => (
              <ConfidenceBanner {...cs} />
            ))
            .with({ __typename: 'ContentfulGrid' }, cg => (
              <PackageCardFinancingGrid {...cg} />
            ))
            .with({ __typename: 'ContentfulFaq' }, faq => (
              <>
                <h2 className="mb-0 text-center w-full">{faq.title}</h2>
                <FAQSection faq={faq} />
              </>
            ))
            .otherwise(() => null)
        )}
      </div>
      <Footer footerId={footerId} type="Full" />
    </TrackingProvider>
  )
}

export const query = graphql`
  query FinancingTemplate($id: String) {
    contentfulGenericLandingPage(id: { eq: $id }) {
      ...financingPageFragment
    }
  }
`
