import React, { FC, ReactNode } from 'react'

import { Column, Row } from '..'
import { RowProps } from '../Row'

export type BannerErrorProps = RowProps & {
  /** The contents of the error message. */
  readonly children: ReactNode
  /** The data-component attribute value. Defaults to component name. */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const BannerError: FC<BannerErrorProps> = ({
  backgroundColor,
  children,
  dataComponent = BannerError.name,
  height,
  padding = 'medium',
  textColor = 'neutralBlack'
}: BannerErrorProps) =>
  children ? (
    <div data-component={dataComponent}>
      <Row
        backgroundColor={backgroundColor}
        height={height}
        padding={padding}
        textColor={textColor}
      >
        <Column justifySelf="center">
          <div className={'alignCenter'}>{children}</div>
        </Column>
      </Row>
    </div>
  ) : null

export default BannerError
