import classNames from 'classnames'
import React, { FC } from 'react'

import { SiteColor } from '../types/colors'

export type LoadingSpinnerProps = {
  /** The className prop of the SVG element. */
  readonly className?: string
  /** The fill color of the loading spinner. Defaults to brandPrimary. */
  readonly fillColor?: SiteColor | 'currentColor'
  /** The data-component attribute value. Defaults to component name. */
  readonly dataComponent?: string
  /** The width and height of the loading spinner. Defaults to 48. */
  readonly size?: number
}

/** @deprecated Do not use ss-react-components*/
const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  className,
  fillColor = 'brandPrimary',
  dataComponent = LoadingSpinner.name,
  size = 48
}: LoadingSpinnerProps) => (
  <svg
    className={classNames('inline-block', `${fillColor}TextColor`, className)}
    data-component={dataComponent}
    height={size}
    version="1.1"
    viewBox="0 0 50 50"
    width={size}
    x="0px"
    y="0px"
  >
    <path
      className="fill-current"
      d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
    >
      <animateTransform
        attributeName="transform"
        attributeType="xml"
        dur="0.8s"
        from="0 25 25"
        repeatCount="indefinite"
        to="360 25 25"
        type="rotate"
      />
    </path>
  </svg>
)

export default LoadingSpinner
