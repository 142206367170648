import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click on continue shopping button in alarm-sensors page when the user wants to add a sensor
 */
export function trackAlarmContinueShopping() {
  getRudderstack(r => {
    r.track('alarm_continue_shopping')
  })
}
