import { trackScoutImagePagination } from '@ecomm/cdp-tracking'
import { useTrackingScoutImagePagination } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useTrackingPagerClick() {
  const handleTracking = useTrackingScoutImagePagination()

  return useCallback(
    (index: number) => {
      handleTracking(index)
      trackScoutImagePagination({ scout_image_position: index })
    },
    [handleTracking]
  )
}
