import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingFaqExpand() {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(
    (index: number) => {
      trackEvent({
        event: 'faq-expand',
        faqPosition: index
      })
    },
    [trackEvent]
  )
}
