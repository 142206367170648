import { useField } from 'formik'

export type FormCheckboxProps = {
  readonly label: string
  readonly name: string
  readonly className?: string
}

function FormCheckbox({
  label,
  name,
  className = 'font-medium text-lg'
}: FormCheckboxProps) {
  const [field] = useField({
    name,
    type: 'checkbox'
  })

  return (
    <div className="flex items-center">
      <input
        className="h-4 w-4"
        id={name}
        type="checkbox"
        {...field}
        checked={field.value}
      />
      <label className={`ml-1.5 ${className}`} htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

export { FormCheckbox }
