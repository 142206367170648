//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import CartUpdatedMessage from '../CartUpdatedMessage'
import QuantityChanger from '../QuantityChanger'
import * as css from './EditPackageSensorItem.module.scss'

export type EditPackageSensorItemProps = {
  readonly description: ReactNode
  /** Maximum allowable value for the quantity selector. Must be a positive integer. */
  readonly maxQuantity?: number
  /** Handler for clicking the quantity selector. Passes the selected quantity value as the first arg. */
  readonly onQuantityChange: (newQuantity: number) => void
  /** Product name, displayed as the card title */
  readonly title: string
  /** Pass default value for Quantity Changer */
  readonly defaultQuantityValue?: number
  /** Message to show when the quantity is changed to indicate that the cart has been updated */
  readonly cartUpdatedMessage?: string
}

type actionType = 'add' | 'remove'

/** @deprecated Do not use ss-react-components*/
const EditPackageSensorItem: FC<EditPackageSensorItemProps> = ({
  description,
  maxQuantity = 10,
  title,
  defaultQuantityValue = 0,
  onQuantityChange,
  cartUpdatedMessage
}: EditPackageSensorItemProps) => {
  const { Track, trackEvent } = useTracking()
  const [isCartUpdated, setCartUpdated] = useState(false)
  const handleQuantityChange = (
    newQuantity: number,
    actionType: actionType
  ) => {
    onQuantityChange(newQuantity)
    trackEvent({
      addRemove: actionType,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
      // @ts-expect-error
      event: 'addRemoveEvent',
      pageTitle: document.title,
      sensor: title
    })
    setCartUpdated(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => setCartUpdated(false), 5000)
    return () => clearTimeout(timer)
  }, [isCartUpdated])

  return (
    <div className={css.packageSensorItem}>
      <Track>
        <QuantityChanger
          defaultValue={defaultQuantityValue}
          max={maxQuantity}
          min={0}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
          // @ts-expect-error
          onChange={(newQuantity: number, actionType: actionType) =>
            handleQuantityChange(newQuantity, actionType)
          }
        />
      </Track>
      <div className={css.packageSensorContainer}>
        <h3>{title}</h3>
        <div className={css.packageSensorDesc}>{description}</div>
        {cartUpdatedMessage ? (
          <CartUpdatedMessage
            className={classNames('hideMobile', css.quantityUpdatedResponse)}
            isVisible={isCartUpdated}
            message={cartUpdatedMessage}
          />
        ) : null}
      </div>
    </div>
  )
}

export default EditPackageSensorItem
