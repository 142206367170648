import { exists, window } from 'browser-monads-ts'
import { useEffect } from 'react'

/**
 * It will call handler when event happens on window.
 * @param handler
 */
export function useCallOnEvent(handler: () => void, event: string) {
  useEffect(() => {
    // Recommended method for validating window prior to SSR per Gatsby documentation: https://www.gatsbyjs.com/docs/debugging-html-builds/
    const isBrowser = exists(window)
    isBrowser && window.addEventListener(event, handler)
    return () => {
      isBrowser && window.removeEventListener(event, handler)
    }
  }, [handler])
}
