import { logError } from '@ecomm/error-handling'
import { TrackMetricProps } from '@ecomm/tracking'
import { get as sessionStorageGet } from '@ecomm/utils'
import { LOCAL_STORAGE_CARTID } from '@simplisafe/ss-ecomm-data/cart/actions'
import { CreateOrderV1Response as CreateOrderResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import { get as localStorageGet } from 'local-storage'

export const getCookieDomain = () => {
  return process.env['LOCALE'] === 'en-GB'
    ? '.simplisafe.co.uk'
    : '.simplisafe.com'
}

export const getOrderId = (response: CreateOrderResponse) =>
  response['orderNumber']

export const forwardToPaymentErrorUrl = (
  error: Error,
  trackMetricEvent: TrackMetricProps
) => {
  trackMetricEvent('forwarded-to-payment-error', {
    paymentError: error.message
  })
  navigateLocal(
    `${window.location.origin}${
      window.location.pathname
    }?error=${encodeURIComponent(error.message)}`
  )
}

export const navigateLocal = (destinationUrl: string, delayMs = 0) => {
  delayMs
    ? setTimeout(() => {
        window.location.href = destinationUrl
      }, delayMs)
    : (window.location.href = destinationUrl)
}

export const logErrorWithOrderInfo = (e: Error) => {
  const orderId = sessionStorageGet('orderId')
  const cartId = localStorageGet<string>(LOCAL_STORAGE_CARTID)
  logError(e, {
    cartId,
    orderId
  })
}
