import { voidFn } from '@simplisafe/ewok'
import { window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { Trustpilot } from './trustPilot'

/**
 * Get TrustPilot and call a function using it.
 *
 * Handles checks for TrustPilot being undefined.
 */
export function getTrustPilot(fn: (t: Trustpilot) => void) {
  return pipe(O.fromNullable(window.Trustpilot), O.fold(voidFn, fn))
}
