import { Multilocation, Onelocation } from '@ecomm/shared-icons'
import classNames from 'classnames'

export default function BusinessSelector({
  selectedOption,
  onSelectOption
}: {
  readonly selectedOption: number
  readonly onSelectOption: (_: number) => void
}) {
  const buttonBaseClass =
    'min-h-[100px] flex flex-col items-center text-base transition-colors duration-200 ease-in-out py-2 h-24 basis-full md:basis-1/2 md:h-40 md:px-8 md:py-6 lg:px-10 lg:py-8 shadow-lg border-none cursor-pointer hover:bg-complementary-blue-100 hover:text-white rounded-base'

  return (
    <div className="grid w-full grid-cols-2 gap-4 md:flex-row md:gap-8 lg:gap-12">
      <div className="col-span-2 flex flex-col justify-center text-left md:col-span-1">
        <h2>Why SimpliSafe?</h2>
        <p>
          You’ll get industry-leading security that charges less than half of
          what most companies do. Plus, the freedom and control to monitor your
          business, right from your smartphone.
        </p>
      </div>
      <div className="bg-neutral-light-50 rounded-base col-span-2 p-6 md:col-span-1 md:p-8 lg:p-12">
        <h3 className="text-center">
          What kind of business are you protecting?
        </h3>
        <div className="flex w-full flex-col justify-center gap-4 md:flex-row">
          <button
            className={classNames(buttonBaseClass, {
              'bg-complementary-blue-100 text-white': selectedOption === 0,
              'bg-white': selectedOption !== 0
            })}
            onClick={() => onSelectOption(0)}
          >
            <Onelocation className="h-24 w-24" />
            Single Location
          </button>
          <button
            className={classNames(buttonBaseClass, 'group', {
              'bg-complementary-blue-100 text-white': selectedOption === 1,
              'bg-white': selectedOption !== 1
            })}
            onClick={() => onSelectOption(1)}
          >
            <Multilocation
              className={classNames(
                'group-hover:text-complementary-blue-100 h-24 w-24',
                {
                  'text-complementary-blue-100': selectedOption === 1
                }
              )}
            />
            Multi-Site Business
          </button>
        </div>
      </div>
    </div>
  )
}
