import { WarningIcon } from '../icons/WarningIcon'
import type { ReactNode } from 'react'

export function CartQuantityWarningMessage({
  message
}: { readonly message: ReactNode }) {
  return (
    <div className="mb-8 rounded-[10px] bg-[#F2F2F2] p-0 md:p-1">
      <div className="flex items-start justify-start gap-8 md:items-center">
        <div className="ml-10 mt-6 md:mt-0">
          <WarningIcon />
        </div>
        <p className="text=[#0F2648] mr-10 text-base my-0 py-0">{message}</p>
      </div>
    </div>
  )
}
