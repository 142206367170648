import classNames from 'classnames'
import { useField } from 'formik'
import React, { forwardRef, HTMLAttributes } from 'react'

export type FormTextInputProps = HTMLAttributes<HTMLInputElement> & {
  readonly fullWidth?: boolean
  readonly hasError?: boolean
  /** To ensure non-visual accessibility this value should match the `htmlFor` value of the field label */
  readonly id?: string
  /** Field name, note that this prop is being used to wire the field to an specific formik field */
  readonly name: string
  readonly onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  /** Placeholder text rendered when no option is selected */
  readonly placeholder?: string
  readonly type?: 'email' | 'text'
}

/** @deprecated Do not use ss-react-components*/
const FormTextInput = forwardRef<HTMLInputElement, FormTextInputProps>(
  (
    {
      fullWidth,
      hasError,
      id,
      name,
      onKeyDown,
      placeholder,
      type = 'text',
      ...rest
    }: FormTextInputProps,
    ref
  ) => {
    const [field, meta] = useField(name)

    return (
      <input
        className={classNames(
          'placeholder:text-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size rounded-base border-neutral-medium-100 h-14 border border-solid p-4',
          {
            'text-sale border-sale': (meta.touched && meta.error) || hasError,
            'w-full': fullWidth
          }
        )}
        id={id}
        type={type}
        {...field}
        name={name}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
        {...rest}
      />
    )
  }
)

export default FormTextInput
