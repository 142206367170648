import { locale as localeEnv, yodaApiUrl } from '@ecomm/data-env-variables'
import { safeFetchJson } from '@jvlk/fp-ts-fetch'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { FetchOptions, headersLens } from './fetch-options'

export function fetchYodaApi(options: FetchOptions) {
  return pipe(
    TE.Do,
    TE.bind('baseUrl', () => yodaApiUrl),
    TE.bind('locale', () => localeEnv),
    TE.chain(({ baseUrl, locale }) => {
      const { method, headers, endpoint, body } = pipe(
        options,
        headersLens.modify(t => ({
          ...t,
          // these headers are added to all requests
          'Accept-Language': locale,
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }))
      )
      return safeFetchJson(`${baseUrl}${endpoint}`, { method, headers, body })
    })
  )
}
