import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './monitoringPageData'
import { schema } from './schema'

export const useMonitoringPageAlarmMomentsData = () => {
  const monitoringPageAlarmMomentsData = useLocalizedData<typeof schema>(
    schema,
    data
  )

  return useMemo(
    () => monitoringPageAlarmMomentsData,
    [monitoringPageAlarmMomentsData]
  )
}
