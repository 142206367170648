import { gql } from '@apollo/client/index.js'

export const PARTNER_CAMPAIGN_QUERY = gql`
  query PartnerCampaignQuery($locale: String!, $preview: Boolean!) {
    partnerCampaignCollection(locale: $locale, preview: $preview) {
      items {
        campaignName
        customerGroup
        partnerName
        partnerUrl
        region
      }
    }
  }
`
