import React from 'react'

function Play() {
  return (
    <svg
      data-component="Play"
      fill="none"
      height="17"
      style={{ marginBottom: '-3px' }}
      viewBox="0 0 13 17"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1549 8.46558L0 16.3277V0.603516L12.1549 8.46558Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Play
