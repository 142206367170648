import {
  useCurrentPromoBannerData,
  useSitewidePromoOfferTitle
} from '@ecomm/promotions-hooks'

import { TierOne } from '../../../TierOne'
import { TierThree } from '../../../TierThree'
import { TierTwo } from '../../../TierTwo'

export function RightLayout() {
  const { disclaimerText, giftLineItemText, offerLabel, overrideBannerText } =
    useCurrentPromoBannerData()
  const offerTitle = useSitewidePromoOfferTitle()

  const shouldRenderTierTwo = !!giftLineItemText

  // Upperase offer like 'X OFF' when its the only TierOne text and not override text.
  const offerUppercased = !overrideBannerText
    ? offerTitle.toUpperCase()
    : offerTitle
  const offerWithLabel = offerLabel
    ? `${offerTitle} ${offerLabel}`
    : offerUppercased

  return (
    <div className="max-lg:text-neutral-black" data-component="RightLayout">
      <div className="text-neutral-black items-end md:flex">
        <TierOne>{!!offerLabel ? offerWithLabel : offerUppercased}</TierOne>
      </div>
      <div>
        {shouldRenderTierTwo ? <TierTwo>{giftLineItemText}</TierTwo> : null}
      </div>
      <div className="max-lg:mt-2">
        <TierThree>{disclaimerText}</TierThree>
      </div>
    </div>
  )
}
