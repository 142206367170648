import { ReactNode } from 'react'

import { getMappedComponent } from '../../utils/getMappedComponent'
import { ComponentTypes } from './schema'

export const mapPageComponents = (
  components: readonly ComponentTypes[]
): readonly ReactNode[] =>
  components.map(
    (componentData, index) =>
      <ReactNode>getMappedComponent(componentData, index)
  )
