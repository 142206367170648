import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingContinuePaymentClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (
      attribution: TrackingData['attribution'],
      checkboxLead: TrackingData['checkboxLead'],
      checkboxShipping: TrackingData['checkboxShipping'],
      shipping: TrackingData['shipping']
    ) => {
      trackEvent({
        event: 'continue-payment-click',
        attribution,
        checkboxLead,
        checkboxShipping,
        shipping
      })
    },
    [trackEvent]
  )
}
