// extracted by mini-css-extract-plugin
export var additionalInfo = "ItemDetail-module--additionalInfo--c2f23";
export var affirmPromoMessage = "ItemDetail-module--affirmPromoMessage--70eb4";
export var badgeDescription = "ItemDetail-module--badgeDescription--30f92";
export var button = "ItemDetail-module--button--535fc";
export var checkProSetup = "ItemDetail-module--checkProSetup--d9850";
export var h4 = "ItemDetail-module--h4--0d553";
export var icon = "ItemDetail-module--icon--53421";
export var image = "ItemDetail-module--image--8e49a";
export var interactiveMonitorPlan = "ItemDetail-module--interactiveMonitorPlan--813e6";
export var modal = "ItemDetail-module--modal--526e6";
export var outOfStockMessage = "ItemDetail-module--outOfStockMessage--2c41d";
export var paragraph = "ItemDetail-module--paragraph--6dbaf";
export var pdpAddToCard = "ItemDetail-module--pdpAddToCard--77e7f";
export var pdpCardPrice = "ItemDetail-module--pdpCardPrice--a413b";
export var pdpDescrptionSection = "ItemDetail-module--pdpDescrptionSection--c1efb";
export var pdpGuarante = "ItemDetail-module--pdpGuarante--d1130";
export var pdpGuaranteMobile = "ItemDetail-module--pdpGuaranteMobile--752e3";
export var pdpSensorMobile = "ItemDetail-module--pdpSensorMobile--4ef09";
export var showMobileOnly = "ItemDetail-module--showMobileOnly--74220";