import { logError } from '@ecomm/error-handling'

/**
 * Returns function that logs error message to console if immediate braze flush did not succeed
 * @param logMetadata data to include in log
 * @returns
 */
export function handleBrazeImmediateFlush(
  logMetadata: Record<string, string> & { readonly caller: string }
) {
  return (success: boolean) => {
    !success &&
      logError(new Error('Braze data flush was unsuccessful'), logMetadata)
  }
}
