import { BenefitsRichText } from '@ecomm/city-state-components'
import { GatsbyImage } from '@ecomm/shared-components'

import type { BenefitsSchema } from './schema'

export type BenefitsProps = BenefitsSchema & {
  readonly location: string
}

export function Benefits({ text, images, location }: BenefitsProps) {
  return (
    <div className="flex flex-col gap-6 lg:flex-row lg:gap-20">
      <BenefitsRichText location={location} text={text} />
      <div className="flex flex-col gap-4 md:flex-row lg:flex-col lg:gap-11">
        {images.nodes.map((image, i) => (
          <GatsbyImage
            className="mx-auto h-[228px] w-full rounded-2xl lg:h-[407px] "
            image={{
              gatsbyImageData: image.gatsbyImageData,
              title: ''
            }}
            key={i}
          />
        ))}
      </div>
    </div>
  )
}
