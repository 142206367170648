import { logError } from '@ecomm/error-handling'
import { ReactSDKClient } from '@optimizely/react-sdk'

import { OptimizelyExperimentData, UserAttributes } from '../types'
import { toUserAttributes } from './toUserAttributes'

export const activateExperiment = async (
  data: OptimizelyExperimentData,
  optimizely: ReactSDKClient
) => {
  const userId = data.userId
  const userAttributes: UserAttributes = toUserAttributes(data.userAttributes)
  const experimentId = data.experimentId ?? ''
  const variationId = data.variation ?? ''

  return optimizely
    .onReady()
    .then(() => {
      variationId !== '' &&
        optimizely.setForcedVariation(experimentId, userId, variationId)
      return optimizely.activate(experimentId, userId, userAttributes)
    })
    .catch(logError)
}
