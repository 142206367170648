function Spinner() {
  return (
    <svg
      className="text-primary-100 inline-block"
      data-component="Spinner"
      fill="none"
      height="48"
      id="Spinner"
      preserveAspectRatio="xMidYMid meet"
      version="1.1"
      viewBox="0 0 50 50"
      width="48"
      x="0px"
      y="0px"
    >
      <path
        className="fill-current"
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
      >
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          dur="0.8s"
          from="0 25 25"
          repeatCount="indefinite"
          to="360 25 25"
          type="rotate"
        ></animateTransform>
      </path>
    </svg>
  )
}

export default Spinner
