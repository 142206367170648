//@ts-nocheck
import React, { FC, ReactNode } from 'react'

import * as css from './HowItWorksDetailWrapper.module.scss'

export type HowItWorksDetailWrapperProps = {
  /** Any children to display inside the wrapper when open. */
  readonly children: ReactNode
  /** A11y text for the close button. Defaults to 'Close'. */
  readonly closeText?: string
  /** Determines if the wrapper is opened or closed. */
  readonly isOpen: boolean
  /** Available so components can set isOpen prop via the useState hook. */
  readonly onClose?: () => void
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const HowItWorksDetailWrapper: FC<HowItWorksDetailWrapperProps> = ({
  children,
  closeText = 'Close',
  isOpen,
  onClose,
  dataComponent = HowItWorksDetailWrapper.name
}: HowItWorksDetailWrapperProps) => {
  const handleCloseClick = () => {
    onClose && onClose()
  }

  return isOpen ? (
    <div className={css.wrapper} data-component={dataComponent}>
      <div className={css.content}>
        <button className={css.closeBtn} onClick={() => handleCloseClick()}>
          <span className={'screenReaderText'}>{closeText}</span>
        </button>
        {children}
      </div>
    </div>
  ) : null
}

export default HowItWorksDetailWrapper
