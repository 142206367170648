import React from 'react'

export function WarningIcon() {
  return (
    <svg
      fill="none"
      height="36"
      role="img"
      viewBox="0 0 33 36"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3.879 28.907h25.243L16.5 5.149 3.88 28.907zM31.815 30.6H1.185L16.5 1.773 31.815 30.6z"
        fill="#F79E00"
        fillRule="evenodd"
      ></path>
      <mask
        height="36"
        id="mask0_358_1400"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
        width="33"
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M0 36h33V0H0v36z"
          fill="#fff"
          fillRule="evenodd"
        ></path>
      </mask>
      <g
        clipRule="evenodd"
        fill="#F79E00"
        fillRule="evenodd"
        mask="url(#mask0_358_1400)"
      >
        <path d="M16.5 23.4c-.683 0-1.237.604-1.237 1.35 0 .746.554 1.35 1.237 1.35.684 0 1.238-.604 1.238-1.35 0-.746-.554-1.35-1.238-1.35zM15.675 21.6h1.65v-9h-1.65v9z"></path>
      </g>
    </svg>
  )
}
