import { trackCheckoutStarted } from '@ecomm/cdp-tracking'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { useCallback } from 'react'

import { createCheckoutStartedPayload } from './cartHelpers'

export type TrackCheckoutStartedProps = {
  readonly cart: ImmutableCart
  readonly originalEvent?: string
}

export function useTrackCheckoutStarted() {
  return useCallback(({ cart, originalEvent }: TrackCheckoutStartedProps) => {
    const payload = createCheckoutStartedPayload(cart, originalEvent)
    const timer = setTimeout(() => trackCheckoutStarted(payload), 3000)
    return () => clearTimeout(timer)
  }, [])
}
