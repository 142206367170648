import isValidLocale from './isValidLocale'
import { Locale } from './types'

/**
 * When possible you should get this from Redux with `selectLocale`.
 * This is mostly for build time use outside of the client or outside of
 * React components/hooks.
 */
const getLocale = (fallback?: Locale): Locale => {
  const envLocale = process.env['LOCALE']
  return isValidLocale(envLocale) ? envLocale : fallback || 'en-US'
}

export default getLocale
