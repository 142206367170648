import { useMicroCopy } from '@ecomm/micro-copy'

import { rawContentUK, rawContentUS } from './content'

export function useLocalizedLegalDisclaimerModalData(isUS: boolean): string {
  const microCopy = useMicroCopy()
  const modalContentUS = rawContentUS.replaceAll(
    'FAST_PROTECT_PRICE_US',
    microCopy['fast-protect-daily-price']
  )
  const modalContentUK = rawContentUK.replaceAll(
    'FAST_PROTECT_PRICE_UK',
    microCopy['fast-protect-daily-price']
  )

  return isUS ? modalContentUS : modalContentUK
}
