import { Locale } from '../../hooks/useLocalizedData'
import { createMockRichText, createMockRichTextWithItalics } from '../../utils'

export const data = {
  [Locale.EnGB]: {
    questions: [
      {
        longTitle: 'How is my privacy protected?',
        shortTitle: 'How is my privacy protected?',
        description: createMockRichText([
          'Your privacy is important. That’s why the SimpliSafe® Smart Alarm Wireless Indoor Camera has a privacy shutter that audibly clicks when opened or closed, and an amber LED light that indicates when it’s in use. Your feed is encrypted — monitoring agents can access it only during an alarm. Also, you can use the SimpliSafe® App to adjust when you want the shutter to be opened and during which alarm states.'
        ])
      },
      {
        longTitle: 'How is the built-in motion sensor unique?',
        shortTitle: 'How is the built-in motion sensor unique?',
        description: createMockRichText([
          'In addition to capturing video, the Smart Alarm Wireless Indoor Camera includes a built-in motion sensor that can trigger your alarm to sound. No other indoor security camera on the market today can do that. The camera has a wide viewing angle (125 diagonal°). The motion detector has a 90 degree horizontal field of view and can detect motion up to 30 feet in front across the camera’s field of view.'
        ])
      },
      {
        longTitle: 'Where should I place the camera?',
        shortTitle: 'Where should I place the camera?',
        description: createMockRichText([
          'We recommend installing the camera in a corner of your room facing the middle where you anticipate foot traffic. The camera can be placed about 6 to 7 feet off the ground and angled 20 degrees down.',
          'Have a pet larger than 50 lbs? We suggest placing your camera on a bookcase or shelf about 4 ft off the ground with no tilt to the camera.'
        ])
      },
      {
        longTitle: 'Can I install it myself or do I need to hire someone?',
        shortTitle: 'Can I install it myself or do I need to hire someone?',
        description: createMockRichText([
          'The camera is simple to install yourself. Be sure to fully charge the camera with the provided USB cable first. Use the SimpliSafe® App to add the camera to your account, then follow the instructions to finish setup and install in your desired location. Check the view and adjust the settings in the SimpliSafe® App.',
          'Professional installation is also available for those who prefer it.'
        ])
      },
      {
        longTitle: 'How long does the rechargeable battery last?',
        shortTitle: 'How long does the rechargeable battery last?',
        description: createMockRichText([
          'The built-in rechargeable battery is designed to last up to 3 months, depending on settings, installation location, and usage.',
          'It takes approximately 6 hours to fully charge the camera from a depleted state. Use the included USB cable and a USB power adapter (not included) to charge the camera. If you don’t already have one, SimpliSafe® offers a Power Adapter sold separately.'
        ])
      },
      {
        longTitle: 'What if I don’t want to deal with charging the battery?',
        shortTitle: 'What if I don’t want to deal with charging the battery?',
        description: createMockRichText([
          'We understand that you may prefer to always power your camera and although that might limit where you can place/install your camera -  you can use the included USB cable to plug into a power source that supplies at least 7.5W for constant power.'
        ])
      },
      {
        longTitle:
          'If I have multiple cameras, will they all turn on when one is triggered?',
        shortTitle:
          'If I have multiple cameras, will they all turn on when one is triggered?',
        description: createMockRichText([
          'Yes. If the alarm is triggered, all SimpliSafe® indoor and outdoor cameras will turn on and begin recording. Recordings are available with select monitoring plans.'
        ])
      },
      {
        longTitle: 'What is different about this versus this SimpliCam?',
        shortTitle: 'What is different about this versus this SimpliCam?',
        description: createMockRichTextWithItalics([
          'The Smart Alarm camera is our most advanced indoor security camera. It’s wireless, which offers next-level professional monitoring designed to stop crime in real time. The SimpliCam® Wired Indoor Camera is our entry-level, wired security camera. Both indoor cameras are designed to capture evidence that can lead to faster emergency response*. Customers have the choice of which camera is best for their security needs.',
          '*Vs. non-verified SimpliSafe® alarms. Requires a Professional Monitoring plan with video verification.'
        ])
      }
    ]
  },
  [Locale.EnUS]: {
    questions: [
      {
        longTitle: 'How is my privacy protected?',
        shortTitle: 'How is my privacy protected?',
        description: createMockRichText([
          'Your privacy is important. That’s why the SimpliSafe® Smart Alarm Wireless Indoor Camera has a privacy shutter that audibly clicks when opened or closed, and an amber LED light that indicates when it’s in use. Your feed is encrypted — monitoring agents can access it only during an alarm. Also, you can use the SimpliSafe® App to adjust when you want the shutter to be opened and during which alarm states.'
        ])
      },
      {
        longTitle:
          'Do I need to have a monitoring plan to use 24/7 live guard protection?',
        shortTitle:
          'Do I need to have a monitoring plan to use 24/7 live guard protection?',
        description: createMockRichText([
          'Yes, live guard protection functionality is only available to Fast Protect™ professional monitoring customers. Customers must have at least one Smart Alarm Indoor Camera connected to their system and opt in for live guard protection to function.'
        ])
      },
      {
        longTitle:
          'Can the Smart Alarm Wireless Indoor Camera stand alone, or does it only function in tandem with a SimpliSafe® system?',
        shortTitle:
          'Can the Smart Alarm Wireless Indoor Camera stand alone, or does it only function in tandem with a SimpliSafe® system?',
        description: createMockRichText([
          'The Indoor Camera is designed to work with our SimpliSafe® system and requires a Base Station and Keypad (sold separately) to function. SimpliSafe® offers unprecedented integration - the Indoor Camera can seamlessly be added to our latest SimpliSafe® system (Gen 3) within minutes.'
        ])
      },
      {
        longTitle: 'Do I need to pay for a monitoring subscription?',
        shortTitle: 'Do I need to pay for a monitoring subscription?',
        description: createMockRichText([
          'No. The Smart Alarm Wireless Indoor Camera is part of the SimpliSafe® system and will function without a monitoring plan. But the monitoring plans enable additional value-add features.'
        ])
      },
      {
        longTitle: 'How is the built-in motion sensor unique?',
        shortTitle: 'How is the built-in motion sensor unique?',
        description: createMockRichText([
          'In addition to capturing video, the Smart Alarm Wireless Indoor Camera includes a built-in motion sensor that can trigger your alarm to sound. No other indoor security camera on the market today can do that. The camera has a wide viewing angle (125 diagonal°). The motion detector has a 90 degree horizontal field of view and can detect motion up to 30 feet in front across the camera’s field of view.'
        ])
      },
      {
        longTitle: 'Where should I place the camera?',
        shortTitle: 'Where should I place the camera?',
        description: createMockRichText([
          'We recommend installing the camera in a corner of your room facing the middle where you anticipate foot traffic. The camera can be placed about 6 to 7 feet off the ground and angled 20 degrees down.',
          'Have a pet larger than 50 lbs? We suggest placing your camera on a bookcase or shelf about 4 ft off the ground with no tilt to the camera.'
        ])
      },
      {
        longTitle: 'Can I install it myself or do I need to hire someone?',
        shortTitle: 'Can I install it myself or do I need to hire someone?',
        description: createMockRichText([
          'The camera is simple to install yourself. Be sure to fully charge the camera with the provided USB cable first. Use the SimpliSafe® App to add the camera to your account, then follow the instructions to finish setup and install in your desired location. Check the view and adjust the settings in the SimpliSafe® App.',
          'Professional installation is also available for those who prefer it.'
        ])
      },
      {
        longTitle: 'How long does the rechargeable battery last?',
        shortTitle: 'How long does the rechargeable battery last?',
        description: createMockRichText([
          'The built-in rechargeable battery is designed to last up to 3 months, depending on settings, installation location, and usage.',
          'It takes approximately 6 hours to fully charge the camera from a depleted state. Use the included USB cable and a USB power adapter (not included) to charge the camera. If you don’t already have one, SimpliSafe® offers a Power Adapter sold separately.'
        ])
      },
      {
        longTitle: 'What if I don’t want to deal with charging the battery?',
        shortTitle: 'What if I don’t want to deal with charging the battery?',
        description: createMockRichText([
          'We understand that you may prefer to always power your camera and although that might limit where you can place/install your camera -  you can use the included USB cable to plug into a power source that supplies at least 7.5W for constant power. SimpliSafe® offers an accessory power adapter for those who may need one.'
        ])
      },
      {
        longTitle:
          'If I have multiple cameras, will they all turn on when one is triggered?',
        shortTitle:
          'If I have multiple cameras, will they all turn on when one is triggered?',
        description: createMockRichText([
          'Yes. If the alarm is triggered, all SimpliSafe® indoor and outdoor cameras will turn on and begin recording. Recordings are available with select monitoring plans.'
        ])
      },
      {
        longTitle: 'What is different about this versus this SimpliCam?',
        shortTitle: 'What is different about this versus this SimpliCam?',
        description: createMockRichTextWithItalics([
          'The Smart Alarm camera is our most advanced indoor security camera. It’s wireless and offers 24/7 live guard protection, which offers next-level professional monitoring designed to stop crime in real time. The SimpliCam® Wired Indoor Camera is our entry-level, wired security camera. Both indoor cameras are designed to capture evidence that can lead to faster emergency response*. Customers have the choice of which camera is best for their security needs.',
          '*Vs. non-verified SimpliSafe® alarms. Requires Fast Protect™ professional monitoring plan with video verification.'
        ])
      }
    ]
  }
}
