import { Link } from 'gatsby'

export type BestSystemsProps = {
  readonly title: string
  readonly description: string
  readonly buttons: readonly {
    readonly label: string
    readonly url: string
  }[]
}

export type BestSystemsCTFLProps = {
  readonly title: string
  readonly description: {
    readonly midAtlantic: string
    readonly newEngland: string
    readonly midWest: string
    readonly mountain: string
    readonly southEast: string
    readonly southwest: string
  }
  readonly buttons: readonly {
    readonly label: string
    readonly url: string
  }[]
}

export function BestSystems({ title, description, buttons }: BestSystemsProps) {
  return (
    <div className="md:text-center">
      <h2 className="font-arizona lg:leading-h1-height mb-4 text-[28px] leading-8 lg:text-[55px]">
        {title}
      </h2>
      <p className="mx-auto mb-5 text-base md:mb-7 lg:mb-12 lg:w-10/12 lg:px-10 lg:text-lg">
        {description}
      </p>
      <div className="flex flex-col justify-center gap-4 md:flex-row lg:gap-6">
        {buttons.map(({ label, url }, i) => (
          <Link
            className="border-complementary-blue-100 text-complementary-blue-100 hover:bg-complementary-blue-100 inline-block w-full rounded-lg border-2 border-solid px-4 py-3 text-center text-base font-medium no-underline duration-200 ease-in-out hover:text-white md:mb-0 md:w-[278px] lg:text-lg"
            key={i}
            to={url}
          >
            {label}
          </Link>
        ))}
      </div>
    </div>
  )
}
