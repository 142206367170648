import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import type { AppWidgetTab, ExpertsReviewsProps } from '@ecomm/scout'
import type { Locale } from '@ecomm/utils'

import type { LanderBelowTheFoldSchema } from '../components/BelowTheFold/schema'

const findByContentfulId = (
  contentfulId: string,
  images: LanderBelowTheFoldSchema['expertReviewsAssets']['nodes']
) => {
  /* istanbul ignore next */
  return images.find(element => element.contentful_id === contentfulId)
}

export const getContentfulImageId = (
  key: string,
  locale: Locale,
  isOdmonExperience: boolean
) => {
  const isUSData = locale === 'en-US'
  const imageData: Record<string, string> = {
    Cameras: isOdmonExperience
      ? '4WmsMErxVXBFHEjlFnRcD1'
      : 'ZYB8WxMtOe454lse5FG2D',
    Controls: isUSData ? '4Z794ao9hfY826K8Tt4FXz' : '6LT6dvk157aAfFdKyeG2Kf',
    Alerts: '50wlqz44dQwAzOoxzmPO90',
    Doorbell: isOdmonExperience
      ? '77eZKHHyg4KdFR2T2WhH90'
      : '38iheGuzSVg8kPQ1aokAnw',
    'us-news': '6uVhRdfLw8y6L3x78SiCuM',
    cnet: '6Fk167pQyK44F3g51qYJ5U',
    newsweek: '3DTUGIquyV64OFfWua1sqK',
    which: '7eH4KhA049wM5ZTOOmWyWd', // UK only
    t3: '5X5OsfxAW6Fc2Zj3HZDlHC', // UK only
    nhw: '2zLbCUSDMAqzV7LqBPgxzE', // UK only
    granddesigns: '3Yk2CSFz84KprPq2t0oyU8' // UK only
  }
  return imageData[key]
}

export const getExpertReviews = (
  images: LanderBelowTheFoldSchema['expertReviewsAssets']['nodes'],
  locale: Locale,
  reviews: ExpertsReviewsProps['reviews']
) => {
  const reviewsWithImages = reviews.map(review => {
    const imageCTFLId = getContentfulImageId(review.key, locale, false) || ''
    return {
      ...review,
      image: findByContentfulId(imageCTFLId, images)
    }
  })

  return reviewsWithImages.filter(review => !!review.image)
}

export const getAppWidgetData = (
  images: LanderBelowTheFoldSchema['expertReviewsAssets']['nodes'],
  locale: Locale,
  tabs: readonly AppWidgetTab[],
  isOdmonExperience = false
) =>
  tabs.map(tab => {
    const imageCTFLId =
      getContentfulImageId(tab.tab, locale, isOdmonExperience) || ''
    return {
      ...tab,
      image: findByContentfulId(imageCTFLId, images)
    }
  })

const appStoreLogo: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=46&h=14&q=50&fm=webp 46w,\nhttps://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=92&h=27&q=50&fm=webp 92w,\nhttps://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=184&h=54&q=50&fm=webp 184w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=184&h=54&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 184,
    height: 54
  },
  title: 'App Store Logo',
  description: 'App Store Logo'
}

const playStoreLogo: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=46&h=14&q=50&fm=webp 46w,\nhttps://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=92&h=28&q=50&fm=webp 92w,\nhttps://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=184&h=56&q=50&fm=webp 184w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=184&h=56&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 184,
    height: 56
  },
  title: 'Play Store Logo',
  description: 'Play Store Logo'
}

const simplisafeIcon: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=32&h=32&q=50&fm=webp 32w,\nhttps://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=63&h=64&q=50&fm=webp 63w,\nhttps://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=126&h=127&q=50&fm=webp 126w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=126&h=127&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 126,
    height: 127
  },
  description: 'SimpliSafe Icon',
  title: 'SimpliSafe Icon'
}

export const appRatingImages = { appStoreLogo, playStoreLogo, simplisafeIcon }
