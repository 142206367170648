import { useCurrentPromoBannerData } from '@ecomm/promotions-hooks'
import { ReactNode } from 'react'

import { SimpliSafeCSSProperties } from '../../types'

export type PromoStylerProps = {
  readonly children: ReactNode
}

export function PromoStyler({ children }: PromoStylerProps) {
  const {
    backgroundColor,
    primaryTextColor,
    secondaryTextColor,
    buttonTextColor,
    buttonBackgroundColor
  } = useCurrentPromoBannerData()

  const styles: SimpliSafeCSSProperties = {
    '--promo-banner-bg': backgroundColor,
    '--promo-flag-bg': backgroundColor,
    '--promo-flag-text': primaryTextColor,
    '--promo-button-bg': buttonBackgroundColor,
    '--promo-button-text-color': buttonTextColor,
    '--promo-primary-text': primaryTextColor,
    '--promo-secondary-text': secondaryTextColor,
    '--prose-links-color': primaryTextColor,
    color: primaryTextColor
  }

  return (
    <div data-component="PromoStyler" style={styles}>
      {children}
    </div>
  )
}
