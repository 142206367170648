import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

import { ContentfulRichText } from '../ContentfulRichText'
import { GatsbyImage } from '../GatsbyImage'
import Arrows from './Arrows'
import { FloorPlanCarouselFragment } from './schema'

type Props = FloorPlanCarouselFragment & {
  /** When set to true it will crop images to make them fit in the container. Otherwise it will resize them. */
  readonly cropImages?: boolean
}

export default function FloorPlanCarousel({
  items,
  title,
  description,
  cropImages = true
}: Props) {
  const [position, setPositionProduct] = useState(0)

  const maxItems: number = items.length - 1

  const next = useCallback(() => {
    const newPosition: number = maxItems < position + 1 ? 0 : position + 1
    setPositionProduct(newPosition)
  }, [setPositionProduct, position, maxItems])

  const previous = useCallback(() => {
    const newPosition: number = position - 1 < 0 ? maxItems : position - 1
    setPositionProduct(newPosition)
  }, [position, setPositionProduct, maxItems])

  useEffect(() => {
    const intervalId = setInterval(() => {
      next()
    }, 4000)

    return () => clearInterval(intervalId)
  }, [next, position])

  const currentProduct = items[position]
  const hasDescription = !!description

  return (
    <div
      className="bg-neutral-light-50 prose-h2:mb-6 flex flex-col justify-center gap-8 overflow-hidden rounded-[20px] p-6 md:gap-11 md:px-10 md:py-10 lg:pl-16 lg:pr-12"
      data-component="FloorPlanCarousel"
    >
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-0">
        <div
          className={classNames('flex flex-col gap-4', {
            'md:gap-6': hasDescription,
            'md:gap-10': !hasDescription
          })}
        >
          <h2 className="h2 my-0 text-[28px] lg:text-[55px]">{title}</h2>
          {description ? (
            <div className="prose-p:m-0">
              <ContentfulRichText raw={description.raw} />
            </div>
          ) : null}
          <div>
            <h3 className="mb-2 mt-0 lg:text-[18px]">{currentProduct.name}</h3>
            <div className="prose prose-p:mb-0 lg:prose-p:text-[18px] h-[75px]">
              <ContentfulRichText raw={currentProduct.description.raw} />
            </div>
          </div>
          <div className="hidden py-5 lg:flex" data-component="Arrows/Desktop">
            <Arrows
              current={position + 1}
              max={items.length}
              onNext={next}
              onPrevious={previous}
            />
          </div>
        </div>
        <div
          className={classNames(
            'relative mt-8 h-64 md:mt-0 md:h-80 md:pl-6 lg:h-[365px] lg:pl-12',
            { 'self-center': hasDescription },
            { 'flex items-center': !cropImages }
          )}
          data-component="ImageContainer"
        >
          {items.map((product, index) => (
            <div className="absolute overflow-hidden" key={product.sku}>
              <GatsbyImage
                className={classNames(
                  'rounded-base prose-img:rounded-base object-cover transition-opacity duration-500',
                  { 'opacity-0': position !== index },
                  { 'opacity-100': position === index },
                  !cropImages ? 'h-auto' : 'h-64 md:h-80 lg:h-[365px]'
                )}
                image={product.floorplanCarouselItem.image}
              />
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex justify-center lg:hidden"
        data-component="Arrows/Mobile"
      >
        <Arrows
          current={position + 1}
          max={items.length}
          onNext={next}
          onPrevious={previous}
        />
      </div>
    </div>
  )
}
