// extracted by mini-css-extract-plugin
export var activeAnchorNav = "SSButton-module--activeAnchorNav--e3c41";
export var anchorNav = "SSButton-module--anchorNav--fa084";
export var button = "SSButton-module--button--c8104";
export var customPlain = "SSButton-module--customPlain--43935";
export var disabled = "SSButton-module--disabled--c41e1";
export var hasHover = "SSButton-module--hasHover--3abb8";
export var link = "SSButton-module--link--5a5f6";
export var minWidthLarge = "SSButton-module--minWidthLarge--5ce1c";
export var minWidthMedium = "SSButton-module--minWidthMedium--ba7e4";
export var minWidthSmall = "SSButton-module--minWidthSmall--1d1d5";
export var primary = "SSButton-module--primary--f096c";
export var primaryHollow = "SSButton-module--primaryHollow--89821";
export var primaryOutline = "SSButton-module--primaryOutline--c0331";
export var primaryPlain = "SSButton-module--primaryPlain--92a89";
export var secondary = "SSButton-module--secondary--9c353";
export var secondaryOutline = "SSButton-module--secondaryOutline--dc77d";
export var selected = "SSButton-module--selected--dcf3c";
export var spinner = "SSButton-module--spinner--1d7cf";
export var spinnerActive = "SSButton-module--spinnerActive--15d75";
export var spinnerMobileAutoHeight = "SSButton-module--spinnerMobileAutoHeight--05055";
export var tertiary = "SSButton-module--tertiary--80e6c";
export var tertiaryOutline = "SSButton-module--tertiaryOutline--535da";