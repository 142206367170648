// extracted by mini-css-extract-plugin
export var column = "Column-module--column--a51ec";
export var desktop0 = "Column-module--desktop0--383d8";
export var desktop1 = "Column-module--desktop1--c7251";
export var desktop10 = "Column-module--desktop10--aec08";
export var desktop11 = "Column-module--desktop11--ec00c";
export var desktop12 = "Column-module--desktop12--881c7";
export var desktop2 = "Column-module--desktop2--4719f";
export var desktop3 = "Column-module--desktop3--ba5e9";
export var desktop4 = "Column-module--desktop4--4443a";
export var desktop5 = "Column-module--desktop5--3fcc5";
export var desktop6 = "Column-module--desktop6--c2c65";
export var desktop7 = "Column-module--desktop7--32722";
export var desktop8 = "Column-module--desktop8--62d3d";
export var desktop9 = "Column-module--desktop9--3c5bf";
export var equalHeightChildren = "Column-module--equalHeightChildren--1bfb8";
export var first = "Column-module--first--00852";
export var fixedHeight = "Column-module--fixedHeight--1b654";
export var mobile0 = "Column-module--mobile0--c20b9";
export var mobile1 = "Column-module--mobile1--76409";
export var mobile10 = "Column-module--mobile10--3c6fa";
export var mobile11 = "Column-module--mobile11--f07d8";
export var mobile12 = "Column-module--mobile12--02d14";
export var mobile2 = "Column-module--mobile2--2d2e9";
export var mobile3 = "Column-module--mobile3--c6ef4";
export var mobile4 = "Column-module--mobile4--4f12f";
export var mobile5 = "Column-module--mobile5--5caf7";
export var mobile6 = "Column-module--mobile6--16dc1";
export var mobile7 = "Column-module--mobile7--53233";
export var mobile8 = "Column-module--mobile8--65925";
export var mobile9 = "Column-module--mobile9--fb9dd";
export var tablet0 = "Column-module--tablet0--19dac";
export var tablet1 = "Column-module--tablet1--d1bb4";
export var tablet10 = "Column-module--tablet10--8f725";
export var tablet11 = "Column-module--tablet11--b7378";
export var tablet12 = "Column-module--tablet12--7ede3";
export var tablet2 = "Column-module--tablet2--2c593";
export var tablet3 = "Column-module--tablet3--e4b28";
export var tablet4 = "Column-module--tablet4--5e64a";
export var tablet5 = "Column-module--tablet5--92983";
export var tablet6 = "Column-module--tablet6--9ac8a";
export var tablet7 = "Column-module--tablet7--a7d7b";
export var tablet8 = "Column-module--tablet8--39236";
export var tablet9 = "Column-module--tablet9--22362";