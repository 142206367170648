//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactElement, useState } from 'react'

import HeaderContext from './context'
import * as css from './Header.module.scss'
import type { HeaderNavigationProps } from './HeaderNavigation'

type HeaderProps = {
  /** Content to display in the header.
   *
   * Limited to 3 children (left, center, and right).
   *
   * The only valid component is HeaderNavigation */

  readonly children:
    | ReactElement<HeaderNavigationProps>
    | readonly [ReactElement, ReactElement, ReactElement]
    | readonly [ReactElement, ReactElement]

  /** Determines the styling of the header when the header contains a progress bar. */
  readonly hasProgressBar?: boolean
}

/** @deprecated Do not use ss-react-components*/
const Header: FC<HeaderProps> = ({ children, hasProgressBar }: HeaderProps) => {
  const [activeDropdown, setActiveDropdown] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const classes = classNames(
    'text-white bg-neutral-black relative',
    css.container,
    { [css.progressBarHeader]: hasProgressBar },
    { [css.closedDrawer]: !isOpen },
    { [css.openDrawer]: isOpen }
  )

  return (
    <HeaderContext.Provider
      value={{
        activeDropdown,
        isOpen,
        setActiveDropdown,
        setIsOpen
      }}
    >
      <header className={classes}>
        <div
          className={classNames(
            'lg:max-w-8xl mx-auto flex h-full w-full items-center justify-between px-3 md:px-0 lg:px-8 lg:py-2',
            { 'items-center': hasProgressBar }
          )}
        >
          {children}
        </div>
      </header>
    </HeaderContext.Provider>
  )
}

export default Header
