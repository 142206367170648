import { useTrackingLiveGuardCta } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useOnLiveGuardCtaClick() {
  const trackLiveGuardCtaClick = useTrackingLiveGuardCta()

  return useCallback(() => {
    trackLiveGuardCtaClick()
  }, [trackLiveGuardCtaClick])
}
