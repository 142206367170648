import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy'
import { type TypeOf, z } from '@simplisafe/ewok'

export const privacyHeroBannerImageSchema = z.object({
  desktopImage: gatsbyImageSchema.optional(),
  tabletImage: gatsbyImageSchema.optional(),
  mobileImage: gatsbyImageSchema.optional(),
  copy: jsonSchema
})

export type PrivacyHeroBanner = TypeOf<typeof privacyHeroBannerImageSchema>
