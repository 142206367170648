import { AccessoriesModalSchema } from '../components/AccessoriesModal/schema'
import { ProductCardFragment } from '../components/ProductCard/schema'
import { SensorGroupFragment } from '../components/SensorGroup/schema'

type Args = {
  readonly sensorGroups: readonly SensorGroupFragment[]
}

export function promoteVariantSkuToGroup(group: ProductCardFragment) {
  return group.variant?.map(({ sku }) => ({
    ...group,
    sku
  }))
}

export const aggregateProducts = ({
  sensorGroups
}: Args): readonly ProductCardFragment[] => {
  const sensorGroupProducts: readonly ProductCardFragment[] =
    sensorGroups.flatMap(group => group.items)

  const colorVariantProducts: readonly ProductCardFragment[] =
    sensorGroupProducts
      .flatMap(group => promoteVariantSkuToGroup(group) ?? [])
      .filter(product => product !== null)

  const accessoriesModalProducts: readonly ProductCardFragment[] = sensorGroups
    .flatMap(group => group.items)
    .map(item => item.accessoriesModal)
    .filter((modal): modal is AccessoriesModalSchema => !!modal)
    .flatMap(modal => modal.products)

  return [
    ...sensorGroupProducts,
    ...accessoriesModalProducts,
    ...colorVariantProducts
  ].filter(
    (product, idx, arr) => arr.findIndex(p => p.sku === product.sku) === idx
  )
}
