import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

import { planFeatureSchema } from '../MonitoringPlanCard/schema'

export const monitoringPlanModalSchema = z.object({
  __typename: z.literal('ContentfulMonitoringPlan'),
  contentful_id: z.string(),
  sku: z.string(),
  name: z.string(),
  features: planFeatureSchema.array(),
  description: contentfulRichTextSchema, //text below the features
  shortDescription: contentfulRichTextSchema.nullish(),
  withAddToCartButton: z.boolean().optional(),
  cta: z.string().optional()
})

export type MonitoringPlanModalSchema = TypeOf<typeof monitoringPlanModalSchema>
