import { RefObject, useCallback } from 'react'

import useEventListener from '../useEventListener'

const useOnClickOutside = <
  T extends HTMLElement = HTMLDivElement,
  U extends MouseEvent | TouchEvent = MouseEvent
>(
  element: RefObject<T> | null,
  callback: (event: U) => void,
  event: 'click' | 'touchend' = 'click'
): void => {
  const handleClick = useCallback<EventListener>(
    (event: Event) => {
      const el = element ? element.current : null
      const target = <Node>event.target

      !(el && el.contains(target)) ? callback(<U>event) : null
    },
    [callback, element]
  )

  useEventListener(event, handleClick)
}

export default useOnClickOutside
