import { useContentfulProductImageCarousel } from '@ecomm/bms/hooks'
import { ImageGallery } from '@ecomm/shared-components'
import Skeleton from 'react-loading-skeleton'

export function SensorImageCarouselLoading() {
  return (
    <>
      <Skeleton className="rounded-lg mb-4 h-96 w-full" count={1} />
      <div className="flex gap-x-4 mb-4">
        <Skeleton className="rounded-lg h-14 w-14" count={1} />
        <Skeleton className="rounded-lg h-14 w-14" count={1} />
        <Skeleton className="rounded-lg h-14 w-14" count={1} />
        <Skeleton className="rounded-lg h-14 w-14" count={1} />
      </div>
    </>
  )
}

type SensorImageCarouselProps = {
  readonly productId: string
}

export function SensorImageCarousel({ productId }: SensorImageCarouselProps) {
  const images = useContentfulProductImageCarousel(productId)

  return images.length ? <ImageGallery isModal productImages={images} /> : null
}
