import classnames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  readonly message: ReactNode
  readonly hasSubItems?: boolean
}

export function CartOutOfStock({ message, hasSubItems = false }: Props) {
  return (
    <div
      className={classnames('mt-4 w-10/12 pb-6', {
        'pl-0.5': !hasSubItems,
        'pl-6': hasSubItems
      })}
      data-component="outOfStockMessage"
    >
      <p className="text-alert-100 m-0 pl-20">{message}</p>
    </div>
  )
}
