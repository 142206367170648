import {
  ContentfulRichText,
  contentfulRichTextSchema,
  GatsbyImage,
  GatsbyImageSchema,
  ImageWithArtDirection,
  ImageWithArtDirectionSchema,
  Modal
} from '@ecomm/shared-components'
import { TypeOf } from '@simplisafe/ewok'
import { useState } from 'react'

export type PartnerPageBrandBannerProps = {
  readonly body?: TypeOf<typeof contentfulRichTextSchema> | null
  readonly img?: ImageWithArtDirectionSchema | null
  readonly coBrandLogo?: GatsbyImageSchema | null
  readonly coBrandTitle?: string | null
  /** Position of Terms and Conditions link. */
  readonly termsAndConditionsPosition?:
    | 'Bottom'
    | 'Co-Brand Box'
    | 'Hero Image'
    | 'None'
  /** Text displayed on Terms and Conditions link and at top of modal. */
  readonly termsAndConditionsTitle?: string | null
  /** Content of Terms and Conditions modal.  */
  readonly termsAndConditions?: TypeOf<typeof contentfulRichTextSchema> | null
}

function PartnerPageBrandBanner({
  body,
  img,
  coBrandLogo,
  coBrandTitle,
  termsAndConditionsPosition = 'None',
  termsAndConditionsTitle,
  termsAndConditions
}: PartnerPageBrandBannerProps) {
  const [isTermsAndConditionsModalVisible, setTermsAndConditionsModalVisible] =
    useState(false)

  return (
    <>
      <div
        className="rounded-base bg-neutral-light-100 flex flex-col-reverse gap-4 overflow-hidden md:flex-row"
        data-component="PartnerPageBrandBanner"
      >
        <div className="my-auto p-4 md:w-1/2 md:p-8">
          <ContentfulRichText raw={body?.raw} />
        </div>
        <div className="relative md:w-1/2">
          {img ? (
            <ImageWithArtDirection
              className="rounded-base h-full min-h-[255px]"
              data={img}
            />
          ) : null}
          {coBrandLogo || coBrandTitle ? (
            <div className="absolute top-3 mt-8 flex flex-col items-center rounded-sm bg-white p-4 shadow-md md:p-8">
              {coBrandLogo ? (
                <div className="mb-2 w-[120px] md:w-40">
                  <GatsbyImage image={coBrandLogo} />
                </div>
              ) : null}
              <h2 className="m-0 text-2xl">{coBrandTitle}</h2>
              {termsAndConditionsPosition === 'Co-Brand Box' ? (
                <button
                  className="m-0 mt-2 cursor-pointer select-text border-0 bg-transparent p-0 text-xs  text-inherit underline"
                  onClick={() => setTermsAndConditionsModalVisible(true)}
                >
                  {termsAndConditionsTitle}
                </button>
              ) : null}
            </div>
          ) : null}
          {termsAndConditionsPosition === 'Hero Image' ? (
            <div className="absolute bottom-0 right-8 items-end bg-transparent pb-4">
              <button
                className="cursor-pointer select-text items-center border-0 bg-transparent p-0 text-white underline"
                onClick={() => setTermsAndConditionsModalVisible(true)}
              >
                {termsAndConditionsTitle}
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        isOpen={isTermsAndConditionsModalVisible}
        onRequestClose={() => setTermsAndConditionsModalVisible(false)}
      >
        <div className="mb-0 p-8 text-gray-400">
          <h2 className="my-4 text-lg font-medium">
            {termsAndConditionsTitle}
          </h2>
          <ContentfulRichText raw={termsAndConditions?.raw} />
        </div>
      </Modal>
    </>
  )
}

export default PartnerPageBrandBanner
