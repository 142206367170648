import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'

import { InstallationOptionCard } from './InstallationOptionCard'
import { InstallationOptionsSectionSchema } from './schema'

export function InstallationOptionsSection({
  leftSide,
  rightSide
}: InstallationOptionsSectionSchema) {
  const microcopy = useMicroCopy()

  return (
    <div className="flex flex-col">
      <h2 className="text-center">
        {microcopy['two-installation-options-title']}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 lg:gap-8">
        <InstallationOptionCard {...leftSide} />
        <InstallationOptionCard {...rightSide} />
      </div>
    </div>
  )
}
