export const mockVideoBanner = {
  badge: 'SIMPLISAFE® EXCLUSIVE',
  preTitle: 'SimpliSafe® ActiveGuard outdoor protection',
  title: 'Your first line of outdoor defense',
  text: 'Now AI-powered outdoor cameras and Simplisafe® security agents work together to help deter criminals in real time, keeping you safer than ever.',
  buttonText: 'See why our monitoring is different',
  redirectUrl: '/features-alarm-monitoring',
  video: {
    webmLink: 'https://assets.simplisafe.com/om/om_video.webm',
    mp4Link: 'https://assets.simplisafe.com/om/om_video.mp4',
    captionsSrc: 'https://assets.simplisafe.com/om/om_video_v2.vtt',
    posterImage: 'https://assets.simplisafe.com/om/om_poster.jpg'
  }
}
