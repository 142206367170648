import { gql } from '@apollo/client/index.js'
import { FOOTER_FRAGMENT } from '@ecomm/footer-data'

export const LEGAL_LAYOUT_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query LEGAL_LAYOUT_QUERY($locale: String!, $preview: Boolean, $id: String!) {
    layout(id: $id, preview: $preview, locale: $locale) {
      promoBannerStyle
      footer {
        ...footer
      }
    }
  }
`
