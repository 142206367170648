import { ContentfulRichText } from '@ecomm/shared-components'

import { ConfidenceBannerSchema } from './schema'

export default function ConfidenceBanner({
  content,
  disclaimer,
  headline
}: ConfidenceBannerSchema) {
  return (
    <div className="w-full bg-primary-100 flex text-center flex-col items-center gap-8 rounded-base px-6 py-8 md:px-8 md:py-10 lg:px-10 lg:py-12">
      <h2 className="mb-0">{headline}</h2>
      <ContentfulRichText {...disclaimer} />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {content.map((st, i) => (
          <div
            className="flex prose-h3:mb-2 text-center flex-col col-span-1 items-center gap-4 bg-white rounded-base py-6 md:py-10 px-4 md:px-20 lg:px-4"
            key={`confidence-banner-item-${i}`}
          >
            <ContentfulRichText {...st.text} />
          </div>
        ))}
      </div>
    </div>
  )
}
