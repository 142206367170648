import { getTrustPilot } from '@ecomm/shared-window'
import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'

type TrustPilotBannerProps = {
  readonly className?: string
}

export function TrustpilotUKTopBanner({
  className = ''
}: TrustPilotBannerProps) {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null)

  /** If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
   * If it's not, it means the script isn't loaded yet.
   * When it is, it will automatically load the TrustBox.
   */
  useEffect(() => {
    const current = ref.current
    current && getTrustPilot(t => t.loadFromElement(current, true))
  }, [])

  return (
    <>
      <Helmet>
        <script
          async
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          type="text/javascript"
        ></script>
      </Helmet>

      <div
        className={classNames('trustpilot-widget', className)}
        data-businessunit-id="5c35aeae94a7770001024810"
        data-component="TrustpilotUKTopBanner"
        data-locale="en-GB"
        data-style-height="28px"
        data-style-width="100%"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-testid="TrustpilotUKTopBanner"
        data-theme="light"
        ref={ref}
      >
        <a
          href="https://uk.trustpilot.com/review/simplisafe.co.uk"
          rel="noopener noreferrer"
          target="_blank"
        >
          Trustpilot
        </a>
      </div>
    </>
  )
}
