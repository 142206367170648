import { useMicroCopy } from '@ecomm/micro-copy'
import { useTracking } from 'react-tracking'

import { PromoBannerBaseTemplate } from '../../PromoBannerBaseTemplate'
import { PromoBannerPageType } from '../../types'
import { PromoButton } from '../PromoButton'
import { Header } from './Header'

export interface MobileBannerTopProps {
  readonly isFormSubmitted: boolean
  readonly onRedeemClick: () => void
  readonly type: PromoBannerPageType
}

export function MobileBannerTop({ onRedeemClick }: MobileBannerTopProps) {
  const microCopy = useMicroCopy()
  const { trackEvent } = useTracking()
  const onClick = () => {
    trackEvent({
      event: 'promo_detail',
      label: 'redeem_top_banner'
    })
    onRedeemClick()
  }

  return (
    <PromoBannerBaseTemplate dataComponent="MobileBannerTop" position="top">
      <div className="flex justify-between">
        <Header />
        <PromoButton onClick={onClick} text={microCopy['redeem']} />
      </div>
    </PromoBannerBaseTemplate>
  )
}
