import classNames from 'classnames'
import React from 'react'
import { useTracking } from 'react-tracking'

import { TrackingLinkFinancingSchema } from './schema'

const defaultClasses = 'text-inherit underline hover:no-underline'

export type Props = {
  readonly data: TrackingLinkFinancingSchema
}

export default function TrackingLinkFinancingComponent({ data }: Props) {
  const { linkText, url, customClassNames } = data

  const { trackEvent } = useTracking()
  const trackOnClick = () =>
    trackEvent({
      action: 'learn_more_affirm_banner_click',
      category: 'affirm_page',
      event: 'learn_more_affirm_banner_click',
      label: ''
    })

  const classes = classNames(defaultClasses, {
    [customClassNames]: !!customClassNames
  })

  return (
    <a className={classes} href={url ?? '#'} onClick={trackOnClick}>
      {linkText}
    </a>
  )
}
