import { getRudderstack } from '@ecomm/shared-window'

export type ScoutImagePaginationEvent = {
  readonly scout_image_position: number
}

/**
 * Track click image gallery pagination on product pages.
 */
export function trackScoutImagePagination(
  scout_image_position: ScoutImagePaginationEvent
) {
  getRudderstack(r => r.track('scout_image_pagination', scout_image_position))
}
