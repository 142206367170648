import { SelectChevron, WarningIcon } from '@ecomm/checkout-icons'
import classNames from 'classnames'
import { useField } from 'formik'

interface Props {
  readonly placeholder: string
  readonly disabled?: boolean
  readonly className?: string
  readonly name: string
  readonly options: readonly string[]
}

function FormSelect({
  placeholder,
  className = 'md:w-auto',
  disabled = false,
  options,
  name
}: Props) {
  const [field, meta] = useField(name)
  const hasError = meta.error && meta.touched

  return (
    <div className={className + ' relative'} data-component={`FormSelect`}>
      <select
        className={classNames(
          `rounded-base border-neutral-medium-100 text-body-size h-11 w-full appearance-none border border-solid p-3`,
          {
            'border-red-800 bg-red-50': hasError
          }
        )}
        disabled={disabled}
        id={name}
        {...field}
        placeholder={placeholder}
      >
        {placeholder !== '' && options?.length !== 1 && (
          <option className="text-neutral-dark-100" value={''}>
            {placeholder}
          </option>
        )}
        {options &&
          options.length > 0 &&
          options.map((item: string) => (
            <option key={`${item}`} value={item}>
              {item}
            </option>
          ))}
      </select>
      {!hasError && (
        <SelectChevron className="absolute right-4 top-1/2 -translate-y-1/2 transform" />
      )}
      {hasError && (
        <>
          <SelectChevron className="absolute right-10 top-1/2 -translate-y-1/2 transform" />
          <WarningIcon className="absolute right-4 top-1/2 -translate-y-1/2 transform" />
        </>
      )}
    </div>
  )
}

export { FormSelect }
