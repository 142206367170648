import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import React from 'react'

import { PackageProduct, PackageType } from '../Package/schema'
import { PersonalizePackageAddOnsButton } from './PersonalizePackageAddOnsButton'

export type Props = {
  readonly regularPrice: string
  readonly discountAmount: O.Option<string>
  readonly discountText: string
  readonly packageName: string
  readonly packageProducts: readonly PackageProduct[]
  readonly packageType: PackageType
  readonly sku: string
  readonly extrasPrice: string
  readonly discountedPrice: O.Option<string>
  readonly error: boolean
  readonly setError: React.Dispatch<React.SetStateAction<boolean>>
}

export function PersonalizePackageAddOns({
  discountedPrice,
  discountAmount,
  discountText,
  error,
  extrasPrice,
  packageName,
  packageProducts,
  packageType,
  regularPrice,
  setError,
  sku
}: Props) {
  return (
    <div
      className="flex flex-col items-end gap-2 py-4"
      data-component="PersonalizePackageAddOns"
    >
      <span>Package: {regularPrice}</span>
      {pipe(
        discountAmount,
        O.map(amount => (
          <span className="text-sale" key="discount-text">
            {discountText}: {amount}
          </span>
        )),
        O.toNullable
      )}
      {packageType !== 'Dynamic' && <span>Extras: {extrasPrice}</span>}
      {pipe(
        discountedPrice,
        O.map(price => <span key="system-total">System Total: {price}</span>),
        O.toNullable
      )}
      <PersonalizePackageAddOnsButton
        className="mt-4"
        error={error}
        packageName={packageName}
        packageProducts={packageProducts}
        packageType={packageType}
        setError={setError}
        sku={sku}
      />
    </div>
  )
}
