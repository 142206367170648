import { addAtomDebugLabel } from '@ecomm/utils'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'
import { match } from 'ts-pattern'

import { useCartValue } from '../atoms/useCart'

const cartTotalAmountAtom = atom(0)
addAtomDebugLabel(cartTotalAmountAtom, 'Cart - Total Amount')

/**
 * Returns the total amount of the cart as a number.
 *
 * Defaults to `0` if the cart is not initialized, empty, or has an error.
 */
export const useCartTotalAmount = () => {
  const cart = useCartValue()

  const [totalAmount, setTotalAmount] = useAtom(cartTotalAmountAtom)

  useEffect(() => {
    match(cart)
      .with({ _tag: 'not_initialized' }, _ => setTotalAmount(0))
      .with({ _tag: 'with_items' }, cart => setTotalAmount(cart.val.totalPrice))
      .with({ _tag: 'updating' }, cart => setTotalAmount(cart.val.totalPrice))
      .with({ _tag: 'error' }, _ => setTotalAmount(0))
      .with({ _tag: 'empty' }, _ => setTotalAmount(0))
      .with({ _tag: 'no_cart_id' }, _ => setTotalAmount(0))
      .exhaustive()
  }, [cart, setTotalAmount])

  return totalAmount
}
