import { z } from 'zod'

import { Locale } from '../../hooks/useLocalizedData'

export const baseSchema = z.object({
  title: z.string().optional(),
  questions: z
    .array(
      z.object({
        longTitle: z.string(),
        shortTitle: z.string(),
        description: z.string()
      })
    )
    .nonempty()
})

export const schema = {
  [Locale.EnGB]: baseSchema,
  [Locale.EnUS]: baseSchema
}
