// import { cookies } from '../cookies'
import { useCallback, useEffect } from 'react'
import { CookieChangeListener } from 'universal-cookie'

import { initCookies } from '../initCookies'

// value is string because cookies.onChangeListener doesn't auto-parse value like cookies.get does
// https://github.com/reactivestack/cookies/issues/302
export const useCookieChange = (
  cookieName: string,
  onChangeCookie: (value: string) => void
) => {
  const cookies = initCookies()

  const onChange = useCallback<CookieChangeListener>(
    ({ name, value }) => {
      name === cookieName && onChangeCookie(value)
    },
    [cookieName, onChangeCookie]
  )

  useEffect(() => {
    cookies && cookies.addChangeListener && cookies.addChangeListener(onChange)
    return () =>
      cookies &&
      cookies.removeChangeListener &&
      cookies.removeChangeListener(onChange)
  }, [onChange])

  return { onChange }
}
