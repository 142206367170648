import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.Wired,
    text: 'Wired'
  },
  {
    icon: SharedIcons.Siren,
    text: '95 dB siren'
  },
  {
    icon: SharedIcons.Chat,
    text: 'Voice prompts'
  },
  {
    icon: SharedIcons.BatteryRechargeable,
    text: 'Rechargeable battery'
  }
]

const description = {
  content: `Ready to protect, right out the box. Just plug in the Base Station and it will locate and control up to 100 sensors, making SimpliSafe the easiest security system to set-up for any size home.
  \nIt's also responsible for sounding your system's alarm and sending all your alarm signals to our 24/7 alarm monitoring center.
  \nNo landline is needed. With a built-in cellular and Wi-Fi connection, your home is protected even if your phone line is cut, or Wi-Fi is downed. A built-in backup battery lasts up to 24 hours during a power outage.`
}

const specs = {
  content: `4 x 8.25 x 4 inches
  \n4 NiMH rechargeable AA 1.2V batteries
  \nAC adaptor with 6-foot cord
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '4bAWvjxO6Pu1t8I3Ql4vYM'
}

export const baseStationData: ProductData = {
  productId: 'qvblWjcKJmDw5608vmE2w',
  sensorName: 'Base Station',
  features,
  description,
  specs,
  faqs
}
