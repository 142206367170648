import { customErrorMap, defaultErrorMap, z } from '@simplisafe/ewok'

const idAsString = z.string({ errorMap: defaultErrorMap })
const idAsObject = z.object({ id: z.string({ errorMap: defaultErrorMap }) })
// The errorMap setup here makes sure we only log an error if the value doesn't match
// either of the items in the union. Without this, an error would be logged if the value
// doesn't match the first item in the union.
// This union is required to be compatible with new apollo response.
const idSchema = z.union([idAsString, idAsObject], { errorMap: customErrorMap })

const variantSchema = z.object({
  id: z.string(),
  hidden: z.boolean()
})

const componentSchema = z.object({
  baseline: z.object({
    id: z.string(),
    title: z.string().nullish() // absent in new apollo response
  }),
  variants: z.array(variantSchema)
})

const audienceSchema = z.object({
  id: idSchema,
  name: z.string()
})

/**
 * Note: When using this schema you must extend it with a `variants` field
 * that includes the an array of the schema you're trying to A/B test.
 *
 * @example
 * const fooSchema = experimentTemplateSchema.extend({
 *   variants: z.array(fooSc)
 * })
 */
export const experimentTemplateSchema = z.object({
  id: idSchema,
  name: z.string(),
  type: z.string(),
  audience: audienceSchema.nullish(),
  config: z.object({
    components: z.array(componentSchema),
    traffic: z.number(),
    distribution: z.array(z.number())
  })
})
