import classNames from 'classnames'
import React from 'react'

type Props = {
  readonly stacked?: boolean
  readonly className?: string
}
export function Divider({ stacked }: Props) {
  return (
    <div
      className={classNames('bg-neutral-medium-100 my-1 pb-px', {
        'md:mx-4 md:pl-px': !stacked
      })}
      data-component="Divider"
    ></div>
  )
}
