import { getLogoLinkUrl } from '@ecomm/shared-cookies'
import { SimpliSafeLogo } from '@ecomm/shared-icons'
import { handleGTMTrackingEvent } from '@ecomm/tracking'
import { Link } from 'gatsby'
import { useCallback } from 'react'

import CartLink from './CartLink'
import ProgressBar from './ProgressBar'

type HeaderProps = {
  readonly stepVal: number
}

//This for now is hardcoded, but once we need progress bar in another place, we might need to move this to contentful or another global place.
const progressBarList = [
  {
    percentage: 10,
    text: 'Review System'
  },
  {
    percentage: 50,
    text: 'Choose Monitoring'
  },
  {
    percentage: 100,
    text: 'Checkout'
  }
]

export function ProgressBarHeader({ stepVal }: HeaderProps) {
  const handleClick = useCallback(() => {
    handleGTMTrackingEvent({
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation',
      eventLabel: 'Home'
    })
  }, [])

  return (
    <header className="bg-neutral-black h-12 text-white md:h-24 lg:h-28">
      <div className="max-w-8xl mx-auto flex h-full w-full items-center justify-between gap-3 px-4 md:px-8">
        <Link
          aria-label="Home"
          className="flex w-28 flex-shrink-0 justify-self-start md:w-[150px]"
          onClick={handleClick}
          role="link"
          state={{ source: 'header' }}
          style={{ textDecoration: 'inherit' }}
          to={getLogoLinkUrl('/')}
        >
          <SimpliSafeLogo className="h-7 w-28 md:h-9 md:w-40" />
        </Link>

        <div className="mr-5 flex grow items-center justify-end gap-3">
          <CartLink />

          <ProgressBar
            currentStep={progressBarList[stepVal || 0]}
            steps={progressBarList}
          />
        </div>
      </div>
    </header>
  )
}
