import { GiftItemDTO } from '@simplisafe/ss-ecomm-data/prices/service'
import * as O from 'fp-ts/lib/Option'
import { createContext, useContext } from 'react'

import { PriceContextProps } from './types'

export const PriceContext = createContext<PriceContextProps>({
  calculateTotalPrice: _ => 0,
  calculateTotalDiscountedPrice: () => O.none,
  calculateTotalDiscountedPriceWithServicePlan: () => O.none,
  formatPrice: _price => O.none,
  getDiscountText: (_sku: string) => O.none,
  getDiscountTextWithServicePlan: (_sku: string) => O.none,
  getDiscountedPrice: (_sku: string, _months?: number) => O.none,
  getDiscountedPriceWithServicePlan: (_sku: string, _months?: number) => O.none,
  getFormattedPrice: (_sku: string, _months?: number) => O.none,
  getFreeGiftItems: (_sku: string): O.Option<readonly GiftItemDTO[]> => O.none,
  getFreeGiftItemsWithServicePlan: (
    _sku: string
  ): O.Option<readonly GiftItemDTO[]> => O.none,
  getPrice: (_sku: string, _months?: number) => O.none,
  getDynamicDiscountsThresholds: (_sku: string) => O.none,
  getDynamicDiscountedPrice: (_totalPrice: number, _sku: string) => O.none,
  isLoading: false,
  getRelativeDiscountTextWithServicePlan: (_sku: string) => O.none
})

export const usePriceContext = () => useContext(PriceContext)
