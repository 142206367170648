import {
  useCartState,
  useHydrateCartState,
  useRequestCart
} from '@ecomm/data-cart'
import { IOGetCart } from '@simplisafe/ss-ecomm-data/cart'
import { selectCartId } from '@simplisafe/ss-ecomm-data/redux/select'
import React, { type FC, type ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

type InitializeStoreProps = {
  readonly children: ReactElement
  readonly enableJotai: boolean
}

const InitializeStore: FC<InitializeStoreProps> = ({
  children,
  enableJotai
}: InitializeStoreProps) => {
  const dispatch = useDispatch()
  const requestCart = useRequestCart()

  const reduxCartId = useSelector(selectCartId)
  const jotaiState = useCartState()

  useHydrateCartState()

  useEffect(() => {
    !enableJotai && reduxCartId.isNone() && dispatch(IOGetCart())
    enableJotai && jotaiState === 'not_initialized' && requestCart()
  }, [enableJotai])

  return <>{children}</>
}

export default InitializeStore
