import { gql } from '@apollo/client/index.js'
import { useContentful } from '@ecomm/shared-apollo'
import { type TypeOf, z } from '@simplisafe/ewok'

export const TRUST_PILOT_FRAGMENT = gql`
  fragment TrustPilot on WidgetTrustpilotBanner {
    __typename
    type
  }
`

/**
 * Once we're on Remix we can probably remove this query and use the fragment.
 */
export const TRUST_PILOT_QUERY = gql`
  ${TRUST_PILOT_FRAGMENT}
  query TrustPilot($id: String!, $preview: Boolean!, $locale: String!) {
    widgetTrustpilotBanner(id: $id, preview: $preview, locale: $locale) {
      ...TrustPilot
    }
  }
`

export const trustPilotSchema = z.object({
  __typename: z.literal('WidgetTrustpilotBanner'),
  type: z.enum(['Top Banner', 'Home Page Carousel', 'Reviews Page Carousel'])
})

export type TrustPilot = TypeOf<typeof trustPilotSchema>

/**
 * Only for Gatsby!
 * Delete once we are on Remix.
 */
export const useTrustPilotQuery = (id: string) => {
  const { data } = useContentful(TRUST_PILOT_QUERY, { id })
  return trustPilotSchema.parse(data['widgetTrustpilotBanner'])
}
