import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'

import { Locale } from '../../hooks/useLocalizedData'

const baseSchema = z.object({
  image: z.object({
    description: gatsbyImageSchema.shape.description,
    gatsbyImageData: gatsbyImageSchema.shape.gatsbyImageData
  }),
  headline: z.string(),
  body: contentfulRichTextSchema.nullable().optional(),
  buttonText: z.string(),
  buttonLink: z.string()
})

export const schema = {
  [Locale.EnGB]: baseSchema,
  [Locale.EnUS]: baseSchema
}
