import React, { FC, ReactElement, ReactNode } from 'react'

export type WizardInitialProps = {
  /** You don't need to add this prop. It's injected by the Wizard container */
  readonly onNextStep?: () => () => void
  readonly newUserHelpText: string
  readonly returningUserHelpText: string
  readonly newUserStartQuizButton: ReactElement
  readonly newUserSkipQuizButton: ReactNode
  readonly returningUserSeeResultsButton: ReactNode
  readonly returningUserRestartQuizButton: ReactElement
  readonly isNewUser: boolean
}

/** @deprecated Do not use ss-react-components*/
const WizardInitial: FC<WizardInitialProps> = ({
  newUserHelpText,
  returningUserHelpText,
  newUserStartQuizButton,
  newUserSkipQuizButton,
  returningUserSeeResultsButton,
  returningUserRestartQuizButton,
  isNewUser,
  onNextStep
}: WizardInitialProps) => {
  return onNextStep ? (
    <div>
      {isNewUser ? (
        <>
          <p className="mb-6 text-base">{newUserHelpText}</p>
          <div className="flex flex-col gap-4">
            {React.cloneElement(newUserStartQuizButton, {
              onClick: () => {
                onNextStep()()
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                newUserStartQuizButton.props.onClick &&
                  newUserStartQuizButton.props.onClick()
              }
            })}
            {newUserSkipQuizButton}
          </div>
        </>
      ) : null}
      {!isNewUser && (
        <>
          <p className="mb-6 text-base">{returningUserHelpText}</p>
          <div className="flex flex-col gap-4">
            {returningUserSeeResultsButton}
            {React.cloneElement(returningUserRestartQuizButton, {
              onClick: onNextStep()
            })}
          </div>
        </>
      )}
    </div>
  ) : null
}

export default WizardInitial
