// extracted by mini-css-extract-plugin
export var addressBlock = "OrderSummary-module--addressBlock--c3caf";
export var chktCouponBlock = "OrderSummary-module--chktCouponBlock--da5ec";
export var chktOrderSummary = "OrderSummary-module--chktOrderSummary--96c9e";
export var chktProductBlock = "OrderSummary-module--chktProductBlock--697de";
export var chktShippingAddress = "OrderSummary-module--chktShippingAddress--0002f";
export var chktTitle = "OrderSummary-module--chktTitle--e9b4b";
export var couponCont = "OrderSummary-module--couponCont--0593a";
export var discount = "OrderSummary-module--discount--53c20";
export var productPriceEach = "OrderSummary-module--productPriceEach--f5110";
export var shippingTitle = "OrderSummary-module--shippingTitle--c6fdb";
export var subTotalChkt = "OrderSummary-module--subTotalChkt--7d198";
export var totalBlock = "OrderSummary-module--totalBlock--14ea0";
export var totalValue = "OrderSummary-module--totalValue--47e3f";