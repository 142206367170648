import { graphql, useStaticQuery } from 'gatsby'

import { affirmFeatureFlagSchema } from './schema'
import { parseObjectKey } from '@simplisafe/ewok'

export const useAffirmExperimentQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query AffirmFeatureFlagQuery {
      contentfulNinetailedFeatureFlag(
        contentful_id: { eq: "5F2YiuOmFiuL5TNNZTdaaP" }
      ) {
        id: contentful_id
        title
        json {
          id
          tag
        }
        nt_experiences {
          id: contentful_id
          name: nt_name
          type: nt_type
          audience: nt_audience {
            id: contentful_id
            name: nt_name
          }
          config: nt_config {
            components {
              baseline {
                id
              }
              variants {
                id
                hidden
              }
            }
            traffic
            distribution
          }
          variants: nt_variants {
            ... on ContentfulNinetailedFeatureFlag {
              id: contentful_id
              title
              json {
                id
                tag
              }
            }
          }
        }
      }
    }
  `)

  return parseObjectKey(
    staticQuery,
    'contentfulNinetailedFeatureFlag',
    affirmFeatureFlagSchema
  )
}
