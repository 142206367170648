import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useContentful } from '@ecomm/shared-apollo'
import { map } from 'fp-ts/Array'
import * as E from 'fp-ts/lib/Either'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import { useMemo } from 'react'

import { FAQ_QUERY } from './query'
import { FAQSchema, faqSchema } from './schema'

export const useContentfulFaqData = (contentfulID: string) => {
  const { data: rawApolloData } = useContentful(FAQ_QUERY(contentfulID))

  return useMemo(
    () =>
      pipe(
        E.tryCatch(
          () =>
            pipe(
              rawApolloData,
              (data: { readonly [key: string]: unknown }) =>
                faqSchema.parse(data),
              (parsedData: FAQSchema) =>
                parsedData?.faq.primaryCollection.items,
              map(primary => ({
                question: primary.question,
                answer: {
                  raw: documentToReactComponents(primary.answer.json)
                }
              }))
            ),
          identity
        ),
        E.fold(constant([]), identity)
      ),
    [rawApolloData]
  )
}
