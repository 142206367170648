import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { useLocale } from '@ecomm/data-hooks'
import { Link } from '@ecomm/framework'
import { getLogoLinkUrl } from '@ecomm/shared-cookies'
import { useOnKeyUp } from '@ecomm/shared-hooks'
import { useCartQuantity } from '@ecomm/shared-hooks'
import { Close, HamburgerRedesigned, SimpliSafeLogo } from '@ecomm/shared-icons'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { useTracking } from 'react-tracking'

import { Breadcrumbs as BreadcrumbsComponent } from './Breadcrumbs'
import { CourtesyNav } from './CourtesyNav'
import { cartItem, getCourtesyNavItems, phoneItem } from './data'
import { MainNav } from './MainNav'
import { HeaderRedesignSchema } from './schema'
import { SideNav } from './SideNav'

type HeaderProps = HeaderRedesignSchema & {
  readonly type?: 'full' | 'plain-with-no-link' | 'plain' | 'slim'
  readonly slimMenuText?: string
  readonly slimMenuURL?: string
}

function LogoLink({ type }: { readonly type: string }) {
  const { trackEvent } = useTracking()

  const logo = (
    <SimpliSafeLogo className="h-7 w-28 min-[1126px]:h-9 min-[1126px]:w-40" />
  )

  return type !== 'plain-with-no-link' ? (
    <Link
      aria-label="Home page"
      className={classNames(
        'ml-1 flex w-28 grow md:ml-[100px] md:grow-0 min-[1126px]:ml-0 min-[1126px]:w-[150px]',
        type !== 'full' && 'justify-center md:grow min-[1126px]:justify-start',
        type === 'slim' && 'ml-[75px]'
      )}
      onClick={() => {
        trackEvent({
          event: 'navigation-link',
          eventAction: 'click',
          eventCategory: 'navigation',
          label: 'logo'
        })
        trackNavigationClick({
          action: 'click',
          navElement: 'logo'
        })
      }}
      to={getLogoLinkUrl('/')}
    >
      {logo}
    </Link>
  ) : (
    logo
  )
}

export function Header({
  type = 'full',
  slimMenuText = '',
  slimMenuURL = '',
  images,
  quoteWizard
}: HeaderProps) {
  const locale = useLocale()

  const [showSideNav, setShowSideNav] = useState(false)
  const toggleSideNav = (track = false) => {
    track &&
      trackEvent({
        event: 'navigation-link',
        eventAction: showSideNav ? 'close' : 'open',
        eventCategory: 'navigation',
        label: 'hamburger'
      })
    track &&
      trackNavigationClick({
        action: showSideNav ? 'close' : 'open',
        navElement: 'hamburger'
      })
    setShowSideNav(prev => !prev)
  }
  const closeSideNav = () => setShowSideNav(false)

  const headerRef = useRef<HTMLDivElement>(null)

  useOnKeyUp('Escape', () => setShowSideNav(false))

  const { cartQuantity } = useCartQuantity()

  const { trackEvent } = useTracking()

  const top = headerRef?.current?.offsetTop || 0

  return (
    <>
      {type === 'slim' || type === 'plain' || type === 'plain-with-no-link' ? (
        <header className="bg-neutral-black relative w-full text-inherit text-white lg:z-20">
          <nav className="py-p-4 max-w-8xl mx-auto flex h-[50px] items-center gap-4 px-3 max-[1125px]:justify-between md:gap-8 md:px-8 md:py-0 min-[1126px]:h-[78px]">
            <LogoLink type={type} />
            {type === 'slim' && (
              <>
                <div className="hidden flex-grow justify-end min-[1126px]:flex ">
                  <div className="mr-4">{slimMenuText}</div>
                </div>
                <CourtesyNav
                  cartQuantity={cartQuantity}
                  images={images}
                  items={[{ ...phoneItem, url: slimMenuURL }, cartItem]}
                  quoteWizard={{ ...quoteWizard }}
                />
              </>
            )}
          </nav>
        </header>
      ) : (
        <header
          className="bg-neutral-black relative w-full text-inherit lg:z-20"
          ref={headerRef}
        >
          <nav className="py-p-4 max-w-8xl mx-auto flex h-[50px] items-center gap-4 px-3 max-[1125px]:justify-between md:gap-8 md:px-8 md:py-0 min-[1126px]:h-[78px]">
            <button
              aria-expanded={showSideNav}
              aria-label={
                showSideNav ? 'Close side navigation' : 'Open side navigation'
              }
              className="h-7 w-7 cursor-pointer border-none bg-transparent px-1 py-0 text-white min-[1126px]:hidden"
              onClick={() => toggleSideNav(true)}
            >
              {showSideNav ? (
                <Close className="h-7 w-7" />
              ) : (
                <HamburgerRedesigned className="h-7 w-7" />
              )}
            </button>
            <LogoLink type="full" />
            <MainNav images={images} quoteWizard={quoteWizard} />
            <CourtesyNav
              cartQuantity={cartQuantity}
              images={images}
              items={getCourtesyNavItems(locale)}
              quoteWizard={quoteWizard}
            />
          </nav>
          <SideNav
            close={closeSideNav}
            images={images}
            quoteWizard={quoteWizard}
            show={showSideNav}
            toggle={toggleSideNav}
            top={top}
          />
        </header>
      )}
    </>
  )
}

// The `Breadcrumbs` component is being exported here to use it below the `Header` without
// caring about the Optimizely experiment. Once the experiment is finished, we'll add it to this component.
export const Breadcrumbs = BreadcrumbsComponent
