import { Atom } from 'jotai'

/**
 * Add a debug label to a Jotai Atom.
 *
 * Required if you want to see a name when using the Jotai dev tools.
 */
export const addAtomDebugLabel = <T>(atom: Atom<T>, label: string) => {
  atom.debugLabel = label
}
