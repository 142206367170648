import { CurrencyCode } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { selectCurrencyCode } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

type PageContext = {
  readonly currencyCode: CurrencyCode
}

export const usePageContext = (): PageContext => {
  const currencyCode = useSelector(selectCurrencyCode)

  return { currencyCode }
}
