import { addAtomDebugLabel } from '@ecomm/utils'
import type { LineItem } from '@simplisafe/eis-commercetools-carts'
import { voidFn } from '@simplisafe/ewok'
import equals from 'fast-deep-equal'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'
import { match } from 'ts-pattern'

import type { Cart } from '../atoms/cartStates'
import { useCartValue } from '../atoms/useCart'

const baseAtom = atom<readonly LineItem[]>([])

const lineItemsAtom = atom(
  get => get(baseAtom),
  (get, set, update: readonly LineItem[]) => {
    const prev = get(baseAtom)
    const next = update
    equals(prev, next) ? voidFn() : set(baseAtom, next)
  }
)
addAtomDebugLabel(lineItemsAtom, 'Cart - Line Items')

export const patternMatch = (
  cart: Cart,
  fn: (lineItems: readonly LineItem[]) => void
) =>
  match(cart)
    .with({ _tag: 'with_items' }, cart => fn(cart.val.lineItems))
    .with({ _tag: 'updating' }, cart => fn(cart.val.lineItems))
    .with({ _tag: 'empty' }, () => fn([]))
    .otherwise(() => voidFn())

/**
 * Returns the LineItems from the cart.
 *
 * This is updated from the Cart Atom and deep equality checks to be performant.
 *
 */
export const useCartLineItems = (): readonly LineItem[] => {
  const cart = useCartValue()

  const [lineItems, setLineItems] = useAtom(lineItemsAtom)

  useEffect(() => {
    patternMatch(cart, setLineItems)
  }, [cart, setLineItems])

  return lineItems
}
