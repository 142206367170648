import { useLocale } from '@ecomm/data-hooks'
import React, { type ReactNode } from 'react'

import { CartOdmonWarning } from './CartOdmonWarning'
import { CartSecuritySystemWarning } from './CartSecuritySystemWarning'
import CartWarningMessageItem from './CartWarningMessageItem'
import { CartQuantityWarningMessage } from './CartQuantityWarningMessage'

export type CartWarningMessagesProps = {
  readonly mixedComponentsMessage?: ReactNode
  readonly showWithoutSystemMessage?: boolean
  readonly showMixedComponentsWarning?: boolean
  readonly showScoutWarning?: boolean
  readonly showOdmonCameraWarning?: boolean
  readonly quantityLimitMessages: ReadonlyArray<{
    readonly node: ReactNode
    readonly key: string
  }>
}

function CartWarningMessages({
  showWithoutSystemMessage,
  showMixedComponentsWarning,
  mixedComponentsMessage,
  showScoutWarning,
  quantityLimitMessages,
  showOdmonCameraWarning = false
}: CartWarningMessagesProps) {
  const locale = useLocale()
  const isUs = locale === 'en-US'

  return (
    <>
      {showMixedComponentsWarning && mixedComponentsMessage ? (
        <CartWarningMessageItem
          iconSrc="//images.ctfassets.net/br4ichkdqihc/1YbER6o3QQAGkeRvxBRSs/5a4dd142ebb4f0318dfdc4759be27856/Icon_-_Caution__Grey_.png"
          key="mixedcomponents"
          message={mixedComponentsMessage}
          showIcon
        />
      ) : null}

      {quantityLimitMessages.map(message => (
        <CartQuantityWarningMessage
          key={`quantity-warning-message-${message.key}`}
          message={message.node}
        />
      ))}

      {isUs && showOdmonCameraWarning ? <CartOdmonWarning /> : null}
      {showWithoutSystemMessage || showScoutWarning ? (
        <CartSecuritySystemWarning />
      ) : null}
    </>
  )
}

export default CartWarningMessages
