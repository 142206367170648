import {
  useDisplayMonitoringDiscount,
  usePromoDiscountText,
  usePromoWithMonitoringDiscountText,
  useTopBannerVisible
} from '@ecomm/promotions-hooks'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { Just } from '@simplisafe/ewok'
import classNames from 'classnames'
import React from 'react'

function PromoTag({ className = '' }: { readonly className?: string }) {
  const displayMonitoringDiscount = useDisplayMonitoringDiscount()
  const discountText = usePromoDiscountText()
  const isPromoTopBanner = useTopBannerVisible()
  const discountWithMonitoringText = usePromoWithMonitoringDiscountText()

  const isPartnerAirline =
    getValueFromPartnerCookie('partnerGroup') === 'airlines'
  const isNHWPartner = getValueFromPartnerCookie('partnerName') === 'nhw'
  const isLightspeedPartner =
    getValueFromPartnerCookie('partnerName') === 'lightspeed'

  const discountPlaceholderText = isNHWPartner
    ? Just('50%')
    : isLightspeedPartner
      ? Just('60%')
      : displayMonitoringDiscount
        ? discountWithMonitoringText
        : discountText

  return discountPlaceholderText.isSome() &&
    isPromoTopBanner &&
    !isPartnerAirline ? (
    <div
      className={classNames(
        'prose-p:text-sm md:prose-p:text-base z-[2] mb-4 w-fit rounded-full border-2 border-solid border-[inherit] px-6 py-2 lg:-mt-16',
        className
      )}
      data-component="PromoTag"
    >
      <p className="mb-0 font-bold">{discountPlaceholderText} OFF</p>
    </div>
  ) : null
}

export default PromoTag
