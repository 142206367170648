import { selectCurrentPromoDisplayMonitoringDiscount } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

/**
 * Returns displayMonitoringDiscount flag,
 * if Active promotion resolves to None check the Evergreen promotion instead
 */
export const useDisplayMonitoringDiscount = (): boolean => {
  return useSelector(selectCurrentPromoDisplayMonitoringDiscount)
}
