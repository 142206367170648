import { useOdmonExperience } from '@ecomm/shared-ninetailed'

import { ODMONdata, data } from './data'
import { userStoriesSchema } from './schema'

export const useMonitoringPageUserStoriesData = () => {
  const userStoriesData = useOdmonExperience().isVariant ? ODMONdata : data

  return userStoriesSchema.parse(userStoriesData)
}
