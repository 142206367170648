//@ts-nocheck
import { Maybe, Some } from '@simplisafe/ewok'
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { CheckMark } from '../icons'
import * as css from './ComparisonTableRow.module.scss'

export type ComparisonTableRowProps = {
  /** Click target for opening modal. */
  readonly clickTarget: ReactNode
  /** Feature title */
  readonly featureTitle?: string
  /** Is this feature part of the Pro Premium monitoring plan? */
  readonly isProPremiumFeature: boolean
  /** Is this feature part of the Pro (Standard) monitoring plan? */
  readonly isStandardFeature: boolean
  /** Row feature text for checkmark alt text */
  readonly rowLabel: string
  /** Handle monitoring feature plan*/
  readonly monitoringFeature?: readonly string[]
  /** data-component attribute value. */
  readonly dataComponent?: string
}

const CHECKMARKTEXT = '{true}'
const UNCHECKMARKTEXT = '{false}'

/** @deprecated Do not use ss-react-components*/
const ComparisonTableRow: FC<ComparisonTableRowProps> = ({
  clickTarget,
  dataComponent = ComparisonTableRow.name,
  isProPremiumFeature,
  isStandardFeature,
  rowLabel,
  monitoringFeature,
  featureTitle = ''
}: ComparisonTableRowProps) => {
  return (
    <tr className="mx-auto my-0 min-h-[40px]" data-component={dataComponent}>
      <td className="border-neutral-light-50 md:border-neutral-light-100 border-b-0 border-l-0 border-r border-t border-solid py-3 text-left md:px-4 lg:px-9">
        <span
          className={classNames(
            'flex items-center pl-1 text-xs font-medium underline md:text-lg md:font-normal md:no-underline',
            css.clickTarget
          )}
        >
          {clickTarget}
        </span>
      </td>
      {Maybe.fromNull(monitoringFeature)
        .map(_monitoringFeature =>
          _monitoringFeature.map((feature, i) => {
            return (
              <td
                className="border-neutral-light-50 md:border-neutral-light-100 border-b-0 border-l-0 border-r border-t border-solid text-xs md:px-4 md:pt-2 md:text-lg md:last:border-r-0 lg:px-9"
                key={`feature-${i}`}
                role="cell"
              >
                {feature === CHECKMARKTEXT ? (
                  <CheckMark
                    className="text-complementary-sage-100"
                    dataComponent="CheckMark"
                  />
                ) : feature === UNCHECKMARKTEXT ? (
                  ' '
                ) : (
                  feature
                )}
              </td>
            )
          })
        )
        .catchMap(() => {
          return Some([
            <>
              <td className="border-neutral-light-50 md:border-neutral-light-100 border-b-0 border-l-0 border-r border-t border-solid md:px-4 md:pt-2 md:last:border-r-0 lg:px-9">
                {isProPremiumFeature ? (
                  <span className="p-0">
                    <CheckMark
                      className="text-complementary-sage-100 m-0 p-0"
                      titleA11y={`${featureTitle} ${rowLabel}`}
                    />
                  </span>
                ) : null}
              </td>
              <td className="border-neutral-light-50 md:border-neutral-light-100 border-b-0 border-l-0 border-r border-t border-solid md:px-4 md:pt-2 md:last:border-r-0 lg:px-9">
                {isStandardFeature ? (
                  <span className="p-0">
                    <CheckMark
                      className="text-complementary-sage-100 m-0 p-0"
                      titleA11y={`${featureTitle} ${rowLabel}`}
                    />
                  </span>
                ) : null}
              </td>
            </>
          ])
        })
        .some()}
    </tr>
  )
}

export default ComparisonTableRow
