/**
 * Imported almost verbatim Aug 2024 for ODMON needs from:
 * https://github.com/simplisafe/ss-ecomm-data/blob/9472cace9fd75ba57cd445ca8e9c5f44ed09132d/src/commercetools/cart.ts
 *
 * This was only to avoid work in ss-ecomm-data;
 * both that and this legacy app are mid-rewrite.
 *
 * Nothing in here should be continued or amended.
 */

import {
  CustomLineItem,
  LineItem as CTLineItem
} from '@commercetools/platform-sdk'
import { transformObject } from '@simplisafe/ewok'
import { safeFind, safePath, safeProp } from '@simplisafe/monda'
import type { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import type { Locale as _Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { ProductTypes } from '@simplisafe/ss-ecomm-data/constants'
import { Maybe } from 'monet'
import any from 'ramda/src/any'
import flatten from 'ramda/src/flatten'
import groupBy from 'ramda/src/groupBy'
import head from 'ramda/src/head'
import length from 'ramda/src/length'
import map from 'ramda/src/map'
import path from 'ramda/src/path'
import pathEq from 'ramda/src/pathEq'
import prop from 'ramda/src/prop'
import propEq from 'ramda/src/propEq'
import reduce from 'ramda/src/reduce'
import values from 'ramda/src/values'
import without from 'ramda/src/without'

type ProductName = {
  readonly [key in _Locale]?: string
}

export type Child = {
  readonly isFree: boolean
  readonly name: ProductName
  readonly quantity: number
  readonly sku: string
}

type NonEmptyArray<T> = readonly [T, ...(readonly T[])]

const toChild = transformObject<CustomLineItem | LineItem, Child>({
  isFree: item => item.totalPrice === 0,
  name: prop('name'),
  quantity: prop('quantity'),
  sku: lineItem =>
    safePath(['custom', 'fields', 'product_key'], lineItem).getOrElse('')
})

const sortKeyedLineItem = (keyedLineItems: {
  readonly [key: string]: readonly LineItem[]
}) =>
  [...Object.keys(keyedLineItems)].sort().reduce(
    (acc: { readonly [key: string]: readonly LineItem[] }, key) => ({
      ...acc,
      [key]: [...keyedLineItems[key]]
    }),
    {}
  )

const flattenKeyedLineItemss =
  (sortKey: boolean) =>
  (keyedLineItems: {
    readonly [key: string]: readonly LineItem[]
  }): readonly LineItem[] => {
    const mapLineItems = sortKey
      ? sortKeyedLineItem(keyedLineItems)
      : keyedLineItems

    return flatten(values(mapLineItems))
  }

const groupByProductType = (lineItem: LineItem) =>
  lineItem.productType === 'package_parent' ||
  lineItem.productType === 'service'
    ? '1stOrder'
    : lineItem.isGift
      ? '3rdOrder'
      : '2ndOrder'

//Grouping lineItems by parent id if lineItems has any package
const groupByProductTypeAndParentId = (
  lineItems: readonly LineItem[]
): readonly LineItem[] => {
  return safeFind(i => !!path(pathPackageParentId, i), lineItems)
    .orElse(safeFind<LineItem>(propEq('isGift', true), lineItems))
    .map(() => groupBy(groupByProductType, lineItems))
    .map(flattenKeyedLineItemss(true))
    .map(
      groupBy(item =>
        safePath([...pathPackageParentId], item).orJust(prop('sku', item))
      )
    )
    .map(flattenKeyedLineItemss(false))
    .getOrElse(lineItems)
}

const pathPackageParentId: readonly ['custom', 'fields', 'package_parent_id'] =
  ['custom', 'fields', 'package_parent_id']
const pathProductKey: readonly ['custom', 'fields', 'product_key'] = [
  'custom',
  'fields',
  'product_key'
]
const pathIsBms: readonly ['custom', 'fields', 'product_is_bms'] = [
  'custom',
  'fields',
  'product_is_bms'
]

const proSetupSkus = ['SSPSH-ON', 'SSPSH-UK']

const isAnyBms = (
  lineItems: readonly CTLineItem[] | readonly LineItem[]
): boolean => any(pathEq(pathIsBms, true))(lineItems)

/**
 * Given a line item, determine its grouping key.
 *
 * @param lineItem The line item to group.
 * @return string The parent package ID when the line item is a system or system component; the line item ID otherwise.
 * @see groupLineItemsByParent
 */
const lineItemGroupKey = (lineItem: LineItem): string => {
  const lineItemId = lineItem.lineItemId
  const parentId = path<string>(pathPackageParentId, lineItem)
  const productSku = path<string>(pathProductKey, lineItem)
  const isProSetup = proSetupSkus.some(sku => sku === productSku)
  const isServicePlan = lineItem.productType === ProductTypes.Service

  return !!parentId && !isProSetup && !isServicePlan ? parentId : lineItemId
}

/**
 * Creates a list of line item "groups" based on parent.
 *
 * Each custom security system and its component line items are all grouped together as a single line item in
 * the cart view. Any other line items, including prebuilt systems, monitoring plans, installation plans, and
 * standalone components will not be grouped together. Instead, each of these line items will display as its
 * own line in the cart view.
 *
 * @param lineItems The line items to divide into groups.
 * @return A list of line items grouped by parent. Each element is a single grouping.
 */
const groupLineItemsByParent = (
  lineItems: readonly LineItem[]
): readonly NonEmptyArray<LineItem>[] => {
  const groupings = groupBy(lineItemGroupKey, lineItems)

  return Object.values<readonly LineItem[]>(groupings).filter(
    (lineItems): lineItems is NonEmptyArray<LineItem> => lineItems.length > 0
  )
}

/**
 * Will return back line items if there is no any parent package
 * will finalize the line items value including bms, regular package, and singular item
 * @param parentPackageSku
 * @param lineItems
 */
export const getCartDetails =
  (hiddenProductSkus: readonly string[]) =>
  (cartLineItems: readonly LineItem[]): readonly LineItem[] => {
    const overrideChild = (packageLineItems: readonly LineItem[]): LineItem => {
      const parent: Maybe<LineItem> = safeFind<LineItem>(
        pathEq(pathIsBms, true)
      )(packageLineItems) // TODO: this as LineItem is holding all of this together and all of this needs to have the types corrected to work without it
      const mChild = parent.chain(_parent =>
        Maybe.fromUndefined(without([_parent])(packageLineItems))
      )

      const extractChildren = (parent: LineItem): readonly Child[] =>
        (parent.child || []).map(child => ({ ...child, isFree: false }))

      // convert what are now line items to children, but also include children from the identified parent
      // todo this is an example of how overly complicated this file is
      const childs = mChild
        .map<readonly Child[]>(map<LineItem, Child>(toChild))
        .getOrElse([])
        .concat(parent.map(extractChildren).getOrElse([]))

      const getPrice = (key: 'price' | 'totalPrice') =>
        mChild
          .map(
            reduce((acc, lineItem: LineItem) => acc + prop(key, lineItem), 0)
          )
          .getOrElse(0)
      const totalChildTotalPrice = getPrice('totalPrice')
      const totalChildPrice = getPrice('price')

      return {
        // TODO the parent type should never be an empty object
        // This should return a Maybe and remove all of the getOrElse functions
        // @ts-expect-error
        ...parent.getOrElse({}),
        child: [...childs],
        price: totalChildPrice,
        totalPrice: totalChildTotalPrice
      }
    }

    const filterHiddenChildren = (lineItem: LineItem): LineItem => ({
      ...lineItem,
      child: safeProp('child', lineItem)
        .map(children =>
          children.filter(product =>
            safeProp('sku', product)
              .map(sku => !hiddenProductSkus.includes(sku))
              .getOrElse(true)
          )
        )
        .getOrElse([])
    })

    const filterHiddenProducts = (lineItem: LineItem): boolean =>
      !hiddenProductSkus.includes(lineItem.sku)

    // Accepts a list of at least one line item so that we can potentially grab the first item out of it without
    // ending up with an undefined value.
    const mapToBmsParent = (lineItems: NonEmptyArray<LineItem>): LineItem => {
      // TODO this logic should be hardened/fixed; the way that overrideChild works, this assumes that if we have multiple
      // lineItems with the same package parent, one of them is going to be a BMS package parent
      const mappedLineItem =
        length(lineItems) > 1 ? overrideChild(lineItems) : head(lineItems)
      return filterHiddenChildren(mappedLineItem)
    }

    const groupAndMapToBmsParent = (
      lineItems: readonly LineItem[]
    ): readonly LineItem[] => {
      const mappedLineItems =
        groupLineItemsByParent(lineItems).map(mapToBmsParent)

      return groupByProductTypeAndParentId(mappedLineItems)
    }

    const groupAndMapOtherwise = (
      lineItems: readonly LineItem[]
    ): readonly LineItem[] => {
      const mappedLineItems = lineItems
        .filter(filterHiddenProducts)
        .map(filterHiddenChildren)

      return groupByProductTypeAndParentId(mappedLineItems)
    }

    return isAnyBms(cartLineItems)
      ? groupAndMapToBmsParent(cartLineItems)
      : groupAndMapOtherwise(cartLineItems)
  }
