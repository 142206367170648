import { noValue } from '@ecomm/utils'
import { localStorage } from '@simplisafe/ewok'
import { useEffect, useMemo } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

import { useOptimizelyActivateExperiment } from '../optimizely'
import { params } from '../queryParams'

const { get, set } = localStorage

export const useOptimizelyParams = () => {
  const optimizelyActivateExperiment = useOptimizelyActivateExperiment()

  const [query, setQuery] = useQueryParams({
    [params.experimentKey]: StringParam,
    [params.variationKey]: StringParam
  })

  const { experiment, variation } = query

  const cachedExperiment = useMemo(() => get('experiment') || '', [])
  const cachedVariation = useMemo(() => get('variation') || '', [])

  useEffect(() => {
    const hasOptimizelyValues = !!(experiment && variation)

    hasOptimizelyValues && set('experiment', experiment)
    hasOptimizelyValues && set('variation', variation)

    hasOptimizelyValues &&
      optimizelyActivateExperiment({
        experimentId: experiment,
        variationId: variation
      })

    hasOptimizelyValues &&
      setQuery({
        [params.experimentKey]: noValue(),
        [params.variationKey]: noValue()
      })
  }, [experiment, variation])

  return {
    experimentKey: experiment || cachedExperiment,
    variationKey: variation || cachedVariation
  }
}
