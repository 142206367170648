import { Locale } from '../../hooks/useLocalizedData'

export const data = {
  [Locale.EnUS]: {
    listItems: [
      {
        headline: 'Faster police response with verified video evidence*',
        icon: 'PoliceCar',
        disclaimer:
          '* Vs. non-verified SimpliSafe® alarms. Requires Fast Protect™ Monitoring plan with Video Verification.'
      },
      {
        headline:
          '24/7 whole home protection against break-ins, fires & floods',
        icon: 'SecureHomeIcon'
      },
      {
        headline: 'Instant alarm response & mobile notifications',
        icon: 'PhoneRinging'
      },
      {
        headline: '1st month of monitoring free. No contracts, cancel anytime',
        icon: 'Dollar'
      }
    ]
  }
}
