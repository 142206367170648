//@ts-nocheck

import classNames from 'classnames'
import React, { FC } from 'react'
import slugify from 'slugify'

import useMediaQuery from '../hooks/useMediaQuery'
import Menu, { MenuProps } from '../Menu'
import * as css from './AccordionLinks.module.scss'

export type AccordionLinksProps = {
  readonly className?: string
  readonly title: string
  readonly menus: readonly MenuProps[]
  readonly theme?: 'footer' | 'header'
  readonly onClick?: (menuItem: unknown) => void
  readonly dataComponent?: string
}

// TODO Refactor to just be a container for Link

/**
 * @deprecated React-components will be deprecated soon!. Do not use this component anymore.
 *
 * React components is gonna be deprecated and replaced by the new eis-simpliecom library!
 * You can create a new component in your new app or in shared/components if you consider that your component is reusable.
 * If you think your component is super reusable and generic, we could consider to include it in eis-simpliecom. Reach out platform team in that case!
 */
const AccordionLinks: FC<AccordionLinksProps> = ({
  className,
  title,
  menus,
  onClick,
  theme = 'footer',
  dataComponent = AccordionLinks.name
}: AccordionLinksProps) => {
  const [selected, setSelected] = React.useState(false)

  // TODO The menu list should just be a child component and this logic can be removed.
  /*
      <AccordionLinks>
        <AccordionLink />
      </AccordionLinks>
    */
  const menuList = menus.map(({ name, url }) => {
    const isNotEmptyMenu = !!name
    const keyValue = isNotEmptyMenu ? name : 'empty'
    const menuTheme = theme === 'footer' ? 'subfooterlink' : 'link'
    return (
      <li
        className={classNames(css.linkText, {
          fontSize14: theme === 'footer',
          fontSize16: theme === 'header',
          [css.header]: theme === 'header',
          [css.footer]: theme === 'footer'
        })}
        key={keyValue}
      >
        <Menu name={name} onClick={onClick} theme={menuTheme} url={url} />
      </li>
    )
  })
  const isTabletUp = useMediaQuery('TabletAndUp')
  const controlsId = slugify(title, { lower: true })
  //const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => (e.key === ' ' || e.key === 'Enter' || e.key === 'Spacebar') ? onClick() : undefined
  const titleClasses = classNames(
    'colorLight',
    'm0',
    'font-default',
    css.title,
    {
      colorDark: theme === 'header' && !selected,
      colorLight: theme === 'footer',
      colorPrimary: theme === 'header' && selected,
      [css.footer]: theme === 'footer',
      [css.header]: theme === 'header',
      fontSize14: theme === 'header',
      h5: theme === 'footer',
      mediumText: theme === 'footer',
      [css.active]: selected,
      [css.deactive]: !selected
    }
  )
  return (
    <div
      className={classNames(className, css.accordionLinks)}
      data-component={dataComponent}
    >
      {isTabletUp ? (
        <h2 className={titleClasses}>{title}</h2>
      ) : (
        <button
          aria-controls={controlsId}
          aria-expanded={selected}
          className={
            titleClasses + ' appearance-none border-0 bg-transparent p-0'
          }
          onClick={() => setSelected(s => !s)}
        >
          {title}
        </button>
      )}
      <ul
        aria-hidden={!isTabletUp && !selected}
        className={classNames(
          css.accordionLink,
          {
            [css.footer]: theme === 'footer',
            [css.header]: theme === 'header',
            [css.active]: selected,
            [css.deactive]: !selected
          },
          'm0'
        )}
        id={controlsId}
      >
        {isTabletUp || selected ? menuList : null}
      </ul>
    </div>
  )
}

export default AccordionLinks
