import classNames from 'classnames'
import { Link } from '@ecomm/framework'
import type { HTMLAttributes, ReactElement } from 'react'

import FooterCollapsible from './FooterCollapsible'
import type { FooterMenus } from '@ecomm/footer-data'

type MenuAccordionsProps = HTMLAttributes<HTMLDivElement> & {
  readonly menus: FooterMenus
  readonly onClickLink?: () => void
  readonly type?: 'Full' | 'Slim'
}

function MenuAccordions({
  type = 'Slim',
  className = '',
  menus
}: MenuAccordionsProps): ReactElement {
  const isHeaderSlim = type === 'Slim'
  return (
    <div className={className}>
      <ul className="m-0 flex list-none flex-col justify-between gap-2 px-0 md:w-full md:flex-row md:gap-8 lg:w-fit">
        {menus.map(menu => (
          <li
            className={classNames({
              hidden: !menu.titleUrl && isHeaderSlim,
              'md:hidden': !isHeaderSlim && menu.title === 'Legal'
            })}
            key={`footer_menus_${menu.title}`}
          >
            {isHeaderSlim ? (
              menu.titleUrl?.startsWith('/') ? (
                <Link
                  className="text-md font-bold text-white no-underline hover:underline md:text-lg"
                  to={menu.titleUrl}
                >
                  {menu.title}
                </Link>
              ) : (
                <a
                  className="text-md font-bold text-white no-underline hover:underline md:text-lg"
                  href={menu.titleUrl ?? ''}
                >
                  {menu.title}
                </a>
              )
            ) : (
              <FooterCollapsible menu={menu} />
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default MenuAccordions
