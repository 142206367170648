import {
  BLOCKS,
  type Block,
  INLINES,
  type Inline,
  type Mark,
  type Text
} from '@contentful/rich-text-types'
import {
  INTERACTIVE_MONITORING,
  ODMON_247_MONITORING,
  ODMON_247_MONITORING_60,
  ODMON_OVERNIGHT_MONITORING,
  ODMON_OVERNIGHT_MONITORING_60,
  SELF_MONITORING_WITH_CAMERA_RECORDINGS,
  STANDARD_MONITORING
} from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import {
  monthlyToLocalizedDailyPrice,
  usePriceContext
} from '@ecomm/data-price'
import { ContentfulRichText } from '@ecomm/shared-components'
import { CheckmarkDarkBlue, Miss } from '@ecomm/shared-icons'
import { useTrackOdmonSeeDetails } from '@ecomm/tracking'
import { isString } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { match } from 'ts-pattern'

import type { Row } from '../ComparisonTable'
import FeatureModal from '../FeatureModal'
import type { PlanFeatureSchema } from '../MonitoringPlanCard/schema'
import { AddPlanToCartButtonProduct } from '../AddPlanToCartButton'

export const applyMark = (
  content: JSX.Element | string,
  mark: Mark
): JSX.Element | string => {
  return match(mark)
    .with({ type: 'bold' }, () => <strong>{content}</strong>)
    .with({ type: 'italic' }, () => <em>{content}</em>)
    .otherwise(() => content)
}

export const applyTextStyles = (
  textContent: string,
  marks: readonly Mark[]
): JSX.Element | string => {
  return marks.reduce<JSX.Element | string>(
    (content, mark) => applyMark(content, mark),
    textContent
  )
}

const getTextOrIcon = (
  text: Text,
  withCategorizedRows: boolean
): JSX.Element | string => {
  return match(text.value)
    .with('Check', () => (
      <CheckmarkDarkBlue
        className="h-8 w-8"
        data-testid="test-checkmark-icon"
      />
    ))
    .with('Miss', () => (
      <Miss className="h-8 w-8" data-testid="test-miss-icon" />
    ))
    .otherwise(() =>
      withCategorizedRows ? (
        text.value
      ) : (
        <div>{applyTextStyles(text.value, text.marks)}</div>
      )
    )
}

const getPlanSKU = (skuWithHash: string) => skuWithHash.split('#')[1]

export const getPlanCode = (sku: string): string => {
  type SkuCodeMapping = {
    [key: string]: string
  }

  const skuCodeMapping: SkuCodeMapping = {
    [INTERACTIVE_MONITORING]: 'essential',
    [ODMON_OVERNIGHT_MONITORING]: 'pro',
    [ODMON_OVERNIGHT_MONITORING_60]: 'pro',
    [ODMON_247_MONITORING]: 'complete',
    [ODMON_247_MONITORING_60]: 'complete',
    [STANDARD_MONITORING]: 'standard',
    [SELF_MONITORING_WITH_CAMERA_RECORDINGS]: 'self'
  }

  return skuCodeMapping[sku] || ''
}

export const useGetParagraphData = (
  paragraphContent: readonly (Block | Inline | Text)[],
  withCategorizedRows: boolean
) => {
  const trackOdmonDetails = useTrackOdmonSeeDetails()
  const locale = useLocale()
  const { getPrice } = usePriceContext()

  const content = paragraphContent
    .map(content => {
      return match(content)
        .with({ nodeType: 'text' }, text =>
          getTextOrIcon(text, withCategorizedRows)
        )
        .with({ nodeType: INLINES.EMBEDDED_ENTRY }, node => {
          if (node.data.target.__typename === 'ContentfulButton') {
            const { url } = node.data.target
            const sku = getPlanSKU(url)
            const priceText = O.match(
              () => null,
              (price: number) => monthlyToLocalizedDailyPrice(locale, price)
            )(getPrice(sku))

            return priceText ? (
              <>
                <span className="mt-6 inline-block font-bold">
                  {priceText}/
                </span>
                <span className="block font-bold md:inline-block">day</span>
              </>
            ) : null
          } else {
            const { helpText, modal, trackingCode }: PlanFeatureSchema =
              node.data.target

            return (
              <div
                className="prose-p:mb-0 prose-p:text-sm prose-p:md:text-base flex items-center gap-1"
                data-testid="TableFeatureModal"
              >
                <ContentfulRichText {...helpText} />
                {modal ? (
                  <FeatureModal
                    modal={modal}
                    onClick={() => {
                      trackingCode && trackOdmonDetails(trackingCode)
                    }}
                  />
                ) : null}
              </div>
            )
          }
        })
        .otherwise(() => '')
    })
    .filter(cell => cell !== '')

  return withCategorizedRows ? content[0] : content
}

export const getValue = (
  cellContent: readonly (Block | Inline | Text)[],
  withCategorizedRows: boolean
): JSX.Element | string => {
  const content = cellContent
    .map(content => {
      return match(content)
        .with({ nodeType: BLOCKS.PARAGRAPH }, paragraph => {
          if (
            paragraph.content.length === 3 &&
            paragraph.content[1].data.target.__typename ===
              'ContentfulButton' &&
            paragraph.content[1].data.target.type === 'Custom Plain'
          ) {
            return (
              <AddPlanToCartButtonProduct
                buttonText={paragraph.content[1].data.target.buttonText}
                className="w-full md:w-48"
                sku={paragraph.content[1].data.target.url}
              />
            )
          }
          return useGetParagraphData(paragraph.content, withCategorizedRows)
        })
        .otherwise(() => '')
    })
    .filter(cell => cell !== '')

  return withCategorizedRows ? <>{content[0]}</> : <>{content}</>
}

export const mapCells = (
  cells: readonly (Block | Inline | Text)[],
  type: BLOCKS.TABLE_CELL | BLOCKS.TABLE_HEADER_CELL,
  withCategorizedRows: boolean
) =>
  cells.map(cell => {
    return match(cell)
      .with({ nodeType: type }, c => getValue(c.content, withCategorizedRows))
      .otherwise(() => '')
  })

export const mapRows = (
  rows: readonly (Block | Inline | Text)[],
  type: BLOCKS.TABLE_CELL | BLOCKS.TABLE_HEADER_CELL,
  withCategorizedRows = true
) =>
  rows
    .map(row => {
      return {
        cells: match(row)
          .with({ nodeType: BLOCKS.TABLE_ROW }, r =>
            mapCells(r.content, type, withCategorizedRows)
          )
          .otherwise(() => []),
        collapsed: false,
        isSectionTitle: false,
        category: ''
      }
    })
    .filter(row => !row.cells.every(el => el === ''))

export const categorizeRows = (
  rows: readonly Row[],
  categories: readonly string[]
): readonly Row[] => {
  let currentCategory = categories[0]

  const rowsWithCategory = rows.map(row => {
    const firstCell = row.cells[0]
    let isSectionTitle = false

    if (isString(firstCell) && categories.includes(firstCell)) {
      isSectionTitle = true
    } else if (
      typeof firstCell === 'object' &&
      firstCell !== null &&
      'props' in firstCell &&
      typeof firstCell.props.children === 'string' &&
      categories.includes(firstCell.props.children)
    ) {
      isSectionTitle = true
    }

    currentCategory = isSectionTitle
      ? isString(firstCell)
        ? firstCell
        : firstCell.props.children
      : currentCategory

    return {
      ...row,
      category: currentCategory,
      collapsed: false,
      isSectionTitle
    }
  })

  return rowsWithCategory
}
