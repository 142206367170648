import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'

export type Props = {
  readonly maxQuantity: number
  readonly minQuantity?: number
  readonly onDecrease: () => void
  readonly onIncrease: () => void
  readonly quantity: number
  readonly name?: string
}

const buttonClass =
  'bg-neutral-white-100 border-none p-0 cursor-pointer w-6 h-6 flex items-center justify-center rounded-[5px]'

export default function QuantitySelector({
  maxQuantity,
  minQuantity = 0,
  onDecrease,
  onIncrease,
  quantity: quantityProp
}: Props) {
  // Prevents negative numbers.
  const quantity = quantityProp < 0 ? 0 : quantityProp

  const handleDecrease = () => {
    onDecrease()
  }

  const handleIncrease = () => {
    onIncrease()
  }

  const isMinExceeded = quantity <= minQuantity
  const isMaxExceeded = quantity >= maxQuantity

  const microCopy = useMicroCopy()

  return (
    <div
      className="flex items-center justify-center"
      data-component="QuantitySelector"
    >
      <button
        aria-label={microCopy['aria-decrease-quantity']}
        className={buttonClass}
        disabled={isMinExceeded}
        onClick={handleDecrease}
      >
        -
      </button>
      <span aria-label="Quantity" className="w-8 text-center">
        {quantity}
      </span>
      <button
        aria-label={microCopy['aria-increase-quantity']}
        className={buttonClass}
        disabled={isMaxExceeded}
        onClick={handleIncrease}
      >
        +
      </button>
    </div>
  )
}
