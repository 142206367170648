import React, { FC } from 'react'

import isNotUndefined from '../utils/isNotUndefined'

type PriceProps = {
  /** The data-component attribute value. */
  readonly dataComponent?: string
  /** The regular price of a product. */
  readonly regularPrice?: string
  /** The discounted price of a product. */
  readonly discountedPrice?: string
}

const strike = (shouldStrike: boolean, price?: string) =>
  shouldStrike ? <s>{price}</s> : price

/** @deprecated Do not use ss-react-components*/
const Price: FC<PriceProps> = ({
  dataComponent = Price.name,
  discountedPrice,
  regularPrice
}: PriceProps) => {
  return discountedPrice || regularPrice ? (
    <span data-component={dataComponent}>
      {discountedPrice ? (
        <span className={'errorRedTextColor'}>
          {regularPrice ? (
            <span className="screenReaderText">Discounted price: </span>
          ) : null}
          {discountedPrice}
        </span>
      ) : null}
      {discountedPrice && regularPrice ? ' ' : null}
      {regularPrice ? (
        <span>
          {discountedPrice ? (
            <span className="screenReaderText">Actual price: </span>
          ) : null}
          {strike(isNotUndefined(discountedPrice), regularPrice)}
        </span>
      ) : null}
    </span>
  ) : null
}

export default Price
