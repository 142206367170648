import {
  type TermsAndConditionsTypes,
  trackTermsAndConditionsClick
} from '@ecomm/cdp-tracking'
import { useMicroCopy } from '@ecomm/micro-copy'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'

export function TermsOfSale() {
  const microCopy = useMicroCopy()

  const onClickTermsConditions = (label: TermsAndConditionsTypes) =>
    trackTermsAndConditionsClick(label)

  return (
    <div data-testid="TermsOfSale">
      <div>
        <p className={'m-0 w-full text-xs text-neutral-dark'}>
          {microCopy['by-submitting-order-agree-simplisafe']}
          <a
            className={'pl-1 text-neutral-dark'}
            href="/legal/terms-sale"
            onClick={() => onClickTermsConditions('terms of sale')}
            rel="noreferrer"
            target="_blank"
          >
            {microCopy['terms-of-sale']}
          </a>
          ,
          <a
            className={'px-1 text-neutral-dark'}
            href="/legal/terms-of-service"
            onClick={() => onClickTermsConditions('terms of service')}
            rel="noreferrer"
            target="_blank"
          >
            {microCopy['terms-of-service']}
          </a>
          and
          <a
            className={'pl-1 text-neutral-dark'}
            href="/legal/privacy-policy"
            onClick={() => onClickTermsConditions('privacy policy')}
            rel="noreferrer"
            target="_blank"
          >
            {microCopy['privacy-policy']}
          </a>
          .
        </p>
        {getValueFromPartnerCookie('partnerName') === 'lightspeed' ? (
          <p className={'m-0 w-full pt-2 text-xs text-neutral-dark'}>
            By placing this order, I hereby acknowledge and agree that certain
            of my personal information (email address and service status) will
            be shared with Lightspeed Broadband.
          </p>
        ) : null}
      </div>
    </div>
  )
}
