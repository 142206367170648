import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const shippingFormSchema = z.object({
  __typename: z.literal('ContentfulCheckoutForm'),
  contentful_id: z.string(),
  foundInfoThrough: z.array(z.string()),
  selectCountry: z.array(z.string()),
  validatedDescription: contentfulRichTextSchema,
  id: z.string()
})

export type ShippingFormFragment = TypeOf<typeof shippingFormSchema>
