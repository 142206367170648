import classNames from 'classnames'
import { useField } from 'formik'
import React from 'react'

type FormTextInputProps = {
  readonly id: string
  readonly className?: string
  readonly name?: string
  readonly placeholder?: string
  readonly type?: React.HTMLInputTypeAttribute
  readonly onChange?: React.ChangeEventHandler<HTMLInputElement>
  readonly disabled?: boolean
}

export function FormTextInput({ id, ...rest }: FormTextInputProps) {
  const [field, meta] = useField(id)

  return (
    <input
      data-testid={`form-input-${rest.name}`}
      id={id}
      {...field}
      {...rest}
      className={classNames(
        'rounded-base border-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size placeholder:text-neutral-medium-100 h-14 border border-solid p-4',
        {
          'border-sale text-sale': meta.error && meta.touched
        },
        rest.className
      )}
    />
  )
}
