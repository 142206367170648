import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  ContentfulRichTextSchema,
  GatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'

import { ImageColumn } from './ImageColumn'
import { TwoColumnImageBlurbsSchema } from './schema'

const getGatsbyImage = (imgData: GatsbyImageSchema) => (
  <GatsbyImage className="h-full w-full" image={imgData} objectFit="cover" />
)

export const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.HEADING_6]: (_, children) => {
      return <h6 className="mb-0 text-xs font-normal">{children}</h6>
    }
  }
}

const getContent = (richTextProps: ContentfulRichTextSchema) => (
  <div data-component="TwoColumnImageBlurbContent">
    <ContentfulRichText
      optionsCustom={descriptionCustomOptions}
      raw={richTextProps.raw}
    />
  </div>
)

export function TwoColumnImageBlurbs({
  leftSide,
  rightSide
}: TwoColumnImageBlurbsSchema) {
  return (
    <div className="md:flex md:justify-between md:gap-x-9">
      <div className="mb-10 flex-1 md:mb-0">
        {leftSide.image ? (
          <ImageColumn>{getGatsbyImage(leftSide.image)}</ImageColumn>
        ) : null}
        {getContent(leftSide.blurb)}
      </div>
      <div className="mb-10 flex-1 md:mb-0">
        {leftSide.image ? (
          <ImageColumn>{getGatsbyImage(rightSide.image)}</ImageColumn>
        ) : null}
        {getContent(rightSide.blurb)}
      </div>
    </div>
  )
}
