import { PRO_INSTALL } from '@ecomm/data-constants'
import { getOrElse, map } from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { useCartLineItems } from './useCartLineItems'

export const proInstallSku = [PRO_INSTALL]

export const useHasProInstall = () => {
  const lineItems = useCartLineItems()

  return pipe(
    lineItems,
    map(l => l.some(i => proInstallSku.includes(i.sku))),
    getOrElse(() => false)
  )
}
