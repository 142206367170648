import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

type PromoBadgeProps = {
  readonly className?: string
  readonly discountPercentage?: string | null
}

export default function PromoBadge({
  className = '',
  discountPercentage = ''
}: PromoBadgeProps) {
  const microCopy = useMicroCopy()

  return discountPercentage ? (
    <div
      className={classNames(
        'border-complementary-blue-100 prose-a:text-white flex items-center justify-center rounded-full border-2 border-solid text-center lg:h-52 lg:w-52',
        className
      )}
    >
      <Link
        className="bg-complementary-blue-100 m-1 flex h-48 w-48 cursor-pointer items-center justify-center rounded-full no-underline"
        to="/home-security-shop"
      >
        <div className="flex max-w-[8rem] flex-col justify-center">
          <span className="text-heading-3-size flex items-center justify-center gap-2">
            {discountPercentage}
            <span className="text-sm"> % Off</span>
          </span>
          <span className="mb-1 text-sm font-medium">
            {microCopy['floating-badge-pro-premium']}
          </span>
          <hr className="w-14" />
          <span className="text-xs">
            {microCopy['floating-badge-available']}
          </span>
        </div>
      </Link>
    </div>
  ) : null
}
