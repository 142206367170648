import { addAtomDebugLabel } from '@ecomm/utils'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

export const cartQuantityAtom = atomWithStorage('cart_quantity', 0)
addAtomDebugLabel(cartQuantityAtom, 'cart count')

export function useCartQuantity() {
  const [cartQuantity] = useAtom(cartQuantityAtom)

  return {
    cartQuantity
  }
}
