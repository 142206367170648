import React from 'react'

import { GatsbyImage, GatsbyImageSchema } from '../GatsbyImage'

type IllustrationProps = {
  readonly image: GatsbyImageSchema
}

export function Illustration({ image }: IllustrationProps) {
  return (
    <div
      className="relative mt-2 w-full max-w-3xl text-center"
      data-component="Illustration"
    >
      {image ? (
        <GatsbyImage className="w-full object-contain" image={image} />
      ) : null}
    </div>
  )
}
