import { buttonSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

const columnTextSchema = z.object({
  raw: z.string(),
  references: z.array(buttonSchema).optional()
})

export const twoColumnTextSchema = z.object({
  headline: z.string().nullish(),
  leftSide: columnTextSchema,
  rightSide: columnTextSchema,
  columnBorders: z.boolean(),
  contentful_id: z.string(),
  __typename: z.literal('ContentfulTwoColumnTextSection')
})

export type TwoColumnTextSchema = TypeOf<typeof twoColumnTextSchema>
