import { gql } from '@apollo/client/index.js'

export const CONFIDENCE_BAR_QUERY = gql`
query ConfidenceBarQuery($id: String! $preview: Boolean! $locale: String!){
  confidenceBar: json(
    id: $id,
    preview : $preview,
    locale : $locale
  ) {
    content
  }
}`
