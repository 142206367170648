//@ts-nocheck
import classNames from 'classnames'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import React, { FC, ReactNode } from 'react'

import * as css from './ComparisonTableRows.module.scss'

export type ComparisonTableRowsProps = {
  /** String that will be added for div id attribute */
  readonly componentId?: string
  /** Text above the table. */
  readonly description?: ReactNode
  /** Strings that will be added to the bottom of the table, includes plan prices. */
  readonly footers: readonly string[]
  /** Strings that will be added to the top of the table for plan names. */
  readonly headers: readonly string[]
  /** The list of ComparisonTableRow will be added here, pre-mapped over in ss-ecomm-frontend. */
  readonly rowsList: readonly ReactNode[]
  /** The button that will be added to the bottom of the table for monitoring plan.  */
  readonly footerPlan?: readonly ReactNode[]
  /** data-component attribute value. */
  readonly dataComponent?: string
}

const planTypes = [
  'footerFeatureText',
  'proPremiumFooterPlan',
  'proFooterPlan',
  'monitoringPlan'
]
const footerTypes = ['footerFeatureText', 'proPremiumFooter', 'proFooter']

/** @deprecated Do not use ss-react-components*/
const ComparisonTableRows: FC<ComparisonTableRowsProps> = ({
  componentId,
  dataComponent = ComparisonTableRows.name,
  description,
  footers,
  headers,
  rowsList,
  footerPlan
}: ComparisonTableRowsProps) => {
  return (
    <div
      className="mx-auto mb-8 px-0 text-center"
      data-component={dataComponent}
      id={componentId}
    >
      <div className={css.description}>{description}</div>

      <table
        className={classNames(
          'rounded-base md:bg-neutral-light-50 mx-auto w-full max-w-[1080px] border-0 md:table-fixed',
          css.comparisonTable
        )}
      >
        <thead className="p-0.5">
          <tr className="p-1">
            <th
              className="border-neutral-light-50 md:border-neutral-light-100 border-y-0 border-l-0 border-r border-solid p-0"
              rowSpan={1}
            ></th>
            {headers.map((header, i) => {
              return (
                <th
                  className="border-neutral-light-50 md:border-neutral-light-100 border-y-0 border-l-0 border-r border-solid px-1.5 py-3 text-xs font-medium md:w-1/4 md:text-lg md:font-bold md:last:border-r-0 lg:w-72 lg:text-2xl lg:font-light"
                  key={`tableHeader-${i}`}
                  rowSpan={1}
                >
                  {header}
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody className="m-0">{rowsList}</tbody>

        <tfoot className="p-1">
          <tr>
            {!isEmpty(footerPlan) && !isNil(footerPlan)
              ? footerPlan.map((buttonFooter, i) => {
                  const planType = planTypes[i]
                  return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
                    // @ts-expect-error
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    <td
                      className={classNames(
                        'border-neutral-light-50 md:border-neutral-light-100 border-b-0 border-l-0 border-r border-t border-solid px-1.5 py-3 text-xs md:px-4 md:text-2xl md:first:font-light md:last:border-r-0 lg:px-9',
                        css[planType]
                      )}
                      key={`planTableFooter-${i}`}
                    >
                      {buttonFooter}
                    </td>
                  )
                })
              : footers.map((footer, i) => {
                  const footerType = footerTypes[i]
                  return (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
                    // @ts-expect-error
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    <td
                      className={classNames(
                        'border-neutral-light-50 md:border-neutral-light-100 border-b-0 border-l-0 border-r border-t border-solid px-1.5 py-3 md:px-4 md:font-bold md:last:border-r-0 lg:px-9',
                        css[footerType]
                      )}
                      key={`tableFooter-${i}`}
                    >
                      {footer}
                    </td>
                  )
                })}
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default ComparisonTableRows
