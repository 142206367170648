import { partnerPageResponseSchema } from '../config/schema'

/**
 * Parses partner page data from Contentful merging template data with page data.
 */
export const parsePartnerPageData = (data: unknown) => {
  const { partnerPageTemplate: template, ...page } =
    partnerPageResponseSchema.parse(
      data &&
        typeof data === 'object' &&
        'contentfulPartnerPageV2' in data &&
        data.contentfulPartnerPageV2
    )

  // Since Contentful gives null values for optional fields we can't merge via Object spread
  return {
    ...page,
    header: template.header,
    footer: template.footer,
    pageTitle: page.pageTitle || template.pageTitle,
    heroBanner: page.heroBanner || template.heroBanner,
    heroText: page.heroText || template.heroText,
    heroImage: page.heroImage || template.heroImage,
    content: page.content.length ? page.content : template.content,
    showPartnerPackageAbsoluteDiscountAsRelative:
      page.showPartnerPackageAbsoluteDiscountAsRelative
  }
}
