import { useIsCurrentPromoLoading } from '@ecomm/promotions-hooks'
import { getChatAppId, getCookie, setCookie } from '@ecomm/shared-cookies'
import { Price } from '@ecomm/ss-react-components'
import { FloatingPromoWidget } from '@ecomm/ss-react-components'
import { useLocation } from '@reach/router'
import { prop } from '@simplisafe/ewok'
import { propOr } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import { selectPartnerBannerLoading } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  liftSelectProduct,
  selectTopBannerVisible
} from '@simplisafe/ss-ecomm-data/redux/select'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage } from 'gatsby-plugin-image'
import { lensProp, over } from 'ramda'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { ContentfulFloatingPromoWidget } from '../../../graphql'
import { formatDisplayPrice } from '../../commercetools/price'
import useDelayedRender from '../../hooks/useDelayedRender'
import useLiveChatAvailability from '../../hooks/useLiveChatAvailability'
import { trackAddToCartFloatingBannerEvent } from '../../util/analytics/addToCart'
import { capitalizeFirst, toButton } from '../../util/helper'
import ContentfulRichText from '../ContentfulRichText'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type FloatingPromoWidgetComponentProps = {
  readonly data: ContentfulFloatingPromoWidget
  readonly liveChatAppId?: string
}

export const COOKIE_FLOATING_PROMO_WIDGET_CLOSED =
  'floating_promo_widget_closed'

const renderPrice = (price?: string) => (
  <Price key={`total-price-${price}`} regularPrice={price} />
)

const renderBackdropImage = (data: ContentfulFloatingPromoWidget) => {
  const image = data?.backdropImage
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulAsset' is not assignab... Remove this comment to see the full error message
  return (
    image && <GatsbyImage image={getImage(image)} style={{ width: '100%' }} />
  )
}

function FloatingPromoWidgetComponent({
  data,
  liveChatAppId: chatIdProp
}: FloatingPromoWidgetComponentProps) {
  const isTopBannerVisible = useSelector(selectTopBannerVisible)
  const isActivePromoLoading = useIsCurrentPromoLoading()
  const isPartnerBannerLoading = useSelector(selectPartnerBannerLoading)
  const hasWidgetClosedCookie =
    getCookie(COOKIE_FLOATING_PROMO_WIDGET_CLOSED) === 'true'
  const chatIdCookie = getChatAppId()
  const chatId = chatIdCookie || chatIdProp || ''
  const hasAvailableAgents: boolean = useLiveChatAvailability(chatId)
  const [widgetClosed, setWidgetClosed] = useState(hasWidgetClosedCookie)
  const [showSuccess, setshowSuccess] = useState(false)
  const [showSpinner, setShowSpinner] = useState(true)
  const { Track, trackEvent } = useTracking()
  const delayedRender = useDelayedRender(1000)
  const description: string = data?.description?.raw || ''
  const thankYouNote: string = data?.thankYouNote?.raw || ''
  const title: string = safeProp('title', data).getOrElse('')
  // @ts-expect-error TS(2345) FIXME: Argument of type 'ContentfulButton' is not assigna... Remove this comment to see the full error message
  const button =
    safeProp('button', data)
      .map(over(lensProp('text'), capitalizeFirst))
      .orUndefined() || {}
  const location = useLocation()
  const currentUrlName = propOr('', 'pathname', location)

  const setMinimizedCookie = () =>
    setCookie(COOKIE_FLOATING_PROMO_WIDGET_CLOSED, 'true')

  const onClose = () => {
    setCookie(COOKIE_FLOATING_PROMO_WIDGET_CLOSED, 'true')
    setMinimizedCookie()
    setWidgetClosed(true)
  }

  const productId = safeProp('productId', data)
  const product = useSelector(liftSelectProduct(productId))

  // TODO move this to ecomm-data
  const productPrice: string = product.cata(
    () => '',
    p =>
      formatDisplayPrice(prop('price', p), {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }).orJust('')
  )

  const onClick = useCallback(() => {
    const handleSuccess = () => {
      setShowSpinner(false)
      setshowSuccess(true)
      setMinimizedCookie()
      trackAddToCartFloatingBannerEvent(product, trackEvent, 1)
    }

    handleSuccess()
  }, [product, trackEvent])

  // Don't show on BMS page when live chat is enabled to avoid overlapping widgets.
  const currentPageIsBuildSystem = currentUrlName.includes('build-my-system')
  const isNotLiveChatRestricted =
    !hasAvailableAgents || (hasAvailableAgents && !currentPageIsBuildSystem)

  // Don't show when a promo banner exists or is loading.
  const hasBanner =
    isTopBannerVisible || isActivePromoLoading || isPartnerBannerLoading

  const showWidget = !widgetClosed && !hasBanner && isNotLiveChatRestricted
  const content = <ContentfulRichText raw={description} />
  const thankYouNoteContent = <ContentfulRichText raw={thankYouNote} />

  return showWidget
    ? delayedRender(() => (
        <Track>
          <FloatingPromoWidget
            backdropImage={renderBackdropImage(data)}
            buttonProps={{
              ...toButton(button),
              showSpinner
            }}
            content={content}
            dataComponent={title}
            onApplyCoupon={onClick}
            onClick={onClick}
            onClose={onClose}
            price={renderPrice(productPrice)}
            showSuccess={showSuccess}
            thankYouNoteContent={thankYouNoteContent}
            title={title}
          />
        </Track>
      ))
    : null
}

export const floatingPromoWidgetQuery = graphql`
  #graphql
  fragment floatingPromoWidget on ContentfulFloatingPromoWidget {
    title
    backdropImage {
      title
      description
      gatsbyImageData(
        layout: CONSTRAINED
        width: 600
        quality: 90
        placeholder: BLURRED
      )
    }
    productId
    id
    description {
      raw
    }
    button {
      text
      type
      url
    }
    thankYouNote {
      raw
    }
  }
`

export default FloatingPromoWidgetComponent
