import { graphql, useStaticQuery } from 'gatsby'

import { buildMySystemBannerSchema } from './schema'

export const useBuildMySystemBannerQuery = () => {
  return buildMySystemBannerSchema.parse(
    useStaticQuery(graphql`
      query BuildMySystemBannerQuery {
        contentfulJson(contentful_id: { eq: "4Asr8sgVdKSS7HygY01Lcz" }) {
          content {
            internal {
              content
            }
          }
        }
      }
    `)
  )
}
