import { useField } from 'formik'
import React, { FC } from 'react'

export type FormRadioProps = {
  readonly checked?: boolean
  /** Label text that will be rendered right next to the checkbox */
  readonly label: string
  /** Field name, note that this prop is being used to wire the field to an specific formik field */
  readonly name: string
  readonly value: string
}
/** @deprecated Do not use ss-react-components*/
const FormRadio: FC<FormRadioProps> = ({
  label,
  name,
  value
}: FormRadioProps) => {
  const [field] = useField({
    name,
    type: 'radio',
    value: value
  })
  return (
    <div
      className="mx-auto my-3 flex w-full items-center justify-start"
      data-component="FormRadio"
    >
      <input
        className="checked:bg-neutral-black bg-neutral-light-50 border-neutral-medium-100 inline-block h-4 w-4 appearance-none rounded-full border border-solid bg-clip-content p-px "
        id={value}
        type="radio"
        {...field}
        name={name}
        role="radio"
        value={value}
      />
      <label className="w-full pl-2" htmlFor={value}>
        {label}
      </label>
    </div>
  )
}

export default FormRadio
