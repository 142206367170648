import { toZonedTime } from 'date-fns-tz'
import { useEffect, useState } from 'react'

function getAreExpertsAvailable() {
  const zonedDate = toZonedTime(new Date(), 'America/New_York')
  return zonedDate.getHours() >= 8 && zonedDate.getHours() < 24
}

/**
 * Returns whether experts are currently available via phone and chat according to a fixed schedule
 * (every day from 8:00am - Midnight ET). Updates every second.
 */
export function useAreExpertsAvailable() {
  const [areExpertsAvailable, setAreExpertsAvailable] = useState(
    getAreExpertsAvailable()
  )
  useEffect(() => {
    const interval = setInterval(() => {
      setAreExpertsAvailable(getAreExpertsAvailable())
    }, 1000)
    return () => clearInterval(interval)
  }, [setAreExpertsAvailable])

  return areExpertsAvailable
}
