import {
  BannerPhone,
  GuidedSystemBuilder,
  LinkComponent
} from '@ecomm/shared-components'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import type { PageProps } from 'gatsby'
import React from 'react'
// import ModalComponent from './components/ModalComponent'
// import RichTextWithButtonsComponent from './components/RichTextWithButtonsComponent'

import AccessoriesCardWrapper from './components/AccessoriesCardWrapper/index'

const AccessoriesCardComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AccessoriesCardComponent'
    )
)

// const AccessoriesCardWrapper = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "legacy" */
//       './components/AccessoriesCardWrapper'
//     )
// )

const AccordionGroup = React.lazy(
  () => import(/* webpackChunkName: "legacy" */ './components/AccordionGroup')
)
const AccordionItemComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/AccordionItemComponent'
    )
)
const ButtonComponent = React.lazy(
  () => import('./components/ButtonComponent' /* webpackChunkName: "legacy" */)
)

const ConditionalContent = React.lazy(
  () =>
    import('./components/ConditionalContent' /* webpackChunkName: "legacy" */)
)
const ContactUsForm = React.lazy(
  () => import('./components/ContactUsForm' /* webpackChunkName: "legacy" */)
)
const ContentCollectionComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentCollectionComponent'
    )
)
const ContentfulCardItemBanner = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulCardItemBanner'
    )
)

const ContentfulLocationGridComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ContentfulCrimeLocationGrid'
    )
)

const CrimeLocationBanner = React.lazy(
  () =>
    import('./components/CrimeLocationBanner' /* webpackChunkName: "legacy" */)
)
const CrimeLocationResults = React.lazy(
  () =>
    import('./components/CrimeLocationResults' /* webpackChunkName: "legacy" */)
)
const DeviceVariationsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/DeviceVariationsComponent'
    )
)

const ExpandableComponent = React.lazy(
  () =>
    import('./components/ExpandableComponent' /* webpackChunkName: "legacy" */)
)
const ExpandableMonitoringPlanComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ExpandableMonitoringPlanComponent'
    )
)

const GroupSection = React.lazy(
  () => import('./components/GroupSection' /* webpackChunkName: "legacy" */)
)
const HeadingComponent = React.lazy(
  () => import('./components/HeadingComponent' /* webpackChunkName: "legacy" */)
)

const HowItWorksInteractiveHouse = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/HowItWorksInteractiveHouse'
    )
)
const IconWithTextComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/IconWithTextComponent'
    )
)

const ImageWithArtDirection = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ImageWithArtDirection'
    )
)
const ImageWithFloatingBadgeComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ImageWithFloatingBadgeComponent'
    )
)
const ImageWithFocalPoint = React.lazy(
  () =>
    import('./components/ImageWithFocalPoint' /* webpackChunkName: "legacy" */)
)
const ImageWithOverlay = React.lazy(
  () => import('./components/ImageWithOverlay' /* webpackChunkName: "legacy" */)
)
const ItemContainerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ItemContainerComponent'
    )
)

const CommunicationsContent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/LiveChat/CommunicationsContent'
    )
)

const LiveChatTrigger = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/LiveChat/LiveChatTrigger'
    )
)

const SmsBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/LiveChat/SmsBlock'
    )
)

const ContentfulLocationBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/LocationBannerComponent'
    )
)
const Media = React.lazy(
  () => import('./components/Media' /* webpackChunkName: "legacy" */)
)
const ModalComponent = React.lazy(
  () => import('./components/ModalComponent' /* webpackChunkName: "legacy" */)
)
const OrderedListComponent = React.lazy(
  () =>
    import('./components/OrderedListComponent' /* webpackChunkName: "legacy" */)
)
const PackageCardGroupFinancingComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/PackageCardGroupFinancing'
    )
)

const PartnerWithUsForm = React.lazy(
  () =>
    import('./components/PartnerWithUsForm' /* webpackChunkName: "legacy" */)
)

const PartnerCaptureForm = React.lazy(
  () =>
    import('./components/PartnerCaptureForm' /* webpackChunkName: "legacy" */)
)

const PerfectSystemBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/PerfectSystemBannerComponent'
    )
)

const PlaceholderComponent = React.lazy(
  () =>
    import('./components/PlaceholderComponent' /* webpackChunkName: "legacy" */)
)

const PopupButton = React.lazy(
  () =>
    import('./components/PopupButtonComponent' /* webpackChunkName: "legacy" */)
)

const ProductPageHero = React.lazy(
  () => import('./components/ProductPageHero' /* webpackChunkName: "legacy" */)
)

const QuoteWizardWrapper = React.lazy(
  () =>
    import('./components/QuoteWizardWrapper' /* webpackChunkName: "legacy" */)
)

const ResponsiveContainerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ResponsiveContainerComponent'
    )
)
const RichTextWithButtonsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/RichTextWithButtonsComponent'
    )
)
const RichTextWithOptionsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/RichTextWithOptionsComponent'
    )
)

const ScoutHardcodedComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/ScoutHardcodedComponent'
    )
)

const SimpleBannerComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "legacy" */
      './components/SimpleBannerComponent'
    )
)

const SmallTextSection = React.lazy(
  () => import('./components/SmallTextSection' /* webpackChunkName: "legacy" */)
)

const TrustpilotBanner = React.lazy(
  () => import('./components/TrustpilotBanner' /* webpackChunkName: "legacy" */)
)
const TwoColumnBanner = React.lazy(
  () => import('./components/TwoColumnBanner' /* webpackChunkName: "legacy" */)
)

// A shared mapping of Contentful content types to component to be rendered
const componentMappings = {
  ContentfulAccordionGroup: AccordionGroup,
  ContentfulAccordion: AccordionItemComponent,
  ContentfulBannerPhone: BannerPhone,
  ContentfulBanner: SimpleBannerComponent,
  ContentfulButton: ButtonComponent,
  ContentfulCommunicationsContent: CommunicationsContent,
  ContentfulConditionalContent: ConditionalContent,
  ContentfulContactUsForm: ContactUsForm,
  ContentfulContentCollection: ContentCollectionComponent,
  ContentfulCrimeLocationBanner: CrimeLocationBanner,
  ContentfulCrimeLocationBannerWithAddressField:
    ContentfulLocationBannerComponent,
  ContentfulCrimeLocationGrid: ContentfulLocationGridComponent,
  ContentfulCrimeLocationResults: CrimeLocationResults,
  ContentfulDeviceVariations: DeviceVariationsComponent,
  ContentfulExpandable: ExpandableComponent,
  ContentfulExpandableMonitoringPlan: ExpandableMonitoringPlanComponent,
  ContentfulFindYourPerfectSystem: QuoteWizardWrapper,
  ContentfulGroupSection: GroupSection,
  ContentfulGuidedSystemBuilder: GuidedSystemBuilder,
  ContentfulHeading: HeadingComponent,
  ContentfulHowItWorksInteractiveHouse: HowItWorksInteractiveHouse,
  ContentfulIconWithText: IconWithTextComponent,
  ContentfulImage: Media,
  ContentfulImageWithArtDirection: ImageWithArtDirection,
  ContentfulImageWithFloatingBadge: ImageWithFloatingBadgeComponent,
  ContentfulImageWithFocalPoint: ImageWithFocalPoint,
  ContentfulLink: LinkComponent,
  ContentfulLiveChatTrigger: LiveChatTrigger,
  ContentfulModal: ModalComponent,
  ContentfulPartnerCaptureForm: PartnerCaptureForm,
  ContentfulPlaceholder: PlaceholderComponent,
  ContentfulPopupButton: PopupButton,
  // typo is in contentful
  ContentfulPrefectSystemBanner: PerfectSystemBannerComponent,
  ContentfulProductCard: ContentfulCardItemBanner,
  ContentfulProductCardAccessories: AccessoriesCardComponent,
  ContentfulAccessoriesCardWrapper: AccessoriesCardWrapper,
  ContentfulProductPageHeroV2: ProductPageHero,
  ContentfulResponsiveContainer: ResponsiveContainerComponent,
  ContentfulRichTextWithButtons: RichTextWithButtonsComponent,
  ContentfulRichTextWithOptions: RichTextWithOptionsComponent,
  ContentfulScoutPlaceholder: ScoutHardcodedComponent,
  ContentfulSmallTextSection: SmallTextSection,
  ContentfulSmsBlock: SmsBlock,
  ContentfulTwoColumn: TwoColumnBanner
}

// TODO: Update all mapped components to use this interface so they have a consistent set of standard props defined in one place
export type ComponentMappedProps = {
  readonly data?: ContentfulComponent
  readonly location?: PageProps['location']
  readonly pageContext?: PageProps['pageContext']
}

export type ContentfulComponent = {
  readonly id?: string
  readonly internal?: Partial<Internal>
}

type ComponentMappings = typeof componentMappings
type ComponentKeys = keyof ComponentMappings

function isComponentKey(
  key: ComponentKeys | string | undefined
): key is ComponentKeys {
  const keys = Object.keys(componentMappings)
  return keys.includes(key || '')
}

export function getMappedComponent<Props extends ComponentMappedProps>(
  contentfulComponent: ContentfulComponent
): React.FC<Props>
export function getMappedComponent<T>(
  contentfulComponent: ContentfulComponent
): React.FC<T>
export function getMappedComponent(contentfulComponent: ContentfulComponent) {
  const internalType = path(['internal', 'type'], contentfulComponent)
  return isComponentKey(internalType)
    ? prop(internalType, componentMappings)
    : null
}
