import { usePriceContext } from '@ecomm/data-price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useMemo } from 'react'

type AmountSavedWithServicePlanProps = {
  readonly sku: string
}

export const useAmountSavedWithServicePlanPackage = ({
  sku
}: AmountSavedWithServicePlanProps) => {
  const {
    getPrice: getPackagePrice,
    getDiscountedPriceWithServicePlan,
    getDiscountedPrice,
    formatPrice
  } = usePriceContext()
  const total = pipe(getPackagePrice(sku), O.toUndefined)

  const discountWithoutMonitoring = pipe(getDiscountedPrice(sku), O.toNullable)
  const discountWithMonitoring = pipe(
    getDiscountedPriceWithServicePlan(sku),
    O.toNullable
  )

  const partialDiscount =
    discountWithoutMonitoring &&
    discountWithMonitoring &&
    Math.abs(discountWithoutMonitoring - discountWithMonitoring)
  const discountDuringEvergreen =
    discountWithMonitoring && total ? total - discountWithMonitoring : 0
  const totalDiscount = partialDiscount || discountDuringEvergreen

  const amountSaved = useMemo(
    () => pipe(O.fromNullable(totalDiscount), formatPrice, O.toNullable),
    [totalDiscount, formatPrice]
  )

  return amountSaved
}
