import { OrderSummaryAccordion } from './OrderSummaryAccordion'
import { OrderSummaryList } from './OrderSummaryList'
import { OrderSummaryPrice } from './OrderSummaryPrice'
import type { CartLineItemProps, PriceCalculationFieldProps } from './types'

type Props = {
  readonly itemList: readonly CartLineItemProps[] | null
  readonly priceCalculationFields: readonly PriceCalculationFieldProps[]
}

export function OrderSummaryContent({
  itemList,
  priceCalculationFields
}: Props) {
  return (
    <div
      className="rounded-base bg-neutral-light-50 mx-auto mb-8 p-6 md:mb-16"
      data-component="OrderSummary"
    >
      <OrderSummaryAccordion
        hasDiscount={priceCalculationFields.some(
          lineItem => lineItem.isDiscount
        )}
        //@ts-expect-error
        itemCount={itemList.length}
      >
        <div>
          {itemList && !!itemList.length && (
            <OrderSummaryList itemList={itemList} />
          )}
        </div>
      </OrderSummaryAccordion>
      <div>
        <hr
          className={`border-neutral-medium-100 my-4 border border-b-0 border-solid`}
          data-component="Divider"
        />
        {priceCalculationFields.map((data, i) => (
          <OrderSummaryPrice
            couponCode={data.couponCode}
            isDiscount={data.isDiscount}
            isTotalLine={i === priceCalculationFields.length - 1}
            key={i}
            label={data.label}
            value={data.value}
          />
        ))}
      </div>
    </div>
  )
}
