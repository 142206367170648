import { gql } from '@apollo/client/index.js'

export const GUARANTEE_COPY_QUERY = gql`
query guaranteeCopyQuery($locale : String! $preview : Boolean!){
  guaranteeCopy: json(
    id: "5bXaWtcoGF04HVmWb5Kuoe",
    locale : $locale,
    preview : $preview
  ) {
    content
  }
}
`
