import classNames from 'classnames'
import React, { FC, ReactNode, useCallback, useState } from 'react'

import Modal, { SSModalProps } from '../Modal'
import SSButton, { SSButtonProps } from '../SSButton'

export type PopupButtonProps = {
  /** Content to show within the button. Passed as children to SSButton. */
  readonly buttonContent: ReactNode | string
  /** The position of where the button will display on the page. */
  readonly buttonPosition?: PopupButtonPosition
  /** Optional props (color, etc) for the button */
  readonly buttonProps?: Partial<Omit<SSButtonProps, 'children'>>
  /** Content to show within the modal */
  readonly modalContent: ReactNode
  // @deprecated: this prop is kept to for backwards compatibility and no effect on the component.
  readonly modalProps?: Partial<SSModalProps>
  /** data-component attribute value */
  readonly dataComponent?: string
}

export type PopupButtonPosition = 'center' | 'inline' | 'left' | 'right'

/** @deprecated Do not use ss-react-components*/
const PopupButton: FC<PopupButtonProps> = ({
  buttonContent,
  buttonPosition = 'left',
  buttonProps,
  modalContent,
  dataComponent = PopupButton.name
}: PopupButtonProps) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const onButtonClick = buttonProps && buttonProps.onClick

  const openModal = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setModalVisible(true)
      onButtonClick && onButtonClick(event)
    },
    [onButtonClick]
  )

  const closeModal = () => setModalVisible(false)

  return (
    <>
      <div
        className={classNames(
          { alignLeft: buttonPosition === 'left' },
          { alignCenter: buttonPosition === 'center' },
          { alignRight: buttonPosition === 'right' },
          { inlineBlock: buttonPosition === 'inline' }
        )}
        data-component={dataComponent}
      >
        <SSButton {...buttonProps} onClick={openModal}>
          {buttonContent}
        </SSButton>
      </div>

      <Modal isOpen={isModalVisible} onRequestClose={closeModal}>
        {modalContent}
      </Modal>
    </>
  )
}

export default PopupButton
