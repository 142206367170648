import { data } from './data'
import { monitoringPageVideoWrapperSchema } from './schema'

export function useMonitoringPageVideoWrapperData() {
  const { headline, body } = monitoringPageVideoWrapperSchema.parse(data)
  return {
    headline,
    body
  }
}
