import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { GatsbyImage, NewFlag } from '@ecomm/shared-components'
import {
  HeaderDropdown as HeaderDropdownAtom,
  HeaderDropdownItem as HeaderDropdownItemAtom,
  HeaderDropdownMultipleItems
} from '@ecomm/ss-react-components'
import { prop } from '@simplisafe/ewok'
import { Link } from 'gatsby'
import { Maybe } from 'monet'
import propOr from 'ramda/src/propOr'
import toLower from 'ramda/src/toLower'
import React from 'react'
import { useTracking } from 'react-tracking'

import { rewritePartnerShopUrl } from '../../util/helper'
import ContentfulVariationContainerComponent from '../ContentfulVariationContainerComponent'
import { ContentfulMenuItemsFragment, NavItem } from './query'

type HeaderDropdownItemProps = {
  readonly item?: NavItem
  readonly showImage: boolean
  readonly isMobile: boolean
  readonly parentLabel: string
}

export const HeaderDropdownItem = ({
  item,
  showImage,
  isMobile,
  parentLabel
}: HeaderDropdownItemProps) => {
  const { trackEvent } = useTracking()

  const trackClick = (label: string) => {
    trackEvent({
      label,
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation'
    })
    trackNavigationClick({
      action: 'click',
      navElement: label
    })
  }

  const contentsToGroup = item?.['contentsToGroup']

  const groupingType = item?.['groupingTypeDesktop']
  const renderLink = (link: NavItem) => {
    const linkText = link?.['linkText'] ?? ''

    const linkUrl = link?.['linkUrl'] ?? ''
    const id = `headerDropdownItem-${link?.id || linkText}`
    const url = rewritePartnerShopUrl(linkUrl)

    const linkSubtext = link?.['linkSubtext']

    const linkImage = link?.['linkImage']

    const displayNewFlag = link?.['displayNewFlag'] || false

    return (
      <Link
        key={id}
        onClick={() =>
          url &&
          trackClick(`${showImage ? '' : `${parentLabel} - `}${linkText}`)
        }
        state={{ source: 'header' }}
        style={{ textDecoration: 'inherit' }}
        to={url}
      >
        {showImage ? (
          <HeaderDropdownItemAtom
            image={
              linkImage ? (
                <GatsbyImage
                  image={linkImage}
                  imgStyle={{ objectFit: 'contain' }}
                  loading="eager"
                  style={{
                    display: 'block',
                    height: '100%',
                    objectPosition: 'center center',
                    width: '100%'
                  }}
                />
              ) : null
            }
            label={linkText}
            labelPosition={!isMobile ? 'right' : 'bottom'}
            newFlag={
              displayNewFlag ? (
                <NewFlag additionalClasses="z-10" hasBackground isNavItem />
              ) : null
            }
            subText={linkSubtext}
          />
        ) : null}
        {!showImage && linkText}
      </Link>
    )
  }

  const renderVariationContainer = data => {
    return (
      <ContentfulVariationContainerComponent
        data={data}
        renderVariant={variant => renderLink(variant)}
      />
    )
  }

  return Maybe.fromFalsy(contentsToGroup)
    .map(contents => (
      <HeaderDropdownMultipleItems
        key={prop('id', item)}
        position={Maybe.fromNull(groupingType)
          /* eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code, legacy code */
          .map(g => toLower(g) as 'column' | 'row')
          .orUndefined()}
      >
        {contents.map((link: any) => {
          return link?.__typename === 'ContentfulVariationContainer'
            ? renderVariationContainer(link)
            : renderLink(link)
        })}
      </HeaderDropdownMultipleItems>
    ))
    .getOrElse(renderLink(item))
}

export type HeaderDropdown = {
  readonly dropDown: ContentfulMenuItemsFragment
  readonly showImage: boolean
  readonly isMobile: boolean
}

export function HeaderDropdown({
  dropDown,
  showImage,
  isMobile
}: HeaderDropdown) {
  const id = prop('id', dropDown)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkText = propOr<string, string>('', 'linkText', dropDown)
  const subNav = prop('subNav', dropDown)

  return (
    <HeaderDropdownAtom
      id={id}
      key={id}
      // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
      label={linkText}
    >
      {/* @ts-expect-error TS(2322) FIXME: Type 'ContentfulGroupSection | ContentfulLinkFragm... Remove this comment to see the full error message */}
      {subNav
        ? subNav.map(item => (
            <HeaderDropdownItem
              isMobile={isMobile}
              item={item}
              key={`subnav-${item?.id || item.linkText}`}
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              parentLabel={linkText}
              showImage={showImage}
            />
          ))
        : null}
    </HeaderDropdownAtom>
  )
}
