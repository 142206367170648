import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.BurningFood,
    text: 'Ignores burning food'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 2 yrs'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.Siren,
    text: 'Built-in 85 dB siren'
  }
]

const description = {
  content: `A 2-in-1 solution for every home, the Smoke & Carbon Monoxide detector protects your family against potentially fatal hazards.
  \nThe smoke detector is engineered to sense the difference between dangerous smoke and burning food, avoiding annoying false alarms.
  \nWith the Fast Protect™ monitoring plan, emergency services will be dispatched automatically if dangerous CO levels are detected from household appliances.
  `
}

const specs = {
  content: `4.8 x 4.8 x 2 inches
  \n2 CR123A batteries (included)
  \nPhotoelectric smoke sensor and electromechanical carbon monoxide sensor
  \nComplies with all applicable UL safety standards (UL 217 for smoke and UL 2034
  for CO)
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '5GcxOjBpnfZMoGSIsaujZ9'
}

export const smokeCarbonMonoxideDetectorData: ProductData = {
  productId: 'UQSZpM6mQhQQDburuU0bp',
  sensorName: 'Smoke & Carbon Monoxide Detector',
  features,
  description,
  specs,
  faqs
}
