import { useMonthsOfFreeServiceText } from '@ecomm/promotions-hooks'

import {
  OptedInContent as OptedInContentDefault,
  OptedInContentODMON,
  OptedOutContent as OptedOutContentDefault,
  OptedOutContentODMON
} from './OptInOrOutContent'
import { DataToRender, ExperimentsData, UseDataToRenderProps } from './types'

export const useDataToRender = (props: UseDataToRenderProps): DataToRender => {
  const monthsOfServiceDisplay = useMonthsOfFreeServiceText(true)

  const defaultData = {
    claimBtnText: `Claim ${monthsOfServiceDisplay}`,
    removeBtnText: 'Remove',
    OptedInContent: OptedInContentDefault,
    OptedOutContent: OptedOutContentDefault
  }

  const experimentComponentsMap: ExperimentsData = {
    odmon: {
      ...defaultData,
      claimBtnText: 'Claim free month',
      OptedInContent: OptedInContentODMON,
      OptedOutContent: OptedOutContentODMON
    }
  }

  // We find the current experiment that is active
  const [experimentToRender] =
    Object.entries(props).find(
      ([experiment, value]) => !!experiment && value
    ) || []

  // Then we render the experiment or fallback to default components
  const dataToRender = !!experimentToRender
    ? experimentComponentsMap[experimentToRender]
    : defaultData

  return dataToRender
}
