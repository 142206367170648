import { IconProps } from '@ecomm/shared-icons'
import React from 'react'
import { TypeOf, z } from 'zod'

const productFeatureSchema = z.object({
  icon: z.custom<React.FC<IconProps>>(),
  text: z.string()
})

export type ProductFeature = TypeOf<typeof productFeatureSchema>

const productDescriptionSchema = z.object({
  content: z.string()
})

export type ProductDescription = TypeOf<typeof productDescriptionSchema>

const productSpecsSchema = z.object({
  content: z.string()
})

export type ProductSpecs = TypeOf<typeof productSpecsSchema>

const productFAQsSchema = z.object({
  contentfulId: z.string()
})

export type ProductFAQs = TypeOf<typeof productFAQsSchema>

export const productDataSchema = z.object({
  productId: z.string(),
  sensorName: z.string(),
  features: z.array(productFeatureSchema).optional(),
  description: productDescriptionSchema,
  specs: productSpecsSchema.optional(),
  faqs: productFAQsSchema.optional()
})

export type ProductData = TypeOf<typeof productDataSchema>
