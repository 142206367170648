import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { jsonSchema } from '@ecomm/micro-copy'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

export const twoColumnImagesSchema = z.object({
  __typename: z.literal('ContentfulTwoColumnImageSection'),
  leftSideImage: gatsbyImageSchema,
  rightSideImage: gatsbyImageSchema,
  variant: z.literal('dark').or(z.literal('light')).default('dark')
})

export const odmonMediaPageSchema = z.object({
  expertReviewsAssets: z.object({
    nodes: gatsbyImageSchema.extend({ contentful_id: z.string() }).array()
  }),
  quizImage: gatsbyImageSchema,
  theBeaconImage: gatsbyImageSchema,
  theLighthouseImage: gatsbyImageSchema,
  quoteWizardAsset: gatsbyImageSchema,
  quoteWizard: guidedSystemBuilderSchema,
  userReviews: jsonSchema.extend({ contentful_id: z.string() }),
  videoBanner: jsonSchema.extend({ contentful_id: z.string() }),
  expertReviews: jsonSchema.extend({ contentful_id: z.string() }),
  featureOverview: twoColumnImagesSchema,
  expertSectionAsset: gatsbyImageSchema
})

export type TwoColumnImagesSchema = TypeOf<typeof twoColumnImagesSchema>
export type OdmonMediaPageSchema = TypeOf<typeof odmonMediaPageSchema>
