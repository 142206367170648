import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { GatsbyImage } from '@ecomm/shared-components'
import { CartCount } from '@ecomm/ss-react-components'
import { Profile } from '@ecomm/ss-react-components'
import { prop } from '@simplisafe/ewok'
import { Link } from 'gatsby'
import propOr from 'ramda/src/propOr'
import React from 'react'
import { useTracking } from 'react-tracking'

import { ContentfulLinkFragment, NavItem } from './query'

type NavigationDrawerBottomItemProps = {
  readonly item: NavItem
  readonly cartItemCount: number
}
function NavigationDrawerBottomItem({
  item,
  cartItemCount
}: NavigationDrawerBottomItemProps) {
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkText = propOr<string, string>('', 'linkText', item)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkUrl = propOr<string, string>('', 'linkUrl', item)
  const id = prop('id', item) || prop('linkText', item)
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const linkIcon = propOr<
    ContentfulLinkFragment['linkIcon'],
    ContentfulLinkFragment['linkIcon']
  >({}, 'linkIcon', item)

  // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
  const isCart = linkUrl === '/cart'
  const icon = isCart ? (
    <CartCount count={cartItemCount} key={`cart-count-${cartItemCount}`} />
  ) : (
    <Profile />
  )

  const { trackEvent } = useTracking()
  const trackClick = (label: string) => {
    trackEvent({
      label,
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation'
    })
    trackNavigationClick({
      action: 'click',
      navElement: label
    })
  }

  const linkContent = (
    <>
      {linkIcon && !isCart ? (
        <GatsbyImage
          // @ts-expect-error TS(2560) FIXME: Value of type '<V>(p: string) => V' has no propert... Remove this comment to see the full error message
          image={linkIcon}
          loading="eager"
        />
      ) : null}
      {icon}
      {linkText}
    </>
  )

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    /(http|https):\/\/.*/.test(linkUrl) ? (
      <a
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
        href={linkUrl}
        key={id}
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
        onClick={() => trackClick(linkText)}
        style={{ textDecoration: 'inherit' }}
      >
        {linkContent}
      </a>
    ) : (
      <Link
        key={id}
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
        onClick={() => trackClick(linkText)}
        state={{ source: 'header' }}
        style={{ textDecoration: 'inherit' }}
        // @ts-expect-error TS(2345) FIXME: Argument of type '<V>(p: string) => V' is not assi... Remove this comment to see the full error message
        to={linkUrl}
      >
        {linkContent}
      </Link>
    )
  )
}

export default NavigationDrawerBottomItem
