import { ChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React, { ReactNode, useState } from 'react'

export type SensorCollapsibleSectionProps = {
  readonly title: string
  readonly children: ReactNode
  readonly withBackground?: boolean
  readonly isVisible?: boolean
}

export function SensorCollapsibleSection({
  title,
  children,
  withBackground = false,
  isVisible = false
}: SensorCollapsibleSectionProps) {
  const [isOpen, setOpen] = useState(isVisible)

  return (
    <section
      className={classNames('flex flex-col', {
        'border-neutral-light-100 my-4 border-0 border-t border-solid py-2':
          !withBackground,
        'bg-neutral-light-50 my-2 rounded-[20px] p-6': withBackground
      })}
    >
      <button
        aria-expanded={isOpen}
        aria-label={isOpen ? 'Collapse section' : 'Expand section'}
        className="min-w-[25px] border-none bg-transparent px-0 pt-1"
        onClick={() => setOpen(v => !v)}
      >
        <div className="flex cursor-pointer justify-between">
          <span
            className={classNames('pr-6 text-left font-medium', {
              'text-xl md:text-2xl': !withBackground,
              'text-md md:text-xl': withBackground
            })}
          >
            {title}
          </span>
          <div className="min-w-6">
            <ChevronDown
              className={classNames('h-6 w-6 transition-transform', {
                'rotate-180': isOpen
              })}
              titleA11y="Expand/Collapse Section"
            />
          </div>
        </div>
      </button>
      <div
        className={classNames(
          'mr-1 pt-3 text-left text-sm md:pt-4 md:text-base',
          { hidden: !isOpen }
        )}
      >
        <span
          className={classNames('[&>p]:m-0', {
            'whitespace-normal': withBackground,
            'whitespace-pre-line': !withBackground
          })}
        >
          {children}
        </span>
      </div>
    </section>
  )
}
