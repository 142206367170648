import { useOptimizelyExcluded, useVisitorId } from '@ecomm/data-storage'
import {
  UserAttributes,
  optimizelyClientAtom,
  variationAtom
} from '@ecomm/optimizely-utils'
import { pushToDataLayer } from '@ecomm/shared-window'
import { voidFn } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { Map as ImmutableMap } from 'immutable'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'

import { experimentKeyIsValid } from './lib/experimentKeyIsValid'
import { useUserAttributes } from './lib/useUserAttributes'

/**
 * Buckets and activates a user into Optimizely experiment.
 *
 * Optimized to only activate once if possible.
 *
 * Returns an [`Option<string>`, boolean].
 *
 * If `none` you should render `null` or a `<div><ReactSkeleton /><div>`
 *
 * The boolean is a value that becomes true after `useEffect` runs.
 * This can help with some render issues if the bucketing happens client side without SSR.
 *
 * @example
 * // prefer this approach
 *
 * import { pipe } from 'fp-ts/lib/function'
 * import * as O from 'fp-ts/lib/Option'\
 *
 * function Component() {
 *
 *   const [variation] = useOptimizelyActivate('experiment-key')
 *
 *   return pipe(
 *     variation,
 *     O.match(
 *       () => null // you could also use a loading skeleton,
 *       variation_id =>
 *        variation_id === "variation_1"
 *          ? <NewThing />
 *          : <Control />
 *     )
 *   )
 * }
 * @example
 * // do this if you have render issues
 *
 * import { pipe } from 'fp-ts/lib/function'
 * import * as O from 'fp-ts/lib/Option'\
 *
 * function Component() {
 *
 *   const [variation, isReady] = useOptimizelyActivate('experiment-key')
 *
 *   return pipe(
 *     variation,
 *     O.chain(v => isReady ? O.of(v) : O.none),
 *     O.match(
 *       () => null // you could also use a loading skeleton,
 *       variation_id =>
 *        variation_id === "variation_1"
 *          ? <NewThing />
 *          : <Control />
 *     )
 *   )
 * }
 */
export const useOptimizelyActivate = (
  experimentKey: string,
  attributes?: UserAttributes | undefined
): [O.Option<string>, boolean] => {
  const [variations, setVariations] = useAtom(variationAtom)

  const [variation, setVariation] = useState<O.Option<string>>(
    O.fromNullable(variations.get(experimentKey))
  )

  const [isReady, setIsReady] = useState(false)

  const [client] = useAtom(optimizelyClientAtom)

  const visitorId = useVisitorId() // we use the at-at token for the user id

  const userAttributes = useUserAttributes()

  const [isExcluded] = useOptimizelyExcluded()

  useEffect(() => {
    pipe(
      client,
      O.bindTo('c'),
      // We don't want to activate again if the experiment is already active
      O.chain(t => (!variations.get(experimentKey) ? O.of(t) : O.none)),
      O.bind('userId', () => visitorId),
      O.bind('_userAttributes', () => userAttributes),
      // Don't activate if the user is excluded
      O.bind('_isExcluded', () => (isExcluded ? O.none : O.of(isExcluded))),
      O.bind('_experimentKey', () =>
        experimentKeyIsValid(experimentKey) ? O.of(experimentKey) : O.none
      ),
      O.fold(voidFn, ({ c, userId, _userAttributes, _experimentKey }) => {
        pipe(
          c.activate(_experimentKey, userId, {
            ..._userAttributes,
            ...attributes
          }),
          O.fromNullable,
          O.fold(voidFn, _variation => {
            setVariations(ImmutableMap({ [_experimentKey]: _variation }))
            setVariation(O.of(_variation))
            // Push experiment data to GTM and FullStory
            pushToDataLayer({
              event: 'experimentActivation',
              experimentKey: _experimentKey,
              variation: _variation
            })()
          })
        )
      })
    )
  }, [client, experimentKey, attributes, visitorId, userAttributes, isExcluded])

  useEffect(() => {
    const _variation = variations.get(experimentKey)
    _variation && setVariation(O.fromNullable(_variation))
  }, [variations.get(experimentKey), experimentKey])

  useEffect(() => {
    setIsReady(true)
  }, [variation])

  return [isExcluded ? O.some('control') : variation, isReady]
}
