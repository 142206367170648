import { getUserId, getVisitorId } from '@ecomm/shared-cookies'

import { handleAtAtTrackingEvent } from '../atat'
import { handleGTMTrackingEvent } from '../gtm'
import type { TrackingData } from '../types/tracking'

// Handler for tracking calls for the current event and tracking data
export const handleTrackingEvent =
  (refreshCookieAtom: () => void) => (data: TrackingData) => {
    // Augment data from the event with values that can potentially change after page load
    const augmentedData: TrackingData = {
      ...data,
      userId: getUserId(),
      visitorId: getVisitorId()
    }

    handleGTMTrackingEvent(augmentedData)
    handleAtAtTrackingEvent(augmentedData, refreshCookieAtom)
  }
