import { fetchApi } from '@ecomm/data-simplisafe-api'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import { pipe } from 'fp-ts/lib/function'
import * as TE from 'fp-ts/lib/TaskEither'

export type ContactIdeaResponse = {
  readonly messageId: string
  readonly status: string
}

export type ContactIdeaPayload = {
  readonly name: string
  readonly email: string
  readonly locale: string
  readonly message: string
}

const isContactIdeaResponse = (res: unknown): res is ContactIdeaResponse => {
  polyfillHasOwn()
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'messageId') &&
    Object.hasOwn(res, 'status')
  )
}

export const contactIdeaCapture = (payload: ContactIdeaPayload) => {
  return pipe(
    TE.Do,
    TE.chain(() => {
      return fetchApi({
        method: 'POST',
        endpoint: '/leads/v1/contact/idea',
        headers: {},
        body: JSON.stringify(payload)
      })
    }),
    TE.chain(res =>
      isContactIdeaResponse(res) ? TE.right(res) : TE.left(res)
    ),
    TE.mapLeft(res => Error(`Error fetching contact idea method ${res}`))
  )
}
