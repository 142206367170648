import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { heroBannerSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

import { installationHowItWorksSectionSchema } from '../../components/Installation/HowItWorksSection/schema'
import { installationOptionsSectionSchema } from '../../components/Installation/InstallationOptionsSection/schema'
import { installationSmallTextSchema } from '../../components/Installation/SmallText/schema'
import { trustPilotSchema } from '../../schemas/trustPilot'

const faqSchema = z.object({
  __typename: z.literal('ContentfulFaq'),
  title: z.string(),
  primary: z
    .object({
      question: z.object({
        question: z.string()
      }),
      answer: contentfulRichTextSchema
    })
    .array()
})

const layoutSchema = z.object({
  promoBannerStyle: z.string(),
  components: z
    .union([
      heroBannerSchema,
      installationOptionsSectionSchema,
      installationSmallTextSchema,
      installationHowItWorksSectionSchema,
      faqSchema,
      trustPilotSchema
    ])
    .array(),
  footer: z.object({
    contentful_id: z.string()
  })
})

export const installationSchema = z.object({
  contentful_id: z.string(),
  breadcrumbTitle: z.string(),
  slug: z.string(),
  layout: layoutSchema
})

export const parseInstallationPage = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const installationPageFragment = query.contentfulGenericLandingPage

  const installationPageSchema = installationSchema.parse(
    installationPageFragment
  )

  return installationPageSchema
}

export type InstallationLayoutSchema = TypeOf<typeof layoutSchema>
