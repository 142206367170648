import type { OptInOrOutProps } from '../OptInOrOut/types'
import { OdmonMAPSCoreMonitoring } from './OdmonMAPSCoreMonitoring'
import { OdmonMAPSProMonitoring } from './OdmonMAPSProMonitoring'

type Props = {
  readonly hasOutdoorCamera: boolean
  readonly json: { readonly id: string; readonly tag: string }
  readonly showedOdmonIntent: boolean
}

export type OdmonDynamicPackagesExperienceProps = OptInOrOutProps & Props

export function OdmonMAPSDynamicTestContainer({
  amountSavedWithServicePlan,
  discountWithServicePlan,
  hasOutdoorCamera,
  json,
  priceToggles,
  showedOdmonIntent
}: OdmonDynamicPackagesExperienceProps) {
  const shouldShowProMonitoringInVariant = hasOutdoorCamera && showedOdmonIntent

  switch (json?.tag) {
    // This is the variant of the ODMON Dynamic Packages Experiment
    case 'variant-1':
      return shouldShowProMonitoringInVariant ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
    case 'control':
    default:
      return (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
  }
}
