//@ts-nocheck
import classNames from 'classnames'
import contains from 'ramda/src/contains'
import defaultTo from 'ramda/src/defaultTo'
import React, { FC, ReactElement, ReactNode } from 'react'

import { download, ifNil } from '../common'
import * as css from './Menu.module.scss'

export type MenuTheme =
  | 'dropdownlink'
  | 'imagelinks'
  | 'link'
  | 'shopButton'
  | 'subfooterlink'

export type MenuProps = {
  readonly name?: string
  readonly url?: string
  readonly src?: ReactElement | ReactNode
  readonly theme?: MenuTheme
  readonly alt?: string
  readonly children?: ReactNode
  readonly className?: string
  readonly subText?: string
  readonly onClick?: (menuItem: MenuItem) => void
  readonly onMouseEnter?: () => void
  readonly onMouseLeave?: () => void
  readonly id?: string
  readonly isDownload?: boolean
  readonly dataComponent?: string
  /** To identify where the given url should be opened */
  readonly target?: string
}

export type MenuItem = {
  readonly id?: string
  readonly name?: string
  readonly subText?: string
  readonly url?: string
}

/**
 * TODO This component will not be used by the new menu, since it will just use Gatsby Link
 */

/** @deprecated Do not use ss-react-components*/
const Menu: FC<MenuProps> = ({
  name,
  url = '',
  theme = 'link',
  children,
  className,
  id,
  onClick,
  onMouseEnter,
  onMouseLeave,
  subText,
  isDownload = false,
  target = '_self',
  dataComponent = Menu.name
}: MenuProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const classes = classNames(css[`${theme}`], 'bodyText', className)
  const content = children ? children : name
  const menuItem = {
    id: id,
    name: name,
    subText: subText,
    url: url
  }

  const handleDownloadClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    url: string
  ) => {
    e.preventDefault()
    download(url)
  }

  const toNavigate = (
    e: React.MouseEvent<HTMLAnchorElement>,
    menuItem: MenuItem,
    onClick: (menuItem: MenuItem) => void
  ) => {
    e.preventDefault()
    onClick(menuItem)
  }
  const isCallableLink = contains('tel:')(defaultTo('')(url))

  return isDownload ? (
    <a
      className={classes}
      data-component={dataComponent}
      href="#"
      onClick={e => handleDownloadClick(e, url)}
    >
      {content}
      {subText ? <span className="subTextContent">{subText}</span> : null}
    </a>
  ) : (
    <a
      aria-expanded={theme === 'dropdownlink' ? false : undefined}
      className={classes}
      data-component={dataComponent}
      download={isDownload}
      href={url || ''}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      onClick={e =>
        !isCallableLink && onClick && toNavigate(e, menuItem, ifNil(onClick))
      }
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseEnter}
      target={target}
    >
      {content}
      {subText ? <span className="subTextContent">{subText}</span> : null}
    </a>
  )
}

export default Menu
