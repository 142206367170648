import classNames from 'classnames'
import { withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

import { GatsbyImage } from '../GatsbyImage'
import { ImageWithArtDirectionSchema } from './schema'

export type ImageWithArtDirectionProps = {
  readonly className?: string
  readonly data: ImageWithArtDirectionSchema
}

export function ImageWithArtDirection({
  data,
  className = ''
}: ImageWithArtDirectionProps) {
  const title = data.desktopImage.title
  const description = data.desktopImage.description
  const { mobileImage, tabletImage, desktopImage } = data

  const imagesWithArtDirection = withArtDirection(
    desktopImage.gatsbyImageData,
    [
      {
        image: mobileImage.gatsbyImageData,
        media: '(max-width: 768px)'
      },
      {
        image: tabletImage.gatsbyImageData,
        media: '(max-width: 1200px)'
      },
      {
        image: desktopImage.gatsbyImageData,
        media: '(min-width: 1440px)'
      }
    ]
  )

  return (
    <GatsbyImage
      className={classNames('h-full', className)}
      image={{
        title,
        description,
        gatsbyImageData: imagesWithArtDirection
      }}
    />
  )
}
