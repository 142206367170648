import { useMicroCopy } from '@ecomm/micro-copy'
import { ChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'

type SensorSeeDetailsButtonProps = {
  readonly onProductModalOpenClose: (isOpen: boolean) => void
  readonly isProductModalOpen: boolean
}

export const SensorSeeDetailsButton = ({
  onProductModalOpenClose,
  isProductModalOpen
}: SensorSeeDetailsButtonProps) => {
  const microCopy = useMicroCopy()

  return (
    <button
      aria-expanded={isProductModalOpen}
      aria-label={
        isProductModalOpen
          ? microCopy['hide-details']
          : microCopy['see-details']
      }
      className="text-complementary-blue-100 capitalize cursor-pointer border-none bg-transparent p-0 text-xl md:text-lg"
      onClick={e => {
        e.preventDefault()
        onProductModalOpenClose(!isProductModalOpen)
      }}
    >
      <span>
        {isProductModalOpen
          ? microCopy['hide-details']
          : microCopy['see-details']}
        <ChevronDown
          aria-hidden="true"
          className={classNames(
            'h-5 w-5 align-sub transition-transform duration-200',
            {
              'rotate-90': isProductModalOpen,
              '-rotate-90': !isProductModalOpen
            }
          )}
        />
      </span>
    </button>
  )
}
