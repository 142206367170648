import { CancelIcon } from '@ecomm/checkout-icons'

interface Props {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly children: React.ReactNode
}

export function Modal({ isOpen, onClose, children }: Props) {
  return isOpen ? (
    <div className={'fixed inset-0 z-50 flex items-center justify-center'}>
      <div
        className="modal-overlay fixed inset-0 bg-gray-900 opacity-50"
        data-component="modal-overlay"
        onClick={onClose}
      ></div>
      <div
        className={
          'modal-container rounded-base left-1/2 top-1/2 z-50 mx-auto max-h-[90%] min-h-[100px] w-fit max-w-[min(90%,1440px)] overflow-y-auto overflow-x-hidden bg-white p-0 shadow-lg outline-none xl:max-w-[min(75%,1440px)]'
        }
      >
        <div className="relative">
          <div
            className="modal-close absolute right-0 top-0 cursor-pointer p-4"
            data-component="modal-close-button"
            onClick={onClose}
          >
            <span className="hidden">close</span>
            <CancelIcon className="text-neutral-medium-120" />
          </div>
          <div className="modal-content" data-component="modal-content">
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}
