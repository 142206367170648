//@ts-nocheck
import React, { FC, ReactNode } from 'react'

import Column from '../Column'
import Row from '../Row'
import * as css from './FooterTopSection.module.scss'

export type FooterTopSectionProps = {
  /** Icon/image component to be displayed alongside the text on one side of the component */
  readonly icon: ReactNode
  readonly productCard: ReactNode
  /** The content of the component */
  readonly content: ReactNode
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const FooterTopSection: FC<FooterTopSectionProps> = ({
  icon,
  productCard,
  content,
  dataComponent = FooterTopSection.name
}: FooterTopSectionProps) => {
  return (
    <Row
      backgroundColor="neutralBlack"
      dataComponent={dataComponent}
      padding="large"
      textColor="neutralWhite"
    >
      <Column spans={[12, 6, 6]}>
        <div className={css.footerTopSectionLeft}>
          <div className={css.leftIcon}>{icon}</div>
          <div className={css.leftText}>{content}</div>
        </div>
      </Column>
      <Column firstRow={true} spans={[12, 6, 6]}>
        {productCard}
      </Column>
    </Row>
  )
}

export default FooterTopSection
