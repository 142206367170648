import classNames from 'classnames'
import cond from 'ramda/src/cond'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import T from 'ramda/src/T'
import React, { FC, ReactNode } from 'react'

import { Text } from '..'
import useUniqueId from '../hooks/useUniqueId'
import { OptionItemHeaderObject } from './types'

const OptionItemHeader: FC<OptionItemHeaderObject> = ({
  groupName,
  item,
  isSelected,
  onChange,
  theme
}: OptionItemHeaderObject) => {
  const labelId = useUniqueId(`${OptionItemHeader.name}_label`)
  const applyOrangeText =
    !isNil(item.discount) && ['With Plan', 'SS2 Upgrade'].includes(item.skuId)
  const richTextWithPlaceholder = (inlineComp?: ReactNode, id?: string) => (
    <Text
      className={classNames({
        'prose-p:inline prose-p:text-sale prose-p:font-bold': applyOrangeText
      })}
      dataComponent="RichTextTitle"
      useTailwind
    >
      {item.titleContent ? (
        <div className="cursor-pointer text-left leading-4" id={id}>
          {item.titleContent}
          {!isNil(inlineComp) && inlineComp}
        </div>
      ) : null}
    </Text>
  )

  return (
    <Text
      className={classNames(
        'prose-p:!m-0 prose-p:text-sm md:prose-p:text-base flex items-center',
        {
          'md:order-0 border-neutral-light-100 mt-4 border border-solid p-4 first:mt-0 md:mt-0 md:w-1/2':
            theme === 'tab',
          'bg-neutral-light-50 md:border-b md:border-l-0 md:border-r-0 md:border-t-0':
            !isSelected && theme === 'tab',
          'bg-white': isSelected,
          'border-b-0': isSelected && theme === 'tab',
          'border-neutral-medium-100': !isSelected && theme === 'column',
          'border-white': isSelected && theme === 'column',
          hidden: !item.content && !item.inlineTitle
        }
      )}
      dataComponent={OptionItemHeader.name}
      onClick={onChange}
    >
      <input
        aria-labelledby={labelId}
        checked={isSelected}
        className="checked:bg-complementary-blue-100 bg-neutral-light-50 border-neutral-medium-100 mr-2 mt-0 inline-block h-4 w-4 cursor-pointer appearance-none rounded-full border border-solid bg-clip-content p-px"
        name={groupName}
        onChange={onChange}
        readOnly={!onChange}
        type="radio"
        value={item.skuId}
      />
      {cond([
        [
          () => !isEmpty(item.discount) && !isNil(item.discount),
          () =>
            richTextWithPlaceholder(
              <p className="empty:hidden md:ml-0 md:mt-0">
                {item.inlineTitle}
              </p>,
              labelId
            )
        ],
        [
          () => !isEmpty(item.inlineTitle),
          () => (
            <label
              className="flex cursor-pointer items-center text-left text-sm md:text-base"
              id={labelId}
            >
              {item.inlineTitle}
            </label>
          )
        ],
        [
          // will remove this condition once eliminated
          T,
          () => richTextWithPlaceholder(undefined, labelId)
        ]
      ])()}
    </Text>
  )
}

export default OptionItemHeader
