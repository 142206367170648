import classNames from 'classnames'

import type { PriceCalculationFieldProps } from '../types'

type OrderSummaryPriceProps = PriceCalculationFieldProps & {
  readonly isTotalLine?: boolean
}
export function OrderSummaryPrice({
  value,
  label,
  isDiscount,
  isTotalLine = false
}: OrderSummaryPriceProps) {
  return (
    <>
      {isTotalLine && (
        <hr
          className={`border-neutral-medium-100 my-4 border border-b-0 border-solid`}
          data-component="Divider"
        />
      )}
      <div
        className={classNames('my-2 justify-between md:my-1.5 ', {
          'mb-2 flex w-full': !!label
        })}
        key={`${label}${value}`}
      >
        <div
          className={classNames('text-base md:text-lg', {
            'font-bold': isTotalLine
          })}
        >
          {label}
        </div>
        <div
          className={classNames(
            'font-regular whitespace-nowrap pl-2 text-base md:text-lg',
            { 'text-sale': isDiscount, 'font-bold': isTotalLine }
          )}
        >
          {value}
        </div>
      </div>
    </>
  )
}
