import { chainProp } from '@simplisafe/monda/chain'
import { selectCurrentPromoOverrideDiscountText } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

type CurrentPromoOverrideData = {
  /** Example: '30% Plus an Extra 10%' */
  readonly chooseMonitoringPage?: string
  /** Example: 'EXTRA 10% OFF' */
  readonly discountSummary?: string
  /** Intended for the 2023 holiday cycle where the ends text was prefixed with a label. Example: 'Early Access' */
  readonly endsTextLabel?: string
  /** May be used to toggle between standard vs holiday banners during peak holiday dates. This can be removed unless wanted for 2024 holiday cycle. */
  readonly isHolidayBanner?: boolean
  /** Example: 'an extra 10%' */
  readonly listingFlag?: string
  /** Example: 'EXTRA 10% OFF' */
  readonly productFlag?: string
  /** Example: 'an extra 10%' */
  readonly toggleBox?: string
}

/**
 * Parses the JSON Override field on the Promotion content type.
 *
 * Intended to be used throughout the front-end where we normally display offer
 * details, so more context can be added for edge-cases such as an offer with
 * more stipulations, gifts, etc.
 *
 * It may be better to create these as permanent fields on the Promotion content type.
 */
export const useCurrentPromoOverrideData = (): CurrentPromoOverrideData => {
  const currentPromoOverrideData = useSelector(
    selectCurrentPromoOverrideDiscountText
  )

  return {
    chooseMonitoringPage: currentPromoOverrideData
      .chain(chainProp('chooseMonitoringPage'))
      .getOrElse(''),
    discountSummary: currentPromoOverrideData
      .chain(chainProp('discountSummary'))
      .getOrElse(''), // 'EXTRA 10% OFF'
    // begin holiday banner workflows.
    endsTextLabel: currentPromoOverrideData
      .chain(chainProp('endsTextLabel'))
      .getOrElse(''), // 'Early Access'
    isHolidayBanner: currentPromoOverrideData
      .chain(chainProp('isHolidayBanner'))
      .getOrElse(false), // Determines if HolidayPromoBanner should replace DeprecatedActivePromoBanner.
    // end holiday banner workflows.
    listingFlag: currentPromoOverrideData
      .chain(chainProp('listingFlag'))
      .getOrElse(''), // 'an extra 10%'
    productFlag: currentPromoOverrideData
      .chain(chainProp('productFlag'))
      .getOrElse(''), // 'EXTRA 10% OFF'
    toggleBox: currentPromoOverrideData
      .chain(chainProp('toggleBox'))
      .getOrElse('') // 'an extra 10%'
  }
}
