import React from 'react'

interface Props {
  readonly color?: string
}

export function Close({ color = '#a4a4a4' }: Props) {
  return (
    <svg
      height="24"
      style={{ marginTop: '2px', marginLeft: '5px' }}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-792 -210) translate(792 210)">
            <g transform="translate(2 2)">
              <circle cx="10" cy="10" r="10" stroke={color} strokeWidth="1.5" />
              <path
                d="M10.626 5L10.626 9.373 15 9.374 15 10.626 10.626 10.625 10.626 15 9.374 15 9.373 10.625 5 10.626 5 9.374 9.373 9.373 9.374 5z"
                fill={color}
                fillRule="nonzero"
                transform="rotate(45 10 10)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
