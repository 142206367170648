import { Warning } from '@ecomm/shared-icons'

export function WarningDisclaimer({
  children
}: {
  readonly children: React.ReactNode
}) {
  return (
    <div className="flex gap-2">
      <Warning className="h-6 w-6 shrink-0 text-primary-100" />
      <p className="mb-0 text-left text-sm font-normal text-neutral-black">
        {children}
      </p>
    </div>
  )
}
