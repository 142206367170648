import { Cancel } from '@ecomm/shared-icons'
import { getShippingEstimate } from '@ecomm/utils'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'

import { ProductFragment } from '../types'

type Props = {
  readonly items: readonly ProductFragment[]
  readonly message: string
  readonly backorderDate: string
}

function OutOfStock({ items, message, backorderDate }: Props) {
  // Wrap it in a set to avoid duplicates
  const verboseItems = Array.from(new Set(items))
    // Use plural names if there's more than one of a given included item
    .map(item =>
      items.filter(i => item.sku === i.sku).length > 1 && item.namePlural
        ? item.namePlural
        : item.name
    )
    .join(', ')
    .replace(/,([^,]*)$/, ' and$1')

  const [showLearnMore, setShowLearnMore] = useState(false)
  const description = message.replace('{{products}}', verboseItems)

  const [shippingEstimate, displayOutOfStockMessage] = useMemo(() => {
    return getShippingEstimate(backorderDate)
  }, [backorderDate])

  return displayOutOfStockMessage ? (
    <p className={'mb-0 font-medium'} data-component="BackorderMessage">
      <span className="text-xs lg:text-lg">Ships in {shippingEstimate}.</span>
      {description ? (
        <div
          className="inline-block relative"
          data-component="OutOfStockMessage"
        >
          <button
            className="underline cursor-pointer text-xs md:text-lg border-none bg-transparent h-fit w-fit hover:no-underline"
            onClick={() => setShowLearnMore(true)}
          >
            Learn more
          </button>
          <div
            className={classNames([
              'bg-white text-left transition-all duration-200 ease-in-out border border-solid border-[#bfbec1] rounded-sm p-4 absolute -right-[5px] -top-[140px] w-[200px] prose-p:text-xs md:prose-p:text-sm md:-right-[30px] md:-top-32 md:w-[230px] z-[15]',
              {
                'visible opacity-100': showLearnMore,
                'opacity-0 invisible': !showLearnMore
              }
            ])}
          >
            <button
              aria-label="Close"
              className="h-5 absolute right-1.5 top-1.5 w-5 p-0 cursor-pointer border-none bg-transparent flex items-center justify-center"
              onClick={() => setShowLearnMore(false)}
            >
              <Cancel className="h-5 w-5" />
            </button>
            <p>{description}</p>
          </div>
        </div>
      ) : null}
    </p>
  ) : null
}

export default OutOfStock
