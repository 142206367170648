import { getPartnerName } from '@ecomm/shared-cookies'
import {
  useNinetailedImpactedEvent,
  useOdmonDynamicPackagesExperience
} from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'

import type { OdmonFeatureFlagDraftSchema } from '../../../experiments/ODMONPrelaunch/schema'
import type { PackageMonitoringWrapperProps } from '../PackageMonitoringWrapper'
import type { DynamicPackageMonitoringWrapperProps } from '../DynamicPackageMonitoringWrapper'
import { useAmountSavedWithServicePlanBMS } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanBMS'
import { useAmountSavedWithServicePlanPackage } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanPackage'
import { useDiscountWithServicePlan } from '../../../hooks/IMAPS/useDiscountWithServicePlan'
import { DraftCartMonitoringWrapper } from '../DraftCartMonitoringWrapper'
import { PackageMonitoringWrapper } from '../PackageMonitoringWrapper'
import { DynamicPackageMonitoringWrapper } from '../DynamicPackageMonitoringWrapper'
import type { PriceToggleProps } from '../types'
import { OdmonMAPSCoreMonitoring } from './OdmonMAPSCoreMonitoring'
import { OdmonMAPSDynamicTestContainer } from './OdmonMAPSDynamicTestContainer'
import { OdmonMAPSProMonitoring } from './OdmonMAPSProMonitoring'

type OdmonHasOutdoorCameraProp = {
  readonly hasOutdoorCamera: boolean
}
type OdmonIntentProps = {
  readonly showedOdmonIntent: boolean
  readonly quizBreakIns: boolean
  readonly quizPackageTheft: boolean
}
type OdmonDraftProps = OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  PriceToggleProps
type OdmonDynamicPackageProps = DynamicPackageMonitoringWrapperProps &
  OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  OdmonIntentProps
type OdmonPackageProps = OdmonFeatureFlagDraftSchema &
  OdmonHasOutdoorCameraProp &
  PackageMonitoringWrapperProps

export function OdmonMonitoringDraftCart({
  hasOutdoorCamera,
  json,
  priceToggles,
  sku,
  total
}: OdmonDraftProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS()

  const partnerName = getPartnerName() || ''
  const isCydcorPartner = partnerName.substring(0, 6) === 'cydcor'

  const shouldShowProInVariant = hasOutdoorCamera && !isCydcorPartner

  switch (json?.tag) {
    case 'variant-1':
      return shouldShowProInVariant ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
    case 'control':
    default:
      return (
        <DraftCartMonitoringWrapper
          priceToggles={priceToggles}
          sku={sku}
          total={total}
        />
      )
  }
}

export function OdmonMonitoringDynamic({
  hasOutdoorCamera,
  json,
  priceToggles,
  proSetup,
  quizBreakIns,
  quizPackageTheft,
  showProsetupHighlightedComponent,
  showedOdmonIntent,
  sku,
  total
}: OdmonDynamicPackageProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS(total)
  const odmonDynamicPackagesExperience = useOdmonDynamicPackagesExperience()
  const odmonDynamicTestObserver = useNinetailedImpactedEvent({
    baseline: odmonDynamicPackagesExperience.baseline,
    experiences: odmonDynamicPackagesExperience.experiences,
    name: 'nt_impacted_event',
    event: {
      hasOutdoorCamera,
      showedOdmonIntent,
      quizBreakIns,
      quizPackageTheft
    },
    shouldReset: (e1, e2) => e1.hasOutdoorCamera !== e2.hasOutdoorCamera
  })

  switch (json?.tag) {
    case 'variant-1':
      return (
        <div>
          {odmonDynamicTestObserver}
          <Experience
            {...odmonDynamicPackagesExperience.data}
            component={OdmonMAPSDynamicTestContainer}
            experiences={odmonDynamicPackagesExperience.experiences}
            passthroughProps={{
              amountSavedWithServicePlan,
              discountWithServicePlan,
              hasOutdoorCamera,
              priceToggles,
              showedOdmonIntent
            }}
          />
        </div>
      )
    case 'control':
    default:
      return (
        <DynamicPackageMonitoringWrapper
          priceToggles={priceToggles}
          proSetup={proSetup}
          showProsetupHighlightedComponent={showProsetupHighlightedComponent}
          sku={sku}
          total={total}
        />
      )
  }
}

export function OdmonMonitoringPackage({
  hasOutdoorCamera,
  json,
  priceToggles,
  proSetup,
  showProsetupHighlightedComponent,
  sku
}: OdmonPackageProps) {
  const { discountWithServicePlan } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanPackage({
    sku: sku || ''
  })

  switch (json?.tag) {
    case 'variant-1':
      return hasOutdoorCamera ? (
        <OdmonMAPSProMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      ) : (
        <OdmonMAPSCoreMonitoring
          amountSavedWithServicePlan={amountSavedWithServicePlan}
          discountWithServicePlan={discountWithServicePlan}
          priceToggles={priceToggles}
        />
      )
    case 'control':
    default:
      return (
        <PackageMonitoringWrapper
          priceToggles={priceToggles}
          proSetup={proSetup}
          showProsetupHighlightedComponent={showProsetupHighlightedComponent}
          sku={sku}
        />
      )
  }
}
