type State = {
  readonly name: string
  readonly abbreviation: string
  readonly capital: string
}

const states: readonly State[] = [
  {
    abbreviation: 'AL',
    capital: 'Montgomery',
    name: 'Alabama'
  },
  {
    abbreviation: 'AK',
    capital: 'Juneau',
    name: 'Alaska'
  },
  {
    abbreviation: 'AS',
    capital: 'Pago Pago',
    name: 'American Samoa'
  },
  {
    abbreviation: 'AZ',
    capital: 'Phoenix',
    name: 'Arizona'
  },
  {
    abbreviation: 'AR',
    capital: 'Little Rock',
    name: 'Arkansas'
  },
  {
    abbreviation: 'CA',
    capital: 'Sacramento',
    name: 'California'
  },
  {
    abbreviation: 'CO',
    capital: 'Denver',
    name: 'Colorado'
  },
  {
    abbreviation: 'CT',
    capital: 'Hartford',
    name: 'Connecticut'
  },
  {
    abbreviation: 'DE',
    capital: 'Dover',
    name: 'Delaware'
  },
  {
    abbreviation: 'DC',
    capital: '',
    name: 'District Of Columbia'
  },
  {
    abbreviation: 'FM',
    capital: 'Palikir',
    name: 'Federated States Of Micronesia'
  },
  {
    abbreviation: 'FL',
    capital: 'Tallahassee',
    name: 'Florida'
  },
  {
    abbreviation: 'GA',
    capital: 'Atlanta',
    name: 'Georgia'
  },
  {
    abbreviation: 'GU',
    capital: 'Hagåtña',
    name: 'Guam'
  },
  {
    abbreviation: 'HI',
    capital: 'Honolulu',
    name: 'Hawaii'
  },
  {
    abbreviation: 'ID',
    capital: 'Boise',
    name: 'Idaho'
  },
  {
    abbreviation: 'IL',
    capital: 'Springfield',
    name: 'Illinois'
  },
  {
    abbreviation: 'IN',
    capital: 'Indianapolis',
    name: 'Indiana'
  },
  {
    abbreviation: 'IA',
    capital: 'Des Moines',
    name: 'Iowa'
  },
  {
    abbreviation: 'KS',
    capital: 'Topeka',
    name: 'Kansas'
  },
  {
    abbreviation: 'KY',
    capital: 'Frankfort',
    name: 'Kentucky'
  },
  {
    abbreviation: 'LA',
    capital: 'Baton Rouge',
    name: 'Louisiana'
  },
  {
    abbreviation: 'ME',
    capital: 'Augusta',
    name: 'Maine'
  },
  {
    abbreviation: 'MH',
    capital: 'Majuro',
    name: 'Marshall Islands'
  },
  {
    abbreviation: 'MD',
    capital: 'Annapolis',
    name: 'Maryland'
  },
  {
    abbreviation: 'MA',
    capital: 'Boston',
    name: 'Massachusetts'
  },
  {
    abbreviation: 'MI',
    capital: 'Lansing',
    name: 'Michigan'
  },
  {
    abbreviation: 'MN',
    capital: 'Saint Paul',
    name: 'Minnesota'
  },
  {
    abbreviation: 'MS',
    capital: 'Jackson',
    name: 'Mississippi'
  },
  {
    abbreviation: 'MO',
    capital: 'Jefferson City',
    name: 'Missouri'
  },
  {
    abbreviation: 'MT',
    capital: 'Helena',
    name: 'Montana'
  },
  {
    abbreviation: 'NE',
    capital: 'Lincoln',
    name: 'Nebraska'
  },
  {
    abbreviation: 'NV',
    capital: 'Carson City',
    name: 'Nevada'
  },
  {
    abbreviation: 'NH',
    capital: 'Concord',
    name: 'New Hampshire'
  },
  {
    abbreviation: 'NJ',
    capital: 'Trenton',
    name: 'New Jersey'
  },
  {
    abbreviation: 'NM',
    capital: 'Santa Fe',
    name: 'New Mexico'
  },
  {
    abbreviation: 'NY',
    capital: 'Albany',
    name: 'New York'
  },
  {
    abbreviation: 'NC',
    capital: 'Raleigh',
    name: 'North Carolina'
  },
  {
    abbreviation: 'ND',
    capital: 'Bismarck',
    name: 'North Dakota'
  },
  {
    abbreviation: 'MP',
    capital: 'Saipan',
    name: 'Northern Mariana Islands'
  },
  {
    abbreviation: 'OH',
    capital: 'Columbus',
    name: 'Ohio'
  },
  {
    abbreviation: 'OK',
    capital: 'Oklahoma City',
    name: 'Oklahoma'
  },
  {
    abbreviation: 'OR',
    capital: 'Salem',
    name: 'Oregon'
  },
  {
    abbreviation: 'PW',
    capital: 'Ngerulmud',
    name: 'Palau'
  },
  {
    abbreviation: 'PA',
    capital: 'Harrisburg',
    name: 'Pennsylvania'
  },
  {
    abbreviation: 'PR',
    capital: 'San Juan',
    name: 'Puerto Rico'
  },
  {
    abbreviation: 'RI',
    capital: 'Providence',
    name: 'Rhode Island'
  },
  {
    abbreviation: 'SC',
    capital: 'Columbia',
    name: 'South Carolina'
  },
  {
    abbreviation: 'SD',
    capital: 'Pierre',
    name: 'South Dakota'
  },
  {
    abbreviation: 'TN',
    capital: 'Nashville',
    name: 'Tennessee'
  },
  {
    abbreviation: 'TX',
    capital: 'Austin',
    name: 'Texas'
  },
  {
    abbreviation: 'UT',
    capital: 'Salt Lake City',
    name: 'Utah'
  },
  {
    abbreviation: 'VT',
    capital: 'Montpelier',
    name: 'Vermont'
  },
  {
    abbreviation: 'VI',
    capital: 'Charlotte Amalie',
    name: 'Virgin Islands'
  },
  {
    abbreviation: 'VA',
    capital: 'Richmond',
    name: 'Virginia'
  },
  {
    abbreviation: 'WA',
    capital: 'Olympia',
    name: 'Washington'
  },
  {
    abbreviation: 'WV',
    capital: 'Charleston',
    name: 'West Virginia'
  },
  {
    abbreviation: 'WI',
    capital: 'Madison',
    name: 'Wisconsin'
  },
  {
    abbreviation: 'WY',
    capital: 'Cheyenne',
    name: 'Wyoming'
  }
]

export default states
