import { Locale } from '../../hooks/useLocalizedData'

const scoutSensorImage = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/1L1k6lh2rrOeoLTGOOOwcb/19c8b69e1c484b14ada226c3518701e6/ImgGallery_Scout-main.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/1L1k6lh2rrOeoLTGOOOwcb/19c8b69e1c484b14ada226c3518701e6/ImgGallery_Scout-main.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/1L1k6lh2rrOeoLTGOOOwcb/19c8b69e1c484b14ada226c3518701e6/ImgGallery_Scout-main.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/1L1k6lh2rrOeoLTGOOOwcb/19c8b69e1c484b14ada226c3518701e6/ImgGallery_Scout-main.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/br4ichkdqihc/1L1k6lh2rrOeoLTGOOOwcb/19c8b69e1c484b14ada226c3518701e6/ImgGallery_Scout-main.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/1L1k6lh2rrOeoLTGOOOwcb/19c8b69e1c484b14ada226c3518701e6/ImgGallery_Scout-main.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/1L1k6lh2rrOeoLTGOOOwcb/19c8b69e1c484b14ada226c3518701e6/ImgGallery_Scout-main.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAABQIDBP/EAB8QAAICAQQDAAAAAAAAAAAAAAECAxEABBIhMQUTgf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A3eMp5YweQzAHCGlnDR6qVSEEYrZV31zeIWg8ZIJpI0ilRhQ2sDmJWiOqm9su5CPmatCAeSu8NT//2Q=='
    }
  },
  description: 'Scout Sensor'
}

const scoutCompactSize = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/5XTzQDxpTzp6kvomsQlAM1/9402372d8702012c3b4c829b1d38df03/ImgGallery_Scout-4.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/5XTzQDxpTzp6kvomsQlAM1/9402372d8702012c3b4c829b1d38df03/ImgGallery_Scout-4.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/5XTzQDxpTzp6kvomsQlAM1/9402372d8702012c3b4c829b1d38df03/ImgGallery_Scout-4.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/5XTzQDxpTzp6kvomsQlAM1/9402372d8702012c3b4c829b1d38df03/ImgGallery_Scout-4.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/br4ichkdqihc/5XTzQDxpTzp6kvomsQlAM1/9402372d8702012c3b4c829b1d38df03/ImgGallery_Scout-4.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/5XTzQDxpTzp6kvomsQlAM1/9402372d8702012c3b4c829b1d38df03/ImgGallery_Scout-4.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/5XTzQDxpTzp6kvomsQlAM1/9402372d8702012c3b4c829b1d38df03/ImgGallery_Scout-4.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQG/8QAIhAAAgEDBAIDAAAAAAAAAAAAAQIDABESBBMhMQVxFEGB/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//EABkRAAMBAQEAAAAAAAAAAAAAAAABESESAv/aAAwDAQACEQMRAD8A0DyEzFQedywv+1ndhcwZDaQqe+qskUNxTAkkEKyNIzuAHLdDg1PKTo+sgL+W0y5SpHPMcgMFUXP1xST8tk9ZCrRaj5UJfYmhs1sZkxPv1WjUC0//2Q=='
    }
  },
  description: 'Scout Compact size'
}

const scoutEasyInstall = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/7JIpG9nYEjaOPn1dV7kFrE/994c2b4ca70cc5ec8b47195b5d4f8b75/ImgGallery_Scout-2.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/7JIpG9nYEjaOPn1dV7kFrE/994c2b4ca70cc5ec8b47195b5d4f8b75/ImgGallery_Scout-2.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/7JIpG9nYEjaOPn1dV7kFrE/994c2b4ca70cc5ec8b47195b5d4f8b75/ImgGallery_Scout-2.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/7JIpG9nYEjaOPn1dV7kFrE/994c2b4ca70cc5ec8b47195b5d4f8b75/ImgGallery_Scout-2.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/br4ichkdqihc/7JIpG9nYEjaOPn1dV7kFrE/994c2b4ca70cc5ec8b47195b5d4f8b75/ImgGallery_Scout-2.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/7JIpG9nYEjaOPn1dV7kFrE/994c2b4ca70cc5ec8b47195b5d4f8b75/ImgGallery_Scout-2.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/7JIpG9nYEjaOPn1dV7kFrE/994c2b4ca70cc5ec8b47195b5d4f8b75/ImgGallery_Scout-2.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAQUCAwT/xAAjEAACAQMDBAMAAAAAAAAAAAABAgMAERMhMUEFEiJRMmKh/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgMAAf/EABsRAQEBAQADAQAAAAAAAAAAAAECABIRIjEh/9oADAMBAAIRAxEAPwCHS4+4ZgAybAXtrSYSeX7pFHXWtkmshgXtU3uC7WAHF6HS+pq8h7VhPJlcGPCgCgfLc+9BTB06Rcr6TO07zFICxQBmYNYcgb6c1yoSDl+6pQ0tbepEKzzYJ5HIMa+ANrrvofZ/KMyfDa6X9cjmnZZCMUun1pr4wJ87/9k='
    }
  },
  description: 'Scout Easy Install'
}

const scoutFastCharging = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/1Hagd5vvMT1pmiqKMPMWZH/05da01d2264989c12bff33199bf72f23/ImgGallery_Scout-5.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/1Hagd5vvMT1pmiqKMPMWZH/05da01d2264989c12bff33199bf72f23/ImgGallery_Scout-5.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/1Hagd5vvMT1pmiqKMPMWZH/05da01d2264989c12bff33199bf72f23/ImgGallery_Scout-5.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/1Hagd5vvMT1pmiqKMPMWZH/05da01d2264989c12bff33199bf72f23/ImgGallery_Scout-5.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/br4ichkdqihc/1Hagd5vvMT1pmiqKMPMWZH/05da01d2264989c12bff33199bf72f23/ImgGallery_Scout-5.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/1Hagd5vvMT1pmiqKMPMWZH/05da01d2264989c12bff33199bf72f23/ImgGallery_Scout-5.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/1Hagd5vvMT1pmiqKMPMWZH/05da01d2264989c12bff33199bf72f23/ImgGallery_Scout-5.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAABQMEBv/EACAQAAIBBAMBAQEAAAAAAAAAAAECAwAEETEFEiFBMmH/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/8QAGBEBAQEBAQAAAAAAAAAAAAAAAAERMQP/2gAMAwEAAhEDEQA/ADbbjOl2uXM0SjYHX2sp5w+NJZSQvKsCuVlOkIzqrwaSDEZAK+f3VMArfjAUwsrqp+VUoxNFYpx0yXixzTvGThUXsfRjQxR2lxYtGkuFeQpLES35kj6nQ+ZqbMOXX//Z'
    }
  },
  description: 'Scout Fast Charging'
}

const scoutMountingOptions = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/4SDy45BYqXvfq36mPrkCaB/b4c641f65c6da67ab6613db684640f40/ImgGallery_Scout-3.jpg?w=492&h=388&q=50&fm=webp 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/4SDy45BYqXvfq36mPrkCaB/b4c641f65c6da67ab6613db684640f40/ImgGallery_Scout-3.jpg?w=984&h=776&q=50&fm=webp 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/4SDy45BYqXvfq36mPrkCaB/b4c641f65c6da67ab6613db684640f40/ImgGallery_Scout-3.jpg?w=1968&h=1552&q=50&fm=webp 1968w',
          sizes: '(min-width: 1968px) 1968px, 100vw',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/4SDy45BYqXvfq36mPrkCaB/b4c641f65c6da67ab6613db684640f40/ImgGallery_Scout-3.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg',
        srcSet:
          'https://images.ctfassets.net/br4ichkdqihc/4SDy45BYqXvfq36mPrkCaB/b4c641f65c6da67ab6613db684640f40/ImgGallery_Scout-3.jpg?w=492&h=388&fl=progressive&q=50&fm=jpg 492w,\nhttps://images.ctfassets.net/br4ichkdqihc/4SDy45BYqXvfq36mPrkCaB/b4c641f65c6da67ab6613db684640f40/ImgGallery_Scout-3.jpg?w=984&h=776&fl=progressive&q=50&fm=jpg 984w,\nhttps://images.ctfassets.net/br4ichkdqihc/4SDy45BYqXvfq36mPrkCaB/b4c641f65c6da67ab6613db684640f40/ImgGallery_Scout-3.jpg?w=1968&h=1552&fl=progressive&q=50&fm=jpg 1968w',
        sizes: '(min-width: 1968px) 1968px, 100vw'
      }
    },
    layout: 'constrained',
    width: 1968,
    height: 1552,
    placeholder: {
      fallback:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAkACQAAD/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQMG/8QAIRAAAQQCAQUBAAAAAAAAAAAAAQIDBBEAEhQFITFRcaH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABcRAQEBAQAAAAAAAAAAAAAAAAABESH/2gAMAwEAAhEDEQA/AEYri3eomPSdQB5B9ZFWlzJDKYzTCtbBVVeTtVfuA+2VFAKhR+3gZ2PHUzM5G99qqsC8wNNphyHI0qQpsq1EdG1fcsmpbhGFN5jJc477FK11fRqo9vNeu+LMJ1//2Q=='
    }
  },
  description: 'Scout Mounting options'
}

const createData = (body: string) => ({
  description: {
    raw: body
  },
  features: ['shippingTruck', 'shield', 'professionalMonitoring'],
  productImages: [
    scoutSensorImage,
    scoutCompactSize,
    scoutEasyInstall,
    scoutFastCharging,
    scoutMountingOptions
  ],
  productName: 'Smart Alarm Wireless Indoor Security Camera',
  showNewFlag: true,
  buttonText: 'Build a system',
  buttonLink: '/build-my-system',
  productSku: 'CM006-01DWW',
  className: 'mb-4 md:mr-4 md:mb-0 w-full md:h-full'
})

export const data = {
  [Locale.EnGB]: createData(
    `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Only SimpliSafe® offers an indoor camera with smart motion detection that can instantly trigger the alarm.","marks":[],"data":{}}]}]}`
  ),
  [Locale.EnUS]: createData(
    `{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Only SimpliSafe® offers an indoor camera with smart motion detection and 24/7 live guard protection.","marks":[],"data":{}}]}]}`
  )
}
