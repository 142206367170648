import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const productModalSchema = z.object({
  description: z.object({ raw: z.string() }),
  headline: z.string(),
  image: gatsbyImageSchema
})

export type ProductModalSchema = TypeOf<typeof productModalSchema>
