import React, { ReactNode } from 'react'

export type CartPartnerCaptureFormSectionProps = {
  readonly children: ReactNode
}

function CartPartnerCaptureFormSection({
  children
}: CartPartnerCaptureFormSectionProps) {
  return <div>{children}</div>
}

export default CartPartnerCaptureFormSection
