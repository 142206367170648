import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'

import { Locale } from '../../hooks/useLocalizedData'

export const twoColumnVideoSchema = z.object({
  description: contentfulRichTextSchema
})

export const schema = {
  [Locale.EnGB]: twoColumnVideoSchema,
  [Locale.EnUS]: twoColumnVideoSchema
}
