import { useLocale } from '@ecomm/data-hooks'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { PriceToggleSchema } from '../../../components/DraftCart/types'
import { ProductCardFragment } from '../../../components/ProductCard/schema'
import { useAmountSavedWithServicePlanPackage } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanPackage'
import { useDiscountWithServicePlan } from '../../../hooks/IMAPS/useDiscountWithServicePlan'
import { LegalDisclaimer } from '../../LegalDisclaimer'
import { ProSetup } from '../../ProSetup'
import { shouldRenderMonitoring } from '../helpers'
import { OptInOrOut } from '../OptInOrOut'

export type PackageMonitoringWrapperProps = {
  readonly priceToggles: readonly PriceToggleSchema[]
  readonly proSetup?: ProductCardFragment | null
  readonly showProsetupHighlightedComponent?: boolean
  readonly sku?: string
}

export function PackageMonitoringWrapper({
  priceToggles,
  proSetup,
  showProsetupHighlightedComponent = false,
  sku = ''
}: PackageMonitoringWrapperProps) {
  const isUs = useLocale() === 'en-US'
  const { discountWithServicePlan, isLoading } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanPackage({
    sku
  })

  return shouldRenderMonitoring(priceToggles) ? (
    <div>
      {isLoading ? (
        <Skeleton count={2.5} data-component="Skeleton" />
      ) : (
        <div data-component="PackageMonitoringWrapper">
          <OptInOrOut
            amountSavedWithServicePlan={amountSavedWithServicePlan}
            discountWithServicePlan={discountWithServicePlan}
            priceToggles={priceToggles}
          />
          <LegalDisclaimer className="!mb-0 mt-4 !pl-0" />
          {proSetup && !showProsetupHighlightedComponent && !isUs ? (
            <ProSetup proSetup={proSetup} />
          ) : null}
        </div>
      )}
    </div>
  ) : null
}
