import { RiskFree, useScoutProductPageRiskFreeData } from '@ecomm/scout'
import React from 'react'

import useRequestPrice from '../../../hooks/useRequestPrice'
import { pricePerUnit } from '../../../providers/PriceProvider/formatter'
import AddToCartButton from '../../AddToCartButton'

export function LegacyRiskFreeComponent() {
  const sku = 'CM006-01DWW'
  const { productSku, className, ...riskFreeData } =
    useScoutProductPageRiskFreeData()
  const { getFormattedPrice } = useRequestPrice(sku)
  const price = getFormattedPrice(pricePerUnit)

  const renderCta = (qty: number) => {
    return productSku ? (
      <AddToCartButton data={{ productSku, className }} quantity={qty} />
    ) : null
  }
  return <RiskFree atcButton={renderCta} data={riskFreeData} price={price} />
}
