export type RecommendedItemBaseProps = {
  readonly linkText: string
  readonly title: string
  readonly text: string
}

type ButtonProps = {
  readonly onClick: React.MouseEventHandler<HTMLButtonElement>
  readonly linkUrl?: never
}

type LinkProps = {
  readonly onClick?: never
  readonly linkUrl: string
}

type RecommendedItemProps = RecommendedItemBaseProps & (ButtonProps | LinkProps)

export const recommendedItemsData = (
  openQuoteWizardModal: React.MouseEventHandler<HTMLButtonElement>
): readonly RecommendedItemProps[] => [
  {
    linkText: 'Explore Package',
    title: 'The Beacon',
    text: 'Take protection to the next level with two outdoor cameras, four entry sensors and two motion sensors.',
    linkUrl: '/home-security-system-beacon'
  },
  {
    linkText: 'Explore Package',
    title: 'The Lighthouse',
    text: 'Our most popular system features one outdoor camera, three entry sensors and one motion sensor.',
    linkUrl: '/home-security-system-lighthouse'
  },
  {
    title: 'Get our recommendation',
    text: 'Answer a few quick questions and we’ll recommend a system that meets your needs and budget.',
    linkText: 'Take the quiz',
    onClick: openQuoteWizardModal
  }
]
