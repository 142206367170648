import classNames from 'classnames'
import React from 'react'

export const formatNumber = (n = 0): string => (n < 10 ? `0${n}` : `${n}`)
export const formatCountdownIntegerMobile = (n = 0): string =>
  n < 10 && n !== 0 ? `0${n}` : `${n}`

export const addDaysToHours = (days = 0, hours = 0) => hours + days * 24

// TODO: Move these to @ecomm/promotions-components/src/Countdown
export const renderTime = (setSize?: boolean) => (s: string, i: number) => (
  <div
    className={classNames({ 'text-5xl': setSize }, 'min-w-[65px]')}
    key={`countdown-${i}`}
  >
    {s}
  </div>
)

export const renderMobileTime =
  (labels: readonly string[]) => (s: string, i: number) => {
    const isSecond = i === labels.length - 1

    return (
      <div
        className={classNames(
          {
            'bg-complementary-blue-100 col-span-3 mb-0.5 pb-2 text-[32px] md:text-[40px]':
              !isSecond,
            'text-neutral-black col-span-6 bg-white text-sm': isSecond
          },
          'rounded-sm'
        )}
        key={`countdown-${i}`}
      >
        <div
          className={classNames(
            {
              grid: !isSecond,
              'inline-flex': isSecond
            },
            'content-center'
          )}
        >
          <span className="px-1 font-bold">{s}</span>
          <span
            className={classNames({
              'text-[7px]': isSecond,
              'text-[10px] md:text-sm': !isSecond
            })}
          >
            {labels ? labels[i] : null}
          </span>
        </div>
      </div>
    )
  }

export const renderLabel = (label: string, i: number) => (
  <div className="text-center text-base" key={`countdown-label-${i}`}>
    {label}
  </div>
)

export const getLocalizedDate = (
  locale: string,
  timeZone: string,
  date?: string
): Date =>
  new Date(
    date
      ? new Date(date).toLocaleString(locale, { timeZone: timeZone })
      : new Date().toLocaleString(locale, { timeZone: timeZone })
  )
