import { ReactNode } from 'react'

export type FormSectionProps = {
  readonly title: string
  readonly children: ReactNode
}

export function FormSection({ title, children }: FormSectionProps) {
  return (
    <>
      <div className="mt-6 text-lg lg:mt-8">
        <h2
          className={`flex w-full shrink-0 items-center whitespace-nowrap text-xl font-medium md:text-2xl`}
        >
          {title}
        </h2>
      </div>
      <div className="my-2.5 md:my-5">{children}</div>
    </>
  )
}
