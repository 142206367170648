import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { mockVideoBanner } from '@ecomm/monitoring/components'
import { createMockRichText } from '@ecomm/utils'
import { Map as ImmutableMap } from 'immutable'

import type { LanderBelowTheFoldSchema } from '../components/BelowTheFold/schema'

const mockGatsbyImageData: GatsbyImageSchema['gatsbyImageData'] = {
  height: 1,
  images: {
    fallback: {
      src: 'test-img.png'
    },
    sources: [
      {
        srcSet: 'test-img.png 436w',
        type: 'image/webp'
      }
    ]
  },
  layout: 'fullWidth',
  width: 1
}

export const defaultAsset = {
  description: 'Test image',
  gatsbyImageData: mockGatsbyImageData,
  title: 'Test image',
  contentful_id: '345'
}

export const comparisonTableMock = {
  header: {
    competitors: ['Traditional Home Security'],
    titles: ['Traditional Home Security']
  },
  body: [
    {
      feature:
        'Lifetime warranty and 20% discount on hardware for subscribers*',
      values: [true, false]
    },
    {
      feature: 'No contracts or hidden fees. Cancel anytime.',
      values: [true, false]
    }
  ],
  footer: ['* Requires Pro Premium plan.']
}

export const homePageMock = async (): Promise<LanderBelowTheFoldSchema> => ({
  contentfulAffirmBanner: {
    __typename: 'ContentfulAffirmBanner',
    description: {
      raw: await createMockRichText(
        'Select Affirm at checkout. You’ll pay at the monthly installment that works best for you. It’s easy to sign up. And there’s no late fees or surprises.'
      )
    },
    title: 'Buy now, pay later with'
  },
  videoButtonAsset: defaultAsset,
  cameraBannerAsset: defaultAsset,
  expertReviewsAssets: {
    nodes: [defaultAsset]
  },
  appFeaturesCarouselAssets: {
    nodes: [defaultAsset]
  },
  guaranteeCopy: {
    contentful_id: '123',
    content: {
      internal: {
        content: '{text_1:"text 1", text_2:"text 2", text_3: "text 3"}'
      }
    }
  },
  guaranteeAsset: defaultAsset,
  quoteWizard: {
    __typename: 'ContentfulQuoteWizard',
    contentful_id: '12345',
    jebbitUrl: 'https://www.example.com',
    type: 'floating',
    id: '6wRdF53o74w2MhOhBIkzip',
    nt_experiences: []
  },
  expertSectionAsset: defaultAsset,
  quoteWizardAsset: defaultAsset,
  comparisonTable: {
    content: {
      internal: {
        content: JSON.stringify({
          ...comparisonTableMock,
          header: { competitors: ['ADT'], titles: ['ADT'], icons: ['ADT'] }
        })
      }
    }
  },
  comparisonTableOdmonVariant: {
    content: {
      internal: {
        content: JSON.stringify({
          ...comparisonTableMock,
          footer: ['Odmon variant footer']
        })
      }
    }
  },
  userReviewsData: {
    contentful_id: '234',
    content: {
      internal: {
        content: '{"reviews": [{"name": "John", "text": "Review Text"}]}'
      }
    }
  },
  confidenceBar: {
    contentful_id: '345',
    content: {
      internal: {
        content:
          '{"listItems": [{"icon": "SupportIcon", "headline": "Professional monitoring under $1/day & your first month free"}]}'
      }
    }
  },
  appWidget: {
    contentful_id: '456',
    content: {
      internal: {
        content:
          '{"tabs": [{"tab": "tab 1","list": ["list 1"], "title": "title 1"}]}'
      }
    }
  },
  appWidgetOdmonVariant: {
    contentful_id: '456',
    content: {
      internal: {
        content:
          '{"tabs": [{"tab": "variant tab 1","list": ["variant list 1"], "title": "variant title 1"}]}'
      }
    }
  },
  expertReviews: {
    contentful_id: '567',
    content: {
      internal: {
        content:
          '{"reviews": [{"id": 1, "key": "home security system", "text": "review"}]}'
      }
    }
  },
  videoBanner: {
    contentful_id: '567',
    content: {
      internal: {
        content: JSON.stringify(mockVideoBanner)
      }
    }
  },
  userReviewsOdmonVariant: {
    contentful_id: '234',
    content: {
      internal: {
        content:
          '{"reviews": [{"name": "Not John", "text": "Variant Review Text"}]}'
      }
    }
  },
  ukAlarmMoments: {
    headline: 'During an alarm',
    description:
      "With the one-of-a-kind Pro Premium Monitoring Plan, our highly trained agents can act quickly in an emergency. Here's how.",
    cta: null,
    tabs: [
      {
        name: 'Break-in',
        image: {
          ...defaultAsset
        },
        details: [
          {
            icon: 'Siren',
            text: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"When a burglary alarm is triggered, the siren sounds.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
            }
          }
        ],
        detailsBubblesTheme: null,
        disclaimer: {
          raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"*Requires Smart Alarm Wireless Indoor Security Camera or SimpliCam®.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"**Vs. non-verified SimpliSafe® alarms. With video verification, our agents capture evidence of a crime in progress so they can request police response. Pro Premium monitoring plan is required.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}'
        }
      }
    ]
  }
})

export const homePageMockWithAdditionalExpertsReview =
  async (): Promise<LanderBelowTheFoldSchema> => ({
    contentfulAffirmBanner: {
      __typename: 'ContentfulAffirmBanner',
      description: {
        raw: await createMockRichText(
          'Select Affirm at checkout. You’ll pay at the monthly installment that works best for you. It’s easy to sign up. And there’s no late fees or surprises.'
        )
      },
      title: 'Buy now, pay later with'
    },
    videoButtonAsset: defaultAsset,
    cameraBannerAsset: defaultAsset,
    expertReviewsAssets: {
      nodes: [defaultAsset]
    },
    expertReviewsAdditionalAssets: {
      nodes: [defaultAsset]
    },
    appFeaturesCarouselAssets: {
      nodes: [defaultAsset]
    },
    guaranteeCopy: {
      contentful_id: '123',
      content: {
        internal: {
          content: '{text_1:"text 1", text_2:"text 2", text_3: "text 3"}'
        }
      }
    },
    guaranteeAsset: defaultAsset,
    quoteWizard: {
      __typename: 'ContentfulQuoteWizard',
      contentful_id: '12345',
      jebbitUrl: 'https://www.example.com',
      type: 'floating',
      id: '6wRdF53o74w2MhOhBIkzip',
      nt_experiences: []
    },
    expertSectionAsset: defaultAsset,
    quoteWizardAsset: defaultAsset,
    comparisonTable: {
      content: {
        internal: {
          content: JSON.stringify({
            ...comparisonTableMock,
            header: { competitors: ['ADT'], titles: ['ADT'], icons: ['ADT'] }
          })
        }
      }
    },
    comparisonTableOdmonVariant: {
      content: {
        internal: {
          content: JSON.stringify({
            ...comparisonTableMock,
            footer: ['Odmon variant footer']
          })
        }
      }
    },
    userReviewsData: {
      contentful_id: '234',
      content: {
        internal: {
          content: '{"reviews": [{"name": "John", "text": "Review Text"}]}'
        }
      }
    },
    confidenceBar: {
      contentful_id: '345',
      content: {
        internal: {
          content:
            '{"listItems": [{"icon": "SupportIcon", "headline": "Professional monitoring under $1/day & your first month free"}]}'
        }
      }
    },
    appWidget: {
      contentful_id: '456',
      content: {
        internal: {
          content:
            '{"tabs": [{"tab": "tab 1","list": ["list 1"], "title": "title 1"}]}'
        }
      }
    },
    appWidgetOdmonVariant: {
      contentful_id: '456',
      content: {
        internal: {
          content:
            '{"tabs": [{"tab": "variant tab 1","list": ["variant list 1"], "title": "variant title 1"}]}'
        }
      }
    },
    expertReviews: {
      contentful_id: '567',
      content: {
        internal: {
          content:
            '{"reviews": [{"id": 1, "key": "home security system", "text": "review"}]}'
        }
      }
    },
    expertReviewsAdditional: {
      contentful_id: '567',
      content: {
        internal: {
          content:
            '{"reviews": [{"id": 1, "key": "home security system", "text": "review"}]}'
        }
      }
    },
    videoBanner: {
      contentful_id: '567',
      content: {
        internal: {
          content: JSON.stringify(mockVideoBanner)
        }
      }
    }
  })

export const ninetailedFeatureFlagMock = {
  id: '123',
  title: 'Experiment Name',
  json: {
    id: '123',
    tag: 'control'
  },
  nt_experiences: []
}

export const guaranteeAssetMock = {
  guranteeAsset: {
    originalWidth: 392,
    originalHeight: 392,
    title: '60 Day Guarantee',
    url: 'test-image.png',
    description: 'test-descriptio'
  }
}
export const guaranteeCopyMock = ImmutableMap({
  text_1: 'Try it. Test it. Love it or return it.',
  text_2: 'Take our quiz',
  text_3: 'Build a system'
})
export const useNinetailedFeatureFlagMock = {
  data: ninetailedFeatureFlagMock,
  experiences: [],
  baseline: { id: '123' },
  variantIndex: 0,
  isControl: true,
  isVariant: false
}
