export const regexMonthsFreeService = /(?:{{months_free_service}})/g
export const regexMonthsFreeServiceCount = /(?:{{months_free_service_count}})/g

export const replaceTokensWithMonths = (
  text: string,
  monthsOfService: string
): string => {
  const monthsOfServiceDisplay =
    monthsOfService.match(/\d/) !== null
      ? monthsOfService
      : `a ${monthsOfService}`

  const monthsOfServiceCount =
    monthsOfService.match(/\d/) !== null
      ? `${monthsOfService.match(/\d/)} month`
      : `one-month`

  return text
    .replace(regexMonthsFreeService, monthsOfServiceDisplay)
    .replace(regexMonthsFreeServiceCount, monthsOfServiceCount)
}
