import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { useMicroCopy } from '@ecomm/micro-copy'
import { GatsbyImage, Modal } from '@ecomm/shared-components'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { Dispatch, SetStateAction } from 'react'

import useAddToCart from '../../../hooks/useAddToCart'
import useCartQuantity from '../../../hooks/useCartQuantity'
import { ProductSchema } from '../../../templates/SensorPage/sensorPageSchema'
import QuantitySelector from '../../PersonalizePackage/QuantitySelector'
import ResultScreen from './ResultScreen'

type AddExtraSensorsModalProps = {
  readonly showModal: boolean
  readonly setShowModal: Dispatch<SetStateAction<boolean>>
  readonly product: ProductSchema
  readonly isOnStock?: boolean
  readonly isSellable?: boolean
  readonly price: string
}

export default function AddExtraSensorsModal({
  showModal,
  setShowModal,
  product: { sku, namePlural, maxQuantity },
  isOnStock = true,
  isSellable = true,
  price
}: AddExtraSensorsModalProps) {
  const microcopy = useMicroCopy()
  const { onDecrease, onIncrease, quantity } = useCartQuantity(sku, maxQuantity)
  const baseStationImage = useStaticQuery<{
    readonly contentfulAsset: GatsbyImageSchema
  }>(graphql`
    query BaseStationImage {
      contentfulAsset(contentful_id: { eq: "syXtA2wv2GwEk46bHx1qN" }) {
        __typename
        contentful_id
        description
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        title
      }
    }
  `).contentfulAsset
  const isCartable = isSellable && isOnStock
  const { addToCart, isLoading, isCartUpdateSuccess, isCartUpdateError } =
    useAddToCart(sku, quantity)

  //Using react-components modal since it's the only that doesn't introduce accessibility issues
  return (
    <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
      <div
        className="prose md:prose-md lg:prose-lg relative flex w-full max-w-lg flex-col gap-6 p-6 md:p-8"
        data-component="AddExtraSensorsModal"
      >
        <h2 className="font-default text-heading-3-size leading-h3-height mb-3 font-medium">
          {`Add ${namePlural} to your existing system`}
        </h2>
        <div className="flex w-full flex-wrap items-center justify-between">
          <span className="font-bold" data-component="Price">
            {price}
          </span>
          <QuantitySelector
            maxQuantity={!isCartable ? 0 : maxQuantity}
            minQuantity={0}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            quantity={quantity}
          />
          <button
            className={classNames(
              'btn relative mt-4 basis-full lg:mt-0 lg:basis-auto',
              {
                'bg-neutral-medium-120 border-0 text-white':
                  !isCartable || quantity < 1,
                'btn-solid-primary': isCartable && quantity >= 1
              }
            )}
            disabled={!isCartable || quantity < 1}
            onClick={addToCart}
          >
            {isOnStock ? microcopy['add-to-cart'] : microcopy['out-of-stock']}
          </button>
        </div>
        <div className="flex flex-nowrap items-end gap-6">
          <GatsbyImage
            className="w-8"
            image={baseStationImage}
            objectFit="contain"
          />
          <p className="mb-0">{microcopy['make-sure-your-base-station']}</p>
        </div>
        <ResultScreen
          isCartUpdateError={isCartUpdateError}
          isCartUpdateSuccess={isCartUpdateSuccess}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  )
}
