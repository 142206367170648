import { trackProductAdded } from '@ecomm/cdp-tracking'
import { getLocale } from '@ecomm/utils'
import { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import { useCallback } from 'react'
import {
  getRudderstackDataFromProduct,
  ssProductsToRudderstackProducts
} from './productHelpers'
import { AccessoryAddedBody, ProductAddedBody, ProductSchema } from './schema'
import { useSelector } from 'react-redux'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { getCartDiscountCode } from '@ecomm/data-cart'
import * as F from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { overloadMaybe } from '@simplisafe/ewok'

export function useTrackAddProductToCart() {
  const cart = useSelector(selectCart)

  const { coupon, cartId } = F.pipe(
    O.fromNullable(cart),
    O.chain(remoteDataCart => overloadMaybe(remoteDataCart.toMaybe())),
    O.map(_cart => ({
      coupon: getCartDiscountCode(_cart),
      cartId: _cart.id
    })),
    O.getOrElse(() => ({ coupon: '', cartId: '' }))
  )

  const trackAddProductToCartEvent = useCallback(
    ({ locale, total, products }: ProductAddedBody) => {
      const currencyCode = locale === 'en-GB' ? 'GBP' : 'USD'
      trackProductAdded({
        currency: currencyCode,
        total,
        products,
        coupon,
        cartId
      })
    },
    [coupon, cartId]
  )

  const trackProductCardAddToCartEvent = useCallback(
    (productDetail?: Product, quantity = 1) => {
      if (!productDetail) {
        return
      }

      const rudderstack = getRudderstackDataFromProduct(productDetail, quantity)
      trackProductAdded({
        ...rudderstack,
        coupon,
        cartId
      })
    },
    [coupon, cartId]
  )

  const trackAddAccessoryToCartEvent = useCallback(
    ({ product, quantity }: AccessoryAddedBody) => {
      const locale = getLocale()
      const currencyCode = locale === 'en-GB' ? 'GBP' : 'USD'
      product.cata(
        () => null,
        product => {
          const productObj: readonly ProductSchema[] = [
            {
              name: product.name[locale] ?? product.name['en-US'],
              sku: product.sku,
              quantity
            }
          ]

          const productPayload = ssProductsToRudderstackProducts(
            productObj,
            false,
            product.price
          )

          const total = product.price * quantity

          trackProductAdded({
            currency: currencyCode,
            products: productPayload,
            total,
            coupon,
            cartId
          })
        }
      )
    },
    [coupon, cartId]
  )

  return {
    trackAddProductToCartEvent,
    trackAddAccessoryToCartEvent,
    trackProductCardAddToCartEvent
  }
}
