import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './data'
import { schema } from './schema'

export function useScoutProductPageRiskFreeData() {
  const pageRiskFreeData = useLocalizedData<typeof schema>(schema, data)

  return useMemo(() => pageRiskFreeData, [pageRiskFreeData])
}
