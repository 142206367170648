import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { iconTypes } from '@ecomm/shared-icons'
import { type TypeOf, z } from '@simplisafe/ewok'

const detailsSchema = z.object({
  icon: z.enum(iconTypes),
  text: contentfulRichTextSchema
})

const alarmMomentsTabSchema = z.object({
  name: z.string(),
  image: gatsbyImageSchema,
  details: detailsSchema.array().nonempty(),
  detailsBubblesTheme: z.enum(['dark', 'light']).nullish(),
  disclaimer: contentfulRichTextSchema.nullish()
})

export const alarmMomentsSchema = z.object({
  headline: z.string(),
  description: z.string(),
  cta: z
    .object({
      text: z.string(),
      url: z.string()
    })
    .nullish(),
  tabs: alarmMomentsTabSchema.array().nonempty()
})

export type AlarmMomentsSchema = TypeOf<typeof alarmMomentsSchema>
