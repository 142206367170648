// extracted by mini-css-extract-plugin
export var buttonContainer = "ProductPlan-module--buttonContainer--eedb8";
export var container = "ProductPlan-module--container--22c76";
export var containerBlue = "ProductPlan-module--containerBlue--659fb";
export var containerColor = "ProductPlan-module--containerColor--7deab";
export var containerFullWidth = "ProductPlan-module--containerFullWidth--bbbf5";
export var containerWhite = "ProductPlan-module--containerWhite--9f6ce";
export var description = "ProductPlan-module--description--9a152";
export var extraPriceText = "ProductPlan-module--extraPriceText--6c486";
export var featuresContainer = "ProductPlan-module--featuresContainer--a238b";
export var keyFeature = "ProductPlan-module--keyFeature--19fab";
export var paragraph = "ProductPlan-module--paragraph--f7812";
export var planName = "ProductPlan-module--planName--71314";
export var planNameContainer = "ProductPlan-module--planNameContainer--9bd87";
export var price = "ProductPlan-module--price--76030";
export var priceDetails = "ProductPlan-module--priceDetails--04476";
export var productInfo = "ProductPlan-module--productInfo--d2132";
export var productInfoMobile = "ProductPlan-module--productInfoMobile--8645c";
export var productOffer = "ProductPlan-module--productOffer--9c7ee";
export var productOfferFullWidth = "ProductPlan-module--productOfferFullWidth--6c949";
export var productPlanFullWidth = "ProductPlan-module--productPlanFullWidth--b23f5";
export var productPlanNoButton = "ProductPlan-module--productPlanNoButton--e82f0";
export var relatedInformation = "ProductPlan-module--relatedInformation--0d277";
export var ulKeyFeatures = "ProductPlan-module--ulKeyFeatures--ca261";