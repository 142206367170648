import classNames from 'classnames'
import React, { FC, HTMLAttributes, ReactNode } from 'react'

import type { SiteColor } from '../types/colors'

export type HeadingElement = 'h1' | 'h2' | 'h3' | 'h4'
export type HeadingMargins =
  | 'extraLarge'
  | 'extraSmall'
  | 'large'
  | 'medium'
  | 'small'
  | 'tiny'

export type HeadingProps = HTMLAttributes<HTMLHeadingElement> & {
  /** Only works when `useTailwind=true`. */
  readonly bold?: boolean
  readonly children?: ReactNode
  /** Only supports "brandPrimary" when `useTailwind=true`. */
  readonly color?: SiteColor
  readonly element: HeadingElement
  /** Override heading style. This helps preserve accessibility when design can't reflect semantic heading structure. */
  readonly headingAspect?: HeadingElement | 'paragraph'
  /** Only works when `useTailwind=true`. */
  readonly margin?: HeadingMargins
  readonly useTailwind?: boolean
}

/** @deprecated Do not use ss-react-components*/
const Heading: FC<HeadingProps> = ({
  bold = false,
  children,
  color,
  element,
  headingAspect,
  margin,
  useTailwind = false,
  ...rest
}: HeadingProps) => {
  const headingStyleClass = headingAspect ? headingAspect : element
  const classes = classNames(headingStyleClass, {
    [`${color}TextColor`]: !!color
  })

  const tailwindClasses = classNames(
    'prose md:prose-md lg:prose-lg',
    {
      //As we now are going to use the typography defined styles,
      'prose-headings:!font-bold': bold,
      'prose-headings:!mb-lg': margin === 'large',
      'prose-headings:!mb-md': margin === 'medium',
      'prose-headings:!mb-sm': margin === 'small',
      'prose-headings:!mb-tiny': margin === 'tiny',
      'prose-headings:!mb-xl': margin === 'extraLarge',
      'prose-headings:!mb-xs': margin === 'extraSmall',
      //Should be used in special cases
      'prose-headings:!text-body-size prose-headings:!leading-text-height prose-headings:font-default':
        headingAspect === 'paragraph',
      'prose-headings:!text-heading-1-size prose-headings:!leading-h1-height prose-headings:font-arizona':
        headingAspect === 'h1',
      'prose-headings:!text-heading-2-size prose-headings:!leading-h2-height prose-headings:font-arizona':
        headingAspect === 'h2',
      'prose-headings:!text-heading-3-size prose-headings:!leading-h3-height prose-headings:font-default':
        headingAspect === 'h3',
      'prose-headings:!text-heading-4-size prose-headings:!leading-h4-height prose-headings:font-default prose-headings:font-light':
        headingAspect === 'h4',
      //this field would be used only for special cases. But it may be deprecated in the future
      'prose-headings:!text-primary-100': color === 'brandPrimary',
      'prose-headings:m-0': !!margin
    },
    rest.className
  )

  const headingElement =
    element && children
      ? React.createElement(
          element,
          {
            ...rest,
            className: !useTailwind ? classes : null
          },
          children
        )
      : null
  return headingElement && useTailwind ? (
    <div className={tailwindClasses} data-component="Heading">
      {headingElement}
    </div>
  ) : (
    headingElement
  )
}

export default Heading
