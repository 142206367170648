//@ts-nocheck
import classNames from 'classnames'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import React, { FC } from 'react'

import { AccordionItem } from '..'
import { Heading } from '..'
import CartLineItem, { CartLineItemProps } from '../CartLineItem'
import { useMediaQuery } from '../hooks'
import SSImg from '../SSImg'
import * as css from './OrderSummary.module.scss'

export type PriceCalculationProps = {
  readonly [key: string]: string
}

export type PriceCalculationField = {
  readonly couponCode?: string
  readonly value?: string
  readonly label?: string
  readonly isDiscount?: boolean
}

export type CouponCodeField = {
  readonly content: PriceCalculationField
  readonly imgUrl: string
}

export type ShippingDetailsProps = {
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly addressLine1: string
  readonly addressLine2: string
  readonly city?: string
  readonly state?: string
  readonly postalCode: string
  readonly phoneNumber: string
}

const toPriceCalculationItem = ({
  value,
  label,
  isDiscount
}: PriceCalculationField) => (
  <div
    className={classNames(css.totalBlock, { [css.discount]: isDiscount })}
    key={`${label}${value}`}
  >
    <div className={classNames(css.totalValue)}>{label}</div>
    <div className={classNames(css.totalValue)}>{value}</div>
  </div>
)

const renderCouponSectionImg = (imgUrl: string) =>
  !isEmpty(imgUrl) && <SSImg alt="" className="" src={imgUrl} />

const renderCouponSection = ({ imgUrl, content }: CouponCodeField) => (
  <div>
    <React.Fragment key={1}>{renderCouponSectionImg(imgUrl)}</React.Fragment>
    <React.Fragment key={2}>{toPriceCalculationItem(content)}</React.Fragment>
  </div>
)

const renderPriceCalculationSection = map(toPriceCalculationItem)

export type OrderSummaryProps = {
  /** @deprecated: no longer in use anymore. remove once checkoutpage redesign rolled out */
  readonly titleHeader?: string
  readonly section1Title: string
  readonly section2Title: string
  readonly itemList?: readonly CartLineItemProps[]
  readonly priceCalculationFields: readonly PriceCalculationField[]
  readonly couponField?: CouponCodeField
  readonly shippingDetails?: ShippingDetailsProps
  readonly collapseOnDesktop?: boolean
}

/** @deprecated Do not use ss-react-components*/
const OrderSummary: FC<OrderSummaryProps> = ({
  titleHeader,
  section1Title,
  section2Title,
  itemList = [],
  couponField,
  priceCalculationFields,
  shippingDetails,
  collapseOnDesktop
}: OrderSummaryProps) => {
  const isMobile = !useMediaQuery('TabletAndUp')

  const renderListItems =
    !isNil(itemList) &&
    itemList.map((item: CartLineItemProps, idx: number) => (
      <CartLineItem
        {...item}
        key={idx}
        theme={isMobile ? 'subLineItem' : 'default'}
      />
    ))

  const itemContent = (
    <div>
      {!isNil(itemList) && renderListItems}
      <div className={classNames(css.chktCouponBlock)}>
        <div className={classNames(css.couponCont)}>
          {!isNil(couponField) && renderCouponSection(couponField)}
        </div>
      </div>
      <div className={classNames(css.subTotalChkt)}>
        {renderPriceCalculationSection(priceCalculationFields)}
      </div>
    </div>
  )

  return (
    <>
      {titleHeader ? (
        <div className={classNames(css.chktTitle)}>
          <Heading bold element="h3" useTailwind>
            {titleHeader}
          </Heading>
        </div>
      ) : null}
      <div
        className="bg-neutral-light-50 rounded-base p-5 md:p-8 lg:p-16"
        data-component="OrderSummary"
      >
        {section1Title && shippingDetails ? (
          <div className={classNames(css.chktShippingAddress)}>
            <div className={classNames(css.addressBlock)}>
              <div className={classNames(css.shippingTitle)}>
                {section1Title}
              </div>
              <ul>
                <li>{shippingDetails.email}</li>
                <li>
                  {shippingDetails.firstName} {shippingDetails.lastName}
                </li>
                <li>{shippingDetails.addressLine1}</li>
                <li>{shippingDetails.addressLine2}</li>
                <li>
                  {!isNil(shippingDetails.city) && shippingDetails.city + ' '}
                  {!isNil(shippingDetails.state) && shippingDetails.state + ' '}
                  {shippingDetails.postalCode}
                </li>
                <li>{shippingDetails.phoneNumber}</li>
              </ul>
            </div>
          </div>
        ) : null}
        <div className={classNames(css.chktOrderSummary)}>
          {!!isMobile || collapseOnDesktop === true ? (
            <AccordionItem id={section2Title} title={section2Title}>
              {itemContent}
            </AccordionItem>
          ) : (
            <>
              <Heading bold element="h3" useTailwind>
                {section2Title}
              </Heading>
              {itemContent}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default OrderSummary
