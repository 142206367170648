export type Campaign = {
  readonly region: 'UK' | 'US'
  readonly customerGroup?: string
  readonly partnerName?: string
  readonly partnerUrl?: string
  readonly campaignName?: string
}

export enum LocaleMap {
  UK = 'en-GB',
  US = 'en-US'
}

export const emptyCampaign: Campaign = {
  region: 'US'
}

export const CUSTOMER_GROUP_COOKIE_TIMEOUT = 500
