import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { FeatureSection, FeatureSectionProps } from '@ecomm/shared-sections'
import { graphql } from 'gatsby'
import React from 'react'

import FeatureSectionEmbeddedEntry from './FeatureSectionEmbeddedEntry'
import classNames from 'classnames'

const richTextOptions: Options = {
  renderNode: {
    [BLOCKS.OL_LIST]: (_, children) => {
      return (
        <ol
          className={classNames(
            'prose-li:flex prose-li:before:inline-block prose-li:before:bg-primary-100 prose-li:before:content-[counter(li)] prose-li:before:[counter-increment:li] list-inside pl-0 [counter-reset:li]',
            'prose-li:before:w-8 prose-li:before:h-8 md:prose-li:before:w-12 md:prose-li:before:h-12 prose-li:before:flex prose-li:before:items-center prose-li:before:justify-center',
            'prose-li:before:rounded-full prose-li:before:shrink-0 prose-li:before:mr-3 md:prose-li:before:mr-5'
          )}
        >
          {children}
        </ol>
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => <FeatureSectionEmbeddedEntry node={node} />
  }
}

export default function HomeLandingFeatureSection({
  body,
  media,
  theme = 'Default',
  type = 'Left Side Media'
}: FeatureSectionProps) {
  return (
    <FeatureSection
      body={body}
      className="rounded-lg"
      media={media}
      richTextOptions={richTextOptions}
      theme={theme}
      type={type}
    />
  )
}

export const query = graphql`
  fragment homeLandingFeatureSection on ContentfulFeatureSection {
    body {
      raw
      references {
        ... on ContentfulButton {
          contentful_id
          __typename
          text
          url
          type
        }
      }
    }
    media {
      contentful_id
      __typename
      mimeType
      file {
        contentType
        url
      }
      title
      gatsbyImageData(layout: FULL_WIDTH)
      description
    }
    type
    __typename
  }
`
