import {
  Miss,
  SimpliCheckBlue,
  SimpliCheckDark,
  SimpliCheckGold
} from '@ecomm/shared-icons'
import { useTrackOdmonSeeDetails } from '@ecomm/tracking'
import classNames from 'classnames'
import React from 'react'
import { match } from 'ts-pattern'

import FeatureModal from '../FeatureModal/FeatureModal'
import { FeatureRowProps } from './types'

export function FeatureRow({
  theme = 'default',
  title,
  leftColumn,
  rightColumn,
  className = '',
  cellClassName = '',
  modal,
  trackingCode
}: FeatureRowProps) {
  const trackOdmonDetails = useTrackOdmonSeeDetails()

  const renderedLeftColumn = match(leftColumn)
    .with(true, () =>
      theme === 'blue' ? (
        <SimpliCheckBlue className="h-[30px] w-[30px]" />
      ) : (
        <SimpliCheckGold className="h-[30px] w-[30px]" />
      )
    )
    .with(false, () => <Miss className="h-[30px] w-[30px]" />)
    .when(React.isValidElement, () => leftColumn)
    .otherwise(() => null)

  const renderedRightColumn = match(rightColumn)
    .with(true, () =>
      theme === 'blue' ? (
        <SimpliCheckBlue className="h-[30px] w-[30px]" />
      ) : (
        <SimpliCheckDark className="h-[30px] w-[30px]" />
      )
    )
    .with(false, () => <Miss className="h-[30px] w-[30px]" />)
    .when(React.isValidElement, () => rightColumn)
    .otherwise(() => null)

  return (
    <tr className={className}>
      <td
        className={classNames(
          'border-b-neutral-medium-100 border-spacing-y-2 border-x-0 border-b border-t-0 border-solid py-2',
          cellClassName
        )}
        colSpan={2}
      >
        <div>
          {title}
          {modal ? (
            <FeatureModal
              modal={modal}
              onClick={() => {
                trackingCode && trackOdmonDetails(trackingCode)
              }}
            />
          ) : null}
        </div>
      </td>
      <td
        className={classNames(
          'border-b-neutral-medium-100 border-spacing-y-2 border-x-0 border-b border-t-0 border-solid py-2 text-center',
          cellClassName
        )}
      >
        {renderedLeftColumn}
      </td>
      <td
        className={classNames(
          'border-b-neutral-medium-100 border-spacing-y-2 border-x-0 border-b border-t-0 border-solid py-2 text-center',
          cellClassName
        )}
      >
        {renderedRightColumn}
      </td>
    </tr>
  )
}
