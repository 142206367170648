import { gql } from '@apollo/client/index.js'
import { contentfulImageSchema } from '@ecomm/contentful/components'
import { apolloRichTextSchema } from '@ecomm/contentful-schemas'
import { useContentful } from '@ecomm/shared-apollo'
import { z } from '@simplisafe/ewok'

export const SMALL_TEXT_FRAGMENT = gql`
  fragment SmallTextEntries on Entry {
    ... on IconAndText {
      text {
        json
      }
      icon
    }
    ... on Button {
      buttonText: text
      url
      newTab
      type
    }
    ... on Placeholder {
      type
    }
  }

  fragment SmallText on Text {
    text {
      json
      links {
        entries {
          inline {
            sys {
              id
            }
            ...SmallTextEntries
          }
          hyperlink {
            sys {
              id
            }
            ...SmallTextEntries
          }
          block {
            sys {
              id
            }
            ... on Button {
              text
              url
              newTab
              type
            }
          }
        }
        assets {
          hyperlink {
            ...image
          }
        }
      }
    }
    textAlignment
    textPosition
    icon {
      ...image
    }
  }
`

export const SMALL_TEXT_QUERY = gql`
  ${SMALL_TEXT_FRAGMENT}
  query SmallText($id: String!, $preview: Boolean!, $locale: String!) {
    text(id: $id, preview: $preview, locale: $locale) {
      ...SmallText
    }
  }
`

export const smallTextSchema = z.object(
  {
    __typename: z.literal('Text'),
    text: apolloRichTextSchema.optional().nullish(),
    textAlignment: z.enum(['Left', 'Center', 'Right']).optional().nullish(),
    textPosition: z.string(),
    icon: contentfulImageSchema.optional().nullish()
  },
  { errorMap: z.defaultErrorMap }
)

export const useSmallText = (id: string) => {
  const { data } = useContentful(SMALL_TEXT_QUERY, { id })
  return smallTextSchema.parse(data?.['text'])
}
