import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { TypeOf, z } from '@simplisafe/ewok'

const ninetailedFeatureFlagDraftSchema = z.object({
  id: z.string(),
  title: z.string(),
  json: z.object({
    id: z.string(),
    tag: z.string()
  })
})
const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(ninetailedFeatureFlagDraftSchema)
})
export const ninetailedFeatureFlagSchema =
  ninetailedFeatureFlagDraftSchema.extend({
    nt_experiences: z.array(experimentSchema).default([])
  })

export type NinetailedFeatureFlagSchema = TypeOf<
  typeof ninetailedFeatureFlagSchema
>
