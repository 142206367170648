import React, { useCallback } from 'react'

type Props = {
  readonly onDecrease: () => void
  readonly onIncrease: () => void
  readonly checked: boolean
  readonly name: string
}

function Checkbox({ onDecrease, onIncrease, checked, name }: Props) {
  // Converts "Base Station" to "checkbox_base_station"
  const id = `checkbox_${name.toLowerCase().replace(/ /g, '_')}`

  const onChange = useCallback(
    () => (checked ? onDecrease() : onIncrease()),
    [checked, onIncrease, onDecrease]
  )

  return (
    <div className="flex items-center justify-center gap-1">
      <input
        checked={checked}
        className="mr-2 h-5 w-5 cursor-pointer"
        id={id}
        name={name}
        onChange={onChange}
        type="checkbox"
      />
      <label htmlFor={id}>Add {name}</label>
    </div>
  )
}

export default Checkbox
