import { useTrackingComparePlans } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useOnComparePlans() {
  const handleTrackingComparePlans = useTrackingComparePlans()

  return useCallback(
    () => handleTrackingComparePlans(),
    [handleTrackingComparePlans]
  )
}
