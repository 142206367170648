//@ts-nocheck
import classNames from 'classnames'
import React, { ReactNode, useRef, useState } from 'react'
import Sticky from 'react-stickynode'
import { useTracking } from 'react-tracking'

import useMediaQuery from '../hooks/useMediaQuery'
import { Cancel } from '../icons'
import * as css from './StickyPopoverLink.module.scss'

export const getLinkWidthStyle = (
  linkElement: React.RefObject<HTMLButtonElement>
) => {
  const linkWidth = linkElement.current
    ? linkElement.current.getBoundingClientRect().width
    : undefined

  return { width: linkWidth ? `${linkWidth}px` : undefined }
}

type StickyPopoverLinkProps = {
  readonly children: ReactNode
  /**
   * If true, link will be "sticky" on desktop-sized screens even if it would otherwise be scrolled off the screen.
   * Intended usage is that this will be set false when there is a promo running with a sticky banner.
   */
  readonly enableDesktopStickyBehavior?: boolean
  /** CSS class name for link */
  readonly linkClass?: string
  /** Text for link that triggers popover */
  readonly linkText: string
  /** Click event for get a quote link */
  readonly onLinkClick?: () => void
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const StickyPopoverLink: React.FC<StickyPopoverLinkProps> = ({
  children,
  linkClass,
  linkText,
  onLinkClick,
  enableDesktopStickyBehavior = true,
  dataComponent = StickyPopoverLink.name
}: StickyPopoverLinkProps) => {
  const [isPopoverVisible, showPopover] = useState(false)
  const isDesktop = useMediaQuery('DesktopAndUp')
  const linkElement = useRef<HTMLButtonElement>(null)
  const linkClasses = classNames(
    'text-base',
    css.link,
    { invisible: isPopoverVisible },
    linkClass
  )
  const linkWidthStyle = getLinkWidthStyle(linkElement)
  const { Track, trackEvent } = useTracking()
  const closePopover = () => {
    trackEvent({ event: 'closePopover' })
    showPopover(false)
  }
  return (
    <Track>
      <div data-component={dataComponent}>
        {/* ^ This wrapper div will remain statically positioned even when the link is sticky (which is position: fixed
        under the hood). We fill this div with a placeholder div that has its width set to the same width as the link,
        so that when the link is sticky this div's width won't change and cause a jerky layout shift. */}
        <div style={linkWidthStyle}>
          {/* ^ This is the aforementioned placeholder div. */}
          <Sticky
            activeClass={css.linkSticky}
            enableTransforms={false}
            enabled={enableDesktopStickyBehavior ? isDesktop : null}
            innerClass={css.inner}
          >
            <button
              className={linkClasses}
              onClick={() => {
                showPopover(true)
                onLinkClick && onLinkClick()
              }}
              ref={linkElement}
            >
              {linkText}
            </button>
          </Sticky>
        </div>
      </div>
      <div className="fixed left-1/2 right-auto top-32 z-[12] flex w-full -translate-x-1/2 justify-end md:w-11/12">
        <div
          className={classNames(
            'rounded-base shadowSoft bg-neutral-light-100 flex min-h-[396px] w-full max-w-sm items-center justify-center overflow-hidden md:max-w-[640px]',
            { hidden: !isPopoverVisible }
          )}
        >
          <Cancel
            className="text-neutral-medium-120 absolute right-4 top-4 z-[1] cursor-pointer"
            onClick={closePopover}
            titleA11y="Close"
          />
          {children}
        </div>
      </div>
    </Track>
  )
}

export default StickyPopoverLink
