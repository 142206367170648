import { z } from '@simplisafe/ewok'

export const partnerUtmCampaignSchema = z.object({
  partnerCampaignCollection: z.object({
    items: z.array(
      z.object({
        campaignName: z.string(),
        customerGroup: z.string(),
        partnerName: z.string(),
        partnerUrl: z.string(),
        region: z.enum(['US', 'UK'])
      })
    )
  })
})
