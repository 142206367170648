import { overloadMaybe, prop } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getCartShippingAddress = (cart: ImmutableCart) =>
  pipe(
    overloadMaybe(prop('shippingAddress', cart)),
    O.chain(shippingAddress => O.fromNullable(shippingAddress)),
    O.fold(
      () => null,
      address => address
    )
  )
