import { z } from '@simplisafe/ewok'

export const contentfulImageQuerySchema = z.object({
  title: z.string(),
  description: z.string(),
  file: z.object({
    url: z.string(),
    details: z.object({
      size: z.number(),
      image: z.object({
        width: z.number(),
        height: z.number()
      })
    })
  })
})
