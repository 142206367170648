import { LoadingSpinner } from '@ecomm/checkout-icons'
import { paypalClientId } from '@ecomm/data-env-variables'
import {
  type BraintreePaymentAuthData,
  fetchClientToken
} from '@ecomm/data-simplisafe-api'
import { logError } from '@ecomm/error-handling'
import { useMicroCopy } from '@ecomm/micro-copy'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import type { PaymentState } from '../PaymentFormWrapper/types'
import { PayPalButtonWrapper } from './PayPalButtonWrapper'

export type PayPalCheckoutContentProps = {
  readonly handleSubmitOrder: (
    orderData: BraintreePaymentAuthData
  ) => Promise<void>
  readonly setPaymentState: (paymentState: PaymentState) => void
}

const clientId = paypalClientId()

export function PaypalCheckoutContent({
  handleSubmitOrder,
  setPaymentState
}: PayPalCheckoutContentProps) {
  const [clientToken, setClientToken] = useState('')
  // todo: setup tracking for paypal button click
  const {
    Track
    // trackEvent
  } = useTracking()
  const microCopy = useMicroCopy()

  async function obtainClientToken() {
    pipe(
      await fetchClientToken()(),
      E.match(
        error => {
          setClientToken('')
          logError(Error(`Error fetching PayPal client token: ${error}`))
        },
        response => {
          setClientToken(response.clientToken)
        }
      )
    )
  }

  useEffect(() => {
    clientToken === '' && obtainClientToken()
  }, [clientToken])

  return (
    <Track>
      {clientToken ? (
        <PayPalScriptProvider
          options={{
            components: 'buttons',
            clientId: clientId,
            dataClientToken: clientToken,
            intent: 'tokenize',
            vault: true
          }}
        >
          <div className="grid grid-cols-2 items-center py-2">
            <p className="max-w-[400px]">
              {microCopy['paypal-checkout-description']}
            </p>
            <div className="max-w-[224px]">
              <PayPalButtonWrapper
                handleSubmitOrder={handleSubmitOrder}
                setPaymentState={setPaymentState}
              />
            </div>
          </div>
        </PayPalScriptProvider>
      ) : (
        <LoadingSpinner />
      )}
    </Track>
  )
}
