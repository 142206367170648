import { GatsbyImage } from '@ecomm/shared-components'
import { useTrackingAlarmTile } from '@ecomm/tracking'
import classNames from 'classnames'
import React from 'react'

import { Selected } from '../../templates/AlarmSensor'
import { GroupSchema } from '../../templates/AlarmSensor/alarmSensorPageSchema'
import { trackAlarmSensorGenClick } from '@ecomm/cdp-tracking'

export type AlarmTitleProps = {
  readonly groups: readonly GroupSchema[]
  readonly selected: Selected
  readonly onSelect: (selected: Selected) => void
}

function AlarmTile({ groups, selected, onSelect }: AlarmTitleProps) {
  const trackAlarmTile = useTrackingAlarmTile()

  const onButtonClicked = (index: number) => {
    trackAlarmTile(index)
    trackAlarmSensorGenClick(index === 0 ? 'gen-3' : 'gen-2')
    onSelect({
      index: index,
      showProducts: true
    })
  }

  return (
    <div
      className="prose md:prose-md lg:prose-lg flex flex-col gap-4 overflow-hidden md:flex-row md:gap-8"
      data-component="Tile"
    >
      {groups.map((group, index) => {
        const isSelected = selected.showProducts && selected.index === index
        return (
          <button
            className={classNames(
              "rounded-base after:rounded-base relative w-full cursor-pointer items-center border-solid bg-transparent p-0 after:absolute after:left-0 after:top-0 after:transition-all after:duration-200 after:ease-in-out hover:after:h-full hover:after:w-full hover:after:bg-black hover:after:bg-opacity-30 md:w-1/2 lg:after:content-['']",
              {
                'border-none': !isSelected,
                'border-complementary-blue-100': isSelected
              }
            )}
            data-testid={`alarm-tile-button-${group.title}`}
            key={`alarm-tile-button-${index}`}
            onClick={() => onButtonClicked(index)}
          >
            <GatsbyImage
              className="rounded-base h-full max-h-96 w-full md:max-h-fit"
              image={group.image}
            />
            <div className="absolute right-8 top-1/2 flex w-40 -translate-y-1/2 flex-col items-center gap-4 text-center md:left-1/2 md:-translate-x-1/2 md:gap-8">
              <p className="text-body-size m-0 font-medium">Expand Your</p>
              <h2 className="m-0">{group.title}</h2>
              <p className="text-body-size m-0 whitespace-pre-line">
                {group.subtitle}
              </p>
            </div>
          </button>
        )
      })}
    </div>
  )
}

export default AlarmTile
