import { ninetailedFeatureFlagSchema } from '@ecomm/promotions-components'
import { parseObjectKey } from '@simplisafe/ewok'
import { graphql, useStaticQuery } from 'gatsby'

export const usePromoBannerExperimentQuery = () => {
  const data = useStaticQuery(graphql`
      query HLPromoBannerExperiment {
        promoBannerExperiment: contentfulNinetailedFeatureFlag(
          contentful_id: { eq: "1GaRfPYfJQJuediSrCar1r" }
        ) {
          ...homeLandingFeatureFlagBase
          nt_experiences {
            ...homeLandingFeatureFlagExperience
          }
        }
      }
    `)

  return parseObjectKey(
    data,
    'promoBannerExperiment',
    ninetailedFeatureFlagSchema
  )
}
