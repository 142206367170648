import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

import { productCardInternalSchema } from '../ProductCard/schema'

export const accessoriesModalSchema = z.lazy(() =>
  z.object({
    description: contentfulRichTextSchema,
    headline: z.string(),
    modalTrigger: z.object({ text: z.string() }),
    products: z.array(productCardInternalSchema)
  })
)

export type AccessoriesModalSchema = TypeOf<typeof accessoriesModalSchema>
