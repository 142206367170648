import { usePriceContext } from '@ecomm/data-price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'

import { itemQuantityAtom } from '../../../atoms/draftCart/itemQuantityAtom'
import { monitoringAtom } from '../../../atoms/draftCart/monitoringPlanAtom'
import { MonitoringPlan } from '../../DraftCart/types'
import { PackageItem } from '..'
import { PackageType } from '../schema'

export type Response<P> = {
  readonly regularPrice: P
  readonly extrasPrice: P
  readonly discountedPrice: O.Option<P>
  readonly discountAmount: O.Option<P>
}

export const usePackagePrice = (
  sku: string,
  type: PackageType,
  productItems: readonly PackageItem[],
  months = 1,
  /**
   * Apply monitoring discount regardless of selected plan
   * @default false
   */
  displayMonitoringDiscount = false,
  /**
   * Don't include draft cart items in price calculation for non-dynamic packages. Useful on
   * PLP pages when displaying dynamic package alongside non-dynamic packages where draft cart should only
   * be used for tracking dynamic package components.
   * @default false
   */
  ignoreDraftCartIfNonDynamic = false
): Response<number> => {
  const nonDynamicPackagePrice = useNonDynamicPackagePrice(
    sku,
    months,
    displayMonitoringDiscount,
    ignoreDraftCartIfNonDynamic
  )
  const dynamicPackagePrice = useDynamicPackagePrice(
    sku,
    productItems,
    months,
    displayMonitoringDiscount
  )

  return type === 'Dynamic' ? dynamicPackagePrice : nonDynamicPackagePrice
}

export const useFormattedPackagePrice = (
  sku: string,
  type: PackageType,
  productItems: readonly PackageItem[],
  months = 1
): Response<string> => {
  const { formatPrice } = usePriceContext()

  const { regularPrice, extrasPrice, discountedPrice, discountAmount } =
    usePackagePrice(sku, type, productItems, months)

  return {
    regularPrice: pipe(
      formatPrice(O.some(regularPrice)),
      O.getOrElse(() => '')
    ),
    extrasPrice: pipe(
      formatPrice(O.some(extrasPrice)),
      O.getOrElse(() => '')
    ),
    discountedPrice: formatPrice(discountedPrice),
    discountAmount: formatPrice(discountAmount)
  }
}

const useNonDynamicPackagePrice = (
  sku: string,
  months: number,
  displayMonitoringDiscount = false,
  ignoreDraftCart = false
): Response<number> => {
  const [items] = useAtom(itemQuantityAtom)
  const [monitoring] = useAtom(monitoringAtom)

  const {
    calculateTotalPrice,
    getPrice,
    getDiscountedPrice,
    getDiscountedPriceWithServicePlan
  } = usePriceContext()

  // Draft cart products are not discounted in Prebuilt/Refurbished/PLA packages
  const draftCartTotal = ignoreDraftCart
    ? 0
    : calculateTotalPrice(
        items.toArray().map(([sku, quantity]) => ({ sku, quantity })),
        months
      )

  // Prices for the package sku
  const packageOriginalPrice = O.getOrElse(() => 0)(getPrice(sku, months))

  const packageDiscountedPrice =
    monitoring.type === MonitoringPlan.interactive ||
    monitoring.type === MonitoringPlan.odmonOvernight ||
    monitoring.type === MonitoringPlan.odmon247 ||
    displayMonitoringDiscount
      ? getDiscountedPriceWithServicePlan(sku, months)
      : getDiscountedPrice(sku, months)

  // Package price + non-discounted products in draft cart
  const totalDiscountedPrice = pipe(
    packageDiscountedPrice,
    O.map(subtotal => subtotal + draftCartTotal)
  )

  // Amount saved from the promotion
  const discountAmount = pipe(
    totalDiscountedPrice,
    O.map(total => total - (packageOriginalPrice + draftCartTotal))
  )

  return {
    regularPrice: packageOriginalPrice,
    extrasPrice: draftCartTotal,
    discountedPrice: totalDiscountedPrice,
    discountAmount: discountAmount
  }
}

const useDynamicPackagePrice = (
  sku: string,
  packageItems: readonly PackageItem[],
  months: number,
  displayMonitoringDiscount = false
): Response<number> => {
  const [items] = useAtom(itemQuantityAtom)
  const [monitoring] = useAtom(monitoringAtom)

  const {
    calculateTotalPrice,
    calculateTotalDiscountedPrice,
    calculateTotalDiscountedPriceWithServicePlan
  } = usePriceContext()

  // Prices for items in the draft cart
  const draftCartItems = items
    .toArray()
    .map(([sku, quantity]) => ({ sku, quantity }))
  const draftCartOriginalTotal = calculateTotalPrice(draftCartItems, months)

  // Prices for items that are always included in a dynamic package (i.e. base station, keypad), also known as core components
  const packageProductItems = packageItems.map(({ product, quantity }) => ({
    sku: product.sku,
    quantity
  }))
  const packageItemsOriginalTotal = calculateTotalPrice(
    packageProductItems,
    months
  )

  // Non-discounted total price of all items in the dynamic package
  const packageOriginalPrice =
    packageItemsOriginalTotal + draftCartOriginalTotal

  // Prices for all items in a dynamic package
  const allPackageItems = draftCartItems.concat(packageProductItems)
  const totalDiscountedPrice =
    monitoring.type === MonitoringPlan.interactive ||
    monitoring.type === MonitoringPlan.odmonOvernight ||
    monitoring.type === MonitoringPlan.odmon247 ||
    displayMonitoringDiscount
      ? calculateTotalDiscountedPriceWithServicePlan(
          sku,
          allPackageItems,
          months
        )
      : calculateTotalDiscountedPrice(sku, allPackageItems, months)

  // Amount saved from the promotion
  const discountAmount = pipe(
    totalDiscountedPrice,
    O.map(total => total - packageOriginalPrice)
  )

  return {
    regularPrice: packageOriginalPrice,
    extrasPrice: 0,
    discountedPrice: totalDiscountedPrice,
    discountAmount: discountAmount
  }
}
