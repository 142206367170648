import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { TrackEvent } from '../analytics'
import { brazeTrackCartEvent } from '../braze'
import { getCommerceDataFromLineItem } from '../getCommerceDataFromLineItems'

const GTM_REMOVE_CART_EVENT = 'eec.remove'
const GTM_REMOVE_CART_CATEGORY = 'eec'
const GTM_REMOVE_CART_ACTION = 'removeFromCart'

export const trackRemoveCartEvent = (
  lineItem: LineItem,
  trackEvent: TrackEvent,
  lineItems: readonly LineItem[],
  quantity?: number,
  sequence?: number
) => {
  const quantityAdjustedLineItem = {
    ...lineItem,
    quantity: quantity || lineItem.quantity
  }

  const ecomm = getCommerceDataFromLineItem('remove')(lineItems)(
    quantityAdjustedLineItem
  )

  trackEvent({
    ecommerce: ecomm,
    event: 'removeFromCart',
    /** Intended to identify the index of package line items that was clicked for the clickable cart link experiment. */
    sequence: sequence
  })

  trackEvent({
    ecommerce: ecomm,
    event: GTM_REMOVE_CART_EVENT,
    eventAction: GTM_REMOVE_CART_ACTION,
    eventCategory: GTM_REMOVE_CART_CATEGORY,
    /** Intended to identify the index of package line items that was clicked for the clickable cart link experiment. */
    sequence: sequence
  })

  brazeTrackCartEvent({
    eventType: 'remove_from_cart',
    productTrackingData: ecomm
  })
}
