//

import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useMemo } from 'react'

export const pxCookieAtom = atomWithStorage<string | null>('pxcookie', null)

/**
 * Read and update the cookie sent to PerimeterX.
 */
export const usePxCookie = (): readonly [
  O.Option<string>,
  (code: string) => void
] => {
  const [unsafePxCookieStorage, setPxCookie] = useAtom(pxCookieAtom)

  const pxCookie = useMemo(
    () => O.fromNullable(unsafePxCookieStorage),
    [unsafePxCookieStorage]
  )

  return [pxCookie, setPxCookie]
}
