//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import * as css from './FacebookPost.module.scss'

export type FacebookOrderBy = 'reverse_time' | 'social' | 'time'

export type FacebookPostProps = {
  /** Props for setting the width */
  readonly width?: string
  /** Props for setting the theme */
  readonly colorScheme?: 'dark' | 'light'
  /** Props for setting the lazy loading data option */
  readonly lazy?: boolean | false
  /** Props for setting mobile view */
  readonly mobileView?: boolean | false
  /** Props for setting the pagination size */
  readonly numOfPosts?: number
  /** Props for setting sorting order */
  readonly orderBy?: FacebookOrderBy
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const FacebookPost: FC<FacebookPostProps> = ({
  colorScheme,
  dataComponent = FacebookPost.name,
  lazy,
  mobileView,
  numOfPosts = 5,
  orderBy,
  width
}: FacebookPostProps) => {
  return (
    <div>
      <div id="fb-root"></div>
      <div className={classNames(css.wrapper)}>
        <div
          className="fb-comments"
          data-colorscheme={colorScheme}
          data-component={dataComponent}
          data-lazy={lazy}
          data-mobile={mobileView}
          data-numposts={numOfPosts}
          data-order-by={orderBy}
          data-width={width}
        ></div>
      </div>
    </div>
  )
}

export default FacebookPost
