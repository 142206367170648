import { Modal } from '@ecomm/shared-components'
import React, { type ReactNode } from 'react'

interface Props {
  readonly showModal: boolean
  readonly closeModal: () => void
  readonly imageSrc: string
  readonly title: string
  readonly paragraphs: readonly ReactNode[]
  readonly disclaimer: readonly string[]
}

function MonitoringTwoColumnModal({
  showModal,
  closeModal,
  title,
  paragraphs,
  disclaimer,
  imageSrc
}: Props) {
  return (
    <Modal
      appElementId="___gatsby"
      dataComponent="OutdoorCameraModal"
      isOpen={showModal}
      onRequestClose={closeModal}
    >
      <div
        className="flex max-w-screen-lg flex-col-reverse gap-8 p-8 lg:flex-row"
        data-component="ODMONmodal"
      >
        <div className="relative flex-1 overflow-hidden rounded-2xl">
          <img alt={title} className="w-full h-full" src={imageSrc} />
        </div>

        <div className="my-auto flex-1 justify-items-start lg:pb-6 pl-0 lg:pr-12 text-xl">
          <h2 className="mt-0 mb-0 text-4xl font-medium">{title}</h2>
          {paragraphs.map((text, index) => (
            <p className="mb-0 text-xl" key={index}>
              {text}
            </p>
          ))}
          <div className=" italic">
            {disclaimer.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export { MonitoringTwoColumnModal }
