import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import type {
  CreateOrderRequest,
  CreateOrderV1Response
} from '@simplisafe/ss-ecomm-data/simplisafe'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { fetchApi } from './lib/fetchApi'

function isOrderCreated(res: unknown): res is CreateOrderV1Response {
  polyfillHasOwn()
  return !!res && typeof res === 'object'
}

export function createOrder(request: CreateOrderRequest) {
  return pipe(
    fetchApi({
      endpoint: `/orders/v1/orders`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    }),
    TE.chain(res =>
      isOrderCreated(res)
        ? TE.right(res)
        : TE.left(Error(`order creation callback failed`))
    )
  )
}
