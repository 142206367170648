import { getCookie } from '@ecomm/shared-cookies'
import { useEffect, useState } from 'react'

const GA_COOKIE = '_ga'
/**
 * Polls Google Analytics clientId
 * @returns
 */
export function useGoogleAnalytics(): readonly [
  string | undefined,
  string | undefined
] {
  const [clientId, setClientId] = useState<string | undefined>()
  const [sessionId, setSessionId] = useState<string | undefined>()
  useEffect(() => {
    const id =
      !clientId &&
      setInterval(() => {
        const cookieValue = getCookie<string>(GA_COOKIE)
        cookieValue && setClientId(cookieValue)
        cookieValue &&
          setSessionId(Math.floor(new Date().getTime() / 1000).toString())
      }, 100)
    return () => {
      id && clearInterval(id)
    }
  }, [clientId])

  return [clientId, sessionId]
}
