import classNames from 'classnames'
import { isSome } from 'fp-ts/lib/Option'
import { head } from 'fp-ts/lib/ReadonlyArray'
import React, { useState } from 'react'

import { replaceTags } from '../../utils/replaceTags'
import { Header } from './Header'
import { Row } from './Row'
import { RowsGroup } from './RowsGroup'
import type { ComparisonTableProps } from './types'

export function ComparisonTable({
  data
}: {
  readonly data: ComparisonTableProps
}) {
  const [expandedRow, setExpandedRow] = useState<number>(1)
  const firstRowOption = head(data.body)
  const hasExpandableRows =
    isSome(firstRowOption) && 'groupName' in firstRowOption.value
  const footer =
    Array.isArray(data?.footer) && data.footer.every(p => typeof p === 'string')
      ? data.footer.map(text => replaceTags(text))
      : data.footer

  return (
    <div
      className={classNames(
        'prose md:prose-md lg:prose-lg',
        hasExpandableRows ? '-mx-4 md:mx-0' : ''
      )}
    >
      <h2
        className={classNames(
          'mb-2 text-center max-lg:text-[28px] lg:text-left',
          hasExpandableRows ? 'hidden' : 'lg:hidden'
        )}
        data-component="ComparisonTable"
        data-testid="ComparisonTable"
      >
        The SimpliSafe<span className="align-super text-lg">®</span> difference
      </h2>

      <table
        className={classNames(
          'bg-neutral-light-50 prose-p:m-0 w-full border-separate',
          hasExpandableRows
            ? 'rounded-none px-6 pt-6 md:rounded-2xl lg:pt-10'
            : 'rounded-2xl px-3 py-5 md:p-6 lg:p-12'
        )}
      >
        <Header
          competitors={data.header.titles.map((c, i) => ({
            title: c,
            icon: data.header.icons?.[i]
          }))}
          hasExpandableRows={hasExpandableRows}
        />

        <tbody>
          {data.body.map((row, index) => {
            if ('groupName' in row) {
              return (
                <RowsGroup
                  key={index}
                  {...row}
                  isExpanded={expandedRow === index + 1}
                  onClick={() => setExpandedRow(index + 1)}
                />
              )
            } else {
              return <Row key={`comparison-table-row-${index}`} row={row} />
            }
          })}
        </tbody>

        {!hasExpandableRows && (
          <tfoot>
            <tr className="flex py-3 lg:py-4">
              <td className="text-xs font-light leading-4 lg:text-sm">
                {footer}
              </td>
            </tr>
          </tfoot>
        )}
      </table>

      {hasExpandableRows ? (
        <div className="border-neutral-light-100 mx-4 flex border-x-0 border-t-0 border-solid py-3 text-[10px] font-light leading-3 md:mx-0 lg:pb-6 lg:pt-4 lg:text-xs">
          {footer}
        </div>
      ) : null}
    </div>
  )
}
