import { overloadMaybe, prop } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getCartShippingInfo = (cart: ImmutableCart) =>
  pipe(
    overloadMaybe(prop('shippingInfo', cart)),
    O.chain(shippingInfo => O.fromNullable(shippingInfo)),
    O.fold(
      () => null,
      shippingInfo => shippingInfo
    )
  )
