import { overloadMaybe } from '@simplisafe/ewok'
import { selectPromoDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { useSelector } from 'react-redux'

/**
 * Returns the discount text of the promo.
 * Uses the active promo if available, otherwise falls back to evergreen.
 */
export const usePromoDiscountText = () => {
  const discountTextWithoutMonitoring = useSelector(selectPromoDiscountText)
  return overloadMaybe(discountTextWithoutMonitoring)
}
