import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { useMonthsOfFreeServiceText } from '@ecomm/promotions-hooks'

type ContentProps = {
  readonly discount?: string | null
}

export function OptedInContent({ discount }: ContentProps) {
  const siteLocale = useLocale()
  const isUS = siteLocale === 'en-US'
  const microCopy = useMicroCopy()
  const monitoringType = isUS
    ? `${microCopy['experiment-service-plan-name']} Monitoring`
    : 'Pro Premium Monitoring'

  const monthsOfService = useMonthsOfFreeServiceText(true)

  const monthsOfServiceDisplay =
    monthsOfService.match(/\d/) !== null
      ? monthsOfService
      : `1 ${monthsOfService}`

  return (
    <>
      {discount ? (
        <>
          <span className="font-semibold text-orange-700">
            You’re saving {`${discount}`} today{' '}
          </span>
          <span>and getting </span>
        </>
      ) : (
        <span>You’re getting </span>
      )}
      <span className="font-semibold">
        {monthsOfServiceDisplay} of 24/7 {monitoringType}
      </span>
    </>
  )
}

export function OptedOutContent({ discount }: ContentProps) {
  const siteLocale = useLocale()
  const isUS = siteLocale === 'en-US'
  const microCopy = useMicroCopy()
  const monitoringType = isUS
    ? `${microCopy['experiment-service-plan-name']} Monitoring`
    : 'Pro Premium Monitoring'
  const monthsOfServiceDisplay = useMonthsOfFreeServiceText(true)

  return (
    <>
      <p className="m-0 mb-2 font-semibold text-orange-700">
        You have removed your free month of 24/7 {monitoringType}
      </p>
      <span>
        Claim your {monthsOfServiceDisplay}
        {discount ? <strong> and save an additional {discount}</strong> : null}.
        No contracts, cancel any time.*
      </span>
    </>
  )
}

/**
 * ODMON IN
 */
export function OptedInContentODMON({ discount }: ContentProps) {
  return (
    <>
      {discount ? (
        <>
          <span className="font-semibold text-orange-700">
            You’re saving {`${discount}`} today{' '}
          </span>
          <span>and getting </span>
        </>
      ) : (
        <span>You’re getting </span>
      )}
      <span className="font-semibold">1 free month of Pro Monitoring</span>
    </>
  )
}

/**
 * ODMON OUT
 */
export function OptedOutContentODMON({ discount }: ContentProps) {
  return (
    <>
      <p className="m-0 mb-2 font-semibold text-orange-700">
        You have removed your one free month of Pro Monitoring
      </p>
      <span>
        Claim your one free month
        <strong> and save up to {discount}</strong>. No contracts, cancel any
        time.
      </span>
    </>
  )
}
