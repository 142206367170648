import { useLocale } from '@ecomm/data-hooks'
import { NativeVideo } from '@ecomm/shared-components'
import React from 'react'

import { Locale } from '../../hooks/useLocalizedData'
import { data as dataGB } from './data-en-GB'
import { data as dataUS } from './data-en-US'

export type ScoutVideoType =
  | 'AlarmMonitoringScoutVideo'
  | 'ScoutHomePageVideo'
  | 'ScoutProductInstallationVideo'
  | 'ScoutProductLiveGuardVideo'

type ScoutVideoProps = {
  readonly type: ScoutVideoType
  readonly className?: string
  readonly containerClassName?: string
  readonly autoPlay?: boolean
}

/**
 * A data-wrapper component that will render different Scout Videos based strongly on their
 * hard-coded location for the Scout A/B test. This component should eventually be deprecated
 * and data should be plumbed from Contentful into a Native Video component.
 */
export function ScoutVideo({
  type,
  className = '',
  containerClassName = '',
  autoPlay = false
}: ScoutVideoProps) {
  const locale = useLocale()

  const data = locale === Locale.EnUS ? dataUS : dataGB
  const videoData = data[type]

  return (
    <NativeVideo
      {...videoData}
      autoPlay={autoPlay}
      className={className}
      containerClassName={containerClassName}
      data-component="scout-video"
    />
  )
}
