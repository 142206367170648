import { z } from '@simplisafe/ewok'

/**
 * Parses contentfulJson.
 */
export const jsonSchema = z.object({
  content: z
    .object({
      internal: z
        .object({
          content: z.string()
        })
        .default({ content: '{}' })
    })
    .default({ internal: { content: '{}' } })
})
