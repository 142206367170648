//@ts-nocheck
import classNames from 'classnames'
import contains from 'ramda/src/contains'
import isNil from 'ramda/src/isNil'
import unless from 'ramda/src/unless'
import React, { FC } from 'react'

import * as css from './SSTable.module.scss'

type activeIndexType = readonly number[]

type SSTableProps = {
  readonly className?: string
  readonly head: readonly string[]
  readonly body: ReadonlyArray<ReadonlyArray<string>>
  readonly activeIndex?: activeIndexType
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const SSTable: FC<SSTableProps> = ({
  className,
  head,
  body,
  activeIndex,
  dataComponent = SSTable.name
}: SSTableProps) => {
  return (
    <div
      className={classNames(css.table, className)}
      data-component={dataComponent}
    >
      <table className={classNames(css.tableData)}>
        <thead>
          <tr>
            {head.map((text: string) => (
              <th key={`head-${text}`}>{text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {body.map((data: ReadonlyArray<string>, index: number) => (
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
            <tr
              className={classNames({
                [css.active]: unless(
                  isNil,
                  contains(index)
                )(activeIndex as activeIndexType)
              })}
              key={`row-${index}`}
            >
              {data.map((text: string) => (
                <td key={`data-${text}`}>{text}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SSTable
