import { match } from 'ts-pattern'

export const getSeoDescription = (partner: string) => {
  return match(partner)
    .when(
      (p: string) => p === 'lightspeed',
      () =>
        'Discover the Ultimate Home Security: SimpliSafe x Lightspeed. Protect your home with cutting-edge technology and broadband. Explore home security and alarm products for a safer home!'
    )
    .otherwise(() => '')
}
