import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.DoorChime,
    text: 'Door chime sound'
  },
  {
    icon: SharedIcons.PeelAndStick,
    text: 'Peel-and-stick install'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.SecretAlerts,
    text: 'Sends secret alerts'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 5 yrs'
  }
]

const description = {
  content: `Entry sensors are your first line of defense against intrusion. Use them to secure your most commonly used and accessible ground-floor doors and windows, because that's where criminals will look first. (For rooms with multiple windows, consider a motion sensor.)
  \nEntry sensors can make your life easier with optional settings. Have your Base Station play a chime or announce whenever a window or door is opened. You can also set secret alerts to protect interior spaces too, like cabinets and safes.`
}

const specs = {
  content: `1.25 x 1.5 x 0.62 inches
    \nOptional screw kit for wall mounting
    \n1 CR-2032 Lithium 3V battery (included)
    \n1 year manufacturer limited warranty. Subscriber lifetime warranty available with Fast Protect Monitoring plan.
  `
}

const faqs = {
  contentfulId: '3IbxdwZVwzGLcUokpJ6itu'
}

export const entrySensorData: ProductData = {
  productId: '1o3hjyOqjqbFLh8XDFNzMw',
  sensorName: 'Entry Sensor',
  features,
  description,
  specs,
  faqs
}
