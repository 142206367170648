import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulImage } from '@ecomm/contentful/components'
import { ContentfulRichText } from '@ecomm/shared-components'

import {
  ContentfulImageQuerySchema,
  VerifyPassTextSchema
} from '../../../templates/ProtectorsTemplate/schema'

const smallTextOptions: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const img: ContentfulImageQuerySchema = node.data.target
      return (
        <div className="flex w-full items-center justify-center gap-3">
          <p className="mb-0">Verification powered by</p>
          <ContentfulImage
            classNameOverride="inline h-12"
            description={img.description}
            originalHeight={img.file.details.image.height}
            originalWidth={img.file.details.image.width}
            url={img.file.url}
          />
        </div>
      )
    }
  }
}

export default function VerifyPassText(data: VerifyPassTextSchema) {
  return <ContentfulRichText {...data.text} optionsCustom={smallTextOptions} />
}
