import { SHIELD_SKU } from '@ecomm/data-constants'

import { useOdmonCameraQuery } from '../../../hooks/useOdmonCameraQuery'
import type { ProductCardFragment } from '../../ProductCard/schema'

const useOdmonReplaceCameraItem = (
  pkgItems: readonly ProductCardFragment[],
  isVariant: boolean
) => {
  const odmonCamera: ProductCardFragment = useOdmonCameraQuery()
  const index = pkgItems.findIndex(item => item.sku === SHIELD_SKU)

  if (!isVariant || index === -1) {
    return pkgItems
  } else {
    return pkgItems.map((item, i) => (i === index ? odmonCamera : item))
  }
}
export default useOdmonReplaceCameraItem
