import { useFooterQuery } from '@ecomm/footer-data'
import { Footer } from './Footer'
import { Suspense } from 'react'
import { ErrorBoundary } from '@ecomm/error-handling'

type Props = {
  footerId: string
  type?: 'Full' | 'Slim'
  showCountrySelector?: boolean
}

function FooterContent({ footerId, type, showCountrySelector }: Props) {
  const footer = useFooterQuery(footerId)
  return (
    <Footer
      {...footer}
      type={type || footer.type}
      showCountrySelector={showCountrySelector}
    />
  )
}

/**
 * For use in Gatsby only!
 *
 * Fetches footer data from Contentful and renders the Footer component wrapped in Suspense.
 */
export function GatsbyFooter(props: Props) {
  return (
    <Suspense>
      <ErrorBoundary>
        <FooterContent {...props} />
      </ErrorBoundary>
    </Suspense>
  )
}
