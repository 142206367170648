/**
 * Converts a ReadOnly array into a mutable Array.
 *
 * @example
 * const a: ReadonlyArray<number> = [1,2,3]
 *
 * const b: Array<number> = mutableArray(a)
 */
export default function mutableArray<T>(
  t: Array<T> | ReadonlyArray<T>
): Array<T> {
  return Array.of(...t)
}
