import { Locale } from './types'

/**
 * Type check if a string is a valid locale.
 */
const isValidLocale = (
  localeCode: Locale | string | undefined
): localeCode is Locale => ['en-US', 'en-GB'].includes(localeCode || '')

export default isValidLocale
