import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

type OutdoorCameraWarningModalEvent = 'outdoor-cam-warning-modal-click'

export const useTrackingOutdoorCameraWarning = (location: string) => {
  const { trackEvent } =
    useTracking<TrackingData<OutdoorCameraWarningModalEvent>>()

  return useCallback(() => {
    trackEvent({
      event: 'outdoor-cam-warning-modal-click',
      location
    })
  }, [trackEvent, location])
}
