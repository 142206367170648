// extracted by mini-css-extract-plugin
export var cardBadgeMobile = "CardBadge-module--cardBadgeMobile--2916c";
export var cardBadgeWrapper = "CardBadge-module--cardBadgeWrapper--6cf68";
export var closedStateImg = "CardBadge-module--closedStateImg--48d08";
export var desc = "CardBadge-module--desc--1041c";
export var guaranteeBadgeDesc = "CardBadge-module--guaranteeBadgeDesc--0f4b1";
export var guaranteeBadgeTitle = "CardBadge-module--guaranteeBadgeTitle--2f4bc";
export var guaranteeBadgecontent = "CardBadge-module--guaranteeBadgecontent--e2080";
export var guaranteeBadgeimage = "CardBadge-module--guaranteeBadgeimage--9e9fd";
export var h4 = "CardBadge-module--h4--dc54c";
export var img = "CardBadge-module--img--8a9d1";
export var imgHover = "CardBadge-module--imgHover--fbd23";
export var imgResposive = "CardBadge-module--imgResposive--9b310";
export var pdpGuarantee = "CardBadge-module--pdpGuarantee--15a88";