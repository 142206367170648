//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode, useState } from 'react'

import { SSButton, Text } from '..'
import QuantityChanger from '../QuantityChanger'
import type { SSButtonProps } from '../SSButton'
import * as css from './CardItem.module.scss'

export type CardItemProps = {
  /** Props for the CTA button. If not provided, button will not be displayed */
  readonly buttonProps: SSButtonProps
  /** Default value for the quantity selector. Must be a positive integer and less than `maxQuantity`. */
  readonly defaultQuantity?: number
  readonly description: string
  readonly img: ReactNode
  /** If false, product cannot be added to cart */
  readonly isCartable?: boolean
  /** Maximum allowable value for the quantity selector. Must be a positive integer. */
  readonly maxQuantity?: number
  /** Minimum allowable value for the quantity selector. Must be an integer >= 0. Defaults to 1. */
  readonly minQuantity?: number
  /** Handler for clicking the CTA button. Passes the selected quantity value as the first arg. */
  readonly onButtonClick: (quantity: number) => void
  /** Out Of Stock message, displayed when a sensor is out of stock */
  readonly outOfStockMessage?: ReactNode
  readonly onQuantityChange?: (quantity: number) => void
  /** Handler for clicking the title. */
  readonly onTitleClick?: () => void
  /** Component with fully formatted price string, including currency symbol and any text like 'each' */
  readonly price: ReactNode
  /** Displays a message about a quantity limit available for purchase  */
  readonly productLimitMessage?: string
  /** If false, the CTA button will not be displayed. Defaults to true. */
  readonly showButton?: boolean
  /** If false, the quantity selector will not be displayed. Defaults to true. */
  readonly showQuantity?: boolean
  /** Product name, displayed as the card title */
  readonly title: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly errorMessage?: ReactNode
  readonly cartUpdatedText?: ReactNode

  readonly variations?: readonly string[]
  // returns an array of disabled colors
  readonly variationsDisabled?: readonly string[]
  readonly selectedVariation?: string
  readonly onVariationClick?: (color: string) => void
  readonly imageTag?: ReactNode
}

const getSelectedVariation = (
  variations: readonly string[],
  disabledVariations: readonly string[]
) => {
  const availableVariations = variations.filter(
    v => !disabledVariations.includes(v)
  )
  return availableVariations.length > 0 ? availableVariations[0] : undefined
}

/** @deprecated Do not use ss-react-components*/
const CardItem: FC<CardItemProps> = ({
  buttonProps,
  defaultQuantity = 1,
  description,
  img,
  isCartable = true,
  maxQuantity = 10,
  minQuantity = 1,
  onButtonClick,
  onQuantityChange: onQuantityChangeProp,
  outOfStockMessage,
  onTitleClick,
  price,
  productLimitMessage,
  showButton = true,
  showQuantity = true,
  title,
  dataComponent = CardItem.name,
  errorMessage,
  cartUpdatedText,
  variations = [],
  variationsDisabled = [],
  selectedVariation,
  onVariationClick,
  imageTag
}: CardItemProps) => {
  const [quantity, setQuantity] = useState(defaultQuantity)

  const handleQuantityChange = (q: number) => {
    onQuantityChangeProp && onQuantityChangeProp(q)
    setQuantity(q)
  }

  const defaultVariation =
    selectedVariation && variationsDisabled.includes(selectedVariation)
      ? getSelectedVariation(variations, variationsDisabled)
      : selectedVariation
  return (
    <div
      className={classNames(
        'rounded-base border-neutral-light-50 overflow-hidden border border-solid',
        css.cardItem
      )}
      data-component={dataComponent}
    >
      <div className={css.image}>
        {img}
        {imageTag ? <div className={css.imageTag}>{imageTag}</div> : null}
      </div>
      <div className={css.content}>
        <div className={css.titleWrapper}>
          <div className={classNames(css.title, 'h5')} onClick={onTitleClick}>
            {title}
          </div>
          <div className={css.variations}>
            {variations.length > 1 &&
              variations.map(variation => (
                <div
                  className={classNames(
                    css.color,
                    { [css.selected]: defaultVariation === variation },
                    {
                      [css.colorDisabled]:
                        variationsDisabled.includes(variation)
                    }
                  )}
                  key={variation}
                  onClick={() =>
                    onVariationClick &&
                    !variationsDisabled.includes(variation) &&
                    onVariationClick(variation)
                  }
                  style={{ backgroundColor: variation }}
                />
              ))}
          </div>
        </div>
        <div className={css.description}>{description}</div>
        <div className={css.price}>{price}</div>
        <div className={css.quantityChecker}>
          {showQuantity ? (
            <QuantityChanger
              defaultValue={defaultQuantity}
              max={maxQuantity}
              min={minQuantity}
              onChange={handleQuantityChange}
            />
          ) : null}
          {outOfStockMessage ? (
            <div
              className={classNames(
                css.additionalText,
                'fontSize12',
                'outOfStockText'
              )}
            >
              {outOfStockMessage}
            </div>
          ) : null}
        </div>
        {productLimitMessage ? (
          <Text
            className={css.productLimitMessage}
            textAlignment="right"
            textSize="xs"
          >
            {productLimitMessage}
          </Text>
        ) : null}
      </div>
      <div className={classNames('m2', css.ctaContainer)}>
        {errorMessage ? <div className={'m1_b'}>{errorMessage}</div> : null}
        {cartUpdatedText ? (
          <div className={classNames('m1_b', css.cartUpdatedText)}>
            {cartUpdatedText}
          </div>
        ) : null}
        {showButton ? (
          <SSButton
            {...buttonProps}
            className={(css.cta, buttonProps.className)}
            disabled={!isCartable}
            onClick={() => onButtonClick(quantity)}
          />
        ) : null}
      </div>
    </div>
  )
}

export default CardItem
