import { RenderMark, RenderNode } from '@contentful/rich-text-react-renderer'
import { INLINES, MARKS, Node } from '@contentful/rich-text-types'
import React, { ReactElement } from 'react'

/**
 * TODO: Consider moving this to default options in ContentfulRichText components.
 * Removes need for customOptions for all OfferTag users.
 * Will need to examine if placeholder text is the same across all components, at which case
 * the helper can reach into Redux for the placeholder text.
 */
export const getRenderNodeWithPlaceholder = (placeholder: ReactElement) => {
  return {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
    renderMark: {
      [MARKS.BOLD]: (text: string) => (
        <span key={`key-span-${text}`}>{text}</span>
      )
    } as RenderMark,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: Node) => {
        const isPlaceHolder =
          node?.data?.target?.internal?.type === 'ContentfulPlaceholder'
        return isPlaceHolder && placeholder
      }
    } as RenderNode
  }
}
