import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track video player playback events.
 */
export function trackVideoEvents(
  action: 'complete' | 'pause' | 'play' | 'progress',
  video_provider: string,
  video_title: string,
  video_percent?: number
) {
  video_percent && action === 'progress'
    ? getRudderstack(r =>
        r.track(`video_${action}`, {
          video_provider,
          video_title,
          video_percent
        })
      )
    : getRudderstack(r =>
        r.track(`video_${action}`, {
          video_provider,
          video_title
        })
      )
}
