import { useCallback, useState } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

type EventProps = React.SyntheticEvent<HTMLVideoElement, Event>

const EVENT_NAME = 'html5_video'
const VIDEO_PROVIDER_HTML5 = 'generic html5 video player'
const INITIAL_PROGRESS_MARKER_VALUES: Record<string, boolean> = {
  0: false,
  25: false,
  50: false,
  75: false,
  100: false
}

const getVideoTitle = (videoSrc: string) => {
  const urlPieces = videoSrc.split('/')
  const urlPath = urlPieces[urlPieces.length - 1]
  return decodeURIComponent(urlPath)
}

// Todo: _greatestMarker prop only exists for adding testing coverage scenarios.
// rewrite so handleTrackingVideoTimeUpdate can be tested in isolation without
// passing in this prop.
export function useTrackingVideo(_greatestMarker?: number) {
  const { trackEvent } = useTracking<TrackingData>()

  // An object where keys are 0, 25, 50, 75 and values are true/false depending if progress has been reached.
  const [progressMarkers, setProgressMarkers] = useState(
    INITIAL_PROGRESS_MARKER_VALUES
  )
  // Keeps track of the highest percentage marker reached by the user.
  const [greatestMarker, setGreatestMarker] = useState(_greatestMarker || 0)

  const handleTrackingVideoEnded = useCallback(
    (e: EventProps) => {
      trackEvent({
        event: EVENT_NAME,
        video_percent: '100',
        video_provider: VIDEO_PROVIDER_HTML5,
        video_status: 'complete',
        video_title: getVideoTitle(e.currentTarget.currentSrc)
      })
    },
    [trackEvent]
  )

  const handleTrackingVideoPause = useCallback(
    (e: EventProps) => {
      // Prevent pause events from firing onEnd.
      greatestMarker < 100 &&
        trackEvent({
          event: EVENT_NAME,
          video_percent: `${greatestMarker}`,
          video_provider: VIDEO_PROVIDER_HTML5,
          video_status: 'pause',
          video_title: getVideoTitle(e.currentTarget.currentSrc)
        })
    },
    [greatestMarker, trackEvent]
  )

  const handleTrackingVideoPlay = useCallback(
    (e: EventProps) => {
      trackEvent({
        event: EVENT_NAME,
        video_percent: `${greatestMarker}`,
        video_provider: VIDEO_PROVIDER_HTML5,
        video_status: 'play',
        video_title: getVideoTitle(e.currentTarget.currentSrc)
      })
    },
    [greatestMarker, trackEvent]
  )

  const handleTrackingVideoTimeUpdate = useCallback(
    (e: EventProps) => {
      const percentagePlayed = Math.floor(
        (100 * e.currentTarget.currentTime) / e.currentTarget.duration
      )
      Object.keys(progressMarkers)
        .map(Number)
        .forEach(n => {
          percentagePlayed >= n && n > greatestMarker && setGreatestMarker(n)
        })

      const fireProgressEvent = () => {
        setProgressMarkers(previousState => ({
          ...previousState,
          [greatestMarker]: true
        }))

        // Don't fire progress updates at 100% video completion.
        greatestMarker < 100 &&
          trackEvent({
            event: EVENT_NAME,
            video_percent: `${greatestMarker}`,
            video_provider: VIDEO_PROVIDER_HTML5,
            video_status: 'progress',
            video_title: getVideoTitle(e.currentTarget.currentSrc)
          })
      }

      // Only fire the progress event if it hasn't been sent to GA yet.
      greatestMarker && !progressMarkers[greatestMarker] && fireProgressEvent()
    },
    [greatestMarker, progressMarkers, trackEvent]
  )

  return {
    handleTrackingVideoEnded,
    handleTrackingVideoPause,
    handleTrackingVideoPlay,
    handleTrackingVideoTimeUpdate
  }
}
