import { Maybe } from 'monet'

import { formatDisplayPrice, safeIsNotNan } from '../../commercetools/price'

export type PriceFormatter = (_price: string) => string

export const priceDefault: PriceFormatter = price => price

export const pricePerUnit: PriceFormatter = price => `${price} ea`

export const formatPrice = (price: Maybe<number>, formatter: PriceFormatter) =>
  price
    .chain(safeIsNotNan)
    .chain(formatDisplayPrice)
    .map(formatter)
    .orUndefined()
