import classNames from 'classnames'
import React from 'react'

type Props = {
  readonly moveTo: (index: number) => void
  readonly position: number
  readonly index: number
}

export default function Bullet({ moveTo, index, position }: Props) {
  const isActive = position === index
  return (
    <li className="inline-flex h-5 list-none md:flex">
      <button
        aria-label={`Go to slide ${index + 1}`}
        className={classNames(
          'bg-neutral-medium mx-1.5 h-[0.4375rem] w-[0.4375rem] cursor-pointer rounded-[50%] border-0 p-0',
          { '!bg-neutral-black': isActive }
        )}
        data-component={`Bullet/${isActive ? 'Active' : 'Unactive'}/${index}`}
        onClick={() => moveTo(index)}
      ></button>
    </li>
  )
}
