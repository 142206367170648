import { graphql, useStaticQuery } from 'gatsby'

import plpPageSchema from './plpPageSchema'
import { parseObjectKey } from '@simplisafe/ewok'

export const useOdmonRefurbishedPLPQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query OdmonRefurbishedPlpTemplate {
      contentfulPackageListingPage(contentful_id: { eq: "4TD3O4ZXY4Yz83lEM86gEl" }) {
        ...plpPage
        ...plpPageVariations
      }
    }
  `)

  return parseObjectKey(
    staticQuery,
    'contentfulPackageListingPage',
    plpPageSchema
  )
}
