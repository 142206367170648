import { parseContentfulJson } from '@ecomm/micro-copy'
import { GatsbyImage } from '@ecomm/shared-components'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { privacyHeroBannerImageSchema } from './schema'

export function PrivacyHeroBanner() {
  const data = privacyHeroBannerImageSchema.parse(
    useStaticQuery(graphql`
      query PrivacyHeroBanner {
        desktopImage: contentfulAsset(
          contentful_id: { eq: "20CYYyYNks27qgFOfemyiN" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        tabletImage: contentfulAsset(
          contentful_id: { eq: "2JZXoL3Rwucaxe5nZhTE0H" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        mobileImage: contentfulAsset(
          contentful_id: { eq: "7AE5sVzeiqArEuJH9JZgQl" }
        ) {
          gatsbyImageData(placeholder: BLURRED)
        }
        copy: contentfulJson(contentful_id: { eq: "5az93epwKKvbPq2qcyh3Pk" }) {
          ...json
        }
      }
    `)
  )

  const copy = parseContentfulJson(data, 'copy')

  return (
    <div data-component="PrivacyHeroBanner">
      <h1 className="md:my-10 lg:mb-7 lg:mt-1 text-4xl md:text-6xl lg:text-heading-1-size mt-10 md:px-8 lg:px-0 text-center">
        {copy.get('title')}
      </h1>
      <div className="relative mt-3 mx-auto lg:mt-0">
        {data.desktopImage ? (
          <div className="hidden lg:block">
            <GatsbyImage
              className="min-h-[320px] rounded-2xl"
              image={data.desktopImage}
            />
          </div>
        ) : null}
        {data.tabletImage ? (
          <div className="hidden md:block lg:hidden">
            <GatsbyImage
              className="min-h-[320px] rounded-2xl"
              image={data.tabletImage}
            />
          </div>
        ) : null}
        {data.mobileImage ? (
          <div className="block md:hidden">
            <GatsbyImage
              className="min-h-[320px] rounded-2xl"
              image={data.mobileImage}
            />
          </div>
        ) : null}
      </div>
      <hr className="mx-4 md:mx-8 max-w-8xl my-10 lg:my-8 lg:mx-1" />
    </div>
  )
}
