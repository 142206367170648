import {
  type SubmitOrderClickPlacement,
  trackSubmitOrderClick
} from '@ecomm/cdp-tracking'
import { useTrackingSubmitOrderClick } from '@ecomm/tracking'
import classNames from 'classnames'

type Props = {
  readonly onClick: () => void
  readonly disabled?: boolean
  readonly className?: string
  readonly hidden?: boolean
  readonly trackingLabel: SubmitOrderClickPlacement
}
export function ZuoraSubmitButton({
  onClick,
  disabled = false,
  className = '',
  hidden = false,
  trackingLabel
}: Props) {
  const trackSubmitOrderClickGTM = useTrackingSubmitOrderClick()

  const submitButton = () => {
    trackSubmitOrderClickGTM(trackingLabel)
    trackSubmitOrderClick(trackingLabel)
    return onClick()
  }

  const style = classNames(
    `min-w-[176px] cursor-pointer rounded-base border-2 border-solid px-6 py-3 transition-[background,border] duration-200
    ease-out md:h-[56px] flex justify-center text-base text-white md:text-lg`,
    {
      'border-btn-primary bg-btn-primary hover:border-[#0a5dbd] hover:bg-[#0a5dbd]':
        !disabled,
      'cursor-not-allowed border-[#0a5dbd] border-neutral-medium bg-[#0a5dbd] bg-neutral-medium hover:border-neutral-medium hover:bg-neutral-medium':
        disabled
    },
    className
  )

  return (
    <button
      className={style}
      data-component="ZuoraSubmitButton"
      data-testid="ZuoraSubmitButton"
      disabled={disabled}
      hidden={hidden}
      onClick={submitButton}
    >
      Place order
    </button>
  )
}
