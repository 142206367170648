/* eslint-disable sort-keys-fix/sort-keys-fix */

import classNames from 'classnames'
import React, { FC, HtmlHTMLAttributes, ReactNode } from 'react'

import { SiteColor } from '../types/colors'

export type TextProps = HtmlHTMLAttributes<HTMLDivElement> & {
  /** HTML markup typically rendered by RichText and Markdown. */
  readonly children?: ReactNode
  /** An optional class to apply. */
  readonly className?: string
  /** Defines the data-component attribute value. */
  readonly dataComponent?: string
  /** Letter spacing. Defaults to normal. */
  readonly letterSpacing?: 'normal' | 'wide'
  /** Defines the list style on unordered lists. Defaults to disc. */
  readonly listStyle?: 'checkmark' | 'disc' | 'square'
  /** Applies a max-width for optimal character lemgth (about 8 out of 12 grid units). */
  readonly maxWidth?: boolean
  /** Text alignment */
  readonly textAlignment?: 'center' | 'left' | 'right'
  /** Text color */
  readonly textColor?: SiteColor
  /** Changes body copy/paragraph size to be either 'xs' (12px), 'sm' (14px), or 'md' (16; the default setting). */
  readonly textSize?: '2xl' | 'lg' | 'md' | 'sm' | 'xl' | 'xs'
  /** Sets body copy/paragraph font weight to default for body copy (300, default), light (200), or medium (500). */
  readonly fontWeight?: 'body' | 'light' | 'medium'
  readonly display?: string
  /** Text color will not be set */
  readonly inheritTextColor?: boolean
  /** Enables newer tailwind based prose typography */
  readonly useTailwind?: boolean
  /**  */
  readonly proseTheme?: 'dark' | 'default'
}

/** @deprecated Do not use ss-react-components*/
const Text: FC<TextProps> = ({
  children,
  className,
  fontWeight,
  letterSpacing = 'normal',
  listStyle,
  maxWidth,
  textAlignment = 'left',
  textColor = 'neutralBlack',
  textSize,
  display,
  dataComponent = Text.name,
  inheritTextColor,
  useTailwind = false,
  proseTheme = 'default',
  ...rest
}: TextProps) => {
  return (
    <div
      className={classNames(
        display,
        'breakLine',
        {
          // this enables tailwind's typography plugin classes
          prose: useTailwind,
          'md:prose-md lg:prose-lg': !textSize && useTailwind,
          'prose-dark': proseTheme === 'dark' && useTailwind,
          'text-center': textAlignment === 'center',
          'text-left': textAlignment === 'left',
          'text-right': textAlignment === 'right',
          inlineElement: display === 'inlineElement',
          'text-inherit': inheritTextColor,
          'text-2xl': textSize === '2xl',
          'text-xl': textSize === 'xl',
          'text-lg': textSize === 'lg',
          'text-base': textSize === 'md' && useTailwind,
          'text-sm': textSize === 'sm' && useTailwind,
          'text-xs': textSize === 'xs' && useTailwind,
          'max-w-none': !maxWidth && useTailwind,

          // these are classes that we will eventually deprecate after we have all our tailwind typography themes created
          ssProse: !useTailwind,
          fontWeightLight: fontWeight === 'light' && !useTailwind,
          fontWeightMedium: fontWeight === 'medium' && !useTailwind,
          '-tracking-widest': letterSpacing === 'wide' && !useTailwind,
          'font-medium': fontWeight === 'medium' && useTailwind,
          listStyleCheckmark: listStyle === 'checkmark' && !useTailwind,
          listStyleDisc: listStyle === 'disc' && !useTailwind,
          listStyleSquare: listStyle === 'square' && !useTailwind,
          maxWidthProse: maxWidth && !useTailwind,
          [`${textColor}TextColor`]:
            textColor !== undefined && !inheritTextColor && !useTailwind,
          textSizeSM: textSize === 'sm' && !useTailwind,
          textSizeXS: textSize === 'xs' && !useTailwind
        },
        className
      )}
      data-component={dataComponent}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Text
