//@ts-nocheck
import { useField } from 'formik'
import React, { FC } from 'react'

import * as css from './FormCheckbox.module.scss'
export type FormCheckboxProps = {
  readonly checked?: boolean
  readonly label: string
  /** Field name, note that this prop is being used to wire the field to an specific formik field */
  readonly name: string
  /** Label text that will be rendered right next to the checkbox */
}

/** @deprecated Do not use ss-react-components*/
const FormCheckbox: FC<FormCheckboxProps> = ({
  label,
  name
}: FormCheckboxProps) => {
  const [field] = useField({
    name,
    type: 'checkbox'
  })

  return (
    <div className={css.container}>
      <input
        className={css.checkbox}
        id={name}
        type="checkbox"
        {...field}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        checked={field.value}
      />
      <label className={css.label} htmlFor={name}>
        {label}
      </label>
    </div>
  )
}

export default FormCheckbox
