import { voidFn } from '@simplisafe/ewok'
import { window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { SprinklrLiveChatClient } from './sprinklr'

export function getSprChat(fn: (s: SprinklrLiveChatClient) => void) {
  const chat = O.fromNullable(window.sprChat)
  return pipe(chat, O.fold(voidFn, fn))
}

export function setSprinklrChatAppId(id: string) {
  window.sprChatSettings ||= {}
  window.sprChatSettings['appId'] = id
}
