//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import * as css from './IconWithText.module.scss'

export type IconPositionToText =
  | 'bottom'
  | 'bottomcenter'
  | 'left'
  | 'right'
  | 'top'
  | 'topcenter'
export type IconWithTextSize =
  | 'extralarge'
  | 'extrasmall'
  | 'large'
  | 'medium'
  | 'responsive'
  | 'small'
export type IconVerticalAlignment = 'bottom' | 'middle' | 'top'
export type TextAlignment = 'center' | 'left' | 'right'

export type IconWithTextProps = {
  /** data-component attribute value */
  readonly dataComponent?: string
  /** The icon that is displayed with text. */
  readonly icon: ReactNode
  /** The position of the icon compared to the text. */
  readonly iconPosition?: IconPositionToText
  /** The size of the icon. */
  readonly iconSize?: IconWithTextSize
  /** Vertical alignment of the icon to the text. */
  readonly iconVerticalAlignment?: IconVerticalAlignment
  /** A RichText ReactNode that gets displayed with the icon. */
  readonly text: ReactNode
  /** The text-align for just the text div. */
  readonly textAlignment?: TextAlignment
}

/** @deprecated Do not use ss-react-components*/
const IconWithText: FC<IconWithTextProps> = ({
  dataComponent = IconWithText.name,
  icon,
  iconPosition = 'left',
  iconSize = 'medium',
  iconVerticalAlignment = 'middle',
  text,
  textAlignment = 'left'
}: IconWithTextProps) => {
  // This is a temporary fix until RichText is ported into frontend. The fix should be applied in ss-frontend-components
  const iconPositionFixed = iconPosition.toLowerCase()
  const textAlignmentFixed = textAlignment.toLowerCase()

  const wrapperClassNames = classNames(css.wrapper, {
    [css.verticalIcon]:
      iconPositionFixed.includes('bottom') || iconPositionFixed.includes('top')
  })

  const iconClassNames = classNames(
    css.icon,
    {
      [css.extraSmall]: iconSize === 'extrasmall',
      [css.small]: iconSize === 'small',
      [css.medium]: iconSize === 'medium',
      [css.large]: iconSize === 'large',
      [css.extraLarge]: iconSize === 'extralarge',
      [css.responsive]: iconSize === 'responsive'
    },
    {
      [css.topLeft]: iconPositionFixed === 'top',
      [css.topCenter]: iconPositionFixed === 'topcenter',
      [css.right]: iconPositionFixed === 'right',
      [css.bottomLeft]: iconPositionFixed === 'bottom',
      [css.bottomCenter]: iconPositionFixed === 'bottomcenter',
      [css.left]: iconPositionFixed === 'left'
    },
    {
      [css.alignMiddle]: iconVerticalAlignment === 'middle',
      [css.alignTop]: iconVerticalAlignment === 'top',
      [css.alignBottom]: iconVerticalAlignment === 'bottom'
    }
  )

  const iconDiv = <div className={iconClassNames}>{icon}</div>

  const textClassNames = classNames(css.content, {
    [css.iconAdjacentToTextRight]: iconPositionFixed === 'right',
    [css.iconAdjacentToTextLeft]: iconPositionFixed === 'left',
    alignCenter: textAlignmentFixed === 'center',
    alignLeft: textAlignmentFixed === 'left',
    alignRight: textAlignmentFixed === 'right'
  })

  return (
    <div className={wrapperClassNames} data-component={dataComponent}>
      {!iconPositionFixed.includes('bottom') && iconPositionFixed !== 'right'
        ? iconDiv
        : ''}
      <div className={textClassNames}>{text}</div>
      {iconPositionFixed.includes('bottom') || iconPositionFixed === 'right'
        ? iconDiv
        : ''}
    </div>
  )
}

export default IconWithText
