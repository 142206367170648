import { sendMemberData } from '@ecomm/partners-utils'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { IOUpdateCart } from '@simplisafe/ss-ecomm-data/cart/actions'
import { buildCustomFieldUpdateAction } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

/*
 * Can be used for all member capture in the future,
 * would need to add additional data to the sendMemberData call
 */
export const useSendMemberData = () => {
  const dispatch = useDispatch()
  const trackMetricEvent = useTrackMetricEvent()
  const memberNumber = getValueFromPartnerCookie('partnerMemberId') || null
  const partnerName = getValueFromPartnerCookie('partnerName') || null
  useEffect(() => {
    const partnerMemberNumberAction = buildCustomFieldUpdateAction({
      name: 'partnerMemberNumber',
      value: memberNumber
    })
    const updateActions = [partnerMemberNumberAction]
    memberNumber &&
      dispatch(
        IOUpdateCart(updateActions, () =>
          trackMetricEvent(
            'member-data-error',
            {
              Error: 'Cart member data attribute not updated'
            },
            true
          )
        )
      )
    const memberFetch = async () => {
      return partnerName && partnerName === 'hub'
        ? pipe(
            await sendMemberData({
              partnerName: partnerName,
              memberNumber: memberNumber
            })(),
            E.match(
              (e: Error) => {
                trackMetricEvent(
                  'member-data-error',
                  {
                    Error: 'Member data not captured: ' + e
                  },
                  true
                )
              },
              _ => {
                return _
              }
            )
          )
        : null
    }

    partnerName && memberFetch()
  }, [memberNumber, partnerName])
}
