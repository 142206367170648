import { businessCapture } from '@ecomm/data-leads'
import { logError } from '@ecomm/error-handling'
import { ContentfulRichText } from '@ecomm/shared-components'
import { match } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useState } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useLeadGenCaptureV2 } from '@ecomm/tracking'
import { businessLeadSchema } from '../../schemas/emailForm'
import type { BusinessFormSchema } from '../../schemas/form'
import Form from '../common/Form'

export default function BusinessSecurityForm({
  button,
  form,
  note,
  successMessage
}: BusinessFormSchema) {
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    loading: false,
    error: false
  })

  const captureLeadV2 = useLeadGenCaptureV2()

  const onSubmit = async (submitFormValues: FieldValues) => {
    const form = {
      firstName: submitFormValues['firstName'],
      lastName: submitFormValues['lastName'],
      numLocations: submitFormValues['numLocations'],
      phone: submitFormValues['phone'],
      email: submitFormValues['email'],
      companyName: submitFormValues['companyName']
    }

    setFormStatus(prev => ({ ...prev, loading: true }))

    pipe(
      await businessCapture({
        ...form,
        source: 'business_security_desktop',
        businessType: 'Business Owner'
      })(),
      match(
        (e: Error) => {
          setFormStatus({
            submitted: true,
            loading: false,
            error: true
          })
          logError(e)
        },
        _ => {
          setFormStatus({
            submitted: true,
            error: false,
            loading: false
          })
        }
      )
    )

    captureLeadV2({
      firstName: form.firstName,
      lastName: form.lastName,
      phone: form.phone,
      email: form.email,
      company: form.companyName,
      source: 'business_security_desktop'
    })
  }

  return (
    <>
      {(!formStatus.submitted || formStatus.error) && !formStatus.loading ? (
        <Form
          button={button}
          form={form}
          note={note}
          onSubmit={onSubmit}
          successMessage={successMessage}
          validationSchema={businessLeadSchema}
        />
      ) : null}
      {formStatus.submitted || formStatus.loading ? (
        <div className="relative overflow-hidden bg-neutral-light-50 prose-headings:mb-4 rounded-base left-0 top-0 flex min-h-[768px] w-full flex-col transition-all duration-200 ease-in-out items-center justify-center p-6 md:p-8 lg:p-10">
          {!formStatus.loading && !formStatus.error ? (
            <ContentfulRichText {...successMessage} />
          ) : (
            <div className="bg-neutral-light-50 absolute left-0 top-0 flex h-full w-full items-center justify-center transition-all duration-200 ease-in-out">
              <div className="border-complementary-blue-100 border-b-none h-14 w-14 animate-spin rounded-full border-4 border-solid border-b-transparent" />
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}
