import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

import { productModalSchema } from '../ProductModal/schema'

export const systemHeroItemSchema = z.object({
  __typename: z.literal('ContentfulProduct'),
  contentful_id: z.string(),
  description: contentfulRichTextSchema.optional().nullable(),
  id: z.string(),
  image: gatsbyImageSchema,
  name: z.string(),
  productModal: productModalSchema.nullable(),
  sku: z.string()
})

export type SystemHeroItemFragment = TypeOf<typeof systemHeroItemSchema>
