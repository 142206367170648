import {
  useCurrentPromoBannerData,
  useSitewidePromoOfferTitle
} from '@ecomm/promotions-hooks'

import { TierOne } from '../../../TierOne'
import { TierThree } from '../../../TierThree'
import { TierTwo } from '../../../TierTwo'

export function MiddleLayoutDefault() {
  const { disclaimerText, giftLineItemText, offerLabel, overrideBannerText } =
    useCurrentPromoBannerData()
  const offerTitle = useSitewidePromoOfferTitle()

  // Uppercase like 'X OFF' when no override text exists and there's no label.
  const tierOneText =
    !overrideBannerText && !offerLabel
      ? offerTitle.toUpperCase()
      : `${offerTitle} ${offerLabel}`

  return (
    <div className="text-center" data-component="MiddleLayoutDefault">
      <TierOne>{tierOneText}</TierOne>
      {giftLineItemText ? <TierTwo>{giftLineItemText}</TierTwo> : null}
      {disclaimerText ? (
        <div className="pt-1">
          <TierThree>{disclaimerText}</TierThree>
        </div>
      ) : null}
    </div>
  )
}
