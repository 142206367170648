import {
  LayoutTypes,
  useCurrentPromoBannerData,
  useDesktopBannerLayout
} from '@ecomm/promotions-hooks'

import { PromoBannerBaseTemplate } from '../../PromoBannerBaseTemplate'
import { LayoutDefault } from './LayoutDefault'
import { LayoutWithCountdown } from './LayoutWithCountdown'
import { LayoutWithSaleName } from './LayoutWithSaleName'

type DesktopLayoutProps = {
  readonly layout: string
}

export function DesktopBanner() {
  const {
    hasSaleName,
    hasCountdown,
    endTime,
    saleName,
    displayEndTimeCalloutOverride,
    endTimeCalloutOverride
  } = useCurrentPromoBannerData()

  const endTimeToDisplay = displayEndTimeCalloutOverride
    ? endTimeCalloutOverride
    : endTime

  const layoutType = useDesktopBannerLayout(hasSaleName, hasCountdown)

  function RenderDesktopLayout({ layout }: DesktopLayoutProps) {
    switch (layout) {
      case LayoutTypes.saleName:
        return <LayoutWithSaleName />
      case LayoutTypes.countdown:
        return (
          <LayoutWithCountdown
            endTime={new Date(endTimeToDisplay)}
            saleName={saleName}
          />
        )
      default:
        return <LayoutDefault />
    }
  }

  return (
    <div className="hidden lg:block">
      <PromoBannerBaseTemplate
        className="flex justify-center"
        dataComponent="DesktopBanner"
        position="top"
      >
        <RenderDesktopLayout layout={layoutType} />
      </PromoBannerBaseTemplate>
    </div>
  )
}
