import { pipe } from 'fp-ts/lib/function'
import { match } from 'ts-pattern'

const defaultExpandQuery =
  '?expand=lineItems[*].productType.id&expand=discountCodes[*].discountCode.id&expand=customerGroup'

const cartsV1Endpoint = '/carts/v1/carts'

const cartsV2Endpoint = '/carts/v2/carts'

/**
 * Validates the expandQuery parameter.
// It checks if the string starts with "?expand=" and continues with one or more characters that are not an ampersand (&).
 */
const validateExpandQuery = (expandQuery: string): boolean => {
  const isValidExpandQuery = /(\?expand=[^&]+)/g

  return isValidExpandQuery.test(expandQuery)
}

/**
 * Normalizes the expandQuery parameter by removing extra slashes
 */
/**
 * Normalizes the expandQuery parameter by replacing any occurrence of // with /
 */
const normalizeExpandQuery = (expandQuery: string): string => {
  return expandQuery.replace(/\/\//g, '/')
}

/**
 * Create the endpoint for cart V1 requests.
 */
const cartEndpointV1 = (
  pathExtension: string,
  expandQuery = defaultExpandQuery
) => {
  return pipe(expandQuery, validateExpandQuery, isValid =>
    isValid
      ? `${cartsV1Endpoint}/${pathExtension}${normalizeExpandQuery(
          expandQuery
        )}`
      : `${cartsV1Endpoint}/${pathExtension}${defaultExpandQuery}`
  )
}

/**
 * Create the endpoint for  cart V2 requests.
 */
const cartEndpointV2 = (
  pathExtension: string,
  expandQuery = defaultExpandQuery
) => {
  return pipe(expandQuery, validateExpandQuery, isValid =>
    isValid
      ? `${cartsV2Endpoint}/${pathExtension}${normalizeExpandQuery(
          expandQuery
        )}`
      : `${cartsV2Endpoint}/${pathExtension}${defaultExpandQuery}`
  )
}

/**
 * Constructs the full endpoint for a carts API request.
 */
export const constructCartEndpoint = (
  apiVersion: number,
  pathExtension: string,
  expansionQuery?: string
) => {
  return match(apiVersion)
    .with(1, () => cartEndpointV1(pathExtension, expansionQuery))
    .with(2, () => cartEndpointV2(pathExtension, expansionQuery))
    .otherwise(() => cartEndpointV1(pathExtension, expansionQuery))
}
