import { MaybeT, overloadOption } from '@simplisafe/ewok'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { selectCurrentPromotion } from '@simplisafe/ss-ecomm-data/promotions/select'
import {
  liftSelectProduct,
  selectPackage
} from '@simplisafe/ss-ecomm-data/redux/select'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import * as O from 'fp-ts/lib/Option'
import { useSelector } from 'react-redux'

/**
 * @deprecated This function depends heavily on the redux store and should't be used.
 * We'll need to make a new version of this that isn't dependent on the redux store.
 */
export function useReduxPackageSelectors(
  packageSku: string,
  proSetup: O.Option<string>
) {
  /**
   * @deprecated Selecting all of state like this is terrible for performance
   */
  const selectState = useSelector((state: ImmutableState) => state)
  const _package = useSelector(selectPackage(packageSku)).toMaybe()
  const packageProduct = useSelector(
    liftSelectProduct(overloadOption(O.fromNullable(packageSku)))
  ).toMaybe()
  const proSetupDetails = useSelector(
    liftSelectProduct(overloadOption(proSetup))
  ).toMaybe()

  const additionalItems: readonly MaybeT<
    Pick<Product, 'masterSku' | 'name' | 'price'>
  >[] = [...(O.isSome(proSetup) ? [proSetupDetails] : [])]
  const currentPromo = useSelector(selectCurrentPromotion)

  return {
    _package,
    additionalItems,
    packageProduct,
    selectState,
    currentPromo
  }
}
