import { LoadingSpinner } from '@ecomm/checkout-icons'
import { useMicroCopy } from '@ecomm/micro-copy'
import { CheckMark, Close } from '@ecomm/shared-icons'

type ResultScreenProps = {
  readonly isLoading: boolean
  readonly isCartUpdateSuccess: boolean
  readonly isCartUpdateError: boolean
}

export default function ResultScreen({
  isLoading,
  isCartUpdateSuccess,
  isCartUpdateError
}: ResultScreenProps) {
  const microcopy = useMicroCopy()

  return isLoading || isCartUpdateSuccess || isCartUpdateError ? (
    <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white">
      {isLoading ? <LoadingSpinner fillColor="currentColor" size={64} /> : null}
      {isCartUpdateSuccess ? (
        <span className="text-complementary-blue-100 flex items-center">
          <CheckMark className="bg-complementary-blue-100 mr-2 h-10 w-10 rounded-full p-2 text-white" />
          {microcopy['cart-updated']}
        </span>
      ) : null}
      {isCartUpdateError ? (
        <span className="text-sale flex items-center">
          <Close className="bg-sale mr-2 h-10 w-10 rounded-full p-2 text-white" />
          {microcopy['package-atc-error']}
        </span>
      ) : null}
    </div>
  ) : null
}
