import { baseStationData } from './products/baseStation'
import { entrySensorData } from './products/entrySensor'
import { extraKeyPadData } from './products/extraKeyPad'
import { extraLoudSirenData } from './products/extraLoudSiren'
import { extraYardSignData } from './products/extraYardSign'
import { glassbreakSensorData } from './products/glassbreakSensor'
import { indoorCameraData } from './products/indoorCamera'
import { keyFobData } from './products/keyFob'
import { motionSensorData } from './products/motionSensor'
import { outDoorCameraData } from './products/outdoorCamera'
import { panicButtonData } from './products/panicButton'
import { proSetupData } from './products/proSetup'
import { smartAlarmIndoorCameraData } from './products/smartAlarmIndoorCamera'
import { smokeCarbonMonoxideDetectorData } from './products/smokeCarbonMonoxideDetector'
import { smokeDetectorData } from './products/smokeDetector'
import { temperatureSensorData } from './products/temperatureSensor'
import { videoDoorbellProData } from './products/videoDoorbellPro'
import { waterSensorData } from './products/waterSensor'
import { ProductData } from './schema'

export const bmsMoreDetailsData: Record<string, ProductData> = {
  /* Required with your system  */
  // Base Station
  SSBS3W: baseStationData,
  // Keypad
  SSKP3W: extraKeyPadData,

  /* Burglary Sensors */
  // Entry Sensor
  SSES3: entrySensorData,
  // Motion Sensor
  'CA001-01DWW': motionSensorData,
  // Glassbreak Sensor
  SSGB3: glassbreakSensorData,

  /* Cameras */
  // Outdoor Camera
  CMOB1: outDoorCameraData,
  // Smart Alarm Indoor Camera
  'CM006-01DWW': smartAlarmIndoorCameraData,
  // Video Doorbell Pro
  SSDB3: videoDoorbellProData,
  // Indoor Camera
  SSCM2: indoorCameraData,

  /* Hazard Sensors */
  // Water Sensor
  SSWT3: waterSensorData,
  // Smoke/Carbon Monoxide Detector
  'CA002-01DWW': smokeCarbonMonoxideDetectorData,
  // Smoke Detector
  SSSD3: smokeDetectorData,
  // Temperature Sensor
  SSFS3: temperatureSensorData,

  /* Optional Add-Ons */
  // Panic Button
  SSPB3: panicButtonData,
  // Key Fob
  SSKF3B: keyFobData,
  // Extra Loud Siren
  SSWS3: extraLoudSirenData,
  // Extra Yard Sign
  SSYS3: extraYardSignData,
  // Pro Setup
  'SSPSH-ON': proSetupData
}
