import { usePriceContext } from '@ecomm/data-price'
import { useCurrentPromoDiscountedPrice } from '@ecomm/promotions-hooks'
import { selectCurrentPromoDiscountedPriceWithMonitoring } from '@simplisafe/ss-ecomm-data/promotions/select'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { totalPriceAtom } from '../../atoms/draftCart/totalPriceAtom'

export const useAmountSavedWithServicePlanBMS = (totalProp?: number) => {
  const { formatPrice } = usePriceContext()
  const [priceTotals] = useAtom(totalPriceAtom)
  const total = totalProp || priceTotals.total

  const promotionDiscountedPrice = useCurrentPromoDiscountedPrice()

  const promotionWithMonitoringDiscountedPrice = useSelector(
    selectCurrentPromoDiscountedPriceWithMonitoring
  )

  // if there is no discountedPrice, just default to the total
  const discountedPriceWithoutMonitoring =
    promotionDiscountedPrice(total).getOrElse(total)
  const discountedPriceWithMonitoring =
    promotionWithMonitoringDiscountedPrice(total).getOrElse(total)

  const totalDiscount = Math.abs(
    discountedPriceWithMonitoring - discountedPriceWithoutMonitoring
  )

  const amountSaved = useMemo(
    () => pipe(O.fromNullable(totalDiscount), formatPrice, O.toNullable),
    [totalDiscount, formatPrice]
  )

  return amountSaved
}
