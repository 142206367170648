import { useLocale } from '@ecomm/data-hooks'
import React, { useCallback, useState } from 'react'

import ProductModal from '../ProductModal'
import OutOfStock from './OutOfStock'
import { Props } from './schema'

function PackageItem({
  productItem,
  quantity,
  onMouseEnter,
  onMouseLeave
}: Props) {
  const isUs = useLocale() === 'en-US'
  const [isProductModalOpen, setIsProductModalOpen] = useState(false)
  const onProductModalOpenClose = useCallback((open: boolean) => {
    setIsProductModalOpen(open)
  }, [])

  const onClick = useCallback(() => {
    onProductModalOpenClose(true)
  }, [onProductModalOpenClose])

  return (
    <div className="flex items-center">
      {isUs ? <div className="inline pr-2 md:hidden">&#8226;</div> : null}
      <a
        className="cursor-pointer text-sm hover:underline"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {quantity} {productItem.name}
      </a>
      <OutOfStock sku={productItem.sku} />
      {productItem.productModal ? (
        <ProductModal
          isNew={false}
          modal={productItem.productModal}
          open={isProductModalOpen}
          setOpen={onProductModalOpenClose}
        />
      ) : null}
    </div>
  )
}

export default PackageItem
