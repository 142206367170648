import type { PayPalOrderSubmissionData } from '@ecomm/data-simplisafe-api'

import type { PayPalOrderData } from './createOrder'

export type SubmitPayPalOrderProps = {
  readonly paymentInformation: PayPalOrderSubmissionData
  readonly createOrder: (orderData: PayPalOrderData) => void
  readonly onPaymentProcessing: () => void
  // todo: setup tracking for paypal order submission
  // readonly trackMetricEvent: TrackMetricProps
}

const submitPayPalOrder = ({
  paymentInformation,
  createOrder,
  onPaymentProcessing
  // todo: setup tracking for paypal order submission
  // trackMetricEvent
}: SubmitPayPalOrderProps) => {
  const paymentMethodId = paymentInformation.paymentMethodId

  onPaymentProcessing()

  createOrder({
    paymentMethodId,
    type: 'paypal'
  })
}

export default submitPayPalOrder
