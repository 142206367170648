import { BLOCKS } from '@contentful/rich-text-types'
import { createMockRichText } from '@ecomm/utils'

import { ColoredSectionSchema } from './schema'

export const buttonNodeMock = {
  data: {
    target: {
      url: '/',
      __typename: 'ContentfulButton',
      buttonText: 'example button'
    }
  },
  nodeType: BLOCKS.EMBEDDED_ENTRY,
  content: []
}

export const iconAndTextNodeMock = async () => ({
  data: {
    target: {
      __typename: 'ContentfulIconAndText',
      text: { raw: await createMockRichText('test IconAndText') },
      icon: 'Check'
    }
  },
  nodeType: BLOCKS.EMBEDDED_ENTRY,
  content: []
})

export const nonExistingNodeMock = {
  data: {
    target: {
      __typename: 'ThisTypeDoesNotExist'
    }
  },
  nodeType: BLOCKS.EMBEDDED_ENTRY,
  content: []
}

export const coloredSectionMock = async (): Promise<ColoredSectionSchema> => ({
  __typename: 'ContentfulColoredSection',
  headline: 'Colored section title',
  theme: 'Gray with Orange',
  content: [
    {
      text: {
        raw: await createMockRichText('First column text'),
        references: []
      }
    },
    {
      text: {
        raw: await createMockRichText('Second column text'),
        references: []
      }
    }
  ],
  disclaimer: {
    raw: await createMockRichText('Disclaimer text')
  }
})
