import { TypeOf, z } from '@simplisafe/ewok'

const contentfulRichTextReferenceSchema = z.object({
  __typename: z.string(),
  contentful_id: z.string()
})

export const contentfulRichTextSchema = z.object({
  raw: z.string(),
  references: z.array(contentfulRichTextReferenceSchema).optional()
})

export const optionalContentfulRichTextSchema = z.makeOptional(
  contentfulRichTextSchema.nullable().optional()
)

export type ContentfulRichTextSchema = TypeOf<typeof contentfulRichTextSchema>
export type OptionalContentfulRichTextSchema = TypeOf<
  typeof optionalContentfulRichTextSchema
>
