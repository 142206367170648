//@ts-nocheck
import React, { FC, ReactNode, useEffect, useState } from 'react'

import {
  DeviceName,
  getDeviceAssignmentsByView,
  getDeviceView,
  ViewName
} from './data'
import HouseViewButtonGroup, {
  HouseViewButtonGroupProps
} from './HouseViewButtonGroup'
import HouseViewGroup, { HouseViewGroupProps } from './HouseViewGroup'
import * as css from './HowItWorksHouse.module.scss'

export type HowItWorksHouseProps = {
  readonly activeDevice?: DeviceName | ''
  readonly activeView?: ViewName
  readonly houseFrontImage: ImageBitmapSource | ReactNode
  readonly houseFrontLabel?: string
  readonly insideFrontImage: ImageBitmapSource | ReactNode
  readonly insideFrontLabel?: string
  readonly insideBackImage: ImageBitmapSource | ReactNode
  readonly insideBackLabel?: string
  readonly onButtonChange?: (buttonId: ViewName) => void
  readonly title: string
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const HowItWorksHouse: FC<HowItWorksHouseProps> = ({
  activeDevice = '',
  activeView: activeViewProp = 'houseFront',
  houseFrontImage,
  houseFrontLabel = 'House Front',
  insideBackImage,
  insideBackLabel = 'Inside Back',
  insideFrontImage,
  insideFrontLabel = 'Inside Front',
  onButtonChange,
  title,
  dataComponent = HowItWorksHouse.name
}: HowItWorksHouseProps) => {
  const [activeView, setActiveView] = useState(activeViewProp)

  const handleButtonChange = (buttonId: ViewName) => {
    setActiveView(buttonId)
    onButtonChange && onButtonChange(buttonId)
  }

  // When activeDevice is updated, always update the view state to match it.
  useEffect(() => {
    activeDevice && setActiveView(getDeviceView(activeDevice))
  }, [activeDevice])

  const buttonGroupData: HouseViewButtonGroupProps = {
    buttons: [
      {
        children: houseFrontLabel,
        id: 'houseFront',
        isActive: activeView === 'houseFront',
        onClick: () => handleButtonChange('houseFront')
      },
      {
        children: insideFrontLabel,
        id: 'insideFront',
        isActive: activeView === 'insideFront',
        onClick: () => handleButtonChange('insideFront')
      },
      {
        children: insideBackLabel,
        id: 'insideBack',
        isActive: activeView === 'insideBack',
        onClick: () => handleButtonChange('insideBack')
      }
    ]
  }

  const viewGroupData: HouseViewGroupProps = {
    views: [
      {
        activeDevice: activeDevice,
        devices: getDeviceAssignmentsByView('houseFront'),
        image: houseFrontImage,
        isActive: activeView === 'houseFront',
        name: 'houseFront'
      },
      {
        activeDevice: activeDevice,
        devices: getDeviceAssignmentsByView('insideFront'),
        image: insideFrontImage,
        isActive: activeView === 'insideFront',
        name: 'insideFront'
      },
      {
        activeDevice: activeDevice,
        devices: getDeviceAssignmentsByView('insideBack'),
        image: insideBackImage,
        isActive: activeView === 'insideBack',
        name: 'insideBack'
      }
    ]
  }

  return (
    <div className={css.outerWrapper} data-component={dataComponent}>
      <div className={css.wrapper}>
        {title ? <p className={css.title}>{title}</p> : null}
        <HouseViewButtonGroup {...buttonGroupData} />
        <HouseViewGroup {...viewGroupData} />
      </div>
    </div>
  )
}

export default HowItWorksHouse
