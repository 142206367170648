import classNames from 'classnames'
import React, { FC } from 'react'

export type ErrorMessageProps = {
  readonly className?: string
  /** data-component attribute value */
  readonly dataComponent?: string
  /** Whether the message is currently visible */
  readonly isVisible?: boolean
  /** The message to display */
  readonly message?: string
}

/** @deprecated Do not use ss-react-components*/
const ErrorMessage: FC<ErrorMessageProps> = ({
  className,
  dataComponent = ErrorMessage.name,
  message,
  isVisible = true
}: ErrorMessageProps) =>
  message ? (
    <div
      className={classNames(
        'text-sale text-xs font-light',
        { hidden: !isVisible },
        className
      )}
      data-component={dataComponent}
    >
      {message}
    </div>
  ) : null

export default ErrorMessage
