import { logError } from '@ecomm/error-handling'

/**
 * Takes in an Error or unknown value, logs it to New Relic, and returns the value as an Error.
 */
export const handleError = (e: Error | unknown): Error => {
  const err = e instanceof Error ? e : Error(`${e}`)
  logError(err)
  return err
}
