import { FieldContainer } from '@commercetools/platform-sdk'

export const formatAttribution = (data: FieldContainer | null) =>
  data !== null
    ? {
        additionalFoundInfoThrough: data['leadOther'] || '',
        foundInfoThrough: data['leadSource'] || ''
      }
    : {
        additionalFoundInfoThrough: '',
        foundInfoThrough: ''
      }
