import type { Locale } from '@ecomm/utils'

import { brazeTrackCartEvent } from '../braze'
import { fbTrackAddProductsToCart } from '../facebook'
import type { TrackingData } from '../types/tracking'
import { bmsToTrackingProducts } from '../utils'
import type { PackageProductSchema, ProductSchema } from '../utils/schema'

export const trackBmsAddToCartSuccess =
  (trackEvent: (data: Partial<TrackingData>) => void) =>
  (
    locale: Locale,
    basePackage: PackageProductSchema,
    productList: readonly ProductSchema[],
    totalPrice: number
  ) => {
    const ecomm = {
      add: {
        products: bmsToTrackingProducts(
          basePackage,
          productList,
          locale,
          totalPrice
        )
      },
      // can we get currencyCode from the caller?
      currencyCode: locale === 'en-GB' ? 'GBP' : 'USD'
    }

    trackEvent({
      ecommerce: ecomm,
      event: 'addToCart'
    })

    trackEvent({
      ecommerce: ecomm,
      event: 'eec.add',
      // todo add eventAction param to TrackingData
      eventAction: 'addtocart',
      // todo add eventCategory param to TrackingData
      eventCategory: 'eec',
      // todo add eventLabel param to TrackingData
      eventLabel: 'build my system'
    })

    brazeTrackCartEvent({
      eventType: 'add_to_cart',
      productTrackingData: ecomm
    })
    ecomm.add?.products && fbTrackAddProductsToCart(ecomm.add.products)
  }
