import React from 'react'
import { TypeOf } from 'zod'

import { DetailBubble } from './DetailBubble'
import { alarmCategoriesSchema } from './schema'

type DetailBubblesProps = TypeOf<typeof alarmCategoriesSchema>['bubbles']

export function DetailBubbles({
  bubbles
}: {
  readonly bubbles: DetailBubblesProps
}) {
  return (
    <div className="inset-x-1/2 ml-[-50vw] mr-[-50vw] flex w-screen max-w-[100vw] justify-center">
      <div
        className="flex gap-4 overflow-x-auto p-2"
        data-component="DetailBubbles"
      >
        {bubbles.map((bubble, i) => (
          <DetailBubble icon={bubble.icon} key={i} text={bubble.text} />
        ))}
      </div>
    </div>
  )
}
