import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './data'
import { schema } from './schema'

export function useScoutProductPageProductHeroData() {
  const productHeroData = useLocalizedData<typeof schema>(schema, data)

  return useMemo(() => productHeroData, [productHeroData])
}
