import {
  applyDiscountCodesRequest,
  useCartAddDiscounts
} from '@ecomm/data-cart'
import { pushToDataLayer } from '@ecomm/shared-window'
import { useTrackMetricEvent } from '@ecomm/tracking'
import { overloadOption } from '@simplisafe/ewok'
import {
  handleCartResponse,
  setCartLoading
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { selectCartId } from '@simplisafe/ss-ecomm-data/redux/select'
import { window } from 'browser-monads-ts'
import { reject, resolve } from 'fluture'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
/**
 * The responsibility of this hook is to iterate through the currentCodes
 * and apply the corresponding ones to the cart.
 *
 * @param currentPromoCodes readonly string[]
 * @param cartLoaded boolean
 * @param cartId string
 */
export function useApplyDiscountCodesToCart(
  currentPromoCodes: readonly string[],
  cartLoaded: boolean,
  useJotaiCart: boolean
) {
  const [areCodesApplied, setAreCodesApplied] = useState(false)
  const trackMetricEvent = useTrackMetricEvent()
  const dispatch = useDispatch()
  const reduxCartId = useSelector(selectCartId).orJust('')
  const jotaiAddDiscounts = useCartAddDiscounts()

  const handleFailure = useCallback(() => {
    setAreCodesApplied(false)
    trackMetricEvent('page-discount-code-error', {
      discountCode: currentPromoCodes.join(',')
    })
  }, [currentPromoCodes.join(',')])

  const handleSuccess = useCallback(() => {
    const utmCode = window.localStorage.getItem('utm_code')
    utmCode && handleUtmApplication(currentPromoCodes)
    setAreCodesApplied(true)
  }, [currentPromoCodes.join(',')])

  useEffect(() => {
    const shouldApplyCodes =
      currentPromoCodes.length > 0 && !areCodesApplied && cartLoaded

    async function reduxApplyDiscounts() {
      dispatch(setCartLoading(true))
      pipe(
        await applyDiscountCodesRequest(reduxCartId, currentPromoCodes)(),
        E.match(
          e => {
            dispatch(handleCartResponse(reject(e)))
            handleFailure()
          },
          cart => {
            dispatch(handleCartResponse(resolve(overloadOption(O.of(cart)))))
            handleSuccess()
          }
        )
      )
    }

    shouldApplyCodes && !useJotaiCart && reduxApplyDiscounts()
    shouldApplyCodes &&
      useJotaiCart &&
      jotaiAddDiscounts(currentPromoCodes, handleFailure, handleSuccess)

    // Using codes.join() in dependency array instead of codes directly.
    // Make sure current codes are applied to the cart if the cart id or promo codes change
  }, [currentPromoCodes.join(','), areCodesApplied, cartLoaded, reduxCartId])
}

function handleUtmApplication(currentPromoCodes: readonly string[]) {
  toast('your discount will be applied in cart', {
    duration: 10000,
    id: 'promoToast',
    position: 'top-right',
    style: {
      margin: 0,
      maxWidth: '380px'
    }
  })
  window.localStorage.removeItem('utm_code')
  pushToDataLayer({
    event: 'toastAlert',
    action: 'AddUTMCodeToCart',
    category: 'UTMAdditionAlert',
    label: currentPromoCodes.toString()
  })
}
