import React, { FC } from 'react'

/**
 * For defining an Image object for a component.
 */
export type SSImgProps = {
  readonly alt: string
  readonly className?: string
  readonly dataComponent?: string
  readonly height?: number
  readonly src: string
  readonly width?: number
}

// TODO The front-end should use gatsby-image. This component should be moved to storybookComponents.
/** @deprecated Do not use ss-react-components*/
const SSImg: FC<SSImgProps> = ({
  alt,
  className,
  src,
  dataComponent = SSImg.name,
  height,
  width
}: SSImgProps) => {
  return (
    <img
      alt={alt}
      className={className}
      data-component={dataComponent}
      height={height}
      src={src}
      width={width}
    />
  )
}

export default SSImg
