import classNames from 'classnames'

import type { CartLineItemProps } from '../../types'

const getCartLineItemNameProps = (item: CartLineItemProps) => ({
  className: classNames('text-base md:text-lg', {
    'text-sale': item.isHighlightedLineItem && !item.isDiscount,
    'pl-[2.4rem]':
      !item.quantity && !item.isHighlightedLineItem && !item.isFreeShippingItem,
    'pl-3': item.quantity,
    'pl-0': item.isHighlightedLineItem
  }),
  'data-testid': 'cart-item-name'
})

export function CartLineItemName({
  item
}: {
  readonly item: CartLineItemProps
}) {
  return (
    <span {...getCartLineItemNameProps(item)}>
      {(item.isBms && item.lineItemDisplayName) || item.itemName}
    </span>
  )
}
