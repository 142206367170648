import { Locale } from '../../hooks/useLocalizedData'
import {
  createMockRichHeadingText,
  createMockRichText,
  createMockRichTextWithItalics
} from '../../utils'

export const data = {
  [Locale.EnGB]: {
    title: createMockRichHeadingText('FAQs', 2),
    questions: [
      {
        longTitle:
          "Why do I need monitoring? Isn't the siren enough to scare away burglars?",
        shortTitle:
          "Why do I need monitoring?  Isn't the siren enough to scare away burglars?",
        description: createMockRichTextWithItalics([
          "We strongly recommend a monitoring plan to get the most protection from your security system. Professional monitoring doesn't just dispatch police, but fire and medical services as well. Our Fast Protect™ plan, when paired with the Smart Alarm Wireless Indoor Security Camera, also includes 24/7 live guard protection, an additional layer of security that enables professional monitoring agents to see and speak to intruders in your home during an alarm. "
        ])
      },
      {
        longTitle: "Isn't all home security monitoring basically the same?",
        shortTitle: "Isn't all home security monitoring basically the same?",
        description: createMockRichTextWithItalics([
          "That's what some security companies want you to think. In fact, SimpliSafe® is the only company whose agents can see and speak to intruders inside your home during an alarm to help stop crime in real time*. With our Fast Protect™ professional monitoring plan, our agents can also use video evidence to get you priority dispatch for faster police response.**",
          '*Requires Fast Protect™ professional monitoring plan with live guard protection.',
          '**Vs. non-verified SimpliSafe® alarms. Requires Fast Protect™ professional monitoring plan with video verification. '
        ])
      },
      {
        longTitle:
          'Will police automatically come to my house during an alarm?',
        shortTitle:
          'Will police automatically come to my house during an alarm?',
        description: createMockRichTextWithItalics([
          "Monitoring agents will attempt to contact you first via Alarm Text and phone call. If you've set off the alarm by mistake, you can easily cancel via the app, Alarm Text or by giving our agent your safeword over the phone prior to our agents requesting emergency dispatch. If you can't be reached during an alarm, agents will process the alarm as a real emergency, contacting 911 centers to request police dispatch. With our Fast Protect™ plan, our agents can verify threats using video evidence which can mean priority dispatch and faster police response.*",
          '*Vs. non-verified SimpliSafe® alarms. Requires Fast Protect™ professional monitoring plan with video verification.'
        ])
      },
      {
        longTitle:
          "Is the self-monitoring plan good enough for me if I'm home a lot?",
        shortTitle:
          "Is the self-monitoring plan good enough for me if I'm home a lot?",
        description: createMockRichText([
          "We recommend that you choose the plan that makes the most sense for your needs and budget. With a self-monitoring plan, you will be notified immediately of alarms and can decide how to respond. When you have a professionally monitored plan, you'll have the peace of mind of knowing that trained professionals have your back 24/7, even when you can't be reached."
        ])
      },
      {
        longTitle:
          'Will I give up my privacy if I subscribe to professional monitoring?',
        shortTitle:
          'Will I give up my privacy if I subscribe to professional monitoring?',
        description: createMockRichText([
          'Your privacy is always assured. All SimpliSafe® indoor cameras are equipped with integrated privacy shutters to ensure your privacy when not in use or when armed in Home mode. Plus, when our indoor cameras activate, the privacy shutter opens with an audible click and LEDs indicate that they are in use. If you subscribe to our Fast Protect™ plan and opt into live guard protection (requires Smart Alarm indoor camera), our agents can only access the camera and microphone during an active alarm. Special LED indicators let you know when an agent is accessing your camera, viewing the live feed, etc. '
        ])
      },
      {
        longTitle:
          'Is there a catch when you say “no contract” for monitoring plans?',
        shortTitle:
          'Is there a catch when you say “no contract” for monitoring plans?',
        description: createMockRichText([
          'No catch. All SimpliSafe® monitoring plans bill monthly, and you can cancel at any time.'
        ])
      },
      {
        longTitle:
          'If I install it myself, can I still get professional monitoring?',
        shortTitle:
          'If I install it myself, can I still get professional monitoring?',
        description: createMockRichText([
          'Yes. Regardless of whether you install your system yourself or select Pro Install, you can still choose the monitoring plan that best meets your needs.'
        ])
      }
    ]
  },
  [Locale.EnUS]: {
    title: createMockRichHeadingText('FAQs', 2),
    questions: [
      {
        longTitle:
          "Why do I need monitoring? Isn't the siren enough to scare away burglars?",
        shortTitle:
          "Why do I need monitoring?  Isn't the siren enough to scare away burglars?",
        description: createMockRichTextWithItalics([
          "We strongly recommend a monitoring plan to get the most protection from your security system. Professional monitoring doesn't just dispatch police, but fire and medical services as well. Our Fast Protect™ plan, when paired with the Smart Alarm Wireless Indoor Security Camera, also includes 24/7 live guard protection, an additional layer of security that enables professional monitoring agents to see and speak to intruders in your home during an alarm. "
        ])
      },
      {
        longTitle: "Isn't all home security monitoring basically the same?",
        shortTitle: "Isn't all home security monitoring basically the same?",
        description: createMockRichTextWithItalics([
          "That's what some security companies want you to think. In fact, SimpliSafe® is the only company whose agents can see and speak to intruders inside your home during an alarm to help stop crime in real time*. With our Fast Protect™ professional monitoring plan, our agents can also use video evidence to get you priority dispatch for faster police response.**",
          '*Requires Fast Protect™ professional monitoring plan with live guard protection.',
          '**Vs. non-verified SimpliSafe® alarms. Requires Fast Protect™ professional monitoring plan with video verification. '
        ])
      },
      {
        longTitle:
          'Will police automatically come to my house during an alarm?',
        shortTitle:
          'Will police automatically come to my house during an alarm?',
        description: createMockRichTextWithItalics([
          "Monitoring agents will attempt to contact you first via Alarm Text and phone call. If you've set off the alarm by mistake, you can easily cancel via the app, Alarm Text or by giving our agent your safeword over the phone prior to our agents requesting emergency dispatch. If you can't be reached during an alarm, agents will process the alarm as a real emergency, contacting 911 centers to request police dispatch. With our Fast Protect™ plan, our agents can verify threats using video evidence which can mean priority dispatch and faster police response.*",
          '*Vs. non-verified SimpliSafe® alarms. Requires Fast Protect™ professional monitoring plan with video verification.'
        ])
      },
      {
        longTitle:
          "Is the self-monitoring plan good enough for me if I'm home a lot?",
        shortTitle:
          "Is the self-monitoring plan good enough for me if I'm home a lot?",
        description: createMockRichText([
          "We recommend that you choose the plan that makes the most sense for your needs and budget. With a self-monitoring plan, you will be notified immediately of alarms and can decide how to respond. When you have a professionally monitored plan, you'll have the peace of mind of knowing that trained professionals have your back 24/7, even when you can't be reached."
        ])
      },
      {
        longTitle:
          'Will I give up my privacy if I subscribe to professional monitoring?',
        shortTitle:
          'Will I give up my privacy if I subscribe to professional monitoring?',
        description: createMockRichText([
          'Your privacy is always assured. All SimpliSafe® indoor cameras are equipped with integrated privacy shutters to ensure your privacy when not in use or when armed in Home mode. Plus, when our indoor cameras activate, the privacy shutter opens with an audible click and LEDs indicate that they are in use. If you subscribe to our Fast Protect™ plan and opt into live guard protection (requires Smart Alarm indoor camera), our agents can only access the camera and microphone during an active alarm. Special LED indicators let you know when an agent is accessing your camera, viewing the live feed, etc. '
        ])
      },
      {
        longTitle:
          'Is there a catch when you say “no contract” for monitoring plans?',
        shortTitle:
          'Is there a catch when you say “no contract” for monitoring plans?',
        description: createMockRichText([
          'No catch. All SimpliSafe® monitoring plans bill monthly, and you can cancel at any time.'
        ])
      },
      {
        longTitle:
          'If I install it myself, can I still get professional monitoring?',
        shortTitle:
          'If I install it myself, can I still get professional monitoring?',
        description: createMockRichText([
          'Yes. Regardless of whether you install your system yourself or select Pro Install, you can still choose the monitoring plan that best meets your needs.'
        ])
      }
    ]
  }
}
