import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import React from 'react'

import { AppWidgetData, AppWidgetTab } from '../../'

const title = (
  <span>
    You’re in control with the SimpliSafe<sup className="text-xl">®</sup> App
  </span>
)

const imageCarousel1: GatsbyImageSchema = {
  description: 'Carousel Image - Cameras',
  title: 'Carousel Image - Cameras',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/ZYB8WxMtOe454lse5FG2D/8efb8d41ef9a2c00db5aa7daa8bb4bc8/cameras3.png?w=375&h=470&q=50&fm=webp 375w,\nhttps://images.ctfassets.net/v6awd0kabi65/ZYB8WxMtOe454lse5FG2D/8efb8d41ef9a2c00db5aa7daa8bb4bc8/cameras3.png?w=750&h=941&q=50&fm=webp 750w,\nhttps://images.ctfassets.net/v6awd0kabi65/ZYB8WxMtOe454lse5FG2D/8efb8d41ef9a2c00db5aa7daa8bb4bc8/cameras3.png?w=1500&h=1881&q=50&fm=webp 1500w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/ZYB8WxMtOe454lse5FG2D/8efb8d41ef9a2c00db5aa7daa8bb4bc8/cameras3.png?w=1500&h=1881&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1000,
    height: 1254
  }
}

const imageCarousel2: GatsbyImageSchema = {
  description: 'Carousel Image - Controls',
  title: 'Carousel Image - Controls',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6rmX3DElVdPZn4Hw3JNqV9/2cfdf9f972cffb9c945a5c10e529bd68/App_Screen_art_3x.png?w=375&h=470&q=50&fm=webp 375w,\nhttps://images.ctfassets.net/v6awd0kabi65/6rmX3DElVdPZn4Hw3JNqV9/2cfdf9f972cffb9c945a5c10e529bd68/App_Screen_art_3x.png?w=750&h=941&q=50&fm=webp 750w,\nhttps://images.ctfassets.net/v6awd0kabi65/6rmX3DElVdPZn4Hw3JNqV9/2cfdf9f972cffb9c945a5c10e529bd68/App_Screen_art_3x.png?w=1500&h=1881&q=50&fm=webp 1500w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6rmX3DElVdPZn4Hw3JNqV9/2cfdf9f972cffb9c945a5c10e529bd68/App_Screen_art_3x.png?w=1500&h=1881&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1000,
    height: 1254
  }
}

const imageCarousel3: GatsbyImageSchema = {
  description: 'Carousel Image - Alerts',
  title: 'Carousel Image - Alerts',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/50wlqz44dQwAzOoxzmPO90/28c00bb27e544c3c431d99c39a8a6f36/App_Screen_art_3x.png?w=382&h=471&q=50&fm=webp 382w,\nhttps://images.ctfassets.net/v6awd0kabi65/50wlqz44dQwAzOoxzmPO90/28c00bb27e544c3c431d99c39a8a6f36/App_Screen_art_3x.png?w=764&h=941&q=50&fm=webp 764w,\nhttps://images.ctfassets.net/v6awd0kabi65/50wlqz44dQwAzOoxzmPO90/28c00bb27e544c3c431d99c39a8a6f36/App_Screen_art_3x.png?w=1527&h=1881&q=50&fm=webp 1527w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/50wlqz44dQwAzOoxzmPO90/28c00bb27e544c3c431d99c39a8a6f36/App_Screen_art_3x.png?w=1527&h=1881&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1018,
    height: 1254
  }
}

const imageCarousel4: GatsbyImageSchema = {
  description: 'Carousel Image - Doorbell',
  title: 'Carousel Image - Doorbell',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/38iheGuzSVg8kPQ1aokAnw/7ab753ffcbb7f61c742cc5ff193d059a/App_Screen_art_3x.png?w=375&h=470&q=50&fm=webp 375w,\nhttps://images.ctfassets.net/v6awd0kabi65/38iheGuzSVg8kPQ1aokAnw/7ab753ffcbb7f61c742cc5ff193d059a/App_Screen_art_3x.png?w=750&h=941&q=50&fm=webp 750w,\nhttps://images.ctfassets.net/v6awd0kabi65/38iheGuzSVg8kPQ1aokAnw/7ab753ffcbb7f61c742cc5ff193d059a/App_Screen_art_3x.png?w=1500&h=1881&q=50&fm=webp 1500w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/38iheGuzSVg8kPQ1aokAnw/7ab753ffcbb7f61c742cc5ff193d059a/App_Screen_art_3x.png?w=1500&h=1881&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1000,
    height: 1254
  }
}

const tabs: ReadonlyArray<AppWidgetTab> = [
  {
    tab: 'Cameras',
    title: 'Keep watch indoors and out with live camera feeds',
    list: [
      'Crisp, HD video with night vision',
      'Unlimited 30-day cloud storage of recordings*',
      '2-way audio on indoor and outdoor cameras',
      'Smart motion detection instantly alerts you of activity'
    ],
    image: imageCarousel1,
    disclaimer: ['*For up to 10 cameras. Monitoring plan required']
  },
  {
    tab: 'Controls',
    title: 'Protect your home from anywhere.',
    list: [
      'Arm and disarm from anywhere',
      'Lock and unlock Smart Locks',
      'Check temperature sensors'
    ],
    image: imageCarousel2
  },
  {
    tab: 'Alerts',
    title: 'Get notified instantly of unusual activity',
    list: [
      'Receive an immediate notification when motion is detected',
      'Cancel an alarm using the app, keypad, or text message*',
      'Monitor against break-ins, fires, floods and more',
      'SimpliSafe® agents call to verify alarms**'
    ],
    disclaimer: ['*Monitoring plan required'],
    image: imageCarousel3
  },
  {
    tab: 'Doorbell',
    title: 'See who’s at the door',
    list: [
      'See and speak to visitors at the door',
      'Communicate safely from home or away',
      'Capture evidence of porch pirates*'
    ],
    disclaimer: ['*Monitoring plan required'],
    image: imageCarousel4
  }
]

const appStoreLogo: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=46&h=14&q=50&fm=webp 46w,\nhttps://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=92&h=27&q=50&fm=webp 92w,\nhttps://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=184&h=54&q=50&fm=webp 184w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6AZWds73eDs6cYUcsNTOb4/1ce0c87e96812e41262431ad7106d04a/image__7_.png?w=184&h=54&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 184,
    height: 54
  },
  title: 'App Store Logo',
  description: 'App Store Logo'
}

const playStoreLogo: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=46&h=14&q=50&fm=webp 46w,\nhttps://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=92&h=28&q=50&fm=webp 92w,\nhttps://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=184&h=56&q=50&fm=webp 184w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/6CiupNcbwZWioKOCt3PIAA/7d32e6562bcac8eec2ba24935a479de4/image__5_.png?w=184&h=56&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 184,
    height: 56
  },
  title: 'Play Store Logo',
  description: 'Play Store Logo'
}

const simplisafeIcon: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=32&h=32&q=50&fm=webp 32w,\nhttps://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=63&h=64&q=50&fm=webp 63w,\nhttps://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=126&h=127&q=50&fm=webp 126w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/7rTc0nb3AVq5NG9WLUcG5I/7e9ec3c0dc2b525528dde190bb0f780e/image__8_.png?w=126&h=127&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 126,
    height: 127
  },
  description: 'SimpliSafe Icon',
  title: 'SimpliSafe Icon'
}
const appRatingImages = { appStoreLogo, playStoreLogo, simplisafeIcon }
const data: AppWidgetData = { tabs, title, appRatingImages }

export { data }
