import { gql } from '@apollo/client/index.js'

export const FAQ_QUERY = (contentfulID: string) => {
  return gql`
    fragment faqFrag on Faq {
      sys {
        id
      }
      primaryCollection {
        items {
          question
          answer {
            json
          }
        }
      }
    }
    query FaqQuery($preview: Boolean!, $locale: String!) {
      faq (id: "${contentfulID}", preview: $preview, locale: $locale) {
        ...faqFrag
      }
    }
  `
}
