import { TrackingProvider } from '@ecomm/tracking'
import { safePath } from '@simplisafe/monda'
import { IOGetCartByQuoteId } from '@simplisafe/ss-ecomm-data/cart'
import { navigate } from 'gatsby'
import nth from 'ramda/src/nth'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { PageProps } from '../components/Page'

function QuoteRedirectPage({ location }: PageProps) {
  const [cartRequested, setCartRequested] = useState(false)
  const [cartLoaded, setCartLoaded] = useState(false)
  const dispatch = useDispatch()

  const pathname = safePath(['pathname'], location).orJust('')
  const pathTokens = pathname.split('/')

  const quoteId = nth(2, pathTokens) || ''
  const emailHash = nth(4, pathTokens) || ''
  const trackingHash = (location && location.hash) || ''

  useEffect(() => {
    const shouldRedirect = () => setCartLoaded(true)

    !cartRequested &&
      dispatch(
        IOGetCartByQuoteId(quoteId, emailHash, shouldRedirect, shouldRedirect)
      )

    // ensure we only make one request to get the cart
    setCartRequested(true)
  }, [quoteId, emailHash, dispatch, cartRequested])

  useEffect(() => {
    cartLoaded && navigate(`/cart${trackingHash}`)
  }, [cartLoaded, trackingHash])

  return (
    <TrackingProvider metaTitle={'SimpliSafe | Quote'}>{null}</TrackingProvider>
  )
}

export default QuoteRedirectPage
