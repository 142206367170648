import { createContext, useContext } from 'react'

import { ProductContextProps } from './types'

export const ProductContext = createContext<ProductContextProps>({
  getProduct: () => null,
  getProducts: () => []
})

export const useProductContext = () => useContext(ProductContext)
