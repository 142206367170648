import { overloadMaybe, prop } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getCartCustomValues = (cart: ImmutableCart) =>
  pipe(
    overloadMaybe(prop('custom', cart)),
    O.chain(custom => O.fromNullable(custom)),
    O.fold(
      () => null,
      custom => custom.fields
    )
  )
