const deployEnvs = <const>['development', 'qa', 'stg', 'prd']
type DeployEnv = (typeof deployEnvs)[number]

function isDeployEnv(
  deployEnv: string | null | undefined
): deployEnv is DeployEnv {
  return ['development', 'qa', 'stg', 'prd'].includes(deployEnv || '')
}

export const validateDeployEnv = (
  deployEnv: string | null | undefined
): DeployEnv => {
  return isDeployEnv(deployEnv) ? deployEnv : 'qa'
}

// this is set as a variable outside the getter function so it's available at runtime
const DEPLOY_ENV: DeployEnv = validateDeployEnv(process.env['DEPLOY_ENV'])

export const getDeployEnv = (): DeployEnv => DEPLOY_ENV
