import React from 'react'

import { SmallTextSectionRedirect } from '../../templates/DefaultPage'
import CountryRedirectModal from '../CountryRedirectModal'

function CountryRedirectModals({ countryRedirectModals, hidePopups }: any) {
  return (
    <>
      {hidePopups ? (
        <></>
      ) : (
        countryRedirectModals
          ?.map((data: SmallTextSectionRedirect) => (
            <CountryRedirectModal
              data={data}
              data-testid="country-redirect-modal"
              key="CountryRedirectModal"
            />
          ))
          .orNull()
      )}
    </>
  )
}

export default CountryRedirectModals
