import {
  type WizardType,
  trackNavigationClick,
  trackNonBounce,
  trackQuoteWizard
} from '@ecomm/cdp-tracking'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

export const useTrackGuidedSystemBuilderOpenEvent = (
  wizardType: WizardType,
  label = ''
) => {
  const { trackEvent } = useTracking()

  return useCallback(() => {
    label &&
      trackEvent({
        label,
        event: 'navigation-link',
        eventAction: 'click',
        eventCategory: 'navigation'
      })
    label &&
      trackNavigationClick({
        action: 'click',
        navElement: label
      })
    trackEvent({
      event: 'quote-wizard-open',
      wizardType
    })
    trackQuoteWizard({
      qw_type: wizardType,
      qw_action: 'open'
    })
    trackNonBounce()
  }, [trackEvent, wizardType, label])
}
