import { useSmallText } from '@ecomm/shared-data'
import { Suspense } from 'react'

import { SmallText } from '.'

type Props = {
  readonly id: string
}

function GatsbyContent({ id }: Props) {
  const props = useSmallText(id)
  return <SmallText {...props} />
}

/**
 * Only for Gatsby!
 */
export function GatsbySmallText(props: Props) {
  return (
    <Suspense>
      <GatsbyContent {...props} />
    </Suspense>
  )
}
