//@ts-nocheck
import classNames from 'classnames'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import map from 'ramda/src/map'
import React, { FC } from 'react'

import { AccordionItem } from '..'
import CartLineItem, { CartLineItemProps } from '../CartLineItem'
import { useMediaQuery } from '../hooks'
import {
  CouponCodeField,
  OrderSummaryProps,
  PriceCalculationField
} from '../OrderSummary'
import SSImg from '../SSImg'
import * as css from './OrderSummaryRedesign.module.scss'

const toPriceCalculationItem = ({
  couponCode,
  value,
  label,
  isDiscount
}: PriceCalculationField) => {
  return (
    <div
      className={classNames({
        [css.discount]: isDiscount,
        'mb-[20px] flex w-full justify-between': !isNil(label)
      })}
      key={`${label}${value}`}
    >
      <div className="text-[16px] lg:text-[18px]">
        {label}
        {couponCode ? (
          <div className="text-neutral-medium-120 text-[14px] font-extralight">
            {couponCode}
          </div>
        ) : null}
      </div>
      <div className="text-[16px] font-medium lg:text-[18px]">{value}</div>
    </div>
  )
}

const renderCouponSectionImg = (imgUrl: string) =>
  !isEmpty(imgUrl) && <SSImg alt="" className="" src={imgUrl} />

const renderCouponSection = ({ imgUrl, content }: CouponCodeField) => (
  <div>
    <React.Fragment key={1}>{renderCouponSectionImg(imgUrl)}</React.Fragment>
    <React.Fragment key={2}>{toPriceCalculationItem(content)}</React.Fragment>
  </div>
)

const renderPriceCalculationSection = map(toPriceCalculationItem)

/** @deprecated Do not use ss-react-components*/
const OrderSummaryRedesign: FC<OrderSummaryProps> = ({
  section1Title,
  section2Title,
  itemList,
  priceCalculationFields,
  couponField,
  shippingDetails
}: OrderSummaryProps) => {
  const isMobile = !useMediaQuery('TabletAndUp')

  const renderListItems =
    !isNil(itemList) &&
    itemList.map((item: CartLineItemProps, idx: number) => (
      <CartLineItem
        {...item}
        isOrderSummaryRedesign={true}
        key={idx}
        theme={'subLineItem'}
      />
    ))

  const itemContent = (
    <div className={css.itemContentContainer}>
      {!isNil(itemList) && renderListItems}
      <div className="flex justify-between">
        <div className={classNames(css.couponContent)}>
          {!isNil(couponField) && renderCouponSection(couponField)}
        </div>
      </div>
      <div className="mt-[18px]">
        {renderPriceCalculationSection(priceCalculationFields)}
      </div>
    </div>
  )

  return (
    <div
      className="bg-neutral-light-50 rounded-base mx-auto p-5 lg:w-[416px]"
      data-component="OrderSummaryRedesign"
    >
      {section1Title && shippingDetails ? (
        <div>
          <h4 className="mb-[8px] mt-[4px] text-[18px] font-medium leading-normal">
            {section1Title}
          </h4>
          <ul className="mx-0 mb-[15px] mt-0 list-none p-0">
            <li className="text-[14px] font-extralight leading-normal">
              {shippingDetails.email}
            </li>
            <li className="text-[14px] font-extralight leading-normal">
              {shippingDetails.firstName} {shippingDetails.lastName}
            </li>
            <li className="text-[14px] font-extralight leading-normal">
              {shippingDetails.addressLine1}
            </li>
            <li className="text-[14px] font-extralight leading-normal">
              {shippingDetails.addressLine2}
            </li>
            <li className="text-[14px] font-extralight leading-normal">
              {!isNil(shippingDetails.city) && shippingDetails.city + ' '}
              {!isNil(shippingDetails.state) && shippingDetails.state + ' '}
              {shippingDetails.postalCode}
            </li>
            <li className="text-[14px] font-extralight leading-normal">
              {shippingDetails.phoneNumber}
            </li>
          </ul>
        </div>
      ) : null}
      <div>
        {!!isMobile ? (
          <AccordionItem id={section2Title} title={section2Title}>
            {itemContent}
          </AccordionItem>
        ) : (
          <>
            <h4 className="mb-[8px] mt-[4px] font-medium leading-normal lg:text-[18px]">
              {section2Title}
            </h4>
            {itemContent}
          </>
        )}
      </div>
    </div>
  )
}

export default OrderSummaryRedesign
