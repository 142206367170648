import once from 'ramda/src/once'
import Cookies from 'universal-cookie'

/**
 * @package ecomm/shared/cookies internal use ONLY
 *
 * This is wrapped in `once` because useCookieChange didn't seem to
 * pick up changes made with `setCookie` using a different instance
 * of the Cookies class.
 */
export const initCookies = once(() => new Cookies())
