import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import {
  get as sessionStorageGet,
  remove as sessionStorageRemove,
  set as sessionStorageSet
} from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { navigate } from '@reach/router'
import { voidFn } from '@simplisafe/ewok'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { fetchUserCheckoutDataWithRedirect } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { get as getLocalStorage, set as setLocalStorage } from 'local-storage'
import isNil from 'ramda/src/isNil'
import when from 'ramda/src/when'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { match } from 'ts-pattern'
import { StringParam, useQueryParams } from 'use-query-params'
import { v4 } from 'uuid'

import { AuthenticationSchema } from './schema'
import { AuthenticationType } from './types'
import {
  DEVICE_ID,
  fromAppAuth,
  getHrefWithoutSearch,
  interactiveUpgradeFlow,
  odmonBetaFlow,
  ss2UpgradeFlow
} from './utils'

export type AuthenticationProps = {
  readonly language: Locale
  readonly children: ReactNode
  readonly type: AuthenticationType
}

const setDeviceId = () => {
  when(isNil, () => setLocalStorage('deviceId', v4()))(
    getLocalStorage('deviceId')
  )
}

const sessionKeyHasDoneCallback = 'hasDoneCallback'
const sessionKeyIsAuthenticated = 'isSessionAuthenticated'

const hasDoneCallback = () => !!sessionStorageGet(sessionKeyHasDoneCallback)
const setHasDoneCallback = () =>
  sessionStorageSet(sessionKeyHasDoneCallback, '1')

export function AuthenticationComponent({
  children,
  language,
  type
}: AuthenticationProps) {
  const location = useLocation()

  const [isStateAuthenticated, setIsStateAuthenticated] =
    useState<boolean>(false)

  /*
    Holding two versions of "isAuthenticated" -- session storage is for something
    longer than state, for refreshes etc, and state storage because initial login
    needs to set state to force a redraw and be able to deliver contents.
  */
  const isAuthenticated = () =>
    sessionStorageGet(sessionKeyIsAuthenticated) || isStateAuthenticated
      ? true
      : false
  const setIsAuthenticated = (is: boolean) => {
    pipe(
      O.guard(is),
      O.match(
        () => {
          sessionStorageRemove(sessionKeyIsAuthenticated)
          setIsStateAuthenticated(false)
        },
        () => {
          sessionStorageSet(sessionKeyIsAuthenticated, '1')
          setIsStateAuthenticated(true)
        }
      )
    )
  }

  const href = getHrefWithoutSearch(location)

  const selectorLocale = useLocale()
  const locale = language || selectorLocale

  const [query] = useQueryParams({
    code: StringParam,
    plan: StringParam,
    state: StringParam,
    utm_medium: StringParam,
    utm_source: StringParam
  })

  const {
    code,
    state,
    utm_source: utmSource,
    utm_medium: utmMedium,
    plan
  } = query

  const fromCallback = code && state
  const isFromAppAuth = fromAppAuth(utmSource, utmMedium, plan, locale)

  const dispatch = useDispatch()

  const authenticate = useCallback(
    () =>
      fetchUserCheckoutDataWithRedirect(
        getLocalStorage(DEVICE_ID),
        href
      )(logError)(() => null),
    [href]
  )

  const authCallback = useCallback(
    (type: AuthenticationSchema['type']) => {
      setHasDoneCallback()

      return match(type)
        .with(AuthenticationType.ss2, () => {
          ss2UpgradeFlow(
            href,
            locale,
            dispatch,
            () => setIsAuthenticated(true),
            () => {
              setIsAuthenticated(false)
              navigate('/alarm-sensors')
            }
          )
        })
        .with(AuthenticationType.interactive, () => {
          interactiveUpgradeFlow(
            href,
            locale,
            dispatch,
            () => setIsAuthenticated(true),
            () => setIsAuthenticated(false)
          )
        })
        .with(AuthenticationType.odmon, () => {
          odmonBetaFlow(
            href,
            () => setIsAuthenticated(true),
            () => setIsAuthenticated(false)
          )
        })
        .otherwise(voidFn)
    },
    [dispatch, href, locale]
  )

  useEffect(() => {
    setDeviceId()

    !fromCallback &&
      type === AuthenticationType.interactive &&
      isFromAppAuth &&
      authenticate()
    !fromCallback && type === AuthenticationType.ss2 && authenticate()

    // ODMON Beta happens at first page load; without an artificial delay it
    // spirals because initial scripts keep getting interrupted
    !fromCallback &&
      type === AuthenticationType.odmon &&
      setTimeout(() => {
        authenticate()
      }, 2000)

    fromCallback && !hasDoneCallback() && authCallback(type)
  }, [authCallback, authenticate, children, fromCallback, isFromAppAuth, type])

  return isAuthenticated() ? <div>{children}</div> : null
}
