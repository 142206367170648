// TODO I think we can remove this component
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

type SquareProps = {
  readonly children?: ReactNode
  readonly className?: string
  /* props for add a padding in border */
  readonly padding?: number
  readonly dataComponent?: string
}

const defaultClasses = classNames(
  'bodyText',
  'colorDark',
  'neutralLightGrayBackgroundColor'
)

/** @deprecated Do not use ss-react-components*/
const Square: FC<SquareProps> = ({
  children,
  className,
  padding = 1,
  dataComponent = Square.name
}: SquareProps) => (
  <div
    className={classNames(className, defaultClasses, `p${padding}`)}
    data-component={dataComponent}
  >
    {children}
  </div>
)

export default Square
