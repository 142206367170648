import classNames from 'classnames'
import keys from 'ramda/src/keys'
import pickBy from 'ramda/src/pickBy'
import React, { FC, ReactNode, useState } from 'react'
import { useTracking } from 'react-tracking'

import type { SSButtonProps } from '../SSButton'
import { WizardType } from './Wizard'
import type { Answer, Response } from './WizardTypes'

export type WizardMultipleChoiceProps = {
  /** You don't need to add this prop. It's injected by the Wizard container */
  readonly backButtonText?: string
  readonly buttonProps?: Partial<SSButtonProps>
  /** If true, multiple answers can be selected for a single question */
  readonly isMultiSelect?: boolean
  /** You don't need to add this prop. It's injected by the Wizard container */
  readonly nextButtonText?: string
  readonly question: ReactNode
  readonly answers: ReadonlyArray<Answer>
  /** You don't need to add this prop. It's injected by the Wizard container */
  readonly onNextStep?: (response: Response) => () => void // this is optional becuase it's injected by the parent Wizard component and not directly as a prop
  /** You don't need to add this prop. It's injected by the Wizard container */
  readonly onPreviousStep?: () => void
  readonly id: string
  /** @deprecated */
  readonly answersPerRow?: number
  /** data-component attribute value */
  readonly dataComponent?: string
  readonly type?: WizardType
}

/** @deprecated Do not use ss-react-components*/
const WizardMultipleChoice: FC<WizardMultipleChoiceProps> = ({
  answers = [], // assigned to an empty array for safety
  backButtonText,
  isMultiSelect = false,
  id,
  nextButtonText,
  onNextStep,
  onPreviousStep,
  question,
  type,
  dataComponent = WizardMultipleChoice.name
}: WizardMultipleChoiceProps) => {
  const { Track } = useTracking({ appSection: 'wizardMultipleChoice' })
  const [answerSelections, setAnswerSelections] = useState<
    Record<string, boolean>
  >({})
  const handleMultiSelectClick =
    ({ value }: Answer) =>
    () =>
      setAnswerSelections({
        ...answerSelections,
        [value]: !answerSelections[value]
      })
  const showNavButtons = !!backButtonText || !!nextButtonText
  return onNextStep ? (
    <Track>
      <fieldset className="m-0 border-0 p-0" data-component={dataComponent}>
        <legend className="absolute left-[-9999px]">Select an option</legend>
        {question}
        <div
          className={classNames('mx-auto my-6 gap-4 md:gap-x-4 md:gap-y-6', {
            'md:flex md:flex-wrap md:justify-center':
              (answers.length > 4 || answers.length % 2 !== 0) &&
              type === 'embedded',
            //hacky and temporary solution for the wrong visualization on tablet and desktop devices
            'md:grid-cols-3': type === 'floating' && answers.length > 4,
            'flex flex-row flex-nowrap justify-center': answers.length === 3,
            'grid grid-cols-2': answers.length !== 3,
            'max-w-md': answers.length <= 4
          })}
        >
          {answers.map((answer: Answer) => {
            const handleClick = isMultiSelect
              ? handleMultiSelectClick(answer)
              : onNextStep({
                  id,
                  value: answer.value
                })
            return (
              <button
                className={classNames(
                  'btn btn-outlined btn-outlined-tertiary col-span-1 min-h-[48px] px-0 !text-sm xl:!text-base',
                  {
                    'md:basis-[30%]':
                      ((answers.length > 4 || answers.length % 2 !== 0) &&
                        type === 'embedded') ||
                      answers.length === 3,
                    '!text-neutral-black !bg-white':
                      isMultiSelect && answerSelections[answer.value]
                  }
                )}
                key={answer.value}
                onClick={handleClick}
                type="button"
              >
                {answer.label}
              </button>
            )
          })}
        </div>
        {showNavButtons ? (
          <div className="my-4 flex w-full justify-between">
            {backButtonText ? (
              <button
                className="text-btn-outline-accent cursor-pointer border-none bg-transparent text-base underline hover:no-underline md:text-lg"
                onClick={onPreviousStep}
                type="button"
              >
                {backButtonText}
              </button>
            ) : null}
            {nextButtonText ? (
              <button
                className="text-btn-outline-accent cursor-pointer border-none bg-transparent text-base underline hover:no-underline md:text-lg"
                onClick={onNextStep({
                  id,
                  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
                  value: keys(
                    pickBy(
                      val => !!val,
                      answerSelections
                    ) as typeof answerSelections
                  ).join(',')
                })}
                type="button"
              >
                {nextButtonText}
              </button>
            ) : null}
          </div>
        ) : null}
      </fieldset>
    </Track>
  ) : null
}

export default WizardMultipleChoice
