import { PriceProvider } from '@ecomm/data-price'
import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Breadcrumbs, Header, useHeaderRedesignQuery } from '@ecomm/header'
import { PageWrapper } from '@ecomm/shared-components'
import { TrustpilotUKTopBanner } from '@ecomm/shared-components'
import { mapExperiences, useOdmonExperience } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import {
  type FeatureSectionProps,
  FeatureSection
} from '@ecomm/shared-sections'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { type PageProps, graphql } from 'gatsby'
import { Suspense, useEffect } from 'react'
import { match } from 'ts-pattern'

import AffirmWrapper from '../../components/AffirmWrapper'
import { PackageListItem } from '../../components/PackageListItem'
import RefurbishedPLPBanner from '../../components/RefurbishedBanner'
import type { SeoNodeSchema } from '../../config/seoNodeSchema'
import type { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useResetDraftCart } from '../../hooks/draftCart/useResetDraftCart'
import type { ProductsDynamicPackage } from '../../utils/DynamicPackages/types'
import type { ComponentTypes } from '../Pdp/pdpPageSchema'
import { useOdmonPLPQuery } from './useOdmonPLPQuery'
import { usePlpPageFragment } from './usePlpPageFragment'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
  readonly prebuiltPackages: readonly ProductsDynamicPackage[]
}

type Props = Partial<PageProps> & {
  readonly data: unknown
  readonly pageContext: PageContext
}

export const getMappedComponent = (component: ComponentTypes, index: number) =>
  match(component)
    .with(
      { __typename: 'ContentfulFeatureSection' },
      (data: FeatureSectionProps) =>
        O.some(
          <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
            <FeatureSection
              {...data}
              className="lg:px-16"
              key={`ContentfulFeatureSection_${index}`}
              mediaClassName="!rounded-none lg:!rounded-base"
            />
          </div>
        )
    )

    .otherwise(() => O.none)

export const mapPageComponents = (components: readonly ComponentTypes[]) =>
  components.map((componentData, index) =>
    pipe(getMappedComponent(componentData, index), O.toNullable)
  )

function Content({ data, pageContext: { locale, seoDetails } }: Props) {
  //@ts-expect-error
  const plpPage = usePlpPageFragment(data)

  const odmonExperienceData = useOdmonExperience()
  const odmonPLPData = useOdmonPLPQuery()

  const { packages, layout, type } =
    odmonExperienceData.isVariant &&
    plpPage.slug === 'home-security-shop-packages'
      ? odmonPLPData
      : plpPage
  const {
    components,
    footer: { contentful_id: footerId }
  } = layout

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    isNofollow,
    isNoindex,
    canonicalLink
  } = seoDetails

  const packageSkus = packages.map(p => p.sku)
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const headerData = useHeaderRedesignQuery()

  // Force wipe Draft Cart state when leaving PLP page.
  // If we don't wipe draft cart, sensors recommended in the Quiz package get pre-filled in "Add extra sensors" section on pre-built packages
  // TODO: This functionality can be removed when Jotai Providers are
  // template scoped: https://simplisafe.atlassian.net/browse/ECP-8723
  const wipeDraftCart = useResetDraftCart()
  useEffect(() => {
    return () => {
      wipeDraftCart()
    }
  }, [])

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription.metaDescription}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <PriceProvider locale={locale} skus={packageSkus}>
        <PageWrapper>
          <PromoBannerWrapper
            experimentData={promoBannerExperiment}
            type="standard"
          />
          <Header {...headerData} />
          {
            // The following Experience component is used for tracking ODMON Launch. Putting
            // it around Breadcrumbs simplifies things since we are changing all the package
            // data for the variant.
          }
          {locale === 'en-US' ? (
            <Experience
              {...odmonExperienceData.data}
              component={Breadcrumbs}
              experiences={odmonExperienceData.experiences}
              passthroughProps={{
                steps: [
                  {
                    label: plpPage.breadcrumbTitle || layout.internalTitle,
                    slug: plpPage.slug
                  }
                ]
              }}
            />
          ) : (
            <Breadcrumbs
              steps={[
                {
                  label: plpPage.breadcrumbTitle || layout.internalTitle,
                  slug: plpPage.slug
                }
              ]}
            />
          )}
          <main
            className="prose flex flex-col whitespace-pre-line md:prose-md lg:prose-lg md:p-0"
            id="content"
          >
            <div className="max-w-8xl mx-auto w-fit lg:mt-8 lg:px-8">
              {type === 'Standard' ? (
                components && mapPageComponents(components)
              ) : (
                <RefurbishedPLPBanner />
              )}
            </div>
            {locale === 'en-GB' && (
              <TrustpilotUKTopBanner className=" mt-5 md:mb-0 md:mt-8"></TrustpilotUKTopBanner>
            )}
            <div className="mx-auto flex max-w-8xl flex-col gap-6 p-4 md:gap-8 md:p-8">
              {packages.map(p =>
                locale === 'en-US' ? (
                  <Experience
                    {...affirmFeatureFlagQueryData}
                    component={AffirmWrapper.PackageListItem}
                    experiences={mapExperiences<AffirmFeatureFlagSchema>(
                      affirmFeatureFlagQueryData.nt_experiences
                    )}
                    key={p.sku}
                    passthroughProps={{
                      item: p
                    }}
                  />
                ) : (
                  <PackageListItem item={p} key={p.sku} />
                )
              )}
            </div>
          </main>
          <Footer footerId={footerId} type="Full" />
        </PageWrapper>
      </PriceProvider>
    </TrackingProvider>
  )
}

export default function PLPTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}

export const plpTemplateQuery = graphql`
  #graphql
  query PlpTemplate($id: String) {
    contentfulPackageListingPage(id: { eq: $id }) {
      ...plpPage
      ...plpPageVariations
    }
  }
`
