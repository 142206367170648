/**
 * Defines the supported device names in the house views infographic.
 *
 * These names are assigned to views. The same device can be added more than
 * once to a view -- its location name would differentiate it.
 *
 * When a device is active, it will be highlighted within the infographic based
 * on CSS positioning that targets the device by name and location.
 */
export type DeviceName =
  | 'baseStation'
  | 'bellBox'
  | 'entrySensor'
  | 'glassbreakSensor'
  | 'keyfob'
  | 'keypad'
  | 'motionSensor'
  | 'outdoorCamera'
  | 'panicButton'
  | 'simplicam'
  | 'siren'
  | 'smokeDetector'
  | 'temperatureSensor'
  | 'videoDoorbellPro'
  | 'waterSensor'

/**
 * Needs to be define supported locations that are assigned to devices.
 *
 * Think of these as the names of locations that users assign their device when
 * setting up for the first time on the keypad; the user has to label the sensor
 * and usually do so by naming it after the location it is installed in.
 *
 * These locations are used as CSS classes that help give more context for
 * scoping position coordinates to devices within a house view. This exists
 * because devices can belong to multiple locations, such as an entry sensor at
 * the frontDoor, backDoor, and kitchen.
 */
export type LocationName =
  | 'babyRoom'
  | 'backDoor'
  | 'diningRoom'
  | 'frontDoor'
  | 'kitchen'
  | 'livingRoom'
  | 'masterBedroom'
  | 'outdoorFrontWall'
  | 'rightWall'
  | 'secondFloor'
  | 'utilities'

/**
 * Valid view names.
 *
 * Defines the valid house view names that are supported for creating the three
 * buttons and their respective views. Currently only supports 'houseFront',
 * 'insideBack', and 'insideFront'.
 */
export type ViewName = 'houseFront' | 'insideBack' | 'insideFront'

/**
 * Device assignments to location names. Used for CSS classnames.
 */
export type IHouseViewDevice = {
  readonly deviceName: DeviceName
  readonly locationName: LocationName
}

/**
 * An object of view names and their device/location assignments.
 */
export type IDeviceAssignmentByView = {
  readonly [key in ViewName]: readonly IHouseViewDevice[]
}

/**
 * An object of devices and their view assignments.
 */
export type IDevicesToViews = {
  readonly [key in DeviceName]: ViewName
}

const deviceAssignmentsByView: IDeviceAssignmentByView = {
  houseFront: [
    {
      deviceName: 'siren',
      locationName: 'frontDoor'
    },
    {
      deviceName: 'outdoorCamera',
      locationName: 'rightWall'
    },
    {
      deviceName: 'bellBox',
      locationName: 'outdoorFrontWall'
    },
    {
      deviceName: 'videoDoorbellPro',
      locationName: 'frontDoor'
    }
  ],
  insideBack: [
    {
      deviceName: 'simplicam',
      locationName: 'backDoor'
    },
    {
      deviceName: 'panicButton',
      locationName: 'masterBedroom'
    },
    {
      deviceName: 'glassbreakSensor',
      locationName: 'diningRoom'
    },
    {
      deviceName: 'motionSensor',
      locationName: 'backDoor'
    },
    {
      deviceName: 'entrySensor',
      locationName: 'diningRoom'
    },
    {
      deviceName: 'entrySensor',
      locationName: 'backDoor'
    },
    {
      deviceName: 'entrySensor',
      locationName: 'masterBedroom'
    },
    {
      deviceName: 'waterSensor',
      locationName: 'kitchen'
    },
    {
      deviceName: 'outdoorCamera',
      locationName: 'rightWall'
    }
  ],
  insideFront: [
    {
      deviceName: 'baseStation',
      locationName: 'livingRoom'
    },
    {
      deviceName: 'keypad',
      locationName: 'frontDoor'
    },
    {
      deviceName: 'simplicam',
      locationName: 'frontDoor'
    },
    {
      deviceName: 'simplicam',
      locationName: 'babyRoom'
    },
    {
      deviceName: 'motionSensor',
      locationName: 'secondFloor'
    },
    {
      deviceName: 'motionSensor',
      locationName: 'livingRoom'
    },
    {
      deviceName: 'smokeDetector',
      locationName: 'frontDoor'
    },
    {
      deviceName: 'smokeDetector',
      locationName: 'secondFloor'
    },
    {
      deviceName: 'waterSensor',
      locationName: 'utilities'
    },
    {
      deviceName: 'temperatureSensor',
      locationName: 'secondFloor'
    },
    {
      deviceName: 'temperatureSensor',
      locationName: 'babyRoom'
    },
    {
      deviceName: 'outdoorCamera',
      locationName: 'rightWall'
    }
  ]
}

export const deviceViews: IDevicesToViews = {
  baseStation: 'insideFront',
  bellBox: 'houseFront',
  entrySensor: 'insideBack',
  glassbreakSensor: 'insideBack',
  keyfob: 'insideFront',
  keypad: 'insideFront',
  motionSensor: 'insideFront',
  outdoorCamera: 'houseFront',
  panicButton: 'insideBack',
  simplicam: 'insideFront',
  siren: 'houseFront',
  smokeDetector: 'insideFront',
  temperatureSensor: 'insideFront',
  videoDoorbellPro: 'houseFront',
  waterSensor: 'insideFront'
}

/**
 * Fetches all device assignments given a ViewName.
 *
 * @param name A valid ViewName to retrieve device assignments by.
 */
export const getDeviceAssignmentsByView = (name: ViewName) =>
  deviceAssignmentsByView[name]

/**
 * Fetches the view for a given device.
 *
 * @param name A valid DeviceName to retrieve a view for.
 */
export const getDeviceView = (name: DeviceName) => deviceViews[name]

/** @deprecated Do not use ss-react-components*/
const HowItWorksHouseData = {
  deviceAssignmentsByView,
  deviceViews,
  getDeviceAssignmentsByView,
  getDeviceView
}

export default HowItWorksHouseData
