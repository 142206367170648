import { FormikHelpers } from 'formik'
import { TypeOf, z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const EMAIL_ERROR = 'Please enter a valid email address.'

const formFieldsSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string({ required_error: EMAIL_ERROR }).email(EMAIL_ERROR).min(1),
  message: z.string().min(1),
  checkboxField: z.boolean()
})

export const validationSchema = toFormikValidationSchema(formFieldsSchema)

export type FormFieldsSchema = TypeOf<typeof formFieldsSchema>

export type OnSubmit = (
  values: FormFieldsSchema,
  formikHelpers: FormikHelpers<FormFieldsSchema>
) => void

export type SubmissionResultProps = {
  readonly result: 'error' | 'pending' | 'success'
}
