import { ScriptStatus } from '@ecomm/shared-hooks'
import { getSprChat } from '@ecomm/shared-window'
import { useEffect, useState } from 'react'

export const useLiveChatClientExists = (status: ScriptStatus) => {
  // Maximum number of retries to check for window.sprChat.
  const maxNumRetries = 10

  // Interval to poll for window.sprChat availability.
  const pollInterval = 1000

  const [pollForLiveChat, setPollForLiveChat] = useState(false)
  const [sprChatExists, setSprChatExists] = useState<boolean>(
    !!window['sprChat']
  )
  const [sprChatInitialized, setSprChatInitialized] = useState(false)
  const [numRetries, setNumRetries] = useState(0)

  // This effect does the polling.
  useEffect(() => {
    // TODO: this could be refactored to use a setInterval. This is probably called more times than necessary.
    const timer = setTimeout(() => {
      getSprChat(() => {
        return setSprChatExists(true)
      })
      setNumRetries(numRetries => numRetries + 1)
    }, pollInterval)

    return () => clearTimeout(timer)
  }, [pollForLiveChat])

  // This effect determines if we should continue polling or not.
  useEffect(() => {
    status === 'ready' &&
      !sprChatExists &&
      numRetries < maxNumRetries &&
      setPollForLiveChat(true)
  }, [numRetries, sprChatExists, status])

  // This effect determines if we should initialize chatTracking after polling has completed.
  useEffect(() => {
    const initializeChatClientExists = () => {
      setSprChatInitialized(true)
    }

    sprChatExists && !sprChatInitialized && initializeChatClientExists()
  }, [sprChatExists, sprChatInitialized])

  return sprChatInitialized
}
