import { Modal } from '@ecomm/shared-components'
import React, { ReactNode, useState } from 'react'

import { StandardMonitoringModalContent } from '../StandardMonitoringBox/StandardMonitoringModalContent'

type StandardMonitoringBoxProps = {
  readonly atcButton?: ReactNode
  readonly price: string
}

export function StandardMonitoringBox({
  atcButton = null,
  price
}: StandardMonitoringBoxProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const modalButton = (
    <button
      className="min-h-0 underline text-body-size cursor-pointer hover:no-underline border-0 bg-transparent w-auto p-0 text-left"
      data-component="ModalButton"
      onClick={() => setIsModalOpen(true)}
    >
      Standard Monitoring {price}
    </button>
  )

  return (
    <div className="prose md:prose-md lg:prose-lg whitespace-pre-line border-neutral-light-50 mt-6 border border-solid p-5 first:mt-0 md:px-10 lg:mt-8">
      <p>
        We strongly recommend protecting your home with professional monitoring.
        Looking for a plan that dispatches police without camera recordings or
        video verification? Check out {modalButton}.
      </p>
      <Modal
        appElementId="___gatsby"
        dataComponent="StandardMonitoringModal"
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        size="medium"
      >
        <StandardMonitoringModalContent atcButton={atcButton} price={price} />
      </Modal>
    </div>
  )
}
