import { useMediaQuery } from '@ecomm/ss-react-components'
import classNames from 'classnames'
import { getSrc } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'

import { HeroBannerFinancingContainerSchema } from './schema'

const getBgUrl = (background, backgroundTablet, backgroundMobile) => {
  const isMobile = !useMediaQuery('TabletAndUp')
  const isDesktop = useMediaQuery('DesktopAndUp')

  return getSrc(
    isMobile ? backgroundMobile : isDesktop ? background : backgroundTablet
  )
}

export type Props = HeroBannerFinancingContainerSchema & {
  readonly children?: ReactNode
}

export default function Container({
  background,
  backgroundMobile,
  backgroundTablet,
  children = null
}: Props) {
  const bgUrl =
    background || backgroundMobile || backgroundTablet
      ? getBgUrl(background, backgroundTablet, backgroundMobile)
      : ''
  const bgImgStyle = { backgroundImage: `url(${bgUrl})` }

  const containerClassNames = classNames(
    'text-white h-full w-full flex justify-center',
    'lg:p-11'
  )

  const bgClassNames = classNames(
    'w-full max-w-[1352px] bg-no-repeat bg-cover bg-center flex items-center',
    'py-20 px-4 h-[367px]',
    'md:px-7 md:h-[352px]',
    'lg:px-11 lg:h-[600px] lg:rounded-xl',
    { 'bg-neutral-black': !bgUrl }
  )

  return (
    <div
      className={containerClassNames}
      data-component="herobannerfinancing-container"
    >
      <div className={bgClassNames} style={bgImgStyle}>
        <div>{children}</div>
      </div>
    </div>
  )
}
