import { qaPaymentEnvsWithoutVariation } from '@ecomm/data-constants'
import { getLocale } from '@ecomm/utils'

// Returns a value indicating whether the origin is a QA page with a variation payment form
const hasVariationPage = (origin: string) =>
  !qaPaymentEnvsWithoutVariation.includes(origin)

/**
 * @param variation boolean indicating whether to return the variation version of the page
 * @param origin window.location.origin
 * @returns a clientType used for an origin mapping to a payment page
 */
export const getClientType = (variation: boolean, origin: string) => {
  const envType = process.env['PAYMENTS_PAGE_TYPE'] || ''
  const isUS = getLocale() === 'en-US'
  return variation && hasVariationPage(origin)
    ? isUS
      ? 'ecomm-US-variation-1'
      : 'ecomm-UK-variation-1'
    : envType
}
