import { ContentfulRichText } from '@ecomm/shared-components'
import React from 'react'

export default function DuplexText({
  text
}: {
  readonly text: string | undefined
}) {
  return (
    <div className="prose-h3:mb-3 prose-h3:text-body-size prose-h3:font-bold prose-h3:leading-normal col-span-2 w-full md:col-span-1 lg:col-span-2">
      <ContentfulRichText raw={text} />
    </div>
  )
}
