type PossiblePaths = '/cart' | '/cart/checkout' | '/payment-page' | null
type PossibleSteps = 'Cart' | 'Order Confirmation' | 'Payment' | 'Shipping'
type StepsLocation = {
  readonly [key in PossibleSteps]: {
    readonly index: number
    readonly pathname: PossiblePaths
  }
}

/**
 * Locations to redirect or null if not needed
 */
export const stepsLocation: StepsLocation = {
  Cart: {
    index: 1,
    pathname: '/cart'
  },
  Shipping: {
    index: 2,
    pathname: '/cart/checkout'
  },
  Payment: {
    index: 3,
    pathname: '/payment-page'
  },
  'Order Confirmation': {
    index: 4,
    pathname: null
  }
}

export function isOfTypeStep(keyInput: string): keyInput is PossibleSteps {
  return ['Cart', 'Order Confirmation', 'Payment', 'Shipping'].includes(
    keyInput
  )
}

/**
 * Get step location pathname
 */
export const getStepLocation = (step: PossibleSteps): string | undefined =>
  stepsLocation[step]?.pathname || undefined

/**
 * Get step location index
 */
export const getStepIndex = (pathname: string): number | null => {
  const step = Object.values(stepsLocation).find(
    ({ pathname: p }) => p === pathname
  )
  return step?.index || null
}
