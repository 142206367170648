//@ts-nocheck
import classNames from 'classnames'
// eslint-disable-next-line no-restricted-imports -- legacy code
import { propOr } from 'ramda'
import isNil from 'ramda/src/isNil'
import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useRef
} from 'react'

import CardBadge, { CardBadgeProps } from '../CardBadge'
import CarouselContainer from '../CarouselContainer'
import useMediaQuery from '../hooks/useMediaQuery'
import { Cancel } from '../icons'
import ItemDetail, { ItemDetailProps } from '../ItemDetail'
import OfferTag, { OfferTagProps } from '../OfferTag'
import SSButton, { SSButtonProps } from '../SSButton'
import * as css from './ItemContainer.module.scss'
export type ItemContainerProps = {
  readonly areSensorsVisible?: boolean
  readonly setSensorsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  readonly cardBadge?: CardBadgeProps
  readonly images: ReadonlyArray<ReactElement>
  readonly selectedImageIndex?: number
  /** The content of the link */
  readonly linkContent?: ReactNode
  readonly itemDetail: ItemDetailProps
  /** The content of the component */
  readonly priceCalculationContent?: ReactNode
  readonly checkOutButtonFooter?: SSButtonProps
  /** List of sensors showing as card item */
  readonly sensors: ReactNode
  readonly checkOutClick?: () => void
  readonly dataComponent?: string
  readonly offerTag?: OfferTagProps
  readonly errorMessage?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const ItemContainer: FC<ItemContainerProps> = ({
  areSensorsVisible,
  setSensorsVisible,
  cardBadge,
  images,
  selectedImageIndex,
  linkContent,
  itemDetail,
  sensors,
  priceCalculationContent,
  checkOutButtonFooter,
  checkOutClick,
  dataComponent = ItemContainer.name,
  offerTag,
  errorMessage
}: ItemContainerProps) => {
  const sensorRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    areSensorsVisible &&
      sensorRef.current &&
      sensorRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [areSensorsVisible])
  const isMobile = !useMediaQuery('TabletAndUp')

  /**
   * Assigns some default values if nothing else is passed.
   */
  const offerTagProps: OfferTagProps = useMemo(() => {
    return {
      ...offerTag,
      tagTextColor: propOr('#fff', 'tagTextColor', offerTag)
    }
  }, [offerTag])

  return (
    <>
      <div
        className="relative grid grid-cols-12 gap-0 lg:gap-4"
        data-component={`${dataComponent}_pdpPage`}
      >
        <div
          className={classNames(
            'col-span-12 lg:col-span-6',
            css.carouselWrapper
          )}
        >
          {!isNil(cardBadge) && (
            <CardBadge {...cardBadge} className={css.cardBadge} />
          )}
          <div className={classNames(css.itemContainer)} id="package-info">
            {/* TODO this CarouselContainer doesn't display properly on mobile */}
            <CarouselContainer
              arrows={true}
              autoplay={false}
              highlightArrow={true}
              paginationPosition={'bottom-right-to-center'}
              playButton={false}
              selectedSlideIndex={selectedImageIndex}
              slides={images}
            />
            {offerTag && !isMobile ? (
              <OfferTag {...offerTagProps} className={css.offerBadge} />
            ) : null}
            {!areSensorsVisible && linkContent ? (
              <div className={classNames(css.pdpSensor)}>{linkContent}</div>
            ) : null}
          </div>
        </div>
        <div className="col-span-12 lg:col-span-6">
          <ItemDetail errorMessage={errorMessage} {...itemDetail} />
        </div>
      </div>
      {!isNil(cardBadge) && (
        <CardBadge
          {...cardBadge}
          className={css.cardBadgeMobile}
          dataComponent={`${dataComponent}_${css.cardBadgeMobile}`}
          type={'mobile'}
        />
      )}
      <div
        className={classNames(css.extraSensors, { hidden: !areSensorsVisible })}
        data-component={`${dataComponent}_${css.extraSensors}`}
        ref={sensorRef}
      >
        <Cancel
          className={classNames(css.close, 'text-neutral-medium-120')}
          onClick={() => {
            window &&
              window.scrollTo({
                behavior: 'smooth',
                top: 0
              })
            setSensorsVisible && setSensorsVisible(false)
          }}
          titleA11y={'close'}
        />
        {sensors}
        {priceCalculationContent ? (
          <div className={css.purchaseDetails}>
            {priceCalculationContent}
            {!isNil(checkOutButtonFooter) && (
              <SSButton
                {...checkOutButtonFooter}
                color={'primary'}
                onClick={checkOutClick}
              />
            )}
            {!!checkOutButtonFooter && errorMessage ? (
              <div className={'m1_t'}>{errorMessage}</div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default ItemContainer
