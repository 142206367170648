import { useTrackingShowOtherPlans } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useOnShowOtherPlans() {
  const handleTrackingShowOtherPlans = useTrackingShowOtherPlans()

  return useCallback(
    () => handleTrackingShowOtherPlans(),
    [handleTrackingShowOtherPlans]
  )
}
