import { match } from 'ts-pattern'
import { OntechPackage, OntechPackageAddOns } from './types'

export const orderSizeToPackageVariant = (
  orderSize: number
): OntechPackage | undefined =>
  match(orderSize)
    .when(
      i => i <= 10,
      () => OntechPackage.piece_10
    )
    .when(
      i => i <= 15,
      () => OntechPackage.piece_15
    )
    .when(
      i => i <= 20,
      () => OntechPackage.piece_20
    )
    .when(
      i => i <= 25,
      () => OntechPackage.piece_25
    )
    .when(
      i => i <= 30,
      () => OntechPackage.piece_30
    )
    .otherwise(() => undefined)

export const addOnToVariant = (sku?: string): OntechPackageAddOns | undefined =>
  match(sku)
    .with('CA010-01DUS', () => OntechPackageAddOns.smartLock)
    .with('CA010-02DUS', () => OntechPackageAddOns.smartLock)
    .with('SSDB3', () => OntechPackageAddOns.videoDoorbell)
    .with('CMOB1', () => OntechPackageAddOns.outdoorCamera)
    .with('CM021-01DWW', () => OntechPackageAddOns.outdoorCamera)
    .otherwise(() => undefined)

type VariantId = `${OntechPackageAddOns}_${number}`
export const productsToAddOnVariants = (
  products: readonly { readonly sku?: string; readonly quantity: number }[]
): readonly VariantId[] =>
  products
    .map<VariantId | undefined>(p => {
      const variant = addOnToVariant(p.sku)
      return variant ? `${variant}_${p.quantity}` : undefined
    })
    .filter(v => v !== undefined)
