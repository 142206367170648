import { useLocale } from '@ecomm/data-hooks'
import { usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  useCurrentPromoDiscountedPrice,
  useCurrentPromoDynamicDiscountCodes
} from '@ecomm/promotions-hooks'
import { noValue } from '@ecomm/utils'
import { overloadOption } from '@simplisafe/ewok'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'

import { itemQuantityAtom } from '../../../atoms/draftCart/itemQuantityAtom'
import { monitoringAtom } from '../../../atoms/draftCart/monitoringPlanAtom'
import { totalPriceAtom } from '../../../atoms/draftCart/totalPriceAtom'
import { useGetDynamicDiscountsSku } from '../hooks'
import { MonitoringPlan, ProductFragment } from '../types'

type Props = {
  readonly basePackageSku: string
  readonly baseProducts: readonly ProductFragment[]
  readonly setError: Dispatch<SetStateAction<string>>
}

const RETRIEVAL_ERROR = 'retrieval-error'

function DraftCartTotal({ basePackageSku, baseProducts, setError }: Props) {
  const isUs = useLocale() === 'en-US'
  const dynamicDiscountsSku = useGetDynamicDiscountsSku()

  const [monitoring] = useAtom(monitoringAtom)
  const [priceTotals, setPriceTotals] = useAtom(totalPriceAtom)
  const [items] = useAtom(itemQuantityAtom)
  const { total, discountedTotal } = priceTotals
  const hasDiscount = !!discountedTotal && discountedTotal !== total

  const microCopy = useMicroCopy()

  const {
    formatPrice,
    calculateTotalPrice,
    calculateTotalDiscountedPrice,
    calculateTotalDiscountedPriceWithServicePlan,
    getDynamicDiscountedPrice
  } = usePriceContext()

  const formattedTotal = pipe(O.of(total), formatPrice, O.toNullable)
  const formattedDiscountedTotal = pipe(
    O.fromNullable(discountedTotal),
    formatPrice,
    O.toNullable
  )

  const promotionDiscountedPrice = useCurrentPromoDiscountedPrice()

  const maybeDynamicDiscountCodes = useCurrentPromoDynamicDiscountCodes()

  // Memoize safety incase of a None value from Redux
  const dynamicDiscountCodes = useMemo(() => {
    return maybeDynamicDiscountCodes.getOrElse([])
  }, [JSON.stringify(maybeDynamicDiscountCodes)])

  // TODO: Write ticket -> Another PR to refactor this to a custom hook -> read itemAtoms, read baseProducts(not in jotai yet), and calculate price. totalPrice left out of Jotai and is always derived by new hook.
  useEffect(() => {
    const productList = [
      ...baseProducts.map(product => ({
        quantity: 1,
        sku: product.sku
      })),
      ...items.toArray().map(([sku, quantity]) => ({
        quantity,
        sku
      }))
    ]

    const total = calculateTotalPrice(productList)

    const priceWithServicePlan = calculateTotalDiscountedPriceWithServicePlan(
      basePackageSku,
      productList
    )
    const priceWithoutServicePlan = calculateTotalDiscountedPrice(
      basePackageSku,
      productList,
      noValue(),
      monitoring.type === MonitoringPlan.ss2
    )

    const monitoringDiscountedTotal =
      dynamicDiscountCodes.length > 0
        ? overloadOption(getDynamicDiscountedPrice(total, dynamicDiscountsSku))
        : priceWithServicePlan

    const discountedTotal =
      monitoring.type === MonitoringPlan.interactive ||
      monitoring.type === MonitoringPlan.odmonOvernight ||
      monitoring.type === MonitoringPlan.odmon247
        ? monitoringDiscountedTotal
        : priceWithoutServicePlan

    setError(total === 0 ? RETRIEVAL_ERROR : '')

    setPriceTotals({
      discountedTotal: pipe(discountedTotal, O.toUndefined),
      total
    })
  }, [
    basePackageSku,
    baseProducts,
    calculateTotalPrice,
    dynamicDiscountCodes,
    items,
    monitoring,
    promotionDiscountedPrice,
    getDynamicDiscountedPrice,
    dynamicDiscountsSku,
    setError,
    setPriceTotals
  ])

  return isUs ? (
    <></>
  ) : (
    <p className="m-0 text-right" data-component="Draftcart-total">
      <b>{microCopy['total-label']}</b>
      {hasDiscount ? (
        <span className="text-sale ml-1 text-xl font-light md:text-2xl">
          {formattedDiscountedTotal}
        </span>
      ) : null}
      <span
        className={classNames('m-0 ml-1 text-xl font-light md:text-2xl', {
          'line-through': hasDiscount
        })}
      >
        {formattedTotal}
      </span>
    </p>
  )
}

export default DraftCartTotal
