//@ts-nocheck
import { isFalsy } from '@simplisafe/ewok'
import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'

import * as css from './Header.module.scss'

type HeaderDropdownItemProps = {
  readonly label: string
  readonly subText?: string
  readonly image?: ReactElement
  readonly labelPosition?: 'bottom' | 'right'
}

/** @deprecated Do not use ss-react-components*/
const HeaderDropdownItem: FC<HeaderDropdownItemProps> = ({
  label,
  subText,
  image,
  labelPosition = 'bottom'
}: HeaderDropdownItemProps) => {
  return (
    <div
      className={classNames(
        'hover:text-complementary-blue-100',
        css.headerDropdownItem,
        { [css.itemRow]: labelPosition === 'right' },
        { [css.itemColumn]: labelPosition === 'bottom' },
        { [css.withoutImage]: isFalsy(image) },
        { [css.withImage]: !isFalsy(image) }
      )}
    >
      {image ? <div className={css.image}>{image}</div> : null}
      <div className={css.menuItemText}>
        {label}
        {subText ? <small>{subText}</small> : null}
      </div>
    </div>
  )
}

export default HeaderDropdownItem
