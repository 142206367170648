import { useTrackOdmonPlanSelection } from '@ecomm/tracking'
import React from 'react'

import { MonitoringCard } from './MonitoringCard'

export function SelfMonitoringCard({
  atcButton = null
}: {
  readonly atcButton?: React.ReactNode
}) {
  const trackCTA = useTrackOdmonPlanSelection()

  return (
    <MonitoringCard
      atcButton={
        <div onClick={() => trackCTA('self', 'cards')}>{atcButton}</div>
      }
      features={[
        {
          type: 'check',
          text: 'Unlimited camera recordings & 30-day cloud storage'
        },
        { type: 'check', text: 'Full self-monitoring with mobile app' },
        {
          type: 'check',
          text: 'Up to 3 year hardware warranty',
          modal: {
            headline: 'Up to 3 year hardware warranty',
            description: (
              <>
                <p>
                  SimpliSafe provides a 3-year limited hardware warranty on all
                  products.
                </p>
                <p>
                  For additional hardware protection, customers who sign up for
                  a service plan before the 1-year warranty expires will be
                  covered by the respective product protection plan for as long
                  as the service is active. Please see our Terms of Sale and
                  Terms of Service for details and conditions.
                </p>
              </>
            )
          }
        },
        {
          type: 'cross',
          text: 'No 24/7 live guard protection by professional monitoring agents'
        },
        {
          type: 'cross',
          text: 'No police, fire or medical dispatch'
        },
        { type: 'cross', text: 'No cellular backup during power outages' },
        { type: 'cross', text: 'No advanced app features' }
      ]}
      hasFlag={false}
      iconTexts={['unlimitedVideoRecording', 'instantAlarmAlerts']}
      name="Self Monitoring with Camera Recordings"
      price={'33¢'}
      subtext="You make the call in emergency situations."
    />
  )
}
