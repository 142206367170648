//@ts-nocheck
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Slider from '@ant-design/react-slick'
import classNames from 'classnames'
import isNil from 'ramda/src/isNil'
import React, {
  FC,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'

import { ArrowLeft, ArrowRight } from '../icons'
import * as css from './CarouselContainer.module.scss'

export type PaginationPosition =
  | 'below-text'
  | 'bottom-center'
  | 'bottom-right-to-center'
  | 'vertical'

export type SSCarouselContainerProps = {
  readonly slides?: readonly ReactNode[]
  readonly selectedSlideIndex?: number
  readonly arrows?: boolean
  readonly autoplay?: boolean
  readonly paginationPosition?: PaginationPosition
  readonly playButton?: boolean
  readonly displayBadgeIcon?: boolean
  readonly className?: string
  readonly enableDots?: boolean
  readonly autoPlaySpeed?: number
  readonly dotsColor?: 'blue' | 'white'
  readonly enableInlineArrow?: boolean
  /* prop for pause the slider while clicking dot indicator in carousel */
  readonly enableDotControl?: boolean
  readonly fade?: boolean
  readonly dataComponent?: string
  readonly highlightArrow?: boolean
}

export type ArrowProps = {
  readonly className?: string
  readonly classes?: string
  readonly direction: 'left' | 'right'
  readonly enableInlineArrow?: boolean
  readonly onClick?: MouseEventHandler
  readonly dataComponent?: string
  readonly highlight?: boolean
}

const ArrowIcon = ({
  className,
  classes,
  direction,
  enableInlineArrow,
  onClick,
  dataComponent = 'ArrowIcon',
  highlight
}: ArrowProps) => {
  const Arrow = direction === 'left' ? ArrowLeft : ArrowRight
  const description = direction === 'left' ? 'previous' : 'next'

  return (
    <span data-component={dataComponent}>
      <Arrow
        className={
          enableInlineArrow
            ? classes
            : `${className} ${highlight ? 'highlight' : ''}`
        }
        onClick={onClick}
        titleA11y={description}
      />
    </span>
  )
}

const bottomCenter = 'bottom-center'
const paginationType = {
  'below-text': 'left-dots',
  'bottom-right-to-center': 'right-dots-to-center',
  bottomCenter,
  vertical: 'vertical-dots'
}

const appendDotsWithClick = (
  dots: readonly ReactElement[],
  handleClick: (dotClicked: boolean) => void,
  enableDotControl: boolean,
  enableDots: boolean
) =>
  enableDots ? (
    <div {...(enableDotControl && { onClick: () => handleClick(true) })}>
      <ul> {dots} </ul>
    </div>
  ) : (
    <></>
  )

/**
 * SSCarousel button
 */
/** @deprecated Do not use ss-react-components*/
const CarouselContainer: FC<SSCarouselContainerProps> = ({
  slides,
  selectedSlideIndex,
  arrows = false,
  autoplay = true,
  paginationPosition = bottomCenter,
  playButton = true,
  className,
  enableDots = true,
  autoPlaySpeed = 4000,
  dotsColor = 'white',
  enableDotControl = true,
  enableInlineArrow = false,
  fade = true,
  dataComponent = CarouselContainer.name,
  highlightArrow
}: SSCarouselContainerProps) => {
  const [autoSlide, setAutoSlide] = useState(true)
  const [count, setCount] = useState(1)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const position = paginationType[paginationPosition]
  const slideLen = slides ? slides.length : 0
  const slickSettings = {
    appendDots: (dots: readonly ReactElement[]) =>
      appendDotsWithClick(dots, handleClick, enableDotControl, enableDots),
    /* istanbul ignore file */
    arrows: arrows,
    autoplay: autoplay,
    autoplaySpeed: autoPlaySpeed,
    beforeChange: (_: number, next: number) => setCount(next + 1),
    dots: enableDots,
    dotsClass: `slick-dots ${position} ${dotsColor}`,
    fade: fade,
    infinite: true,
    nextArrow: (
      <ArrowIcon
        classes={'slick-arrow slick-next slickRight'}
        direction="right"
        enableInlineArrow={enableInlineArrow}
        highlight={highlightArrow}
      />
    ),
    prevArrow: (
      <ArrowIcon
        classes={'slick-arrow slick-prev slickLeft'}
        direction="left"
        enableInlineArrow={enableInlineArrow}
        highlight={highlightArrow}
      />
    ),
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    waitForAnimate: false
  }

  const sliderRef: React.RefObject<Slider> = useRef<Slider>(null)

  useEffect(() => {
    const isValidSlideIndex = (idx: number | undefined): idx is number =>
      !isNil(idx) && idx >= 0 && idx < slideLen

    !!sliderRef.current &&
      isValidSlideIndex(selectedSlideIndex) &&
      sliderRef.current.slickGoTo(selectedSlideIndex)
  }, [selectedSlideIndex, slideLen])

  const baseClassNames = classNames('carousel', css.carousel, className)

  // TODO refactor this to be a child component
  /**
   * <Carousel>
   *   <CarouselSlide />
   * <Carousel/>
   */

  const slidesElement =
    slides &&
    slides.map((slide, index) => (
      <div className={classNames(css.carousel__slide)} key={`${index}`}>
        {slide}
      </div>
    ))

  const play = () => {
    sliderRef.current && sliderRef.current.slickPlay()
  }

  const pause = () => {
    sliderRef.current && sliderRef.current.slickPause()
  }

  // Todo handle play and pause slide icon

  const handleClick = (dotClicked: boolean) => {
    autoSlide || dotClicked ? pause() : play()
    setAutoSlide(dotClicked ? !dotClicked : !autoSlide)
  }

  return (
    <div className={baseClassNames} data-component={dataComponent}>
      <Slider {...slickSettings} arrows={arrows} ref={sliderRef}>
        {slidesElement}
      </Slider>
      {enableInlineArrow && !!slideLen ? (
        <span className={classNames(css.slickCount)}>
          {' '}
          {`${count} of ${slideLen}`}{' '}
        </span>
      ) : null}
      <div
        className={classNames(
          css['autoPlay'],
          css[autoSlide ? 'playIcon' : 'pauseIcon'],
          css[playButton ? 'showIcon' : 'hideIcon']
        )}
        onClick={() => handleClick(false)}
      ></div>
    </div>
  )
}

export default CarouselContainer
