import { ContentfulImage } from '@ecomm/contentful/components'
import { ArrowDownSolidIcon } from '@ecomm/shared-icons'

import { ManualGridSchema } from '../../templates/Manual/schema'

export default function ManualGrid({
  items,
  headline
}: {
  readonly items: ManualGridSchema
  readonly headline: string
}) {
  return (
    <div className="flex w-full flex-col items-center">
      <h2 className="mb-4 w-full text-left text-4xl md:mb-6 lg:mb-8">
        {headline}
      </h2>
      <div className="grid w-full grid-cols-2 gap-4 md:gap-8 lg:gap-12">
        {items.map(item => (
          <a
            className="rounded-base group relative col-span-2 flex h-32 w-full items-center justify-end overflow-hidden p-4 no-underline shadow-lg md:col-span-1 md:h-36 md:p-6 lg:p-8"
            href={item.url}
            key={`grid-item-${item.title}`}
          >
            <ContentfulImage
              classNameOverride="absolute -z-[1] w-full h-full left-0 top-0 object-contain object-bottom"
              description={item.image.description}
              originalHeight={item.image.file.details.image.height}
              originalWidth={item.image.file.details.image.width}
              url={item.image.file.url}
            />
            <span className="z-[2] text-center text-2xl font-medium">
              {item.title}
            </span>
            <span
              className="absolute left-0 right-0 z-[3] flex h-full w-full items-center justify-center bg-black text-white opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-80"
              data-component="HoverScreen"
            >
              <ArrowDownSolidIcon className="h-16 w-16 text-white" />
            </span>
          </a>
        ))}
      </div>
    </div>
  )
}
