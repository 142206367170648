import { overloadMaybe } from '@simplisafe/ewok'
import { selectCurrentPromoDiscountedPrice } from '@simplisafe/ss-ecomm-data/promotions/select'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

/**
 * Returns a discount price by applying the current promotion applied to a price without a service plan.
 * If there is an active promo it will use those details first, otherwise it uses the evergreen promotion.
 */
export const useCurrentPromoDiscountedPrice = () => {
  const promotionDiscountedPrice = useSelector(
    selectCurrentPromoDiscountedPrice
  )

  const fn = useCallback(
    (price: number) => overloadMaybe(promotionDiscountedPrice(price)),
    [promotionDiscountedPrice]
  )

  return fn
}
