import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { getShippingMethodId } from '@ecomm/data-cart'
import {
  IOSetShippingMethod,
  ShippingOption
} from '@simplisafe/ss-ecomm-data/cart'
import { selectCartLoading } from '@simplisafe/ss-ecomm-data/cart/select'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

type Props = {
  readonly formattedShippingOptions: readonly ShippingOption[]
  readonly setIsFormLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export function useSelectCheckoutShippingOption({
  formattedShippingOptions,
  setIsFormLoading
}: Props) {
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<CheckoutFormValuesType>()
  const dispatch = useDispatch()
  const cart = useSelector(selectCart)
  const cartIsLoading = useSelector(selectCartLoading)

  const firstShippingResultId = formattedShippingOptions[0]?.id || ''
  const shippingOptionValue = values.shippingOption || ''
  const shippingMethodIdFromCart: string = cart
    .map(_cart => getShippingMethodId(_cart))
    .toMaybe()
    .getOrElse('')

  /**
   * Set shipping option.
   * This is setting the value in the form using `shippingOption` as key.
   * Set first option on load and then what the user selects.
   *  */
  useEffect(() => {
    setIsFormLoading(cartIsLoading)

    shippingOptionValue === ''
      ? setFieldValue('shippingOption', firstShippingResultId)
      : setFieldValue('shippingOption', shippingOptionValue)
  }, [firstShippingResultId, setFieldValue, shippingOptionValue, cart])

  /**
   * Update the cart with the selected option.
   */
  useEffect(() => {
    const shippingOptionValue = values.shippingOption || ''

    shippingMethodIdFromCart !== shippingOptionValue &&
      shippingOptionValue &&
      !isSubmitting &&
      dispatch(IOSetShippingMethod(shippingOptionValue))
  }, [shippingMethodIdFromCart, shippingOptionValue, isSubmitting])
}
