//@ts-nocheck
import classNames from 'classnames'
import isEmpty from 'ramda/src/isEmpty'
import React, { FC, ReactElement, ReactNode } from 'react'

import AdditionalOptionItems from '../AdditionalOptionItems'
import { AdditionalOptionItemsProps } from '../AdditionalOptionItems/types'
import IncludedItem, { IncludedItemProps } from '../IncludedItem'
import SSButton, { SSButtonProps } from '../SSButton'
import * as css from './ItemDetail.module.scss'

export type ItemDetailProps = {
  readonly productTitle: string
  readonly descriptionWithOptions?: ReactNode
  /** The content of the component */
  readonly content?: ReactNode
  readonly includedItemProps: IncludedItemProps
  readonly price: ReactElement
  /** The content of the interactive plan */
  readonly interactivePlanContent?: ReactNode
  readonly additionalOptionItemsProps?: AdditionalOptionItemsProps
  readonly onClickButton?: () => void
  readonly dataComponent?: string
  readonly placeholderText?: string
  readonly buttonProps: SSButtonProps
  readonly errorMessage?: ReactNode
  readonly additionalItem?: ReactNode
  readonly affirmPromoMessage?: ReactNode
  /** Out of stock message displayed when the product is out of stock */
  readonly outOfStockMessage?: ReactNode
  readonly productQuantity?: ReactNode
}

const renderRichTextInteractivePlan = (interactivePlanContent?: ReactNode) => {
  if (interactivePlanContent) {
    return (
      <div className={classNames(css.interactiveMonitorPlan)}>
        {interactivePlanContent}
      </div>
    )
  } else {
    return null
  }
}

/** @deprecated Do not use ss-react-components*/
const ItemDetail: FC<ItemDetailProps> = ({
  additionalItem,
  affirmPromoMessage,
  productTitle,
  content,
  descriptionWithOptions,
  includedItemProps,
  price,
  interactivePlanContent,
  additionalOptionItemsProps,
  onClickButton,
  dataComponent = ItemDetail.name,
  buttonProps,
  errorMessage,
  outOfStockMessage,
  productQuantity
}: ItemDetailProps) => {
  return (
    <div
      className={classNames(css.pdpDescrptionSection)}
      data-component={dataComponent}
    >
      {productQuantity}
      <h1 className="h2 my-4 mb-4">{productTitle}</h1>
      {descriptionWithOptions ? (
        <div className="mb-6">{descriptionWithOptions}</div>
      ) : (
        <div className="mb-6">{content}</div>
      )}
      <IncludedItem {...includedItemProps} />
      {!!additionalOptionItemsProps ? (
        <AdditionalOptionItems {...additionalOptionItemsProps} />
      ) : (
        <></>
      )}
      {additionalItem ? (
        <div
          className={classNames(css.additionalInfo, {
            'mt-4': isEmpty(additionalOptionItemsProps)
          })}
        >
          {additionalItem}
        </div>
      ) : null}
      {errorMessage ? <div className="mt-2">{errorMessage}</div> : null}
      <div className={classNames(css.pdpAddToCard)}>
        <SSButton
          className={classNames(css.button)}
          {...buttonProps}
          onClick={onClickButton}
        />
        <div className={classNames(css.pdpCardPrice)}>{price}</div>
      </div>
      {outOfStockMessage ? (
        <div
          className={classNames(
            'breakLine',
            'fontSize12',
            'outOfStockText',
            css.outOfStockMessage
          )}
        >
          {outOfStockMessage}
        </div>
      ) : null}
      {affirmPromoMessage ? (
        <div className={css.affirmPromoMessage}>{affirmPromoMessage}</div>
      ) : (
        renderRichTextInteractivePlan(interactivePlanContent)
      )}
    </div>
  )
}

export default ItemDetail
