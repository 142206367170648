import classNames from 'classnames'
import React, { FC } from 'react'

import { Text } from '..'
import isNotUndefined from '../utils/isNotUndefined'
import { OptionItemBodyObject } from './types'

export const OptionItemBody: FC<OptionItemBodyObject> = ({
  isSelected,
  theme,
  item
}: OptionItemBodyObject) => {
  const seeDetailsModal = isNotUndefined(item.detailsModal)

  return (
    <Text
      className={classNames('prose-p:m-0 prose-p:text-xs md:prose-p:text-sm', {
        'flex border-t-0 bg-white': isSelected && theme === 'tab',
        'border-neutral-light-100 border border-solid pb-4 pl-10 pr-4 md:order-2 md:pt-4':
          theme === 'tab',
        'relative ml-6 mt-2 flex': theme === 'column',
        hidden: !isSelected || (!item.content && !item.inlineTitle)
      })}
      useTailwind
    >
      {isNotUndefined(item.content) ? (
        <div className="prose-p:text-xs md:prose-p:text-sm">
          {item.content}
          {seeDetailsModal ? <span>{item.detailsModal}</span> : null}
        </div>
      ) : null}
    </Text>
  )
}

export default OptionItemBody
