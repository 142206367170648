import { useCartIsOrderAssigned } from '@ecomm/data-cart'
import { useMicroCopy } from '@ecomm/micro-copy'
import { useTrackingCartContinueShopping } from '@ecomm/tracking'
import { LineItem } from '@simplisafe/eis-commercetools-carts'
import { Link } from 'gatsby'

import ContinueToCheckoutButton from '../ContinueToCheckoutButton'

type ExtendedLineItem = LineItem & { readonly totalQuantityInCart: number }

type Props = {
  readonly lineItems: readonly ExtendedLineItem[]
  readonly partnerCaptureMandatory?: boolean
}

function CartHeader({ lineItems, partnerCaptureMandatory = false }: Props) {
  const trackContinueShopButton = useTrackingCartContinueShopping()
  const isOrderAssigned = useCartIsOrderAssigned()
  const microCopy = useMicroCopy()

  return (
    <div className="md:px-0 md:pb-[25px] md:pt-[35px] lg:px-0 lg:pb-[25px] lg:pt-0">
      <div className="md:flex md:items-center md:justify-between md:border-y-0">
        <h2 className="text-[24px] md:mb-0 md:text-[36px]">
          Your Shopping Cart:
        </h2>
        <div className="flex items-center">
          <Link
            className="mr-4 p-0 text-lg text-complementary-blue-100"
            onClick={trackContinueShopButton}
            to="/home-security-shop"
          >
            Continue shopping
          </Link>
          <ContinueToCheckoutButton
            lineItems={lineItems}
            text={microCopy['secure-checkout']}
          />
        </div>
      </div>
      {partnerCaptureMandatory && !isOrderAssigned ? (
        <div className="text:left text-sale mt-4 hidden text-base font-medium md:block md:text-right">
          {microCopy['please-enter-attribution-info']}
        </div>
      ) : null}
    </div>
  )
}

export default CartHeader
