import { type TypeOf, z } from '@simplisafe/ewok'

export const youtubeVideoSchema = z.object({
  title: z.string(),
  link: z.string(),
  text: z.string(),
  // TODO: ContentfulYoutubeVideo comes from Gatsby and can eventually be removed
  __typename: z.union([
    z.literal('ContentfulYoutubeVideo'),
    z.literal('YoutubeVideo')
  ])
})

export type YoutubeVideoProps = TypeOf<typeof youtubeVideoSchema>
