// extracted by mini-css-extract-plugin
export var headerPartner = "HeaderPartner-module--headerPartner--53e3d";
export var headerPartnerInner = "HeaderPartner-module--headerPartnerInner--ca2d7";
export var logoContainer = "HeaderPartner-module--logoContainer--370e5";
export var logoContainerLeft = "HeaderPartner-module--logoContainerLeft--0571c";
export var logoContainerRight = "HeaderPartner-module--logoContainerRight--f164f";
export var logosContainer = "HeaderPartner-module--logosContainer--68d75";
export var mergerText = "HeaderPartner-module--mergerText--fe9dc";
export var mergerTextContainer = "HeaderPartner-module--mergerTextContainer--96366";
export var offerText = "HeaderPartner-module--offerText--0ad50";
export var offerTextContainer = "HeaderPartner-module--offerTextContainer--e7bbd";