import { z } from 'zod'

const stringMinLength = (fieldName: string, minLength: number, message = '') =>
  z
    .string({ required_error: `The field ${fieldName} is required` })
    .min(minLength, message)
    .refine(value => value.trim().length > 0, {
      message: `The field ${fieldName} must not be empty.`
    })

export const firstNameSchema = stringMinLength('First Name', 1)
export const lastNameSchema = stringMinLength('Last Name', 1)

export const streetAddress1Schema = stringMinLength('Street Address', 1)
export const streetAddress2Schema = z.string().optional()

export const citySchema = stringMinLength('City', 1)

export const zipCodeSchema = stringMinLength(
  'Zip Code',
  4,
  'Please enter a valid zip code'
)
export const stateSchema = stringMinLength('State', 2, 'Please select a state')

export const phoneNumberSchema = stringMinLength(
  'Phone Number',
  4,
  'Please enter a valid phone number'
)

export const emailSchema = z
  .string({ required_error: 'The field Email is required' })
  .email('Please enter a valid email')

export const sidNumberSchema = stringMinLength(
  'SID Number',
  4,
  'Please enter a valid SID number'
)

export const Form2GUpgradeSchema = z.object({
  city: citySchema,
  email: emailSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  phoneNumber: phoneNumberSchema,
  sidNumber: sidNumberSchema,
  state: stateSchema,
  streetAddress1: streetAddress1Schema,
  streetAddress2: streetAddress2Schema,
  zipCode: zipCodeSchema
})
