import { cartGet } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetCart } from '../atoms/useCart'

/**
 * Gets the cart on initial load and sets it in the cart atom.
 */
export const useRequestCart = () => {
  const setCart = useSetCart()

  return useCallback(() => {
    const cartId = getCartId()

    const fn = async (id: string) => {
      const cart = await cartGet(id)()
      pipe(
        cart,
        E.fold(
          err => {
            return !getCartId() // Calling getCartId() again to get a fresh value, since cartId might have been removed while the request was in flight
              ? setCart(['no_cart_id'])
              : setCart(['set_cart_error', err])
          },
          t => setCart(['update_cart', t])
        )
      )
    }
    pipe(
      O.fromNullable(cartId),
      O.fold(() => voidFn(), fn)
    )
  }, [setCart])
}
