import { useOptimizelyActivate } from '@ecomm/optimizely-hooks'
import {
  COOKIE_CHAT_APP_ID,
  getChatAppId,
  getChatOpened,
  setCookie
} from '@ecomm/shared-cookies'
import { path } from '@simplisafe/ewok'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useState } from 'react'

import { PageProps } from '../components/Page/types'

const enableLiveChat = (
  liveChat: boolean,
  bmsLiveChat: boolean,
  chatOpened: string,
  isQuizExperimentVariation1: boolean,
  pageUrl: string
) => {
  return (
    (liveChat && !bmsLiveChat && pageUrl !== 'quiz/results') ||
    bmsLiveChat ||
    (liveChat && pageUrl === 'quiz/results' && isQuizExperimentVariation1) ||
    chatOpened === 'true'
  )
}

const useEnableLiveChat = (data: PageProps['data']) => {
  const [isInLiveChatExperiment, setIsInLiveChatExperiment] = useState(false)
  const chatIdContentful: string =
    path(['contentfulPage', 'pageLayout', 'liveChatAppId'], data) || ''
  // Enable BMS LiveChat in webConfig
  const bmsLiveChat: boolean =
    path(['contentfulPage', 'pageLayout', 'bmsLiveChatTest'], data) || false

  const isQuizExperimentVariation1 = pipe(
    useOptimizelyActivate('all___quiz_results___live_chat'),
    ([quizExperimentVariation]) => quizExperimentVariation,
    O.match(
      () => false,
      quizExperimentVariation => quizExperimentVariation === 'variation_1'
    )
  )
  const pageUrl: string = path(['contentfulPage', 'pageUrl'], data) || ''

  // Enable regular LiveChat on page or the Sales Livechat
  const hasLiveChat: boolean =
    path(['contentfulPage', 'pageLayout', 'hasLiveChat'], data) || false
  const chatOpened: string = getChatOpened()
  const _enableLiveChat = enableLiveChat(
    hasLiveChat,
    bmsLiveChat,
    chatOpened,
    isQuizExperimentVariation1,
    pageUrl
  )

  // Get AppId Cookie for Sales LiveChat
  const chatIdCookie: string = getChatAppId()
  const chatId = chatIdCookie || chatIdContentful
  // Set Cookie for Sales LiveChat
  isInLiveChatExperiment &&
    chatOpened === 'true' &&
    setCookie(COOKIE_CHAT_APP_ID, chatIdContentful, cookiesOption)

  return {
    liveChatEnabled: _enableLiveChat,
    liveChatId: chatId
  }
}

export default useEnableLiveChat
