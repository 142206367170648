import { usePriceContext } from '@ecomm/data-price'
import { useDisplayMonitoringDiscount } from '@ecomm/promotions-hooks'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'

type Props = {
  readonly sku: string
}

/**
 * This is a duplicate of apps/shop/src/components/Price/Price.tsx
 * Used for partner pages
 */
export function Price({ sku }: Props) {
  const {
    getPrice,
    isLoading,
    getDiscountedPriceWithServicePlan,
    getDiscountedPrice,
    formatPrice
  } = usePriceContext()

  const displayMonitoringDiscount = useDisplayMonitoringDiscount()

  const price = pipe(
    O.of(isLoading),
    O.chain(loading => (loading ? O.none : formatPrice(getPrice(sku)))),
    O.map(p => (
      <span
        className="text-2xl font-extralight line-through"
        key={`price-${sku}`}
      >
        {p}
      </span>
    )),
    O.toNullable
  )

  const discountedPrice = pipe(
    O.of(isLoading),
    O.chain(loading =>
      loading
        ? O.none
        : formatPrice(
            displayMonitoringDiscount
              ? getDiscountedPriceWithServicePlan(sku)
              : getDiscountedPrice(sku)
          )
    ),
    O.map(p => (
      <span
        className="text-complementary-red-100 mr-2 text-2xl font-extralight"
        key={`price-discount-${sku}`}
      >
        {p}
      </span>
    )),
    O.toNullable
  )

  return (
    <div className="text-2xl font-extralight">
      <div className="flex flex-wrap">
        {discountedPrice}
        {price}
      </div>
    </div>
  )
}
