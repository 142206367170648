import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingSummaryDetails() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (label: TrackingData['label']) => {
      trackEvent({
        event: 'summary-details',
        label
      })
    },
    [trackEvent]
  )
}
