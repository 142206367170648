import { CheckMark, PaypalLogo } from '@ecomm/shared-icons'

type PaypalCheckoutCompleteProps = {
  readonly email: string
}

export function PaypalCheckoutComplete({ email }: PaypalCheckoutCompleteProps) {
  return (
    <div className="bg-neutral-gray rounded-2xl">
      <div className="flex flex-col items-start">
        <PaypalLogo className="mb-2 h-10 w-32" />
        <div className="flex flex-row">
          <CheckMark className="text-complementary-green-100 mx-2 my-auto h-4 w-4" />
          <span className="items-center text-lg">
            Authorized with
            <strong className="text-gray-600"> {email}</strong>
          </span>
        </div>
      </div>
    </div>
  )
}
