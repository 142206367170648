import type { LegalTextPage } from '@ecomm/legal-data'
import { TrustpilotUKTopBanner } from '@ecomm/shared-components'
import type { Locale } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'

import { LegalBreadCrumbs } from './LegalBreadcrumbs'
import { LegalRichText } from './LegalRichText'
import { SubmitYourIdeasForm } from './SubmitYourIdeasForm'

type Props = LegalTextPage & {
  readonly locale: Locale
}

export function LegalContent({
  form,
  breadcrumbTitle,
  slug,
  locale,
  text
}: Props) {
  return (
    <>
      <LegalBreadCrumbs
        breadcrumbTitle={O.fromNullable(breadcrumbTitle)}
        slug={slug}
      />
      {locale === 'en-GB' && <TrustpilotUKTopBanner className="mt-7 md:mt-8" />}
      <main
        className="max-w-8xl mx-auto mb-16 flex flex-col p-4 md:p-8"
        id="content"
      >
        <LegalRichText text={text} />
        {form ? <SubmitYourIdeasForm {...form} /> : null}
      </main>
    </>
  )
}
