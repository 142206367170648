import { gql } from '@apollo/client/index.js'

export const PRODUCT_IMAGE_CAROUSEL_QUERY = gql`
  fragment ImageCarouselCollection on AssetCollection {
    items {
      url
      description
      originalWidth: width
      originalHeight: height
    }
  }

  query ProductImageCarouselData($productId: String!, $preview: Boolean!, $locale: String!) {
    product(id: $productId, preview: $preview, locale: $locale) {
      imageCarouselCollection(preview: $preview, locale: $locale) {
        ...ImageCarouselCollection
      }
    }
  }
`
