import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const duplexSmallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: contentfulRichTextSchema
})

export const duplexImageLinkSchema = z.object({
  __typename: z.literal('ContentfulImageLink'),
  image: gatsbyImageSchema
})

export const possibleEmbeddedSchema = z.union([
  duplexSmallTextSchema,
  duplexImageLinkSchema,
  guidedSystemBuilderSchema
])

export const duplexSectionSchema = z.object({
  __typename: z.literal('ContentfulDuplex'),
  left: possibleEmbeddedSchema.array(),
  right: possibleEmbeddedSchema.array()
})

export type PossibleEmbeddedComponent = TypeOf<typeof possibleEmbeddedSchema>
export type DuplexSmallText = TypeOf<typeof duplexSmallTextSchema>
export type DuplexImageLink = TypeOf<typeof duplexImageLinkSchema>
export type DuplexSectionSchema = TypeOf<typeof duplexSectionSchema>
