import {
  FAST_PROTECT_THREE_MONTHS_OFFER,
  INTERACTIVE_MONITORING,
  SELF_MONITORING_WITH_CAMERA_RECORDINGS,
  STANDARD_MONITORING
} from '@ecomm/data-constants'
import {
  useIsVariationOfExperiment,
  useMonthsOfFreeServiceText
} from '@ecomm/promotions-hooks'
import { ScoutExpandableMonitoring } from '@ecomm/scout'
import React from 'react'

import AddToCartButton from '../../AddToCartButton'

export function LegacyChooseMonitoringExpandable() {
  /**
   * 3 free months monitoring experiment
   */
  const isThreeMonthsMonitoringExperiment = useIsVariationOfExperiment(
    'all___us___promo___three_months_offer'
  )

  const monthsOfFreeService: string = useMonthsOfFreeServiceText(true)
  const monthsOfFreeServiceVariation: string = isThreeMonthsMonitoringExperiment
    ? '3 free months'
    : monthsOfFreeService
  const premiumSku: string = isThreeMonthsMonitoringExperiment
    ? FAST_PROTECT_THREE_MONTHS_OFFER
    : INTERACTIVE_MONITORING

  const professionalMonitoringAtcButton = (
    <AddToCartButton
      data={{
        buttonText: `Add ${monthsOfFreeServiceVariation}`,
        className: '!w-auto',
        productSku: premiumSku
      }}
    />
  )

  const selfMonitoringAtcButton = (
    <AddToCartButton
      data={{
        buttonText: `Add ${monthsOfFreeService}`,
        className: '!w-auto',
        productSku: SELF_MONITORING_WITH_CAMERA_RECORDINGS
      }}
    />
  )

  const standardMonitoringAtcButton = (
    <AddToCartButton
      data={{
        buttonText: `Add ${monthsOfFreeService}`,
        className: 'w-full md:w-auto',
        productSku: STANDARD_MONITORING
      }}
    />
  )

  return (
    <ScoutExpandableMonitoring
      professionalMonitoringAtcButton={professionalMonitoringAtcButton}
      selfMonitoringAtcButton={selfMonitoringAtcButton}
      standardMonitoringAtcButton={standardMonitoringAtcButton}
    />
  )
}
