/**
 * Checks if a string is valid URL
 * Return true or false
 */

function isValidUrl(urlString: string): boolean {
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
  return urlRegex.test(urlString)
}

export default isValidUrl
