import type { CookieSetOptions } from 'universal-cookie'
import { getLocale } from '@ecomm/utils'

export const getCookieDomain = () =>
  getLocale() === 'en-GB' ? '.simplisafe.co.uk' : '.simplisafe.com'

export const COOKIE_MANDO_EXPIRES_TOKEN = 'ssOauthAccessExpires'

export const getWebAppUrl = () => {
  return process.env['WEBAPP_URL']
    ? process.env['WEBAPP_URL']
    : 'https://webapp.simplisafe.com'
}
export const cookiesOptions: CookieSetOptions = {
  domain: getCookieDomain(),
  path: '/'
}
