import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.RedSiren,
    text: 'Built-in 80 dR siren'
  },
  {
    icon: SharedIcons.PrivacyShutter,
    text: 'Privacy shutter'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.SupportIcon,
    text: 'Enables live guard protection'
  },
  {
    icon: SharedIcons.BatteryRechargeable,
    text: 'Built-in rechargeable battery'
  },
  {
    icon: SharedIcons.MotionDetection,
    text: '125° field of view'
  },
  {
    icon: SharedIcons.SmartMotion,
    text: 'Smart motion detection'
  }
]

const description = {
  content: `Our most advanced indoor camera does what others can't. When used with a Fast Protect™ Monitoring Plan, professional monitoring agents can see and speak to intruders, helping stop crime in real time.
  \nAgents can only access your video feed and microphone during an active alarm.
  \nYour data feed is encrypted, so it can't be seen by unauthorized viewers. The audible privacy shutter opens and closes when you determine it should.
  \nIt's the only indoor camera with a built-in siren and live, two-way agent audio.
  \nVision Al motion detection distinguishes between humans and pets, so you can be confident that a threat is real.
  `
}

const specs = {
  content: `4.53 × 1.46 × 2.95 inches
  \nOptional screw kit for wall mounting
  \nBuilt-in rechargeable lithium-ion battery
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: 'uPcY3hDKhGUwKMigIgRXo'
}

export const smartAlarmIndoorCameraData: ProductData = {
  productId: '45MsIZkPTKu10d8foUwCQH',
  sensorName: 'Smart Alarm Indoor Camera',
  features,
  description,
  specs,
  faqs
}
