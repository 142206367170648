import { getPartnerCookie } from '@ecomm/shared-cookies'

/**
 * Retrieves partners_data cookie and checks if airline is a partner
 * @returns boolean
 */
export const isAirlinePartner = () => {
  const cookie = getPartnerCookie()
  return !!cookie && cookie.partnerGroup === 'airlines'
}
