import React, { useCallback, useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import type { QuantityChangerProps } from '../../types'

type Props = {
  readonly trackQuantityChange?: (
    newQuantity: number,
    oldQuantity: number
  ) => void
  readonly quantity: QuantityChangerProps
}

export function CartQuantity({
  trackQuantityChange = null,
  quantity: { defaultValue, disabled, max, min = 1, onChange }
}: Props) {
  const [quantity, setQuantity] = useState(defaultValue || 1)
  const { Track } = useTracking()

  const minDisabled = disabled || quantity <= min
  const maxDisabled = (max && quantity >= max) || disabled

  const incrementQuantity = useCallback(() => {
    !maxDisabled && setQuantity((q: number) => q + 1)
    !maxDisabled && onChange && onChange(quantity + 1, 'add')
    !maxDisabled &&
      trackQuantityChange &&
      trackQuantityChange(quantity + 1, quantity)
  }, [trackQuantityChange, setQuantity, quantity, onChange, maxDisabled])

  const decrementQuantity = useCallback(() => {
    !minDisabled && setQuantity((q: number) => q - 1)
    !minDisabled && onChange && onChange(quantity - 1, 'remove')
    !minDisabled &&
      trackQuantityChange &&
      trackQuantityChange(quantity - 1, quantity)
  }, [trackQuantityChange, setQuantity, quantity, onChange, minDisabled])

  useEffect(() => {
    setQuantity(defaultValue)
  }, [defaultValue])

  return (
    <Track>
      <div
        className="flex items-center justify-center"
        data-testid="cart-quantity-component"
      >
        <button
          aria-label="Click here to decrease the quantity"
          className="bg-neutral-light-50 flex h-6 w-6 cursor-pointer items-center justify-center rounded-[0.3125rem] border-none"
          data-testid="draft-quantity-decrease-button"
          disabled={minDisabled}
          onClick={decrementQuantity}
        >
          <span>-</span>
        </button>
        <span className="flex px-2" data-testid="cart-quantity-number">
          {quantity}
        </span>
        <button
          aria-label="Click here to increase the quantity"
          className="bg-neutral-light-50 flex h-6 w-6 cursor-pointer items-center justify-center rounded-[0.3125rem] border-none"
          data-testid="draft-quantity-increase-button"
          disabled={maxDisabled}
          onClick={incrementQuantity}
        >
          <span>+</span>
        </button>
      </div>
    </Track>
  )
}
