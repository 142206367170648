import { logError } from '@ecomm/error-handling'
import { getValueFromLeadData } from '@ecomm/shared-cookies'
import { getNavigator } from '@ecomm/shared-window'
import { LeadGenCaptureResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import { getDeviceType } from '@simplisafe/ss-ecomm-data/utils/windowScreenSize'

import { brazeInitialized } from '../../..'
import { BrazeProps, User } from '../../../../types/braze'
import { brazeSetCustomUserAttributes } from '../../brazeSetCustomUserAttributes'
import { UPDATE_LEADS_DATA_DELAY } from '../../constants'
import { formatPhoneNumber } from '../../formatPhoneNumber'
import { handleBrazeImmediateFlush } from '../../handleBrazeImmediateFlush'
import { setUserId } from '../setUserId'

/**
 * Retrieve user's country locale
 * @returns
 */
const getCountryValue = () => {
  // Safari returns 'en' for US
  const getBrowserLanguage = (browserLanguage: string): string =>
    browserLanguage === 'en' ? 'en-us' : browserLanguage
  // Use country value from navigator language. If undefined, use the value from env variable
  const siteLanguage =
    getNavigator(navigator =>
      getBrowserLanguage(navigator.language.toLowerCase())
    ) || process.env['LOCALE']

  return siteLanguage ? siteLanguage.split('-')[1].toUpperCase() : null
}

export function setUserIdCallback(
  externalId: string,
  leadId: string,
  leadSource: string,
  resolve: (value: PromiseLike<string> | string) => void
) {
  return (brazeUserId: string | null) => {
    !externalId &&
      logError(new Error('setUserIdCallback: externalId is nil'), {
        leadId,
        leadSource,
        externalId
      })
    const userId = externalId || `L${leadId}`
    if (brazeUserId !== externalId) {
      console.info(
        `Braze: In setUserIdCallback current Braze user does not match externalId from response. Calling setUserId with value ${userId}`
      )
      return resolve(setUserId(leadSource, userId))
    } else {
      console.info(
        `Braze: In setUserIdCallback current braze session user matches externalId from response, not changing user ${brazeUserId}`
      )
      return resolve(brazeUserId)
    }
  }
}

/**
 * Initializes braze user for current session
 * @param braze
 * @param leadData
 * @returns Id of initialized user
 */
export async function initUser(
  braze: BrazeProps,
  leadData: Required<LeadGenCaptureResponse>
) {
  const timeoutMs = 5000
  const { externalId, leadId, leadSource } = leadData
  console.info('Braze: In function initUser')
  const setUserIdPromise = new Promise<string>(resolve => {
    braze
      .getUser()
      .getUserId(setUserIdCallback(externalId, leadId, leadSource, resolve))
  })
  const timeoutPromise = new Promise<string>((_, reject) => {
    setTimeout(
      () =>
        reject(new Error('Timeout: Braze user initialization took too long')),
      timeoutMs
    )
  })

  return Promise.race([setUserIdPromise, timeoutPromise]).catch(e => {
    console.info('Braze: In function initUser, setUserId timed out')
    if (e instanceof Error) {
      logError(e)
    }
    return setUserId(leadSource, externalId || `L${leadId}`)
  })
}

export function brazeSetUserPhoneNumber(
  brazeUser: User,
  phoneNumber: string,
  leadId: string
) {
  const setPhonerNumberResult = brazeUser.setPhoneNumber(
    formatPhoneNumber(phoneNumber)
  )

  !setPhonerNumberResult &&
    logError(new Error('Failed to set phone number for Braze user'), {
      phoneNumber,
      leadId
    })

  process.env['GATSBY_BRAZE_SMS_SUBSCRIPTION_ID'] &&
    brazeUser.addToSubscriptionGroup(
      process.env['GATSBY_BRAZE_SMS_SUBSCRIPTION_ID']
    )
}

/**
 * Updates user's braze profile
 * @returns true if update was successfully flushed, false otherwise
 */
export function updateBrazeUser(
  braze: BrazeProps,
  brazeUser: User,
  leadData: Required<LeadGenCaptureResponse>,
  channel?: string,
  firstSubmission = false
) {
  const deviceType = getDeviceType().toLowerCase()
  const isMobile = deviceType === 'mobile'

  const { externalId, leadId, leadSource, email } = leadData
  return new Promise<boolean>(resolve => {
    const initialAttributes = firstSubmission
      ? {
          'First Lead Signup Channel': channel,
          'Lead Signup Device': isMobile ? 'mobile' : 'desktop'
        }
      : null
    setTimeout(() => {
      const setEmailResult = brazeUser.setEmail(email)
      const setCountryResult = brazeUser.setCountry(getCountryValue())
      const addAliasResult = brazeUser.addAlias('simplisafe_lead_id', leadId)
      const setCustomAttributesResult = brazeSetCustomUserAttributes({
        ...initialAttributes,
        'External ID': externalId,
        'Last Lead ID': leadId,
        'Last Lead Source': leadSource.replace(/[^a-zA-Z0-9]/g, ' '),
        'Last Lead Signup Channel': channel || ''
      })

      const phoneNumber = getValueFromLeadData('phone')
      phoneNumber && brazeSetUserPhoneNumber(brazeUser, phoneNumber, leadId)

      !(
        setCustomAttributesResult &&
        addAliasResult &&
        setEmailResult &&
        setCountryResult
      ) &&
        logError(new Error('Failed to set attribute for Braze user'), {
          setCustomAttributesResult: setCustomAttributesResult.toString(),
          addAliasResult: addAliasResult.toString(),
          setEmailResult: setEmailResult.toString(),
          setCountryResult: setCountryResult.toString()
        })

      braze.requestImmediateDataFlush(flushResult => {
        handleBrazeImmediateFlush({ caller: 'updateLeadsData', ...leadData })(
          flushResult
        )
        resolve(flushResult)
      })
    }, UPDATE_LEADS_DATA_DELAY)
  })
}

/**
 * Polls braze initialization status, rejects if not initialized within polling period
 * @returns
 */
export function pollBrazeInitialization(): Promise<boolean> {
  const maxAttempts = 10
  const checkIntervalMs = 400
  console.info('Braze SDK initialization polling started')
  return new Promise((resolve, reject) => {
    let attempts = 0
    const interval = setInterval(() => {
      if (brazeInitialized) {
        clearInterval(interval)
        console.info('Braze SDK initialization polled successfully')
        resolve(brazeInitialized)
      } else if (attempts > maxAttempts) {
        clearInterval(interval)
        const errorMessage =
          'Braze SDK failed to initialize within polling period'
        logError(new Error(errorMessage))
        reject(errorMessage)
      }
      attempts++
    }, checkIntervalMs)
  })
}
