import { useMicroCopy } from '@ecomm/micro-copy'
import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import { useTrackingCardShopNow } from '@ecomm/tracking'
import { Link } from 'gatsby'
import React from 'react'

export type ShopImageLinkProps = {
  readonly title: string
  readonly url: string
  readonly image: GatsbyImageSchema
}
export default function ShopImageLink({
  title,
  url,
  image
}: ShopImageLinkProps) {
  const microcopy = useMicroCopy()

  const trackShopNow = useTrackingCardShopNow(title)

  return (
    <div
      className="prose-h2:mb-4 prose-h2:font-default prose-h2:text-heading-3-size flex flex-col items-center gap-5"
      data-component="ShopImageLink"
      data-testid="ShopImageLink"
    >
      <h2>{title}</h2>
      <GatsbyImage className="h-52" image={image} objectFit="contain" />
      <Link
        className="btn btn-outlined btn-outlined-primary !px-4 mt-4 block w-full min-w-[190px] border-2 md:w-fit"
        onClick={trackShopNow}
        to={url}
      >
        {microcopy['shop-now']}
      </Link>
    </div>
  )
}
