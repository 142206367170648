import { useTrustPilotQuery } from '@ecomm/shared-data'
import { Suspense } from 'react'

import { TrustPilot } from '.'

type Props = {
  readonly id: string
  readonly className?: string
}

function GatsbyTrustPilotContent({ id, className = '' }: Props) {
  const data = useTrustPilotQuery(id)
  return <TrustPilot className={className} type={data.type} />
}

export function GatsbyTrustPilot({ id, className = '' }: Props) {
  return (
    <Suspense>
      <GatsbyTrustPilotContent className={className} id={id} />
    </Suspense>
  )
}
