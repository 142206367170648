import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export function optionEquals<T>(a: O.Option<T>, b: O.Option<T>): boolean {
  return pipe(
    a,
    O.map(_a => _a === pipe(b, O.toNullable)),
    O.getOrElse(() => O.isNone(b))
  )
}
