import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import { toPromoBannerStyleValue } from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { type PageProps, graphql, navigate } from 'gatsby'
import { useEffect } from 'react'

import EmailFeedbackForm from '../../components/EmailFeedbackForm'
import type { SeoNodeSchema } from '../../config/homeLandingResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { parseEmailFeedbackPage } from './schema'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
  readonly positiveFeedback: boolean
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function EmailFeedback<
  T extends { readonly contentfulGenericLandingPage: U },
  U
>({ data, pageContext: { locale, seoDetails, positiveFeedback } }: Props<T>) {
  const { layout } = parseEmailFeedbackPage(data)
  const {
    components,
    footer: { contentful_id: footerId }
  } = layout

  const { metaTitle, metaDescription, metaKeywords } = seoDetails
  const { isNofollow, isNoindex, canonicalLink } = seoDetails
  const headerData = useHeaderRedesignQuery()
  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle) || 'none'
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const campaignId = searchParams.get('campaign_id')
  const userId = searchParams.get('user')

  useEffect(() => {
    !campaignId && navigate('/')
  }, [])

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <div className="relative lg:z-20">
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={bannerStyle}
        />
      </div>
      <Header {...headerData} />
      <div className="prose md:prose-md lg:prose-lg max-w-xl mx-auto p-4 md:p-6 lg:p-8">
        {components.map(c => (
          <EmailFeedbackForm
            {...c}
            campaignId={campaignId ?? ''}
            key="feedaback-form"
            positiveFeedback={positiveFeedback}
            userId={userId ?? ''}
          />
        ))}
      </div>
      <Footer footerId={footerId} type="Full" />
    </TrackingProvider>
  )
}

export const query = graphql`
  query EmailFeedbackTemplate($id: String) {
    contentfulGenericLandingPage(id: { eq: $id }) {
      ...emailFeedbackPageFragment
    }
  }
`
