import { contentfulImageSchema } from '@ecomm/contentful/components'
import { v2GuidedSystemBuilderSchema as guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

const idSchema = z.object({
  id: z.string()
})

export const headerRedesignSchema = z.object({
  images: z.object({
    items: contentfulImageSchema
      .extend({ contentful_id: idSchema.transform(sysObj => sysObj.id) })
      .array()
  }),
  quoteWizard: guidedSystemBuilderSchema
})

export type HeaderRedesignSchema = TypeOf<typeof headerRedesignSchema>
