import { chainProp } from '@simplisafe/monda/chain'
import { selectPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions/select'
import { useSelector } from 'react-redux'

import { useCurrentPromoBannerData } from '../useCurrentPromoBannerData'
import { useMonthsOfFreeServiceText } from '../useMonthsOfFreeServiceText'

export type PartnerPromoBannerData = {
  readonly backgroundColor: string
  readonly discountSecondaryText: string
  readonly discountText: string
  readonly displayEmailInputField: boolean
  readonly hasPartnerBanner: boolean
  readonly lineItemText: string
  readonly linkPath: string
  readonly logoDescription: string
  readonly logoUrl: string
  readonly monthsOfService: string
  readonly partnerName: string
  readonly primaryOfferText: string
  readonly primaryTextColor: string
  readonly secondaryOfferText: string
  readonly secondaryOfferTextMobile: string
  readonly secondaryTextColor: string
}

export function usePartnerPromoBannerData(): PartnerPromoBannerData {
  const partnerBanner = useSelector(selectPartnerBanner)
  const { giftLineItemText, hasFreeGiftItem, offerTitle } =
    useCurrentPromoBannerData()
  const monthsOfServiceDisplay = useMonthsOfFreeServiceText(true)

  return {
    backgroundColor: partnerBanner
      .chain(chainProp('backgroundColor'))
      .getOrElse(''),
    discountSecondaryText: partnerBanner
      .chain(chainProp('discountSecondaryText'))
      .getOrElse(''),
    discountText: offerTitle || '15% off',
    displayEmailInputField: partnerBanner
      .chain(chainProp('displayEmailInputField'))
      .getOrElse(false),
    hasPartnerBanner: partnerBanner.isSome(),
    lineItemText: hasFreeGiftItem && giftLineItemText ? giftLineItemText : '',
    linkPath: partnerBanner.chain(chainProp('linkPath')).getOrElse(''),
    logoDescription: partnerBanner
      .chain(chainProp('logoDescription'))
      .getOrElse(''),
    logoUrl: partnerBanner.chain(chainProp('logoUrl')).getOrElse(''),
    monthsOfService: `${monthsOfServiceDisplay} of monitoring`,
    partnerName: partnerBanner.chain(chainProp('partnerName')).getOrElse(''),
    primaryOfferText: partnerBanner
      .chain(chainProp('primaryOfferText'))
      .getOrElse(''),
    primaryTextColor: partnerBanner
      .chain(chainProp('primaryTextColor'))
      .getOrElse(''),
    secondaryOfferText: partnerBanner
      .chain(chainProp('secondaryOfferText'))
      .getOrElse(''),
    secondaryOfferTextMobile: partnerBanner
      .chain(chainProp('secondaryOfferTextMobile'))
      .getOrElse(''),
    secondaryTextColor: partnerBanner
      .chain(chainProp('secondaryTextColor'))
      .getOrElse('')
  }
}
