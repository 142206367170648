import { useIsProInstallPromotionActive } from '@ecomm/promotions-components'
import { useMonthsOfFreeServiceText } from '@ecomm/promotions-hooks'
import { Text } from '@ecomm/ss-react-components'
import { prop } from '@simplisafe/ewok'
import React from 'react'

import { ContentfulProductInformation } from '../../../../graphql'
import usePriceVariation from '../../../hooks/usePriceVariation'
import { replaceTokensWithMonths } from '../../../util/monitoring-plan-placeholders'
import { replaceTokensWithPrice } from '../../../util/monitoring-price-placeholders'
import ContentfulRichText from '../../ContentfulRichText'
import { proInstallOverrideDescription } from './experimentsData'

export type CartLineItemDescriptionProps = {
  readonly onClick: (_url: string) => void
  readonly productInformation: ContentfulProductInformation
  readonly sku: string
}

function CartLineItemDescription({
  onClick,
  productInformation,
  sku
}: CartLineItemDescriptionProps) {
  const isProInstallPromoActive = useIsProInstallPromotionActive()
  const planProduct = usePriceVariation(sku)
  const planProductPrice = planProduct.cata(
    () => 0,
    value => prop('price', value)
  )
  const monthsOfService = useMonthsOfFreeServiceText(true)

  const displayProInstallExperimentDescription =
    isProInstallPromoActive && sku === 'SSPSH-ON'

  const description = displayProInstallExperimentDescription
    ? proInstallOverrideDescription
    : productInformation.description?.raw &&
      replaceTokensWithMonths(
        replaceTokensWithPrice(
          productInformation.description?.raw,
          planProductPrice
        ),
        monthsOfService
      )
  const references = productInformation.description?.references || []

  return (
    <div data-component="CartLineItemDescriptionWrapper">
      <Text
        className="prose-p:!text-[#78746F] prose-p:max-md:!text-xs text-[#78746F]"
        useTailwind
      >
        <ContentfulRichText
          onLinkClick={(e, uri) => {
            e.preventDefault()
            onClick && onClick(uri)
          }}
          raw={description}
          // @ts-expect-error TS(2322): Type 'readonly Maybe<ContentfulAssetContentfulLink... Remove this comment to see the full error message
          references={references}
        />
      </Text>
    </div>
  )
}

export default CartLineItemDescription
