import { RawGiftItemContainer, usePriceContext } from '@ecomm/data-price'
import { useIsVariationOfExperiment } from '@ecomm/promotions-hooks'
import { useEnv } from '@ecomm/utils'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useSetAtom } from 'jotai'
import { useMemo } from 'react'

import { freeGiftItemAtom } from '../../atoms/draftCart/freeGiftAtom'
import useMonitoringPlan from '../../hooks/IMAPS/useMonitoringPlan'
import { DraftCartFragment } from './types'

export const useGiftItems = (sku: string): RawGiftItemContainer => {
  const { getFreeGiftItems, getFreeGiftItemsWithServicePlan } =
    usePriceContext()
  const setFreeGiftItems = useSetAtom(freeGiftItemAtom)

  const withMonitoringGiftItems = pipe(
    getFreeGiftItemsWithServicePlan(sku),
    O.toNullable
  )

  const withoutMonitoringGiftItems = pipe(getFreeGiftItems(sku), O.toNullable)

  const freeGiftItems: RawGiftItemContainer = useMemo(() => {
    ;(withMonitoringGiftItems || withoutMonitoringGiftItems) &&
      setFreeGiftItems({
        withMonitoring: withMonitoringGiftItems,
        withoutMonitoring: withoutMonitoringGiftItems
      })

    return {
      withMonitoring: withMonitoringGiftItems,
      withoutMonitoring: withoutMonitoringGiftItems
    }
  }, [withMonitoringGiftItems, withoutMonitoringGiftItems, setFreeGiftItems])

  return freeGiftItems
}

export const useGetDynamicDiscountsSku = (): string => {
  const { locale } = useEnv()
  return useMemo(
    () =>
      `simplisafe-custom-home-security-system-en-${
        locale === 'en-US' ? 'US' : 'GB'
      }`,
    [locale]
  )
}

export const useGetDraftCartStaticList = (
  priceToggles: DraftCartFragment['priceToggles'],
  { products, otherItems }: DraftCartFragment['defaultBundle']
) => {
  const { hasMonitoring } = useMonitoringPlan({ priceToggles })
  const isFree100VisaGiftCardExperiment = useIsVariationOfExperiment(
    'all___us___promo___free__100_visa_gift_card'
  )

  return {
    baseProducts: products,
    otherItems:
      hasMonitoring && isFree100VisaGiftCardExperiment
        ? [{ key: '$100 Visa Gift Card', value: 'FREE' }, ...otherItems]
        : otherItems
  }
}
