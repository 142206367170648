import { TypeOf, z } from '@simplisafe/ewok'
import { ReactNode } from 'react'

const Children: Zod.ZodType<ReactNode> = z.any()

export const systemHeroTextSchema = z.object({
  __typename: z.literal('ContentfulBmsPageHeroText'),
  raw: z.string()
})

const SystemHeroSchema = z.object({
  children: Children,
  heroText: systemHeroTextSchema
})

export type SystemHeroFragment = TypeOf<typeof SystemHeroSchema>
