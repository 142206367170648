import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const bannerPhoneSchema = z.object({
  title: z.string(),
  subtitle: z.string(),
  icon: gatsbyImageSchema,
  phoneNumber: z.string(),
  backgroundColor: z.string().optional()
})

export type BannerPhoneSchema = TypeOf<typeof bannerPhoneSchema>
