import { useMicroCopy } from '@ecomm/micro-copy'
import { SimpliSafeLogoBlack } from '@ecomm/shared-icons'
import React from 'react'

type HeaderComponentProps = {
  readonly classNames?: string
}

export function LeftColumnHeaderComponent({
  classNames = ''
}: HeaderComponentProps) {
  return (
    <div
      className={`${classNames} text-center text-xs font-bold md:text-base whitespace-pre-line prose md:prose-md lg:prose-lg`}
      data-component="Text"
    >
      Fast Protect&trade; Professional Monitoring
    </div>
  )
}

export function RightColumnHeaderComponent({
  classNames = ''
}: HeaderComponentProps) {
  return (
    <div
      className={`${classNames} prose md:prose-md lg:prose-lg text-center text-xs font-bold md:text-base`}
      data-component="Text"
    >
      Self Monitoring with Camera Recordings
    </div>
  )
}

export function useScoutPlanComparisonData() {
  const microCopy = useMicroCopy()
  const fastProtectDailyPrice = microCopy['fast-protect-daily-price']

  return {
    headline: <h3 className="text-sm md:text-4xl">Plan comparison</h3>,
    leftColumnHeader: (
      <LeftColumnHeaderComponent classNames="m-auto max-w-[190px]" />
    ),
    rightColumnHeader: (
      <RightColumnHeaderComponent classNames="m-auto max-w-[190px]" />
    ),
    sections: [
      {
        title: 'MONITORING SERVICES',
        features: [
          {
            title: '24/7 monitoring against break-ins, fires, floods, and more',
            leftColumn: true,
            rightColumn: false,
            trackingCode: '1',
            modal: {
              headline:
                '24/7 monitoring against break-ins, fires, floods, and more',
              description:
                'Professional monitoring agents respond to break-in and fire alarms. In cases in which you cannot be reached or a threat is verified, agents will dispatch emergency responders to your home.'
            }
          },
          {
            title: 'Alert and phone call in case of flooding or extreme temps',
            leftColumn: true,
            rightColumn: false,
            trackingCode: '2',
            modal: {
              headline:
                'Alert and phone call in case of flooding or extreme temps',
              description:
                'A Water Sensor is required for flood detection. A Temperature Sensor is required for extreme temperature detection.'
            }
          },
          {
            title: 'Intruder deterrence with live, 2-way agent audio*',
            leftColumn: true,
            rightColumn: false
          },
          {
            title: 'Agents verify incidents using video when possible',
            leftColumn: true,
            rightColumn: false,
            trackingCode: '3',
            modal: {
              headline: 'Agents verify incidents using video when possible',
              description:
                'When agents capture video evidence of a crime in progress, they will relay the information to emergency dispatch — which can mean faster police response compared to a non-verified alarm.'
            }
          }
        ]
      },
      {
        title: 'HARDWARE & CAMERAS',
        features: [
          {
            title: 'Cellular backup in the event of power or internet outage',
            leftColumn: true,
            rightColumn: false,
            trackingCode: '4',
            modal: {
              headline:
                'Cellular backup in the event of power or Internet outage',
              description:
                'When unable to connect via Wi-Fi, the Base Station uses the cellular network to transmit to monitoring centers. It also comes with battery backup to keep running up to 24 hours after a power outage.'
            }
          },
          {
            title: 'Unlimited camera recordings & 30-day cloud storage',
            leftColumn: true,
            rightColumn: true
          },
          {
            title: 'HD live view on all cameras',
            leftColumn: true,
            rightColumn: true
          }
        ]
      },
      {
        title: 'MOBILE APP & SMART HOME',
        features: [
          {
            title: 'Confirm or cancel alarms in seconds via alarm texts',
            leftColumn: true,
            rightColumn: false
          },
          {
            title: 'Arm & disarm system, lock & unlock doors remotely in app',
            leftColumn: true,
            rightColumn: true
          },
          {
            title: 'Arm system with Alexa, Google Assistant & Apple Watch',
            leftColumn: true,
            rightColumn: true
          }
        ]
      },
      {
        title: 'WARRANTY, DISCOUNTS & PRICE',
        features: [
          {
            title: 'Extended warranty',
            leftColumn: <h4>Subscription lifetime</h4>,
            rightColumn: <h4>Up to 3 years</h4>,
            trackingCode: '5',
            modal: {
              headline: 'Extended warranty',
              description:
                'SimpliSafe provides a 1-year limited hardware warranty on all products. For additional hardware protection, customers who sign up for a service plan before the 1-year warranty expires will be covered by the respective product protection plan for as long as the service is active. Please see our Terms of Sale and Terms of Service for details and conditions.'
            }
          },
          {
            title: 'Discount on new hardware purchases',
            leftColumn: <h4 className="font-normal">10%</h4>,
            rightColumn: <h4>N/A</h4>
          },
          {
            title: 'Home insurance discounts',
            leftColumn: <h4>Up to 15%</h4>,
            rightColumn: <h4>N/A</h4>,
            trackingCode: '6',
            modal: {
              headline: 'Home insurance discounts',
              description:
                'Some insurance companies offer discounts for homes that have security systems, including burglary, smoke, water and temperature monitoring. Check with yours to see if you qualify.'
            }
          },
          {
            title: 'No contracts. Cancel anytime.',
            leftColumn: true,
            rightColumn: true
          },
          {
            title: 'Price',
            leftColumn: <h4>{fastProtectDailyPrice} per day</h4>,
            rightColumn: <h4>33¢ per day</h4>
          }
        ]
      }
    ]
  }
}

export function usePlanComparisonData() {
  const microCopy = useMicroCopy()
  const fastProtectDailyPrice = microCopy['fast-protect-daily-price']

  return {
    headline: <h3 className="text-sm md:text-4xl">Plan comparison</h3>,
    leftColumnHeader: <LeftColumnHeaderComponent />,
    rightColumnHeader: <RightColumnHeaderComponent />,
    sections: [
      {
        title: 'MONITORING SERVICES',
        features: [
          {
            title: '24/7 monitoring against break-ins, fires, floods, and more',
            leftColumn: true,
            rightColumn: false
          },
          {
            title: 'Alert and phone call in case of flooding or extreme temps',
            leftColumn: true,
            rightColumn: false
          },
          {
            title: 'Intruder deterrence with live, 2-way agent audio*',
            leftColumn: true,
            rightColumn: false
          },
          {
            title: 'Agents verify incidents using video when possible',
            leftColumn: true,
            rightColumn: false
          }
        ]
      },
      {
        title: 'HARDWARE & CAMERAS',
        features: [
          {
            title: 'Celullar backup in the event of power or internet outage',
            leftColumn: true,
            rightColumn: false
          },
          {
            title: 'Unlimited camera recordings & 30-day cloud storage',
            leftColumn: true,
            rightColumn: true
          },
          {
            title: 'HD live view on all cameras',
            leftColumn: true,
            rightColumn: true
          }
        ]
      },
      {
        title: 'MOBILE APP & SMART HOME',
        features: [
          {
            title: 'Confirm or cancel alarms in seconds via alarm texts',
            leftColumn: true,
            rightColumn: false
          },
          {
            title: 'Arm & disarm system, lock & unlock doors remotely in app',
            leftColumn: true,
            rightColumn: true
          },
          {
            title: 'Arm system with Alexa, Google Assistant & Apple Watch',
            leftColumn: true,
            rightColumn: true
          }
        ]
      },
      {
        title: 'WARRANTY, DISCOUNTS & PRICE',
        features: [
          {
            title: 'Extended warranty',
            leftColumn: <h4 className="font-normal">Subscription lifetime</h4>,
            rightColumn: true
          },
          {
            title: 'Discount on new hardware purchases',
            leftColumn: <h4 className="font-normal">10%</h4>,
            rightColumn: true
          },
          {
            title: 'Home insurance discounts',
            leftColumn: <h4 className="font-normal">Up to 15%</h4>,
            rightColumn: true
          },
          {
            title: 'No contracts. Cancel anytime.',
            leftColumn: true,
            rightColumn: true
          },
          {
            title: 'Price',
            leftColumn: <h4>{fastProtectDailyPrice} per day</h4>,
            rightColumn: <h4>33¢ per day</h4>
          }
        ]
      }
    ]
  }
}

export const theSimpliSafeDifference = {
  headline: (
    <h2 className="text-xs md:text-4xl">The SimpliSafe&reg; difference</h2>
  ),
  leftColumnHeader: <SimpliSafeLogoBlack className="h-2.5 md:h-9" />,
  rightColumnHeader: (
    <h4 className="text-xs font-bold md:text-base">
      Traditional Home Security
    </h4>
  ),
  sections: [
    {
      features: [
        {
          title: 'Whole home protection',
          leftColumn: true,
          rightColumn: true
        },
        {
          title: 'Mobile app control & smart home integration',
          leftColumn: true,
          rightColumn: true
        },
        {
          title: '24/7 professional monitoring for less than $1/day',
          leftColumn: true,
          rightColumn: false
        },
        {
          title: 'Faster police response powered by Fast Protect™ Technology*',
          leftColumn: true,
          rightColumn: false
        },
        {
          title:
            'Agents can see and speak to intruders with live, 2-way indoor audio*',
          leftColumn: true,
          rightColumn: false
        },
        {
          title: 'No contracts or hidden fees. Cancel anytime.',
          leftColumn: true,
          rightColumn: false
        },
        {
          title: 'Your choice of professional or self-installation',
          leftColumn: true,
          rightColumn: false
        }
      ]
    }
  ]
}
