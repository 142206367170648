import { DocumentNode } from '@apollo/client/index.js'
import { useContentful } from '@ecomm/shared-apollo'
import { useMemo } from 'react'

import { headerRedesignSchema } from '../components/Header/schema'
import { DEFAULT_HEADER_REDESIGNED_QUERY } from './defaultHeaderQuery'

export const useHeaderRedesignQuery = (
  HEADER_QUERY: DocumentNode = DEFAULT_HEADER_REDESIGNED_QUERY
) => {
  const { data: apolloData } = useContentful(HEADER_QUERY)
  const headerData = useMemo(
    () => headerRedesignSchema.parse(apolloData),
    [apolloData]
  )
  return headerData
}
