import { ContentfulRichText } from '@ecomm/shared-components'
import React from 'react'

import { Output as ComponentProps } from '../../hooks/useLocalizedData'
import { ScoutVideo } from '../ScoutVideo'
import { schema } from './schema'

type TwoColumnVideoProps = ComponentProps<typeof schema>

export function TwoColumnVideo({
  data
}: {
  readonly data: TwoColumnVideoProps
}) {
  const { description } = data

  return (
    <div className="prose md:prose-md lg:prose-lg grid grid-cols-12 gap-4 md:gap-8 lg:gap-16">
      <div className="col-span-12 md:col-span-7">
        <ScoutVideo type="ScoutProductInstallationVideo" />
      </div>
      <div className="col-span-12 md:col-span-5 ">
        <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
          <ContentfulRichText raw={description.raw} />
        </div>
      </div>
    </div>
  )
}
