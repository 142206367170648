import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'
const modalTriggerSchema = z
  .object({ text: z.string().nullable() })
  .nullable()
  .optional()

const emailSchema = z
  .object({
    form: z.array(
      z.object({
        placeholder: z.string(),
        title: z.string()
      })
    )
  })
  .optional()
  .nullable()

export const saveMySystemModalSchema = z.object({
  button: z
    .object({
      id: z.string(),
      text: z.string().nullable()
    })
    .optional(),
  description: z.object({ raw: z.string() }).optional(),
  disclaimer: z.object({ raw: z.string() }).optional(),
  email: emailSchema,
  headline: z.string().optional().nullable(),
  image: gatsbyImageSchema.optional().nullable(),
  modalTrigger: modalTriggerSchema,
  success: z.object({ raw: z.string() }).optional()
})
