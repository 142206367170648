import { getRudderstack } from '@ecomm/shared-window'

// extend type when additional properties are required, or default properties need to be overridden
type Traits = {
  /** TikTok Ads ID */
  readonly ttclid?: string
}

/**
 * Identify a visitor and associate them with their actions. Also supports assigning and updating visitor traits.
 *
 * https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/supported-api/#identify
 *
 * This function will eventually be expanded to capture additional traits with a type interface locking
 * down the expected trait properties.
 *
 * CAUTION: If you are expanding traits to include PII, it is likely that transformations
 * in RudderStack will also need to be adjusted.
 */
export function trackIdentify(visitorId: string, traits?: Traits) {
  getRudderstack(r => r.identify(visitorId, traits))
}
