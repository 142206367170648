import { GatsbyImageSchema } from '@ecomm/contentful-schemas'

import { AppWidgetData, AppWidgetTab } from '../../'

const imageCarousel1: GatsbyImageSchema = {
  description: 'Carousel Image - Live Feed',
  title: 'Carousel Image - Live Feed',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/17MBm73hOXOEvW1W0KOaFJ/a525e8ab8dfdf8640a79b25c6b600f18/image__9_.png?w=125&h=157&q=50&fm=webp 125w,\nhttps://images.ctfassets.net/br4ichkdqihc/17MBm73hOXOEvW1W0KOaFJ/a525e8ab8dfdf8640a79b25c6b600f18/image__9_.png?w=250&h=314&q=50&fm=webp 250w,\nhttps://images.ctfassets.net/br4ichkdqihc/17MBm73hOXOEvW1W0KOaFJ/a525e8ab8dfdf8640a79b25c6b600f18/image__9_.png?w=500&h=627&q=50&fm=webp 500w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/17MBm73hOXOEvW1W0KOaFJ/a525e8ab8dfdf8640a79b25c6b600f18/image__9_.png?w=500&h=627&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1000,
    height: 1254
  }
}

const imageCarousel2: GatsbyImageSchema = {
  description: 'Carousel Image - Alerts',
  title: 'Carousel Image - Alerts',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/1pFPd7TTSUi9Etgg9J2PWR/479bf099d3fc298a9a35e8e3811a2f0d/image__10_.png?w=250&h=314&q=50&fm=webp 250w,\nhttps://images.ctfassets.net/br4ichkdqihc/1pFPd7TTSUi9Etgg9J2PWR/479bf099d3fc298a9a35e8e3811a2f0d/image__10_.png?w=500&h=627&q=50&fm=webp 500w,\nhttps://images.ctfassets.net/br4ichkdqihc/1pFPd7TTSUi9Etgg9J2PWR/479bf099d3fc298a9a35e8e3811a2f0d/image__10_.png?w=1000&h=1254&q=50&fm=webp 1000w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/1pFPd7TTSUi9Etgg9J2PWR/479bf099d3fc298a9a35e8e3811a2f0d/image__10_.png?w=1000&h=1254&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1000,
    height: 1254
  }
}

const imageCarousel3: GatsbyImageSchema = {
  description: 'Carousel Image - Motion',
  title: 'Carousel Image - Motion',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/3hl66TGxQW2uKyTUYLNw1m/9f2bfdd48172278d02cbdd4d5552df24/image__11_.png?w=250&h=314&q=50&fm=webp 250w,\nhttps://images.ctfassets.net/br4ichkdqihc/3hl66TGxQW2uKyTUYLNw1m/9f2bfdd48172278d02cbdd4d5552df24/image__11_.png?w=500&h=627&q=50&fm=webp 500w,\nhttps://images.ctfassets.net/br4ichkdqihc/3hl66TGxQW2uKyTUYLNw1m/9f2bfdd48172278d02cbdd4d5552df24/image__11_.png?w=1000&h=1254&q=50&fm=webp 1000w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/3hl66TGxQW2uKyTUYLNw1m/9f2bfdd48172278d02cbdd4d5552df24/image__11_.png?w=1000&h=1254&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1000,
    height: 1254
  }
}

const imageCarousel4: GatsbyImageSchema = {
  description: 'Carousel Image - Settings',
  title: 'Carousel Image - Settings',
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/br4ichkdqihc/5xKzzKRVllB371SyoMA4Nu/a222186100e09f4adcbd051a4832baeb/image__12_.png?w=250&h=314&q=50&fm=webp 250w,\nhttps://images.ctfassets.net/br4ichkdqihc/5xKzzKRVllB371SyoMA4Nu/a222186100e09f4adcbd051a4832baeb/image__12_.png?w=500&h=627&q=50&fm=webp 500w,\nhttps://images.ctfassets.net/br4ichkdqihc/5xKzzKRVllB371SyoMA4Nu/a222186100e09f4adcbd051a4832baeb/image__12_.png?w=1000&h=1254&q=50&fm=webp 1000w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/br4ichkdqihc/5xKzzKRVllB371SyoMA4Nu/a222186100e09f4adcbd051a4832baeb/image__12_.png?w=1000&h=1254&q=50&fm=png'
      }
    },
    layout: 'constrained',
    width: 1000,
    height: 1254
  }
}

const tabs: ReadonlyArray<AppWidgetTab> = [
  {
    tab: 'Live Feed',
    title: '24/7 indoor protection',
    description: 'View live footage in crisp HD video, day or night.',
    list: [
      'Footage is clear even in the dark with night vision.',
      "With a Fast Protect™ monitoring subscription and live guard protection, agents can see and speak to intruders in real time during an alarm. Agents can only access your camera's microphone and video feed during an active alarm.",
      'Check in on pets at any time of day.'
    ],
    image: imageCarousel1
  },
  {
    tab: 'Alerts',
    title: 'Receive alerts and monitor camera activity on your event timeline',
    description:
      'You can review your event timeline whenever you want, and customize which events trigger a notification.',
    list: [
      'Know exactly when alarms are triggered.',
      'Watch video recordings when motion is detected.*',
      'Get notified when a monitoring agent reviews video footage.**',
      'Get notified when emergency responders are dispatched.**'
    ],
    image: imageCarousel2,
    disclaimer: [
      '*Requires Fast Protect™ Monitoring plan.',
      '**Simplisafe® will also attempt to call you, depending on the situtation.'
    ]
  },
  {
    tab: 'Motion',
    title: 'Fine-tune motion settings to reduce false alarms',
    list: [
      'Configure custom Activity Zones to ignore areas with expected motion, such as ceiling fans, TVs or hanging plants.',
      'You won’t receive alerts for areas you set to ignore.',
      'Adjust motion sensitivity, motion type and alarm trigger (All Motion or People Only).'
    ],
    image: imageCarousel3
  },
  {
    tab: 'Settings',
    title: 'Configure your camera settings for ultimate control',
    list: [
      'Set camera behavior for Off, Home and Away modes, including when the privacy shutter is open/closed.',
      'Select video quality and night vision options.',
      'Toggle audio and siren on/off.',
      'Customize notifications.',
      'Check battery life.'
    ],
    image: imageCarousel4
  }
]

const data: AppWidgetData = { tabs }

export { data }
