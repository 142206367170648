import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { getLocalizedDate } from '@ecomm/promotions-utils'
import type { Locale as dateFnsLocale } from 'date-fns'
import {
  differenceInCalendarDays,
  differenceInHours,
  formatWithOptions
} from 'date-fns/fp'
import { enGB, enUS } from 'date-fns/locale'
import { match } from 'ts-pattern'

const options = (locale: string): { readonly locale: dateFnsLocale } =>
  locale === 'en-US' ? { locale: enUS } : { locale: enGB }

/**
 * Takes a time and converts it into relative string like "Wednesday", "Tomorrow", "Midnight", "10PM", or "soon".
 * End Date in CTFL will be set to 3AM EST to accommodate promo being live in the West Coast
 * endDate will be adjusted dynamically based on timezone to show the correct relative time under
 * the 3-hour shifted scenario
 *
 *
 * Defines the date to display today's relative time from, usually the active
 * promotion's end date.
 *
 * @param endDate
 *
 * Get days of the week abbreviations (Mon, Tue, Wed, ..., Sun)
 *
 * @param isAbbreviated
 */
export function useGetPromoRelativeEndText(
  endDate: Date,
  isAbbreviated = false
) {
  const locale = useLocale()

  const currentDate = new Date()
  const hoursAway = differenceInHours(currentDate, endDate)
  const microCopy = useMicroCopy()

  const DAY_HRS = 24

  const westCoastTime = getLocalizedDate(
    'en-US',
    'America/Los_Angeles'
  ).getTime() // this value changes when we enter Daylight Savings Time
  const utcTime = getLocalizedDate('en-US', 'UTC').getTime()

  const WEST_COAST_UTC_OFFSET = (utcTime - westCoastTime) / (3600 * 1000)

  /**
   * Offset needs to be calculated dynamically based on US timezone.
   * EST should be offset by 3hrs to display the 3am EST endtime as midnight
   * CST should be offset by 2hrs to display the 3am EST (2am CST) endtime as midnight
   * ...
   * PST should be offset by 0hrs to display the 3am EST (12am PST) endtime as midnight
   * getTimeZoneOffset gives the offset from UTC, which for the west coast is 7 hours during daylight savings time and 8 hours otherwise
   */
  const PROMO_END_TIME_OFFSET_HRS = {
    'en-GB': new Date().getTimezoneOffset() / 60, // UK is always offset from UTC by 1 hour
    'en-US': WEST_COAST_UTC_OFFSET - new Date().getTimezoneOffset() / 60
  }

  const adjustedPromoTime: Record<string, Date> = {
    'en-GB': new Date(new Date(endDate).getTime()),
    'en-US': new Date(
      new Date(endDate).getTime() -
        3600 * PROMO_END_TIME_OFFSET_HRS['en-US'] * 1000
    ) // if promo ends at 3AM, adjust it to midnight of that day to accommodate west coast US timezones
  }

  const previousDayTime = adjustedPromoTime[locale].getTime() - 60 * 1000 // to display the correct day the promo 'ends', shift it by one minute to keep it within the same 'day'

  const daysAway = differenceInCalendarDays(currentDate, previousDayTime)

  return match(hoursAway)
    .when(
      n => n <= DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale] && daysAway === 0,
      () => {
        //  promo ends today
        const isMidnight =
          locale === 'en-US'
            ? new Date(endDate).getHours() -
                PROMO_END_TIME_OFFSET_HRS['en-US'] ===
              0 // US promos will be offset by 0-3 hours
            : new Date(endDate).getHours() === 0 // UK promos will always end at midnight

        return isMidnight
          ? formatWithOptions(
              options(locale),
              'bbbb',
              adjustedPromoTime[locale]
            ) // "MIDNIGHT"
          : formatWithOptions(options(locale), 'haa', endDate) // time, e.g. 10PM
      }
    )
    .when(
      n => n <= DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale] && daysAway === 1,
      () =>
        isAbbreviated ? microCopy['tomorrow-abbreviation'] : microCopy.tomorrow
    )
    .when(
      n =>
        n < 2 * DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale] &&
        n > DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale] &&
        daysAway === 2,
      () =>
        formatWithOptions(
          options(locale),
          isAbbreviated ? 'EEE' : 'EEEE',
          previousDayTime
        )
    ) // if the promotion ends 2 full calendar days away (i.e. not at midnight of the next day, at, say 7pm) don't say 'ENDS TOMORROW'
    .when(
      n =>
        n < 2 * DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale] &&
        n > DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale],
      () =>
        isAbbreviated ? microCopy['tomorrow-abbreviation'] : microCopy.tomorrow
    ) // if the promotion ends more than 24 hours away but only 1 calendar day away, say 'ENDS TOMORROW'
    .when(
      n =>
        n < 7 * DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale] &&
        n >= 2 * DAY_HRS + PROMO_END_TIME_OFFSET_HRS[locale],
      () =>
        // Less than 1 week it returns the day it ends, e.g. Thursday, Wednesday
        formatWithOptions(
          options(locale),
          isAbbreviated ? 'EEE' : 'EEEE',
          previousDayTime
        )
    )
    .otherwise(() => microCopy.soon) // for dates in the past or more than 7 days away it returns "soon"
}
