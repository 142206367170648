import { useNavigate as useRemixNavigate } from '@remix-run/react'
import { navigate as gatsbyNavigate } from 'gatsby'
/**
 * Returns a navigate function that works with Remix or Gatsby.
 */
export const useNavigate = () => {
  // Remix's useNavigate hook throws an error if it's not used inside a Router,
  // so we have to wrap it in a try/catch block
  try {
    const navigate = useRemixNavigate()
    return (to: string) => navigate(to)
  } catch (err) {
    return (to: string) => gatsbyNavigate(to)
  }
}
