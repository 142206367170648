import { localStorage } from '@simplisafe/ewok'
import {
  IOAddToCart,
  IOCreateOrUpgradeCart,
  IOVerifyEligibility,
  IOVerifySS2Eligibility
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { handleRedirectCallback } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { ThunkDispatch } from 'redux-thunk'
const { get, set } = localStorage

const localeMapping = {
  'en-GB': 'GB',
  'en-US': 'US'
}

export const DEVICE_ID = 'deviceId'
export const USER_ID = 'userId'

export const interactiveUpgradeFlow = (
  href: string,
  locale: Locale,
  dispatch: ThunkDispatch<ImmutableState, void, ACTION>,
  setIsAuthenticated: () => void,
  setFailedAuthentication: () => void
) => {
  const customerGroup = `interactiveCustomers-${localeMapping[locale]}`

  const verifyEligibility = (id: string) => {
    IOVerifyEligibility(id, customerGroup, setFailedAuthentication, () => {
      dispatch(
        IOAddToCart(
          { products: [] },
          () => null,
          () => {
            dispatch(IOCreateOrUpgradeCart(id, customerGroup))
          }
        )
      )
      setIsAuthenticated()
    })
  }

  handleRedirectCallback(
    get(DEVICE_ID) || '',
    href
  )(() => {
    const userId = get(USER_ID)
    userId ? verifyEligibility(userId) : setFailedAuthentication()
  })(userAuthData => {
    const { id } = userAuthData
    set(USER_ID, id)
    verifyEligibility(id)
  })
}

export const ss2UpgradeFlow = (
  href: string,
  locale: Locale,
  dispatch: ThunkDispatch<ImmutableState, void, ACTION>,
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>,
  setFailedAuthentication: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const customerGroup = `ss2Customer-${localeMapping[locale]}`
  const failAuthentication = () => setFailedAuthentication(true)

  handleRedirectCallback(get(DEVICE_ID) || '', href)(failAuthentication)(
    userAuthData => {
      const { id } = userAuthData
      IOVerifySS2Eligibility(id, failAuthentication, () => {
        dispatch(IOCreateOrUpgradeCart(id, customerGroup))
        setIsAuthenticated(true)
      })
    }
  )
}
