import React, { FC } from 'react'
import { Renderable } from 'react-hot-toast'

import { CheckMarkGreenCircle } from '../icons'

export type ToastAlertProps = {
  readonly hasCloseButton: boolean
  readonly onCloseHandler: () => void
  readonly message: Renderable | string
}

const wrapper =
  'bg-neutral-white border-2 border-[#4a9a88] border-solid flex items-center flex-row  rounded h-16'
const checkMark = 'my-0 ml-4 mr-0 pointer-events-none'
const messageWithClose = 'flex w-full m-0 justify-center'
const messageWithoutClose = 'flex w-5/6 m-0 justify-center'
const closeText =
  'text-sm text-center h-full w-1/5 border-l-2 border-zinc-400 border-solid border-y-0 border-r-0'
const closeButton =
  'relative bg-neutral-white text-primary-100 px-4 mx-0 w-full h-full border-0 hover:bg-zinc-200 active:bg-zinc-300'

/** @deprecated Do not use ss-react-components*/
const ToastCheckMarkAlert: FC<ToastAlertProps> = ({
  hasCloseButton,
  onCloseHandler,
  message
}: ToastAlertProps) => {
  const toastWithCloseButton = (
    onCloseHandler: () => void,
    message: Renderable | string
  ) => {
    return (
      <div className={wrapper}>
        <CheckMarkGreenCircle className={checkMark} />
        <span className={messageWithClose}>{message}</span>
        <span className={closeText}>
          <button className={closeButton} onClick={() => onCloseHandler()}>
            close
          </button>
        </span>
      </div>
    )
  }

  const toastWithoutCloseButton = (message: Renderable | string) => (
    <div className={wrapper}>
      <CheckMarkGreenCircle className={checkMark} />
      <span className={messageWithoutClose}>{message}</span>
    </div>
  )

  return hasCloseButton
    ? toastWithCloseButton(onCloseHandler, message)
    : toastWithoutCloseButton(message)
}

export default ToastCheckMarkAlert
