// extracted by mini-css-extract-plugin
export var alignBottom = "IconWithText-module--alignBottom--01c19";
export var alignMiddle = "IconWithText-module--alignMiddle--af2f0";
export var alignTop = "IconWithText-module--alignTop--0b74c";
export var bottomCenter = "IconWithText-module--bottomCenter--27f5a";
export var bottomLeft = "IconWithText-module--bottomLeft--7c507";
export var content = "IconWithText-module--content--2be9c";
export var extraLarge = "IconWithText-module--extraLarge--cd2e3";
export var extraSmall = "IconWithText-module--extraSmall--5509a";
export var icon = "IconWithText-module--icon--e9b28";
export var iconAdjacentToTextLeft = "IconWithText-module--iconAdjacentToTextLeft--e1b94";
export var iconAdjacentToTextRight = "IconWithText-module--iconAdjacentToTextRight--7c7e4";
export var large = "IconWithText-module--large--d763e";
export var left = "IconWithText-module--left--d915e";
export var medium = "IconWithText-module--medium--e904b";
export var responsive = "IconWithText-module--responsive--277ff";
export var right = "IconWithText-module--right--a176b";
export var small = "IconWithText-module--small--c9d79";
export var topCenter = "IconWithText-module--topCenter--4e6e1";
export var topLeft = "IconWithText-module--topLeft--eba7a";
export var verticalIcon = "IconWithText-module--verticalIcon--99d2f";
export var wrapper = "IconWithText-module--wrapper--942a1";