import { useLocale } from '@ecomm/data-hooks'
import {
  GatsbyImage,
  GatsbyImageSchema,
  GuidedSystemBuilder,
  GuidedSystemBuilderSchema
} from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import classNames from 'classnames'
import React from 'react'

export type QuoteWizardSectionProps = {
  readonly quoteWizard: GuidedSystemBuilderSchema
  readonly image?: GatsbyImageSchema | null
  readonly hideExtraTextOnMobile?: boolean
}

export function QuoteWizardSection({
  quoteWizard,
  image = null,
  hideExtraTextOnMobile = true
}: QuoteWizardSectionProps) {
  const locale = useLocale()
  const imageTag = image && (
    <GatsbyImage
      className="mr-12 w-full rounded-2xl max-md:hidden"
      image={image}
    />
  )

  return (
    <div
      className="grid grid-cols-12 gap-4 md:gap-8"
      data-component="QuoteWizardSection"
    >
      <div
        className={classNames(
          hideExtraTextOnMobile
            ? 'col-span-6 mb-6 max-md:hidden'
            : 'col-span-12 md:col-span-6'
        )}
      >
        <h2 className="lg:text-heading-2-size lg:leading-h2-height mb-2 text-[28px] leading-8 md:mb-2 lg:mb-4">
          The right security system for your home
        </h2>
        <p className="text-base lg:text-lg">
          When it comes to home security, one size doesn’t fit all. Answer a few
          quick questions and we’ll recommend a system that meets your needs and
          budget.
        </p>
        {imageTag}
      </div>
      <div className="col-span-12 md:col-span-6">
        <div className="bg-neutral-light-50 col-span-12 overflow-hidden rounded-2xl px-4 py-8">
          {locale === 'en-US' ? (
            <Experience
              {...quoteWizard}
              component={GuidedSystemBuilder}
              experiences={mapExperiences(quoteWizard.nt_experiences)}
              key={quoteWizard.id}
            />
          ) : (
            <GuidedSystemBuilder {...quoteWizard} />
          )}
        </div>
      </div>
    </div>
  )
}
