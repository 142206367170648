import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

const userStoryAssetSchema = z.object({
  description: gatsbyImageSchema.shape.description,
  gatsbyImageData: gatsbyImageSchema.shape.gatsbyImageData.optional()
})

export const userStorySchema = z.object({
  color: z.string(),
  backgroundImage: userStoryAssetSchema.optional(),
  deviceImage: userStoryAssetSchema.optional(),
  title: z.string(),
  description: contentfulRichTextSchema.nullable().optional(),
  listTitle: z.string(),
  list: z.array(z.string()).default([]),
  author: z.string(),
  note: z.string().nullish()
})

export type UserStorySchema = TypeOf<typeof userStorySchema>

export const userStoriesSchema = z.array(userStorySchema)

export type UserStoriesSchema = TypeOf<typeof userStoriesSchema>
