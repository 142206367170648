import { OrderSummaryListItem } from '../OrderSummaryListItem'
import type { CartLineItemProps } from '../types'

type Props = {
  readonly itemList: readonly CartLineItemProps[]
}

export function OrderSummaryList({ itemList }: Props) {
  return (
    <div>
      {itemList &&
        itemList.map((item: CartLineItemProps, idx: number) => (
          <OrderSummaryListItem item={item} key={idx} />
        ))}
    </div>
  )
}
