//@ts-nocheck
import classNames from 'classnames'
import React, {
  FC,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'

import useOnClickOutside from '../hooks/useOnClickOutside'
import * as css from './IconDropDown.module.scss'

/**
 * For defining an Icon object for a component.
 */

export type ItemIconDropDown = {
  readonly title: string
  readonly srcIcon?: ReactElement | ReactNode
  readonly href?: string
}
export type IconDropDownProps = {
  readonly label?: string
  readonly a11yLabel?: string
  readonly srcIcon: ReactElement | ReactNode
  readonly listItem: ReadonlyArray<ItemIconDropDown>
  readonly positionDropdown?: 'down' | 'up'
  readonly eventShowDropdown?: 'click' | 'hover'
  readonly onClick?: (iconItem: ItemIconDropDown) => void
  readonly dataComponent?: string
}

const renderListItems = (
  listItem: ReadonlyArray<ItemIconDropDown>,
  onClick: ((iconItem: ItemIconDropDown) => void) | undefined
) => {
  return listItem.map((item: ItemIconDropDown) => {
    const srcIcon = item.srcIcon
    const iconItem = {
      name: item.title,
      title: item.title,
      url: item.href
    }
    return (
      <li
        className={css.navDropdownMenuItem}
        key={`${item.title}-${item.href}`}
      >
        <a href={item.href} onClick={() => onClick && onClick(iconItem)}>
          {srcIcon} &nbsp; {item.title}
        </a>
      </li>
    )
  })
}

// TODO refactor to use children to show when hovering
// TODO add animation
/** @deprecated Do not use ss-react-components*/
const IconDropDown: FC<IconDropDownProps> = ({
  label,
  a11yLabel,
  listItem,
  positionDropdown = 'down',
  eventShowDropdown = 'hover',
  srcIcon,
  onClick,
  dataComponent = IconDropDown.name
}: IconDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside<HTMLDivElement, TouchEvent>(
    ref,
    () => setIsOpen(false),
    'touchend'
  )

  useEffect(() => {
    const onDocumentKeydown = (event: KeyboardEvent) => {
      event.key === 'Escape' && setIsOpen(false)
    }

    document.addEventListener('keydown', onDocumentKeydown, false)
    return () => {
      document.removeEventListener('keydown', onDocumentKeydown, false)
    }
  }, [setIsOpen])

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const dropdownClasses = classNames(css.navDropdownMenu, {
    [css.navDropdownMenuUp]: positionDropdown !== 'down',
    [css.hover]: eventShowDropdown === 'hover',
    [css.open]: isOpen
  })
  return (
    <div className={dropdownClasses} ref={ref}>
      <button
        className={css.dropdownButton}
        data-component={dataComponent}
        onClick={handleClick}
      >
        {a11yLabel ? (
          <span className="screenReaderText">{a11yLabel}</span>
        ) : null}
        {label ? (
          <div className={classNames(css.iconRow)}>
            {srcIcon}
            <span className={classNames(css['iconLabel'], 'fontSize16')}>
              {label}
            </span>
          </div>
        ) : null}
        {!label && srcIcon}
      </button>
      <ul className={css.navDropdownMenuList}>
        {renderListItems(listItem, onClick)}
      </ul>
    </div>
  )
}

export default IconDropDown
