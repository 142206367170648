import { requestLiveChatAvailability } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { useEffect, useState } from 'react'

import { salesChatAppId } from '../chatAppId'

/**
 * @returns whether live chat is enabled and agents are available
 *
 * Hard-coded to sales chat experience.
 */
export const useLiveChatAvailability = () => {
  const [hasAvailableAgents, setHasAvailableAgents] = useState(false)
  useEffect(() => {
    const handleFailure = () => setHasAvailableAgents(false)
    const handleSuccess = (response: boolean) =>
      response === true ? setHasAvailableAgents(true) : handleFailure()

    requestLiveChatAvailability(salesChatAppId)(handleFailure)(handleSuccess)
  }, [])

  return hasAvailableAgents
}
