import { useMicroCopy } from '@ecomm/micro-copy'
import { ContentfulRichText } from '@ecomm/shared-components'
import { useEnv } from '@ecomm/utils'
import classNames from 'classnames'
import React from 'react'

import type { SystemHeroFragment } from './schema'

function SystemHero({ children, heroText }: SystemHeroFragment) {
  const microCopy = useMicroCopy()
  const { locale } = useEnv()

  return (
    <>
      {heroText.raw ? <ContentfulRichText raw={heroText.raw} /> : null}
      <div
        className="rounded-base bg-neutral-light-50 px-4 py-8 md:mx-0 md:px-10 lg:mt-8"
        data-component="SystemHero"
      >
        <h2 className="mb-3 text-center text-2xl md:text-3xl">
          {microCopy['required-with-system']}
        </h2>
        <div
          className={classNames('grid gap-2 md:grid-cols-2', {
            'md:grid-cols-3': locale === 'en-GB'
          })}
        >
          {children}
        </div>
      </div>
    </>
  )
}

export default SystemHero
