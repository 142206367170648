import { Set } from 'immutable'

import { DraftCartFragment } from '../components/DraftCart/types'
import { SensorGroupFragment } from '../components/SensorGroup/schema'
import { SystemHeroItemFragment } from '../components/SystemHeroItem/schema'

type inboundSkuContainers = {
  readonly heroItems: readonly SystemHeroItemFragment[]
  readonly sensorGroups: readonly SensorGroupFragment[]
  readonly draftCart: DraftCartFragment
}

export const aggregateShopSkus = ({
  heroItems,
  sensorGroups,
  draftCart
}: inboundSkuContainers) => {
  const heroProductSkus = heroItems.map(heroItem => heroItem.sku)

  const sensorGroupProducts = sensorGroups.flatMap(group => group.items)

  const colorVariantProducts = sensorGroupProducts.flatMap(
    group =>
      group.variant?.map(({ sku }) => ({
        ...group,
        sku: sku
      })) ?? []
  )

  const combinedSensorGroupProducts = [
    ...sensorGroupProducts,
    ...colorVariantProducts
  ].filter(
    (product, idx, arr) => arr.findIndex(p => p.sku === product.sku) === idx
  )

  const sensorGroupProductSkus = combinedSensorGroupProducts.map(i => i.sku)
  const accessoriesModalProductSkus = combinedSensorGroupProducts
    .flatMap(product => product?.accessoriesModal?.products ?? [])
    .map(i => i.sku)

  const basePackageSku = draftCart.defaultBundle.baseProduct
  const baseProductSkus = draftCart.defaultBundle.products.map(i => i.sku)

  const experiencesSkus = sensorGroupProducts.flatMap(sensorGroup =>
    sensorGroup.nt_experiences.flatMap(experience =>
      experience.variants.map(variant => variant.sku)
    )
  )

  return Set.of(
    ...heroProductSkus,
    ...sensorGroupProductSkus,
    ...accessoriesModalProductSkus,
    basePackageSku,
    ...baseProductSkus,
    ...experiencesSkus
  ).toArray()
}
