import { z } from '@simplisafe/ewok'

export const response = z.object({
  status: z.number(),
  ok: z.boolean(),
  body: z.string(),
  json: z.string(),
  statusText: z.string(),
  text: z.string()
})
