import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { useMonthsOfFreeServiceText } from '@ecomm/promotions-hooks'
import { ContentfulRichText, Modal } from '@ecomm/shared-components'
import { useTrackingSeeDetailsPlanSelection } from '@ecomm/tracking'
import classNames from 'classnames'
import { useState } from 'react'

import { useLocalizedLegalDisclaimerModalData } from './useLocalizedLegalDisclaimerModalData'
import { trackPackagePageSeeDetails } from '@ecomm/cdp-tracking'

type Props = {
  readonly className?: string
  readonly modalContent?: string
  readonly modalTitle?: string
  readonly monthsQuantity?: string
  readonly priceByLocale?: string
}

export function LegalDisclaimer({
  className,
  modalContent,
  modalTitle,
  monthsQuantity,
  priceByLocale
}: Props) {
  const microCopy = useMicroCopy()
  const siteLocale = useLocale()
  const isUS = siteLocale === 'en-US'
  const defaultModalContent = useLocalizedLegalDisclaimerModalData(isUS)
  const defaultModalTitle = isUS
    ? 'Fast Protect™ Monitoring'
    : 'Pro Premium Plan'
  const defaultPriceByLocale = microCopy['fast-protect-daily-price']
  const trackSeeDetails = useTrackingSeeDetailsPlanSelection()

  const title = modalTitle || defaultModalTitle
  const content = modalContent || defaultModalContent
  const price = priceByLocale || defaultPriceByLocale

  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false)

  const monthsOfServiceDisplay = useMonthsOfFreeServiceText(false)

  const months = monthsQuantity || monthsOfServiceDisplay

  return (
    <>
      <p className={classNames('mb-8 px-2 text-xs', className)}>
        *First {months} free, no contract, cancel any time. After trial,
        monitoring is charged <br />
        separately for {price}
        /day.{' '}
        <button
          className="my-4 bg-transparent p-0 cursor-pointer text-xs underline hover:no-underline"
          data-component="SeeDetailsLink"
          onClick={() => {
            trackSeeDetails()
            setIsOpenDetailsModal(true)
            trackPackagePageSeeDetails()
          }}
        >
          See Details
        </button>
      </p>
      <Modal
        isOpen={isOpenDetailsModal}
        onRequestClose={() => setIsOpenDetailsModal(false)}
      >
        <div className="p-8 md:max-w-[864px]" data-component="modal_content">
          <h2
            className="border-neutral-light-100 -mx-8 mt-0 flex flex-col border-x-0 border-b border-t-0 border-solid px-8 pb-6 text-2xl"
            data-component="content_headline"
          >
            {title}
          </h2>
          <div
            className="prose-h4:text-lg"
            data-component="content_description"
          >
            <ContentfulRichText raw={content} />
          </div>
        </div>
      </Modal>
    </>
  )
}
