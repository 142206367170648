import React from 'react'
import { useCallback } from 'react'

import CartWidgetItem from '../CartWidgetItem'

export function MonitoringPlanCard(
  siteLocale: string,
  showCartMonitoringPlan: boolean,
  onAddToCart: () => void
) {
  return useCallback(() => {
    return (
      siteLocale === 'en-GB' &&
      showCartMonitoringPlan && (
        <CartWidgetItem
          alignContentCenter
          buttonText="Add free month"
          dataComponentName="MonitoringPlanCard"
          description={
            <>
              <p className="my-[5px] text-sm font-bold text-[#bf4436]">
                Get 30 Days Free
              </p>
              <p className="text-left text-sm">
                Get 24/7 monitoring, police & fire dispatch, and more money off
                your system today.
              </p>
            </>
          }
          heading={<h3 className="mb-[5px]">Complete Your System</h3>}
          onClick={() => onAddToCart()}
          subHeading={
            <h4 className="mb-[7px] mt-2.5">
              {siteLocale === 'en-GB'
                ? 'Pro Premium Monitoring'
                : 'Fast Protect™ Monitoring'}
            </h4>
          }
        />
      )
    )
  }, [onAddToCart, showCartMonitoringPlan, siteLocale])
}
