import React, { FC, ReactNode } from 'react'

type PageBodyProps = {
  readonly children?: ReactNode
  readonly dataComponent?: string
}

// The 'main' element has an ID so that it can be jumped to
// from the "Skip to content" link found in /PageWrapper.
/** @deprecated Do not use ss-react-components*/
const PageBody: FC<PageBodyProps> = ({
  children,
  dataComponent = PageBody.name
}: PageBodyProps) => (
  <main
    className="p-4 md:p-8 lg:px-16 lg:pb-16"
    data-component={dataComponent}
    id="content"
  >
    {children}
  </main>
)

export default PageBody
