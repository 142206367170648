import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

const carouselItemSchema = z.object({
  description: contentfulRichTextSchema,
  headline: z.string().nullable(),
  image: gatsbyImageSchema,
  mobileImage: gatsbyImageSchema
})

export const carouselSectionSchema = z.object({
  __typename: z.literal('ContentfulCarousel'),
  id: z.string().optional(),
  items: z.array(carouselItemSchema).optional(),
  title: z.string().optional()
})

export type CarouselSectionFragment = TypeOf<typeof carouselSectionSchema>
