import classNames from 'classnames'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import propOr from 'ramda/src/propOr'
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import { Heading } from '..'
import { mailformat } from '../common'
import SSButton, { ButtonColor } from '../SSButton'
import SSInput from '../SSInput'

export type OffersProps = {
  readonly success?: { readonly title?: string; readonly message?: string }
  readonly inputLabel?: string
  readonly inputId?: string
  readonly errorMsg?: string
  /** Func for handle Email Submission */
  readonly onEmailSubmit: (email: string) => void
  /** Props for button value */
  readonly buttonText?: string
  readonly buttonColor?: ButtonColor
  readonly buttonBGColor?: string
  readonly placeHolder?: string
  /** An optional class to apply */
  readonly className?: string
  /** Data-component attribute value */
  readonly dataComponent?: string
  readonly title?: string
  readonly formSubmitErrorMessage?: ReactNode
  readonly formBtnTextColor?: string
  /** The content of the component */
  readonly ctaContent?: ReactNode
  readonly textColor?: string
  readonly defaultEmail?: string
}

/**
 * Get Offer Section on Footer
 */
// TODO If this is used in the footer the component name should include Footer.
/** @deprecated Do not use ss-react-components*/
const Offers: FC<OffersProps> = ({
  success,
  errorMsg,
  // TODO This shouldn't have a default value. Supply via Contentful.
  inputLabel = 'Enter email',
  inputId = 'offersEmail',
  // TODO This shouldn't have a default value. Supply via Contentful.
  placeHolder = 'Enter email',
  // TODO This shouldn't have a default value. Supply via Contentful.
  title: titleProp = '',
  onEmailSubmit,
  // TODO This shouldn't have a default value. Supply via Contentful.
  buttonText = 'Send',
  buttonColor = 'tertiaryOutline',
  buttonBGColor,
  formBtnTextColor,
  className = '',
  dataComponent = Offers.name,
  formSubmitErrorMessage,
  textColor,
  defaultEmail = '',
  ctaContent
}: OffersProps) => {
  const [email, setEmail] = useState(defaultEmail)
  const [error, setError] = useState(false)
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault()
      ifElse(
        equals(true),
        () => {
          setError(false)
          onEmailSubmit(email)
        },
        always(setError(true))
      )(mailformat.test(email))
    },
    [onEmailSubmit, email]
  )

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget
      setEmail(value)
    },
    []
  )

  useEffect(() => {
    setEmail(defaultEmail)
  }, [defaultEmail])

  const isSuccess = !isNil(success)
  // TODO This shouldn't have a default value. Supply via Contentful.
  const headline: string = isSuccess
    ? propOr('Thanks!', 'title', success)
    : titleProp
  const inLineStyle = { color: textColor }
  return (
    <div data-component={dataComponent}>
      {headline ? (
        <Heading
          className="prose-headings:text-white"
          element="h2"
          headingAspect="paragraph"
          margin="tiny"
          useTailwind
        >
          {headline}
        </Heading>
      ) : null}
      {!isSuccess && (
        <form>
          <div
            className={classNames(
              'grid grid-cols-12 content-start gap-4',
              className
            )}
          >
            <div className="col-span-8 md:col-span-9">
              <SSInput
                className="h-14 max-w-full"
                disabled={false}
                error={error}
                hideLabel={true}
                id={inputId}
                label={inputLabel}
                onChange={handleEmailChange}
                placeholder={placeHolder}
                required={false}
                type="email"
                value={email}
              />
              {error ? (
                <p className="text-neutral-light-100 m-0">{errorMsg}</p>
              ) : null}
            </div>
            <SSButton
              buttonColor={buttonBGColor}
              className="col-span-4 h-14 w-24 md:col-span-3 md:w-28"
              color={buttonColor}
              onClick={onClick}
              textColor={formBtnTextColor}
              type="submit"
            >
              {buttonText}
            </SSButton>
          </div>
          {formSubmitErrorMessage ? (
            <div className="text-neutral-medium-100 mt-1">
              {formSubmitErrorMessage}
            </div>
          ) : null}
        </form>
      )}
      {!isSuccess && ctaContent ? (
        <div
          className="prose md:prose-md prose-p:text-xs prose-a:text-white prose-p:text-white prose-a:font-normal prose-a:hover:no-underline prose-a:underline mt-1"
          style={inLineStyle}
        >
          {ctaContent}
        </div>
      ) : null}
      {isSuccess ? (
        <p className="text-white">
          {propOr(
            'Keep an eye on your email for tips, offers and more.',
            'message',
            success
          )}
        </p>
      ) : null}
    </div>
  )
}

export default Offers
