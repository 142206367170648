import { z } from '@simplisafe/ewok'
import type { TypeOf } from 'zod'

export const guaranteeCopySchema = z.object({
  guaranteeCopy: z.object({
    content: z.record(z.string(), z.string())
  })
})

export type GuaranteeCopy = TypeOf<typeof guaranteeCopySchema>
