export function toPriceCalculationData(
  shippingPrice: string,
  discountCode: string,
  discountValue: string,
  shippingLabel: string,
  subTotal: string,
  total: string,
  taxedPrice: string
) {
  return {
    checkoutShippingValue: shippingPrice,
    couponCode: discountCode,
    discountValue: discountValue,
    shippingLabel: shippingLabel,
    subtotal: subTotal,
    total: total,
    vAT: taxedPrice
  }
}
