import { addAtomDebugLabel } from '@ecomm/utils'
import { atom } from 'jotai'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo } from 'react'

import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'

/**
 * A hook-derived-atom to optimize render dependencys for items in the draft cart.
 *
 * Atom can be derived anywhere, but must take into account referential equality.
 *
 * UseMemo is particularly important to prevent looping with this approach.
 *
 * Takes an input sku `string` and returns its quantity within the Draft Cart, or 0 if it does not exist.
 */
export const useItemQuantity = (sku: string): number => {
  const derivedItemAtom = useMemo(
    () => atom(get => get(itemQuantityAtom).get(sku)),
    [sku]
  )
  useEffect(() => {
    addAtomDebugLabel(derivedItemAtom, `DraftCart:QuantityFor->${sku}`)
  }, [sku])
  const itemQty = useAtomValue(derivedItemAtom) || 0
  return itemQty
}
