import { useField } from 'formik'
import React, { FC } from 'react'

type FormTextAreaInputProps = {
  /** Field name, note that this prop is being used to wire the field to an specific formik field */
  readonly name: string
  /** Placeholder text rendered when no option is selected */
  readonly placeholder?: string
  /** To ensure non-visual accessibility this value should match the `htmlFor` value of the field label */
  readonly id?: string
}

/** @deprecated Do not use ss-react-components*/
const FormTextAreaInput: FC<FormTextAreaInputProps> = ({
  name,
  placeholder,
  id
}: FormTextAreaInputProps) => {
  const [field] = useField(name)

  return (
    <textarea
      className="rounded-base border-neutral-medium-100 placeholder:text-neutral-medium-100 text-body-size font-default placeholder:font-default placeholder:text-body-size h-44 w-full border border-solid p-4 placeholder:font-normal"
      cols={50}
      id={id}
      rows={1}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
      // @ts-expect-error
      type="textarea"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      value={field.value || null}
      {...field}
      placeholder={placeholder}
    />
  )
}

export default FormTextAreaInput
