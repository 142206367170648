/**
 * Jebbit Iframe onLoad method
 * This code is provided by the jebbit console
 */
export function embedJebbit(t) {
  function e(t) {
    const e =
      t === 'attach' ? window.addEventListener : window.removeEventListener
    e('DOMContentLoaded', i, !1),
      e('load', i, !1),
      e('scroll', i, !1),
      e('resize', i, !1)
  }
  const n = document.querySelector(t)

  function i() {
    ;(function (t) {
      const e = t.getBoundingClientRect && t.getBoundingClientRect(),
        n = t.clientHeight / 2,
        i = t.clientWidth / 2
      return (
        e.top >= 0 &&
        e.left >= 0 &&
        e.top <=
          (window.innerHeight || document.documentElement.clientHeight) - n &&
        e.left <=
          (window.innerWidth || document.documentElement.clientWidth) - i
      )
    })(n) &&
      n.contentWindow &&
      n.contentWindow.postMessage('startJebbitCampaign', '*')
  }
  window.addEventListener(
    'message',
    function (t) {
      t &&
        (t.data === 'jebbitSessionCreated'
          ? e('remove')
          : t.data === 'jebbitCampaignLoaded'
            ? i()
            : t.data &&
              t.data.action === 'redirect' &&
              t.data.options &&
              t.data.options.link &&
              (window.location.href = t.data.options.link))
    },
    !1
  ),
    e('attach')
}
