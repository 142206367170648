import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import React from 'react'

export type NhwDescriptonProps = {
  readonly data: ReadonlyMap<string, string>
  readonly asset: GatsbyImageSchema
}

export function NhwDescripton({ data, asset }: NhwDescriptonProps) {
  const image = asset && (
    <GatsbyImage
      className="h-[132px] w-[132px] lg:h-[240px] lg:w-[240px]"
      image={asset}
    />
  )

  return (
    <div className="grid grid-cols-12 lg:gap-8" data-component="NhwDescripton">
      <div className="col-span-12 lg:col-span-8">
        <h2 className="mb-6 text-center text-[28px] font-bold leading-8 lg:text-left lg:text-[55px]">
          {data.get('title')}
        </h2>
        <p>{data.get('body_1')}</p>
        <p>{data.get('body_2')}</p>
        <p className="mb-0">{data.get('body_3')}</p>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <div className="px-4 py-8 text-center">{image}</div>
      </div>
    </div>
  )
}
