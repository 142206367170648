import { useLocale } from '@ecomm/data-hooks'
import {
  monthlyToLocalizedDailyPriceInCents,
  usePriceContext
} from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import { ContentfulRichText, Modal } from '@ecomm/shared-components'
import { CheckMark, XSolidIcon } from '@ecomm/shared-icons'
import {
  useTrackOdmonPlanSelection,
  useTrackOdmonSelfMonitoringLinkClick,
  useTrackOdmonStandardMonitoringLinkClick
} from '@ecomm/tracking'
import { match } from 'fp-ts/lib/Option'
import { useState } from 'react'

import { AddPlanToCartButtonProduct } from '../AddPlanToCartButton'
import { getPlanCode } from '../utils/table'
import type { MonitoringPlanModalSchema } from './schema'

export default function MonitoringPlanModal({
  name,
  sku,
  features,
  description,
  shortDescription,
  withAddToCartButton = false,
  cta
}: MonitoringPlanModalSchema) {
  const [show, setShow] = useState(false)
  const { getPrice } = usePriceContext()
  const trackCTA = useTrackOdmonPlanSelection()
  const trackSandardMonitoring = useTrackOdmonStandardMonitoringLinkClick()
  const trackSelfMonitoring = useTrackOdmonSelfMonitoringLinkClick()
  const locale = useLocale()
  const microcopy = useMicroCopy()

  const handleClick = () => {
    setShow(true)
    name.includes('Standard') ? trackSandardMonitoring() : trackSelfMonitoring()
  }

  const priceText = match(
    () => null,
    (price: number) => monthlyToLocalizedDailyPriceInCents(locale, price)
  )(getPrice(sku))

  return (
    <>
      <button
        className="text-body-size text-neutral-black text-left cursor-pointer border-none bg-transparent p-0 underline hover:no-underline"
        onClick={handleClick}
      >
        {name}
      </button>
      <Modal isOpen={show} onRequestClose={() => setShow(false)}>
        <div className="prose md:prose-md lg:prose-lg max-w-3xl p-6 md:p-8 lg:p-12">
          <h2 className="mb-4 md:mb-6">{name}</h2>
          <ContentfulRichText {...description} />
          <ul className="m-0 grid list-none grid-cols-2 gap-2 p-0">
            {features.map((feature, i) => (
              <li
                className="prose-p:mb-0 col-span-1 flex items-center gap-1"
                key={`monitoring-plan-modal-listitem-${i}`}
              >
                {feature.icon === 'CheckMark' ? (
                  <CheckMark className="text-complementary-green-100 h-5 w-5 shrink-0" />
                ) : (
                  <XSolidIcon className="text-complementary-red-100 h-6 w-6 shrink-0" />
                )}
                <ContentfulRichText {...feature.helpText} />
              </li>
            ))}
          </ul>
          <div className="mt-8 flex items-center justify-between">
            <div className="flex flex-col">
              <span className="font-bold">{priceText}/day</span>
              <p className="mb-0">{microcopy['no-contract']}</p>
            </div>
            <div className="prose-p:text-xs">
              {withAddToCartButton && cta ? (
                <AddPlanToCartButtonProduct
                  buttonText={cta}
                  className="mb-4 mt-2 block !text-sm md:!text-lg"
                  onClick={() => trackCTA(getPlanCode(sku), 'modal')}
                  quantity={1}
                  sku={sku}
                />
              ) : null}

              <ContentfulRichText {...shortDescription} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
