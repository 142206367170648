import { useDevMode } from '@ecomm/data-storage'
import { devThrowError } from '@ecomm/error-handling'
import { useLocation } from '@reach/router'
import { useEffect, useState } from 'react'

function getIsSkipTargetMissing() {
  return document.getElementById('content') === null
}

function useSkipTargetCheck() {
  const [isSkipTargetMissing, setIsSkipTargetMissing] = useState(true)
  const { pathname } = useLocation()

  useEffect(() => {
    setIsSkipTargetMissing(getIsSkipTargetMissing())
    const interval = setInterval(() => {
      const isSkipTargetFound = !getIsSkipTargetMissing()
      isSkipTargetFound && setIsSkipTargetMissing(false)
      isSkipTargetFound && clearInterval(interval)
    }, 100)

    const timeout = setTimeout(() => {
      getIsSkipTargetMissing() &&
        devThrowError(
          new Error(
            `Page at "${pathname}" is missing an element with the id "content". This is required for the skip to content link to work which is an accessibility requirement.`
          )
        )
    }, 5000)

    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
    }
  }, [pathname])

  return { isSkipTargetMissing }
}

function SkipTargetAlert() {
  const { isSkipTargetMissing } = useSkipTargetCheck()

  return isSkipTargetMissing ? (
    <div className="text-alert-100">
      Target is missing. Page must contain an element with the id
      &quot;content&quot;.
    </div>
  ) : null
}

/**
 * Provides a link to skip to page main content while navigating via keyboard.
 * Main content must be wrapped in an element with id "content". Should be placed as first
 * focusable element on page.
 *
 * Displays an alert and logs a warning in development if target is missing.
 */
function SkipToContentLink() {
  const [isDevMode] = useDevMode()

  return (
    <a
      className="absolute left-[-9999px] z-50 bg-white p-2 focus:left-0 focus:top-0"
      data-component="SkipToContentLink"
      href="#content"
    >
      Skip to content
      {isDevMode ? <SkipTargetAlert /> : null}
    </a>
  )
}

export default SkipToContentLink
