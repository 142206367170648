import classNames from 'classnames'
import React from 'react'

export type Props = {
  readonly stepNumber: number
  readonly isLastStep: boolean
  readonly isOpen?: boolean
}

export function StepLine({ isLastStep, stepNumber, isOpen = false }: Props) {
  return (
    <div className="relative order-2 h-full w-8 text-center top-0 lg:w-auto lg:px-5">
      <p
        className={classNames(
          `absolute z-10 lg:static left-0 right-0 top-0.5 rounded-full text-2xl lg:px-2.5 lg:py-2`,
          {
            'text-white bg-neutral-black lg:mt-9': stepNumber === 1,
            'text-neutral-black bg-primary-100': stepNumber === 2,
            'text-white bg-complementary-red-100': stepNumber === 3,
            'lg:mt-20': stepNumber !== 1
          }
        )}
      >
        {stepNumber}
      </p>
      {!isLastStep && (
        <div
          className={classNames(
            `absolute left-1/2 right-0 lg:-z-[1] w-2.5 -translate-x-1/2 transform bg-gradient-to-b  lg:top-20`,
            {
              '-bottom-1 top-1': isOpen,
              '-bottom-1 top-1 lg:-bottom-20': !isOpen,
              'from-neutral-black to-primary-100': stepNumber === 1,
              'from-primary-100 to-complementary-red-100': stepNumber === 2
            }
          )}
          data-testid="StepLine-line"
        ></div>
      )}
    </div>
  )
}
