//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import * as css from './HeaderPartner.module.scss'

export type HeaderPartnerProps = {
  /** data-component attribute value. */
  readonly dataComponent?: string
  /** The logo markup for the partner. Should be a white image or SVG logo. */
  readonly logoPartner?: ReactNode
  /** The logo markup for the SimpliSafe logo. Should be a white image or SVG logo. */
  readonly logoSimpliSafe: ReactNode
  /** The text to display in tablet and desktop in between the two logos. Example: "+". */
  readonly mergerText?: ReactNode
  /** The text to define the background color -- already with a default one */
  readonly backgroundColor?: string
  /** The text to display before both logos and below them in mobile */
  readonly offerText?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const HeaderPartner: FC<HeaderPartnerProps> = ({
  dataComponent = HeaderPartner.name,
  logoPartner,
  logoSimpliSafe,
  backgroundColor,
  mergerText,
  offerText
}: HeaderPartnerProps) => {
  return (
    <header className={css.headerPartner} data-component={dataComponent}>
      <div
        className={classNames(css.headerPartnerInner)}
        style={{ backgroundColor }}
      >
        <div className={classNames(css.logosContainer)}>
          <div className={classNames(css.logoContainer, css.logoContainerLeft)}>
            {logoSimpliSafe}
          </div>
          <div className={css.mergerTextContainer}>
            <span className={css.mergerText}>{mergerText}</span>
          </div>
          <div
            className={classNames(css.logoContainer, css.logoContainerRight)}
          >
            {logoPartner}
          </div>
        </div>
        <div className={css.offerTextContainer}>
          <span className={css.offerText}>
            {offerText}
            <sup>*</sup>
          </span>
        </div>
      </div>
    </header>
  )
}

export default HeaderPartner
