import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@ecomm/shared-components'
import * as Icons from '@ecomm/shared-icons'

import { ItemSchema } from './schema'

const textRenderer: Options = {
  renderNode: {
    [BLOCKS.HEADING_3]: (_, text) => {
      return (
        <h3 className="my-2 text-base font-bold md:text-lg lg:block">{text}</h3>
      )
    },
    [BLOCKS.PARAGRAPH]: (_, text) => {
      return <p className="my-2 text-lg lg:block">{text}</p>
    }
  }
}

function ConfidenceItem({ icon, text, disclaimer }: ItemSchema) {
  const Icon = Icons[icon]

  return (
    <div
      className="flex w-full flex-row items-start gap-4 lg:flex-col lg:gap-0"
      data-component="ConfidenceItem"
    >
      <div className="flex flex-col">
        <Icon className="text-neutral-black my-2" height={40} width={40} />
      </div>
      <div className="text-neutral-black flex flex-col self-center">
        <ContentfulRichText optionsCustom={textRenderer} raw={text.raw} />
        {disclaimer ? <p className="my-0 text-xs">{disclaimer}</p> : null}
      </div>
    </div>
  )
}

export default ConfidenceItem
