import type { Address } from '@commercetools/platform-sdk'
import { ZuoraCreditCardFields } from '@ecomm/data-zuora'
import { Locale } from '@ecomm/utils'
import { abbreviationToName } from '@simplisafe/ss-ecomm-data/commercetools/usStates'
import { PaymentMethodResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import {
  splitZuoraCountryList,
  zuoraCountryCode
} from '@simplisafe/ss-ecomm-data/thirdparty/zuora'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export const formatCreditCardFields = (
  billingAddress: O.Option<Address>,
  locale: Locale,
  method: PaymentMethodResponse
): O.Option<ZuoraCreditCardFields> => {
  return pipe(
    billingAddress,
    O.map((address): ZuoraCreditCardFields => {
      const addressValue = (key: keyof Omit<Address, 'custom'>): string =>
        address[key] ?? ''
      const allowedCountries = splitZuoraCountryList(method.countryWhiteList)
      return {
        creditCardAddress1: addressValue('streetName'),
        creditCardAddress2: addressValue('additionalStreetInfo'),
        creditCardCity: addressValue('city'),
        creditCardCountry: zuoraCountryCode(locale).orJust(allowedCountries[0]),
        creditCardHolderName: `${addressValue('firstName')} ${addressValue(
          'lastName'
        )}`,
        creditCardPostalCode: addressValue('postalCode'),
        creditCardState: abbreviationToName(address.state).orUndefined(),
        email: addressValue('email'),
        phone: addressValue('phone')
      }
    })
  )
}
