import React from 'react'

export function PartnersComponentsTest() {
  return (
    <div>
      <h1>Partners Components</h1>
    </div>
  )
}

export default PartnersComponentsTest
