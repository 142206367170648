//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'

import * as css from './Header.module.scss'

export type HeaderNavigationProps = {
  /**
   * Links, dropdowns, buttons, and icons.
   *
   * Everything placed in here should be a clickable and navigate around the site.
   */
  readonly children: ReactElement | readonly ReactElement[]
  readonly navigationType?: string
}

/** @deprecated Do not use ss-react-components*/
const HeaderNavigation: FC<HeaderNavigationProps> = ({
  children,
  navigationType
}: HeaderNavigationProps) => (
  <nav
    className={classNames('bg-inherit text-inherit', css.navigation)}
    data-navigation-type={navigationType}
  >
    {children}
  </nav>
)

export default HeaderNavigation
