/**
 * Makes sure that the slug is prefixed with 'blog/'.
 * If a slug in Contentful doesn't match what this method returns, it won't appear on site.
 * This is how we currently store it in Contentful.
 * I'm not sure we want to keep it this way, but this works with the current data as of June 2024.
 *
 * NOTE: If this is ever changed, you should also update the validation rules for the slug field
 * for the Blog Post content type in Contentful!
 */
export const normalizeSlug = (slug = ''): string =>
  decodeURIComponent(`blog/${slug.replace('blog/', '').replace(/\//g, '')}`)
