import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { iconTypes } from '@ecomm/shared-icons'
import { z } from 'zod'

import { Locale } from '../../hooks/useLocalizedData'

export const headerSchema = z.object({
  title: z.string(),
  description: z.string()
})

export const ctaSchema = z.object({
  text: z.string(),
  link: z.string()
})

export const bubblesSchema = z.object({
  icon: z.enum(iconTypes),
  text: z.string()
})

export const alarmCategoriesSchema = z.object({
  id: z.number(),
  name: z.string(),
  image: gatsbyImageSchema,
  bubbles: z.array(bubblesSchema).nonempty(),
  disclaimerText: z.string().optional()
})

export const baseSchema = z.object({
  header: headerSchema,
  cta: ctaSchema.optional(),
  alarmCategories: z.array(alarmCategoriesSchema).nonempty()
})

export const schema = {
  [Locale.EnGB]: baseSchema,
  [Locale.EnUS]: baseSchema
}
