import { useLocale } from '@ecomm/data-hooks'
import { GiftItemDTO } from '@simplisafe/ss-ecomm-data/prices/service'
import React from 'react'

export type FreeGiftItemProps = {
  readonly giftItem: GiftItemDTO
}

function FreeGiftItem({ giftItem }: FreeGiftItemProps) {
  const isUs = useLocale() === 'en-US'
  const text = `${giftItem.quantity} FREE ${giftItem.title}`

  return (
    <div data-component={giftItem.sku} key={giftItem.sku}>
      {isUs ? <div className="inline pr-2 md:hidden">&#8226;</div> : null}
      <span className="text-sm text-red-600">{text}</span>
    </div>
  )
}

export default FreeGiftItem
