import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { guaranteeSchema } from './schema'
import { parseObjectKey } from '@simplisafe/ewok'

type Props = {
  readonly type: 'desktop' | 'mobile'
}

function Guarantee({ type }: Props) {
  const data = useStaticQuery(graphql`
      query GuaranteeQuery {
        contentfulGuarantee(contentful_id: { eq: "3GZdvpQYuM6hYgT0pzBiky" }) {
          image {
            title
            gatsbyImageData(width: 104, layout: FIXED)
          }
          description {
            raw
          }
        }
      }
    `)
  const guarantee = parseObjectKey(data, 'contentfulGuarantee', guaranteeSchema)

  return guarantee ? (
    type === 'mobile' ? (
      <div className="relative mt-4 md:hidden">
        {guarantee.image ? (
          <GatsbyImage
            className="!h-[5.25rem] !w-[5.25rem] rounded-full"
            image={guarantee.image}
          />
        ) : null}
        <div className="[&>h4]:absolute [&>h4]:left-[6.5rem] [&>h4]:top-[4px] [&>h4]:text-lg [&>h4]:font-medium [&>h4]:uppercase [&>p]:text-sm">
          {guarantee.description ? (
            <ContentfulRichText raw={guarantee.description.raw} />
          ) : null}
        </div>
      </div>
    ) : (
      <div className="rounded-base hover:bg-neutral-light-50 group absolute top-8 z-[2] hidden w-64 flex-col gap-4 p-5 text-center md:flex md:w-32 lg:w-64">
        <div className="mx-auto">
          {guarantee.image ? (
            <GatsbyImage
              className="h-full w-full rounded-full"
              image={guarantee.image}
            />
          ) : null}
        </div>
        <div className="prose hidden group-hover:flex group-hover:flex-col">
          {guarantee.description ? (
            <ContentfulRichText raw={guarantee.description.raw} />
          ) : null}
        </div>
      </div>
    )
  ) : null
}

export default Guarantee
