import {
  PartnerOfferText,
  PromoBannerBaseTemplate
} from '@ecomm/promotions-components'
import type { SimpliSafeCSSProperties } from '@ecomm/shared-components'
import propOr from 'ramda/src/propOr'
import React from 'react'

import { ContentfulBannerTopPage } from '../../../graphql'

export type HeaderBannerProps = {
  readonly data: ContentfulBannerTopPage
}

function TopPageBanner({ data }: HeaderBannerProps) {
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const backgroundColor: string = propOr<string, string>(
    'var(--promo-banner-bg)',
    'backgroundColor',
    data
  )
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const primaryTextColor: string = propOr<string, string>(
    'var(--white)',
    'primaryTextColor',
    data
  )
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const secondaryTextColor: string = propOr<string, string>(
    'var(--white)',
    'secondaryTextColor',
    data
  )

  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const primaryText: string = propOr<string, string>('', 'primaryText', data)
  // @ts-expect-error TS(2322) FIXME: Type '<V>(p: string) => V' is not assignable to ty... Remove this comment to see the full error message
  const secondaryText: string = propOr<string, string>(
    '',
    'secondaryText',
    data
  )

  const styles: SimpliSafeCSSProperties = {
    '--promo-banner-bg': backgroundColor,
    '--promo-primary-text': primaryTextColor,
    '--promo-secondary-text': secondaryTextColor
  }

  return !primaryText ? null : (
    <div style={styles}>
      <PromoBannerBaseTemplate role="banner">
        <PartnerOfferText
          isSingleLine
          primaryText={primaryText}
          secondaryText={secondaryText}
        />
      </PromoBannerBaseTemplate>
    </div>
  )
}

export default TopPageBanner
