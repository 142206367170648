import type { HTMLAttributes, ReactElement } from 'react'

import { ContentfulImage } from '@ecomm/contentful/components'
import type { SocialLink, SocialLinks } from '@ecomm/footer-data'

type SocialMediaLinksProps = HTMLAttributes<HTMLUListElement> & {
  readonly links: SocialLinks
}

type SocialMediaLinkProps = {
  readonly className?: string
  readonly link: SocialLink
}

export function SocialMediaLink({
  link,
  className = ''
}: SocialMediaLinkProps): ReactElement {
  return (
    <li className={`mr-3 md:ml-0 md:mr-4 ${className} h-4 w-4`}>
      <a href={link.url} key={link.url}>
        <ContentfulImage classNameOverride="h-4 w-4" {...link.icon} />
      </a>
    </li>
  )
}

export function SocialMediaLinks({
  links,
  className = '',
  style
}: SocialMediaLinksProps) {
  return (
    <ul
      className={`m-0 flex list-none py-2 md:mt-0 ${className}`}
      style={style}
    >
      {links.map((link, index) => (
        <SocialMediaLink
          className="hover:opacity-50"
          key={`social_media_link${index}`}
          link={link}
        />
      ))}
    </ul>
  )
}
