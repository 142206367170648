import { z } from '@simplisafe/ewok'

export const guaranteeAssetSchema = z.object({
  guaranteeAsset: z.object({
    originalWidth: z.number(),
    originalHeight: z.number(),
    title: z.string(),
    url: z.string(),
    size: z.number(),
    description: z.string()
  })
})
