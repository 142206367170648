import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  },
  {
    icon: SharedIcons.PeelAndStick,
    text: 'Peel-and-stick install'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 5 yrs'
  },
  {
    icon: SharedIcons.WeatherResistant,
    text: 'Weather Resistant'
  },
  {
    icon: SharedIcons.RedSiren,
    text: '105 dB siren'
  }
]

const description = {
  content: `The extra loud siren drives away intruders and alerts neighbors. It's even louder than our Base Station siren - about as loud as a Led Zeppelin concert in '72.
    \nIt's weather resistant for indoor or outdoor use, mounting as easily under an eave outside as it does to an interior wall. Place the extra siren far from the Base Station for maximum deterrence.
  `
}

const specs = {
  content: `3 x 6.25 x 0.87 inches
    \n4 AA Alkaline batteries (included)
    \nOptional screw kit for wall mounting
    \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '1NXqNF4yxU1hPal694fzD6'
}

export const extraLoudSirenData: ProductData = {
  productId: '110UAEWXgghndTpfVADY7P',
  sensorName: 'Extra Loud Siren',
  features,
  description,
  specs,
  faqs
}
