import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { coloredSectionSchema } from '@ecomm/shared-sections'
import { type TypeOf, z } from '@simplisafe/ewok'

import { verifyPassBannerSchema } from '../../components/ProtectorsPage/VerifyPassBanner/schema'

export const contentfulImageQuerySchema = z.object({
  __typename: z.literal('ContentfulAsset'),
  contentful_id: z.string(),
  title: z.string(),
  description: z.string(),
  file: z.object({
    url: z.string(),
    details: z.object({
      size: z.number(),
      image: z.object({
        width: z.number(),
        height: z.number()
      })
    })
  })
})

export const howItWorksSchema = z.object({
  __typename: z.literal('ContentfulReviewSection'),
  headline: z.string(),
  imageContent: contentfulRichTextSchema,
  reviews: z
    .object({
      logo: contentfulImageQuerySchema,
      review: contentfulRichTextSchema
    })
    .array()
})

const gatsbyGridSchema = z.object({
  __typename: z.literal('ContentfulGrid'),
  contentful_id: z.string()
})

const faqSchema = z.object({
  __typename: z.literal('ContentfulFaq'),
  title: z.string(),
  primary: z
    .object({
      question: z.object({
        question: z.string()
      }),
      answer: contentfulRichTextSchema
    })
    .array()
})

const smallTextSchema = z.object({
  __typename: z.literal('ContentfulSmallText'),
  text: z.object({
    raw: z.string(),
    references: contentfulImageQuerySchema.array().optional()
  })
})

const layoutSchema = z.object({
  promoBannerStyle: z.string(),
  components: z
    .union([
      verifyPassBannerSchema,
      coloredSectionSchema,
      howItWorksSchema,
      gatsbyGridSchema,
      faqSchema,
      smallTextSchema
    ])
    .array(),
  footer: z.object({
    contentful_id: z.string()
  })
})

export const protectorsPageSchema = z.object({
  contentful_id: z.string(),
  breadcrumbTitle: z.string(),
  slug: z.string(),
  layout: layoutSchema
})

export const parseProtectorsPage = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const protectorsPageFragment = query.contentfulGenericLandingPage

  const homeLandingPage = protectorsPageSchema.parse(protectorsPageFragment)

  return homeLandingPage
}

export type ContentfulImageQuerySchema = TypeOf<
  typeof contentfulImageQuerySchema
>
export type ProtectorsLayoutSchema = TypeOf<typeof layoutSchema>
export type HowItWorksProps = TypeOf<typeof howItWorksSchema>
export type GridSchema = TypeOf<typeof gatsbyGridSchema>
export type VerifyPassTextSchema = TypeOf<typeof smallTextSchema>
