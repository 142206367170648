import type { Options } from '@contentful/rich-text-react-renderer'
import { MARKS } from '@contentful/rich-text-types'
import { PriceProvider } from '@ecomm/data-price'
import { Footer } from '@ecomm/footer-components'
import { useFooterQuery } from '@ecomm/footer-data'
import { BelowTheFold } from '@ecomm/lander'
import {
  usePartnershipRewardsValue,
  useSetPartnerLogic
} from '@ecomm/partners-hooks'
import { PartnerBanner } from '@ecomm/promotions-components'
import { usePartnerPromoBannerData } from '@ecomm/promotions-hooks'
import {
  ContentfulRichText,
  CountryRedirectModal,
  HeroBanner,
  PopupQuoteWizard
} from '@ecomm/shared-components'
import { useNinetailed } from '@ecomm/shared-ninetailed'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { Suspense, useEffect } from 'react'

import { PartnerHeader } from '../components/PartnerHeader'
import PartnerPageBrandBanner from '../components/PartnerPageBrandBanner'
import StrategicReferral from '../components/StrategicReferral'
import { usePartnerPageBodyQuery } from '../hooks/usePartnerPageBodyQuery'
import { getCustomerId } from '../utils/getCustomerId'
import { getSeoDescription } from '../utils/getSeoDescription'
import { parsePartnerPageData } from './parsePartnerPageData'

export type PageContext = {
  readonly locale: Locale
}

export type Props = Pick<
  PageProps<unknown, PageContext, unknown, Response>,
  'data' | 'location' | 'pageContext' | 'path'
>

function Content({
  data,
  path: pagePath,
  pageContext: { locale },
  location
}: Props) {
  const {
    pageTitle,
    partnerName,
    heroBanner,
    heroImage,
    heroText,
    coBrandLogo,
    coBrandTitle,
    termsAndConditionsPosition,
    termsAndConditions,
    termsAndConditionsTitle,
    partnerGroup,
    content,
    header,
    footer: { contentful_id: footerId },
    showPartnerPackageAbsoluteDiscountAsRelative
  } = parsePartnerPageData(data)

  const footer = useFooterQuery(footerId)

  const { hasPartnerBanner } = usePartnerPromoBannerData()

  const { identify } = useNinetailed()

  const airlinePartnerMilesString =
    usePartnershipRewardsValue().toLocaleString()

  const id = new URLSearchParams(location.search).get('id')

  const customerID = getCustomerId(partnerName, id)

  useSetPartnerLogic(partnerName, partnerGroup, pagePath, customerID)

  // This page takes responsibility for setting the partner trait.
  // Relying only on setting the partner cookie will lead to race conditions
  // with NT onRouteChange handler.
  useEffect(() => {
    const timer = setTimeout(() => {
      identify('', { isPartnerCookieSet: true })
    }, 750)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const packagesData = content.flatMap(item => item.content)

  const packageSkus = packagesData.map(partnerPackage => partnerPackage.product)

  //If the partner group is airline, dynamically calculate the number of miles for coBrandTitle
  const numberOfMilesRegex = /\{numberOfMiles\}/g
  const airlineCoBrandTitle = coBrandTitle
    ? coBrandTitle.replace(numberOfMilesRegex, `${airlinePartnerMilesString}`)
    : null

  const bodyContent = usePartnerPageBodyQuery()

  const options: Options = {
    renderMark: {
      [MARKS.SUPERSCRIPT]: text => <sup className="text-[10px]">{text}</sup>
    }
  }

  return (
    <TrackingProvider metaTitle={pageTitle}>
      <PriceProvider
        locale={locale}
        partnerGroup={partnerGroup}
        partnerName={partnerName}
        skus={packageSkus}
      >
        <SEO
          isNofollow={true}
          isNoindex={true}
          lang={locale}
          metaDescription={getSeoDescription(partnerName)}
          metaTitle={pageTitle}
        />
        <CountryRedirectModal />
        <PopupQuoteWizard data={bodyContent.quoteWizard} />
        {hasPartnerBanner ? <PartnerBanner /> : null}
        <PartnerHeader {...header} locale={locale} />
        <main
          className="prose md:prose-md lg:prose-lg relative z-0 w-full whitespace-pre-line"
          id="content"
        >
          {heroBanner ? <HeroBanner {...heroBanner} /> : null}
          <div className="max-w-8xl mx-auto mb-16 flex flex-col gap-16 px-4 pt-8 md:px-8">
            {heroBanner ? null : (
              <PartnerPageBrandBanner
                body={heroText}
                coBrandLogo={coBrandLogo}
                coBrandTitle={
                  partnerGroup === 'airlines'
                    ? airlineCoBrandTitle
                    : coBrandTitle
                }
                img={heroImage}
                termsAndConditions={termsAndConditions}
                termsAndConditionsPosition={
                  termsAndConditionsPosition !== 'Bottom'
                    ? termsAndConditionsPosition
                    : 'None'
                }
                termsAndConditionsTitle={termsAndConditionsTitle}
              />
            )}
            {partnerGroup === 'partner-strategic-referral' &&
            locale === 'en-US' ? (
              <StrategicReferral
                packagesData={packagesData}
                showPartnerPackageAbsoluteDiscountAsRelative={
                  !!showPartnerPackageAbsoluteDiscountAsRelative
                }
              />
            ) : (
              <BelowTheFold data={bodyContent} />
            )}
            {termsAndConditions && termsAndConditionsPosition === 'Bottom' ? (
              termsAndConditionsTitle ? (
                <div className="max-w-8xl mx-auto -mt-8 text-sm leading-7 lg:mt-1 lg:px-7">
                  {termsAndConditionsTitle}
                  <ContentfulRichText
                    optionsCustom={options}
                    raw={termsAndConditions.raw}
                  />
                </div>
              ) : (
                <div className="max-w-8xl mx-auto -mt-8 text-sm leading-7 lg:mt-1 lg:px-7">
                  <ContentfulRichText
                    optionsCustom={options}
                    raw={termsAndConditions.raw}
                  />
                </div>
              )
            ) : null}
          </div>
        </main>
        <Footer {...footer} />
      </PriceProvider>
    </TrackingProvider>
  )
}

export default function PartnerPageTemplate(props: Props) {
  return (
    <Suspense>
      <Content {...props} />
    </Suspense>
  )
}

// Fields commented out until a partner uses it (perkspot doesn't use these)
export const query = graphql`
  query PartnerPageTemplate($id: String) {
    contentfulPartnerPageV2(id: { eq: $id }) {
      contentful_id
      pageTitle
      partnerName
      partnerGroup
      partnerPageTemplate {
        id
        pageTitle
        heroBanner {
          ...partnersHeroBanner
        }
        heroText {
          raw
        }
        header {
          ...partnersHeader
        }
        footer {
          contentful_id
        }
        pageTitle
        heroImage {
          ...partnersImageWithArtDirection
        }
        content {
          content {
            ...partnersPackage
          }
        }
      }
      heroBanner {
        ...partnersHeroBanner
      }
      heroText {
        raw
      }
      heroImage {
        ...partnersImageWithArtDirection
      }
      coBrandLogo {
        gatsbyImageData(layout: FIXED, width: 160, placeholder: BLURRED)
        description
      }
      coBrandTitle
      termsAndConditionsPosition
      termsAndConditionsTitle
      termsAndConditions {
        raw
      }
      content {
        content {
          ...partnersPackage
        }
      }
      showPartnerPackageAbsoluteDiscountAsRelative
    }
  }
`
