import { ErrorMessage } from 'formik'
import React from 'react'

type FormFieldProps = {
  readonly label: string
  readonly name: string
  readonly children: React.ReactNode
  readonly className?: string
  readonly htmlFor?: string
}

export function FormField({
  label,
  name,
  children,
  className = '',
  htmlFor = name
}: FormFieldProps) {
  return (
    <div className={className} data-component="FormField">
      <label className="font-medium" htmlFor={htmlFor}>
        {label}
      </label>
      {children}
      <div className="h-3">
        <ErrorMessage
          className="text-complementary-red-100 static left-9"
          component="span"
          name={name}
        />
      </div>
    </div>
  )
}
