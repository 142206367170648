import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const guaranteeSchema = z
  .object({
    description: contentfulRichTextSchema.nullable(),
    image: gatsbyImageSchema.optional()
  })
  .optional()

export type GuaranteeSchema = TypeOf<typeof guaranteeSchema>
