import { trackNavigationClick } from '@ecomm/cdp-tracking'
import {
  ContentfulImage,
  ContentfulImageSchema
} from '@ecomm/contentful/components'
import { useLocale } from '@ecomm/data-hooks'
import { Link } from '@ecomm/framework'
import classNames from 'classnames'
import { useTracking } from 'react-tracking'

import { type ServiceMenuItem, getServiceMenuItems } from './data'
import type { HeaderRedesignSchema } from './schema'

type ServicesDropdownMenuProps = HeaderRedesignSchema & {
  readonly onToggleDropdown: (close?: boolean) => void
  readonly isOdmonVariant: boolean
}

type MenuItem = ServiceMenuItem & {
  readonly image?: ContentfulImageSchema
}

export function ServicesDropdownMenu({
  images,
  onToggleDropdown,
  isOdmonVariant
}: ServicesDropdownMenuProps) {
  const locale = useLocale()

  const { trackEvent } = useTracking()

  const menuItems = getServiceMenuItems(locale, isOdmonVariant).map(item => ({
    ...item,
    image: images.items.find(img => img.contentful_id === item.imageId)
  }))

  const getMenuItemContent = (item: MenuItem, index: number) => (
    <div
      className={classNames(
        'flex flex-nowrap items-center border-0 border-b border-solid border-b-neutral-300 py-4 min-[1126px]:mx-5 min-[1126px]:w-[215px] min-[1126px]:flex-row min-[1126px]:flex-wrap min-[1126px]:border-b-0 min-[1126px]:py-0',
        index === menuItems.length - 1 && 'px-4 min-[1126px]:px-0'
      )}
    >
      <div className="flex w-1/2 flex-wrap min-[1126px]:w-auto">
        <span
          className={classNames(
            'mb-2 mr-6 w-full text-base font-bold min-[1126px]:mr-0 min-[1126px]:w-full min-[1126px]:whitespace-nowrap'
          )}
        >
          {item.text}
        </span>
      </div>
      {item.image ? (
        <div
          className="flex w-1/2 justify-end min-[1126px]:w-auto"
          data-testid={`services-dropdown-image-${index}`}
        >
          <ContentfulImage
            className="mr-3 h-[75px] w-[110px] rounded-xl md:h-[95px] md:w-[145px] min-[1126px]:h-[145px] min-[1126px]:w-[215px]"
            {...item.image}
            quality={90}
            width={215}
          />
        </div>
      ) : null}
    </div>
  )

  const handleClick = (item: MenuItem) => {
    onToggleDropdown(true)
    trackEvent({
      event: 'navigation-link',
      eventAction: 'click',
      eventCategory: 'navigation',
      label: item.text
    })
    trackNavigationClick({
      action: 'click',
      navElement: item.text
    })
  }

  const menuItemClasses =
    'w-full rounded-md text-neutral-black no-underline hover:underline focus:outline-2 focus:outline-offset-1 focus:outline-complementary-blue-100 min-[1126px]:mx-0 min-[1126px]:w-auto [&:not(:last-of-type)]:mx-4'

  return (
    <div
      className={classNames(
        'left-0 top-0 z-[1] flex w-full flex-wrap justify-start gap-x-12 bg-white min-[1126px]:absolute min-[1126px]:mt-[78px] min-[1126px]:h-52 min-[1126px]:flex-nowrap min-[1126px]:justify-center min-[1126px]:py-3 min-[1126px]:shadow-md'
      )}
      data-testid="services-dropdown-menu"
    >
      {menuItems.map((item, i) => (
        <Link
          className={menuItemClasses}
          key={i}
          onClick={() => handleClick(item)}
          to={item.url}
        >
          {getMenuItemContent(item, i)}
        </Link>
      ))}
    </div>
  )
}
