import { ScoutVideoType } from '.'

// TODO: We will revisit the styling aspects of these props when we have the correct
// video assets (based on aspect ratio and resolution) to ensure they can work
// accross our different viewports.
export type ScoutVideoMetadata = {
  readonly videoSrcUrlWebm: string
  readonly videoSrcUrlMp4: string
  readonly captionsSrcUrl?: string
  readonly poster?: string
  readonly preload?: string
}

const AlarmMonitoringScoutVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/monitoring_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/monitoring_video.mp4',
  captionsSrcUrl:
    'https://video.simplisafe.com/marketing/scout/monitoring_caption.vtt',
  poster:
    'https://video.simplisafe.com/marketing/scout/monitoring_hp_poster.jpg',
  preload: 'none'
}

const ScoutProductLiveGuardVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/scout_pdp_60_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/scout_pdp_60_video.mp4',
  captionsSrcUrl:
    'https://video.simplisafe.com/marketing/scout/scout_pdp_60_captions.vtt',
  poster:
    'https://video.simplisafe.com/marketing/scout/scout_pdp_60_poster.jpg',
  preload: 'none'
}

const ScoutProductInstallationVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/scout_installation_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/scout_installation_video.mp4',
  poster:
    'https://video.simplisafe.com/marketing/scout/scout_installation_poster.jpg',
  preload: 'none'
}

const ScoutHomePageVideo: ScoutVideoMetadata = {
  videoSrcUrlWebm:
    'https://video.simplisafe.com/marketing/scout/scout_hp_video.webm',
  videoSrcUrlMp4:
    'https://video.simplisafe.com/marketing/scout/scout_hp_video.mp4',
  captionsSrcUrl:
    'https://video.simplisafe.com/marketing/scout/scout_hp_captions.vtt',
  poster:
    'https://video.simplisafe.com/marketing/scout/monitoring_hp_poster.jpg',
  preload: 'none'
}

export const data: Record<ScoutVideoType, ScoutVideoMetadata> = {
  AlarmMonitoringScoutVideo,
  ScoutProductLiveGuardVideo,
  ScoutProductInstallationVideo,
  ScoutHomePageVideo
}
