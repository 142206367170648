import React from 'react'

export type CeoCommentProps = {
  readonly data: ReadonlyMap<string, string>
}

export function CeoComment({ data }: CeoCommentProps) {
  return (
    <div
      className="bg-neutral-black flex items-center justify-between rounded-2xl px-4 py-8 lg:px-20 lg:py-14"
      data-component="CeoComment"
    >
      <div className="flex basis-full flex-wrap justify-center">
        <p className="mb-8 basis-auto text-center text-base leading-[22px] text-white lg:mb-10 lg:text-[22px] lg:leading-7">
          {data.get('comment')}
        </p>
        <p className="mb-0 basis-auto text-center text-base font-bold leading-[22px] text-white">
          {data.get('quoteBy')}
        </p>
      </div>
    </div>
  )
}
