import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'
import { useAtom } from 'jotai'
import React, { Dispatch, SetStateAction } from 'react'

import { monitoringAtom } from '../../atoms/draftCart/monitoringPlanAtom'
import { useGiftItems } from '../DraftCart/hooks'
import { MonitoringPlan } from '../DraftCart/types'
import FreeGiftItem from '../FreeGiftItem'
import { PackageItem as PackageItemType } from '../Package'
import PackageItem from '../PackageItem'

type Props = {
  readonly productItems: readonly PackageItemType[]
  readonly setHoveredIdx: Dispatch<SetStateAction<number>>
  readonly sku: string
  readonly hasOffset?: boolean
}

function PackageItems({
  productItems,
  setHoveredIdx,
  sku,
  hasOffset = false
}: Props) {
  const isUs = useLocale() === 'en-US'
  const microCopy = useMicroCopy()
  const [monitoring] = useAtom(monitoringAtom)

  const headerText = isUs ? 'equipment' : 'what-is-included'

  const monitoringPlan =
    monitoring.type === MonitoringPlan.interactive ||
    monitoring.type === MonitoringPlan.odmonOvernight ||
    monitoring.type === MonitoringPlan.odmon247
      ? 'withMonitoring'
      : 'withoutMonitoring'
  const freeGiftItems = useGiftItems(sku)[monitoringPlan]

  return (
    <div className="flex flex-col gap-2">
      <h2
        className={classNames('my-0', {
          'font-bold': isUs,
          'font-normal': !isUs
        })}
      >
        {microCopy[headerText]}
      </h2>

      <div
        className={classNames('', {
          'grid grid-cols-3 gap-2': !isUs,
          'mt-2 flex flex-col gap-2 md:mt-0 md:grid md:grid-cols-3 md:gap-2':
            isUs
        })}
      >
        {productItems.map((productItem, index) => (
          <PackageItem
            key={productItem.product.sku}
            onMouseEnter={() => setHoveredIdx(index + Number(hasOffset))}
            onMouseLeave={() => setHoveredIdx(0)}
            productItem={productItem.product}
            quantity={productItem.quantity}
          />
        ))}
        {freeGiftItems
          ? freeGiftItems.map((item, idx) => (
              <FreeGiftItem giftItem={item} key={idx} />
            ))
          : null}
      </div>

      <p className="mb-0 text-sm font-medium">
        {microCopy['not-the-right-fit']}{' '}
        <a
          className="text-inherit visited:text-inherit hover:no-underline"
          href="/build-my-system"
        >
          {microCopy['build-your-own-system']}
        </a>
      </p>
    </div>
  )
}

export default PackageItems
