import enUs from './en-US'

// the typeof check makes sure that we don't add any extra keys
const enGb: typeof enUs = {
  ...enUs,
  'if-the-issue-persists':
    'If the issue persists, please call 0800-456-1789 to complete your order.',
  'add-sensors-to-customize': 'Add sensors to customise your package',
  color: 'colour',
  'monitoring-toggle-title': (_discountText: string, _planName: string) =>
    `Pick your system price`,
  'monitoring-name': 'Pro Premium Monitoring',
  'floating-badge-pro-premium': 'All systems with Pro Premium monitoring',
  'country-redirect-title': 'Are you in the US?',
  'country-redirect-text':
    "You're on the UK site, which serves our customers in the United Kingdom. Switch to the US site below.",
  'country-redirect-button-text': 'Go to the US site',
  'country-redirect-link': 'https://simplisafe.com',
  'take-our-quiz-label': 'Get Started',
  'live-guard-protection': '24/7 alarm monitoring',
  'required-with-system': 'Included with your system',
  'payment-number': '0800-456-1802',
  'postal-code': 'Postal Code',
  'shipping-truck': 'Free delivery with system order',
  'cart-error-state-subheading':
    'If the issue persists, please call 0800-920-2420 to complete your order.',
  'contact-email': 'customer-support@simplisafe.co.uk',
  'fast-protect-daily-price': '93p',
  'contact-number': '0800-456-1789',
  'checkout-tax': 'Estimated VAT:',
  'checkout-total': 'Total (including VAT):'
}

export default enGb
