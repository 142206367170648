import { pipe } from 'fp-ts/lib/function'

import {
  CHANGE_MONITORING_URL,
  CHANGE_MONITORING_URL_REPLACEMENT,
  CHOOSE_MONITORING_URL,
  CHOOSE_MONITORING_URL_REPLACEMENT
} from './constants'

export const renameLineItemProps = (lineItemProps: {
  readonly itemDescriptionContent: React.ReactElement
  readonly itemName: string
}) => {
  const itemDescriptionContentElement: React.ReactElement = <
    React.ReactElement
  >lineItemProps.itemDescriptionContent

  const descriptionRaw: string =
    itemDescriptionContentElement?.props.productInformation?.description?.raw ||
    ''
  const newDescriptionRaw = pipe(descriptionRaw, replaceMonitoringUrl)

  const newItemDescriptionContentElement: React.ReactElement = isChanged(
    descriptionRaw,
    newDescriptionRaw
  )
    ? {
        ...itemDescriptionContentElement,
        props: {
          ...itemDescriptionContentElement.props,
          productInformation: {
            ...itemDescriptionContentElement.props.productInformation,
            description: {
              raw: newDescriptionRaw
            }
          }
        }
      }
    : itemDescriptionContentElement

  const newProps = isChanged(descriptionRaw, newDescriptionRaw)
    ? {
        ...lineItemProps,
        itemDescriptionContent: newItemDescriptionContentElement
      }
    : lineItemProps

  return newProps
}

const replaceMonitoringUrl = (s: string) =>
  s
    .replaceAll(CHOOSE_MONITORING_URL, CHOOSE_MONITORING_URL_REPLACEMENT)
    .replaceAll(CHANGE_MONITORING_URL, CHANGE_MONITORING_URL_REPLACEMENT)

const isChanged = (older: string, newer: string) =>
  older && newer && older !== newer
