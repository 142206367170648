//@ts-nocheck
import classNames from 'classnames'
import React, { FC } from 'react'

import { SSButton } from '..'
import { SSButtonProps } from '../SSButton'
import { ViewName } from './data'
import * as css from './HowItWorksHouse.module.scss'

/**
 * Defines the props that make up each house view's button.
 *
 * These are then mapped to create SSButton components to trigger eac view.
 */
export type HouseViewButtonProps = SSButtonProps & {
  /**
   * Button ID. Expects to be a valid ViewName. Support 'houseFront',
   * 'insideFront', and 'insideBack'
   */
  readonly id: ViewName
  /** Determines if button is highlighted or not. */
  readonly isActive?: boolean
  /**
   * Button click event.
   *
   * Passes this button's ViewName as the property for setting the active button
   * and associated house view.
   */
  readonly onClick?: (viewName: ViewName) => void
  /** data-component attribute value */
  readonly dataComponent?: string
}

const HouseViewButton: FC<HouseViewButtonProps> = ({
  children,
  id,
  isActive,
  onClick,
  dataComponent = HouseViewButton.name
}: HouseViewButtonProps) => {
  return (
    <SSButton
      className={classNames(css.houseViewButton, { [css.isActive]: isActive })}
      color={isActive ? 'primary' : 'primaryOutline'}
      dataComponent={dataComponent}
      hasHover={false}
      id={id}
      onClick={() => onClick && onClick(id)}
      value={id}
    >
      {children}
    </SSButton>
  )
}

export default HouseViewButton
