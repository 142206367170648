import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

type OutdoorCameraWarningViewEvent = 'cart-outdoor-cam-warning-view'

export const useTrackOutdoorCamWarningView = () => {
  const { trackEvent } =
    useTracking<TrackingData<OutdoorCameraWarningViewEvent>>()

  return useCallback(() => {
    trackEvent({
      event: 'cart-outdoor-cam-warning-view'
    })
  }, [trackEvent])
}
