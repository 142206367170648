//@ts-nocheck
import classnames from 'classnames'
import classNames from 'classnames'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import prop from 'ramda/src/prop'
import React, { FC, ReactNode } from 'react'
import { animated, useTransition } from 'react-spring'

//TODO to check on passing this style from FE itself.
import promocss from '../FloatingPromoWidget/FloatingPromoWidget.module.scss'
import { Cancel } from '../icons'
import Square from '../Square'
import * as css from './PopupCard.module.scss'

export type TransformProps = {
  readonly transform: string
}

export type PopupType = 'bottom' | 'right'

export type PopupCardProps = {
  readonly children?: ReactNode
  readonly showOpen: boolean
  readonly popupType?: PopupType
  readonly onCloseCallback: () => void
  /** This props is used for transition effect eg: from left to right transition */
  readonly transition?: {
    readonly enter: TransformProps
    readonly from: TransformProps
    readonly leave: TransformProps
  }
  /* props for add a padding in border */
  readonly padding?: number
  /* props for promo widget styles */
  readonly promoWidgetStyles?: boolean
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const PopupCard: FC<PopupCardProps> = ({
  children,
  showOpen,
  onCloseCallback,
  popupType = 'bottom',
  dataComponent = PopupCard.name,
  padding,
  promoWidgetStyles
}: PopupCardProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const styles = ifElse(
    equals(true),
    always(promocss),
    always(css)
  )(promoWidgetStyles)

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const popupClasses = classNames(styles.popupCard, 'shadowMedium rounded-base')
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const iconClasses = classNames(styles.closeIcon)

  const transitionTypes = {
    bottom: {
      enter: { transform: 'translate3d(0,0,0)' },
      from: { transform: 'translate3d(0,100%,0)' },
      leave: { transform: 'translate3d(0,100%,0)' }
    },
    right: {
      enter: { transform: 'translate3d(0,0,0)' },
      from: { transform: 'translate3d(100%,0,0)' },
      leave: { transform: 'translate3d(100%,0,0)' }
    }
  }

  const transitions = useTransition(
    showOpen,
    null,
    prop(popupType, transitionTypes)
  )
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              className={popupClasses}
              data-component={dataComponent}
              key={key}
              style={props}
            >
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <Square className={styles.popupCardSquare} padding={padding}>
                <Cancel
                  className={classnames(iconClasses, 'text-neutral-medium-120')}
                  onClick={onCloseCallback}
                  titleA11y="Close" //TODO should this come from Contentful?
                />
                {children}
              </Square>
            </animated.div>
          )
      )}
    </>
  )
}

export default PopupCard
