import { Locale } from '@ecomm/utils'
import { IODynamicPackage } from '@simplisafe/ss-ecomm-data/dynamicPackages'
import { OrderedMap } from 'immutable'
import { useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'

import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'
import { PackageType } from './schema'

export function useDynamicTypeFetch(
  locale: Locale,
  type: PackageType,
  packageSku: string,
  attributeHash?: string
) {
  const [loading, setLoading] = useState(type === 'Dynamic')
  const [isError, setError] = useState(false)
  const setItems = useSetAtom(itemQuantityAtom)

  const onSuccess = (
    data: readonly { readonly sku: string; readonly quantity: number }[]
  ) => {
    const ordered = data.reduce(
      (o, { sku, quantity }) =>
        sku !== packageSku ? o.set(sku, o.get(sku, 0) + quantity) : o,
      OrderedMap<string, number>()
    )

    setItems(ordered)
    setLoading(false)
  }

  const onError = () => {
    setLoading(false)
    setError(true)
  }

  useEffect(() => {
    type === 'Dynamic' && attributeHash
      ? IODynamicPackage(attributeHash, locale, onError, onSuccess)
      : null
  }, [])

  return { isLoading: loading, isError }
}
