import { contentfulImageSchema } from '@ecomm/contentful/components'
import { apolloRichTextSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

export const blogArticleSchema = z.object({
  content: apolloRichTextSchema,
  image: contentfulImageSchema.nullish(),
  publishDate: z.string().optional(),
  title: z.string(),
  slug: z.string()
})

export type BlogArticle = TypeOf<typeof blogArticleSchema>
