import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click change/add coupon code in cart.
 */
export function trackCartChangeAddCode(hasCodeInCart: boolean) {
  getRudderstack(r => {
    const label = hasCodeInCart ? 'change' : 'add'
    r.track('coupon_change_add_code_click', { label })
  })
}

/**
 * Track click apply coupon code in cart.
 */
export function trackCartCouponApplied(promo_code: string, state: boolean) {
  getRudderstack(r => {
    const response = state ? 'valid' : 'invalid'
    r.track('coupon_apply_button_click', { promo_code, response })
  })
}
