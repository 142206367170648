import { ProductData } from '../schema'

const description = {
  content: `There are two options to secure your home with a SimpliSafe system. Set it up yourself with interactive guidance from the SimpliSafe App or have a trained, professional technician set up some or all of it for you.
    \nThe technician will ensure your system is set up and activated, and will show you how to use it.
    \nEither way, you're backed by 24/7 professional monitoring, expert customer support and a 100% satisfaction guarantee.
  `
}

const faqs = {
  contentfulId: '4zws6Vk9b4G5MgeHaku79C'
}

export const proSetupData: ProductData = {
  productId: '2N9rMzHMI5Wh5L5J6WFxd6',
  sensorName: 'Pro Setup',
  description,
  faqs
}
