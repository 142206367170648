import both from 'ramda/src/both'
import contains from 'ramda/src/contains'
import equals from 'ramda/src/equals'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
import not from 'ramda/src/not'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'
import trim from 'ramda/src/trim'
import type from 'ramda/src/type'
import when from 'ramda/src/when'
import React, { Fragment } from 'react'

const wrapComponent = (text: string) => {
  return (
    <Fragment key={text}>
      {' '}
      {text} <br />
    </Fragment>
  )
}
const spanComponent = (text: string | unknown, index: number) => {
  // @ts-expect-error TS(2746) FIXME: This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message
  return <span key={`key-span-${text}-${index}`}> {text} </span>
}

const mustCond = both(contains('\n'), pipe(trim, isEmpty, not))

export const wrapperText = when(mustCond, pipe(split('\n'), map(wrapComponent)))

export const newLineCheck = when(pipe(type, equals('String')), wrapperText)

const mapSpanWithIndex = (textArray: readonly string[]) =>
  textArray.map((text, index) => spanComponent(text, index))

/**
 * Helper function that splits a text by empty space and wraps each item in an indexed span tag.
 *
 *
 * @returns {JSX.Element}
 *
 */
export const spanWrap = when(
  pipe(type, equals('String')),
  pipe(split(' '), mapSpanWithIndex)
)
