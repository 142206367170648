import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from 'zod'

export const emailFeedbackPropsSchema = z.object({
  form: z
    .object({
      title: z.string(),
      inputName: z.string(),
      placeholder: z.string(),
      required: z.boolean()
    })
    .array(),
  button: z.object({
    text: z.string()
  }),
  note: contentfulRichTextSchema,
  successMessage: contentfulRichTextSchema,
  submittedMessage: z.string()
})

export const emailFeedbackSchema = z.object({
  userComment: z
    .string({ required_error: 'Comments is a required field' })
    .min(10, { message: 'Comments must contain at least 10 characters' })
    .trim()
})

export type EmailFeedbackProps = TypeOf<typeof emailFeedbackPropsSchema> & {
  readonly positiveFeedback: boolean
  readonly campaignId: string
  readonly userId: string
}
