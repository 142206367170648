import {
  SecureHeaderProgressBarComponent,
  SimpleFooterComponent,
  SiteWideMessagesContext
} from '@ecomm/checkout/layout'
import {
  type ShippingFormFragment,
  shippingFormSchema
} from '@ecomm/checkout/shipping-schema'
import { useLocale } from '@ecomm/data-hooks'
import { Footer } from '@ecomm/footer-components'
import { ApplyPromoCode, PageToaster } from '@ecomm/promotions-components'
import {
  CountryRedirectModal,
  SkipToContentLink
} from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'
import {
  devParams,
  TrackingProvider,
  useOptimizelyParams
} from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import type { Locale } from '@simplisafe/ewok'
import { configureNewRelic } from '@simplisafe/ss-ecomm-data/thirdparty/errorLogging'
import { type PageProps, graphql } from 'gatsby'
import { useEffect, useMemo } from 'react'
import { useTracking } from 'react-tracking'
import { BooleanParam, useQueryParam } from 'use-query-params'

import type {
  ContentfulCheckoutPage,
  ContentfulCheckoutPageTemplate,
  ContentfulSiteWideMessages
} from '../../checkout.graphql'
import { checkoutFooterData } from '../components/CheckoutFooter'
import CheckoutPageWrapper from '../components/CheckoutPageWrapper'
import useAwinTracking from '../utils/useAwinTracking'
import useUtmContent from '../utils/useUtmContent'

export type CheckoutPageContext = {
  readonly id: string
  readonly locale: Locale
  readonly slug?: string
}

export type CheckoutPageQuery = {
  readonly contentfulSiteWideMessages?: Partial<ContentfulSiteWideMessages> | null
  readonly contentfulCheckoutPage?: Pick<
    ContentfulCheckoutPage,
    'metaTitle' | 'pageTitle' | 'site' | 'url'
  > & {
    readonly template?: Pick<
      ContentfulCheckoutPageTemplate,
      'defaultPageTitle' | 'hasLiveChat' | 'liveChatAppId'
    > & {
      readonly ukCheckoutFooter?: {
        readonly description?: {
          readonly raw?: string | null
        }
      } | null
    }
    readonly form?: Partial<ShippingFormFragment> | null
  }
}

export type CheckoutPageProps = PageProps<
  CheckoutPageQuery,
  CheckoutPageContext
>

function CheckoutPage({ data }: CheckoutPageProps) {
  const { trackEvent } = useTracking()
  const isTabletUp = useMediaQuery('TabletAndUp')
  const locale = useLocale()

  const contentfulCheckoutPage = data?.contentfulCheckoutPage
  const pageUrl = contentfulCheckoutPage?.url || ''
  const metaTitle = contentfulCheckoutPage?.metaTitle || ''
  const formData = contentfulCheckoutPage?.form || {}
  const parsedForm = useMemo(() => shippingFormSchema.parse(formData), [])

  const [hidePopups] = useQueryParam(devParams.hidePopUps, BooleanParam)
  useUtmContent()
  useOptimizelyParams()
  useAwinTracking(locale === 'en-GB')

  useEffect(() => {
    trackEvent({ isMobile: !isTabletUp })
  }, [isTabletUp, trackEvent])

  useEffect(() => {
    // This sets up New Relic with our configuration from ecomm-data
    configureNewRelic()
    // Scroll to top on route change
    window && window.scrollTo(0, 0)
  }, [])

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <SiteWideMessagesContext.Provider
        // @ts-expect-error
        value={data?.contentfulSiteWideMessages || {}}
      >
        <SkipToContentLink />
        <SEO
          canonicalLink={pageUrl}
          isNofollow={false}
          isNoindex={true}
          lang={locale}
          metaDescription={'Checkout page'}
          metaTitle={metaTitle}
        />
        <PageToaster />
        <ApplyPromoCode />
        <SecureHeaderProgressBarComponent />

        <main className="p-4 md:p-8 lg:px-16 lg:pb-16" id="content">
          <CheckoutPageWrapper formData={parsedForm} />
        </main>

        {locale === 'en-US' && (
          <Footer
            {...checkoutFooterData}
            showCountrySelector={false}
            type="Slim"
          />
        )}

        {locale === 'en-GB' && (
          <SimpleFooterComponent
            // @ts-expect-error Type '{ readonly description?: { readonly raw?: string | null | undefined; } | undefined; }' is not assignable to type 'ContentfulSmallTextSection'.
            data={contentfulCheckoutPage?.template?.ukCheckoutFooter || {}}
          />
        )}

        {!hidePopups && <CountryRedirectModal />}
      </SiteWideMessagesContext.Provider>
    </TrackingProvider>
  )
}

export default CheckoutPage

export const query = graphql`
  #graphql
  query CheckoutPage($id: String, $locale: String) {
    contentfulSiteWideMessages(node_locale: { eq: $locale }) {
      ...siteWideMessages
    }
    contentfulCheckoutPage(id: { eq: $id }) {
      pageTitle
      site
      url
      metaTitle
      template {
        defaultPageTitle
        hasLiveChat
        liveChatAppId
        ukCheckoutFooter {
          ... on ContentfulSmallTextSection {
            description {
              raw
              references {
                ... on ContentfulLink {
                  contentful_id
                  ...contentfulLinkFragment
                }
              }
            }
            descriptionMobile {
              raw
              references {
                ... on ContentfulLink {
                  contentful_id
                  ...contentfulLinkFragment
                }
              }
            }
          }
        }
      }
      form {
        ... on ContentfulCheckoutForm {
          ...checkoutForm
        }
      }
    }
  }
`
