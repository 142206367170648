import { Link } from '@ecomm/framework'
import { getLogoLinkUrl } from '@ecomm/shared-cookies'
import classNames from 'classnames'

type BreadcrumbStep = {
  readonly label: string
  readonly slug: string
}

type BreadcrumbsTemplate = 'default' | 'Legacy/PLP' | 'Legal' | 'PDP'

export type BreadcrumbsProps = {
  readonly steps: readonly BreadcrumbStep[]
  readonly template?: BreadcrumbsTemplate
  readonly type?:
    | ''
    | 'Crime Near You'
    | 'Dynamic'
    | 'Expand My System'
    | 'main'
    | 'PLA'
    | 'Prebuilt'
    | 'Refurbished'
  readonly bgClassName?: string
  readonly textClassName?: string
}

const templateTypeSteps: Record<
  Exclude<BreadcrumbsTemplate, 'default'>,
  Record<string, BreadcrumbStep>
> = {
  PDP: {
    Prebuilt: {
      label: 'Packages',
      slug: 'home-security-shop-packages'
    },
    Refurbished: {
      label: 'Refurbished Packages',
      slug: 'refurb-home-security-systems-2'
    }
  },
  'Legacy/PLP': {
    'Crime Near You': {
      label: 'Crime Near Me',
      slug: 'crime-near-you'
    },
    'Expand My System': {
      label: 'Expand My System',
      slug: 'alarm-sensors'
    }
  },
  Legal: {
    main: {
      label: 'Legal',
      slug: 'legal'
    }
  }
}

const getHomeSlug = () => getLogoLinkUrl().replace('/', '')

const buildSteps = (
  steps: BreadcrumbsProps['steps'],
  template: BreadcrumbsTemplate,
  type: BreadcrumbsProps['type']
): readonly BreadcrumbStep[] => {
  const baseStep = { label: 'Home', slug: getHomeSlug() }
  const fullSteps =
    template !== 'default' && !!type
      ? [baseStep, templateTypeSteps[template][type], ...steps]
      : [baseStep, ...steps]

  return fullSteps.filter((step): step is BreadcrumbStep => !!step)
}

export function Breadcrumbs({
  steps,
  template = 'default',
  type = '',
  bgClassName = '',
  textClassName = ''
}: BreadcrumbsProps) {
  const crumbs = buildSteps(steps, template, type).map((step, index) => {
    return (
      <span
        className={classNames(
          "after:ml-1 after:mr-1 after:content-['/'] last:underline last:underline-offset-2 last:after:content-['']",
          textClassName
        )}
        key={index}
      >
        <Link
          className={classNames(
            'font-bold text-[#0F2648] no-underline',
            textClassName
          )}
          to={`/${step.slug}`}
        >
          {step.label}
        </Link>
      </span>
    )
  })

  return (
    <div
      className={classNames('w-full', bgClassName)}
      data-testid="breadcrumbs"
    >
      <div className="max-w-8xl mx-auto flex flex-wrap px-8 pt-4 text-left text-base">
        {crumbs}
      </div>
    </div>
  )
}
