import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const meetTheSystemBannerSchema = z.object({
  image: z.object({
    description: gatsbyImageSchema.shape.description,
    gatsbyImageData: gatsbyImageSchema.shape.gatsbyImageData,
    title: gatsbyImageSchema.shape.title
  }),
  headline: z.object({
    text: contentfulRichTextSchema.nullable().optional()
  }),
  body: z.object({
    text: contentfulRichTextSchema.nullable().optional()
  })
})

export type MeetTheSystemBannerSchema = TypeOf<typeof meetTheSystemBannerSchema>
