import React from 'react'

type Props = {
  readonly height?: string
  readonly width?: string
}
export function AffirmLogo({ height = '42px', width = '100%' }: Props) {
  return (
    <svg
      aria-label="Affirm"
      data-component="AffirmLogoSVG"
      height={height}
      viewBox="0 0 105 42"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <rect height="42" id="rect-1" width="105" x="0" y="0" />
        <mask
          id="mask-2"
          maskContentUnits="userSpaceOnUse"
          maskUnits="userSpaceOnUse"
        >
          <rect fill="black" height="42" width="105" x="0" y="0" />
          <use fill="white" xlinkHref="#rect-1" />
        </mask>
      </defs>
      <g>
        <use fill="none" xlinkHref="#rect-1" />
        <g mask="url(#mask-2)">
          <path
            d="M.69583477 26.42315834c1.77878893-1.42210548 5.0546027-2.76561456 7.90409496-2.76561456l.00245065-.00982478c4.20931054 0 7.44102179 1.8838592 7.44102179 6.55052586V41.5185986h-4.6209312v-2.70175813c-1.04865213 1.81263277-3.25376238 3.1929862-5.73328657 3.1929862-3.4718236 0-5.69408464-1.89368586-5.69408464-5.02281002 0-3.9470156 4.00595039-5.36175483 9.11690562-6.02491198 1.3671688-.1792972 2.08995513-.63368466 2.08995513-1.53017439 0-1.33614095-1.09275535-1.9992981-3.07000414-1.9992981-2.08015395 0-4.38571896 1.1199994-5.81169041 2.42912267l-1.6244312-3.4385965zM6.8788384 38.38209882l.00600605.00000374c2.45109127-.00707579 4.21176072-2.13136585 4.21176072-4.7452612-3.98634943.42491123-6.17185873 1.08561358-6.17185873 3.05052586 0 1.05036578.65737268 1.69069525 1.94784712 1.6947166l.00624484.000015z"
            fill="rgb(16,24,32)"
            fillRule="evenodd"
          />
          <path
            d="M54.97829891 24.16596491v17.33052632h4.94434722v-8.3508772c0-3.96666666 2.39621981-5.13333333 4.06475324-5.13333333.75115749-.00587955 1.48711918.21211718 2.11445572.6263158l.9040952-4.58070176c-.70059046-.2811308-1.45066445-.41731119-2.20511025-.40035088-2.54077704 0-4.13825691 1.12736842-5.19180959 3.4214035v-2.91298245h-4.63073154"
            fill="rgb(16,24,32)"
            fillRule="evenodd"
          />
          <path
            d="M89.91949597 23.65754386c-2.6142807 0-4.56947847 1.54736842-5.5862793 3.04070175-.94329717-1.92807017-2.94014702-3.04070175-5.34126707-3.04070175-2.6118306 0-4.42247113 1.45403509-5.2579629 3.1291228v-2.62070175h-4.7679384v17.33052632h4.94924746v-8.9231579c0-3.19298245 1.67343368-4.73789473 3.23416171-4.73789473 1.41372069 0 2.71228562.91614035 2.71228562 3.2814035v10.37964913h4.93944697v-8.9231579c0-3.23964912 1.63178159-4.73789473 3.2660133-4.73789473 1.30836542 0 2.69513476.95298245 2.69513476 3.24701754v10.41403509h4.93944697V29.5154386c0-3.89298246-2.61428071-5.85789474-5.77248863-5.85789474"
            fill="rgb(16,24,32)"
            fillRule="evenodd"
          />
          <path
            d="M42.94574729 24.16596491h-4.47882394v-1.76105263c0-2.29403509 1.3059153-2.94736842 2.43052152-2.94736842.76787409.01004514 1.52285876.1992549 2.20511026.55263158l1.5239762-3.49508772s-1.5460273-1.01192983-4.35631782-1.01192983c-3.15820791 0-6.75253763 1.78561404-6.75253763 7.38807018v1.27473684h-7.48512425v-1.76105263c0-2.29403509 1.30346517-2.94736842 2.43052152-2.94736842.76914705-.0000504 1.52647477.18974655 2.20511026.55263158l1.5239762-3.49508772c-.90899545-.53298246-2.37171859-1.01192983-4.3538677-1.01192983-3.1582079 0-6.75253762 1.78561404-6.75253762 7.38807018v1.27473684H18.2240112v3.82175439h2.86909346v13.50877193h4.93944697V27.9877193h7.49492474v13.50877193h4.93944698V27.9877193h4.47882394V24.1659649"
            fill="rgb(16,24,32)"
            fillRule="evenodd"
          />
          <rect
            fill="rgb(16,24,32)"
            height="17.323"
            rx="0"
            ry="0"
            width="4.935"
            x="45.896"
            y="24.166"
          />
          <path
            d="M46.07700385 21.22105263h4.8340917c2.820091-8.88877193 12.3878194-16.70175438 23.76618831-16.70175438 13.83829192 0 25.79733987 10.5614035 25.79733987 27.00280701.04210894 3.3748275-.42038014 6.7369368-1.3720686 9.97438597h4.6919846l.04655232-.16210527c.79060325-3.2049908 1.18071362-6.49607186 1.16135807-9.79754385 0-18.33508772-13.32866643-31.53192983-30.31536577-31.53192983-13.34336716 0-25.2362618 9.28421053-28.60763038 21.22105263l-.00245012-.00491228z"
            fill="rgb(74,74,244)"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}
