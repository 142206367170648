import { type TypeOf, z } from '@simplisafe/ewok'

const formSchema = z.object({
  __typename: z.literal('ContentfulFormV2'),
  contentful_id: z.string(),
  form: z
    .object({
      title: z.string(),
      inputName: z.string(),
      options: z.string().array()
    })
    .array(),
  button: z.object({
    text: z.string()
  })
})

export const verifyPassBannerSchema = z.object({
  __typename: z.literal('ContentfulHeroBanner'),
  content: z
    .object({
      __typename: z.literal('ContentfulHeroBannerColumn'),
      description: z.object({
        raw: z.string(),
        references: formSchema.array()
      }),
      descriptionMobile: z.object({
        raw: z.string(),
        references: formSchema.array()
      }),
      image: z.object({
        title: z.string(),
        description: z.string(),
        file: z.object({
          url: z.string(),
          details: z.object({
            size: z.number(),
            image: z.object({
              width: z.number(),
              height: z.number()
            })
          })
        })
      })
    })
    .array()
})

export type VerifyPassBannerSchema = TypeOf<typeof verifyPassBannerSchema>
export type VerifyPassFormSchema = TypeOf<typeof formSchema>
