import { useMicroCopy } from '@ecomm/micro-copy'

export type AddToCartErrorType = 'recoverable' | 'unrecoverable'

export type AddToCartErrorProps = {
  readonly errorType: AddToCartErrorType
  readonly textAlign?: 'center' | 'left' | 'right'
}

function AddToCartError({ errorType }: AddToCartErrorProps) {
  const microcopy = useMicroCopy()

  return (
    <div className="m-0 text-center text-xs">
      {microcopy[`add-to-cart-error-${errorType}`]}
    </div>
  )
}

export default AddToCartError
