import { useEffect, useState } from 'react'

import ColorBody, { ColorBodySlim } from './ColorBody'
import type { ColorVariantSchema } from './schema'
import { useUniqueId } from '@ecomm/shared-hooks'

type Props = {
  readonly data: readonly ColorVariantSchema[]
  readonly defaultSku: string
  readonly onChangeColor: (newSku: string) => void
}

function ColorSelector({ data, defaultSku, onChangeColor }: Props) {
  const [sku, setSku] = useState('')
  const [color, setColor] = useState('')

  const defaultProduct = data.flat().find(product => product.sku === defaultSku)

  const groupName = useUniqueId('color-selector')

  useEffect(() => {
    setSku(defaultProduct?.sku || '')
    setColor(defaultProduct?.productColor || '')
    onChangeColor(defaultProduct?.sku || '')
  }, [data, setSku, setColor])

  const onClick = (data: ColorVariantSchema, isSelected: boolean) => {
    setSku(data.sku)
    setColor(data.productColor)
    !isSelected && onChangeColor(data.sku)
  }

  return (
    <div
      className="border-neutral-light-100 mb-3 inline-flex w-full items-center border-b-2 border-l-0 border-r-0 border-t-0 border-solid pb-2.5"
      data-component="ColorSelector"
    >
      <fieldset className="contents">
        <legend className="sr-only">Color</legend>
        <div className="mr-2 max-w-[60px]">
          <div className="text-heading-4-size mb-0 mt-1 font-bold ">Color</div>
          <p className="m-0">{color}</p>
        </div>
        {data.map((data, idx) => (
          <ColorBody
            data={data}
            isSelected={data.sku === sku}
            key={idx}
            name={groupName}
            onClick={() => onClick(data, data.sku === sku)}
          />
        ))}
      </fieldset>
    </div>
  )
}

export function ColorSelectorSlim({ data, defaultSku, onChangeColor }: Props) {
  const [sku, setSku] = useState('')

  const defaultProduct = data.flat().find(product => product.sku === defaultSku)

  const groupName = useUniqueId('color-selector-slim')

  useEffect(() => {
    setSku(defaultProduct?.sku || '')
    onChangeColor(defaultProduct?.sku || '')
  }, [data, setSku])

  const onClick = (data: ColorVariantSchema, isSelected: boolean) => {
    setSku(data.sku)
    !isSelected && onChangeColor(data.sku)
  }

  return (
    <div
      className="border-neutral-light-100 mb-3 inline-flex w-full items-center pb-2.5"
      data-component="ColorSelector"
    >
      <fieldset className="contents">
        <legend className="sr-only">Color</legend>
        {data.map((data, idx) => (
          <ColorBodySlim
            data={data}
            isSelected={data.sku === sku}
            key={idx}
            name={groupName}
            onClick={() => onClick(data, data.sku === sku)}
          />
        ))}
      </fieldset>
    </div>
  )
}

export default ColorSelector
