export type LoadingSpinnerProps = {
  readonly className?: string
  readonly fillColor?: string
  readonly dataComponent?: string
  readonly size?: number
}

export function LoadingSpinner({
  size = 35,
  className = '',
  fillColor = 'white'
}: LoadingSpinnerProps) {
  return (
    <svg
      className={className}
      data-component="LoadingSpinner"
      data-testid="LoadingSpinner"
      height={size}
      version="1.1"
      viewBox="0 0 50 50"
      width={size}
      x="0px"
      y="0px"
    >
      <path
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
        fill={fillColor}
      >
        <animateTransform
          attributeName="transform"
          attributeType="xml"
          dur="0.8s"
          from="0 25 25"
          repeatCount="indefinite"
          to="360 25 25"
          type="rotate"
        />
      </path>
    </svg>
  )
}
