import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { IOPartnerBanner } from '@simplisafe/ss-ecomm-data/promotions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { usePromoBannerState } from '../usePromoBannerState'

/**
 * Get partner banner for displaying override in-place of site-wide promo banner.
 * This hook should not be used on partner pages since an up-to-date partner cookie won't
 * be available there. Those pages should use the useSetPartnerLogic hook and pass the partner
 * details directly.
 */
export const useFetchPartnerBanner = () => {
  const dispatch = useDispatch()
  const partnerName = getValueFromPartnerCookie('partnerName')
  const partnerGroup = getValueFromPartnerCookie('partnerGroup')

  const { isPartnerBanner } = usePromoBannerState()

  useEffect(() => {
    // Fetch partner banner to display in place of default site-wide promo banner if banner hasn't already been loaded
    !isPartnerBanner &&
      partnerName &&
      partnerGroup &&
      dispatch(IOPartnerBanner(partnerName, partnerGroup))
  }, [dispatch, partnerName, partnerGroup, isPartnerBanner])
}
