import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { Experience, mapExperiences } from '@ecomm/shared-ninetailed'
import classNames from 'classnames'
import React from 'react'

import type { ShopProductExperimentSchema } from '../../templates/Shop/shopPageSchema'
import ShopAccessoryCard from '../ShopAccessoryCard'

export type ShopAccesoryCardListProps = {
  readonly products: readonly ShopProductExperimentSchema[]
  readonly className?: string
}
export default function ShopAccesoryCardList({
  products,
  className = ''
}: ShopAccesoryCardListProps) {
  const microcopy = useMicroCopy()
  const isUs = useLocale() === 'en-US'

  return (
    <section
      className={classNames('flex flex-col items-center', className)}
      data-component="ShopAccesoryCardList"
    >
      <h2 className="text-center">
        {microcopy['shop-cameras-and-accessories']}
      </h2>
      <ul className="m-0 grid list-none grid-cols-12 gap-4 p-0 md:gap-8 lg:gap-16">
        {products.map(accessory =>
          isUs ? (
            <Experience
              {...accessory}
              component={ShopAccessoryCard}
              experiences={mapExperiences<ShopProductExperimentSchema>(
                accessory.nt_experiences
              )}
              id={accessory.id}
              key={`Shop-product-card-${accessory.name}`}
            />
          ) : (
            <ShopAccessoryCard
              key={`Shop-product-card-${accessory.name}`}
              {...accessory}
            />
          )
        )}
      </ul>
    </section>
  )
}
