import { GatsbyImage } from '@ecomm/shared-components'
import { HeaderPartner } from '@ecomm/ss-react-components'
import { safePath, safeProp } from '@simplisafe/monda'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { Maybe } from 'monet'
import type { ReactNode } from 'react'
import React from 'react'

import type { ContentfulHeaderPartner } from '../../../graphql'

export type HeaderPartnerComponentProps = {
  readonly data: ContentfulHeaderPartner
}

// This type refers to a ContentfulAsset. If its an image file type, the
// response sets fluid key to ContentfulFluid props. However, this value is null
// if its an SVG. Therefore, we have to use the file url on a regular img tag.
type ImageData = ContentfulHeaderPartner['logoPartner'] | null

const getAltTextFromImageData = (imgData: ImageData): string =>
  Maybe.fromNull(imgData).chain(safeProp('description')).getOrElse('')

// Outputs SVG as a regular img tag.
const toImg = (imgData: ImageData, logoDimensions?: string): ReactNode =>
  Maybe.fromNull(imgData)
    .chain(safePath(['file', 'url']))
    .cata(
      () => null,
      (fileUrl: string) => (
        <img
          alt={getAltTextFromImageData(imgData)}
          key={fileUrl}
          src={fileUrl}
          width={logoDimensions || '200'}
        />
      )
    )

const toGatsbyImage = (
  imgData: ImageData,
  position: string,
  logoDimensions?: string
): ReactNode =>
  Maybe.fromNull(imgData).cata(
    () => null,
    imgProps => {
      return (
        <GatsbyImage
          alt={getAltTextFromImageData(imgData)}
          // @ts-expect-error TS(2559) FIXME: Type 'IGatsbyImageData' has no properties in commo... Remove this comment to see the full error message
          image={getImage(imgProps)}
          imgStyle={{ objectPosition: position }}
          objectFit="contain"
          style={{
            height: logoDimensions || '100%',
            width: logoDimensions || '100%'
          }}
        />
      )
    }
  )

// Renders either a Gatsby <Img /> component, or a regular <img /> tag if src is svg.
const renderImage = (
  imgData: ImageData,
  position: string,
  logoDimensions?: string
): ReactNode => {
  const contentType = Maybe.fromNull(imgData)
    .chain(safePath(['file', 'contentType']))
    .getOrElse('')

  return contentType === 'image/svg+xml'
    ? toImg(imgData, logoDimensions)
    : toGatsbyImage(imgData, position, logoDimensions)
}

function HeaderPartnerComponent({ data }: HeaderPartnerComponentProps) {
  const {
    id,
    logoPartner,
    logoSimpliSafe,
    backgroundColor,
    mergerText,
    offerText,
    partnerLogoWidthAndHeight
  } = data

  return (
    <HeaderPartner
      backgroundColor={backgroundColor || ''}
      key={id}
      logoPartner={renderImage(logoPartner, 'left', partnerLogoWidthAndHeight)}
      logoSimpliSafe={renderImage(logoSimpliSafe, 'right')}
      mergerText={mergerText}
      offerText={offerText}
    />
  )
}

export default HeaderPartnerComponent

export const HeaderPartnerQuery = graphql`
  #graphql
  fragment headerPartner on ContentfulHeaderPartner {
    __typename
    id
    internal {
      type
    }
    logoPartner {
      description
      file {
        url
        contentType
      }
      gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: BLURRED)
      id
    }
    logoSimpliSafe {
      description
      file {
        url
        contentType
      }
      gatsbyImageData(layout: CONSTRAINED, width: 200, placeholder: BLURRED)
      id
    }
    backgroundColor
    partnerLogoWidthAndHeight
    mergerText
    offerText
  }
`
