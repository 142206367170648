import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Organization } from 'schema-dts'
import { match } from 'ts-pattern'

import { SEOProps } from './types'

/**
 * Inject SEO properties to <head> of html document.
 *
 * Open Graph SEO Types: https://ogp.me/#types
 * Twitter Cards: https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started

 * TODO: Extend to support pageSchema SEO Content
 */
export function SEO(props: SEOProps) {
  const {
    metaTitle,
    metaDescription,
    lang,
    canonicalLink,
    metaKeywords,
    isNoindex,
    isNofollow,
    isLandingPage
  } = props

  const isUsPage = lang === 'en-US'

  const canonicalLinkContainer = canonicalLink
    ? [
        {
          href: canonicalLink,
          rel: 'canonical'
        }
      ]
    : []

  const keywords = metaKeywords ? metaKeywords.join(',') : ''

  const metaRobot = match<readonly [boolean, boolean], string>([
    isNoindex,
    isNofollow
  ])
    .with([true, true], () => 'noindex, nofollow')
    .with([true, false], () => 'noindex')
    .with([false, true], () => 'nofollow')
    .with([false, false], () => '')
    .exhaustive()

  const metaData = [
    {
      content: metaDescription,
      name: 'description'
    },
    {
      content: metaTitle,
      property: 'og:title'
    },
    {
      content: metaDescription,
      property: 'og:description'
    },
    {
      content: 'website',
      property: 'og:type'
    },
    {
      content: 'summary',
      name: 'twitter:card'
    },
    {
      content: '@SimpliSafe',
      name: 'twitter:creator'
    },
    {
      content: metaTitle,
      name: 'twitter:title'
    },
    {
      content: metaDescription,
      name: 'twitter:description'
    },
    {
      ...(keywords && {
        content: keywords,
        name: 'keywords'
      })
    },
    {
      ...(metaRobot && {
        content: metaRobot,
        name: 'robots'
      })
    }
  ]

  const organizationSchema =
    isLandingPage === true
      ? [
          helmetJsonLdProp<Organization>({
            brand: {
              name: 'SimpliSafe',
              '@type': 'Brand'
            },
            '@context': 'https://schema.org',
            '@type': 'Organization',
            url: `https://simplisafe.${isUsPage ? 'com' : 'co.uk'}`,
            name: 'SimpliSafe',
            image:
              'images.ctfassets.net/br4ichkdqihc/2eyveVi5KryyProsvOnvwp/94238a4da46addd84f3d9906edfbc5be/SimplisafeLogo.png',
            sameAs: isUsPage
              ? [
                  'https://pinterest.com/simplisafe/',
                  'https://twitter.com/simplisafe',
                  'https://facebook.com/SimpliSafe',
                  'https://linkedin.com/company/simplisafe',
                  'https://www.youtube.com/user/simplisafe',
                  'https://apps.apple.com/us/app/simplisafe-home-security-app/id555798051',
                  'https://play.google.com/store/apps/details?id=com.simplisafe.mobile&hl=en_US&gl=US'
                ]
              : [
                  'https://pinterest.com/simplisafeuk/',
                  'https://twitter.com/simplisafe_uk',
                  'https://facebook.com/SimpliSafeUK',
                  'https://linkedin.com/company/simplisafe-uk',
                  'https://www.youtube.com/channel/UCwGgZULVml0IfSS26z1564g',
                  'https://apps.apple.com/gb/app/simplisafe-home-security-app/id555798051 ',
                  'https://play.google.com/store/apps/developer?id=SimpliSafe+Home+Security+Systems&hl=en_GB '
                ],
            legalName: isUsPage ? 'SimpliSafe, Inc.' : 'SimpliSafe, Ltd.',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: isUsPage ? '+1-888-910-1215' : '0800-920-2420',
              contactType: 'customer service'
            }
          })
        ]
      : []

  return (
    <Helmet
      htmlAttributes={{ lang }}
      link={canonicalLinkContainer}
      meta={metaData}
      script={[...organizationSchema]}
      title={metaTitle}
    />
  )
}
