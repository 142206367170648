import React from 'react'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { bannerClickEvent } from '../../../SmallTextSection'
import CartWidgetItem from '../CartWidgetItem'

export function AffirmCard(siteLocale: string) {
  const { trackEvent } = useTracking()
  return useCallback(
    () =>
      siteLocale === 'en-US' ? (
        <CartWidgetItem
          dataComponentName="AffirmCard"
          description={
            <p className="mb-0 text-center text-sm leading-normal">
              Pay for your SimpliSafe at your own pace. It&apos;s easy to sign
              up, and there&apos;s no late fees and no surprises. Select affirm
              at checkout today.
            </p>
          }
          heading={
            <h3 className="text-5 mb-3 mt-2 text-center">
              0% APR financing available
            </h3>
          }
          icon={
            <img
              alt="Affirm logo for the card content on the cart page"
              className={'mx-auto block h-auto w-[5.625rem]'}
              height="36"
              src="//images.ctfassets.net/br4ichkdqihc/79n6SshSFQkVCiBfVb7qgP/5ef77aedba32f3589036bed48eb66e63/affirm_cart.svg"
              width="90"
            />
          }
          onClick={() => {
            bannerClickEvent(trackEvent, 'Affirm card content')
          }}
          shouldTrackClicks
        />
      ) : null,
    [trackEvent, siteLocale]
  )
}
