import { useOdmonExperience } from '@ecomm/shared-ninetailed'

export const useBreadcrumbTitleODMONOverride = (
  slug: string,
  breadcrumbTitle: string | null | undefined
) => {
  return useOdmonExperience().isVariant &&
    slug === 'features-alarm-monitoring' &&
    breadcrumbTitle
    ? 'Professional Monitoring'
    : breadcrumbTitle
}
