import { type TypeOf, z } from '@simplisafe/ewok'

export const schema = z.object({
  phone: z.string(),
  texts: z.array(z.string()),
  cookie: z.string()
})

type Props = TypeOf<typeof schema>

function Footer({ phone, texts, cookie }: Props) {
  return (
    <div className="bg-neutral-black flex flex-col items-center py-8 text-lg font-extralight text-white">
      <b>Questions?</b>
      <span>
        Call us at{' '}
        <a
          className="text-primary-100 no-underline hover:underline"
          href={`tel:${phone}`}
        >
          {phone}
        </a>
      </span>
      {texts.map((text, idx) => (
        <span key={idx}>{text}</span>
      ))}
      <a
        className="ot-sdk-show-settings mt-2 cursor-pointer text-xs no-underline hover:underline"
        onClick={() => window?.OneTrust?.ToggleInfoDisplay()}
      >
        {cookie}
      </a>
    </div>
  )
}

export default Footer
