import { z } from '@simplisafe/ewok'

export const videoSchema = z.object({
  __typename: z.literal('ContentfulVideo'),
  entryTitle: z.string(),
  webmLink: z.string(),
  mp4Link: z.string(),
  posterImage: z.string().nullish(),
  captions: z.string().nullish()
})
