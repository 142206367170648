import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.Battery,
    text: 'Battery life up to 2 yrs'
  },
  {
    icon: SharedIcons.Wifi,
    text: 'Wireless'
  }
]

const description = {
  content: `Flood damage is the most common home insurance claim - and one of the most expensive. Early detection is critical. That's why the water sensor activates the instant it comes into contact with moisture. Place it in low-lying rooms like basements, or next to appliances like washers, to be alerted as soon as there's a leak.
  `
}

const specs = {
  content: `2 × 2 x 1 inches
  \n1 CR-2032 Lithium 3V battery (included)
  \nWater-resistant housing (no battery tab)
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '1oNTsNPbUJVa5EcrIRUDWN'
}

export const waterSensorData: ProductData = {
  productId: '27EWTII1qL6tyHG1cgrCpN',
  sensorName: 'Water Sensor',
  features,
  description,
  specs,
  faqs
}
