import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import { RouterProps, useLocation } from '@reach/router'
import { voidFn } from '@simplisafe/ewok'
import { localStorage } from '@simplisafe/ewok'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { fetchUserCheckoutDataWithRedirect } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { navigate } from 'gatsby'
import isNil from 'ramda/src/isNil'
import when from 'ramda/src/when'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { match } from 'ts-pattern'
import { StringParam, useQueryParams } from 'use-query-params'
import { v4 } from 'uuid'

import { AuthenticationSchema } from './schema'
import { AuthenticationType } from './types'
import { DEVICE_ID, interactiveUpgradeFlow, ss2UpgradeFlow } from './utils'

const { get, get: getLocalStorage, set: setLocalStorage } = localStorage

export type AuthenticationProps = {
  readonly language: Locale
  readonly children: ReactNode
  readonly type: AuthenticationType
}

const setDeviceId = () => {
  when(isNil, () => setLocalStorage('deviceId', v4()))(
    getLocalStorage('deviceId')
  )
}

export const getHrefWithoutSearch = (
  location: RouterProps['location']
): string => {
  const href = location?.href || ''
  const search = location?.search

  return search ? href.substring(0, href.indexOf(search)) : href
}

const localeMonitoringPlanMapping: Record<Locale, string> = {
  'en-GB': 'SSEDSM2_GB',
  'en-US': 'SSEDSM2'
}

const fromAppAuth = (
  locale: Locale,
  utmSource?: string | null,
  utmMedium?: string | null,
  planStr?: string | null
): boolean => {
  const plans: readonly string[] = planStr?.split(',') || []

  return !!(
    utmSource &&
    utmMedium &&
    plans.includes(localeMonitoringPlanMapping[locale])
  )
}

export default function AuthenticationComponent({
  children,
  language,
  type
}: AuthenticationProps) {
  const location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const href = getHrefWithoutSearch(location)

  const selectorLocale = useLocale()
  const locale = language || selectorLocale

  const [query] = useQueryParams({
    code: StringParam,
    plan: StringParam,
    state: StringParam,
    utm_medium: StringParam,
    utm_source: StringParam
  })

  const {
    code,
    state,
    utm_source: utmSource,
    utm_medium: utmMedium,
    plan
  } = query

  const fromCallback = code && state
  const isFromAppAuth = fromAppAuth(locale, utmSource, utmMedium, plan)

  const dispatch = useDispatch()

  const authenticate = useCallback(
    () =>
      fetchUserCheckoutDataWithRedirect(get(DEVICE_ID) || '', href)(logError)(
        () => null
      ),
    [href]
  )

  const authCallback = (type: AuthenticationSchema['type']) => {
    return match(type)
      .with(AuthenticationType.ss2, () => {
        ss2UpgradeFlow(href, locale, dispatch, setIsAuthenticated, () => {
          setIsAuthenticated(false)
          navigate('/alarm-sensors')
        })
      })
      .with(AuthenticationType.interactive, () => {
        interactiveUpgradeFlow(
          href,
          locale,
          dispatch,
          () => {
            setIsAuthenticated(true)
          },
          () => {
            setIsAuthenticated(false)
            navigate('/alarm-sensors')
          }
        )
      })
      .otherwise(voidFn)
  }

  useEffect(() => {
    setDeviceId()
    !fromCallback &&
      type === AuthenticationType.interactive &&
      isFromAppAuth &&
      authenticate()
    !fromCallback && type === AuthenticationType.ss2 && authenticate()
    fromCallback && authCallback(type)
  }, [])

  return isAuthenticated ? <div>{children}</div> : null
}
