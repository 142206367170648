import { useReady } from '@ecomm/shared-hooks'
import React from 'react'
import { Helmet } from 'react-helmet'

export type FacebookPostProps = {
  readonly appId?: string
  readonly sdkUrl?: string
}

const config = {
  numOfPosts: 5,
  orderBy: 'time',
  width: '100%'
}

export function FacebookCommentsBox({
  appId = '',
  sdkUrl = ''
}: FacebookPostProps) {
  //This was added to force the iframe to load after the component is rendered. This was causing hydration errors (#418 and #423)
  const isReady = useReady()

  return isReady && appId && sdkUrl ? (
    <>
      <Helmet>
        <meta content={appId} property="fb:app_id" />
        <script async crossOrigin="anonymous" defer src={sdkUrl} />
      </Helmet>
      <div className="facebook-container" data-testid="FacebookCommentsBox">
        <div id="fb-root"></div>
        <div className="mx-auto my-0">
          <div
            className="fb-comments"
            data-numposts={config.numOfPosts}
            data-order-by={config.orderBy}
            data-width={config.width}
          ></div>
        </div>
      </div>
    </>
  ) : null
}
