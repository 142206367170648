import { TypeOf, z } from '@simplisafe/ewok'

/**
 * This is a similar duplicate of apps/shop/src/components/ProductCard/schema.ts
 * only to be used for partner pages
 */
export const productCardInternalSchema = z.object({
  isNew: z.boolean(false)
})

export type ProductCardFragment = TypeOf<typeof productCardInternalSchema>
