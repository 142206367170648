//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import T from 'ramda/src/T'
import React, { FC, ReactElement, ReactNode } from 'react'

import Menu from '../Menu'
import { SiteColor } from '../types/colors'
import * as css from './FloatingBadge.module.scss'

export type FloatingBadgeProps = {
  readonly backgroundColor?: SiteColor
  readonly borderColor?: SiteColor
  /** @deprecated use floatingBadgeContent prop to pass a ReactNode instead of a Document */
  readonly children?: ReactNode
  readonly className?: string
  readonly linkUrl?: string
  readonly textColor?: SiteColor
  readonly dataComponent?: string
  /** The content of the component */
  readonly floatingBadgeContent?: ReactElement | ReactNode
}

/** @deprecated Do not use ss-react-components*/
const FloatingBadge: FC<FloatingBadgeProps> = ({
  backgroundColor,
  borderColor,
  children,
  className,
  linkUrl,
  textColor,
  dataComponent = FloatingBadge.name,
  floatingBadgeContent
}: FloatingBadgeProps) => {
  const hasFloatingBadgeContent = Boolean(floatingBadgeContent)
  const hasChildren = Boolean(children)

  const renderContent = () =>
    cond([
      [
        always(hasFloatingBadgeContent),
        always(
          <div className="neutralWhiteTextColor">{floatingBadgeContent}</div>
        )
      ],
      [always(hasChildren), always(children)],
      [T, always(null)]
    ])()

  return (
    // TODO FloatingBadge doesn't seem like something that should have a Menu in it
    <Menu className={className} dataComponent={dataComponent} url={linkUrl}>
      <div
        className={classNames(css.roundBadge, 'alignCenter', {
          [`${borderColor}BorderColor`]: !!borderColor,
          [css.hasBorder]: !!borderColor
        })}
      >
        <div
          className={classNames(css.roundbadgeBg, {
            [`${backgroundColor}BackgroundColor`]: !!backgroundColor,
            [`${textColor}TextColor`]: !!textColor
          })}
        >
          {renderContent()}
        </div>
      </div>
    </Menu>
  )
}

export default FloatingBadge
