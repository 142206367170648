import { DynamicDiscount } from '@simplisafe/ss-ecomm-data/prices/service'
import React from 'react'

import { useDynamicTextData } from '../../hooks/useDynamicTextData'
import { BannerSection } from './BannerSection'

export function SavingsBanner({
  dynamicDiscounts
}: {
  readonly dynamicDiscounts: readonly DynamicDiscount[]
}) {
  const bannerSectionData = useDynamicTextData(dynamicDiscounts)

  return (
    <div
      className="border-neutral-medium-100 col-span-12 rounded-xl border border-solid bg-white p-4 shadow-md md:mb-8"
      data-component="SavingsBanner"
    >
      <div className="flex w-full flex-wrap md:justify-evenly">
        {bannerSectionData.map((bannerSection, idx) => (
          <BannerSection
            data={bannerSection}
            key={idx}
            lastSection={!bannerSectionData[idx + 1]}
          />
        ))}
      </div>
    </div>
  )
}
