import { PromoBannerBaseTemplate } from '../../PromoBannerBaseTemplate'
import { LeadCaptureFormPropsOnSubmit } from '../../types'
import { ExpandedContent } from './ExpandedContent'
import { Header } from './Header'
import { SaleEndsTitle } from './SaleEndsTitle'

export interface MobileBannerBottomProps {
  readonly isVisible: boolean
  readonly toggleBannerVisibility: () => void
  readonly onCloseBanner: () => void
  readonly hasToggles: boolean
  readonly isFormSubmitted: boolean
  readonly onEmailSubmit: LeadCaptureFormPropsOnSubmit
}

export function MobileBannerBottom({
  isVisible,
  toggleBannerVisibility,
  onCloseBanner,
  hasToggles
}: MobileBannerBottomProps) {
  return (
    <PromoBannerBaseTemplate
      className="z-[15] bg-white"
      dataComponent="MobileBannerBottom"
      onClickOutside={onCloseBanner}
      position="bottom"
    >
      <Header
        hasToggles={hasToggles}
        isVisible={isVisible}
        toggleBannerVisibility={toggleBannerVisibility}
      >
        <SaleEndsTitle />
      </Header>
      {isVisible ? <ExpandedContent /> : null}
    </PromoBannerBaseTemplate>
  )
}
