import { leadGenSubscribe, leadGenUnsubscribe } from '@ecomm/data-leads'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useState } from 'react'

export default function useUnsubscribeEmail() {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<'' | 'error' | 'success'>('')

  const unsubscribeEmail = async (email: string) => {
    setStatus('')
    setIsLoading(true)
    pipe(
      await leadGenUnsubscribe({ email })(),
      E.match(
        (_: Error) => {
          setStatus('error')
          setIsLoading(false)
        },
        _ => {
          setStatus('success')
          setIsLoading(false)
        }
      )
    )
  }

  const resubscribeEmail = async (email: string) => {
    setStatus('')
    setIsLoading(true)
    pipe(
      await leadGenSubscribe({ email })(),
      E.match(
        (_: Error) => {
          setStatus('error')
          setIsLoading(false)
        },
        _ => {
          setStatus('success')
          setIsLoading(false)
        }
      )
    )
  }

  return {
    unsubscribeEmail,
    resubscribeEmail,
    status,
    isLoading
  }
}
