//@ts-nocheck
import React, { FC, ReactNode } from 'react'

import * as css from './EditPackageSensor.module.scss'

export type EditPackageSensorItemContainerProps = {
  readonly title: string
  readonly children?: ReactNode
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const EditPackageSensorItemContainer: FC<
  EditPackageSensorItemContainerProps
> = ({
  children,
  dataComponent,
  title
}: EditPackageSensorItemContainerProps) => {
  return (
    <div className={css.packageSensorContainer} data-component={dataComponent}>
      <h2>{title}</h2>
      {children}
    </div>
  )
}

export default EditPackageSensorItemContainer
