type Props = {
  readonly className?: string
}

export function WarningIcon({ className = '' }: Props) {
  return (
    <svg
      className={className}
      data-component="WarningIcon"
      fill="none"
      height="19"
      viewBox="0 0 18 19"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9 3.5C7.4087 3.5 5.88258 4.13214 4.75736 5.25736C3.63214 6.38258 3 7.9087 3 9.5C3 10.2879 3.15519 11.0681 3.45672 11.7961C3.75825 12.5241 4.20021 13.1855 4.75736 13.7426C5.31451 14.2998 5.97595 14.7417 6.7039 15.0433C7.43185 15.3448 8.21207 15.5 9 15.5C9.78793 15.5 10.5681 15.3448 11.2961 15.0433C12.0241 14.7417 12.6855 14.2998 13.2426 13.7426C13.7998 13.1855 14.2418 12.5241 14.5433 11.7961C14.8448 11.0681 15 10.2879 15 9.5C15 7.9087 14.3679 6.38258 13.2426 5.25736C12.1174 4.13214 10.5913 3.5 9 3.5ZM3.6967 4.1967C5.10322 2.79018 7.01088 2 9 2C10.9891 2 12.8968 2.79018 14.3033 4.1967C15.7098 5.60322 16.5 7.51088 16.5 9.5C16.5 10.4849 16.306 11.4602 15.9291 12.3701C15.5522 13.2801 14.9997 14.1069 14.3033 14.8033C13.6069 15.4997 12.7801 16.0522 11.8701 16.4291C10.9602 16.806 9.98491 17 9 17C8.01509 17 7.03982 16.806 6.12987 16.4291C5.21993 16.0522 4.39314 15.4997 3.6967 14.8033C3.00026 14.1069 2.44781 13.2801 2.0709 12.3701C1.69399 11.4602 1.5 10.4849 1.5 9.5C1.5 7.51088 2.29018 5.60322 3.6967 4.1967ZM9 5.75C9.41421 5.75 9.75 6.08579 9.75 6.5V9.5C9.75 9.91421 9.41421 10.25 9 10.25C8.58579 10.25 8.25 9.91421 8.25 9.5V6.5C8.25 6.08579 8.58579 5.75 9 5.75ZM8.25 12.5C8.25 12.0858 8.58579 11.75 9 11.75H9.0075C9.42171 11.75 9.7575 12.0858 9.7575 12.5C9.7575 12.9142 9.42171 13.25 9.0075 13.25H9C8.58579 13.25 8.25 12.9142 8.25 12.5Z"
        fill="#881B0F"
        fillRule="evenodd"
      />
    </svg>
  )
}
