import classNames from 'classnames'
import React from 'react'

type ProgressBarProps = {
  readonly className?: string
  readonly steps: readonly {
    readonly text: string
    readonly percentage: number
  }[]
  readonly currentStep: { readonly text: string; readonly percentage: number }
}

export default function ProgressBar({
  className = '',
  steps,
  currentStep
}: ProgressBarProps) {
  return (
    <div
      className={classNames(
        'flex w-full min-w-[20vw] max-w-[500px] flex-col text-xs font-light md:text-sm',
        className
      )}
      data-component={ProgressBar.name}
      data-testid={ProgressBar.name}
    >
      <div className="rounded-base bg-neutral-light-50 h-3 w-full">
        <div
          className="rounded-base bg-primary-100 h-full"
          style={{ width: `${currentStep.percentage}%` }}
        />
      </div>
      <ul className="m-0 mx-0 mb-0 flex list-none items-center justify-between gap-2 p-0 md:mt-1">
        {steps.map(title => (
          <li key={title.text}>{title.text}</li>
        ))}
      </ul>
    </div>
  )
}
