import {
  type AffirmMonthlyOptionProps,
  AffirmMonthlyOption
} from '@ecomm/shared-components'
import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import React from 'react'

import type {
  AffirmFeatureFlagDraftSchema,
  AffirmFeatureFlagSchema
} from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import DraftCartLineListComponent from '../DraftCart/DraftCartLineList'
import DraftCartStaticListComponent from '../DraftCart/DraftCartStaticList'
import type { KeyValueFragment, ProductFragment } from '../DraftCart/types'
import type { PackageFragment } from '../Package/schema'
import { PackageListItem as PackageListItemComponent } from '../PackageListItem'

function DraftCartLineList() {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <div
      data-component={
        isAffirmExperience
          ? 'AffirmDraftCartLineListVariant'
          : 'AffirmDraftCartLineListControl'
      }
    >
      <DraftCartLineListComponent isAffirmExperience={isAffirmExperience} />
    </div>
  )
}

interface DraftCartStaticListProps {
  readonly baseProducts: readonly ProductFragment[]
  readonly otherItems: readonly KeyValueFragment[]
}

function DraftCartStaticList({
  baseProducts,
  otherItems
}: DraftCartStaticListProps) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <div
      data-component={
        isAffirmExperience
          ? 'AffirmDraftCartLineListVariant'
          : 'AffirmDraftCartLineListControl'
      }
    >
      <DraftCartStaticListComponent
        baseProducts={baseProducts}
        isAffirmExperience={isAffirmExperience}
        otherItems={otherItems}
      />
    </div>
  )
}

interface PackageListItemProps {
  readonly item: PackageFragment
  readonly itemCount?: number
}

function PackageListItem({ item, itemCount }: PackageListItemProps) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <div
      data-component={
        isAffirmExperience ? 'PackageListItemVariant' : 'PackageListItemControl'
      }
    >
      <PackageListItemComponent
        isAffirmExperience={isAffirmExperience}
        item={item}
        itemCount={itemCount}
      />
    </div>
  )
}

type Props = AffirmFeatureFlagDraftSchema & AffirmMonthlyOptionProps

function MonthlyOption(props: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <div
      data-component={
        isAffirmExperience ? 'AffirmMonthlyVariant' : 'AffirmMonthlyControl'
      }
    >
      <AffirmMonthlyOption {...props} isAffirmExperience={isAffirmExperience} />
    </div>
  )
}

const AffirmWrapper = {
  DraftCartLineList,
  DraftCartStaticList,
  PackageListItem,
  MonthlyOption
}

export default AffirmWrapper
