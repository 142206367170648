// import { parseContentfulJson } from '@ecomm/micro-copy'
import { GatsbyImage, GatsbyImageSchema } from '@ecomm/shared-components'
import React from 'react'

export type HeroBannerData = {
  readonly title: string
  readonly text?: string
}

export type HeroBannerProps = HeroBannerData & {
  readonly image: GatsbyImageSchema
}

export function HeroBanner({ data }: { readonly data: HeroBannerProps }) {
  const { image, text, title } = data

  // Add a superscript to ® symbol
  const processedTitle = title.replace(
    /®/g,
    '<span class="align-super text-lg">®</span>'
  )

  return (
    <div className="-mx-4 -mt-8 md:-mx-8 lg:m-0" data-component="HeroBanner">
      <div className="relative mt-3 lg:m-auto lg:mt-0">
        <div className="absolute bottom-10 left-4 z-10 w-4/5 md:left-8 lg:bottom-12 lg:left-10">
          <h1
            className="m-0 text-[35px] font-bold leading-10 text-white lg:text-[65px] lg:leading-none"
            dangerouslySetInnerHTML={{ __html: processedTitle }}
            data-component="heroBannerTitle"
          />
          {text ? (
            <p className="m-0 mt-2 text-[18px] font-bold leading-[22px] text-white lg:mt-4 lg:text-2xl">
              {text}
            </p>
          ) : null}
        </div>
        <GatsbyImage
          className="min-h-[437px] lg:min-h-[320px] lg:rounded-2xl"
          image={image}
        />
      </div>
    </div>
  )
}
