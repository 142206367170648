//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import * as css from './SimpleFooter.module.scss'

export type SimpleFooterProps = {
  /** The content of the component */
  readonly content?: ReactNode
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const SimpleFooter: FC<SimpleFooterProps> = ({
  content,
  dataComponent = SimpleFooter.name
}: SimpleFooterProps) => {
  return (
    <div
      className={classNames(css.simpleFooter, css.footer)}
      data-component={dataComponent}
    >
      {content}
    </div>
  )
}

export default SimpleFooter
