import { useEffect, useState } from 'react'

import { fetchAtAtVisitorId, visitorIdAtAt } from '../atat'

/**
 * Polls VID value and returns it
 * @returns
 */
export function usePollVid() {
  const [vid, setVid] = useState<string | undefined>(visitorIdAtAt())
  useEffect(() => {
    !vid && fetchAtAtVisitorId().then(setVid).catch()
  }, [vid])

  return vid
}
