import { useCallback, useState } from 'react'

import { trackVideoEvents } from './videoEvents'

type EventProps = React.SyntheticEvent<HTMLVideoElement, Event>

const VIDEO_PROVIDER_HTML5 = 'generic html5 video player'
const INITIAL_PROGRESS_MARKER_VALUES: Record<string, boolean> = {
  0: false,
  25: false,
  50: false,
  75: false,
  100: false
}

export const getVideoTitle = (videoSrc: string) => {
  const urlPieces = videoSrc.split('/')
  const urlPath = urlPieces[urlPieces.length - 1]
  return decodeURIComponent(urlPath)
}

export const percentagePlayed = (e: EventProps) => {
  return Math.floor(
    (100 * e.currentTarget.currentTime) / e.currentTarget.duration
  )
}

export function useVideoTracking() {
  // An object where keys are 0, 25, 50, 75 and values are true/false depending if progress has been reached.
  const [progressMarkers, setProgressMarkers] = useState(
    INITIAL_PROGRESS_MARKER_VALUES
  )
  // Keeps track of the highest percentage marker reached by the user.
  const [greatestMarker, setGreatestMarker] = useState(0)

  const trackVideoComplete = useCallback((e: EventProps) => {
    trackVideoEvents(
      'complete',
      VIDEO_PROVIDER_HTML5,
      getVideoTitle(e.currentTarget.currentSrc)
    )
  }, [])

  const trackVideoPause = useCallback(
    (e: EventProps) => {
      percentagePlayed(e) < 100 &&
        trackVideoEvents(
          'pause',
          VIDEO_PROVIDER_HTML5,
          getVideoTitle(e.currentTarget.currentSrc)
        )
    },
    [greatestMarker]
  )

  const trackVideoPlay = useCallback((e: EventProps) => {
    trackVideoEvents(
      'play',
      VIDEO_PROVIDER_HTML5,
      getVideoTitle(e.currentTarget.currentSrc)
    )
  }, [])

  const trackVideoProgress = useCallback(
    (e: EventProps) => {
      const localGreatestMarker = Object.keys(progressMarkers)
        .map(Number)
        .reduce((acc, n) => {
          return percentagePlayed(e) >= n && n > acc ? n : acc
        }, greatestMarker)

      localGreatestMarker !== greatestMarker &&
        setGreatestMarker(localGreatestMarker)

      const fireProgressEvent = () => {
        setProgressMarkers(previousState => ({
          ...previousState,
          [localGreatestMarker]: true
        }))

        // Don't fire progress updates at 100% video completion.
        localGreatestMarker < 100 &&
          trackVideoEvents(
            'progress',
            VIDEO_PROVIDER_HTML5,
            getVideoTitle(e.currentTarget.currentSrc),
            localGreatestMarker
          )
      }

      localGreatestMarker &&
        !progressMarkers[localGreatestMarker] &&
        fireProgressEvent()
    },
    [greatestMarker, progressMarkers]
  )
  return {
    trackVideoComplete,
    trackVideoPause,
    trackVideoPlay,
    trackVideoProgress
  }
}
