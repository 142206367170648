import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export function useFooterIntersectionObserver() {
  const { ref, inView } = useInView({
    root: null,
    rootMargin: '-20px',
    threshold: 0.05
  })

  const [style, setStyle] = useState('')

  useEffect(
    () => setStyle(inView ? 'absolute mt-[5px] md:mt-0' : 'fixed top-[300px]'),
    [inView]
  )

  return {
    ref: ref,
    style: style
  }
}
