import { ProductHero, useScoutProductPageProductHeroData } from '@ecomm/scout'
import React from 'react'

import useRequestPrice from '../../../hooks/useRequestPrice'
import { pricePerUnit } from '../../../providers/PriceProvider/formatter'
import AddToCartButton from '../../AddToCartButton'

export function LegacyScoutProductPageHero() {
  const sku = 'CM006-01DWW'

  const { productSku, className, ...productHeroData } =
    useScoutProductPageProductHeroData()

  const { getFormattedPrice } = useRequestPrice(sku)
  const price = getFormattedPrice(pricePerUnit)

  const renderCta = (qty: number) => (
    <AddToCartButton data={{ productSku, className }} quantity={qty} />
  )

  return (
    <ProductHero atcButton={renderCta} data={productHeroData} price={price} />
  )
}
