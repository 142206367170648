import type React from 'react'
import { Item } from '../../404/Item'
import { recommendedItemsData } from './harcodedData'
import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'

interface Props {
  readonly openQuoteWizardModal: React.MouseEventHandler<HTMLButtonElement>
  readonly images: readonly GatsbyImageSchema[]
  readonly title: string
}

export function RecommendedItems({
  openQuoteWizardModal,
  images,
  title
}: Props) {
  return (
    <div data-component="RecommendedItems">
      <h2 className="mb-4 md:mb-0 text-center">{title}</h2>
      <div className="mx-4 mt-8 flex flex-col gap-12 md:mx-10 lg:mx-0 lg:flex-row">
        {recommendedItemsData(openQuoteWizardModal).map((item, index) => (
          <Item
            {...item}
            image={images[index]}
            key={item.title.toLowerCase().replaceAll(' ', '-')}
          />
        ))}
      </div>
    </div>
  )
}
