import { useEffect, useState, useRef } from 'react'

/**
 * Hook that checks if a component is visible in view port
 * @param ref reference to component
 * @param threshold how much of the component needs to be visible for visibility state to change
 * @returns
 * @author https://stackoverflow.com/a/67826055/19258694
 */
export function useIsOnScreen(
  ref: React.MutableRefObject<HTMLElement | null>,
  threshold = 0.5
) {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const observerRef = useRef<IntersectionObserver>()

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => setIsOnScreen(entry.isIntersecting),
      { threshold: threshold }
    )
  }, [threshold])

  useEffect(() => {
    ref.current && observerRef.current?.observe(ref.current)

    return () => {
      observerRef.current?.disconnect()
    }
  }, [ref])

  return isOnScreen
}
