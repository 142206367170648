import { getChatAppId } from '@ecomm/shared-cookies'
import { requestLiveChatAvailability } from '@simplisafe/ss-ecomm-data/thirdparty/livechat'
import { useEffect, useState } from 'react'

/**
 * @deprecated Use `useLiveChatAvailability` from `@ecomm/live-chat` instead
 */
export const useLiveChatAvailability = (chatIdProp: string) => {
  const [hasAvailableAgents, setHasAvailableAgents] = useState(false)
  const chatId = getChatAppId() || chatIdProp

  useEffect(() => {
    const handleFailure = () => setHasAvailableAgents(false)
    const handleSuccess = (response: boolean) =>
      response === true ? setHasAvailableAgents(true) : handleFailure()

    // Only make the request to sprinklr if Optimizely feature flag is enabled
    // and chat id is not an empty string.
    const shouldRequest = !!chatId
    shouldRequest &&
      requestLiveChatAvailability(chatId)(handleFailure)(handleSuccess)
  }, [chatId])

  return hasAvailableAgents ? true : false
}

export default useLiveChatAvailability
