import { usePriceContext } from '@ecomm/data-price'
import {
  GiftItemContainer,
  GiftItemDTO
} from '@simplisafe/ss-ecomm-data/prices/service'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyArray'
import { useSetAtom } from 'jotai'
import { useMemo } from 'react'

import { freeGiftItemAtom } from '../../atoms/draftCart/freeGiftAtom'

const giftMatchesSku = (
  sku: string,
  gift: readonly GiftItemDTO[]
): O.Option<GiftItemDTO> =>
  pipe(
    gift,
    // This is a partial match to handle cases like the free indoor camera that has a sku of SSCM2-INC instead of SSCM2
    A.findFirst(gift => !!gift.sku && gift.sku.includes(sku))
  )

export const useGiftItemsProductCard = (sku: string): GiftItemContainer => {
  const { getFreeGiftItems, getFreeGiftItemsWithServicePlan } =
    usePriceContext()
  const setFreeGiftItems = useSetAtom(freeGiftItemAtom)

  const withMonitoringGiftItems = pipe(
    getFreeGiftItemsWithServicePlan(sku),
    O.toNullable
  )

  const withoutMonitoringGiftItems = pipe(getFreeGiftItems(sku), O.toNullable)

  const matchingWithMonitoringGiftItem = pipe(
    O.fromNullable(withMonitoringGiftItems),
    O.chain(gift => giftMatchesSku(sku, gift)),
    O.toNullable
  )

  const matchingWithoutMonitoringGiftItem = pipe(
    O.fromNullable(withoutMonitoringGiftItems),
    O.chain(gift => giftMatchesSku(sku, gift)),
    O.toNullable
  )

  const freeGiftItems: GiftItemContainer = useMemo(() => {
    ;(withMonitoringGiftItems || withoutMonitoringGiftItems) &&
      setFreeGiftItems({
        withMonitoring: withMonitoringGiftItems,
        withoutMonitoring: withoutMonitoringGiftItems
      })

    return {
      withMonitoring: matchingWithMonitoringGiftItem,
      withoutMonitoring: matchingWithoutMonitoringGiftItem
    }
  }, [
    withMonitoringGiftItems,
    withoutMonitoringGiftItems,
    setFreeGiftItems,
    matchingWithMonitoringGiftItem,
    matchingWithoutMonitoringGiftItem
  ])

  return freeGiftItems
}
