//@ts-nocheck
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import React, { FC, ReactNode } from 'react'

import * as css from './SmallBannerItem.module.scss'

export type SmallBannerItemProps = {
  /** The icon that is displayed with text. */
  readonly icon: ReactNode
  /** A RichText ReactNode that gets displayed with the icon. */
  readonly text: ReactNode
  /** A Boolean For Identify DefalutBannerClass. */
  readonly isDefaultBanner?: boolean
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const SmallBannerItem: FC<SmallBannerItemProps> = ({
  icon,
  text,
  isDefaultBanner,
  dataComponent = SmallBannerItem.name
}: SmallBannerItemProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const defaultBannerclass = ifElse(
    equals(true),
    always(css.defaultBannerWrap),
    always(css.cartbannerHorizontal)
  )(isDefaultBanner)
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <div className={defaultBannerclass} data-component={dataComponent}>
      <div className={css.wrapper}>
        <div className={css.icon}>{icon}</div>
        <div className={css.content}>{text}</div>
      </div>
    </div>
  )
}

export default SmallBannerItem
