import { QueryOptions, useApolloClient } from '@apollo/client/index.js'
import { useLocale } from '@ecomm/data-hooks'
import { useIsOnScreen } from '@ecomm/shared-hooks'
import { voidFn } from '@simplisafe/ewok'
import { DocumentNode } from 'graphql'
import React, { useCallback, useEffect, useRef } from 'react'

import { Link } from './Link'
import { useWhichFramework } from './useWhichFramework'

type Props = {
  readonly queries: readonly {
    readonly query: DocumentNode
    readonly variables?: QueryOptions['variables']
  }[]
  readonly to: string
  readonly children: React.ReactNode
  readonly preloadOnScreen?: boolean
  readonly 'data-testid'?: string
}

export function PreloadLink({
  queries,
  to,
  children,
  preloadOnScreen = false
}: Props) {
  const ref = useRef(null)
  const isGatsby = useWhichFramework() === 'gatsby'
  // @ts-expect-error - we don't want a valid client if we're not on gatsby
  const client = useApolloClient(isGatsby ? null : { query: voidFn })
  const isOnScreen = useIsOnScreen(ref)
  const locale = useLocale()

  const handlePreload = useCallback(() => {
    if (isGatsby) {
      return queries.forEach(({ query, variables }) =>
        client.query({
          query,
          variables: {
            ...variables,
            preview: process.env['PUBLIC_CONTENTFUL_PREVIEW'] === 'true',
            locale
          }
        })
      )
    } else {
      return voidFn()
    }
  }, [isGatsby, queries, client, locale])

  useEffect(() => {
    // when the link is on screen we want to prefetch
    isOnScreen && preloadOnScreen && handlePreload()
  }, [handlePreload, isOnScreen, preloadOnScreen])

  return (
    <Link
      onMouseEnter={_ => handlePreload()}
      onTouchStart={_ => handlePreload()}
      to={to}
    >
      {isGatsby}
      {children}
    </Link>
  )
}
