import { useMicroCopy } from '@ecomm/micro-copy'
import { CheckMarkCircle } from '@ecomm/shared-icons'

export default function ResultMessage({
  status
}: {
  readonly status: '' | 'error' | 'success'
}) {
  const microcopy = useMicroCopy()

  return (
    <>
      {status === 'success' ? (
        <div
          className="rounded-base bg-neutral-light-50 col-span-2 flex items-center gap-4 px-6 py-8"
          data-component="SuccessMessage"
        >
          <CheckMarkCircle className="text-complementary-green-100 h-8 w-8 shrink-0" />
          <p className="mb-0">
            Thanks, the preferences for your email address have been updated. If
            you have any questions regarding your preferences, please contact
            the team at {microcopy['contact-number']} or email{' '}
            <a href={`mailto:${microcopy['contact-email']}`}>
              customer support.
            </a>
          </p>
        </div>
      ) : null}

      {status === 'error' ? (
        <p
          className="text-complementary-red-100 col-span-2 mb-0"
          data-component="ErrorMessage"
        >
          We’re unable to find that email address. Please check that it’s typed
          correctly and try again.
        </p>
      ) : null}
    </>
  )
}
