import React from 'react'

function CartAffirmCartMessage() {
  return (
    <div className="order-4 col-start-2 col-end-3 my-4 flex justify-end text-sm lg:flex">
      <div className="defaultTextColor">
        <p className="inline">Select</p>
        <img
          alt="Affirm"
          className="mx-1 max-w-full object-contain"
          height="19"
          src="//images.ctfassets.net/br4ichkdqihc/6tCzpY2HyllDEQJha2103K/b485b7bfa0e0a763875dd958f7af92f6/affirm_black.svg"
          width="47"
        />
        <p className="inline">at checkout to pay over time</p>
      </div>
    </div>
  )
}

export default CartAffirmCartMessage
