import { usePriceContext } from '@ecomm/data-price'
import { isAirlinePartner } from '@ecomm/partners-utils'
import { usePromoWithMonitoringDiscountText } from '@ecomm/promotions-hooks'
import { chainProp } from '@simplisafe/monda/chain'
import { selectCurrentPromoOverrideDiscountText } from '@simplisafe/ss-ecomm-data/redux/select'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useSelector } from 'react-redux'

export const useDiscountWithServicePlan = (sku?: string) => {
  const { getDiscountTextWithServicePlan, isLoading } = usePriceContext()
  const isAirlinePartnerCookie = isAirlinePartner()

  const overrideText = useSelector(
    selectCurrentPromoOverrideDiscountText
  ).chain(chainProp('toggleBox'))

  const hasOverrideText = overrideText.isSome()

  const discountText = O.getOrElse(() => '')(
    usePromoWithMonitoringDiscountText()
  )

  const realTimeDiscountText = pipe(
    getDiscountTextWithServicePlan(sku || ''),
    O.getOrElse(() => '')
  )

  const showRealTimeDiscount = !!realTimeDiscountText || isAirlinePartnerCookie

  const promoWithMonitoringDiscountText =
    sku && showRealTimeDiscount ? realTimeDiscountText : discountText

  const withMonitoringText = hasOverrideText
    ? overrideText.orJust('')
    : promoWithMonitoringDiscountText

  return { discountWithServicePlan: withMonitoringText, isLoading }
}
