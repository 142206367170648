import { getBraze } from '@ecomm/shared-window'
/**
 * Sets custom attributes on user's braze profile
 * @param attributesMapping
 * @returns true if all attributes were set, false otherwise
 */
export function brazeSetCustomUserAttributes(
  attributesMapping: Record<string, boolean | string> = {}
): boolean {
  return getBraze(braze => {
    return Object.keys(attributesMapping)
      .filter(key => attributesMapping[key] !== '')
      .map(key =>
        braze.getUser().setCustomUserAttribute(key, attributesMapping[key])
      )
      .reduce((prev, current) => {
        return prev && current
      }, true)
  })
}
