import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const systemBannerSchema = z.object({
  image: z.object({
    gatsbyImageData: gatsbyImageSchema.shape.gatsbyImageData,
    description: z.string(),
    title: z.string()
  }),
  quoteWizard: guidedSystemBuilderSchema,
  copy: z.object({
    contentful_id: z.string(),
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export type SystemBannerSchema = TypeOf<typeof systemBannerSchema>
