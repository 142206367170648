import React from 'react'

import { mapPageComponents } from './contentMapper'
import type { ContentMapperSchema } from './schema'

export default function ContentMapper({ inside }: ContentMapperSchema) {
  return (
    <div className="max-w-8xl mx-auto my-4 flex flex-col gap-10 px-4 md:my-8 md:gap-12 md:px-8 lg:my-10 lg:gap-16">
      {mapPageComponents(inside)}
    </div>
  )
}
