import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const confidenceBannerSchema = z.object({
  __typename: z.literal('ContentfulColoredSection'),
  headline: z.string(),
  content: z
    .object({
      text: contentfulRichTextSchema
    })
    .array(),
  disclaimer: contentfulRichTextSchema
})

export type ConfidenceBannerSchema = TypeOf<typeof confidenceBannerSchema>
