import classNames from 'classnames'
import clamp from 'ramda/src/clamp'
import React, { FC } from 'react'

type ProgressBarProps = {
  readonly className?: string
  readonly fillClassName?: string
  readonly innerLabelClassName?: string
  /** Percent of the progress bar to fill in. Must be between 0 and 100 */
  readonly percent: number
  /** data-component attribute value */
  readonly dataComponent?: string
  /** Optional text to include inside the progress bar */
  readonly innerLabel?: string
}

type Props = {
  readonly percent: number
  readonly children?: React.ReactNode
  readonly className?: string
}
const Fill: FC<Props> = ({ percent, children, className }: Props) => (
  <div
    className={className}
    style={{
      width: `${percent}%`
    }}
  >
    {children}
  </div>
)

const validatePercent = clamp(0, 100)
/** @deprecated Do not use ss-react-components*/
const ProgressBar: FC<ProgressBarProps> = ({
  className,
  fillClassName,
  percent,
  dataComponent = ProgressBar.name,
  innerLabel,
  innerLabelClassName
}: ProgressBarProps) => (
  <div
    className={classNames(
      'bg-neutral-light-50 h-3 w-full',
      className,
      innerLabel ? 'flex items-center justify-start' : ''
    )}
    data-component={dataComponent}
  >
    <Fill
      className={classNames('h-full', fillClassName, {
        'bg-primary-100': !fillClassName
      })}
      percent={validatePercent(percent)}
    ></Fill>

    {innerLabel ? (
      <span className={`absolute ${innerLabelClassName}`}>{innerLabel}</span>
    ) : null}
  </div>
)

export default ProgressBar
