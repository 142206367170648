import { LineItem } from '@simplisafe/ss-ecomm-data/cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

/**
 * Deep checkking: This is recollecting all skus from a system -- including childs items.
 */
export function useCartSkus() {
  const cart = useSelector(selectCart)
  return useMemo(
    () =>
      cart
        .map(_cart =>
          _cart.lineItems.reduce(
            (acc: ReadonlySet<string>, lineItem: LineItem) => {
              const skus = new Set(acc).add(lineItem.sku)

              lineItem.child?.forEach(item => skus.add(item.sku))

              return skus
            },
            new Set()
          )
        )
        .orJust(new Set()),
    [cart]
  )
}
