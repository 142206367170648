import classNames from 'classnames'

import { ReviewGridSchema } from '../../templates/Reviews/schema'
import ReviewCard from './ReviewCard'

export default function ReviewGrid({
  leftContent,
  rightContent
}: ReviewGridSchema) {
  const isLeftToRight = leftContent.length === 1 && rightContent.length > 1
  const isRightToLeft = rightContent.length === 1 && leftContent.length > 1

  return (
    <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 md:gap-x-4">
      <div
        className={classNames(
          'col-span-2 grid grid-cols-2 gap-4 md:order-none md:col-span-1',
          {
            'order-1 grid-rows-2': isLeftToRight,
            'order-2 grid-rows-2': isRightToLeft
          }
        )}
        data-component="grid-left-side"
      >
        {leftContent.map((card, i) => (
          <ReviewCard
            className={classNames({
              'col-span-2 row-span-2': isLeftToRight,
              'col-span-1 row-span-2 px-4 py-2 md:row-span-1': isRightToLeft,
              'px-4 py-2': !isLeftToRight && !isRightToLeft,
              'last:col-span-2': leftContent.length % 2 !== 0
            })}
            key={`review-left-card-${i}`}
            {...card}
          />
        ))}
      </div>
      <div
        className={classNames(
          'col-span-2 grid grid-cols-2 gap-4 md:order-none md:col-span-1',
          {
            'order-1 grid-rows-2': isRightToLeft,
            'order-2 grid-rows-2': isLeftToRight
          }
        )}
        data-component="grid-right-side"
      >
        {rightContent.map((card, i) => (
          <ReviewCard
            className={classNames({
              'col-span-2 row-span-2 p-0': isRightToLeft,
              'col-span-1 row-span-2 px-4 py-2 md:row-span-1': isLeftToRight,
              'px-4 py-2': !isLeftToRight && !isRightToLeft,
              'last:col-span-2': rightContent.length % 2 !== 0
            })}
            key={`review-right-card-${i}`}
            {...card}
          />
        ))}
      </div>
    </div>
  )
}
