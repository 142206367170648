import { addAtomDebugLabel } from '@ecomm/utils'
import { voidFn } from '@simplisafe/ewok'
import equal from 'fast-deep-equal'
import { atom } from 'jotai'

import { Action, shippingMethodReducer } from './shippingMethodReducer'
import { ShippingMethodState } from './shippingMethodStates'

const baseShippingMethodAtom = atom<ShippingMethodState>({
  _tag: 'with_items',
  val: []
})

export const shippingMethodAtom = atom(
  get => get(baseShippingMethodAtom),
  (get, set, update: Action) => {
    const prev = get(baseShippingMethodAtom)
    const next = shippingMethodReducer(prev, update)
    prev._tag === next._tag && equal(prev.val, next.val)
      ? voidFn()
      : set(baseShippingMethodAtom, next)
  }
)

addAtomDebugLabel(shippingMethodAtom, 'Shipping Methods')
