import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf } from '@simplisafe/ewok'
import { z } from 'zod'

import { priceToggleSchema } from '../DraftCart/schema'
import { productCardInternalSchema } from '../ProductCard/schema'
import { sensorGroupSchema } from '../SensorGroup/schema'

const packageType = z.enum(['Prebuilt', 'Refurbished', 'Dynamic', 'PLA'])

const disclaimerReferencesSchema = z.object({
  __typename: z.string(),
  contentful_id: z.string(),
  description: contentfulRichTextSchema,
  headline: z.string(),
  modalTrigger: z.object({ text: z.string() })
})

const packageProductSchema = z.object({
  quantity: z.number(),
  sku: z.string()
})

export const packageSchema = z.object({
  contentful_id: z.string(),
  description: contentfulRichTextSchema.nullish(),
  disclaimer: z
    .object({
      raw: z.string(),
      references: z.array(disclaimerReferencesSchema).optional()
    })
    .nullish(),
  displayName: z.string(),
  image: gatsbyImageSchema.nullable(),
  monitoringToggles: z.array(priceToggleSchema),
  packageProducts: z.array(packageProductSchema).optional(),
  personalizePackage: sensorGroupSchema.nullish(),
  proSetup: productCardInternalSchema.nullish(),
  product: z.string(),
  products: z.array(productCardInternalSchema),
  sku: z.string(),
  slug: z.string(),
  type: packageType,
  showPartnerPackageAbsoluteDiscountAsRelative: z
    .boolean()
    .nullable()
    .default(false)
})

export type PackageType = z.infer<typeof packageType>

type HideMap = {
  readonly [type in PackageType]: {
    readonly [hideKey: string]: boolean
  }
}

export const hideMap: HideMap = {
  Dynamic: {
    packageTitle: true
  },
  PLA: {},
  Prebuilt: {},
  Refurbished: {
    floorPlan: true,
    overrideText: true
  }
}

export type PackageProduct = TypeOf<typeof packageProductSchema>
export type PackageFragment = TypeOf<typeof packageSchema>
