import { Locale } from '../../hooks/useLocalizedData'
import {
  createMockRichHeadingText,
  createMockRichTextWithItalics,
  createMockRichTextWithLinks
} from '../../utils'

export const data = {
  [Locale.EnUS]: {
    title: createMockRichHeadingText('Frequently asked questions', 2),
    questions: [
      {
        longTitle: 'What is the SimpliSafe® return policy?',
        shortTitle: 'What is the SimpliSafe® return policy?',
        description: createMockRichTextWithItalics([
          'Your purchase from SimpliSafe.com comes with a 60 day money back guarantee (we’ll even pay return shipping).'
        ])
      },
      {
        longTitle: 'Will I be committed to a contract with SimpliSafe®?',
        shortTitle: 'Will I be committed to a contract with SimpliSafe®?',
        description: createMockRichTextWithItalics([
          'No. All SimpliSafe® monitoring plans bill monthly, and you can cancel at any time.'
        ])
      },
      {
        longTitle: 'Can I customize a package to suit my needs?',
        shortTitle: 'Can I customize a package to suit my needs?',
        description: createMockRichTextWithLinks(
          'Absolutely. You can customize a unique system [here](/build-my-system).'
        )
      },
      {
        longTitle: 'How does 24/7 Live monitoring work?',
        shortTitle: 'How does 24/7 Live monitoring work?',
        description: createMockRichTextWithLinks(
          'With 24/7 live guard protection, agents can use the Smart Alarm indoor camera to see, speak to and deter intruders in real time during an alarm. Agents can only access your camera’s microphone and video feed during an active alarm and only if you opt in to this service. To learn more about 24/7 Live Guard Protection, visit our [monitoring page](/features-alarm-monitoring).'
        )
      },
      {
        longTitle: 'What support options do I have?',
        shortTitle: 'What support options do I have?',
        description: createMockRichTextWithLinks(
          'SimpliSafe offers Live Chat and Phone support every day, 8:00am - Midnight, ET. You can also utilize our comprehensive help center 24/7 to get immediate answers to your questions & learn how to troubleshoot your system. Visit our [contact us](/contact-us) page to see all support options.'
        )
      }
    ]
  }
}
