import { ODMON_247_MONITORING, ODMON_OVERNIGHT_MONITORING } from '.'

export const FREE_CABLES_NAME = 'Outdoor Camera Power Cable'

export const MONITORING_PLAN_SKUS = [
  ODMON_OVERNIGHT_MONITORING,
  ODMON_247_MONITORING
]

export const ODMON_DISCOUNT_CODE = 'SIMPLI60OUTDOORPRO'
