//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import isNil from 'ramda/src/isNil'
import when from 'ramda/src/when'
import React, { FC, useCallback, useEffect, useState } from 'react'

import * as css from './QuantityChanger.module.scss'

export type QuantityChangerProps = {
  readonly className?: string
  readonly id?: string
  readonly label?: string
  readonly defaultValue?: number
  readonly max?: number
  readonly min?: number
  readonly disabled?: boolean
  readonly onChange?: (quantity: number, actionType?: string) => void
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const QuantityChanger: FC<QuantityChangerProps> = ({
  defaultValue,
  max,
  onChange,
  className,
  id,
  label,
  min = 1,
  disabled = false,
  dataComponent = QuantityChanger.name
}: QuantityChangerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const defaultQuantity = when(isNil, always(min))(defaultValue)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [quantity, setQuantity] = useState(defaultQuantity)
  const minDisabled = disabled || quantity <= min
  const maxDisabled = (!isNil(max) && quantity >= max) || disabled
  const incrementQuantity = useCallback(() => {
    !maxDisabled && setQuantity((q: number) => q + 1)
    /* Action type 'add' for identifying the quantity is increment */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    onChange && onChange(quantity + 1, 'add')
  }, [setQuantity, quantity, onChange, maxDisabled])
  const decrementQuantity = useCallback(() => {
    !minDisabled && setQuantity((q: number) => q - 1)
    /* Action type 'remove' for identifying the quantity is decremented */
    onChange && onChange(quantity - 1, 'remove')
  }, [setQuantity, quantity, onChange, minDisabled])
  useEffect(() => {
    setQuantity(defaultQuantity)
  }, [defaultQuantity])
  return (
    <div
      aria-label={label}
      className={classNames(css.quantityChanger, className)}
      data-component={dataComponent}
      id={id}
    >
      <div className={classNames(css.quantityBtn)}>
        <button
          aria-label={'Click here to decrease the quantity'}
          className={classNames(css.quantityleftMinus, 'rounded-md')}
          disabled={minDisabled}
          onClick={decrementQuantity}
          type={'button'}
        >
          <span>-</span>
        </button>
      </div>
      <span className={classNames(css.quantityValue)}>{quantity}</span>
      <div className={classNames(css.quantityBtn)}>
        <button
          aria-label={'Click here to increase the quantity'}
          className={classNames(css.quantityrightPlus, 'rounded-md')}
          disabled={maxDisabled}
          onClick={incrementQuantity}
          type={'button'}
        >
          <span>+</span>
        </button>
      </div>
    </div>
  )
}

export default QuantityChanger
