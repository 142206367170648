//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode, useCallback } from 'react'

import * as css from './HowItWorksNav.module.scss'

export type HowItWorksMenuItemProps = {
  readonly icon: ImageBitmapSource | ReactNode
  readonly id: string
  readonly label: string
}

export type HowItWorksNavProps = {
  /** Show the selected item */
  readonly activeMenuItem?: string
  readonly menuItems: readonly HowItWorksMenuItemProps[]
  readonly onMenuItemChange?: (id: string) => void
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const HowItWorksNav: FC<HowItWorksNavProps> = ({
  activeMenuItem,
  menuItems,
  onMenuItemChange,
  dataComponent = HowItWorksNav.name
}: HowItWorksNavProps) => {
  const handleMenuItemClick = useCallback(
    (menuItemId: string) => {
      onMenuItemChange && onMenuItemChange(menuItemId)
    },
    [onMenuItemChange]
  )

  return (
    <nav className={css.nav} data-component={dataComponent}>
      {menuItems.map((menuItem: HowItWorksMenuItemProps, x: number) => {
        return (
          <button
            className={classNames(css.menuItem, {
              [css.menuItemIsActive]: activeMenuItem === menuItem.id
            })}
            id={menuItem.id}
            key={`${menuItem.id}-${x}`}
            onClick={() => handleMenuItemClick(menuItem.id)}
          >
            <span className={css.menuItemIcon}>
              <span className={css.menuItemIconWrapper}>{menuItem.icon}</span>
            </span>
            <span className={css.menuItemLabel}>{menuItem.label}</span>
          </button>
        )
      })}
    </nav>
  )
}

export default HowItWorksNav
