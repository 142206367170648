import { contentfulImageSchema } from '@ecomm/contentful/components'
import { TypeOf, z } from '@simplisafe/ewok'

export const embeddedPartnerHeader = z.object({
  __typename: z.literal('ContentfulEmbeddedPartnerHeader'),
  offerText: z.string().optional(),
  backgroundColor: z.string().optional(),
  showPlusSign: z.boolean(),
  partnerLogo: contentfulImageSchema.nullish(),
  partnerLogoHeight: z.number()
})

export type EmbeddedPartnerHeaderSchema = TypeOf<typeof embeddedPartnerHeader>
