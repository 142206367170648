import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { TypeOf } from '@simplisafe/ewok'
import z from 'zod'

const guidedSystemBuilderSchemaDraft = z.object({
  __typename: z.literal('ContentfulQuoteWizard').optional(),
  id: z.string(),
  contentful_id: z.string().optional(),
  jebbitUrl: z.string().optional(),
  type: z.enum([
    'embedded',
    'floating',
    'guarantee',
    'hero',
    'monitoring_component',
    'popup',
    'nav'
  ]),
  buttonText: z.string().nullish()
})

const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(guidedSystemBuilderSchemaDraft).default([])
})

export const guidedSystemBuilderSchema = guidedSystemBuilderSchemaDraft.extend({
  nt_experiences: z.array(experimentSchema).default([])
})

export type GuidedSystemBuilderSchema = TypeOf<typeof guidedSystemBuilderSchema>
