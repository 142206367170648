import { useTrackingAlarmMoments } from '@ecomm/tracking'
import { useCallback } from 'react'

export function useOnSelectAlarmMoment() {
  const trackAlarmMoments = useTrackingAlarmMoments()

  return useCallback(
    (carouselTab: number) => {
      trackAlarmMoments(carouselTab)
    },
    [trackAlarmMoments]
  )
}
