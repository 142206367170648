import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './monitoringPageData'
import { schema } from './schema'

export const useMonitoringPageConfidenceBar = () => {
  const { listItems } = useLocalizedData<typeof schema>(schema, data)

  return useMemo(() => ({ listItems }), [listItems])
}
