import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export const useTrackingCardShopNow = (label?: string) => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    label &&
      trackEvent({
        event: 'shop-now',
        label
      })
  }, [trackEvent, label])
}
