type Props = {
  readonly text: string
  readonly classes?: string
}

// Component to add a superscript to ® symbol and avoid using `dangerouslySetInnerHTML`
export function TextWithSup({ text, classes = '' }: Props) {
  const textParts = text.split(/(®)/)

  return (
    <>
      {textParts.map((part, index) =>
        part === '®' ? (
          <sup className={classes} key={index}>
            ®
          </sup>
        ) : (
          part
        )
      )}
    </>
  )
}
