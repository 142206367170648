import {
  type Options,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import type { Json, Links } from '@ecomm/contentful-schemas'

import ContentfulImage from './ContentfulImage'
import {
  type CreateOptions,
  getNodeTargetId,
  getTargetAssetBlock
} from './richTextUtils'

const defaultOptions: CreateOptions = (links: Links): Options['renderNode'] => {
  return {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const target = getTargetAssetBlock(links, getNodeTargetId(node))
      return target ? <ContentfulImage {...target} /> : null
    }
  }
}

type Props = {
  readonly json: Json
  readonly links?: Links
  readonly options?: CreateOptions
}

export function ApolloRichText({
  links = {},
  json,
  options = () => ({})
}: Props) {
  return documentToReactComponents(json, {
    renderNode: {
      ...defaultOptions(links),
      ...options(links)
    }
  })
}
