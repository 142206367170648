import { featureSectionSchema } from '@ecomm/shared-sections'
import { addVariationSchema } from '@ecomm/utils'
import { TypeOf, z } from '@simplisafe/ewok'

import { layoutNoHeaderSchema } from '../../assemblies/Layout/layoutSchema'
import { packageSchema } from '../../components/Package/schema'

const plpPageSchema = z.object({
  contentful_id: z.string(),
  layout: layoutNoHeaderSchema.extend({
    components: z.array(featureSectionSchema).optional()
  }),
  packages: z.array(packageSchema),
  slug: z.string(),
  type: z.enum(['Standard', 'Refurbished']).optional(),
  title: z.string(),
  breadcrumbTitle: z.string().optional()
})

export const plpPageLayout = addVariationSchema(
  layoutNoHeaderSchema.extend({
    components: z.array(featureSectionSchema).optional()
  })
)

export type PlpPageSchema = TypeOf<typeof plpPageSchema>

export default plpPageSchema
