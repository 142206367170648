import { ContentfulRichText } from '@ecomm/shared-components'
import React from 'react'

import { ProductDetailsSchema } from './schema'

export function ProductDetails({
  data
}: {
  readonly data: ProductDetailsSchema
}) {
  const { columnLeft, columnRight } = data

  return (
    <div
      className="bg-neutral-light-50 flex flex-col justify-center rounded-xl p-5 md:flex-row md:gap-16"
      data-component="ScoutProductDetails"
    >
      <div>
        <ContentfulRichText raw={columnLeft.raw} />
      </div>
      <div>
        <ContentfulRichText raw={columnRight.raw} />
      </div>
    </div>
  )
}
