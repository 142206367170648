import {
  COOKIE_QUIZ_BREAK_INS,
  COOKIE_QUIZ_PACKAGE_THEFT,
  COOKIE_SHOWED_ODM_INTENT,
  getCookie
} from '@ecomm/shared-cookies'

export const getShowedOdmonIntentCookie = () =>
  getCookie(COOKIE_SHOWED_ODM_INTENT) === 'true'

export const getQuizBreakInsCookie = () =>
  getCookie(COOKIE_QUIZ_BREAK_INS) === 'true'

export const getQuizPackageTheftCookie = () =>
  getCookie(COOKIE_QUIZ_PACKAGE_THEFT) === 'true'
