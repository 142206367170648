import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { useDisplayMonitoringDiscount } from '../useDisplayMonitoringDiscount'
import { usePromoDiscountText } from '../usePromoDiscountText'
import { usePromoWithMonitoringDiscountText } from '../usePromoWithMonitoringDiscountText'

const regexMonitoringDiscount = /(?:{{monitoring_discount}})/g

/**
 * Will replace any {{monitoring_discount}} occurrences in text with the usePromoWithMonitoringDiscountText
 * return value if displayMonitoringDiscount is true. Otherwise it will use usePromoDiscountText return value
 * and if that resolves to None, replace them with defaultsTo
 *
 * @param text        string
 * @param defaultsTo  string
 * @returns           string
 */
export const usePlaceholderForMonitoringDiscount = (
  text: string,
  defaultsTo = ''
) => {
  const displayMonitoringDiscount = useDisplayMonitoringDiscount()
  const discountWithMonitoringText = usePromoWithMonitoringDiscountText()
  const discountText = usePromoDiscountText()

  const placeholderText = displayMonitoringDiscount
    ? discountWithMonitoringText
    : discountText

  return text.replace(
    regexMonitoringDiscount,
    pipe(
      placeholderText,
      O.getOrElse(() => defaultsTo)
    )
  )
}
