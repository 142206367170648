import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulImageQuerySchema } from '../../../schemas/image'

const packageCardFinancingSchema = z.object({
  __typename: z.literal('ContentfulPackage'),
  displayName: z.string(),
  slug: z.string(),
  image: contentfulImageQuerySchema,
  product: z.string(),
  products: z
    .object({
      __typename: z.literal('ContentfulProduct')
    })
    .array()
    .nullish()
})

export const packageCardFinancingGridSchema = z.object({
  __typename: z.literal('ContentfulGrid'),
  gridItems: packageCardFinancingSchema.array()
})

export type PackageCardFinancingGridSchema = TypeOf<
  typeof packageCardFinancingGridSchema
>
export type PackageCardFinancingSchema = TypeOf<
  typeof packageCardFinancingSchema
>
