import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { guidedSystemBuilderSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const quoteWizardEmbeddedSchema = z.object({
  quoteWizard: guidedSystemBuilderSchema,
  quoteWizardAsset: gatsbyImageSchema
})

export type QuoteWizardEmbedded = TypeOf<typeof quoteWizardEmbeddedSchema>
