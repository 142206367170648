export const set = (key: string, value: string): void => {
  typeof window !== 'undefined' &&
    window.sessionStorage &&
    window.sessionStorage.setItem(key, value)
}

export const get = (key: string): string => {
  return (
    (typeof window !== 'undefined' &&
      window.sessionStorage &&
      window.sessionStorage.getItem(key)) ||
    ''
  )
}

export const remove = (key: string): void => {
  typeof window !== 'undefined' &&
    window.sessionStorage &&
    window.sessionStorage.removeItem(key)
}
