import {
  AFFIRM_FINANCING_PROGRAM,
  AFFIRM_FINANCING_PROGRAM_VARIANT,
  AFFIRM_NO_SYSTEM_FINANCING_PROGRAM
} from '@ecomm/data-constants'
import { overloadMaybe, prop } from '@simplisafe/ewok'
import type { RemoteData } from '@simplisafe/ss-ecomm-data/RemoteData'
import type { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export const getAffirmFinancingProgram = (
  cart: RemoteData<ImmutableCart>,
  isAffirmExperience = false
) => {
  const systemPromoId = isAffirmExperience
    ? AFFIRM_FINANCING_PROGRAM_VARIANT
    : AFFIRM_FINANCING_PROGRAM

  return pipe(
    O.fromNullable(cart),
    O.chain(remoteDataCart => overloadMaybe(remoteDataCart.toMaybe())),
    O.chain(_cart => O.fromNullable(prop('isThereAnySecurity', _cart))),
    O.chain(cartHasSystem =>
      O.fromNullable(
        cartHasSystem ? systemPromoId : AFFIRM_NO_SYSTEM_FINANCING_PROGRAM
      )
    ),
    O.getOrElse(() => '')
  )
}
