import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData } from '../types/tracking'

export function useTrackingMonitoringMeetTheSystem() {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(() => {
    trackEvent({
      event: 'meet-the-system'
    })
  }, [trackEvent])
}
