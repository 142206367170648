import { useLocale } from '@ecomm/data-hooks'
import { useTracking } from 'react-tracking'

import { DiscountProps } from '../../types'
import { CartClosedCoupon } from './CartClosedCoupon'
import { CartOpenedCoupon } from './CartOpenedCoupon'

type Props = {
  readonly discountProps: DiscountProps
  readonly cartCouponCode: string
}

export function CartApplyCoupon({ discountProps, cartCouponCode }: Props) {
  const locale = useLocale()
  const { Track } = useTracking()

  const handleOpenForm = () => discountProps.setIsClose(false)

  return (
    <Track>
      <div className="block">
        {locale === 'en-GB' && (
          <div
            className="grid-cols-auto mb-2 mt-4 text-sm font-medium text-complementary-blue-100 no-underline md:text-lg [&_a]:text-complementary-blue-100"
            id="mmWrapper"
          ></div>
        )}
        <div>
          {discountProps.isClose ? (
            <CartClosedCoupon
              cartCouponCode={cartCouponCode}
              handleOpenForm={handleOpenForm}
            />
          ) : (
            <CartOpenedCoupon
              cartCouponCode={cartCouponCode}
              handleApplyCoupon={discountProps.handleApplyCoupon}
              handleCouponChange={discountProps.handleCouponChange}
              isClose={discountProps.isClose}
              isCouponError={discountProps.isCouponError}
              isCouponSuccess={discountProps.isCouponSuccess}
              promoCode={discountProps.promoCode}
              setIsClose={discountProps.setIsClose}
            />
          )}
        </div>
      </div>
    </Track>
  )
}
