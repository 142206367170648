import { PriceProvider } from '@ecomm/data-price'
import { ContentfulRichText } from '@ecomm/shared-components'
import { FeatureSection } from '@ecomm/shared-sections'
import { match } from 'ts-pattern'

import { ContentCollectionSchema } from '../../templates/BusinessSecurity/schema'
import QuoteWizardEmbedded from '../common/QuoteWizardEmbedded'
import PackageGrid from './PackageGrid'
import TwoColumn from './TwoColumn'

export default function Collection({ content }: ContentCollectionSchema) {
  return content.map((component, i) =>
    match(component)
      .with({ __typename: 'ContentfulTwoColumnSection' }, data => (
        <TwoColumn {...data} key={`two-column-${i}`} />
      ))
      .with({ __typename: 'ContentfulSmallText' }, data => (
        <div className="-mb-6 md:-mb-8 lg:-mb-12">
          <ContentfulRichText {...data.text} key={`small-text-${i}`} />
        </div>
      ))
      .with({ __typename: 'ContentfulFeatureSection' }, data => (
        <FeatureSection {...data} key={`feature-section-${i}`} />
      ))
      .with({ __typename: 'ContentfulGrid' }, data => (
        <PriceProvider locale="en-US" skus={data.gridItems.map(i => i.sku)}>
          <PackageGrid {...data} key={`package-grid-${i}`} />
        </PriceProvider>
      ))
      .with({ __typename: 'ContentfulLayoutReference' }, _ => (
        <QuoteWizardEmbedded key={`layout-reference-${i}`} />
      ))
      .otherwise(() => null)
  )
}
