import { COOKIE_PREACTIVATION, setCookie } from '@ecomm/shared-cookies'
import { OptimizelyEvent, TrackingData } from '@ecomm/tracking'
import { CreateOrderV1Response as CreateOrderResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import { cookiesOption as yodaCookieOptions } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import * as E from 'fp-ts/lib/Either'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'

import { getCookieDomain } from './common'

const preactivationCookieSettings = () => ({
  ...yodaCookieOptions,
  ...{
    domain: getCookieDomain(),
    // Override default behavior so value is not encoded. However, the service
    // consuming this value should decode it.
    encode: (str: string) => str
  }
})

export const handlePreactivationEvents =
  (trackEvent: (_data: TrackingData) => void) =>
  (optimizelyTrackSiteEvents: (_data: OptimizelyEvent) => void) => {
    optimizelyTrackSiteEvents({ eventType: 'auto_activation' })
    trackEvent({ event: 'userInAutoActivation' })
  }

export const handlePreactivationCookie = (value: string) => {
  // The cookie has characters that have been escaped when saving from API to
  // hidden input value. We need to decode any escaped characters so the
  // cookie is back in its raw value.
  setCookie(COOKIE_PREACTIVATION, value, preactivationCookieSettings())
}

export const checkPreActivationEligibilityStatus = (
  orderResponse: CreateOrderResponse
): E.Either<Error, CreateOrderResponse> => {
  return orderResponse.preActivationEligibility === 'eligible'
    ? E.right(orderResponse)
    : E.left(
        Error(
          'createOrder: initPreactivationFlow - order is ineligible for preactivation'
        )
      )
}

export const validateWebAppToken = (
  orderResponse: CreateOrderResponse
): E.Either<Error, CreateOrderResponse> => {
  return checkPreActivationToken(orderResponse)
    ? E.right(orderResponse)
    : E.left(
        Error(
          'createOrder: initPreactivationFlow - missing webappToken on eligible preactivation order'
        )
      )
}

const checkPreActivationToken = (
  orderResponse: CreateOrderResponse
): boolean => {
  const isNewUser = orderResponse.custom.fields.uidCreated
  const hasToken =
    !isNil(orderResponse.webappToken) && !isEmpty(orderResponse.webappToken)

  return (isNewUser && hasToken) || !isNewUser
}
