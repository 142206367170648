import { SHIELD_SKU } from '@ecomm/data-constants'
import { useAtom } from 'jotai'

import { itemQuantityAtom } from '../../../atoms/draftCart/itemQuantityAtom'

const useExistsOutdoorCameraInPkg = () => {
  const [itemMap] = useAtom(itemQuantityAtom)

  const skus = itemMap.keySeq().toArray()
  return skus.findIndex(sku => sku === SHIELD_SKU) !== -1
}

export default useExistsOutdoorCameraInPkg
