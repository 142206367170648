import {
  CartUpdatedMessage,
  SSButton,
  SSInput
} from '@ecomm/ss-react-components'
import classNames from 'classnames'
import React, { useEffect } from 'react'

import { DiscountProps } from '../../../types'

type Props = DiscountProps & { readonly cartCouponCode: string }

export function CartOpenedCoupon({
  cartCouponCode,
  handleCouponChange,
  promoCode,
  isCouponError,
  isCouponSuccess,
  handleApplyCoupon
}: Props) {
  // If the cart coupon code is empty, clear the input field
  useEffect(() => {
    cartCouponCode === '' &&
      !isCouponError &&
      !isCouponSuccess &&
      handleCouponChange('')
  }, [cartCouponCode])

  return (
    <>
      <div
        className={`flex w-full max-w-[340px] flex-col md:mt-5 md:w-1/2 ${
          promoCode ? '' : 'mt-4'
        } ${isCouponError || isCouponSuccess ? '' : 'mb-2'}`}
      >
        <p className="mb-3 mt-0 font-bold">Coupon Code</p>
        <div className="flex">
          <SSInput
            className={classNames('mr-2', {
              'text-complementary-red-100 border-2 border-solid border-[#C84A3B]':
                isCouponError
            })}
            error={isCouponError}
            id="couponCode"
            label=""
            name="couponCode"
            onChange={e => handleCouponChange(e.target.value)}
            placeholder={
              cartCouponCode === '' ? 'Add coupon code' : 'Change code'
            }
            type="text"
            value={promoCode}
          ></SSInput>
          <SSButton className="w-3/12" onClick={handleApplyCoupon}>
            apply
          </SSButton>
        </div>
      </div>
      <div>
        {isCouponError ? (
          <div className="text-complementary-red-100 mt-1 flex max-w-[340px] gap-2.5">
            this coupon code is invalid or has expired
          </div>
        ) : (
          <CartUpdatedMessage
            className="mt-1 flex max-w-[340px] gap-2.5"
            duration={5000}
            isVisible={promoCode ? isCouponSuccess : null}
            message="Coupon code applied"
          />
        )}
      </div>
    </>
  )
}
