import { FormSection } from '@ecomm/checkout/components'
import {
  useIsPayPalExperienceEnabled,
  useTrackPurchaseComplete
} from '@ecomm/checkout/payment-hooks'
import { getBillingAddress } from '@ecomm/data-cart'
import { AFFIRM_MIN_PRICE } from '@ecomm/data-constants'
import { useLocale } from '@ecomm/data-hooks'
import { useMicroCopy } from '@ecomm/micro-copy'
import { useSendMemberData } from '@ecomm/partners-hooks'
import { AffirmInformationLogoIcon, AffirmLogo } from '@ecomm/shared-components'
import {
  CCAmex,
  CCDiscover,
  CCMaster,
  CCVisa,
  PaypalLogo
} from '@ecomm/shared-icons'
import {
  type TrackingData,
  useTrackingAffirmInformationIcon,
  useTrackPaypal
} from '@ecomm/tracking'
import { convertMonetMaybe, prop, propOr } from '@simplisafe/ewok'
import {
  selectCart,
  selectLocale
} from '@simplisafe/ss-ecomm-data/redux/select'
import { window } from 'browser-monads-ts'
import classNames from 'classnames'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import type React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTracking } from 'react-tracking'

import { AffirmCheckoutContent } from '../AffirmCheckoutContent/AffirmCheckoutContent'
import InputRadio from '../InputRadio'
import { PaymentErrorMessage } from '../PaymentErrorMessage/PaymentErrorMessage'
import { PaymentFormBanner } from '../PaymentFormBanner/PaymentFormBanner'
import { PaypalCheckoutComplete } from '../PaypalCheckoutContent/PaypalCheckoutComplete'
import { PaypalCheckoutContent } from '../PaypalCheckoutContent/PaypalCheckoutContent'
import { ShippingCard } from '../ShippingCard/ShippingCard'
import { TermsOfSale } from '../TermsOfSale/TermsOfSale'
import { ZuoraPaymentForm } from '../ZuoraPaymentForm/ZuoraPaymentForm'
import { ZuoraSubmitButton } from '../ZuoraSubmitButton/ZuoraSubmitButton'
import type { PaymentFormWrapperProps } from './types'
import {
  getPaymentOptions,
  getSavedPaymentMethodsRadioOptions,
  PAYMENT_OPTION_AFFIRM,
  PAYMENT_OPTION_CARD,
  PAYMENT_OPTION_PAYPAL,
  PAYMENT_OPTION_SAVED_CARD,
  paymentMethodIconClasses,
  paymentOptionMapper
} from './utils'

export function PaymentFormWrapper({
  // FIXME move functions that directly access window to `@ecomm/shared-window`
  // @ts-ignore
  affirmClient = window.affirm,
  data,
  errorMessage,
  handleSelectedSavedCard,
  handleSubmitAffirmOrder,
  handleCreatePayPalOrder,
  handleZuoraFormRender,
  isZuoraPaymentSubmitted,
  isPayPalOrderCreated,
  paypalOrderData,
  paymentState,
  refreshZuoraForm,
  safeTechSdkUrl,
  savedPaymentMethods,
  selectedPaymentOption,
  selectPaymentOption,
  setIsZuoraPaymentSubmitted,
  setIsPayPalOrderCreated,
  setPaymentState,
  submitZuoraPaymentForm,
  // FIXME move functions that directly access window to `@ecomm/shared-window`
  // @ts-ignore
  zuoraClient = window.Z,
  zuoraPaymentMethod
}: PaymentFormWrapperProps) {
  const microCopy = useMicroCopy()
  const cart = useSelector(selectCart)
  const locale = useSelector(selectLocale)
  const { Track, trackEvent } = useTracking()
  const { trackEditPayment } = useTrackPaypal()
  const trackIconClickEvent = useTrackingAffirmInformationIcon('content')
  const [isAffirmOrderSubmitted, setIsAffirmOrderSubmitted] = useState(false)
  const isUs = useLocale() === 'en-US'

  const hasSavedPaymentMethods = savedPaymentMethods.length > 0

  const trackEventPaymentForm = useCallback(
    (args: TrackingData) => {
      trackEvent({ appSection: 'paymentForm', ...args })
    },
    [trackEvent]
  )

  const discountedPrice = cart.map(_cart => _cart.totalPrice * 100)

  const isAffirmEnabled = !!(affirmClient && prop('affirmEnabled', data))

  const isPayPalExperienceEnabled = useIsPayPalExperienceEnabled().isVariant

  const hasPaymentOptions = getPaymentOptions(
    hasSavedPaymentMethods,
    isAffirmEnabled,
    isPayPalExperienceEnabled
  )

  const shippingAddress = convertMonetMaybe(
    cart.toMaybe().chain(_cart => _cart.shippingAddress)
  )
  const shippingInfo = convertMonetMaybe(
    cart.toMaybe().chain(_cart => _cart.shippingInfo)
  )

  useEffect(() => {
    affirmClient &&
      affirmClient.ui.ready(() => {
        affirmClient && affirmClient.ui.refresh()
      })
  }, [affirmClient, discountedPrice])

  useEffect(() => {
    if (savedPaymentMethods.length > 0) {
      selectPaymentOption(PAYMENT_OPTION_SAVED_CARD)
      trackEventPaymentForm({
        event: 'payment-option-toggle',
        selectedPaymentOption: PAYMENT_OPTION_SAVED_CARD
      })
    }
  }, [savedPaymentMethods, selectPaymentOption, trackEventPaymentForm])

  useTrackPurchaseComplete(
    paymentState,
    selectedPaymentOption,
    trackEventPaymentForm
  )

  const _handleSubmitAffirmOrder = () =>
    handleSubmitAffirmOrder(setIsAffirmOrderSubmitted)

  const onClickEditButton = useCallback(() => {
    trackEditPayment()
    setIsPayPalOrderCreated(false)
  }, [setIsPayPalOrderCreated, trackEditPayment])

  const onPaymentOptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const paymentOption = paymentOptionMapper[e.target.value]

      paymentOption === 'new card' && refreshZuoraForm()
      paymentOption === 'saved card' && handleSelectedSavedCard(e.target.id)

      selectPaymentOption(paymentOption)
      trackEventPaymentForm({
        event: 'payment-option-toggle',
        selectedPaymentOption: paymentOption
      })
    },
    [
      handleSelectedSavedCard,
      refreshZuoraForm,
      selectPaymentOption,
      trackEventPaymentForm
    ]
  )

  const paymentMethodRadioButtonText = (
    <>
      {hasSavedPaymentMethods
        ? data.creditCardCheckoutOptionTitleWithSavedCc
        : data.creditCardCheckoutOptionTitle}
    </>
  )

  const paymentMethodRadioButtonDescription = (
    <>
      <CCVisa className={paymentMethodIconClasses} />
      <CCMaster className={paymentMethodIconClasses} />
      <CCAmex className={paymentMethodIconClasses} />
      <CCDiscover className={paymentMethodIconClasses} />
    </>
  )

  const cartHasSystem = cart
    .map(_cart => _cart.isThereAnySecurity)
    .orJust(false)

  const isCartAffirmEligible =
    cartHasSystem &&
    cart.map(_cart => _cart.totalPrice >= AFFIRM_MIN_PRICE).orJust(false)

  const cardVerificationNoteContent = prop('cardVerificationNote', data)

  const creditCardCheckoutContent = (
    <ZuoraPaymentForm
      billingAddress={convertMonetMaybe(getBillingAddress(cart))}
      cardVerificationNote={O.fromNullable(cardVerificationNoteContent)}
      errorMessage={errorMessage}
      isSubmitted={isZuoraPaymentSubmitted}
      locale={locale}
      onPaymentFormRender={handleZuoraFormRender}
      paymentMethod={O.fromNullable(zuoraPaymentMethod)}
      paymentState={paymentState}
      safeTechCollectorSdkUrl={pipe(
        O.fromNullable(safeTechSdkUrl),
        O.getOrElse(() => '')
      )}
      setIsSubmitted={setIsZuoraPaymentSubmitted}
      zuoraClient={pipe(O.fromNullable(zuoraClient))}
    />
  )

  const affirmCopy = cartHasSystem
    ? 'affirm-only-available-orders-over-150'
    : 'affirm-only-available-orders-with-system'

  const paypalCheckoutContent = (
    <PaypalCheckoutContent
      handleSubmitOrder={handleCreatePayPalOrder}
      setPaymentState={setPaymentState}
    />
  )

  const paypalRadioButtonLabel = (
    <div className="flex flex-row items-center font-medium">
      {'Pay with '}
      <PaypalLogo className="ml-2 h-8 w-16" />
    </div>
  )

  const affirmUnavailableMessage = (
    <div className="rounded-base bg-[reset] overflow-hidden p-4">
      <p>{microCopy[affirmCopy]}</p>
    </div>
  )

  const affirmCheckoutContent = (
    <AffirmCheckoutContent
      affirmClient={affirmClient}
      onOrderSubmit={_handleSubmitAffirmOrder}
    />
  )

  const affirmRadioButtonLabel = (
    <div
      className="prose md:prose-md lg:prose-lg whitespace-pre-line font-medium"
      data-testid="affirm-radio-button"
    >
      {propOr('', 'affirmCheckoutOptionTitle', data)}{' '}
      {!isUs && <AffirmLogo height="22px" width="58px" />}
      {isUs && affirmClient ? (
        <AffirmInformationLogoIcon
          height="22px"
          iconSize="25px"
          onClick={trackIconClickEvent}
          priceInCents={discountedPrice.orJust(0)}
          useSystemTermMonths={cartHasSystem}
          width="58px"
        />
      ) : null}
    </div>
  )

  const affirmRadioOption = {
    content: affirmCheckoutContent,
    disabled: !isCartAffirmEligible,
    disabledContent: affirmUnavailableMessage,
    text: affirmRadioButtonLabel,
    value: PAYMENT_OPTION_AFFIRM,
    className:
      'mb-4 pb-4 border border-solid border-t-0 border-x-0 border-neutral-light-100 items-center'
  }

  const newCreditCardOption = {
    content: creditCardCheckoutContent,
    defaultChecked: hasSavedPaymentMethods ? false : true,
    text: paymentMethodRadioButtonText,
    value: PAYMENT_OPTION_CARD,
    className: 'pt-5 mb-5 items-start md:items-center',
    ...(hasSavedPaymentMethods
      ? {}
      : { description: paymentMethodRadioButtonDescription })
  }

  const paypalRadioOption = {
    content: paypalCheckoutContent,
    text: paypalRadioButtonLabel,
    value: PAYMENT_OPTION_PAYPAL,
    className:
      'py-3 items-start md:items-center border border-solid border-t-0 border-x-0 border-[var(--neutral-light-100)]'
  }

  const RadioComponent = InputRadio

  const radioOptions = [
    ...(isAffirmEnabled ? [affirmRadioOption] : []),
    ...(hasSavedPaymentMethods
      ? getSavedPaymentMethodsRadioOptions(savedPaymentMethods)
      : []),
    ...(isPayPalExperienceEnabled ? [paypalRadioOption] : []),
    newCreditCardOption
  ]

  useSendMemberData()

  return (
    <Track>
      <div className="payment-iframe-container" data-testid="PaymentForm">
        <PaymentFormBanner paymentState={paymentState} />
        <div
          className={classNames('overflow-hidden', {
            'invisible h-0': ['loading', 'ready'].indexOf(paymentState) < 0
          })}
        >
          <ShippingCard
            shippingAddress={shippingAddress}
            shippingInfo={shippingInfo}
          />
          <PaymentErrorMessage />
          {!isPayPalOrderCreated ? (
            <FormSection title={'Payment options'}>
              <div
                className={'rounded-base bg-neutral-light-50 p-4 pb-4 md:p-8'}
              >
                {hasPaymentOptions ? (
                  <RadioComponent
                    dataComponent="payment-options"
                    id="payment-options"
                    name="payment-options"
                    onChange={onPaymentOptionChange}
                    options={radioOptions}
                  />
                ) : (
                  creditCardCheckoutContent
                )}
              </div>
            </FormSection>
          ) : (
            <>
              <div className="grid grid-cols-2">
                <span className="mb-4 flex shrink-0 items-center whitespace-nowrap text-xl font-medium md:text-2xl">
                  Payment
                </span>
                <span
                  className="text-complementary-blue-100 text-right text-lg underline hover:text-black hover:cursor-pointer"
                  onClick={onClickEditButton}
                >
                  Edit
                </span>
              </div>
              <div
                className={'rounded-base bg-neutral-light-50 p-4 pb-4 md:p-8'}
              >
                <PaypalCheckoutComplete
                  email={paypalOrderData.details?.email ?? ''}
                />
              </div>
            </>
          )}
          <div className="mt-12 hidden justify-between lg:flex">
            <div className="flex-2">
              <TermsOfSale />
            </div>
            <div className="flex flex-1 justify-end">
              <ZuoraSubmitButton
                className="ml-14 w-72"
                disabled={
                  isZuoraPaymentSubmitted ||
                  (selectedPaymentOption !== PAYMENT_OPTION_CARD &&
                    selectedPaymentOption !== PAYMENT_OPTION_SAVED_CARD &&
                    selectedPaymentOption === PAYMENT_OPTION_PAYPAL &&
                    !isPayPalOrderCreated) ||
                  isAffirmOrderSubmitted
                }
                onClick={submitZuoraPaymentForm}
                trackingLabel="below"
              />
            </div>
          </div>
        </div>
      </div>
    </Track>
  )
}
