import { getNewRelic } from '@ecomm/error-handling'
import { getAtAtToken, getUserId, getVisitorId } from '@ecomm/shared-cookies'
import { localStorage } from '@simplisafe/ewok'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import { TrackingData, TrackingEvent } from '../types/tracking'
const { get } = localStorage

/**
 * Track an event in New Relic with default details populated from cookies
 * @param event represents an actionName in New Relic
 * @param details additional key-string details to be included
 */
export const useTrackMetricEvent = () => {
  const { trackEvent } = useTracking<TrackingData>()

  const trackMetricEvent = (
    event: string,
    details: Record<string, string>,
    isError: boolean
  ) => {
    const env = process.env['NODE_ENV'] ?? 'unknown'
    const vid = getVisitorId()
    const drupalUid = getUserId()
    const atAtToken = getAtAtToken()

    const cartId: string = get('cartId') || 'unknown'
    const eventDetails = {
      ...details,
      atAtToken: atAtToken ?? '',
      cartId,
      drupalUid,
      environment: env,
      vid: vid ?? ''
    }

    // If this is an error, send it to NewRelic as a JavaScriptError. Otherwise, send it as a PageAction.
    getNewRelic(nr =>
      isError
        ? nr.noticeError(event, eventDetails)
        : nr.addPageAction(event, eventDetails)
    )
  }

  return useCallback(
    (event: string, details: Record<string, string> = {}, isError = false) => {
      // Track to Google Analytics
      trackEvent({
        event: <TrackingEvent>event,
        ...details
      })

      // Track to New Relic
      trackMetricEvent(event, details, isError)
    },
    [trackEvent]
  )
}

export type TrackMetricProps = (
  event: string,
  details?: Record<string, string>,
  isError?: boolean
) => void
