import {
  AFFIRM_PROMO_ID,
  AFFIRM_PROMO_ID_VARIANT,
  AFFIRM_PROMO_NO_SYSTEM
} from '@ecomm/data-constants'
import { useOptimizelyAffirmIconClick } from '@ecomm/optimizely-hooks'
import { useAffirmAPRExperimentLegacy } from '@ecomm/shared-hooks'
import { InformationIcon } from '@ecomm/shared-icons'

import { AffirmLogo } from '../AffirmBanner/AffirmLogo'

type Props = {
  readonly iconSize?: string
  readonly height: string
  readonly width?: string
  readonly onClick: () => void
  readonly priceInCents: number
  readonly useSystemTermMonths?: boolean
}

export function AffirmInformationLogoIcon({
  height,
  width,
  onClick,
  priceInCents,
  iconSize = '15px',
  useSystemTermMonths = true
}: Props) {
  const affirmIconClick = useOptimizelyAffirmIconClick()
  const isAffirmExperience = useAffirmAPRExperimentLegacy()

  const getMonthlyPromoId = (isMonthlyExperiment: boolean) =>
    isMonthlyExperiment ? AFFIRM_PROMO_ID_VARIANT : AFFIRM_PROMO_ID

  const promoId = useSystemTermMonths
    ? getMonthlyPromoId(isAffirmExperience)
    : AFFIRM_PROMO_NO_SYSTEM

  return (
    <span
      className="-mt-1"
      data-component="AffirmLogoIcon"
      data-testid="AffirmLogoIcon"
    >
      {' '}
      <AffirmLogo height={height} width={width} />{' '}
      <button
        aria-label="get more information"
        className="affirm-product-modal cursor-pointer border-none bg-transparent p-0 text-inherit"
        data-amount={Math.round(priceInCents)}
        data-component="IconContainer"
        data-promo-id={promoId}
        onClick={() => {
          affirmIconClick()
          onClick()
        }}
      >
        <InformationIcon height={iconSize} width={iconSize} />
      </button>{' '}
    </span>
  )
}
