import { Heading } from '@ecomm/ss-react-components'
import type { HeadingProps } from '@ecomm/ss-react-components/Heading'
import { path } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import applySpec from 'ramda/src/applySpec'
import React from 'react'

import { ContentfulHeading } from '../../../graphql'

export type HeadingComponentProps = {
  readonly data: Partial<ContentfulHeading>
}

const toHeadingProps = applySpec<HeadingProps>({
  bold: prop('bold'),
  color: path(['color', 'color']),
  element: prop('headingType'),
  headingAspect: prop('fontSizeAspect'),
  margin: prop('margin'),
  useTailwind: prop('useTailwind')
})

function HeadingComponent({ data }: HeadingComponentProps) {
  const text = data?.text?.text

  return <Heading {...toHeadingProps(data)}>{text}</Heading>
}

export default HeadingComponent
