import SharedIcons from '@ecomm/shared-icons'

import { ProductData } from '../schema'

const features = [
  {
    icon: SharedIcons.SecureHome,
    text: 'Indoor use'
  },
  {
    icon: SharedIcons.MotionDetection,
    text: '125° field of view'
  },
  {
    icon: SharedIcons.PrivacyShutter,
    text: 'Privacy shutter'
  },
  {
    icon: SharedIcons.Wired,
    text: 'Wired'
  },
  {
    icon: SharedIcons.SmartMotion,
    text: 'Smart motion detection'
  }
]

const description = {
  content: `Trusted since 2017, and it's no wonder why. You get instant alerts when human activity is detected, and can tune into your HD video feed at any time using the SimpliSafe® App.
  \nWith select monitoring plans, you get unlimited cloud recordings for 30 days, and agents can capture evidence of a crime in progress for priority police response.
  `
}

const specs = {
  content: `4.56 x 2.55 x 2.08 inches
  \n1 CR123A Lithium 3V battery (included)
  \nLifetime warranty with select professional monitoring plans
  `
}

const faqs = {
  contentfulId: '41QVm9MmwYazItKYTv8iqi'
}

export const indoorCameraData: ProductData = {
  productId: '2ihT178g27XM53mUuPKmdZ',
  sensorName: 'SimpliCam® Indoor Camera',
  features,
  description,
  specs,
  faqs
}
