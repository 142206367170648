import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const proSetupSchema = z.object({
  contentfulAsset: z.object({
    gatsbyImageData: gatsbyImageSchema.shape.gatsbyImageData,
    description: z.string()
  }),
  contentfulJson: z.object({
    contentful_id: z.string(),
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export type ProSetupSchema = TypeOf<typeof proSetupSchema>
