import { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@ecomm/shared-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { MonitoringPageHeroBannerData } from './schema'

const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="w-[74%] text-xl font-light leading-7 text-white">
        {children}
      </p>
    )
  }
}
//TODO: Remove this component once features-alarm-monitoring gets in production
/**
 * @deprecated this component has been translated to monitoring app and will be deleted once features-alarm-monitoring gets in production
 */
export function MonitoringPageHeroBanner({
  data
}: {
  readonly data: MonitoringPageHeroBannerData
}) {
  const backgroundImage = data.backgroundImage?.gatsbyImageData && (
    <div className="hidden md:block">
      <GatsbyImage
        alt={data.backgroundImage.description || ''}
        className="w-full md:h-[600px]"
        image={data.backgroundImage.gatsbyImageData}
      />
    </div>
  )

  const backgroundImageMobile = data.backgroundImageMobile?.gatsbyImageData && (
    <div className="md:hidden">
      <GatsbyImage
        alt={data.backgroundImageMobile.description || ''}
        className="h-72 w-full"
        image={data.backgroundImageMobile.gatsbyImageData}
      />
    </div>
  )

  const fastProtectLogo = data?.fastProtectLogo?.gatsbyImageData && (
    <GatsbyImage
      alt={data.fastProtectLogo.description || ''}
      className="hidden h-full w-3/5 md:inline-block"
      image={data.fastProtectLogo.gatsbyImageData}
    />
  )

  return (
    <section
      className="relative prose md:prose-md lg:prose-lg whitespace-pre-line w-full lg:px-8 lg:pt-8"
      data-component="MonitoringPageHeroBanner"
    >
      <div className="max-w-content lg:rounded-base relative mx-auto w-full lg:overflow-hidden">
        {backgroundImage}
        {backgroundImageMobile}
        <div className="absolute top-0 hidden h-full w-full md:block">
          <div className="relative mx-auto grid h-full max-w-screen-xl grid-cols-12 px-10 py-4 ">
            <div className="absolute bottom-4 right-8 text-right">
              {fastProtectLogo}
            </div>
            <div className="place-self-center md:col-span-10 lg:col-span-7">
              <span className="bg-primary-100 rounded-full px-3 py-1 font-light uppercase text-black">
                {data.badge}
              </span>
              <h1 className="leading-12 mb-9 mt-6 text-7xl font-medium text-white">
                {data.title}
              </h1>
              <ContentfulRichText
                optionsCustom={descriptionCustomOptions}
                raw={data.description?.raw}
              />
            </div>
          </div>
        </div>
      </div>
      <h2 className="!mb-7 text-center lg:hidden">{data.title}</h2>
    </section>
  )
}
