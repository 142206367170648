import { DynamicDiscount } from '@simplisafe/ss-ecomm-data/prices/service'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { getBMSMAbsoluteDiscountedPrice } from './getBMSMAbsoluteDiscountedPrice'

export const getBMSMDraftCartDiscountedTotal = (
  thresholds: readonly DynamicDiscount[],
  total: number,
  discountedTotal?: number
) =>
  pipe(
    getBMSMAbsoluteDiscountedPrice(
      thresholds,
      total,
      O.fromNullable(discountedTotal)
    ),
    O.chain(discount => (discount === total ? O.none : O.some(discount))),
    O.toUndefined
  )
