import { reviewsLayoutSchema, reviewsPageContentSchema } from './schema'

export const useReviewsPageFragment = <
  T extends { readonly contentfulGenericLandingPage: U },
  U
>(
  query: T
) => {
  const reviewsFragment = query.contentfulGenericLandingPage

  const reviewsPage = reviewsPageContentSchema.parse(reviewsFragment)

  //defaults showPopupQuoteWizard to true if no value is provided in CTFL
  const reviewsPageWithPopupQW =
    reviewsPage.showPopupQuoteWizard === null
      ? { ...reviewsPage, showPopupQuoteWizard: true }
      : reviewsPage

  return {
    ...reviewsPageContentSchema.parse(reviewsPageWithPopupQW),
    layout: reviewsLayoutSchema.parse(reviewsPageWithPopupQW.layout)
  }
}
