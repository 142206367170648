import { RouterProps } from '@reach/router'
import {
  IOAddToCart,
  IOCreateOrUpgradeCart,
  IOVerifyEligibility,
  IOVerifySS2Eligibility
} from '@simplisafe/ss-ecomm-data/cart/actions'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import {
  handleRedirectCallback,
  setAuthCookies
} from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { get, set } from 'local-storage'
import { ThunkDispatch } from 'redux-thunk'

const localeMapping = {
  'en-GB': 'GB',
  'en-US': 'US'
}

export const DEVICE_ID = 'deviceId'
export const USER_ID = 'userId'

export const interactiveUpgradeFlow = (
  href: string,
  locale: Locale,
  dispatch: ThunkDispatch<ImmutableState, void, ACTION>,
  handleSuccess: () => void,
  handleFailure: () => void
) => {
  const customerGroup = `interactiveCustomers-${localeMapping[locale]}`

  const verifyEligibility = (id: string) => {
    IOVerifyEligibility(
      id,
      customerGroup,
      () => handleFailure(),
      () => {
        dispatch(
          IOAddToCart(
            { products: [] },
            () => null,
            () => {
              dispatch(IOCreateOrUpgradeCart(id, customerGroup))
            }
          )
        )
        handleSuccess()
      }
    )
  }

  handleRedirectCallback(
    get(DEVICE_ID),
    href
  )(() => {
    const userId = get<string>(USER_ID)
    userId ? verifyEligibility(userId) : handleFailure()
  })(userAuthData => {
    const { id } = userAuthData
    set(USER_ID, id)
    verifyEligibility(id)
  })
}

export const ss2UpgradeFlow = (
  href: string,
  locale: Locale,
  dispatch: ThunkDispatch<ImmutableState, void, ACTION>,
  handleSuccess: () => void,
  handleFailure: () => void
) => {
  const customerGroup = `ss2Customer-${localeMapping[locale]}`

  handleRedirectCallback(get(DEVICE_ID), href)(handleFailure)(userAuthData => {
    const { id } = userAuthData
    IOVerifySS2Eligibility(id, handleFailure, () => {
      dispatch(IOCreateOrUpgradeCart(id, customerGroup))
      handleSuccess()
    })
  })
}

export const odmonBetaFlow = (
  href: string,
  handleSuccess: () => void,
  handleFailure: () => void
) => {
  handleRedirectCallback(get(DEVICE_ID), href)(handleFailure)(userAuthData => {
    const { id, accessToken, emailAddress } = userAuthData
    setAuthCookies(id, accessToken, emailAddress)
    handleSuccess()
  })
}

export const fromAppAuth = (
  utmSource?: string | null,
  utmMedium?: string | null,
  planStr?: string | null,
  locale?: Locale
): boolean => {
  const plans: readonly string[] = planStr?.split(',') || []

  const isUsLocale = locale ? locale === 'en-US' : true
  return !!(utmSource && utmMedium && isUsLocale && plans.includes('SSEDSM2'))
}

export const getHrefWithoutSearch = (
  location: RouterProps['location']
): string => {
  const href = location?.href || ''
  const search = location?.search

  return search ? href.substring(0, href.indexOf(search)) : href
}
