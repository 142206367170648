import classNames from 'classnames'
import { Link } from 'gatsby'
import React, { useCallback } from 'react'
import { useTracking } from 'react-tracking'

export const REFURBISHED_EVENT_NAME = 'shop-refurb-see-details'
export const NON_REFURBISHED_EVENT_NAME = 'shop-see-details'

type Props = {
  readonly slug: string
  readonly className?: string
  readonly product?: string
  readonly onClick?: () => void
}
/**
 * This is a duplicate of apps/shop/src/components/PackageListItem/DetailsButton/DetailsButton.tsx in partners app
 */
export default function DetailsButton({
  slug,
  product,
  className = 'font-normal border-[0.125rem] px-6 py-3',
  onClick: onClickProp
}: Props) {
  const { trackEvent } = useTracking()
  const onClick = useCallback(() => {
    const isRefurbished = slug.includes('refurb')
    const eventName = isRefurbished
      ? REFURBISHED_EVENT_NAME
      : NON_REFURBISHED_EVENT_NAME
    trackEvent({
      package: product,
      event: eventName
    })
  }, [slug])

  return (
    <Link
      className="whitespace-nowrap no-underline"
      data-testid="package-see-details-button"
      onClick={onClickProp || onClick}
      to={`/${slug}`}
    >
      <div
        className={classNames(
          'relative flex justify-center rounded-base border-solid bg-transparent text-center text-base text-blue-600/100 hover:bg-blue-700 hover:text-white sm:w-full md:w-auto md:text-lg',
          className
        )}
        data-component="ProductButton"
      >
        See details
      </div>
    </Link>
  )
}
