import classNames from 'classnames'

import type { CartLineItemProps } from '../types'

type Props = {
  readonly item: CartLineItemProps
}

export function OrderSummaryListItem({ item }: Props) {
  return (
    <div
      className="mt-0 w-full pt-1.5 md:pb-0 md:pt-1"
      data-component="OrderSummaryListItem"
    >
      <div className="flex justify-between py-2 md:justify-start md:py-1">
        <div className="flex items-center">
          <span className="pr-2" data-component="quantityText">
            {item.quantityText}
          </span>
          <span className="text-base md:text-lg">
            {(item.isBms && item.lineItemDisplayName) || item.itemName}
          </span>
        </div>
        <div className="mx-4 mb-1.5 hidden h-px flex-1 self-end md:flex"></div>
        <div className="flex flex-row gap-1">
          {item.isFreeItem ? (
            <span className="text-sale text-right text-base font-normal md:text-lg">
              Free
            </span>
          ) : null}
          <span
            className={classNames('text-base font-normal md:text-lg', {
              'line-through': item.isFreeItem
            })}
          >
            {item.price}
          </span>
        </div>
      </div>
    </div>
  )
}
