//@ts-nocheck
import classnames from 'classnames'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import React, { FC, ReactNode, useState } from 'react'

import isNotUndefined from '../utils/isNotUndefined'
import * as css from './ColorSelector.module.scss'

export type ColorSelectorColor = 'obsidian' | 'white'
export type ColorSelectorOption = {
  readonly description: ReactNode
  readonly color: ColorSelectorColor
  readonly colorDisabled?: boolean
}

export type ColorSelectorProps = {
  /** optional id for ColorSelector */
  readonly id?: string
  /** optional className for ColorSelector */
  readonly className?: string
  /** optional message to display to inform that one of colors is unavailable */
  readonly colorNotAvailableMessage?: string
  /** a list of color selector option text mapped to the color to display */
  readonly options: ReadonlyArray<ColorSelectorOption>
  /** data-component attribute value */
  readonly dataComponent?: string
  /** handle click function - idx arg is index of button clicked */
  readonly handleClick: (idx: number) => void
  readonly selectedIndex?: number
}

const radioButtonClasses = (option: ColorSelectorColor) => {
  return classnames(
    css.circle,
    { [css.white]: option === 'white' },
    { [css.obsidian]: option === 'obsidian' }
  )
}

const availableColorIndex = (options: ColorSelectorProps['options']) => {
  const availableColorOptions = options.filter(color => !color.colorDisabled)
  const availableColorOptionIndex =
    availableColorOptions.length > 0
      ? options.findIndex(c => c.color === availableColorOptions[0].color)
      : -1
  return availableColorOptionIndex !== -1
    ? availableColorOptionIndex
    : undefined
}

const getSelectedColorIndex = (
  options: ColorSelectorProps['options'],
  selectedIndex?: number
) => {
  const selectedIndexAvailable: number | undefined =
    selectedIndex && !options[selectedIndex].colorDisabled
      ? selectedIndex
      : undefined
  return selectedIndexAvailable
    ? selectedIndexAvailable
    : availableColorIndex(options)
}

const renderButtons = (
  options: ColorSelectorProps['options'],
  handleClick: ColorSelectorProps['handleClick'],
  id?: string,
  selectedIndex?: number
) => {
  const selectedColorIndex: number | undefined = getSelectedColorIndex(
    options,
    selectedIndex
  )
  return (
    <form className={css.formWrapper}>
      <fieldset className="m-0 border-0 p-0">
        <legend className="absolute left-[-9999px]">Select color</legend>
        {options.map((option, idx: number) => {
          const value = `${option.color}${idx}-${id}`
          const colorIsDisabled: boolean = option.colorDisabled || false

          return (
            <label className={css.label} htmlFor={value} key={value}>
              <input
                checked={idx === selectedColorIndex}
                className={css.checkbox}
                disabled={colorIsDisabled}
                id={value}
                name={id}
                onChange={() => handleClick(idx)}
                type="radio"
                value={value}
              />
              <div className={radioButtonClasses(option.color)}></div>
              <span className={classnames('textSizeSM', css.buttonGroup)}>
                {option.description}
              </span>
            </label>
          )
        })}
      </fieldset>
    </form>
  )
}

const renderDisabledColorMessage = (
  disabledColors: ReadonlyArray<ColorSelectorColor>,
  colorNotAvailableMessage?: string
) => {
  const numberOfDisabledColors: number = disabledColors.length
  // ${colorNotAvailableMessage} will be coming from Contentful and will be set to 'is currently unavailable'
  // I'm not sure if there's a better way to do it and if anything else needs to be considered in case the copy in Contentful doesn't make sense
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const productIdentifier: string = ifElse(
    equals(1),
    always(`${disabledColors[0]} color option`),
    always('product')
  )(numberOfDisabledColors)

  return (
    <div className={css.colorNotAvailableMessage}>
      {`${productIdentifier} ${colorNotAvailableMessage}`}
    </div>
  )
}

/** @deprecated Do not use ss-react-components*/
const ColorSelector: FC<ColorSelectorProps> = ({
  id,
  className,
  colorNotAvailableMessage,
  dataComponent = ColorSelector.name,
  handleClick: handleClickProp,
  options,
  selectedIndex: selectedIndexProp
}: ColorSelectorProps) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedIndexProp || 0)
  const handleClick = (idx: number) => {
    handleClickProp(idx)
    setSelectedIndex(idx)
  }

  const disabledColors = options
    .filter(color => color.colorDisabled)
    .map(c => c.color)

  const showDisabledColorMessage: boolean =
    disabledColors.length > 0 && isNotUndefined(colorNotAvailableMessage)

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- legacy code
      // @ts-expect-error
      className={classnames([className, css.component])}
      data-component={dataComponent}
      id={id}
    >
      {renderButtons(options, handleClick, id, selectedIndex)}
      {showDisabledColorMessage
        ? renderDisabledColorMessage(disabledColors, colorNotAvailableMessage)
        : null}
    </div>
  )
}

export default ColorSelector
