import equals from 'ramda/src/equals'
import when from 'ramda/src/when'
import { useEffect, useRef } from 'react'

type Options = {
  readonly enabled?: boolean
  readonly target?: GlobalEventHandlers
}
export const isSSR = () => typeof window === undefined

const useEventListener = (
  eventType: keyof GlobalEventHandlersEventMap,
  handler: EventListener,
  { enabled = true, target }: Options = {}
) => {
  const canItRun = !isSSR() && enabled
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  })

  useEffect(() => {
    const internalHandler = (e: Event) => {
      return handlerRef.current(e)
    }

    const element = !isSSR() && !target ? window : target

    when(equals(true), () => {
      element && element.addEventListener(eventType, internalHandler)
    })(canItRun)

    return () => {
      element && element.removeEventListener(eventType, internalHandler)
    }
  }, [eventType, canItRun, target])
}

export default useEventListener
