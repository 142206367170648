import { BrazeQuoteWizardResponse } from '@ecomm/tracking'
import { prop } from '@simplisafe/ewok'
import { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { Maybe } from 'monet'
import add from 'ramda/src/add'
import isNil from 'ramda/src/isNil'

// Map response keys and values to the question and answer strings that need to be passed to Braze
const entrySensorResponseMapper: Record<number, string> = {
  2: '1-2',
  4: '3-4',
  7: '5-7',
  8: '8+'
}

const motionSensorResponseMapper: Record<number, string> = {
  0: '0',
  1: '1',
  2: '2',
  3: '3'
}

const multiSelectResponseMapper: Record<string, string> = {
  CMOB1: 'Outdoor video footage',
  SSCM2: 'Indoor video footage',
  SSDB3: 'Package theft',
  SSFS3: 'Freezing/burst pipes',
  SSGB3: 'Deterring break-ins',
  SSPB3: 'Medical dispatch',
  SSSD3: 'Preventing fires',
  SSWS3: 'Deterring break-ins',
  SSWT3: 'Water damage/leaks'
}

const mapMultiSelectResponses = (skus: ReadonlyArray<string>) => {
  const questionSet = new Set<string>()
  skus.map(sku => questionSet.add(multiSelectResponseMapper[sku]))
  return Array.from(questionSet).filter(q => !!q)
}

// TODO this is a super quick and dirty way of getting QW response data into GTM for Braze in the interest of time, and should be revisited and reworked.
// At the the very least, we shouldn't be hardcoding SKUs since those can change, and this should probably be moved to ecomm-data.
// NOTE: Braze expects QW questions and answers to EXACTLY match what Drupal is currently sending, and IS CASE-SENSITIVE.
export const buildRecommendationResponseData = (
  siteLocale: Locale,
  attributeHash: string | undefined,
  responses: Record<string, unknown>
): ReadonlyArray<BrazeQuoteWizardResponse> => {
  // Add up the number of products from the attribute hash (e.g. "010003" => 4)
  const productCount = (attributeHash || '')
    .split(/(..)/g)
    .map(c => parseInt(c, 10))
    .filter(c => !isNaN(c))
    .reduce(add, 0)
  // Add 2 for US for included base station + keypad; add 3 for UK because it also has an included camera
  const recommendationProductCount =
    siteLocale === 'en-GB' ? productCount + 3 : productCount + 2

  return [
    {
      // TODO: fix type

      // @ts-expect-error TS(2538) FIXME: Type 'unknown' cannot be used as an index type.
      answer:
        entrySensorResponseMapper[
          Maybe.fromNull(prop('SSES3', responses)).orJust(0)
        ],
      question: 'QW_RECS How many first floor windows and doors?'
    },
    {
      // TODO: fix type

      // @ts-expect-error TS(2538) FIXME: Type 'unknown' cannot be used as an index type.
      answer:
        motionSensorResponseMapper[
          Maybe.fromNull(prop('CA001-01DWW', responses)).orJust(0)
        ],
      question: 'QW_RECS How many motion sensors do you need?'
    },
    {
      // the multiSelect question doesn't have a sku associated with it, so it just ends up with 0 as the question id (as set in Contentful)
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- legacy code
      answer: Maybe.fromNull(responses['0'] as string)
        .map(skus => mapMultiSelectResponses(skus.split(',')))
        .orJust([]),
      question: 'QW_RECS What Can We Help You With?'
    },
    {
      answer: `${recommendationProductCount}-Piece Security System`,
      question: 'QW_RECS_PRODUCT_NAME'
    },
    {
      answer: `/product/system/${attributeHash}`,
      question: 'QW_RECS_URL'
    }
  ].filter(data => !isNil(data.answer) && !isNil(data.question))
}
