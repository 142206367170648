/**
 * This is for fragments available across the app.
 * DO NOT ADD FRAGMENTS THAT ARE NOT GLOBAL!
 * We want to keep this as small as possible.
 * */

import { gql } from '@apollo/client/index.js'

export const fragments = gql`
  fragment image on Asset {
    originalWidth: width
    originalHeight: height
    url
    description
    sys {
      id
    }
  }
`
