import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@ecomm/shared-components'
import { useMediaQuery } from '@ecomm/shared-hooks'

import type { ContentfulSmallTextSection } from '../schema'

// CAUTION: gatsby-4-upgrade requires using Contentful Schema type instead of Fragment, ensure data only references fragment properties.
type SimpleFooterComponentProps = {
  readonly id?: string
  readonly data: ContentfulSmallTextSection
}

const richTextOptions: Options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: node => {
      const type: string | undefined = node.data?.['target']?.internal?.type

      const linkUrl: string | undefined = node.data?.['target']?.linkUrl

      const linkText: string | undefined = node.data?.['target']?.linkText
      return type === 'ContentfulLink' ? (
        <a
          className="text-primary-100 no-underline hover:underline"
          href={linkUrl}
        >
          {linkText}
        </a>
      ) : null
    },
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="my-0 whitespace-pre text-white">{children}</p>
    )
  }
}

function SimpleFooterComponent({ data }: SimpleFooterComponentProps) {
  const isMobile = !useMediaQuery('TabletAndUp')

  return (
    <div className="w-full overflow-x-hidden">
      <div className="my-0 mx-[calc(50%-50vw)] bg-neutral-black text-white text-center px-2 md:pt-8 md:px-5 md:pb-4 lg:py-10 lg:px-12 text-lg clear-both font-extralight leading-7 py-8">
        <div>
          <ContentfulRichText
            optionsCustom={richTextOptions}
            raw={
              isMobile ? data?.descriptionMobile?.raw : data?.description?.raw
            }
            //@ts-expect-error
            references={data?.description?.references || []}
          />

          <a
            className="!text-white ot-sdk-show-settings cursor-pointer text-xs no-underline hover:underline"
            onClick={() => window?.OneTrust?.ToggleInfoDisplay()}
          >
            {data?.description?.references
              ? data.description.references[1]?.linkText
              : null}
          </a>
        </div>
      </div>
    </div>
  )
}

export default SimpleFooterComponent
