//@ts-nocheck
import { Maybe } from '@simplisafe/ewok'
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'

import { Cancel } from '../icons'
import setAppElement from './setAppElement'

export type ModalSize = 'large' | 'medium' | 'small'

// @deprecated: this type is kept to for backwards compatibility and no effect on the component.
export type ModalStyle = {
  readonly content?: object
  readonly overlay?: object
}

export type SSModalProps = {
  // @deprecated: this prop is kept to for backwards compatibility and no effect on the component.
  readonly appElementId?: string
  readonly children: ReactNode
  readonly size?: ModalSize
  readonly isClosable?: boolean
  readonly isOpen: boolean
  readonly onRequestClose?: () => void
  readonly style?: ReactModal.Styles
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const Modal: FC<SSModalProps> = ({
  children,
  dataComponent = Modal.name,
  isClosable = true,
  isOpen,
  onRequestClose,
  size,
  style
}: SSModalProps) => {
  const sizeClassName = classNames({
    'md:max-w-[min(90%,600px)]': size === 'small',
    'md:max-w-[min(90%,750px)]': size === 'medium',
    'md:max-w-[min(90%,900px)]': size === 'large'
  })
  const handleClose = () => {
    isClosable && onRequestClose?.()
  }

  setAppElement(process.env.NODE_ENV)

  return (
    <ReactModal
      className={classNames(
        'rounded-base relative left-1/2 top-1/2 z-[21] max-h-[90%] min-h-[100px] w-fit max-w-[min(90%,1440px)] -translate-x-1/2 -translate-y-1/2 overflow-y-auto overflow-x-hidden bg-white p-0 shadow-[-3px_3px_7px_0_rgba(0,0,0,0.2)] outline-none xl:max-w-[min(75%,1440px)]',
        sizeClassName
      )}
      closeTimeoutMS={250}
      data={{ component: dataComponent }}
      isOpen={isOpen}
      onRequestClose={handleClose}
      overlayClassName="inset-0 fixed z-20 bg-black/[.65]"
      shouldCloseOnOverlayClick={true}
      style={style}
    >
      {/* The close button is a div (with enter/space key handling for a11y) instead of a button in order to prevent
       * Cookiebot from interpreting closing a modal as cookie consent */}
      {isClosable ? (
        <div
          className="absolute right-[11px] top-[16px] z-[21] h-[24px] w-[24px] cursor-pointer p-0 opacity-70 hover:opacity-100"
          onClick={handleClose}
          onKeyDown={e => {
            e.preventDefault()
            Maybe.fromFalsy(['Enter', ' '].includes(e.key)).forEach(handleClose)
          }}
          role="button"
          tabIndex={0}
        >
          <span className="screenReaderText">close</span>
          <Cancel className="text-neutral-medium-120" />
        </div>
      ) : null}
      {
        // TODO The .modalContentInner class exists solely for a unit test to
        // evaluate existence of children. Not currently used for styling, but
        // could potentially be a nested component like <ModalContent />
        // or <Box />, etc.
      }
      <div className="modalContentInner">{children}</div>
    </ReactModal>
  )
}

export default Modal
