import classNames from 'classnames'
import React, { FC } from 'react'

import { OptionItemWrapperObject } from './types'

const OptionItemWrapper: FC<OptionItemWrapperObject> = ({
  theme,
  children,
  isSelected
}: OptionItemWrapperObject) =>
  theme === 'column' ? (
    <div
      className={classNames(
        'rounded-base mb-4 flex flex-col justify-center border border-solid p-4 last:mb-0',
        {
          'border-white bg-white': isSelected,
          'border-neutral-medium-100': !isSelected
        }
      )}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  )

export default OptionItemWrapper
