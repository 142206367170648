//@ts-nocheck
import classNames from 'classnames'
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  useMemo,
  useState
} from 'react'

import * as css from './SSTextarea.module.scss'

export type SSTextareaProps = {
  readonly error?: boolean
  readonly id: string
  readonly label?: string
  readonly errorMsg?: string
  readonly placeholder?: string
  readonly required?: boolean
  readonly value?: string
  readonly onChange?: (event: ChangeEvent) => unknown
  readonly disabled?: boolean
  readonly className?: string
  readonly name?: string
  readonly rows?: number
  readonly cols?: number
  readonly defaultValue?: string
  readonly showRequiredSymbol?: boolean | true
  readonly dataComponent?: string
  readonly maxLength?: number
}

/** @deprecated Do not use ss-react-components*/
const Textarea = forwardRef<HTMLTextAreaElement, SSTextareaProps>(
  (
    {
      disabled,
      value,
      error,
      errorMsg,
      id,
      label,
      onChange,
      placeholder,
      required,
      className,
      name,
      rows = 1,
      cols = 50,
      defaultValue,
      showRequiredSymbol,
      maxLength,
      dataComponent = 'Textarea'
    }: SSTextareaProps,
    ref
  ) => {
    const [touched, setTouched] = useState(false)
    const inputRequired = useMemo(() => {
      return touched && required
    }, [required, touched])

    const handleTouched: (event: FocusEvent) => unknown = () => {
      setTouched(true)
    }

    //TODO: Remove bodyText and remaining color classes after color definition
    //When all classes are removed, update tests
    //bodyText includes first:mt-0 and last:mb-0
    const bodyTextClasses =
      'text-base leading-[1.56] md:text-lg md:leading-[1.56] first:mt-0 last:mb-0'
    const inputClass = classNames(
      'border border-solid stroke rounded-sm block max-h-[50px] max-w-[427px] w-full',
      bodyTextClasses,
      css['stroke'],
      { [css['error']]: error },
      'bodyText',
      className
    )
    return (
      <>
        <label
          className={classNames('bodyText', bodyTextClasses, 'font-medium')}
          data-component={`${dataComponent}_label`}
          htmlFor={id}
        >
          {label}
          {required && showRequiredSymbol ? <span>*</span> : null}
        </label>
        <textarea
          aria-required={required}
          className={inputClass}
          cols={cols}
          data-component={`${dataComponent}_textarea`}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          name={name}
          onBlur={handleTouched}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          required={inputRequired}
          rows={rows}
          value={value}
        ></textarea>
        <div
          className={classNames(css['errorText'], 'mt-2.5 md:ml-2')}
          data-component={`${dataComponent}_error`}
        >
          {errorMsg}
        </div>
      </>
    )
  }
)

export default Textarea
