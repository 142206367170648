import { WarningIcon } from '@ecomm/checkout-icons'
import classNames from 'classnames'
import { useField } from 'formik'
import { HTMLAttributes } from 'react'

export type Props = HTMLAttributes<HTMLInputElement> & {
  readonly fullWidth?: boolean
  readonly hasError?: boolean
  readonly id?: string
  readonly name: string
  readonly className?: string
  readonly maxLength?: number
  readonly placeholder?: string
  readonly type?: 'email' | 'number' | 'text'
}

export function FormTextInput({
  hasError = false,
  className = '',
  id = '',
  name,
  placeholder = '',
  type = 'text',
  ...rest
}: Props) {
  const [field, meta] = useField(name)

  return (
    <div className="relative">
      <input
        id={id}
        type={type}
        {...field}
        name={name}
        placeholder={placeholder}
        {...rest}
        className={classNames(
          'placeholder:text-neutral-dark-100 rounded-base border-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size h-11 w-full border border-solid p-3',
          {
            'border-red-800 bg-red-50': (meta.touched && meta.error) || hasError
          },
          className
        )}
        data-testid={`input-${name}`}
      />
      {meta.error && meta.touched ? (
        <WarningIcon className="absolute right-4 top-1/2 -translate-y-1/2 transform" />
      ) : null}
    </div>
  )
}
