type Props = {
  readonly className?: string
}

export function CancelIcon({ className = '' }: Props) {
  return (
    <svg
      className={className}
      data-component="CancelIcon"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g stroke="currentColor">
          <path d="M18.033 5L4.967 18.066M4.967 5l13.066 13.066" />
        </g>
      </g>
    </svg>
  )
}
