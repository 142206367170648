import { SHIELD_SKU } from '@ecomm/data-constants'
import { usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import { ContentfulRichText, GatsbyImage } from '@ecomm/shared-components'
import { useIsOnScreen, useMediaQuery } from '@ecomm/shared-hooks'
import {
  mapExperiences,
  useNinetailedImpactedEvent,
  useOdmonExperience
} from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import type { TrackingData } from '@ecomm/tracking'
import { trackBmsView } from '@ecomm/tracking'
import { useEnv } from '@ecomm/utils'
import classNames from 'classnames'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTracking } from 'react-tracking'

import { itemQuantityAtom } from '../../atoms/draftCart/itemQuantityAtom'
import { totalPriceAtom } from '../../atoms/draftCart/totalPriceAtom'
import { useProductContext } from '../../contexts/ProductContext'
import type { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import { SavingsBanner } from '../../experiments/BuyMoreSaveMore/SavingsBanner'
import { getBMSMDraftCartDiscountedTotal } from '../../experiments/BuyMoreSaveMore/utils/getBMSMDraftCartDiscountedTotal'
import { OdmonDraftCartAddToCartButton } from '../../experiments/ODMON/OdmonDraftCartAddToCartButton'
import { useDecodeProductsFromHash } from '../../hooks/ClickableCart/useDecodeProductsFromHash'
import {
  getQuizBreakInsCookie,
  getQuizPackageTheftCookie,
  getShowedOdmonIntentCookie
} from '../../utils/odmonIntentCookies'
import AffirmWrapper from '../AffirmWrapper'
import { DraftCartMonitoringWrapper } from '../IMAPS/DraftCartMonitoringWrapper'
import { OdmonMonitoringDraftCart } from '../IMAPS/Odmon/OdmonMAPSTestContainer'
import DraftCartAddToCartButton from './DraftCartAddToCartButton'
import DraftCartLineList from './DraftCartLineList'
import DraftCartShipping from './DraftCartShipping'
import DraftCartStaticList from './DraftCartStaticList'
import DraftCartTotal from './DraftCartTotal'
import { validateCart } from './helpers'
import { useGetDraftCartStaticList, useGetDynamicDiscountsSku } from './hooks'
import type { DraftCartFragment } from './types'

type DraftCartProps = DraftCartFragment & {
  readonly isSS2Upgrade?: boolean
}

function DraftCart({
  button,
  dependentProducts,
  errors,
  satisfactionGuarantee,
  satisfactionGuaranteeImage,
  priceToggles,
  defaultBundle,
  shippingEstimateDate,
  outOfStock,
  isSS2Upgrade = false
}: DraftCartProps) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const odmonExperienceData = useOdmonExperience()
  const { baseProducts, otherItems } = useGetDraftCartStaticList(
    priceToggles,
    defaultBundle
  )

  //Selectors
  const { locale } = useEnv()
  const [items] = useAtom(itemQuantityAtom)
  const [priceTotals] = useAtom(totalPriceAtom)

  const [impactedFlagFired, setImpactedFlagFired] = useState(false)
  const [hasOutdoorCameraInDraftCart, setHasOutdoorCameraInDraftCart] =
    useState(false)
  const microCopy = useMicroCopy()
  const { getDynamicDiscountsThresholds } = usePriceContext()
  const dynamicDiscountsSku = useGetDynamicDiscountsSku()
  const dynamicDiscounts = pipe(
    getDynamicDiscountsThresholds(dynamicDiscountsSku),
    O.fold(constant([]), identity)
  )

  //Errors
  const [errorKey, setError] = useState('')
  const isValidCart = useMemo(
    () => validateCart(items.keySeq().toArray(), dependentProducts),
    [items, dependentProducts]
  )
  const error = useMemo(
    () => errors.find(e => e.key === errorKey)?.value,
    [errorKey, errors]
  )

  //Pre-Populate Sensors
  useDecodeProductsFromHash()

  //Tracking
  const { trackEvent } = useTracking<TrackingData>()
  const trackView = trackBmsView(trackEvent)
  const isUs = locale === 'en-US'

  const { getProduct } = useProductContext()
  const products = items.toArray().map(([sku, quantity]) => ({
    name: getProduct(sku)?.name || '',
    quantity,
    sku
  }))

  const showedOdmonIntent = getShowedOdmonIntentCookie()
  const quizBreakIns = getQuizBreakInsCookie()
  const quizPackageTheft = getQuizPackageTheftCookie()
  const odmonTestObserver = useNinetailedImpactedEvent({
    baseline: odmonExperienceData.baseline,
    experiences: odmonExperienceData.experiences,
    name: 'nt_impacted_event',
    event: {
      hasOutdoorCamera: hasOutdoorCameraInDraftCart,
      showedOdmonIntent,
      quizBreakIns,
      quizPackageTheft
    },
    shouldReset: (e1, e2) => e1.hasOutdoorCamera !== e2.hasOutdoorCamera
  })

  // todo revisit -- with no dependencies, fires 1x but with 0 price; if dependent on priceTotals it fires several times
  useEffect(() => {
    trackView(
      locale,
      defaultBundle,
      products,
      priceTotals.discountedTotal || priceTotals.total
    )
  }, [])

  useEffect(() => {
    setHasOutdoorCameraInDraftCart(
      items.toArray().some(([sku]) => sku === SHIELD_SKU)
    )
  }, [items])

  const intersectionRef = useRef(null)
  const isOnScreen = useIsOnScreen(intersectionRef, 0.5)

  const fireImpactedFlag = useCallback(() => {
    setImpactedFlagFired(true)
  }, [])

  const isMobile = !useMediaQuery('TabletAndUp')

  useEffect(() => {
    !isMobile &&
      locale === 'en-US' &&
      isOnScreen &&
      !impactedFlagFired &&
      fireImpactedFlag()
  }, [locale, isMobile, isOnScreen, impactedFlagFired, fireImpactedFlag])

  // TODO: This is BMSMv2 code. Remove it once the experiment is over
  const BMSMDraftCartDiscountedTotal = getBMSMDraftCartDiscountedTotal(
    dynamicDiscounts,
    priceTotals.total,
    priceTotals?.discountedTotal
  )

  return (
    <div
      className="items-left rounded-base bg-neutral-light-50 mt-10 grid w-full grid-cols-12 px-4 py-5 md:gap-8 md:p-8 lg:p-16"
      id="Summary"
      ref={intersectionRef}
    >
      {!!dynamicDiscounts.length && (
        <div className="col-span-12">
          <SavingsBanner dynamicDiscounts={dynamicDiscounts} />
        </div>
      )}
      <div className="col-span-12 hidden text-center md:col-span-4 md:block">
        <div className="mb-4 inline-block h-32 w-32 -rotate-12 lg:mb-6 lg:h-40 lg:w-40">
          {satisfactionGuaranteeImage ? (
            <GatsbyImage
              className="h-full w-full rounded-full"
              image={satisfactionGuaranteeImage}
            />
          ) : null}
        </div>
        <ContentfulRichText raw={satisfactionGuarantee?.raw} />
      </div>
      <div className="col-span-12 grid md:col-span-8">
        <h2 className="mb-4 text-xl font-medium md:text-4xl">
          {microCopy['equipment']}
        </h2>
        <div
          className={classNames('mb-10 w-full md:block', {
            'border-neutral-medium-100 rounded-[20px] border-[1px] border-solid bg-white p-4 shadow-md':
              isUs
          })}
        >
          {isUs ? (
            <Experience
              {...affirmFeatureFlagQueryData}
              component={AffirmWrapper.DraftCartStaticList}
              experiences={mapExperiences<AffirmFeatureFlagSchema>(
                affirmFeatureFlagQueryData.nt_experiences
              )}
              passthroughProps={{
                baseProducts: baseProducts,
                otherItems: otherItems
              }}
            />
          ) : (
            <DraftCartStaticList
              baseProducts={baseProducts}
              isAffirmExperience={false}
              otherItems={otherItems}
            />
          )}
          {isUs ? (
            <Experience
              {...affirmFeatureFlagQueryData}
              component={AffirmWrapper.DraftCartLineList}
              experiences={mapExperiences<AffirmFeatureFlagSchema>(
                affirmFeatureFlagQueryData.nt_experiences
              )}
            />
          ) : (
            <DraftCartLineList />
          )}
          {isUs ? (
            <div className="mt-6">
              <Experience
                {...affirmFeatureFlagQueryData}
                component={AffirmWrapper.MonthlyOption}
                experiences={mapExperiences<AffirmFeatureFlagSchema>(
                  affirmFeatureFlagQueryData.nt_experiences
                )}
                passthroughProps={{
                  // TODO: Uncomment when BMSMv2 Experiment is over
                  // discountedPrice={priceTotals.discountedTotal}
                  discountedPrice: BMSMDraftCartDiscountedTotal,
                  stacked: true,
                  subTotalPrice: priceTotals.total
                }}
              />
            </div>
          ) : null}
        </div>
        {/* Monitoring options */}
        {!isSS2Upgrade && (
          <h2 className="mb-4 text-xl font-medium md:text-4xl">
            {microCopy['monitoring']}
          </h2>
        )}
        {isUs && !isSS2Upgrade ? (
          <div>
            {odmonTestObserver}
            <Experience
              {...odmonExperienceData.data}
              component={OdmonMonitoringDraftCart}
              experiences={odmonExperienceData.experiences}
              passthroughProps={{
                hasOutdoorCamera: hasOutdoorCameraInDraftCart,
                priceToggles,
                sku: dynamicDiscountsSku,
                total: priceTotals.total
              }}
            />
          </div>
        ) : (
          <DraftCartMonitoringWrapper
            priceToggles={priceToggles}
            sku={dynamicDiscountsSku}
          />
        )}
        {/* Pricing */}
        <DraftCartTotal
          basePackageSku={defaultBundle.baseProduct}
          baseProducts={defaultBundle?.products}
          setError={setError}
        />
        {/* Error text and add to cart button */}
        <div
          className={classNames(
            'items-center text-right text-xs md:flex md:text-lg',
            isUs ? 'justify-between' : 'justify-end'
          )}
        >
          {isUs ? (
            <div className="hidden flex-none md:mr-10 md:flex">
              <DraftCartShipping
                items={defaultBundle?.products}
                outOfStock={outOfStock}
                shippingEstimate={shippingEstimateDate}
              />
            </div>
          ) : (
            <p className="text-sale mb-0 mr-4 w-auto text-left text-xs empty:hidden md:mr-8">
              {error}
            </p>
          )}
          {isUs ? (
            <OdmonDraftCartAddToCartButton
              button={button}
              defaultBundle={defaultBundle}
              isValidCart={isValidCart}
              locale={locale}
              setError={setError}
            />
          ) : (
            <DraftCartAddToCartButton
              button={button}
              defaultBundle={defaultBundle}
              isValidCart={isValidCart}
              locale={locale}
              setError={setError}
            />
          )}
          {isUs ? (
            <div className="mt-4 block flex-none md:hidden">
              <DraftCartShipping
                items={defaultBundle?.products}
                outOfStock={outOfStock}
                shippingEstimate={shippingEstimateDate}
              />
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="mt-3 flex flex-col text-right">
          {/* Free shipping text */}
          {!isUs && (
            <p className="mb-2 md:hidden">{microCopy['free-shipping']}</p>
          )}

          {isUs ? (
            <div className="text-sale mt-2 w-auto text-right text-xs font-semibold empty:hidden">
              {error}
            </div>
          ) : (
            <DraftCartShipping
              items={defaultBundle?.products}
              outOfStock={outOfStock}
              shippingEstimate={shippingEstimateDate}
            />
          )}
          {!isValidCart && (
            <small className="mt-1">
              {microCopy['add-sensors-to-customize']}
            </small>
          )}
        </div>
      </div>
    </div>
  )
}

export default DraftCart
