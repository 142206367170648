import { isNotNil, nullToUndefined } from '@simplisafe/ewok'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe'

import { COOKIE_PARTNERS_DATA } from './constants'
import { getCookie, setCookie } from './cookies'

export type PartnerCookie = {
  readonly customShopUrl?: string
  readonly partnerGroup: string
  readonly partnerName: string
  readonly partnerUrl: string
  /**
   * currently only used by HUB partner, can hold member IDs for other partners in the future
   */
  readonly partnerMemberId?: string
}

export const setPartnerCookie = (partnerCookieObj: PartnerCookie) =>
  setCookie(COOKIE_PARTNERS_DATA, partnerCookieObj, cookiesOption)

export const getValueFromPartnerCookie = (
  partnerCookieKey: keyof PartnerCookie
): string | null =>
  getCookie<Record<string, string | undefined>>(COOKIE_PARTNERS_DATA)?.[
    partnerCookieKey
  ] || null

export const getPartnerGroup = (): string | undefined =>
  nullToUndefined(getValueFromPartnerCookie('partnerGroup'))

export const getPartnerName = (): string | undefined =>
  nullToUndefined(getValueFromPartnerCookie('partnerName'))

export const getLogoLinkUrl = (linkUrl = '/') => {
  const partnerPageUrl = getValueFromPartnerCookie('partnerUrl')
  return isNotNil(partnerPageUrl) ? partnerPageUrl : linkUrl
}

export const getPartnerCookie = (): PartnerCookie | undefined =>
  getCookie(COOKIE_PARTNERS_DATA)
