import { contentfulImageSchema } from '@ecomm/contentful/components'
import { contentfulRichTextSchema } from '@ecomm/shared-components'
import { TypeOf, z } from '@simplisafe/ewok'

export const installationHowItWorksSectionSchema = z.object({
  __typename: z.literal('ContentfulCarousel'),
  description: contentfulRichTextSchema,
  items: z
    .object({
      headline: z.string(),
      image: contentfulImageSchema,
      description: z.object({
        raw: z.string()
      })
    })
    .array()
})

export type InstallationHowItWorksSectionSchema = TypeOf<
  typeof installationHowItWorksSectionSchema
>
