//@ts-nocheck
import { isFalsy } from '@simplisafe/ewok'
import { useEffect } from 'react'

/**
 * This is extracted to a function so it cane be tested
 */
export const getWindow = () => window

/** @deprecated Do not use ss-react-components*/
const useLockBodyScroll = (block?: boolean, isLockPosition = true) => {
  useEffect(() => {
    const _window = getWindow()

    const originalStyle = isFalsy(_window)
      ? ''
      : _window.getComputedStyle(document.body).overflow

    // eslint-disable-next-line functional/immutable-data -- legacy code
    document.body.style.overflow = block ? 'hidden' : originalStyle

    const originalStylePosition = isFalsy(_window)
      ? ''
      : _window.getComputedStyle(document.body).position

    // eslint-disable-next-line functional/immutable-data -- legacy code
    document.body.style.position =
      block && isLockPosition === true ? 'fixed' : originalStylePosition

    return function () {
      // eslint-disable-next-line functional/immutable-data -- legacy code
      document.body.style.overflow = originalStyle
      // eslint-disable-next-line functional/immutable-data -- legacy code
      document.body.style.position = originalStylePosition
    }
  }, [block, isLockPosition])
}

export default useLockBodyScroll
