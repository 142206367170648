import type { AddressValidation } from '@ecomm/data-simplisafe-api'
import { addAtomDebugLabel } from '@ecomm/utils'
import { atom, useAtomValue, useSetAtom } from 'jotai'

const addressValidationAtom = atom<AddressValidation | null>(null)

addAtomDebugLabel(addressValidationAtom, 'Address Validation')

export const useAddressValidationValue = () => {
  return useAtomValue(addressValidationAtom)
}

export const useSetAddressValidation = () => {
  return useSetAtom(addressValidationAtom)
}
