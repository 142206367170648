export const UNIQUE_SESSION_ID_PARAM = 'unique_session_id'
export const UID_PARAM = 'uid'
export const WIZARD_TYPE_PARAM = 'wizardType'

export function createGSBLaunchUrlParams(
  vid?: string,
  clientId?: string,
  sessionId?: string,
  type?: string
) {
  const searchParams = new URLSearchParams()
  type && searchParams.append(WIZARD_TYPE_PARAM, type)
  const uniqueSessionId = clientId && sessionId ? sessionId + clientId : null
  vid && searchParams.append(UID_PARAM, vid)
  uniqueSessionId &&
    searchParams.append(UNIQUE_SESSION_ID_PARAM, uniqueSessionId)
  return searchParams.toString()
}

export function appendParamsToGSBLaunchUrl(
  url: string,
  vid: string | undefined,
  clientId: string | undefined,
  sessionId: string | undefined
): string {
  if (!vid) {
    return url
  } else {
    return `${url}?${createGSBLaunchUrlParams(vid, clientId, sessionId)}`
  }
}
