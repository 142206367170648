/* eslint-disable functional/prefer-readonly-type */
import { safeProp } from '@simplisafe/monda'
import {
  GiftItemDTO,
  PriceData,
  Prices
} from '@simplisafe/ss-ecomm-data/prices/service'
import * as A from 'fp-ts/lib/Array'
import { constant, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import * as RNEA from 'fp-ts/lib/ReadonlyNonEmptyArray'
import * as R from 'fp-ts/lib/Record'
import * as S from 'fp-ts/lib/string'
import { Maybe } from 'monet'

export type PriceVariationsData = Pick<PriceData, 'fractionDigits' | 'price'>
export type PriceVariations = {
  readonly [sku: string]: PriceVariationsData
}

export const countFractionDigits = (decimalNumber: number): number =>
  pipe(
    decimalNumber,
    number => number.toString(),
    O.fromPredicate(S.includes('.')),
    O.match(constant(0), (stringDecimalNumber: string) =>
      pipe(stringDecimalNumber, S.split('.'), RNEA.last, S.size)
    )
  )

export const getPriceWithFractionDigits = (priceVariations: {
  [sku: string]: number
}): PriceVariations =>
  pipe(
    priceVariations,
    R.toEntries,
    A.map<[string, number], [string, PriceVariationsData]>(([sku, price]) => [
      sku,
      {
        fractionDigits: countFractionDigits(price),
        price: price * Math.pow(10, countFractionDigits(price))
      }
    ]),
    R.fromEntries
  )

export const mergePriceVariations = (
  priceVariations: PriceVariations,
  prices: Prices
): Prices =>
  pipe(
    prices,
    R.toEntries,
    A.map<[string, PriceData], [string, PriceData]>(([sku, price]) => [
      sku,
      {
        ...price,
        ...priceVariations[sku]
      }
    ]),
    R.fromEntries
  )

export const isPLAsku = (sku: string) => sku.includes('sscs3-pla-')

export const divideByFractionDigits =
  (sku: string, prices: Prices) => (price: number) => {
    const fractionDigits = safeProp(sku, prices)
      .chain(safeProp('fractionDigits'))
      .orJust(0)
    return price / Math.pow(10, fractionDigits)
  }

export const giftMatchesSku = (
  sku: string,
  gift: readonly GiftItemDTO[]
): Maybe<GiftItemDTO> => {
  const giftSku: readonly string[] = Maybe.some(
    gift.map((g: GiftItemDTO) => g.sku || '')
  ).getOrElse([])
  const giftMatchesSku = giftSku.filter(gift => gift.includes(sku)).length > 0
  return giftMatchesSku ? Maybe.some(gift[0]) : Maybe.none()
}

const getCentsSymbol = (locale: string) =>
  pipe(
    locale,
    l => S.Eq.equals('en-US', l),
    isUS => (isUS ? '¢' : 'p'),
    O.of
  )

const calculatePrice = (price: number) =>
  pipe(
    price,
    p => (p / 30) * 100,
    p => p.toString(),
    S.split('.'),
    RNEA.head,
    O.of
  )

/*
  Given a monthly price, return the daily price with the localized cents symbol.
  This function returns only the integer part of the calculated price. It turns
  the number into a string and removes the fractional part so that we
  avoid the rounding up produced by `toFixed()`
*/
export const localizeCents = (locale: string, price: number): string =>
  pipe(
    O.Do,
    O.bind('localeCent', () => getCentsSymbol(locale)),
    O.bind('price', () => calculatePrice(price)),
    O.map(({ localeCent, price }) => `${price}${localeCent}`),
    O.getOrElse(() => '')
  )
