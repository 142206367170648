import { graphql, useStaticQuery } from 'gatsby'

import plpPageSchema from './plpPageSchema'
import { parseObjectKey } from '@simplisafe/ewok'

export const useOdmonPLPQuery = () => {
  const staticQuery = useStaticQuery(graphql`
    query OdmonPlpTemplate {
      contentfulPackageListingPage(contentful_id: { eq: "2NZwUx14bynQ8WlEppNqv5" }) {
        ...plpPage
        ...plpPageVariations
      }
    }
  `)

  return parseObjectKey(
    staticQuery,
    'contentfulPackageListingPage',
    plpPageSchema
  )
}
