import { createMockRichText } from '@ecomm/utils'

import type { MonitoringPlanCardSchema } from './schema'

export const getMonitoringPlanCardMock = async (
  recommended = true
): Promise<MonitoringPlanCardSchema> => ({
  __typename: 'ContentfulMonitoringPlan',
  description: {
    raw: await createMockRichText('Description')
  },
  features: [
    {
      __typename: 'ContentfulMonitoringFeature',
      contentful_id: '12345',
      helpText: {
        raw: await createMockRichText('Feature 1 description')
      },
      icon: 'CheckMark',
      modal: {
        description: {
          raw: await createMockRichText('Feature 1 modal description')
        },
        headlineText: 'Feature 1 modal title',
        __typename: 'ContentfulModal'
      }
    },
    {
      __typename: 'ContentfulMonitoringFeature',
      contentful_id: '123456',
      helpText: {
        raw: await createMockRichText('Feature 2 description')
      },
      icon: 'XSolidIcon'
    }
  ],
  additionalFeatures: [
    {
      __typename: 'ContentfulIconAndText',
      contentful_id: '1234',
      icon: 'PoliceCar',
      text: {
        raw: await createMockRichText('Additional Feature 1')
      },
      title: ''
    },
    {
      __typename: 'ContentfulImageAndText',
      contentful_id: '5678',
      image: {
        url: '/image-test',
        description: 'Test Image',
        originalHeight: 100,
        originalWidth: 100
      },
      text: {
        raw: await createMockRichText('Additional Feature 2')
      }
    }
  ],
  cta: 'Add free month',
  disclaimer: 'some disclaimer',
  recommended: recommended,
  name: 'Standard Monitoring',
  sku: '100',
  planConfirmationModal: {
    title: 'Test Title',
    description: 'Test Description',
    recommendedText: 'Test text'
  }
})

export const getMonitoringPlanCardWithoutCtaMock = async (
  recommended = true
): Promise<MonitoringPlanCardSchema> => ({
  __typename: 'ContentfulMonitoringPlan',
  description: {
    raw: await createMockRichText('Description')
  },
  features: [
    {
      __typename: 'ContentfulMonitoringFeature',
      contentful_id: '12345',
      helpText: {
        raw: await createMockRichText('Feature 1 description')
      },
      icon: 'CheckMark',
      modal: {
        description: {
          raw: await createMockRichText('Feature 1 modal description')
        },
        headlineText: 'Feature 1 modal title',
        __typename: 'ContentfulModal'
      }
    },
    {
      __typename: 'ContentfulMonitoringFeature',
      contentful_id: '123456',
      helpText: {
        raw: await createMockRichText('Feature 2 description')
      },
      icon: 'XSolidIcon'
    }
  ],
  additionalFeatures: [
    {
      __typename: 'ContentfulIconAndText',
      contentful_id: '1234',
      icon: 'PoliceCar',
      text: {
        raw: await createMockRichText('Additional Feature 1')
      },
      title: ''
    },
    {
      __typename: 'ContentfulImageAndText',
      contentful_id: '5678',
      image: {
        url: '/image-test',
        description: 'Test Image',
        originalHeight: 100,
        originalWidth: 100
      },
      text: {
        raw: await createMockRichText('Additional Feature 2')
      }
    }
  ],
  cta: '',
  disclaimer: 'some disclaimer',
  recommended: recommended,
  name: 'Standard Monitoring',
  sku: '100',
  planConfirmationModal: {
    title: 'Test Title',
    description: 'Test Description',
    recommendedText: 'Test text'
  }
})

export const mockLocationObject = {
  pathname: '/choose-monitoring2',
  search: '',
  hash: '',
  href: 'https://localhost:8000/choose-monitoring2',
  origin: 'https://localhost:8000',
  protocol: 'https:',
  host: 'localhost:8000',
  hostname: 'localhost',
  port: '8000',
  state: null,
  key: 'initial'
}
