import classNames from 'classnames'
import React from 'react'

type Props = {
  readonly className?: string
  readonly description: string
}

export function GuaranteeBanner({ className, description }: Props) {
  return (
    <div
      className={classNames(
        'bg-neutral-light-50 rounded-[20px] p-2 px-6 leading-[normal] lg:p-1',
        className
      )}
      data-component="GuaranteeBanner"
    >
      <div className="mt-2 flex items-center justify-center">
        <div className="ml-4 p-3">
          <img
            alt="100% statisfaction guaranteed"
            height={92}
            loading="lazy"
            src="/icons/guarantee-icon.png"
            width={92}
          />
        </div>
        <div className="ml-4 flex flex-col justify-center gap-2 pr-4 text-xs text-neutral-black">
          <p className="my-0">{description}</p>
          <p className="my-0">
            Questions? Call <span className="font-bold">1-800-920-2420</span>
          </p>
        </div>
      </div>
    </div>
  )
}
