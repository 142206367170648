export function devError<E, D>(err: E, details?: D) {
  if (
    process.env['NODE_ENV'] === 'development' ||
    import.meta.env?.MODE === 'development'
  ) {
    details ? console.error(err, details) : console.error(err)
    return undefined
  } else {
    return undefined
  }
}
