import { parseContentfulJson } from '@ecomm/micro-copy'
import { ContentfulRichText, Modal } from '@ecomm/shared-components'
import { graphql, useStaticQuery } from 'gatsby'
import { useState } from 'react'

import { refurbishedBannerSchema } from './schema'

export default function RefurbishedPLPBanner() {
  const [modalOpen, setModalOpen] = useState(false)

  const data = refurbishedBannerSchema.parse(
    useStaticQuery(graphql`
      query RefurbishedBannerTitles {
        copy: contentfulJson(contentful_id: { eq: "6EgsH6Xs4CdXeHlS25YVCH" }) {
          ...json
        }
        modalData: contentfulModal(
          contentful_id: { eq: "AYsNLnwhONGA0LzU18pvy" }
        ) {
          contentful_id
          headline
          description {
            raw
          }
        }
      }
    `)
  )

  const copy = parseContentfulJson(data, 'copy')
  const modalData = data.modalData

  return (
    <div className="prose md:prose-md lg:prose-lg flex w-full flex-col justify-center p-4 text-center md:p-8">
      <h1 className="mb-4 md:mb-6">{copy.get('text_1')}</h1>
      <h2 className="font-default text-heading-4-size leading-h4-height text-complementary-red-100 mb-4 font-bold md:mb-8">
        {copy.get('text_2')}
      </h2>
      <p className="mb-0">
        {copy.get('text_3')}
        <button
          className="text-body-size mt-4 cursor-pointer border-none bg-transparent underline hover:no-underline md:ml-2 md:mt-0 md:p-0"
          onClick={() => setModalOpen(true)}
        >
          {copy.get('product_warranty_button')}
        </button>
        {copy.get('text_4')}
      </p>
      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
        <div className="prose md:prose-md lg:prose-lg whitespace-pre-line p-4 md:p-8 lg:p-16">
          <ContentfulRichText raw={modalData.description.raw} />
        </div>
      </Modal>
    </div>
  )
}
