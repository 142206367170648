import type { TrackEvent } from '../../../../util/analytics'

export const onApplyCouponTrack = (
  trackEvent: TrackEvent,
  promoCode: string,
  isSuccess: boolean
) =>
  trackEvent({
    action: 'click',
    event: 'coupon_apply_button_click',
    response: isSuccess ? 'Valid' : 'Invalid',
    promoCode
  })

export const onOpenCouponFormTrack = (trackEvent: TrackEvent, label: string) =>
  trackEvent({
    action: 'click',
    event: 'coupon_change_add_code_click',
    label
  })
