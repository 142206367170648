import { graphql } from 'gatsby'
import { createContext } from 'react'

import type { ContentfulSiteWideMessages } from '../../graphql'

/**
 * Used to make content from the Site-Wide Messages singleton content type available to descendant components.
 */
// @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
export const SiteWideMessagesContext =
  createContext<ContentfulSiteWideMessages>({})

export const query = graphql`
  #graphql
  fragment siteWideMessages on ContentfulSiteWideMessages {
    addToCartError
    addToCartErrorUnrecoverable
  }
`
