import { match } from 'ts-pattern'

import { useCartState } from './useCartState'

export const useCartLoaded = () => {
  const jotaiCartState = useCartState()
  const jotaiCartLoaded = match(jotaiCartState)
    .with('empty', () => true)
    .with('with_items', () => true)
    .with('updating', () => false)
    .with('error', () => false)
    .with('no_cart_id', () => false)
    .with('not_initialized', () => false)
    .exhaustive()
  return jotaiCartLoaded
}
