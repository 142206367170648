import { useLocale } from '@ecomm/data-hooks'
import { useHandleFormSubmission } from '@ecomm/promotions-hooks'
import { getValueFromLeadData } from '@ecomm/shared-cookies'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useContext } from 'react'

import { LeadFormContext } from '../../PromoBannerWrapper/leadFormContext'
import { PhoneLeadCaptureForm } from '../experiments/PhoneLeadCaptureForm'
import { LeadCaptureForm } from './LeadCaptureForm'
import { LeadCaptureSuccess } from './LeadCaptureSuccess'

type ExperienceContentProps = {
  readonly id: string
  readonly json: { readonly tag: string }
}

function Baseline() {
  const { isFormSubmitted } = useHandleFormSubmission()
  return !isFormSubmitted ? <LeadCaptureForm /> : <LeadCaptureSuccess />
}

function ExperienceContentWrapper({ json }: ExperienceContentProps) {
  const phoneNumber = getValueFromLeadData('phone')
  return json.tag === 'variant' && phoneNumber === null ? (
    <PhoneLeadCaptureForm />
  ) : (
    <Baseline />
  )
}

export function LeadCapture() {
  const experimentData = useContext(LeadFormContext)
  const locale = useLocale()

  return (
    <div className="px-4 pb-4 lg:p-0">
      {experimentData && locale === 'en-US' ? (
        <Experience
          {...experimentData}
          component={ExperienceContentWrapper}
          experiences={mapExperiences<ExperienceContentProps>(
            experimentData.nt_experiences
          )}
        />
      ) : (
        <Baseline />
      )}
    </div>
  )
}
