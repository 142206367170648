import { z } from 'zod'

import { usStatesAndTerritoriesRegex } from './regex'

export const usStateShema = z
  .string({ required_error: 'State is a required field' })
  .refine(
    val => usStatesAndTerritoriesRegex.test(val),
    val => ({ message: `${val} is not a valid US state` })
  )

export const ukStateSchema = z.string().nullable().optional()
