import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

const inputSchema = z.object({
  title: z.string(),
  inputName: z.string(),
  type: z.string(),
  placeholder: z.string().nullish(),
  options: z.string().array().nullish(),
  required: z.boolean().nullish()
})

export const businessFormSchema = z.object({
  __typename: z.literal('ContentfulFormV2'),
  form: inputSchema.array(),
  button: z.object({
    text: z.string()
  }),
  note: contentfulRichTextSchema,
  successMessage: contentfulRichTextSchema
})

export type BusinessFormSchema = TypeOf<typeof businessFormSchema>
