import { Set as ImmutableSet } from 'immutable'
import { useEffect, useState } from 'react'

/**
 * The responsibility of this hook is, give a list of promo codes,
 * return an array of the ones that should be applied.
 *
 * @param currentPromoCode string
 * @param currentPromoCodeWithMonitoring: string
 * @param dynamicPromoCodes readonly string[]
 * @param partnerPromoCodes readonly string[]
 * @param hasUtmCode boolean
 * @param utmCodes readonly string[]
 */
export function useSetCurrentDiscountCodes(
  currentPromoCode: string,
  currentPromoCodeWithMonitoring: string,
  dynamicPromoCodes: readonly string[],
  partnerPromoCodes: readonly string[],
  hasUtmCode: boolean,
  utmCodes: readonly string[]
) {
  const [applicablePromoCodes, setApplicablePromoCodes] = useState(
    ImmutableSet<string>([])
  )

  useEffect(() => {
    hasUtmCode &&
      utmCodes.forEach((code: string) =>
        setApplicablePromoCodes(s => s.add(code))
      )

    // apply active promo if one is defined and apply no other codes
    !hasUtmCode &&
      !!currentPromoCode &&
      setApplicablePromoCodes(v => v.add(currentPromoCode))

    !hasUtmCode &&
      !!currentPromoCodeWithMonitoring &&
      setApplicablePromoCodes(v => v.add(currentPromoCodeWithMonitoring))

    // Partner Override Promo Code
    !hasUtmCode &&
      !!partnerPromoCodes &&
      partnerPromoCodes.forEach(code =>
        setApplicablePromoCodes(v => v.add(code))
      )

    !hasUtmCode &&
      !!dynamicPromoCodes &&
      dynamicPromoCodes.forEach(code =>
        setApplicablePromoCodes(v => v.add(code))
      )
  }, [
    currentPromoCode,
    currentPromoCodeWithMonitoring,
    dynamicPromoCodes,
    hasUtmCode,
    utmCodes
  ])

  return {
    applicablePromoCodes: Array.from(applicablePromoCodes.values()).filter(
      Boolean
    )
  }
}
