import { ninetailedFeatureFlagSchema } from '@ecomm/promotions-components'
import { parseObjectKey } from '@simplisafe/ewok'
import { graphql, useStaticQuery } from 'gatsby'

export const usePromoBannerExperimentQuery = () => {
  const promoBannerExperiment = useStaticQuery(graphql`
        fragment shopFeatureFlagBase on ContentfulNinetailedFeatureFlag {
          id: contentful_id
          title
          json {
            id
            tag
          }
        }

      fragment shopFeatureFlagExperience on ContentfulNinetailedExperience {
        id: contentful_id
        name: nt_name
        type: nt_type
        audience: nt_audience {
          id: contentful_id
          name: nt_name
        }
        config: nt_config {
          components {
            baseline {
              id
            }
            variants {
              id
              hidden
            }
          }
          traffic
          distribution
        }
        variants: nt_variants {
          ... on ContentfulNinetailedFeatureFlag {
            ...shopFeatureFlagBase
          }
        }
      }
      query ShopPromoBannerExperiment {
        promoBannerExperiment: contentfulNinetailedFeatureFlag(
          contentful_id: { eq: "1GaRfPYfJQJuediSrCar1r" }
        ) {
          ...shopFeatureFlagBase
          nt_experiences {
            ...shopFeatureFlagExperience
          }
        }
      }
    `)

  return parseObjectKey(
    promoBannerExperiment,
    'promoBannerExperiment',
    ninetailedFeatureFlagSchema
  )
}
