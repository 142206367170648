import { LoadingSpinner } from '@ecomm/checkout-icons'
import classNames from 'classnames'

type SubmitButtonProps = {
  /* onClick function */
  readonly onClick?: (e: React.MouseEvent<HTMLElement>) => void
  /* Button type */
  readonly type?: 'button' | 'submit'
  /* button text */
  readonly text: string
  /* The is loading status */
  readonly disableSubmit: boolean
  /* Should render the spinner or not */
  readonly spinner?: boolean
  /* button class */
  readonly className?: string
}

export function SubmitButton({
  type = 'submit',
  text,
  spinner = true,
  disableSubmit,
  onClick = () => null,
  className = ''
}: SubmitButtonProps) {
  return (
    <button
      className={classNames(
        `rounded-base w-full min-w-[176px] cursor-pointer border-2 border-solid px-6 py-3 transition-[background,border] duration-200  
        ease-out md:h-[56px] md:w-auto`,
        {
          'border-btn-primary bg-btn-primary hover:border-[#0a5dbd] hover:bg-[#0a5dbd]':
            !disableSubmit,
          'border-neutral-medium bg-neutral-medium hover:border-neutral-medium hover:bg-neutral-medium':
            disableSubmit && !spinner,
          'border-[#0a5dbd] bg-[#0a5dbd] ': disableSubmit && spinner,
          'cursor-not-allowed': disableSubmit
        },
        className
      )}
      data-component="checkout_submit_button"
      data-testid="checkout_submit_button"
      onClick={onClick}
      type={type}
    >
      {disableSubmit && spinner ? (
        <LoadingSpinner />
      ) : (
        <span className="flex justify-center text-base text-white md:text-lg">
          {text}
        </span>
      )}
    </button>
  )
}
