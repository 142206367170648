import { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { COOKIE_EMAIL, getCookie } from '@ecomm/shared-cookies'
import { isNotEmpty } from '@simplisafe/ewok'
import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'

export function useReturningCustomerAddress(
  loginSuccess: boolean,
  userAddress: Partial<CheckoutFormValuesType>,
  setUserAddress: React.Dispatch<
    React.SetStateAction<Partial<CheckoutFormValuesType>>
  >
) {
  /*
    Set all field values, but do not let Formik validate them immediately.
    Then set them as touched, also do not validate.
    Then finally validate the entire form.

    See https://github.com/jaredpalmer/formik/issues/2059
  */

  const { setFieldValue, setFieldTouched, isSubmitting, validateForm } =
    useFormikContext<CheckoutFormValuesType>()

  const populateValues = (userAddress: Partial<CheckoutFormValuesType>) => {
    Promise.all([
      setFieldValue('postalCode', userAddress.postalCode, false),
      setFieldValue(
        'additionalStreetInfo',
        userAddress.additionalStreetInfo,
        false
      ),
      setFieldValue(
        'email',
        userAddress.email || getCookie(COOKIE_EMAIL),
        false
      ),
      setFieldValue('firstName', userAddress.firstName, false),
      setFieldValue('lastName', userAddress.lastName, false),
      setFieldValue('streetName', userAddress.streetName, false),
      setFieldValue('phone', userAddress.phone, false),
      setFieldValue('city', userAddress.city, false),
      setFieldValue('state', userAddress.state, false),
      // set offerAndTip field to false here because we don't want to register returning customers as a lead
      setFieldValue('offerAndTip', false, false)
    ])
      .then(() => {
        setFieldTouched('postalCode', true, false),
          setFieldTouched('email', true, false),
          setFieldTouched('firstName', true, false),
          setFieldTouched('lastName', true, false),
          setFieldTouched('streetName', true, false),
          setFieldTouched('phone', true, false),
          setFieldTouched('city', true, false),
          setFieldTouched('state', true, false)

        // appease the lint demons
        return null
      })
      .then(() => validateForm())
      .then(() => setUserAddress({}))
      // swallow errors on this operation
      .catch()
  }

  useEffect(() => {
    loginSuccess &&
      isNotEmpty(userAddress) &&
      !isSubmitting &&
      populateValues(userAddress)
  }, [isSubmitting, loginSuccess, userAddress])
}
