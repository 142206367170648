import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { Price } from '@ecomm/ss-react-components'
import { useOptimizelyParams, userAttributes } from '@ecomm/tracking'
import { isNotNil, overloadMaybe } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import { chainProp } from '@simplisafe/monda/chain'
import { selectCustomerGroupKey } from '@simplisafe/ss-ecomm-data/cart/select'
import { Package } from '@simplisafe/ss-ecomm-data/packages'
import {
  DynamicDiscount,
  GiftItemDTO,
  Prices,
  requestPrices
} from '@simplisafe/ss-ecomm-data/prices/service'
import { Product } from '@simplisafe/ss-ecomm-data/products'
import { selectItemsFromSkus } from '@simplisafe/ss-ecomm-data/redux/select'
import * as A from 'fp-ts/lib/Array'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { Maybe, None } from 'monet'
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useSelector } from 'react-redux'

import {
  absoluteDiscountToRelative,
  formatDisplayPrice,
  formatPercentage
} from '../../commercetools/price'
import useIsPartner from '../../hooks/useIsPartner'
import { findFirstJust } from '../../util/helper'
import { usePriceVariations } from './../../hooks/usePriceVariation'
import { formatPrice, PriceFormatter } from './formatter'
import { PriceContextProps } from './types'
import {
  divideByFractionDigits,
  getPriceWithFractionDigits,
  giftMatchesSku,
  isPLAsku,
  mergePriceVariations
} from './utils'

const PriceContext = createContext<PriceContextProps>({
  getDiscountedPrice: (_sku: string, _months?: number) => None(),
  getDiscountedPriceWithServicePlan: (_sku: string, _months?: number) => None(),
  getDiscountedText: (_sku: string, _months?: number) => None(),
  getDiscountedTextWithServicePlan: (_sku: string, _months?: number) => None(),
  getDynamicDiscountsThresholds: (_sku: string) => O.none,
  getDynamicDiscountedPrice: (_totalPrice: number, _sku: string) => O.none,
  getFormattedPrice:
    (_skuID, _months?: number) => (_formatter, _showDiscountedPrice) =>
      null,
  getFreeGiftItems: (_sku: string): Maybe<GiftItemDTO> => None(),
  getFreeGiftItemsWithServicePlan: (_sku: string): Maybe<GiftItemDTO> => None(),
  getPrice: (_sku: string, _months?: number) => None()
})

export const usePriceContext = () => useContext(PriceContext)

/**
 * Renders formatted price details for the given sku, optionally including strikethrough price (default: true) and service plan discount (default: false).
 */
export const getFormattedPriceHelper =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string, months = 1) =>
    function (
      formatter: PriceFormatter,
      showDiscountedPrice = true,
      isServiceDiscount = true
    ) {
      const price = getRawPrice(prices, fallbacks, isLoading)(sku, months)
      const discountedPrice = getRawDiscountedPrice(
        prices,
        fallbacks,
        isLoading
      )(sku, months)
      const discountedPriceWithServicePlan =
        getRawDiscountedPriceWithServicePlan(
          prices,
          fallbacks,
          isLoading
        )(sku, months)
      getFreeGiftItemHelper(prices)(sku)
      getFreeGiftItemWithServicePlanHelper(prices)(sku)
      const finalDiscountedPrice =
        isServiceDiscount && discountedPriceWithServicePlan.isSome()
          ? discountedPriceWithServicePlan
          : discountedPrice

      const hasDiscount = finalDiscountedPrice
        .map(discountedPrice =>
          price.cata(
            () => false,
            _price => discountedPrice !== _price
          )
        )
        .orJust(false)

      return (
        <Price
          discountedPrice={
            showDiscountedPrice && hasDiscount
              ? formatPrice(finalDiscountedPrice, formatter)
              : undefined
          }
          regularPrice={formatPrice(price, formatter)}
        />
      )
    }

const getPriceData = (sku: string, prices: Prices) =>
  safeProp(sku, prices).filter(priceData => isNotNil(priceData.price))

export const getRawPrice =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string, months = 1) =>
    getPriceData(sku, prices).cata<Maybe<number>>(
      () =>
        isPLAsku(sku) && isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(safeProp('price')),
      _priceData =>
        safeProp('price', _priceData)
          .map(divideByFractionDigits(sku, prices))
          .map(price => price / months)
    )

export const getRawDiscountedPrice =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string, months = 1) =>
    getPriceData(sku, prices).cata<Maybe<number>>(
      () =>
        isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(chainProp('discountedPrice')),
      _priceData =>
        safeProp('discountedPrice', _priceData)
          .map(divideByFractionDigits(sku, prices))
          .map(price => price / months)
    )

export const getRawDiscountedPriceWithServicePlan =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    isLoading: boolean
  ) =>
  (sku: string, months = 1) =>
    getPriceData(sku, prices).cata<Maybe<number>>(
      () =>
        isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(
              chainProp('discountedPriceWithServicePlan')
            ),
      _priceData =>
        safeProp('discountedPriceWithServicePlan', _priceData)
          .map(divideByFractionDigits(sku, prices))
          .map(price => price / months)
    )

export const getDiscountedTextHelper =
  (
    prices: Prices,
    fallbacks: Record<string, Package | Product>,
    hasServicePlan: boolean,
    isLoading: boolean,
    showAbsoluteDiscountAsRelative = false
  ) =>
  (sku: string, months = 1) =>
    getPriceData(sku, prices).cata<Maybe<string>>(
      () =>
        isLoading
          ? None()
          : safeProp(sku, fallbacks).chain(product =>
              product['@@type'] === 'package'
                ? findFirstJust([
                    (hasServicePlan
                      ? product.relativeDiscountWithServicePlan
                      : product.relativeDiscount
                    ).map(formatPercentage),
                    showAbsoluteDiscountAsRelative
                      ? Maybe.of(absoluteDiscountToRelative)
                          .apTo(Maybe.fromUndefined(product.price))
                          .apTo(
                            hasServicePlan
                              ? product.absoluteDiscountWithServicePlan
                              : product.absoluteDiscount
                          )
                          .chain(percent => percent.map(formatPercentage))
                      : (hasServicePlan
                          ? product.absoluteDiscountWithServicePlan
                          : product.absoluteDiscount
                        ).chain(formatDisplayPrice)
                  ])
                : None()
            ),
      _priceData => {
        const price = safeProp('price', _priceData).map(price => price / 100)

        const absoluteDiscount = safeProp(
          hasServicePlan
            ? 'absoluteDiscountWithServicePlan'
            : 'absoluteDiscount',
          _priceData
        )
          .map(price => price / 100)
          .map(price => price / months)

        const absoluteDiscountAsRelative = Maybe.of(absoluteDiscountToRelative)
          .apTo(price)
          .apTo(absoluteDiscount)

        const relativeDiscount = safeProp(
          hasServicePlan
            ? 'relativeDiscountWithServicePlan'
            : 'relativeDiscount',
          _priceData
        )
          .map(price => price / 100)
          .map(price => price / months)

        return findFirstJust([
          showAbsoluteDiscountAsRelative
            ? absoluteDiscountAsRelative.chain(percent =>
                percent.map(formatPercentage)
              )
            : absoluteDiscount.chain(formatDisplayPrice),
          relativeDiscount.map(formatPercentage)
        ])
      }
    )

export const getFreeGiftItemHelper =
  (prices: Prices) =>
  (sku: string): Maybe<GiftItemDTO> =>
    getPriceData(sku, prices).cata<Maybe<GiftItemDTO>>(
      () => Maybe.none(),
      _priceData => {
        return safeProp('withoutMonitoringGifts', _priceData).cata(
          () => Maybe.none(),
          (gift: readonly GiftItemDTO[]) => giftMatchesSku(sku, gift)
        )
      }
    )

export const getFreeGiftItemWithServicePlanHelper =
  (prices: Prices) =>
  (sku: string, shouldGiftItemMatchSku = true): Maybe<GiftItemDTO> =>
    getPriceData(sku, prices).cata<Maybe<GiftItemDTO>>(
      () => Maybe.none(),
      _priceData => {
        return safeProp('withMonitoringGifts', _priceData).cata(
          () => Maybe.none(),
          (gift: readonly GiftItemDTO[]) =>
            shouldGiftItemMatchSku
              ? giftMatchesSku(sku, gift)
              : // Sometimes we don't need the gift item to have a matching SKU and just want to retrieve it from the response
                gift.length
                ? Maybe.some(gift[0])
                : Maybe.none()
        )
      }
    )

export const getDynamicDiscountsThresholdsHelper =
  (prices: Prices) =>
  (sku: string): O.Option<readonly DynamicDiscount[]> =>
    pipe(
      overloadMaybe(
        getPriceData(sku, prices).cata<Maybe<readonly DynamicDiscount[]>>(
          () => None(),
          ({ dynamicDiscounts = [], fractionDigits = 2 }) =>
            Maybe.some(
              dynamicDiscounts.map(d => ({
                ...d,
                threshold: {
                  ...d.threshold,
                  lowerThreshold:
                    d.threshold.lowerThreshold / Math.pow(10, fractionDigits),
                  upperThreshold:
                    d.threshold.upperThreshold / Math.pow(10, fractionDigits)
                },
                value: d.value / Math.pow(10, fractionDigits)
              }))
            )
        )
      )
    )

export const getMatchingDynamicPrice =
  (totalPrice: number) =>
  (
    dynamicDiscounts: O.Option<readonly DynamicDiscount[]>
  ): O.Option<DynamicDiscount> =>
    pipe(
      dynamicDiscounts,
      O.fold(constant([]), identity),
      A.findFirst(
        ({ threshold: { lowerThreshold, upperThreshold } }) =>
          totalPrice >= lowerThreshold && totalPrice < upperThreshold
      )
    )

export const getDynamicDiscountedPriceHelper =
  (prices: Prices) =>
  (totalPrice: number, sku: string): O.Option<number> =>
    pipe(
      getDynamicDiscountsThresholdsHelper(prices)(sku),
      getMatchingDynamicPrice(totalPrice),
      O.map(({ discountType, value: discount }) => {
        if (discountType === 'absolute') {
          return totalPrice - discount
        } else {
          const roundedTotalPrice = Math.floor(totalPrice * 100) / 100
          const relativeDiscount = roundedTotalPrice * (discount / 100)
          return Math.floor((roundedTotalPrice - relativeDiscount) * 100) / 100
        }
      })
    )

type PriceProviderProps = {
  readonly children: ReactNode | readonly ReactNode[]
  readonly skus: readonly string[]
}

const excludedSkus = ['SSPSH-ON', 'SSPSH-UK']

export function PriceProvider({ children, skus }: PriceProviderProps) {
  const [prices, setPrices] = useState<Prices>({})
  const [isLoading, setLoading] = useState(true)
  const customerGroupMaybe = useSelector(selectCustomerGroupKey)
  const customerGroupCookie =
    getValueFromPartnerCookie('partnerGroup') || undefined
  const optimizelyParams = useOptimizelyParams()
  const partnerName = getValueFromPartnerCookie('partnerName') || undefined

  const fallbacks = useSelector(selectItemsFromSkus(skus))

  const customerGroup = customerGroupCookie || customerGroupMaybe.orUndefined()
  const isPartner = useIsPartner()
  const priceVariations = usePriceVariations(skus)
  const priceExperiments = pipe(
    isPartner,
    O.fromPredicate(Boolean),
    O.match(
      () =>
        pipe(
          priceVariations,
          O.map(getPriceWithFractionDigits),
          O.getOrElse(constant({}))
        ),
      constant({})
    )
  )

  useEffect(() => {
    const includedSkus = skus.filter(sku => !excludedSkus.includes(sku))
    const attributes = userAttributes()
    includedSkus.length > 0 &&
      requestPrices(
        includedSkus,
        attributes,
        customerGroup,
        optimizelyParams,
        partnerName
      )(() => {
        setLoading(false)
      })(response => {
        response.cata(
          () => null,
          newPrices => {
            const mergedPricesVariations = mergePriceVariations(
              priceExperiments,
              newPrices
            )
            setPrices(mergedPricesVariations)
          }
        )
        setLoading(false)
      })
    // TODO: With exhaustive deps, the pricing service gets hit ~10 times per page load. Something in Page/index.tsx is creating unnecessary rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerGroup])

  return (
    <PriceContext.Provider
      value={{
        getDiscountedPrice: getRawDiscountedPrice(prices, fallbacks, isLoading),
        getDiscountedPriceWithServicePlan: getRawDiscountedPriceWithServicePlan(
          prices,
          fallbacks,
          isLoading
        ),
        getDiscountedText: getDiscountedTextHelper(
          prices,
          fallbacks,
          false,
          isLoading
        ),
        getDiscountedTextWithServicePlan: getDiscountedTextHelper(
          prices,
          fallbacks,
          true,
          isLoading
        ),
        getDynamicDiscountsThresholds:
          getDynamicDiscountsThresholdsHelper(prices),
        getDynamicDiscountedPrice: getDynamicDiscountedPriceHelper(prices),
        getFormattedPrice: getFormattedPriceHelper(
          prices,
          fallbacks,
          isLoading
        ),
        getFreeGiftItems: getFreeGiftItemHelper(prices),
        getFreeGiftItemsWithServicePlan:
          getFreeGiftItemWithServicePlanHelper(prices),
        getPrice: getRawPrice(prices, fallbacks, isLoading)
      }}
    >
      {children}
    </PriceContext.Provider>
  )
}
