import { webAppUrl } from '@ecomm/data-env-variables'
import {
  CardboardBox,
  Cart,
  HeadsetPerson,
  HomeBase,
  MagicStick,
  PencilAndRuler,
  Phone,
  PoliceLogo,
  Profile,
  SimpliSafeAppIcon
} from '@ecomm/shared-icons'
import type { Locale } from '@ecomm/utils'

// Minimum width in pixels needed to display header in desktop mode. See https://www.figma.com/file/qITFbwWanfaVjL3Vo4A0vC/
export const NARROWEST_DESKTOP_SIZE = 1126

export const getMainMenuItems = (locale: Locale) => [
  {
    hasDropdown: true,
    dropdownMenu: 'PackagesDropdownMenu',
    text: locale === 'en-US' ? 'Shop Packages' : 'Shop',
    mobileIcon: <CardboardBox className="h-6 w-6" />
  },
  {
    text: 'Build My System',
    url: '/build-my-system',
    mobileIcon: <PencilAndRuler className="h-6 w-6" />
  },
  {
    text: locale === 'en-US' ? 'Take Our Quiz' : 'Get A Quote',
    mobileIcon: <MagicStick className="h-[29px] w-[29px]" />,
    openQuiz: true,
    id: '1L1QlxR38aLrLL718EWGUh'
  },
  {
    hasDropdown: true,
    dropdownMenu: 'ServicesDropdownMenu',
    text: locale === 'en-US' ? 'Services' : 'How It Works',
    mobileIcon: <PoliceLogo className="h-6 w-6" />
  }
]

export type CourtesyNavItemType = {
  readonly displayInSideNav?: boolean
  readonly icon?: React.ReactNode
  readonly hasDropdown?: boolean
  readonly dropdownMenu?: string
  readonly text: string
  readonly url?: string
  readonly mobileOnly?: boolean
  readonly openQuiz?: boolean
}

export const cartItem: CourtesyNavItemType = {
  text: 'Cart',
  icon: <Cart className="h-6 w-6" />,
  url: '/cart'
}

export const phoneItem: CourtesyNavItemType = {
  text: 'Call',
  icon: <Phone className="h-6 w-6" />,
  mobileOnly: true
}

export const getCourtesyNavItems = (locale: Locale) => [
  {
    text: locale === 'en-US' ? 'Take Our Quiz' : 'Get A Quote',
    openQuiz: true,
    mobileOnly: true,
    id: '1L1QlxR38aLrLL718EWGUh'
  },
  {
    displayInSideNav: true,
    hasDropdown: false,
    icon: <HeadsetPerson className="h-6 w-6" />,
    text: locale === 'en-US' ? 'Support' : 'Contact',
    url: locale === 'en-US' ? 'https://support.simplisafe.com/' : '/contact-us'
  },
  {
    displayInSideNav: true,
    hasDropdown: true,
    icon: <Profile className="mx-auto h-6 w-6" />,
    dropdownMenu: 'AccountDropdownMenu',
    text: 'Account'
  },
  cartItem
]

export type ServiceMenuItem = {
  readonly text: string
  readonly imageId: string
  readonly url: string
}

export const getServiceMenuItems = (
  locale: Locale,
  isOdmonVariant: boolean
): readonly ServiceMenuItem[] => [
  ...(locale === 'en-US'
    ? []
    : [
        {
          text: 'Meet the System',
          imageId: '2n9okk7IQZPtmVYvSD3KXg',
          url: '/meet-the-system'
        }
      ]),
  {
    text: isOdmonVariant
      ? 'Professional Monitoring'
      : '24/7 Professional Monitoring',
    imageId: isOdmonVariant
      ? '2gnj0MqS2RvadjpecJDG6K'
      : '3X477mLg2byYfBD0MzmdMe',
    url:
      locale === 'en-US'
        ? '/features-alarm-monitoring'
        : '/professional-alarm-monitoring'
  },
  {
    text: 'Installation Options',
    imageId: isOdmonVariant
      ? '75LoLEcNP7nJnbsRPpG3W6'
      : '4ISQo32VbzFoCuz0sPSEIb',
    url: '/installation'
  }
]

export const getPackagesMenuLeftItems = (locale: Locale) => [
  {
    text: 'Shop all packages',
    url: '/home-security-shop-packages'
  },
  ...(locale === 'en-US'
    ? [
        {
          text: 'Refurbished packages',
          url: '/refurb-home-security-systems-2'
        }
      ]
    : []),
  {
    caption: 'For existing customers:',
    text: 'Expand my system',
    url: '/alarm-sensors'
  }
]

export const getPackagesMenuMiddleItems = (
  locale: Locale,
  isOdmonVariant: boolean
) => [
  {
    title: 'Outdoor Elite Package',
    packageName: locale === 'en-US' ? 'The Beacon' : 'The Edinburgh',
    url:
      locale === 'en-US'
        ? '/home-security-system-beacon'
        : '/home-security-system-edinburgh',
    imageId: isOdmonVariant
      ? '4bC09EpnddkbaJd0Rw1GS8'
      : '30qjwcrVYFr7ZLmfts9yID'
  },
  {
    title: 'Outdoor Basic Package',
    packageName: locale === 'en-US' ? 'The Lighthouse' : 'The Tower',
    url:
      locale === 'en-US'
        ? '/home-security-system-lighthouse'
        : '/home-security-system-tower',
    imageId: isOdmonVariant
      ? '2S40uNCo4M8wvKgkKPQ17m'
      : '5C0JVOfwJLkJK6WF6NZ9PW'
  },
  {
    title: 'Indoor Basic Package',
    packageName: 'The Starter',
    url: '/home-security-system-starter',
    imageId: '6pToedl3jApJdlviDDSSFi'
  }
]

export const getPackageMenuRightItems = (
  locale: Locale,
  isOdmonVariant: boolean
) => ({
  title: locale === 'en-US' ? 'Customize A Package' : 'Customise A Package',
  description: 'Choose the cameras, sensors, and sirens that fit your needs',
  link: 'Build My System',
  url: '/build-my-system',
  imageId: isOdmonVariant ? '26vernKYKmkWfqIw6BiBFm' : '2H1lDKAlSNUC4E4nSolJIV'
})

export const accountMenuItems = [
  {
    text: 'Security dashboard',
    icon: (
      <SimpliSafeAppIcon className="h-8 w-8 rounded-md bg-white p-[2px] min-[1126px]:bg-transparent min-[1126px]:p-0" />
    ),
    url: webAppUrl()
  },
  {
    text: 'Expand my system',
    icon: <HomeBase className="h-8 w-8" />,
    url: '/alarm-sensors'
  }
]
