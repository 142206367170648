import React from 'react'

import { replaceTags } from '.'
import type { ComparisonTableProps } from '../components/ComparisonTable/types'
import { parseJSONDataFromContentful } from './parseJSONDataFromContentful'

export function transformToComparisonTableData(
  data: Record<string, unknown>,
  key?: string
): ComparisonTableProps {
  const comparisonTableData: {
    readonly header: ComparisonTableProps['header']
    readonly body: ComparisonTableProps['body']
    readonly footer: readonly string[]
  } = parseJSONDataFromContentful(data, key || 'comparisonTable')

  return {
    ...comparisonTableData,
    footer: (
      <span>
        {comparisonTableData.footer.map((str, index) => (
          <span className="mt-2 block" key={index}>
            {replaceTags(str)}
            <br />
          </span>
        ))}
      </span>
    )
  }
}
