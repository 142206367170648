import { addAtomDebugLabel } from '@ecomm/utils'
import { atom } from 'jotai'

import enGb from './en-GB'
import enUs from './en-US'

const microCopyAtom = atom({
  'en-GB': enGb,
  'en-US': enUs
})

addAtomDebugLabel(microCopyAtom, 'micro-copy')

export default microCopyAtom
