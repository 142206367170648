import { useEffect, useState } from 'react'

/**
 * Returns false during static builds and SSR, and true after the app has been
 * hydrated client-side. Can be used in order to avoid a flicker or hydration
 * errors when rendering different content during SSR vs client-side (e.g. a
 * loading skeleton).
 *
 * @example
 * function MyComponent() {
 *   const isReady = useReady()
 *   return isReady ? <div>Hello!</div> : <div>Loading...</div>
 * }
 */
export const useReady = () => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setReady(true)
  }, [])

  return ready
}
