import { useOdmonExperience } from '@ecomm/shared-ninetailed-odmon'

import AddToCartButton from '../../components/AddToCartButton'
import type { Props as AddToCartButtonPackageProps } from '../../components/AddToCartButton/AddToCartButtonPackage'

export function OdmonAddToCartButtonPackage(
  props: Omit<AddToCartButtonPackageProps, 'isOdmonVariant'>
) {
  const odmonExperienceData = useOdmonExperience()

  return (
    <AddToCartButton.Package
      {...props}
      isOdmonVariant={odmonExperienceData.isVariant}
    />
  )
}
