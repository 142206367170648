import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'

import { Locale } from '../../hooks/useLocalizedData'

const twoColumnImagesSchema = z.object({
  image1: gatsbyImageSchema.optional(),
  image2: gatsbyImageSchema.optional()
})

export const schema = {
  [Locale.EnGB]: twoColumnImagesSchema,
  [Locale.EnUS]: twoColumnImagesSchema
}
