//@ts-nocheck
import classNames from 'classnames'
import equals from 'ramda/src/equals'
import React, { FC, ReactNode } from 'react'

import * as css from './OfferTag.module.scss'

export type OfferTagProps = {
  readonly tagTextColor?: string
  /** The content of the component */
  readonly content?: ReactNode
  readonly className?: string
  readonly tagBackgroundColor?: string
  readonly borderColor?: string
  readonly dataComponent?: string
  readonly theme?: 'autoWidth' | 'default'
}

/** @deprecated Do not use ss-react-components*/
const OfferTag: FC<OfferTagProps> = ({
  tagTextColor,
  className = `${css.bannerOfferTag}`,
  content,
  tagBackgroundColor,
  borderColor,
  theme = 'default'
}: OfferTagProps) => {
  const style = {
    backgroundColor: tagBackgroundColor,
    color: tagTextColor,
    ...(borderColor && { borderColor: borderColor })
  }

  const defaultClasses = [
    { [css.autoWidth]: equals('autoWidth', theme) },
    { [css.offerBadge]: equals('autoWidth', theme) },
    { 'border-2 border-solid': !!borderColor }
  ]

  return content ? (
    <span
      className={classNames(
        className,
        'neutralWhiteTextColor',
        ...defaultClasses
      )}
      style={style}
    >
      {content}
    </span>
  ) : null
}

export default OfferTag
