import { TypeOf, z } from '@simplisafe/ewok'

export const subHeroTextSchema = z.object({
  copy: z.object({
    contentful_id: z.string(),
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export type SubHeroTextSchema = TypeOf<typeof subHeroTextSchema>
