import classNames from 'classnames'
import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

export type ListStyle = 'circular' | undefined

export type OrderedListProps = {
  readonly dataComponent?: string
  /** contents to render next to each list */
  readonly contents: readonly ReactNode[]
  /** enumerated styles to apply to the numbered bullet */
  readonly listStyle?: ListStyle
}

/** @deprecated Do not use ss-react-components*/
const OrderedList: FC<OrderedListProps> = ({
  dataComponent = OrderedList.name,
  contents,
  listStyle
}: OrderedListProps) => {
  const isCircularList = listStyle === 'circular' || !listStyle
  return (
    <ul
      className="prose prose-h3:text-base prose-h3:mb-tiny prose-h3:font-bold prose-h3:font-default md:prose-h3:text-lg m-0 p-0"
      data-component={dataComponent}
    >
      {contents.map((content, idx) => (
        <li
          className="flex list-none items-start pb-3 last-of-type:pb-0 md:pb-5 lg:pb-8"
          key={idx}
        >
          <span
            className={classnames({
              'leading-4 md:leading-6': !isCircularList,
              'bg-primary-100 mr-3 flex h-8 w-8 shrink-0 items-center justify-center rounded-full md:mr-5 md:h-12 md:w-12':
                isCircularList
            })}
          >
            {idx + 1}
          </span>
          <span className={classNames({ 'mt-1 md:mt-[9px]': isCircularList })}>
            {content}
          </span>
        </li>
      ))}
    </ul>
  )
}

export default OrderedList
