import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import {
  CountryRedirectModal,
  FAQSection,
  HeroBanner,
  toPromoBannerStyleValue,
  TrustpilotUKTopBanner,
  v2PopupQuoteWizard as PopupQuoteWizard
} from '@ecomm/shared-components'
import { TrackingProvider } from '@ecomm/tracking'
import { type Locale, SEO } from '@ecomm/utils'
import { type PageProps, graphql } from 'gatsby'
import { match } from 'ts-pattern'

import { BreadcrumbsWrapper } from '../../components/BreadcrumbsWrapper'
import { InstallationHowItWorksSection } from '../../components/Installation/HowItWorksSection'
import { InstallationOptionsSection } from '../../components/Installation/InstallationOptionsSection'
import { InstallationSmallText } from '../../components/Installation/SmallText'
import type { SeoNodeSchema } from '../../config/homeLandingResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { parseInstallationPage } from './schema'

export type PageContext = {
  readonly locale: Locale
  readonly seoDetails: SeoNodeSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function GenericHomeLandingTemplateM<
  T extends { readonly contentfulGenericLandingPage: U },
  U
>({ data, pageContext: { locale, seoDetails } }: Props<T>) {
  const { layout, breadcrumbTitle, slug } = parseInstallationPage(data)
  const { components, footer } = layout
  const { metaTitle, metaDescription, metaKeywords } = seoDetails
  const { isNofollow, isNoindex, canonicalLink } = seoDetails
  const bannerStyle = toPromoBannerStyleValue(layout.promoBannerStyle) || 'none'
  const headerData = useHeaderRedesignQuery()
  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <div className="relative lg:z-20">
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={bannerStyle}
        />
      </div>
      <Header {...headerData} />
      <BreadcrumbsWrapper breadcrumbTitle={breadcrumbTitle} slug={slug} />
      <main
        className="prose md:prose-md lg:prose-lg relative z-0 w-full whitespace-pre-line max-w-8xl mx-auto mb-16 flex flex-col gap-6 md:gap-8 px-4 md:px-8 lg:gap-12"
        data-component="components-wrapper"
        data-testid="landing-page-content"
        id="content"
      >
        {components.map(component =>
          match(component)
            .with({ __typename: 'ContentfulHeroBanner' }, data => (
              <HeroBanner
                className="p-0 !-mx-8 -my-4 max-md:rounded-none"
                {...data}
              />
            ))
            .with({ __typename: 'ContentfulTwoColumnSection' }, data => (
              <InstallationOptionsSection {...data} />
            ))
            .with({ __typename: 'ContentfulSmallText' }, data => (
              <InstallationSmallText {...data} />
            ))
            .with({ __typename: 'ContentfulCarousel' }, data => (
              <InstallationHowItWorksSection {...data} />
            ))
            .with({ __typename: 'ContentfulFaq' }, faq => (
              <div className="prose-h2:text-center prose-h2:mb-8">
                <FAQSection faq={faq} title={faq.title} />
              </div>
            ))
            .with({ __typename: 'ContentfulWidgetTrustpilotBanner' }, _ => (
              <div className="-mb-3 -mt-5">
                <TrustpilotUKTopBanner />
              </div>
            ))
            .otherwise(() => null)
        )}
        <CountryRedirectModal />
      </main>
      {footer ? <Footer footerId={footer.contentful_id} type="Full" /> : null}
      <PopupQuoteWizard data={headerData.quoteWizard} />
    </TrackingProvider>
  )
}
export const query = graphql`
  query InstallationTemplateQuery($id: String) {
    contentfulGenericLandingPage(id: { eq: $id }) {
      ...installationPageFragment
    }
  }
`
