import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { TypeOf, z } from '@simplisafe/ewok'

const affirmFeatureFlagDraftSchema = z.object({
  id: z.string(),
  json: z.object({
    id: z.string(),
    tag: z.string()
  })
})

const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(affirmFeatureFlagDraftSchema)
})

export const affirmFeatureFlagSchema = affirmFeatureFlagDraftSchema.extend({
  nt_experiences: z.array(experimentSchema).default([])
})

export type AffirmFeatureFlagDraftSchema = TypeOf<
  typeof affirmFeatureFlagDraftSchema
>
export type AffirmFeatureFlagSchema = TypeOf<typeof affirmFeatureFlagSchema>
