import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { productDetailsData } from './data'
import { schema } from './schema'

export const useScoutProductPageProductDetailsData = () => {
  const productPageData = useLocalizedData<typeof schema>(
    schema,
    productDetailsData
  )

  return useMemo(() => productPageData, [productPageData])
}
