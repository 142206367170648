import {
  AffirmClient,
  AffirmMonthlyPricingBanner
} from '@ecomm/shared-components'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import React from 'react'

import { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'

type Props = {
  readonly affirmClient?: AffirmClient | null
  readonly title?: string
  readonly description?: string
  readonly discountedPrice: number
  readonly className?: string
  readonly isBms?: boolean
}

export function AffirmExperienceMonthlyPricingBanner({
  affirmClient = null,
  title = 'Monthly prices presented using',
  description = 'Prices displayed are an example of a 24-mo. payment plan. Order total must be $150+ to select Affirm at checkout.',
  discountedPrice,
  className = '',
  isBms = false
}: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <AffirmMonthlyPricingBanner
      affirmClient={affirmClient}
      className={className}
      description={description}
      discountedPrice={discountedPrice}
      isAffirmExperience={isAffirmExperience}
      isBms={isBms}
      title={title}
    />
  )
}
