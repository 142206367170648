import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import {
  ApplyPromoCode,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import { TrackingProvider } from '@ecomm/tracking'
import { type PageProps, graphql } from 'gatsby'

import UnsubscribeForm from '../../components/UnsubscribeEmailPage/UnsubscribeForm'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { parseUnsubscribeMailData } from './schema'

export type PageContext = {
  readonly locale: string
  readonly footerId: string
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function UnsubscribeEmailTemplate<
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>({ data, pageContext: { footerId } }: Props<T>) {
  const { content } = parseUnsubscribeMailData(data)

  const headerData = useHeaderRedesignQuery()

  const promoBannerExperiment = usePromoBannerExperimentQuery()

  return (
    <TrackingProvider metaTitle={'unsubscribe_email'}>
      <div className="prose md:prose-md lg:prose-lg whitespace-pre-line">
        <ApplyPromoCode />
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={'standard'}
        />
        <Header {...headerData} />
        <main
          className="max-w-8xl prose-h2:text-2xl mx-auto mb-16"
          id="content"
        >
          <UnsubscribeForm {...content} />
        </main>
        <Footer footerId={footerId} />
      </div>
    </TrackingProvider>
  )
}

export const query = graphql`
  #graphql
  query UnsubscribeEmail {
    content: contentfulJson(contentful_id: { eq: "5jdnfKrTf58xxJiN1RSeYQ" }) {
      contentful_id
      content {
        internal {
          content
        }
      }
    }
  }
`
