import React from 'react'
import { useBMSMoreDetailsData } from '@ecomm/bms/hooks'

import { SensorSeeDetailsButton } from '../SensorSeeDetailsButton'
import { SensorModal } from '../SensorModal'

export type SensorModalProps = {
  readonly isOpen: boolean
  readonly setModalVisibility: (value: boolean) => void
  readonly sku: string
}

export function BMSMoreDetailsModal({
  isOpen,
  setModalVisibility,
  sku
}: SensorModalProps) {
  const sensorData = useBMSMoreDetailsData(sku)

  return (
    sensorData && (
      <div data-component="BMSMoreDetailsModal">
        <SensorSeeDetailsButton
          isProductModalOpen={isOpen}
          onProductModalOpenClose={setModalVisibility}
        />
        {isOpen ? (
          <SensorModal
            isOpen={isOpen}
            sensorData={sensorData}
            setModalVisibility={setModalVisibility}
          />
        ) : null}
      </div>
    )
  )
}
