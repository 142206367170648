import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track click on checkout with affirm.
 */
export function trackCheckoutAffirm() {
  getRudderstack(r => {
    r.track('checkout_affirm')
  })
}
