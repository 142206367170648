import { TypeOf } from '@simplisafe/ewok'

import {
  bundleSchema,
  buttonSchema,
  draftCartSchema,
  keyValueSchema,
  priceToggleSchema,
  productSchema
} from './schema'

export type PriceToggleSchema = TypeOf<typeof priceToggleSchema>
export type DraftCartFragment = TypeOf<typeof draftCartSchema>
export type KeyValueFragment = TypeOf<typeof keyValueSchema>
export type ButtonFragment = TypeOf<typeof buttonSchema>
export type BundleFragment = TypeOf<typeof bundleSchema>
export type ProductFragment = TypeOf<typeof productSchema>

export type DraftCartProduct = ProductFragment & {
  readonly hasQuantitySelector: boolean
  readonly isCheckbox: boolean
  readonly quantity: number
  readonly canIncrement: boolean
  readonly canDecrement: boolean
  readonly increment?: () => void
  readonly decrement?: () => void
}

export enum MonitoringPlan {
  interactive = 'interactive',
  none = 'none',
  odmonOvernight = 'odmonOvernight',
  odmon247 = 'odmon247',
  ss2 = 'ss2'
}

export type MonitoringPlanType = keyof typeof MonitoringPlan

export type DraftCartTotal = {
  readonly total: number
  readonly discountedTotal?: number
}
