//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { DeviceName, ViewName } from './data'
import HouseViewDevice, { HouseViewDeviceProps } from './HouseViewDevice'
import * as css from './HowItWorksHouse.module.scss'

export type HouseViewProps = {
  /** The device to highlight within this house view. */
  readonly activeDevice?: DeviceName | ''
  /** Assigned devices for display in this house view. */
  readonly devices: readonly HouseViewDeviceProps[]
  /** Expects the markup that represents a house view. */
  readonly image: ImageBitmapSource | ReactNode
  /** Image is only shown if active. */
  readonly isActive?: boolean
  /** A unique identifier for this house view, representing its associated image and devices. */
  readonly name: ViewName
  /** data-component attribute value */
  readonly dataComponent?: string
}

const HouseView: FC<HouseViewProps> = ({
  activeDevice,
  devices,
  image,
  isActive,
  name,
  dataComponent = HouseView.name
}: HouseViewProps) => {
  return (
    <div
      className={classNames(css.houseView, css[name], {
        [css.isActive]: isActive
      })}
      data-component={dataComponent}
    >
      {image}
      <div className={css.houseDevices}>
        {devices.map(device => (
          <HouseViewDevice
            deviceName={device.deviceName}
            isActive={device.deviceName === activeDevice}
            key={`${name}-${device.deviceName}-${device.locationName}`}
            locationName={device.locationName}
          />
        ))}
      </div>
    </div>
  )
}

export default HouseView
