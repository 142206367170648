export function hasPackageSku(
  state: unknown
): state is { readonly packageSku: string } {
  return (
    typeof state === 'object' &&
    state !== null &&
    'packageSku' in state &&
    typeof state.packageSku === 'string'
  )
}
