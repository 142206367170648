import { useAtom, useAtomValue, useSetAtom } from 'jotai'

import { shippingMethodAtom } from './shippingMethodAtom'

export const useShippingMethodState = () => {
  return useAtom(shippingMethodAtom)
}

export const useShippingMethodStateValue = () => {
  return useAtomValue(shippingMethodAtom)
}

export const useSetShippingMethodState = () => {
  return useSetAtom(shippingMethodAtom)
}
