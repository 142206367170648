import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track Zuora validation errors.
 */
export function trackZuoraValidationError(type: string, message: string) {
  getRudderstack(r => {
    r.track('zuora_validation_error', { type, message })
  })
}
