import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { z } from '@simplisafe/ewok'

export const NotFoundSchema = z.object({
  HeroBannerAsset: gatsbyImageSchema,
  MobileHeroBannerAsset: gatsbyImageSchema,
  OurRecommendationAsset: gatsbyImageSchema,
  MobileOurRecommendationAsset: gatsbyImageSchema,
  BuildSystemAsset: gatsbyImageSchema,
  MobileBuildSystemAsset: gatsbyImageSchema,
  ShopPackagesAsset: gatsbyImageSchema,
  MobileShopPackagesAsset: gatsbyImageSchema
})
