import { useStaticQuery, graphql } from 'gatsby'

export const use404Query = () => {
  return useStaticQuery(graphql`
    query NotFoundPageQuery {
      HeroBannerAsset: contentfulAsset(
        contentful_id: { eq: "2yy8P7iz1lk1Bovnje8weG" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileHeroBannerAsset: contentfulAsset(
        contentful_id: { eq: "65jJTduJkHGAp9o0yoIeqM" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      OurRecommendationAsset: contentfulAsset(
        contentful_id: { eq: "6sYXLIk3bQKRcuMvyA5edj" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileOurRecommendationAsset: contentfulAsset(
        contentful_id: { eq: "6rBmlliCTHU9AT8B6xfng3" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      BuildSystemAsset: contentfulAsset(
        contentful_id: { eq: "5rLVCdFLXCvSZr3zZUVCDR" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileBuildSystemAsset: contentfulAsset(
        contentful_id: { eq: "6ZQ6Ec4apIoh56eChMZrrC" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      ShopPackagesAsset: contentfulAsset(
        contentful_id: { eq: "4UTUV7acAPLQtZykS7war6" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
      MobileShopPackagesAsset: contentfulAsset(
        contentful_id: { eq: "3VXzIS8KLkWftOPpwkWJSN" }
      ) {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 90)
        description
      }
    }
  `)
}
