import { graphql, useStaticQuery } from 'gatsby'

import { meetTheSystemBannerSchema } from './schema'

const useMeetTheSystemBannerQuery = () => {
  return meetTheSystemBannerSchema.parse(
    useStaticQuery(graphql`
      query MeetTheSystemBannerQuery {
        image: contentfulAsset(
          contentful_id: { eq: "3E48COmRtPpeHKWuvmmmdF" }
        ) {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1362
            height: 1038
            placeholder: BLURRED
          )
          description
        }

        headline: contentfulSmallText(
          contentful_id: { eq: "EY4wjFc2jlsIt8AIqtmAw" }
        ) {
          text {
            raw
          }
        }

        body: contentfulSmallText(
          contentful_id: { eq: "683TrOg0lgAwv6CbzHINkv" }
        ) {
          text {
            raw
          }
        }
      }
    `)
  )
}

export { useMeetTheSystemBannerQuery }
