import React from 'react'

import { GatsbyImage } from '../GatsbyImage'
import { BannerPhoneSchema, bannerPhoneSchema } from './schema'

interface Props {
  readonly data: BannerPhoneSchema
}

function BannerPhone({ data }: Props) {
  const parsedData = bannerPhoneSchema.parse(data)

  const {
    title,
    subtitle,
    icon,
    phoneNumber,
    backgroundColor = '#f1f1f2'
  } = parsedData

  return (
    <div
      className={`prose md:prose-md lg:prose-lg justify-center bg-[${backgroundColor}] rounded-3xl py-8 text-center md:flex md:px-4 lg:py-5`}
      data-component="BannerPhone"
    >
      <GatsbyImage
        className="h-20 w-20 md:mr-8 lg:my-auto lg:mr-4"
        image={icon}
      />
      <div className="my-6 md:my-0 md:block lg:my-auto lg:flex">
        <h2 className="md:text-3xl lg:mx-4 lg:my-auto lg:max-w-xs">{title}</h2>
        <div className="block md:inline-flex lg:my-auto lg:ml-4 lg:flex">
          <p className="my-6 whitespace-pre-line md:my-0 md:text-lg md:leading-3 lg:mx-4">
            {subtitle}
          </p>
          <a
            className="inline-flex items-center text-lg underline md:my-0 md:pl-4 md:text-lg md:leading-3"
            href={`tel:${phoneNumber}`}
          >
            {phoneNumber}
          </a>
        </div>
      </div>
    </div>
  )
}

export default BannerPhone
