import { BigChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React, { Fragment, useState } from 'react'

import { AtcButtonRow } from './AtcButtonRow'
import { FeatureRow } from './FeatureRow'
import {
  theSimpliSafeDifference,
  usePlanComparisonData,
  useScoutPlanComparisonData
} from './comparisonData'
import {
  MonitoringComparisonTableProps,
  ScoutPlanComparisonProps,
  SectionsState
} from './types'

/**
 * @deprecated this component has been moved to monitoring/components' library and will be deleted soon
 */
export function MonitoringComparisonTable({
  theme,
  headline,
  leftColumnHeader,
  rightColumnHeader,
  sections,
  atcButtonRow
}: MonitoringComparisonTableProps) {
  const sectionsStateObject: SectionsState = sections.reduce(
    (acc, _, index) => ({
      ...acc,
      [index]: {
        isOpen: true
      }
    }),
    {}
  )

  const [sectionsState, setSectionsState] = useState(sectionsStateObject)

  const handleToggleSection = (index: number) =>
    setSectionsState(prevSectionsState => ({
      ...prevSectionsState,
      [index]: {
        ...prevSectionsState[index],
        isOpen: !prevSectionsState[index].isOpen
      }
    }))

  return (
    <table className="table w-full table-fixed border-separate border-spacing-y-2 place-items-center items-center">
      <caption>
        <h2 className="sr-only">Monitoring Comparison Table</h2>
      </caption>
      <thead>
        <tr>
          <th className="text-left" colSpan={2}>
            {headline}
          </th>
          <th>{leftColumnHeader}</th>
          <th>{rightColumnHeader}</th>
        </tr>
      </thead>
      <tbody>
        {sections.map((section, sectionIndex) => {
          return (
            <Fragment key={`section_${sectionIndex}`}>
              {section.title ? (
                <tr>
                  <td
                    className="border-0 border-b-2 border-solid border-b-black py-2"
                    colSpan={3}
                  >
                    <div className="font-bold prose md:prose-md lg:prose-lg whitespace-pre-line">
                      {section.title}
                    </div>
                  </td>
                  <td className="border-0 border-b-2 border-solid border-b-black py-2 text-center">
                    <button
                      className={classNames(
                        'm-0 cursor-pointer appearance-none border-none bg-transparent p-0',
                        { 'rotate-180': sectionsState[sectionIndex].isOpen }
                      )}
                      onClick={() => handleToggleSection(sectionIndex)}
                    >
                      <BigChevronDown className="h-4 w-4" />
                    </button>
                  </td>
                </tr>
              ) : null}
              {section.features.map((feature, index) => (
                <FeatureRow
                  key={`feature_${index}`}
                  {...feature}
                  cellClassName={classNames(
                    'border-spacing-y-2 border-x-0 border-t-0 border-b border-solid border-b-neutral-medium-100 py-2 font-bold',
                    {
                      'border-t border-t-neutral-medium-100':
                        index === 0 && !section.title
                    }
                  )}
                  className={classNames({
                    hidden: !sectionsState[sectionIndex].isOpen
                  })}
                  theme={theme}
                />
              ))}
            </Fragment>
          )
        })}
        {atcButtonRow ? atcButtonRow : null}
      </tbody>
    </table>
  )
}

export function TheSimpliSafeDifference() {
  return <MonitoringComparisonTable {...theSimpliSafeDifference} />
}

export function PlanComparison() {
  const data = usePlanComparisonData()
  return <MonitoringComparisonTable {...data} />
}

export function ScoutPlanComparison({
  professionalMonitoringAtcButton,
  selfMonitoringAtcButton
}: ScoutPlanComparisonProps) {
  const data = useScoutPlanComparisonData()
  const atcButtonRow = AtcButtonRow({
    professionalMonitoringAtcButton,
    selfMonitoringAtcButton
  })
  return (
    <MonitoringComparisonTable
      atcButtonRow={atcButtonRow}
      {...data}
      theme="blue"
    />
  )
}
