import { useAffirmAPRExperiment } from '@ecomm/shared-hooks'
import { mapExperiences } from '@ecomm/shared-ninetailed'
import React from 'react'

import type { AffirmFeatureFlagSchema } from '../../experiments/AffirmAPR/schema'
import { useAffirmExperimentQuery } from '../../experiments/AffirmAPR/useAffirmExperimentQuery'
import type { ProductCardExperimentFragment } from '../ProductCard/schema'
import OdmonProductCardsList from './OdmonProductCardList'

interface Props {
  readonly items: readonly ProductCardExperimentFragment[]
  readonly isCurrent: boolean
}

function AffirmProductCardListWrapper({ items, isCurrent }: Props) {
  const affirmFeatureFlagQueryData = useAffirmExperimentQuery()
  const mappedExperiences = mapExperiences<AffirmFeatureFlagSchema>(
    affirmFeatureFlagQueryData.nt_experiences
  )
  const isAffirmExperience = useAffirmAPRExperiment(mappedExperiences)

  return (
    <OdmonProductCardsList
      isActiveAccordion={isCurrent}
      isAffirmExperience={isAffirmExperience}
      items={items}
    />
  )
}

export default AffirmProductCardListWrapper
