import { gql } from '@apollo/client/index.js'
import { FOOTER_FRAGMENT } from '@ecomm/footer-data'

export const BLOG_LAYOUT_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query BLOG_LAYOUT_QUERY($locale: String!, $preview: Boolean!) {
    layout(id: "4KBDQhAdzsztgNStKG2ZYW", preview: $preview, locale: $locale) {
      sys {
        id
      }
      internalTitle
      promoBannerStyle
      footer {
        ...footer
      }
    }
  }
`
