import { useLocale } from '@ecomm/data-hooks'
import { ReactNode } from 'react'

import CartAffirmMonthlyOption from './CartAffirmMonthlyOption'

export type CartSubtotalProps = {
  readonly subtotal: string | undefined
  readonly affirmMonthlyOption: ReactNode | null
}

function CartSubtotal({ subtotal, affirmMonthlyOption }: CartSubtotalProps) {
  const locale = useLocale()

  if (!subtotal) {
    return null
  }

  return locale === 'en-US' ? (
    <CartAffirmMonthlyOption content={affirmMonthlyOption} />
  ) : (
    <div className="bg-neutral-light-50 my-4 w-full rounded-[0.625rem] px-10 py-4">
      <h4 className="m-0 mb-2 text-right text-lg font-medium leading-none">
        Subtotal: {subtotal}
      </h4>
      <p className="m-0 mb-2 text-right text-lg leading-none">
        (Including VAT)
      </p>
    </div>
  )
}

export default CartSubtotal
