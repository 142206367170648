import { gql } from '@apollo/client/index.js'

export const CAMERA_BANNER_ASSET_QUERY = gql`
query CameraBannerAssetQuery($locale : String! $preview : Boolean!) {
  cameraBannerAsset: asset(
    id:  "5mbs4LEkQgjIZpoFklL2ur",
    locale : $locale,
    preview : $preview,
  ) {
    originalWidth : width
    originalHeight : height
    title
    url
    size
    description
  }
}
`
