import { gatsbyDevMode, nodeEnv } from '@ecomm/data-env-variables'
import { addAtomDebugLabel } from '@ecomm/utils'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import { match } from 'ts-pattern'
import { BooleanParam, useQueryParam } from 'use-query-params'

const devmodeAtom = atomWithStorage<boolean | undefined>(
  'disable_dev',
  undefined
)
addAtomDebugLabel(devmodeAtom, 'dev mode - localstorage')

export const useDevMode = (): readonly [boolean, () => void, () => void] => {
  const [isDisabledByStorage, setIsDisabledByStorage] = useAtom(devmodeAtom)
  const env = useMemo(() => nodeEnv(), [])
  const gatsbyDev = useMemo(() => gatsbyDevMode(), [])

  const [isDisabledByParam, setIsDisabledByParam] = useQueryParam(
    'disable_dev',
    BooleanParam
  )

  const isDevModeEnabled = useMemo(() => {
    return match([
      isDisabledByStorage === true,
      env === 'development',
      gatsbyDev === 'true',
      isDisabledByParam === true
    ])
      .when(
        ([disabledByStorage]) => disabledByStorage === true,
        () => false
      )
      .when(
        ([_, __, ___, disabledByParam]) => disabledByParam === true,
        () => false
      )
      .when(
        ([_, enabledByDevelopmentEnv]) => enabledByDevelopmentEnv === true,
        () => gatsbyDev !== 'false'
      )
      .when(
        ([_, __, enabledByGatsbyDev]) => enabledByGatsbyDev === true,
        () => true
      )
      .otherwise(() => false)
  }, [isDisabledByStorage, env, gatsbyDev, isDisabledByParam])

  const disablePermenently = useCallback(() => {
    setIsDisabledByStorage(true)
  }, [])

  const disableTemp = useCallback(() => {
    setIsDisabledByParam(true)
  }, [])

  return [isDevModeEnabled, disablePermenently, disableTemp]
}
