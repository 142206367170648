import { overloadMaybe, path, prop } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

export const getCartDiscountCode = (cart: ImmutableCart) =>
  pipe(
    overloadMaybe(prop('discountCodes', cart)),
    O.chain(discountCodes =>
      O.fromNullable(
        discountCodes.find(discountCode => discountCode.state === 'MatchesCart')
      )
    ),
    O.chain(discountCodeInfo =>
      O.fromNullable(path(['obj', 'code'], discountCodeInfo.discountCode))
    ),
    O.getOrElse(() => '')
  )
