import { useAtom, useAtomValue, useSetAtom } from 'jotai'

import { cartAtom } from './cartAtom'

export const useCart = () => {
  return useAtom(cartAtom)
}

export const useCartValue = () => {
  return useAtomValue(cartAtom)
}

export const useSetCart = () => {
  return useSetAtom(cartAtom)
}
