import { LoadingSpinner } from '@ecomm/checkout-icons'
import React from 'react'

function LoadingBanner() {
  return (
    <div
      className="prose md:prose-md lg:prose-lg py-8 gap-4 min-h-[350px] md:min-h-[400px] md:gap-6 lg:gap-8 px-4 md:py-8 w-full flex flex-col items-center justify-center"
      data-component="PackageLoading"
    >
      <h2 className="mb-2">Loading your package…</h2>
      <LoadingSpinner
        className="text-primary-100"
        fillColor="currentColor"
        size={56}
      />
    </div>
  )
}

export default LoadingBanner
