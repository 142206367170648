import parsePhoneNumberFromString from 'libphonenumber-js'
import { z } from 'zod'

export const validatePhoneNumber = (
  value: string | undefined,
  region: 'GB' | 'US'
) => {
  if (!value || value.length < 9) {
    return false
  } else {
    return parsePhoneNumberFromString(value, region)?.isValid() || false
  }
}

export const usPhoneSchema = z
  .string({ required_error: 'Phone is a required field' })
  .min(10, { message: 'Phone must contain at least 10 characters' })
  .trim()
  .refine(
    val => validatePhoneNumber(val, 'US'),
    val => ({ message: `${val} is not a valid US phone number` })
  )

export const businessLeadSchema = z.object({
  firstName: z.string().min(1, { message: 'First Name is a required field' }),
  lastName: z.string().min(1, { message: 'Last Name is a required field' }),
  numLocations: z.enum(['2-5', '6-10', '11-20', '21+'], {
    message: 'Num Locations is a required field'
  }),
  phone: usPhoneSchema,
  email: z.string().min(1, { message: 'Email is a required field' }).email(),
  companyName: z
    .string()
    .min(1, { message: 'Company Name is a required field' })
})
