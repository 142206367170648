import { Star } from '@ecomm/shared-icons'
import React from 'react'

type RatingProps = {
  readonly starClasses?: string
  readonly className?: string
}

function Rating({ className = '', starClasses = '' }: RatingProps) {
  const starDefaultClasses = 'mr-1 h-4 w-4 text-primary-100'

  return (
    <div className={className} data-component="rating">
      <Star className={starClasses || starDefaultClasses} />
      <Star className={starClasses || starDefaultClasses} />
      <Star className={starClasses || starDefaultClasses} />
      <Star className={starClasses || starDefaultClasses} />
      <Star className={starClasses || starDefaultClasses} />
    </div>
  )
}

export default Rating
