import { logError } from '@ecomm/error-handling'
import { ReactSDKClient } from '@optimizely/react-sdk'

import { OptimizelyExperimentData, UserAttributes } from '../types'
import { toUserAttributes } from './toUserAttributes'

export const trackEvent = async (
  data: OptimizelyExperimentData,
  optimizely: ReactSDKClient
) => {
  const userId = data.userId
  const userAttributes: UserAttributes = toUserAttributes(data.userAttributes)
  const eventRevenue = data.revenue ?? null
  const eventTags = {
    revenue: eventRevenue
      ? Number(eventRevenue * 100)
          .toFixed()
          .toString()
      : null,
    value: data.value ?? null
  }
  const eventId = data.eventId ?? ''

  return optimizely
    .onReady()
    .then(() => optimizely.track(eventId, userId, userAttributes, eventTags))
    .catch(logError)
}
