import { Locale } from '../../hooks/useLocalizedData'
import {
  createMockRichHeadingText,
  createMockRichTextWithItalics
} from '../../utils'

export const data = {
  [Locale.EnUS]: {
    title: createMockRichHeadingText('Frequently asked questions', 2),
    questions: [
      {
        longTitle: 'Will SimpliSafe® protect my whole home?',
        shortTitle: 'Will SimpliSafe® protect my whole home?',
        description: createMockRichTextWithItalics([
          'Yes. SimpliSafe® is an advanced security system that can protect every inch of your home with a wide range of high-tech cameras, security sensors, and hazard detection.'
        ])
      },
      {
        longTitle: 'How does 24/7 professional monitoring work?',
        shortTitle: 'How does 24/7 professional monitoring work?',
        description: createMockRichTextWithItalics([
          'During an alarm, our live monitoring agents will contact you by text message and phone call. If the emergency is real, they’ll quickly dispatch police, fire or medical responders to your home.'
        ])
      },
      {
        longTitle:
          'Is there support available if I don’t choose professional installation?',
        shortTitle:
          'Is there support available if I don’t choose professional installation?',
        description: createMockRichTextWithItalics([
          'Absolutely. SimpliSafe offers an interactive guide in our app with step-by-step details on how to install your system as well as expert customer support by phone or chat before, during and after your installation.'
        ])
      }
    ]
  }
}
