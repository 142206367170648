import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf } from '@simplisafe/ewok'
import { z } from 'zod'

import { productCardInternalSchema } from './productCardSchema'
/**
 * This is a similar duplicate of apps/shop/src/components/Package/schema.ts
 * only to be used for partner pages
 */
export const packageSchema = z.object({
  description: contentfulRichTextSchema.nullish(),
  displayName: z.string(),
  image: gatsbyImageSchema.nullable(),
  product: z.string(),
  products: z.array(productCardInternalSchema),
  slug: z.string(),
  offerTagExtraText: contentfulRichTextSchema.nullable()
})

export type PackageFragment = TypeOf<typeof packageSchema>
