import { type TypeOf, z } from '@simplisafe/ewok'

export const parseUnsubscribeMailData = <
  T extends { readonly footer: U; readonly content: R },
  U,
  R
>(
  data: T
): UnsubscribeEmailSchemaPage => {
  const { content } = unsubscribeEmailPageQuerySchema.parse(data, {
    path: ['parseUnsubscribeMailData', 'unsubscribeEmailPageQuerySchema']
  })

  const parsedJSONContentData = JSON.parse(content.content.internal.content)

  const validatedContent = contentSchema.parse(parsedJSONContentData, {
    path: ['parseUnsubscribeMailData', 'contentSchema']
  })

  return {
    content: validatedContent
  }
}

export const unsubscribeEmailPageQuerySchema = z.object({
  content: z.object({
    content: z.object({
      internal: z.object({
        content: z.string()
      })
    })
  })
})

export const contentSchema = z.object({
  emailForm: z.object({
    title: z.string(),
    description: z.string(),
    inputLabel: z.string(),
    unsubscribeTitle: z.string(),
    unsubscribeDescription: z.string(),
    submitButtonText: z.string(),
    disclaimer: z.string()
  }),
  resubscribeForm: z.object({
    title: z.string(),
    description: z.string(),
    inputLabel: z.string(),
    submitButtonText: z.string()
  }),
  contactTitle: z.string(),
  contactText: z.string()
})

export const unsubscribeEmailSchemaPage = z.object({
  content: contentSchema
})

export type UnsubscribeEmailSchemaPage = TypeOf<
  typeof unsubscribeEmailSchemaPage
>
export type UnsubscribeEmailPageQuerySchema = TypeOf<
  typeof unsubscribeEmailPageQuerySchema
>
export type ContentSchema = TypeOf<typeof contentSchema>
