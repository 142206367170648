import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { ContentfulRichText } from '@ecomm/shared-components'
import { zodResolver } from '@hookform/resolvers/zod'
import { TypeOf } from '@simplisafe/ewok'
import { FieldValues, useForm } from 'react-hook-form'
import { z, ZodSchema } from 'zod'

export const businessFormSchema = z.object({
  form: z
    .object({
      title: z.string(),
      inputName: z.string(),
      type: z.string(),
      placeholder: z.string().nullish(),
      options: z.string().array().nullish(),
      required: z.boolean().nullish()
    })
    .array(),
  button: z.object({
    text: z.string()
  }),
  note: contentfulRichTextSchema,
  successMessage: contentfulRichTextSchema
})

export type FormSchema = TypeOf<typeof businessFormSchema> & {
  readonly validationSchema: ZodSchema
  readonly onSubmit: (_: FieldValues) => void
}

export default function Form({
  button,
  form,
  note,
  validationSchema,
  onSubmit
}: FormSchema) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: zodResolver(validationSchema) })

  return (
    <form
      className="bg-neutral-light-50 rounded-base relative flex w-full flex-col gap-4 overflow-hidden p-6 md:gap-6 md:p-8 lg:p-10"
      onSubmit={handleSubmit(onSubmit)}
    >
      {form.map((input, i) =>
        input.type === 'Radio' ? (
          <div className="flex flex-col gap-2" key={`input-${i}`}>
            <fieldset className="flex gap-6 border-none p-0">
              {input.options?.map((opt, i) => (
                <div key={`radio-opt-${i}`}>
                  <input
                    id={opt}
                    type="radio"
                    value={opt}
                    {...register(input.inputName)}
                  />
                  <label htmlFor={opt}>{opt}</label>
                </div>
              ))}
            </fieldset>
            {!!errors[input.inputName] ? (
              <span className="text-complementary-red-100">
                {errors[input.inputName]?.message?.toString()}
              </span>
            ) : null}
          </div>
        ) : (
          <div className="flex w-full flex-col" key={`input-${i}`}>
            <label htmlFor={input.inputName}>{input.title}</label>
            <input
              {...register(input.inputName)}
              className="placeholder:text-neutral-medium-100 text-body-size placeholder:font-default placeholder:text-body-size rounded-base border-neutral-medium-100 h-14 border border-solid p-4"
              id={input.inputName}
              placeholder={input.placeholder ?? ''}
              type={input.type}
            />
            {!!errors[input.inputName] ? (
              <span className="text-complementary-red-100">
                {errors[input.inputName]?.message?.toString()}
              </span>
            ) : null}
          </div>
        )
      )}
      <button className="btn btn-solid-primary" type="submit">
        {button.text}
      </button>
      <div className="text-sm">
        <ContentfulRichText {...note} />
      </div>
    </form>
  )
}
