// extracted by mini-css-extract-plugin
export var boldTextSensor = "ItemContainer-module--boldTextSensor--6db54";
export var cardBadge = "ItemContainer-module--cardBadge--1af1f";
export var cardBadgeMobile = "ItemContainer-module--cardBadgeMobile--eee93";
export var carouselWrapper = "ItemContainer-module--carouselWrapper--530cb";
export var close = "ItemContainer-module--close--c7ad3";
export var desc = "ItemContainer-module--desc--2642a";
export var extraSensors = "ItemContainer-module--extraSensors--a8f7c";
export var itemContainer = "ItemContainer-module--itemContainer--c3c49";
export var offerBadge = "ItemContainer-module--offerBadge--f1215";
export var paragraphSensor = "ItemContainer-module--paragraphSensor--194cd";
export var pdpSensor = "ItemContainer-module--pdpSensor--614f1";
export var priceText = "ItemContainer-module--priceText--8c290";
export var purchaseDetails = "ItemContainer-module--purchaseDetails--42720";