import { Modal } from '@ecomm/shared-components'
import { InformationIcon } from '@ecomm/shared-icons'
import React, { useState } from 'react'

import { FeatureModalProps } from './types'

type Props = {
  readonly modal: FeatureModalProps
  readonly onClick?: () => void
}

function FeatureModal({ modal, onClick = () => null }: Props) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <span
        className="m-0 cursor-pointer"
        onClick={e => {
          e.preventDefault()
          onClick()
          !open && setOpen(true)
        }}
        role="button"
      >
        <InformationIcon className="-mb-1 ml-1 h-5 w-5" />
      </span>
      <Modal isOpen={open} onRequestClose={() => setOpen(false)}>
        <div className="prose md:prose-md lg:prose-lg max-w-xs bg-neutral-50 px-4 pb-6 pt-6 md:px-10 md:pb-20 md:pt-14">
          <div className="mb-4">
            <h4 className="font-bold" data-component="modal_headline">
              {modal.headline}
            </h4>
          </div>
          {modal.description}
        </div>
      </Modal>
    </>
  )
}

export default FeatureModal
