import classNames from 'classnames'
import React, { FC } from 'react'

import type { SiteColor } from '../types/colors'

export type DividerProps = {
  /** Defines the thickness of the border. Defaults to 1. */
  readonly borderWidth?: number
  /** Sets the color to a valid SiteColor value. Example 'neutralBlack'. Defaults to the parent element's `color` property. */
  readonly color?: SiteColor
  /** The data-component attribute value for identifying this component when it renders. */
  readonly dataComponent?: string
  /** Sets the `max-width` value. Defaults to none (full-width). */
  readonly width?: number
}

/** @deprecated Do not use ss-react-components*/
const Divider: FC<DividerProps> = ({
  borderWidth: borderWidthProp,
  color = 'neutralMediumGray',
  dataComponent = Divider.name,
  width: widthProp
}: DividerProps) => {
  const classes = classNames(
    'border border-solid border-current border-b-0 my-4 mx-0',
    `${color}TextColor`
  )
  const style =
    borderWidthProp || widthProp
      ? {
          ...(borderWidthProp && { borderTopWidth: borderWidthProp }),
          ...(widthProp && { maxWidth: widthProp })
        }
      : undefined

  return <hr className={classes} data-component={dataComponent} style={style} />
}

export default Divider
