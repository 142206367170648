import { useSwipeMotion } from '@ecomm/shared-hooks'
import classNames from 'classnames'
import React from 'react'

type ArrowControlsProps = {
  readonly onNextClick: () => void
  readonly onPreviousClick: () => void
  readonly disableNext?: boolean
  readonly disablePrevious?: boolean
  readonly onSwipeLeft: () => void
  readonly onSwipeRight: () => void
}

function ArrowControls({
  onNextClick,
  onPreviousClick,
  disableNext = false,
  disablePrevious = false,
  onSwipeLeft,
  onSwipeRight
}: ArrowControlsProps) {
  const touchEvents = useSwipeMotion({ onSwipeLeft, onSwipeRight })

  return (
    <div
      className={classNames(
        'absolute flex h-full w-full flex-row items-center',
        {
          'justify-between': !disablePrevious && !disableNext,
          'justify-end': disablePrevious && !disableNext,
          'justify-start': !disablePrevious && disableNext
        }
      )}
      {...touchEvents}
    >
      {!disablePrevious && (
        <button
          aria-label="Previous Slide"
          className="outline-revert text-neutral-medium hover:text-neutral-dark cursor-pointer rounded-full border-none bg-transparent p-5 opacity-50"
          data-component="ArrowControls/PreviousButton"
          name="Previous Slide"
          onClick={onPreviousClick}
          type="button"
        >
          <svg className="h-6 w-6 fill-current" viewBox="0 0 20 20">
            <path d="M6.293 10L13.146 3.146a.5.5 0 0 0-.708-.708l-7 7a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708L6.293 10z" />
          </svg>
        </button>
      )}
      {!disableNext && (
        <button
          aria-label="Next Slide"
          className="outline-revert text-neutral-medium hover:text-neutral-dark cursor-pointer rounded-full border-none bg-transparent p-5 opacity-50"
          data-component="ArrowControls/NextButton"
          name="Next Slide"
          onClick={onNextClick}
          type="button"
        >
          <svg className="h-6 w-6 fill-current" viewBox="0 0 20 20">
            <path d="M13.707 10L6.854 16.854a.5.5 0 1 0 .708.708l7-7a.5.5 0 0 0 0-.708l-7-7a.5.5 0 1 0-.708.708L13.707 10z" />
          </svg>
        </button>
      )}
    </div>
  )
}

export default ArrowControls
