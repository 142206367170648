import { Locale } from '@ecomm/utils'
import { isNilOrEmpty } from '@simplisafe/ewok'

import {
  PriceCalculationFieldProps,
  PriceCalculationProps
} from '../content/types'

export const toPriceCalculationFields = (
  values: PriceCalculationProps,
  locale: Locale
): readonly PriceCalculationFieldProps[] =>
  [
    {
      label: 'Subtotal:',
      value: values.subtotal
    },
    {
      couponCode: values.couponCode,
      isDiscount: true,
      label: 'Coupon code applied:',
      value: values.discountValue
    },
    {
      label: 'Shipping:',
      value: values.checkoutShippingValue
    },
    {
      label: locale === 'en-US' ? 'Estimated tax:' : 'Estimated VAT:',
      value: values.vAT
    },
    {
      label: locale === 'en-US' ? 'Total:' : 'Total (including VAT):',
      value: values.total
    }
  ].filter(({ value }) => !isNilOrEmpty(value))
