/**
 * For a US phone number
 * * Optional
 * * Min length of 10
 * * A trimmed string (no leading or trailing whitespace)
 * * must pass validatePhoneNumber function
 */
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { string } from 'yup'

const validatePhoneNumber = (value = '', region: 'GB' | 'US') =>
  parsePhoneNumberFromString(value, region)?.isValid() ?? false
export const usOptionalPhoneSchema = string()
  .nullable()
  .notRequired()
  .test(
    'is-us-phone-number',
    '${originalValue} is not a valid US phone number',
    value => {
      return !value || validatePhoneNumber(value, 'US')
    }
  )
  .min(10)
  .trim()
