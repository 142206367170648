import { iconAndTextSchema } from '@ecomm/shared-components'
import { TypeOf, z } from 'zod'

export const headlineSchema = z.string().optional()

export const itemSchema = iconAndTextSchema.pick({
  icon: true,
  text: true,
  disclaimer: true
})

export const itemsSchema = z.array(itemSchema).nonempty()

export const confidenceBarSchema = z.object({
  headline: headlineSchema,
  items: itemsSchema
})

export type ItemSchema = TypeOf<typeof itemSchema>
export type ItemsSchema = TypeOf<typeof itemsSchema>
export type ConfidenceBarSchema = TypeOf<typeof confidenceBarSchema>
