import { GatsbyImage } from '@ecomm/shared-components'
import classNames from 'classnames'

import { TwoColumnImagesSchema } from './schema'

export function TwoColumnImages({
  leftSideImage,
  rightSideImage,
  variant
}: TwoColumnImagesSchema) {
  return (
    <div
      className={classNames(
        'rounded-base  w-full justify-between p-2.5 md:flex md:gap-x-9 md:p-8',
        {
          'bg-neutral-light-50': variant === 'light',
          'bg-neutral-black': variant === 'dark'
        }
      )}
    >
      <div className="mx-auto max-w-md md:mx-0 md:max-w-none">
        <GatsbyImage image={leftSideImage} objectFit="contain" />
      </div>
      <div className="mx-auto max-w-md md:mx-0 md:max-w-none">
        <GatsbyImage image={rightSideImage} objectFit="contain" />
      </div>
    </div>
  )
}
