import { useMemo } from 'react'

import { useLocalizedData } from '../../hooks/useLocalizedData'
import { data } from './data'
import { schema } from './schema'

export function useScoutProductPageTwoColumnImagesData() {
  const twoColumnImagesData = useLocalizedData<typeof schema>(schema, data)

  return useMemo(() => twoColumnImagesData, [twoColumnImagesData])
}
