import React from 'react'

type TierOneProps = {
  readonly children: React.ReactNode
}

/**
 * Tier one (offer + offer secondary) text styles.
 *
 * Example: "20% OFF" or "20% off any new system".
 *
 * Accounts for the typography styles on mobile/tablet bottom banner and desktop top banner.
 * Mobile starts at 36px, tablet starts at 38px, and desktop reduces back to 36px.
 */
export function TierOne({ children }: TierOneProps) {
  return (
    <div
      className="text-[36px] font-extrabold leading-[38px] md:text-[38px] md:leading-[40px] lg:text-[36px] lg:leading-[41.4px]"
      data-component="TierOne"
    >
      {children}
    </div>
  )
}
