//@ts-nocheck
import React, { FC, ReactNode } from 'react'

import useMediaQuery from '../hooks/useMediaQuery'
import * as css from './CardItemGrid.module.scss'

type CardItemGridProps = {
  readonly children?: ReactNode
  /** Rich text to display as the page title, styled for desktop & tablet */
  readonly desktopTabletTitleText: ReactNode
  /** Rich text to display as the page title, styled for mobile */
  readonly mobileTitleText: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const CardItemGrid: FC<CardItemGridProps> = ({
  children,
  desktopTabletTitleText,
  mobileTitleText,
  dataComponent = CardItemGrid.name
}: CardItemGridProps) => {
  const isTabletUp = useMediaQuery('TabletAndUp')
  return (
    <>
      {!isTabletUp && (
        <div
          className={'alignCenter'}
          data-component={`${dataComponent}_alignCenter`}
        >
          {mobileTitleText}
        </div>
      )}
      <div className={css.grid} data-component={`${dataComponent}_grid`}>
        {isTabletUp ? (
          <div className={css.desktopTabletTitle}>{desktopTabletTitleText}</div>
        ) : null}
        {children}
      </div>
    </>
  )
}

export default CardItemGrid
