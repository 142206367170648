import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

export const featureModalSchema = z.object({
  __typename: z.literal('ContentfulModal'),
  headlineText: z.string(),
  description: contentfulRichTextSchema,
  button: z
    .object({
      __typename: z.literal('ContentfulButton'),
      text: z.string()
    })
    .nullish()
})

export type FeatureModalSchema = TypeOf<typeof featureModalSchema>
