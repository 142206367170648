//@ts-nocheck
import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { SiteColor } from '../types/colors'
import * as css from './ImageWithFloatingBadge.module.scss'

export type BadgePosition = 'left' | 'right'
export type ImageWithFloatingBadgeProps = {
  readonly badgeBackgroundColor?: SiteColor
  readonly badgeTextColor?: SiteColor
  /** Text to show in the badge */
  readonly badgeText?: string
  /** Image to show behind the badge */
  readonly image: ReactNode
  /** Text Badge rotation in degrees */
  readonly badgeRotation?: number
  /** Position of top badge */
  readonly badgePosition?: BadgePosition
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const ImageWithFloatingBadge: FC<ImageWithFloatingBadgeProps> = ({
  badgeBackgroundColor = 'neutralDarkGray',
  badgeTextColor = 'neutralWhite',
  badgeText,
  image,
  badgePosition = 'left',
  badgeRotation = 0,
  dataComponent = ImageWithFloatingBadge.name
}: ImageWithFloatingBadgeProps) => {
  const badgePositionClass =
    badgePosition === 'left' ? css.leftImageBadge : css.rightImageBadge

  return (
    <div className={css.imageWithFloatingBadge} data-component={dataComponent}>
      <div className={css.content}>
        <span
          className={classNames(badgePositionClass, css.imageBadge, {
            [`${badgeBackgroundColor}BackgroundColor`]: !!badgeBackgroundColor,
            [`${badgeTextColor}TextColor`]: !!badgeTextColor
          })}
          style={{ transform: `rotate(${badgeRotation}deg)` }}
        >
          {badgeText}
        </span>
        {image}
      </div>
    </div>
  )
}

export default ImageWithFloatingBadge
