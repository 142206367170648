import { iconTypes } from '@ecomm/shared-icons'
import { TypeOf, z } from '@simplisafe/ewok'

import { contentfulRichTextSchema } from '../ContentfulRichText/contentfulRichTextSchema'

export const iconAndTextSchema = z.object({
  __typename: z.literal('ContentfulIconAndText'),
  className: z.string().optional(),
  contentful_id: z.string(),
  disclaimer: z.string().nullish(),
  icon: z.enum(iconTypes),
  text: contentfulRichTextSchema,
  title: z.string()
})

export type IconAndTextSchema = TypeOf<typeof iconAndTextSchema>
