import { DynamicDiscount } from '@simplisafe/ss-ecomm-data/prices/service'
import * as O from 'fp-ts/lib/Option'

export const getLowerTierAbsoluteDiscount = (
  thresholds: readonly DynamicDiscount[]
) =>
  thresholds && thresholds.length
    ? thresholds.reduce(
        (lower: number, current: DynamicDiscount) => {
          return Math.min(lower, current.threshold.lowerThreshold)
        },
        // Random high number used as higher threshold
        999999
      )
    : 0

// Just used for BMSMv2 Experiment
export const getBMSMAbsoluteDiscountedPrice = (
  thresholds: readonly DynamicDiscount[],
  packageOriginalPrice: number,
  totalDiscountedPrice: O.Option<number>
) => {
  const withoutMonitoringBMSMDiscountPrice =
    packageOriginalPrice > getLowerTierAbsoluteDiscount(thresholds)
      ? totalDiscountedPrice
      : O.none
  return withoutMonitoringBMSMDiscountPrice
}
