import { GatsbyFooter as Footer } from '@ecomm/footer-components'
import { GatsbyTrustPilot as TrustPilot } from '@ecomm/lander'
import {
  ApplyPromoCode,
  PageToaster,
  PromoBannerWrapper
} from '@ecomm/promotions-components'
import { Header, useHeaderRedesignQuery } from '@ecomm/header'
import {
  CountryRedirectModal,
  toPromoBannerStyleValue,
  v2PopupQuoteWizard as PopupQuoteWizard
} from '@ecomm/shared-components'
import { ColoredSection } from '@ecomm/shared-sections'
import { TrackingProvider } from '@ecomm/tracking'
import { SEO } from '@ecomm/utils'
import { head } from 'fp-ts/lib/Array'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { type PageProps, graphql } from 'gatsby'
import { match } from 'ts-pattern'

import { BreadcrumbsWrapper } from '../../components/BreadcrumbsWrapper'
import Hero from '../../components/ReviewsPageComponents/Hero'
import ReviewGrid from '../../components/ReviewsPageComponents/ReviewGrid'
import type { SeoNodeSchema } from '../../config/homeLandingResponseNodeSchema'
import { usePromoBannerExperimentQuery } from '../../experiments/PromoBannerPhoneNumber/usePromoBannerExperimentQuery'
import { useReviewsPageFragment } from './useReviewsPageFragment'

export type PageContext = {
  readonly locale: string
  readonly seoDetails: SeoNodeSchema
}

type Props<T> = Partial<PageProps> & {
  readonly data: T
  readonly pageContext: PageContext
}

export default function ReviewsTemplate<
  T extends { readonly contentfulGenericLandingPage: U },
  U
>({ data, pageContext: { locale, seoDetails } }: Props<T>) {
  const { layout, slug, breadcrumbTitle, showPopupQuoteWizard } =
    useReviewsPageFragment(data)
  const {
    canonicalLink,
    isNofollow,
    isNoindex,
    metaDescription,
    metaKeywords,
    metaTitle
  } = seoDetails
  const {
    components,
    promoBannerStyle,
    footer: { contentful_id: footerId }
  } = layout
  const headerData = useHeaderRedesignQuery()
  const promoBannerExperiment = usePromoBannerExperimentQuery()
  const bannerStyle = toPromoBannerStyleValue(promoBannerStyle) || 'none'

  return (
    <TrackingProvider metaTitle={metaTitle}>
      <PageToaster />
      <ApplyPromoCode />
      <SEO
        canonicalLink={canonicalLink || ''}
        isLandingPage={true}
        isNofollow={isNofollow}
        isNoindex={isNoindex}
        lang={locale}
        metaDescription={metaDescription?.metaDescription ?? ''}
        metaKeywords={metaKeywords || []}
        metaTitle={metaTitle}
      />
      <div className="relative lg:z-20">
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={bannerStyle}
        />
      </div>
      <Header {...headerData} />
      <BreadcrumbsWrapper breadcrumbTitle={breadcrumbTitle} slug={slug} />
      <main
        className="max-w-8xl prose md:prose-md lg:prose-lg mx-auto flex flex-col gap-8 px-4 py-6 md:gap-10 lg:gap-12 lg:px-8 lg:py-10"
        id="content"
        key="reviews-page-main"
      >
        {components.map((component, i) =>
          match(component)
            .with({ __typename: 'ContentfulReviewGrid' }, data => (
              <ReviewGrid {...data} key={`review-grid-${i}`} />
            ))
            .with({ __typename: 'ContentfulLink' }, data => {
              return (
                <a
                  className="btn btn-solid-primary w-full md:mx-auto md:w-48"
                  href={data.url}
                  key={`reviews-page-link-${i}`}
                >
                  {data.text}
                </a>
              )
            })
            .with({ __typename: 'ContentfulHeroBanner' }, data =>
              pipe(data.content, head, col =>
                O.isSome(col) ? (
                  <Hero
                    content={col.value.descriptionMobile}
                    key={`reviews-hero-key-${i}`}
                  />
                ) : null
              )
            )
            .with({ __typename: 'ContentfulColoredSection' }, data => (
              <ColoredSection {...data} key="colored-section-key" />
            ))
            .with({ __typename: 'ContentfulWidgetTrustpilotBanner' }, data => (
              <TrustPilot
                className="!mb-0 !mt-0"
                id={data.contentful_id}
                key={`trustpilot-banner-${i}`}
              />
            ))
            .otherwise(() => null)
        )}
        <CountryRedirectModal />
      </main>
      <Footer footerId={footerId} type="Full" />
      {showPopupQuoteWizard ? (
        <PopupQuoteWizard data={{ ...headerData.quoteWizard }} />
      ) : null}
    </TrackingProvider>
  )
}

export const query = graphql`
  query ReviewsTemplate($id: String) {
    contentfulGenericLandingPage(id: { eq: $id }) {
      ...reviewsPageFragment
    }
  }
`
