import { type TypeOf, z } from '@simplisafe/ewok'

import { experimentTemplateSchema } from '..'

const ntComponentsSchema = z.object({
  baseline: z.object({
    id: z.string()
  }),
  variants: z.array(
    z.object({
      id: z.string(),
      hidden: z.boolean()
    })
  )
})

const ntConfigSchema = z.object({
  components: z.array(ntComponentsSchema),
  distribution: z.array(z.number()),
  distributionType: z.string(),
  traffic: z.number()
})

export const ninetailedFeatureFlagFragmentSchema = z.object({
  __typename: z.literal('NtFeatureFlag'),
  sys: z.object({
    id: z.string()
  }),
  title: z.string(),
  json: z
    .object({
      id: z.string(),
      tag: z.string()
    })
    .optional()
    .default({ id: '', tag: '' })
})

/** This will remove anything that isn't a feature flag from the variants collection */
export const ninetailedVariantsSchema = z
  .array(
    ninetailedFeatureFlagFragmentSchema.or(
      z
        .object({
          __typename: z
            .string()
            .refine(
              s => s !== 'NtFeatureFlag',
              'This NtFeatureFlag is incomplete. Please verify your GraphQL query.'
            )
        })
        .passthrough()
    )
  )
  .transform(t =>
    t.filter(
      // We can be sure that this is correct since if it has the correct __typename it will have to match that schema.
      // There is a unit test to verify this behavior.
      (t): t is TypeOf<typeof ninetailedFeatureFlagFragmentSchema> =>
        t.__typename === 'NtFeatureFlag'
    )
  )

const ninetailedFeatureFlagExperienceSchema = z.object({
  sys: z.object({
    id: z.string()
  }),
  ntName: z.string(),
  ntType: z.string(),
  ntAudience: z.object({
    sys: z.object({
      id: z.string()
    }),
    ntName: z.string()
  }),
  ntConfig: ntConfigSchema,
  ntVariantsCollection: z.object({
    items: ninetailedVariantsSchema
  })
})

const ninetailedFeatureFlagDraftSchema = z.object({
  sys: z.object({
    id: z.string()
  }),
  title: z.string(),
  json: z
    .object({
      id: z.string(),
      tag: z.string()
    })
    .optional()
    .default({ id: '', tag: '' }),
  ntExperiencesCollection: z.object({
    items: z.array(ninetailedFeatureFlagExperienceSchema)
  })
})

export const ninetailedFeatureFlagQueryDataSchema = z.object({
  ntFeatureFlag: ninetailedFeatureFlagDraftSchema
})

export type NinetailedFeatureFlagQueryDataSchema = TypeOf<
  typeof ninetailedFeatureFlagQueryDataSchema
>

const experimentSchema = experimentTemplateSchema.extend({
  variants: z.array(ninetailedFeatureFlagDraftSchema)
})

export const ninetailedFeatureFlagSchema =
  ninetailedFeatureFlagDraftSchema.extend({
    nt_experiences: z.array(experimentSchema).default([])
  })

export type NinetailedFeatureFlagDraftSchema = TypeOf<
  typeof ninetailedFeatureFlagDraftSchema
>
export type NinetailedFeatureFlagSchema = TypeOf<
  typeof ninetailedFeatureFlagSchema
>
