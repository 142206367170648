import { prop } from '@simplisafe/ewok'
import React, { type ErrorInfo, type ReactNode } from 'react'

import { logError } from './logError'
import { exists, window } from 'browser-monads-ts'

type Props = {
  readonly children: ReactNode
}

type State = {
  readonly hasError: boolean
  readonly error: Error | null
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(__error: string) {
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, {
      componentStack: prop('componentStack', errorInfo)
    })
    this.setState({ error })
    // This isn't really unit testable
    /* v8 ignore next 20 */
    if (
      /**
       * If we are running on node, we're on Gatsby and not Remix, and we're running a build and not the dev server,and if the error message isn't something Apollo will retry,
       * we want to kill the node process to break the build.
       */
      !exists(window) &&
      process.env['GATSBY_DEPLOY'] !== undefined &&
      process.env['NODE_ENV'] === 'production' &&
      !error.message.includes('status code 429')
    ) {
      console.error(error)
      process.abort()
    }
  }

  override render() {
    return this.state.hasError ? (
      process.env['NODE_ENV'] !== 'production' ? (
        <div className="rounded-base overflow-hidden bg-red-100 px-4 py-8  md:py-8">
          <div
            className="whitespace-pre-line prose md:prose-md lg:prose-lg max-w-none text-red-700"
            data-component="Text"
          >
            <h3>Error!</h3>
            <pre>{this.state.error?.message}</pre>
            <p>{JSON.stringify(this.state.error?.cause)}</p>
            <small>This box only shows for local development.</small>
          </div>
        </div>
      ) : (
        <div className="rounded-base bg-neutral-light-100 overflow-hidden px-4 py-8 text-center md:py-8">
          <div
            className="whitespace-pre-line prose md:prose-md lg:prose-lg max-w-none"
            data-component="Text"
          >
            <h2>Something went wrong.</h2>
            <p className="text-center">Try reloading the page.</p>
          </div>
        </div>
      )
    ) : (
      this.props.children
    )
  }
}

export { ErrorBoundary }
