import { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import { TrackEvent } from '../analytics'
import { getCommerceDataFromLineItems } from '../getCommerceDataFromLineItems'
import { TrackMetricProps } from '../useTrackMetricEvent'

export const trackPaymentPageView = (
  lineItems: readonly LineItem[],
  trackEvent: TrackEvent,
  trackMetricEvent: TrackMetricProps
) => {
  const ecomm = {
    ...getCommerceDataFromLineItems('checkout')(lineItems),
    actionField: { step: 3 }
  }

  trackEvent({
    ecommerce: ecomm,
    event: 'paymentInfo'
  })

  trackEvent({
    ecommerce: ecomm,
    event: 'eec.payment',
    eventAction: 'checkout',
    eventCategory: 'eec',
    eventLabel: 'payment information'
  })

  trackMetricEvent('payment-page-view')
}
