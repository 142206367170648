import { RadioItem } from '@ecomm/checkout/components'
import { ShippingOption } from '@simplisafe/ss-ecomm-data/cart'

interface Props {
  readonly shippingOptions: readonly ShippingOption[]
  readonly text?: string
  readonly setSelectedShipping: (shippingName: string) => void
}

const defaultText =
  'Shipping quotes are generated automatically when you enter your address. Free delivery on all orders over £50.'

function UkShippingOptions({
  text = defaultText,
  setSelectedShipping,
  shippingOptions
}: Props) {
  return (
    <div data-component="UkShippingOptionDisclaimer">
      <h3 className="text-base font-normal md:text-lg">{text}</h3>
      {shippingOptions.map((option, i) => (
        <div key={option.id} onClick={() => setSelectedShipping(option.name)}>
          <RadioItem
            checked={i === 0}
            description={option.localizedShippingPrice}
            key={option.id}
            label={option.name.replace('business', 'bus.')}
            name="shippingOption"
            value={option.id}
          />
        </div>
      ))}
    </div>
  )
}

export default UkShippingOptions
