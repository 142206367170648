//This component was created just for the sections that are completely hardcoded in @ecomm/scout and no content-type was created for them.
import { useHeaderRedesignQuery } from '@ecomm/header'
import {
  DeadEnd,
  ExpertSection,
  MonitoringAccolades,
  useExpertSectionData,
  useMonitoringPageAccoladesData,
  useMonitoringPageDeadEndData,
  useMonitoringPageUserStoriesData,
  UserStories
} from '@ecomm/scout'
//Renders each component based on the label of a placeholder.
export function MonitoringHardcodedComponent({
  label
}: {
  readonly label: string
}) {
  const { quoteWizard } = useHeaderRedesignQuery()
  const monitoringPageAccoladesData = useMonitoringPageAccoladesData()
  const monitoringPageDeadEndData = useMonitoringPageDeadEndData()
  const expertSectionData = useExpertSectionData()
  const monitoringPageUserStoriesData = useMonitoringPageUserStoriesData()

  const components: Record<string, JSX.Element> = {
    'Monitoring Page: Accolades': (
      <div
        className="prose-h2:mb-6 prose-h2:md:mb-8 pb-8 md:pb-0"
        data-component="MonitoringAccolades"
        data-testid="MonitoringAccolades"
      >
        <MonitoringAccolades data={monitoringPageAccoladesData} />
      </div>
    ),
    'Monitoring Page: Dead End Nav Component': (
      <DeadEnd
        data={{
          ...monitoringPageDeadEndData,
          quoteWizard: quoteWizard
        }}
      />
    ),
    'Monitoring Page: Help Widget': <ExpertSection data={expertSectionData} />,
    'Monitoring Page: User Story Cards': (
      <UserStories data={monitoringPageUserStoriesData} />
    )
  }

  return components.hasOwnProperty(label) ? components[label] : null
}
