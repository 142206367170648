import {
  useCurrentPromoBannerData,
  useSitewidePromoOfferTitle
} from '@ecomm/promotions-hooks'

import { TierOne } from '../../../TierOne'
import { TierThree } from '../../../TierThree'
import { TierTwo } from '../../../TierTwo'

export function MiddleLayoutNoGift() {
  const { disclaimerText, offerLabel, overrideBannerText } =
    useCurrentPromoBannerData()
  const offerTitle = useSitewidePromoOfferTitle()
  const tierOneText = !overrideBannerText
    ? offerTitle.toUpperCase()
    : offerTitle

  return (
    <div className="text-center" data-component="MiddleLayoutNoGift">
      <TierOne>{tierOneText}</TierOne>
      {offerLabel ? <TierTwo>{offerLabel}</TierTwo> : null}
      {disclaimerText ? (
        <div className="pt-1">
          <TierThree>{disclaimerText}</TierThree>
        </div>
      ) : null}
    </div>
  )
}
