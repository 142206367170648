import { zuoraPaymentCallback } from '@ecomm/data-simplisafe-api'
import { PaymentCallbackRequest } from '@ecomm/data-zuora'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'

export async function handleZuoraResponse(
  callbackRequest: PaymentCallbackRequest,
  successFn: () => void,
  failureFn: () => void
) {
  return await zuoraPaymentCallback(callbackRequest)().then(e =>
    pipe(e, E.bimap(failureFn, successFn))
  )
}
