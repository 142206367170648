import { overloadMaybe, prop } from '@simplisafe/ewok'
import { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'

import { getShippingInfoPrice } from './getShippingInfoPrice'

export const getCartSubTotal = (locale: string) => (cart: ImmutableCart) => {
  const shouldIncludeTax = locale === 'en-GB'
  const taxedPrice = pipe(
    overloadMaybe(cart.get('taxedPrice')),
    O.getOrElse(() => 0)
  )
  return pipe(
    O.fromNullable(prop('totalPrice', cart)),
    O.chain(totalPrice =>
      O.fromNullable(totalPrice - getShippingInfoPrice(cart))
    ),
    O.chain(totalPrice =>
      shouldIncludeTax
        ? O.fromNullable(totalPrice)
        : O.fromNullable(totalPrice - taxedPrice)
    ),
    O.chain(totalPrice =>
      pipe(
        overloadMaybe(localizedDisplayPrice(locale)(totalPrice)),
        O.chain(displayPrice => O.fromNullable(displayPrice))
      )
    )
  )
}
