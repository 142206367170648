import { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { Carousel } from '@ecomm/shared-components'
import React from 'react'

import PackageCarouselSlide from './PackageCarouselSlide'

type Props = {
  readonly hoveredIdx?: number
  readonly images: readonly GatsbyImageSchema[]
}

function PackageCarousel({ images, hoveredIdx }: Props) {
  return (
    <Carousel
      activeSlide={hoveredIdx}
      arrowControls
      autoPlaySpeed={5000}
      dotControls
      infinite
    >
      {images.map((image, idx) => {
        return <PackageCarouselSlide image={image} key={idx} />
      })}
    </Carousel>
  )
}

export default PackageCarousel
