// @ts-nocheck
import React, { FC, ReactNode, useCallback, useState } from 'react'

import Divider from '../Divider'
import type { SSButtonProps } from '../SSButton'
import SSButton from '../SSButton'
import SSInput from '../SSInput'
import type { Response } from './WizardTypes'

export type WizardPhoneSubmitProps = {
  readonly buttonProps?: Partial<SSButtonProps>
  readonly hideLabel?: boolean
  readonly message: ReactNode
  readonly nameLabel: ReactNode
  /** You don't need to add this prop. It's injected by the Wizard container */
  readonly onNextStep?: (response: Response) => () => void
  readonly onSubmit: (x: Record<string, unknown>) => void
  readonly phoneLabel: ReactNode
  /** Placeholder text for name input field */
  readonly placeholderName?: string
  /** Placeholder text for phone input field */
  readonly placeholderPhone?: string
  readonly response?: Record<string, unknown>
  readonly submitButtonLabel: string
  readonly subtitle?: ReactNode
  /** data-component attribute value */
  readonly dataComponent?: string
}

/** @deprecated Do not use ss-react-components*/
const WizardPhoneSubmit: FC<WizardPhoneSubmitProps> = ({
  buttonProps,
  hideLabel = true,
  message,
  nameLabel,
  onNextStep,
  onSubmit,
  phoneLabel,
  placeholderName,
  placeholderPhone,
  response,
  submitButtonLabel,
  subtitle,
  dataComponent = WizardPhoneSubmit.name
}: WizardPhoneSubmitProps) => {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const handleNameChange = useCallback(e => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const v = e.target.value
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setName(v)
  }, [])

  const handlePhoneChange = useCallback(e => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const v = e.target.value
    setPhone(v)
  }, [])

  const handleSubmit = useCallback(
    e => {
      onSubmit({
        name: name,
        phone: phone,
        ...response
      })

      onNextStep &&
        onNextStep({
          id: 'submitted',
          value: 'true'
        })()

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      e.preventDefault()
    },
    [onNextStep, name, phone, response, onSubmit]
  )

  const isValid = () => {
    return name.length >= 2 && phone.length >= 10
  }

  return onNextStep ? (
    <div data-component={dataComponent}>
      {subtitle}
      {message ? (
        <div>
          {message}
          <Divider />
        </div>
      ) : null}
      <div className="grid w-full grid-cols-12 gap-2">
        <SSInput
          className="col-span-9 h-14 max-w-none md:col-span-12"
          hideLabel={hideLabel}
          id="name"
          label={nameLabel}
          onChange={handleNameChange}
          placeholder={placeholderName}
          type="text"
        />
        <SSInput
          className="col-span-9 h-14 max-w-none"
          hideLabel={hideLabel}
          id="phone"
          label={phoneLabel}
          onChange={handlePhoneChange}
          placeholder={placeholderPhone}
          type="text"
        />
        <div className="col-span-3 h-14">
          <SSButton
            className="w-full min-w-0 md:max-w-[120px]"
            disabled={!isValid()}
            onClick={handleSubmit}
            type="submit"
            {...buttonProps}
          >
            {submitButtonLabel}
          </SSButton>
        </div>
      </div>
    </div>
  ) : null
}

export default WizardPhoneSubmit
