import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { TypeOf, z } from '@simplisafe/ewok'

import { featureSectionSchema } from '../FeatureSection/schema'

export const carouselSchema = z.object({
  __typename: z.literal('ContentfulCarousel'),
  id: z.string(),
  title: z.string(),
  description: contentfulRichTextSchema.optional(),
  name: z.string().optional(),
  items: z
    .object({
      headline: z.string(),
      image: gatsbyImageSchema,
      description: contentfulRichTextSchema
    })
    .array()
})

export const anchorSectionSchema = z.object({
  __typename: z.literal('ContentfulAnchorSection'),
  name: z.string(),
  component: z.union([carouselSchema, featureSectionSchema]),
  classNames: z.string().optional()
})

export type AnchorSectionSchema = TypeOf<typeof anchorSectionSchema>
