import { z } from 'zod'

import { layoutSchema } from '../../assemblies/Layout/layoutSchema'
import { sensorGroupSchema } from '../../components/SensorGroup/schema'
import useFragmentsWithVariations from '../../hooks/useFragmentsWithVariations'
import useFragmentWithVariations from '../../hooks/useFragmentWithVariations'
import bmsPageSchema from './bmsPageSchema'

export const useBmsPageFragment = <
  T extends { readonly contentfulBmsPage: U },
  U extends { readonly slug: string }
>(
  query: T
) => {
  const bmsPageFragment = query.contentfulBmsPage

  const bmsPage = useFragmentWithVariations(bmsPageFragment, bmsPageSchema)

  const layoutFragment = bmsPage.layout

  const layout = useFragmentWithVariations(layoutFragment, layoutSchema)

  const sensorGroupsFragment = bmsPage?.sensorGroups

  const [sensorGroups] = useFragmentsWithVariations(
    sensorGroupsFragment,
    sensorGroupSchema
  )

  return {
    ...bmsPageSchema.parse(bmsPage),
    layout: layoutSchema.parse(layout),
    slug: bmsPageFragment.slug,
    sensorGroups: z.array(sensorGroupSchema).parse(sensorGroups)
  }
}
