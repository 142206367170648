import { useMediaQuery } from '@ecomm/ss-react-components'
import React from 'react'

import { ContentfulButton } from '../../../graphql'
import { ContentfulButtonProps } from '../ButtonComponent'
import Button from '../ButtonComponent'

/**
 * Renders PartnerClaimNowClickTargetComponent applies specific styling for mobile
 * @see https://simplisafe.atlassian.net/browse/ECP-4422
 * @param props
 * @returns
 */
export function PartnerClaimNowClickTargetComponent(
  props: ContentfulButtonProps
) {
  const isMobile = !useMediaQuery('TabletAndUp')
  return (
    <Button
      {...props}
      style={
        isMobile
          ? {
              paddingLeft: 'revert',
              paddingRight: 'revert',
              whiteSpace: 'nowrap',
              width: '100%'
            }
          : {}
      }
    />
  )
}

/**
 * Check if we should map a contentful button component to  PartnerClaimNowClickTargetComponent
 * @param contentfulComponent
 * @returns PartnerClaimNowClickTargetComponent or false
 */
export function mapToPartnerClaimNowClickTargetComponent(
  contentfulComponent: Partial<ContentfulButton>
) {
  // @ts-expect-error TS(2339) FIXME: Property 'buttonText' does not exist on type 'Part... Remove this comment to see the full error message
  if (
    contentfulComponent.internal?.type === 'ContentfulButton' &&
    contentfulComponent.buttonText === 'Claim Now'
  ) {
    return PartnerClaimNowClickTargetComponent
  } else {
    return false
  }
}
