import { Locale } from '../../hooks/useLocalizedData'

const description = {
  raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"heading-3","data":{},"content":[{"nodeType":"text","value":"Easy to set up","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Once the camera is fully charged, press the setup button and follow the steps in the app to connect it to your system. Because the camera is wireless, it\'s easy to place where you want protection. No wiring need.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"For help with optional wall mounting, or to install as part of a complete security system, consider ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"/installation"},"content":[{"nodeType":"text","value":"Pro Install options","marks":[],"data":{}}]},{"nodeType":"text","value":".","marks":[],"data":{}}]}]}'
}

export const data = {
  [Locale.EnGB]: {
    description
  },
  [Locale.EnUS]: {
    description
  }
}
