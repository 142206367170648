import { getFormattedSS2RelativeDiscount } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { MonitoringPlan } from '../../../components/DraftCart/types'
import { useAmountSavedWithServicePlanBMS } from '../../../hooks/IMAPS/useAmountSavedWithServicePlanBMS'
import { useDiscountWithServicePlan } from '../../../hooks/IMAPS/useDiscountWithServicePlan'
import useMonitoringPlan from '../../../hooks/IMAPS/useMonitoringPlan'
import { LegalDisclaimer } from '../../LegalDisclaimer'
import { OptInOrOut } from '../OptInOrOut'
import { PriceToggleProps } from '../types'

export function DraftCartMonitoringWrapper({
  priceToggles,
  sku = ''
}: PriceToggleProps) {
  const { discountWithServicePlan, isLoading } = useDiscountWithServicePlan(sku)
  const amountSavedWithServicePlan = useAmountSavedWithServicePlanBMS()
  const { type: monitoringType } = useMonitoringPlan({ priceToggles })
  const microCopy = useMicroCopy()

  return (
    <div>
      {isLoading ? (
        <Skeleton count={2.5} />
      ) : (
        <div data-component="DraftCartMonitoringWrapper">
          {monitoringType === MonitoringPlan.ss2 ? (
            <div className="border-neutral-medium-100 mb-4 w-full rounded-xl border border-solid bg-white p-6 shadow-md">
              <p className="m-0 mb-2 font-semibold text-orange-700">
                {microCopy['ss2-monitoring-toggle'](
                  getFormattedSS2RelativeDiscount()
                )}
              </p>
            </div>
          ) : (
            <>
              <OptInOrOut
                amountSavedWithServicePlan={amountSavedWithServicePlan}
                discountWithServicePlan={discountWithServicePlan}
                priceToggles={priceToggles}
              />
              <LegalDisclaimer />
            </>
          )}
        </div>
      )}
    </div>
  )
}
