import React from 'react'

export default function UKBlogHeroBanner({
  title
}: {
  readonly title: string
}) {
  return (
    <div className="max-w-8xl rounded-base bg-neutral-black mx-4 mt-4 p-8 text-center md:mx-8 md:mt-8 md:p-12 lg:p-16 xl:mx-auto">
      <h1 className="m-0 p-4 text-white">{title}</h1>
    </div>
  )
}
