import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import {
  type CreateOptions,
  ApolloRichText,
  ContentfulImage,
  renderBlocksEmbeddedEntry
} from '@ecomm/contentful/components'
import { type GridSchema, isSmallTextArray } from '@ecomm/shared-sections'
import { z } from '@simplisafe/ewok'
import classNames from 'classnames'
import { match } from 'ts-pattern'

const gridOpts =
  (idx: number): CreateOptions =>
  (links): Options['renderNode'] => {
    return {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return renderBlocksEmbeddedEntry(
          links,
          node,
          z.object({ text: z.string() }),
          ({ text }) => (
            <button
              className={classNames(
                'btn mt-auto btn-outlined btn-outlined-primary',
                {
                  vfyps_mil: idx === 0,
                  vfyps_med: idx === 1,
                  vfyps_tea: idx === 2,
                  vfyps_fir: idx === 3
                }
              )}
            >
              {text}
            </button>
          )
        )
      }
    }
  }

export default function CardGrid({
  headline,
  gridItemsCollection: { items: gridItems }
}: GridSchema) {
  return (
    <div className="w-full grid grid-cols-1 p-4 md:p-8 lg:p-10 gap-4 md:gap-8 lg:gap-10 md:grid-cols-2 lg:grid-cols-4 bg-neutral-light-50 rounded-base">
      <h2 className="col-span-1 md:col-span-2 lg:col-span-4 text-center mb-0">
        {headline}
      </h2>
      {match(gridItems)
        .when(isSmallTextArray, items =>
          items.map((item, i) => {
            const icon = item.icon
            const text = item.text
            return (
              <div
                className="col-span-1 prose-h3:text-body-size prose-h3:mb-3 prose-h3:leading-normal rounded-base overflow-hidden bg-white"
                key={`grid-item-${i}`}
              >
                {icon ? (
                  <ContentfulImage classNameOverride="w-full h-48" {...icon} />
                ) : null}
                <div className="p-4 md:p-6 flex flex-col h-52 md:h-64">
                  {text?.json ? (
                    <ApolloRichText
                      json={text.json}
                      links={text.links}
                      options={gridOpts(i)}
                    />
                  ) : null}
                </div>
              </div>
            )
          })
        )
        .otherwise(() => null)}
    </div>
  )
}
