import type { Address } from '@simplisafe/eis-commercetools-carts'
import equals from 'fast-deep-equal'
import { match } from 'ts-pattern'

import { addAtomDebugLabel } from '@ecomm/utils'
import { useAtom } from 'jotai'
import { atomWithReducer } from 'jotai/utils'
import { useEffect } from 'react'
import { useCartValue } from '../atoms/useCart'

// https://jotai.org/docs/recipes/atom-with-compare
const baseBillingAddressAtom = atomWithReducer(
  null,
  (prev: Address | null, next: Address | null) => {
    return equals(prev, next) ? prev : next
  }
)
addAtomDebugLabel(baseBillingAddressAtom, 'Cart - Billing Address')

/**
 * Get the billing address from a jotai cart, with deep equality checks
 */
export const useCartBillingAddress = (): Address | null => {
  const cart = useCartValue()
  const [billingAddress, setBillingAddress] = useAtom(baseBillingAddressAtom)

  const cartBillingAddress = match(cart)
    .with({ _tag: 'not_initialized' }, _ => null)
    .with({ _tag: 'error' }, _ => null)
    .with({ _tag: 'empty' }, _ => null)
    .with({ _tag: 'no_cart_id' }, _ => null)
    .with({ _tag: 'updating' }, cart => cart.val.billingAddress)
    .with({ _tag: 'with_items' }, cart => cart.val.billingAddress)
    .exhaustive()

  useEffect(() => {
    setBillingAddress(cartBillingAddress)
  }, [cartBillingAddress, setBillingAddress])

  return billingAddress
}
