import { addAtomDebugLabel } from '@ecomm/utils'
import { Map as ImmutableMap } from 'immutable'
import { atom } from 'jotai'

const internalAtom = atom<ImmutableMap<string, string>>(
  ImmutableMap<string, string>()
)
addAtomDebugLabel(internalAtom, `internal - optimizely variations`)

export const variationAtom = atom(
  get => get(internalAtom),
  (get, set, newValue: ImmutableMap<string, string>) => {
    set(internalAtom, get(internalAtom).mergeDeep(newValue))
  }
)
addAtomDebugLabel(variationAtom, `optimizely variations`)
