//@ts-nocheck
import classNames from 'classnames'
import always from 'ramda/src/always'
import equals from 'ramda/src/equals'
import ifElse from 'ramda/src/ifElse'
import React, { FC, ReactElement, ReactNode } from 'react'

import { SSButton } from '..'
import useMediaQuery from '../hooks/useMediaQuery'
import PopupCard from '../PopupCard'
import { SSButtonProps } from '../SSButton'
import * as css from './FloatingPromoWidget.module.scss'

export type FloatingPromoWidgetProps = {
  /** The content of the component */
  readonly content?: ReactNode
  /* title for Promo Widget*/
  readonly title: string
  /* props for apply deal button*/
  readonly buttonProps: SSButtonProps
  /* props for promo camera image */
  readonly backdropImage: ReactElement
  /* props for promo widget styles */
  readonly promoWidgetStyles?: boolean
  /**
   * Function for apply promo code
   * @deprecated use onClick prop to pass a function
   */
  readonly onApplyCoupon?: () => void
  /* Function for onClick */
  readonly onClick: () => void
  /* boolean for sucessfully applied promo code */
  readonly showSuccess: boolean
  /** The content of the component */
  readonly thankYouNoteContent?: ReactNode
  /* Function for close ppoup card */
  readonly onClose: () => void
  /** data-component attribute value */
  readonly dataComponent?: string
  /* product price detail */
  readonly price?: ReactNode
  readonly errorMessage?: ReactNode
}

/** @deprecated Do not use ss-react-components*/
const FloatingPromoWidget: FC<FloatingPromoWidgetProps> = ({
  title,
  content,
  buttonProps,
  backdropImage,
  onClick,
  onApplyCoupon,
  showSuccess,
  thankYouNoteContent,
  onClose,
  price,
  dataComponent = FloatingPromoWidget.name,
  errorMessage
}: FloatingPromoWidgetProps) => {
  const isTabletOrUp = useMediaQuery('TabletAndUp')
  const handleClick = () => {
    onClick && onClick()
    onApplyCoupon && onApplyCoupon()
  }

  return (
    <PopupCard
      dataComponent={dataComponent}
      onCloseCallback={onClose}
      padding={0}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      popupType={ifElse(
        equals(true),
        always('right'),
        always(null)
      )(isTabletOrUp)}
      promoWidgetStyles={true}
      showOpen={true}
    >
      <div className={classNames(css.slideContent)}>
        {backdropImage}
        <div className={classNames(css.innerCont)}>
          <h2 className={classNames(css.title)}>
            {title}{' '}
            {price ? (
              <span className={classNames(css.priceTxt)}>({price})</span>
            ) : null}
          </h2>
          {thankYouNoteContent && content
            ? showSuccess
              ? thankYouNoteContent
              : content
            : null}
          {!showSuccess && (
            <>
              <SSButton
                className={classNames(css.popupBtn)}
                {...buttonProps}
                onClick={handleClick}
              />
              {errorMessage ? (
                <div className={'m1_t'}>{errorMessage}</div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </PopupCard>
  )
}

export default FloatingPromoWidget
