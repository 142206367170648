import { PromoBannerWrapper } from '@ecomm/promotions-components'
import { toPromoBannerStyleValue } from '@ecomm/shared-components'
import { path } from '@simplisafe/ewok'
import { safePath } from '@simplisafe/monda'

import { ContentfulBannerTopPage } from '../../../graphql'
import { usePromoBannerExperimentQuery } from '../../experiments/usePromoBannerExperimentQuery'
import { PageProps } from './types'

function PromoBanner({ pageData }: { readonly pageData: PageProps['data'] }) {
  const promoBannerExperiment = usePromoBannerExperimentQuery()
  const promoBannerStyle =
    toPromoBannerStyleValue(
      path(['contentfulPage', 'pageLayout', 'promoBannerStyle'], pageData)
    ) || 'none'

  const pageTopBanner: ContentfulBannerTopPage = safePath(
    ['contentfulPage', 'pageTopBanner'],
    pageData
  ).orUndefined()

  return (
    <>
      {!pageTopBanner && (
        <PromoBannerWrapper
          experimentData={promoBannerExperiment}
          type={promoBannerStyle}
        />
      )}
    </>
  )
}

export default PromoBanner
