import { useMemo } from 'react'

import { bmsMoreDetailsData } from './data'
import { productDataSchema } from './schema'

export const useBMSMoreDetailsData = (sku: string) => {
  const productData = bmsMoreDetailsData[sku]

  return useMemo(() => {
    return !productData ? null : productDataSchema.parse(productData)
  }, [productData])
}
